import React from 'react';
import ReactDOM from 'react-dom'; 
import App from './App';
import * as serviceWorker from './serviceWorker';

import modules from './modules'; // MODULES
import ReduxThunk from 'redux-thunk'; // FOR ASYNC
import {createStore, applyMiddleware} from 'redux'; // FOR MIDDLEWARES

import Promise from 'promise-polyfill'; 
import {Provider} from 'react-redux';
import { HashRouter } from 'react-router-dom';

import './css/indexCss/bootstrap.css';
import './css/indexCss/ui-carousel.css';   
import './css/primary-style/main.css';
import './css/primary-style/util.css';
import './css/animations.css';
import './css/blog.css';
import './css/branch.css';
import './css/dashboard.css';
import './css/event.css';
import './css/font-awesome.min.css';
import './css/index.css';
import './css/jquery-ui.css';
import './css/laypage.css';
import './css/main.css';
import './css/messages.css';
import './css/mooc.css';
import './css/pay.css';
import './css/questionandanswer.css';
import './css/sitemessage.css';
import './css/static_page.css';
import './css/studentreg.css';
import './css/teacherprofile.css';
import './css/teachersearch.css';
import './css/tutorbookrecord.css';
import './css/tutorreg.css';
import './css/userereg.css';
import './css/userlogin.css';
import './nav.css';


// To add to window
if (!window.Promise) {
    window.Promise = Promise;
}
const store = createStore(modules, applyMiddleware(ReduxThunk));

const AppRendering = ()=>{
    const appElement = document.getElementById('root');
    ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <App/>
        </HashRouter>
    </Provider>
    , appElement);
}
 
AppRendering();


// serviceWorker.register();
// DONOT USE register
serviceWorker.unregister();
