import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasGroupClass
} from '../../../Translate/EachTranslateC';


// css
import './groupclass.css';

// data
import { staticGroupClassList } from './staticdata'
import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';
import {metaClass, applyMetaHeader} from '../../extra/metaController';

class GroupClass extends Component{
    state = {
        lang: window.localStorage.lang || 'ko',
        modalGroupClassTitle: '',
        modalGroupClassList: [],
    }

    componentDidMount = async () =>  {
        this.setMetas();
    }

    setMetas = () => {
        const _type = '';
        const _title = '그룹수업';
        const _url = window.location.href;
        let _description = '그루미 검증! 최고의 선생님들이 각자의 노하우로 효과적인 수업을 기획하고 제공합니다.';
        const _imageUrl = '';
        let _keywords = '';
        for(let i = 0; i < staticGroupClassList.length; i++){
            _keywords += staticGroupClassList[i].title + ', ';
        }
        _keywords = _keywords.substr(0, _keywords.length -2);
        const newMetaClass = new metaClass(_type, _title, _url, _description, _imageUrl, _keywords);
        applyMetaHeader(newMetaClass);
    }

    initGroupClassChildShowModal = (modalTitle, groupClasses) => {
        this.setState({
            modalGroupClassTitle: modalTitle, 
            modalGroupClassList: groupClasses
        })
    }

    createBreadcrumb = () => {
        return(
            <div className="container">
                <ol className="breadcrumb mooc-index-breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasGroupClass/></li>
                </ol>
            </div>
        )
    }

    createHeaderBanner = () => {
        return(
            <div className="parallax position-relative background-size-cover ng-scope group-class-bg">
                <div className="color-overlay"></div>
                <div className="container">
                    <div className="col-md-12 text-center"><h1
                        className="margin-top-100 margin-bottom-100 font-size-44 boldest-font white-font-color">그루미 검증! 최고의 선생님들이 각자의
                        노하우로 효과적인 수업을 기획하고 제공합니다.</h1>
                        {/* <!--<h4 className="margin-bottom-100 normal-font white-font-color">-->
                        <!--IB 직접 이수 및 해외,국내 명문대 출신 과목별 전문 강사진이 준비한 “개념 정리 & 문제 풀이”를 위한 선행 & 복습--> <!--</h4>-->  */}
                    </div>
                </div>
            </div>
        )
    }


    clickOldRedirect = () => {
        let msg = '해당 수업은 종료되었습니다.';
        showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
    }

    createContentHtml = () => {
        const {modalGroupClassTitle,modalGroupClassList} = this.state
        return(
            <div className="container">
                <div className="sidebar sidebar-with-bg margin-top-20">
                            <div className="sidebar-widget">
                                <h4 className="blue-font-color no-margin-top"> 그룹수업 장점</h4>
                                <ul className="no-list-style padding-left-5 no-padding-xs">
                                    <li className="margin-5">
                                        <i className="fa fa-check gray3-font-color"></i> "같이 배우는 <span className="blue-text">재미</span>
                                        그리고 서로간의 <span className="blue-text">경쟁</span>의 시너지로 수업에
                                        <span className="blue-text">활력</span> 추가"
                                    </li>
                                    <li className="margin-5"><i className="fa fa-check gray3-font-color"></i> "각국에서 공부하고 있는 유학생들이 서로 교류하여
                                        자신의 ‘<span className="blue-text">우물 밖</span>’을 느끼고 경험"
                                    </li>
                                    <li className="margin-5"><i className="fa fa-check gray3-font-color"></i> 그룹수업은 수업료 부담을 덜기 위해 만들어진 (수업
                                        모집) 시스템입니다. 그루미 측에서도 학생들을 모집하지만 학부모님께서도 관심 그룹수업에 같이 참여할 자녀 친구들을 모셔올 수 있습니다.
                                    </li>
                                </ul>
                            </div>
                            <div className="sidebar-widget">
                                <h4 className="blue-font-color"> 그루미 그룹수업</h4>
                                <ul className="no-list-style padding-left-5 no-padding-xs">
                                    <li className="margin-5"><i className="fa fa-check gray3-font-color"></i> "3~5명 소수정예"</li>
                                    <li className="margin-5"><i className="fa fa-check gray3-font-color"></i> "정직한 수업료"</li>
                                </ul>
                            </div>
                            <div className="sidebar-widget">
                                <p>학교 커리큘럼대로 수업하고 싶으시면 같은 학교/반 친구들끼리 소규모 그룹으로 신청 가능</p>
                                <p>오프라인 (원하는 시간/장소) 및 온라인</p>
                            </div>
                        </div>

                <div className="margin-top-20 margin-bottom-20">
                    {
                        staticGroupClassList.map((item,index) => {
                            // RRR - OLD PAGE REDIR
                            let redefinedRedirection;
                            if(item.isOld){
                                redefinedRedirection =   <a className="btn btn-gurume-primary float-right" onClick={this.clickOldRedirect}>
                                                            <i className="fa fa-caret-right"></i> 상세 보기
                                                        </a>;
                            }else{
                                redefinedRedirection =   <a className="btn btn-gurume-primary float-right" href={item.redirectUrl}>
                                                            <i className="fa fa-caret-right"></i> 상세 보기
                                                        </a>;
                            }
                            return(

                        
                            <div className={['group-class-block row',item.end?'opacity-100':''].join(' ')}>
                                <div className="col-md-3 col-xs-12">
                                    {
                                        item.end ?
                                            <div className="ribbon-wrapper margin-right-15" ng-if="item.end">
                                            <div className="ribbon  ribbon-red">
                                                종료
                                            </div>
                                        </div> : ''
                                    }
                                    
                                    <img alt="img" src={item.imageUrl} className="img-fluid margin-bottom-20"/>
                                </div>
                                <div className="col-md-9 col-xs-12">
                                    <h3 className="no-margin-top margin-top-10-xs blue-font-color">{item.title}</h3>
                                    <h5 className="gray2-font-color"><i className="fa fa-calendar margin-right-5"></i> 기간 :
                                        {item.period} &nbsp;/&nbsp; <i className="fa fa-map-marker  margin-right-5"></i> 장소 :
                                        {item.location}</h5>
                                    <p className="gray1-font-color">
                                        {
                                            (item.teachers || []).map((teacher,index2) => 
                                                <span>{teacher}<br/></span>
                                            )
                                        }
                                    </p>
                                    {
                                        item.childGroupClasses ? 
                                        <button className="btn btn-gurume-primary float-right" data-toggle="modal"
                                            data-target="#child-group-class-show-modal"
                                            onClick={() => this.initGroupClassChildShowModal('겨울 특강 수업', item.childGroupClasses)}>
                                                <i className="fa fa-caret-right"></i> 상세 보기
                                        </button> 
                                        : redefinedRedirection
                                      
                                    }
                                </div>
                            </div>
                        )})
                    }
                    <div className="modal fade" id="child-group-class-show-modal" tabIndex="-1" role="dialog" aria-labelledby="">
                        <div className="modal-dialog modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">{modalGroupClassTitle}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                        aria-hidden="true">&times;</span></button>
                                </div>
                                <div className="modal-body">
                                {
                                    modalGroupClassList.map((item,index) => 
                                        <div className="group-class-block row">
                                            <div className="col-md-3 col-xs-12">
                                                <div className="ribbon-wrapper margin-right-15" ng-if="item.end">
                                                    <div className="ribbon  ribbon-red">
                                                        종료
                                                    </div>
                                                </div>
                                                <img alt="img" src={item.imageUrl} className="img-fluid margin-bottom-20"/>
                                            </div>
                                            <div className="col-md-9 col-xs-12">
                                                <h3 className="no-margin-top margin-top-10-xs blue-font-color">{item.title}</h3>
                                                <h5 className="gray2-font-color"><i className="fa fa-calendar margin-right-5"></i> 기간 :
                                                    {item.period} &nbsp;/&nbsp; <i className="fa fa-map-marker  margin-right-5"></i> 장소 :
                                                    {item.location}</h5>
                                                <p className="gray1-font-color">
                                                    {
                                                        (item.teachers || []).map((teacher,index2) => 
                                                            <span>{teacher}<br/></span>
                                                        )
                                                    }
                                                </p>
                                                {
                                                    (item.redirectList || []).map((redirect,index2) => 
                                                        <a className="btn btn-gurume-primary float-right margin-left-5" target="_blank" rel="noopener noreferrer" href={redirect.url}>
                                                            <i className="fa fa-caret-right"></i> {redirect.title}
                                                        </a>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!--<div className="group-class-block opacity-50" ng-repeat="item in itemList">-->
                    <!--<div className="col-md-3">-->
                    <!--<img ng-src="{item.thumbnail}" className="img-fluid margin-bottom-20"/>-->
                    <!--</div>-->
                    <!--<div className="col-md-9">-->
                    <!--<h3 className="no-margin-top margin-top-10-xs">[마감] {item.title}</h3>-->
                    <!--<h5 className="gray2-font-color"><i className="fa fa-calendar margin-right-5"></i> 기간 :-->
                    <!--{item.start_time}~{item.end_time} &nbsp;/&nbsp; <i-->
                    <!--className="fa fa-map-marker  margin-right-5"></i>-->
                    <!--장소 : {item.location}</h5>-->

                    <!--<p className="gray1-font-color">-->
                    <!--<span>선생님 : {lang=='en' ? item.tutor.first_name_en + item.tutor.last_name_en : item.tutor.last_name+item.tutor.first_name}</span>-->
                    <!--</p>-->
                    <!--<a className="btn btn-gurume-success float-right"><i className="fa fa-caret-right"></i> 상세 보기</a>-->
                    <!--</div>-->
                    <!--<div className="clearfix"></div>-->
                    <!--</div>--> */}
                </div>
            </div>
        )
    }

    render = () => {
        return(
            <div className="group-class-container">
                {this.createBreadcrumb()}
                {this.createHeaderBanner()}
                {this.createContentHtml()}
            </div>
        )
    }
}

export default withRouter(GroupClass);
