import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasCredit,
    TrasBalanceHistory,
    TrasBalance,
    TrasQuestion,

} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { showNotify, STATE_NOTIFY, moneyFormat, TypeConsume } from '../../extra/cusUtil';

import './credit_history.css';
import './credit_common.css';


class credit_history extends Component {


    state = {
        currentCurrencySimbol: '₩',
        currentMoney: '0',
        currentList: [],

        studentList: [],

        isAjaxDone: false,
        isLoading: false,
    }


    componentDidMount = () => {
        this.doInit();
    }

    doInit = async () => {

        const allUrls = await GetAjaxURL();
        const approveUrl = allUrls.gcoin.approve;
        const approveRes = await axios.get(approveUrl).catch((err) => { return console.log(err); });

        const approveData = approveRes.data;
        if (approveData.status === 'fail') {
            let msg = '어드민만 접근할 수 있습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.history.back();
        }

        const studentList = approveData.students;

        this.setState({
            ...this.state,
            studentList: studentList,
            isAjaxDone: true,
        })

    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/credit/balance"><TrasCredit /></a></li>
                    <li className='active'><TrasBalanceHistory /></li>
                </ol>
            </div>
        )
    }


    createBody = () => {

        const gCoinList = this.state.currentList;
        const li = [];
        for (let i = 0; i < gCoinList.length; i++) {
            const type = String(gCoinList[i].type);
            const tempDate = gCoinList[i].date.date.split('.');
            const gcoin = gCoinList[i].gcoin;
            const name = gCoinList[i].name;
            const picture = gCoinList[i].picture;


            let date = tempDate;
            let sDate = '';
            let sTime = '';
            if (date.length !== 0) {
                date = date[0];
                date = date.split(' ');
                if (date.length !== 0) {
                    sDate = date[0];
                    sTime = date[1];
                }
            }

            const dateSpan =
                <span className='credit-bt-bodies-date'>
                    <div>{sDate}</div>
                    <div>{sTime}</div>
                </span>;

            let textCurrency = 'KRW';

            let iconSpan = '';
            let typeSpan = '';
            if (type === '3') {
                iconSpan =
                    <span>
                        <img src={picture} alt='...' onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }} />
                    </span>;
                typeSpan =
                    <span>
                        <span>
                            {name}
                        </span>
                    </span>;
            } else if (type === '4') {
                iconSpan =
                    <span>
                        <i className="fa fa-question-circle fa-2x"></i>
                    </span>;
                typeSpan =
                    <span>
                        <TrasQuestion />
                    </span>
            } else {
                iconSpan =
                    <span>
                        <i className="fa fa-credit-card fa-2x"></i>
                    </span>;
                typeSpan =
                    <span>
                        <TrasCredit />
                    </span>
            }




            // 리스트는 한화로 통일
            textCurrency = 'KRW';

            li.push(
                <li key={'gCoinList_' + i}>
                    {iconSpan}
                    {typeSpan}
                    <span>{TypeConsume(Number(type))}</span>
                    <span>{moneyFormat(gcoin)} {textCurrency}</span>
                    {dateSpan}
                </li>
            )
        }



        return (
            <div className='credit-default-body'>
                <h4 className='color-gurume-blue'><TrasBalanceHistory /></h4>
                <div className='credit-default-table'>
                    <div className='credit-bt-header'>
                        <span><i className="fa fa-star fa-1x"></i></span>
                        <span>종류</span>
                        <span>출처</span>
                        <span>금액</span>
                        <span>일자</span>
                    </div>
                    <div className='credit-bt-bodies'>
                        <ul>
                            {li}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }


    changerUsers = async (e) => {

        const target = e.target;
        const value = target.value;

        const allUrls = await GetAjaxURL();
        const history_adminUrl = allUrls.gcoin.history_admin;

        const formData = new FormData();
        formData.append('id', value);

        const history_adminRes = await axios.post(history_adminUrl, formData, postConfig).catch((err) => { return console.log(err); });
        const history_adminData = history_adminRes.data;

        let tempCoin = history_adminData.total_gcoin;
        tempCoin = tempCoin.split('.');
        if (tempCoin.length !== 0) {
            tempCoin = tempCoin[0];
        }

        const total_gcoin = tempCoin;
        const currentCurrency = String(history_adminData.preferred_currency);
        const currentCurrencySimbol = currentCurrency === '1' ? '￥' : currentCurrency === '3' ? '£' : '₩';

        const currentList = history_adminData.transactions;

        this.setState({
            ...this.state,
            currentMoney: total_gcoin,
            currentCurrencySimbol: currentCurrencySimbol,
            currentList: currentList,
        })



    }

    createHeader = () => {

        const studentList = this.state.studentList;

        const options = [];
        for (let i = 0; i < studentList.length; i++) {
            options.push(
                <option key={'_key_' + i} value={studentList[i].id}>{studentList[i].id + ") " + studentList[i].name}</option>
            );
        }



        return (
            <div className='credit-history-head'>
                <h3 className="blue-font-color"><TrasBalance /></h3>
                <div className='credit-history-head-won'>
                    <div>
                        {this.state.currentCurrencySimbol}
                        <span className='color-gurume-red'>{' ' + moneyFormat(this.state.currentMoney)}</span>
                    </div>
                    <div>
                        <div>
                            <span>
                                <div>회원 : </div>
                                <div>
                                    <select onChange={this.changerUsers}>
                                        {options}
                                    </select>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div className='academy-wapper-c credit-default-resize'>
                {
                    this.state.isAjaxDone ?
                        this.createHeader()
                        : ''
                }
                {
                    this.state.isAjaxDone ?
                        this.createBody()
                        : ''
                }
            </div>
        )
    }

    render() {
        return (
            <div className='credit-history-wapper'>
                {this.createNav()}
                {this.createHtml()}
            </div>
        )
    }

}

export default withRouter(credit_history);