import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Pagination from "react-js-pagination";
import Marquee from "react-marquee";
import { Wave } from "react-animated-text";

import { TrasHome, TrasDashboard } from "../../../Translate/EachTranslateC";

import {
  GetAjaxURL,
  postConfig,
  copyToClipboard,
  clipMsgState,
  NoImgContens,
  getEnvironment,
} from "../../../config/AjaxConfig";
import axios from "../../../config/axios";
import queryString from "query-string";
import { showNotify, STATE_NOTIFY, moneyFormat } from "../../extra/cusUtil";

import "../credit/credit_common.css";
import "../tuition_requests/tuition_request_index.css";
import "./search.css";
import kakaoIcon from "../../../images/icon/kakao_icon_white.png";
import defaultTeacherImg from "../../../images/backuptutorpic.png";
import ibdpIcon from "../../../images/icon/ibdp_icon.png";
import gurumeLogoWhite from "../../../images/gurume_logo_white.png";

// import ReactBootstrapSlider from 'react-bootstrap-slider';
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Handle, Track, Tick } from "../../extra/Slider";
import {
  metaRegExp,
  metaClass,
  applyMetaHeader,
} from "../../extra/metaController";

/* image ratio on IE */
const $ = window.jQuery;
let t = $(".target");
let s = "url(" + t.attr("src") + ")";
let p = t.parent();
let d = $('<div class="ie-teacher-img-box"></div>');

t.hide();
p.append(d);

d.css({
  height: 300,
  "background-size": "cover",
  "background-repeat": "no-repeat",
  "background-position": "center",
  "background-image": s,
  border: "1px solid red",
});

const ReactSliderForSearchPagePropTypes = {
  changerMoney: PropTypes.func,
  moneyMin: PropTypes.number,
  moneyMax: PropTypes.number,
};
const ReactSliderForSearchPageDefaultProps = {
  changerMoney: () => {
    return console.log("");
  },
  moneyMin: 30000,
  moneyMax: 110000,
};

class ReactSliderForSearchPage extends Component {
  render() {
    const sliderStyle = {
      // Give the slider some width
      position: "relative",
      width: "100%",
      height: 80,
    };

    const railStyle = {
      position: "absolute",
      width: "100%",
      height: 10,
      marginTop: 35,
      borderRadius: 5,
      backgroundColor: "#8B9CB6",
    };

    return (
      <Slider
        rootStyle={sliderStyle}
        domain={[30000, 110000]}
        step={5000}
        mode={1}
        values={[Number(this.props.moneyMax)]}
        onChange={this.props.changerMoney}
      >
        <Rail>
          {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => {
                return (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    getHandleProps={getHandleProps}
                  />
                );
              })}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks values={[30000, 50000, 70000, 90000, 110000]}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((
                tick // get back ids, values and percents (to place them)
              ) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    );
  }
}
ReactSliderForSearchPage.propTypes = ReactSliderForSearchPagePropTypes;
ReactSliderForSearchPage.defaultProps = ReactSliderForSearchPageDefaultProps;

const TeacherSearchBoxPropTypes = {
  hasParam: PropTypes.bool,
  universitiesData: PropTypes.array.isRequired,
  curriculumsData: PropTypes.object.isRequired,
};
const TeacherSearchBoxDefaultProps = {
  hasParam: false,
  universitiesData: {},
  curriculumsData: {},
};
class TeacherSearchBox extends Component {
  state = {
    currentSelected: {
      id: "",
      CateA: "Test", // Test, Admission, English
      CateB: "IB",
      selectedUniversity: "0",
      selectedCurricul: [],
      isUndergraduate: true,
      isGraduate: true,
      isProfessional: true,
      selectedSex: "0",
      moneyMin: "30000",
      moneyMax: "110000",
    },
    allCurData: [],
  };

  componentDidMount = () => {

    const urlStr = window.location.hash;
    const parser = queryString.parse(urlStr);
    const curriculumsData = this.props.curriculumsData;
    const allCurData = [];
    const tempData = [];
    const CateAKeys = Object.keys(curriculumsData);

    for (let i = 0; i < CateAKeys.length; i++) {
      const CateBKeys = Object.keys(curriculumsData[CateAKeys[i]]);
      for (let k = 0; k < CateBKeys.length; k++) {
        const eachData = curriculumsData[CateAKeys[i]][CateBKeys[k]];
        for (let j = 0; j < eachData.length; j++) {
          let each = eachData[j];
          each["CateA"] = CateAKeys[i];
          each["CateB"] = CateBKeys[k];
          allCurData.push(each);
        }
      }
    }

    if (this.props.hasParam) {
      let isUndergraduate = false;
      let isGraduate = false;
      let isProfessional = false;
      if (parser.teacher_levels) {
        const tLevels = parser.teacher_levels.split("_");
        for (let i = 0; i < tLevels.length; i++) {
          if (tLevels[i] === "undergraduate") {
            isUndergraduate = true;
          }
          if (tLevels[i] === "graduate") {
            isGraduate = true;
          }
          if (tLevels[i] === "professional") {
            isProfessional = true;
          }
        }
      }

      const currentSelected = {
        id: "",
        CateA: "Test",
        CateB: "IB",
        selectedUniversity: parser.uid,
        selectedCurricul: parser.sid.split("_"),
        isUndergraduate: isUndergraduate,
        isGraduate: isGraduate,
        isProfessional: isProfessional,
        selectedSex: parser.gender,
        moneyMin: parser.money_min,
        moneyMax: parser.money_max,
      };
      this.setState(
        {
          ...this.state,
          currentSelected: currentSelected,
          allCurData: allCurData,
        },
        () => {
          const gender = document.getElementsByName("gender");
          for (let i = 0; i < gender.length; i++) {
            if (
              String(gender[i].value) === this.state.currentSelected.selectedSex
            ) {
              gender[i].checked = true;
            }
          }

          this.doClassSelect();
        }
      );
    } else {
      this.setState({
        ...this.state,
        allCurData: allCurData,
      });
    }
  };

  changerCateB = (e) => {
    const target = e.target;
    const value = target.value;

    this.setState(
      {
        ...this.state,
        currentSelected: {
          ...this.state.currentSelected,
          CateB: value,
        },
      },
      () => {
        this.doClassSelect();
      }
    );
  };

  changerCateA = (e) => {
    const target = e.target;
    const dataValue = target.dataset["value"];
    const allCurData = this.state.allCurData;

    let DefaultCateB = "";
    for (let i = 0; i < allCurData.length; i++) {
      if (allCurData[i]["CateA"] === dataValue) {
        DefaultCateB = allCurData[i]["CateB"];
        break;
      }
    }

    this.setState(
      {
        ...this.state,
        currentSelected: {
          ...this.state.currentSelected,
          CateA: dataValue,
          CateB: DefaultCateB,
        },
      },
      () => {
        this.doClassSelect();
      }
    );
  };

  changerUniversity = (e) => {
    const target = e.target;
    const value = target.value;

    if (String(value) === "-1") return;

    this.setState({
      ...this.state,
      currentSelected: {
        ...this.state.currentSelected,
        selectedUniversity: value,
      },
    });
  };

  changerIsUndergraduate = (e) => {
    const target = e.target;
    const isChecked = target.checked;
    this.setState({
      ...this.state,
      currentSelected: {
        ...this.state.currentSelected,
        isUndergraduate: isChecked,
      },
    });
  };
  changerIsGraduate = (e) => {
    const target = e.target;
    const isChecked = target.checked;
    this.setState({
      ...this.state,
      currentSelected: {
        ...this.state.currentSelected,
        isGraduate: isChecked,
      },
    });
  };
  changerIsProfessional = (e) => {
    const target = e.target;
    const isChecked = target.checked;
    this.setState({
      ...this.state,
      currentSelected: {
        ...this.state.currentSelected,
        isProfessional: isChecked,
      },
    });
  };

  changerSex = (e) => {
    const target = e.target;
    const value = String(target.value);

    this.setState({
      ...this.state,
      currentSelected: {
        ...this.state.currentSelected,
        selectedSex: value,
      },
    });
  };

  changerMoney = (value) => {
    this.setState({
      ...this.state,
      currentSelected: {
        ...this.state.currentSelected,
        // moneyMin : value[0],
        moneyMax: value[0],
      },
    });
  };

  doSearch = () => {
    const teacher_levels = [];
    if (this.state.currentSelected.isUndergraduate) {
      teacher_levels.push("undergraduate");
    }
    if (this.state.currentSelected.isGraduate) {
      teacher_levels.push("graduate");
    }
    if (this.state.currentSelected.isProfessional) {
      teacher_levels.push("professional");
    }

    const params = {
      sid: this.state.currentSelected.selectedCurricul.join("_"),
      uid: this.state.currentSelected.selectedUniversity,
      current_page: "1",
      money_min: "0", // 미니멈 값은 없다고 함.
      money_max: this.state.currentSelected.moneyMax,
      gender: this.state.currentSelected.selectedSex,
      teacher_levels: teacher_levels.join("_"),
    };
    const strParma = queryString.stringify(params);
    window.location.href = "/#/teachers#teacher-list-header" + "/?" + strParma;
    window.location.reload();
  };

  setSelectedCurricul = (e) => {
    const target = e.target;
    const dataId = String(target.dataset["key"]);
    const selectedCurricul = this.state.currentSelected.selectedCurricul;

    let newSelectedCur = [];
    if (!selectedCurricul.includes(dataId)) {
      selectedCurricul.push(dataId);
      newSelectedCur = selectedCurricul;
    } else {
      while (selectedCurricul.length > 0) {
        const each = selectedCurricul.shift();
        if (each !== dataId) {
          newSelectedCur.push(each);
        }
      }
    }

    this.setState(
      {
        ...this.state,
        currentSelected: {
          ...this.state.currentSelected,
          selectedCurricul: newSelectedCur,
        },
      },
      () => {
        this.doClassSelect();
      }
    );
  };

  doClassSelect = () => {
    const tutorRegUl = document.getElementById("tutorRegUl");
    for (let i = 0; i < tutorRegUl.childElementCount; i++) {
      const keys = String(tutorRegUl.children[i].dataset["key"]);
      tutorRegUl.children[i].classList.remove("active");
    }

    const selectedCuls = this.state.currentSelected.selectedCurricul;

    for (let k = 0; k < selectedCuls.length; k++) {
      for (let i = 0; i < tutorRegUl.childElementCount; i++) {
        const keys = String(tutorRegUl.children[i].dataset["key"]);
        const culData = selectedCuls[k];
        if (keys === culData) {
          tutorRegUl.children[i].classList.add("active");
        }
      }
    }
  };

  render() {
    const universitiesData = this.props.universitiesData;
    const universitiesOptions = [];
    for (let i = 0; i < universitiesData.length; i++) {
      universitiesOptions.push(
        <option key={"_i_" + i} value={universitiesData[i].id}>
          {" "}
          {universitiesData[i].university_name}{" "}
        </option>
      );
    }

    const allCurData = this.state.allCurData;

    const sortData = [];
    for (let i = 0; i < allCurData.length; i++) {
      const cateAName = allCurData[i]["CateA"];

      if (cateAName === this.state.currentSelected.CateA) {
        sortData.push(allCurData[i]);
      }
    }

    const CateBList = [];
    const displayCurObtions = [];
    for (let i = 0; i < sortData.length; i++) {
      if (this.state.currentSelected.CateB === sortData[i]["CateB"]) {
        displayCurObtions.push(
          <li
            key={"_d_" + i}
            className="tutorreg-subject-li"
            data-key={sortData[i]["id"]}
            onClick={this.setSelectedCurricul}
          >
            {sortData[i]["content"]}
          </li>
        );
      }

      if (!CateBList.includes(sortData[i]["CateB"])) {
        CateBList.push(sortData[i]["CateB"]);
      }
    }

    const CateBOptions = [];
    for (let i = 0; i < CateBList.length; i++) {
      CateBOptions.push(
        <option key={"cateB_" + i} value={CateBList[i]}>
          {CateBList[i]}
        </option>
      );
    }

    return (
      <div className="teacher-search-card">
        <h4>검색창</h4>
        <div className="teacher-search-card-box">
          <div className="search-major">
            <div className="search-major-box">
              <div className="search-major-btn">
                <div className="search-major-btn-wrap">
                  <button
                    className="btn test-btn base-btn"
                    data-value={"Test"}
                    onClick={this.changerCateA}
                  >
                    시험
                  </button>
                </div>
                <div className="search-major-btn-wrap">
                  <button
                    className="btn admission-btn base-btn"
                    data-value={"Admission"}
                    onClick={this.changerCateA}
                  >
                    입시
                  </button>
                </div>
                <div className="search-major-btn-wrap">
                  <button
                    className="btn english-btn base-btn"
                    data-value={"English"}
                    onClick={this.changerCateA}
                  >
                    영어
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="curr-select-area">
            <select
              id="selectMajor"
              className="form-control base-select"
              style={{ marginBottom: "7px" }}
              onChange={this.changerCateB}
            >
              {CateBOptions.length === 0 ? (
                <option>데이터를 불러오지 못했습니다.</option>
              ) : (
                CateBOptions
              )}
            </select>
            <div className="base-select">
              <div className="subject-select-wrapper half-height">
                <ul id="tutorRegUl" className="tutorreg-subject-ul">
                  {/* <li className="tutorreg-subject-li">
                                        A Level
                                    </li> */}
                  {displayCurObtions.length === 0 ? (
                    <li className="tutorreg-subject-li">
                      {" "}
                      데이터를 불러오지 못했습니다.
                    </li>
                  ) : (
                    displayCurObtions
                  )}
                </ul>
              </div>
            </div>
            <select
              className="form-control base-select"
              style={{ marginTop: "7px" }}
              onChange={this.changerUniversity}
              value={this.state.currentSelected.selectedUniversity}
            >
              {universitiesOptions.length === 0 ? (
                <option value="0">데이터를 불러오지 못했습니다.</option>
              ) : (
                <option value="0">희망 대학 선택</option>
              )}
              {universitiesOptions}
            </select>
          </div>

          <div className="search-form">
            {/* <div className="search-form-wrap">
                            <label htmlFor="level-undergraduate">
                                <input type="checkbox" value="1" id="level-undergraduate" onChange={this.changerIsUndergraduate} checked={this.state.currentSelected.isUndergraduate} />재학생</label>
                            <label htmlFor="level-graduate">
                                <input type="checkbox" value="2" id="level-graduate" onChange={this.changerIsGraduate} checked={this.state.currentSelected.isGraduate} />전문강사</label>
                            <label htmlFor="level-professional">
                                <input type="checkbox" value="3" id="level-professional" onChange={this.changerIsProfessional} checked={this.state.currentSelected.isProfessional} />그루미 강사진</label>
                        </div> */}

            <div className="search-form-wrap">
              <label className="radio-inline">
                <input
                  type="radio"
                  value="1"
                  name="gender"
                  onChange={this.changerSex}
                />
                남자
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  value="2"
                  name="gender"
                  onChange={this.changerSex}
                />
                여자
              </label>
              <label className="radio-inline">
                <input
                  type="radio"
                  value="0"
                  name="gender"
                  onChange={this.changerSex}
                  defaultChecked
                />
                미선택
              </label>
            </div>
          </div>
          <div className="search-rate padding-left-15 padding-right-15">
            <div>
              <ReactSliderForSearchPage
                changerMoney={this.changerMoney}
                moneyMin={Number(this.state.currentSelected.moneyMin)}
                moneyMax={Number(this.state.currentSelected.moneyMax)}
              />
            </div>
          </div>
          <div className="search-filter-btn">
            <button onClick={this.doSearch}>검색</button>
          </div>
        </div>

        {/* search help box */}
        {/* <div className="search-help-card">
                    <div className="search-help-card-box">
                        <div className="search-help-card-main">
                            <h4 style={{ color: 'white' }}>도움이 필요하신가요?</h4>
                            <ul className="no-list-style color-white text-left margin-top-20 margin-left-25p">
                                <li>
                                    <i className="fa fa-phone margin-right-5 color-white"></i>
                                    <a className="color-white" href="tel:070-4100-7221">070-4100-7221</a>
                                </li>
                                <li>
                                    <img className="margin-right-5" style={{ width: '14px', height: '14px' }}
                                        src={kakaoIcon} />
                                    <a className="color-white" href="http://goto.kakao.com/@gurume" target="_blank">gurume</a>
                                </li>
                                <li>
                                    <i className="fa fa-wechat margin-right-5 color-white"></i>
                                    ecubs919</li>
                            </ul>

                            <div className="search-help-teacher-wrapper" style={{ marginBottom: '-30px' }}>
                                <div className="search-help-teacher-box">
                                    <div className="search-help-teacher" id="search-manager-1" />
                                    <b>제스쌤</b>
                                </div>
                                <div className="search-help-teacher-box">
                                    <div className="search-help-teacher" id="search-manager-2" />
                                    <b>지미쌤</b>
                                </div>
                                <div className="search-help-teacher-box">
                                    <div className="search-help-teacher" id="search-manager-3" />
                                    <b>송이쌤</b>
                                </div>
                            </div>
                            <div className="search-help-caption">
                                <p style={{ paddingTop: '10px', textAlign: 'center' }}>선생님 찾으시는데 어려움이 있으시다면<br />
                                    그루미&nbsp;<a className="gurume-link" href="/#/contact_us">고객센터</a>로 연락주세요.</p>

                                <h5 className="text-center color-gurume-blue font-size-14">
                                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                                    10:00-19:00</h5>
                                <br />
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    );
  }
}
TeacherSearchBox.propTypes = TeacherSearchBoxPropTypes;
TeacherSearchBox.defaultProps = TeacherSearchBoxDefaultProps;

const TeacherProfileBoxPropTypes = {
  tdIsDemo: PropTypes.string,
  tdReviewCount: PropTypes.string,
  tdGoodPointCount: PropTypes.string,
  tdId: PropTypes.string,
  TdOt: PropTypes.string,
  visibility: PropTypes.string,
  tdPicture: PropTypes.string,
  tdIBDP: PropTypes.string,
  tdRespondT: PropTypes.string,
  tdName: PropTypes.string,
  tdUniversity: PropTypes.string,
  tdMajor: PropTypes.string,
  tdFullMajor: PropTypes.string,
  tdIntro: PropTypes.string,
  tdFlagstring: PropTypes.string,
};
const TeacherProfileBoxDefaultProps = {
  tdIsDemo: 0,
  tdReviewCount: 0,
  tdGoodPointCount: 0,
  tdId: "0",
  TdOt: "0",
  visibility: "0",
  tdPicture: "",
  tdIBDP: 0,
  tdRespondT: 0,
  tdName: "",
  tdUniversity: "",
  tdMajor: "",
  tdFullMajor: "",
  tdIntro: "",
  tdFlagstring: "",
};
class TeacherPremiumBox extends Component {
  moveToTdPage = () => {
    window.location.href = "/#/teachers/" + this.props.pId;
  };
  render() {
    const pReviewCount = this.props.pReviewCount;
    const pRecommendCount = this.props.pRecommendCount;
    return (
      /* new IBDP card desigan */
      <div className="teacher-premium-card-main">
        <div onClick={this.moveToTdPage}>
          <div className="teacher-profile-card-header">
            <div className="teacher-card-header-box">
              <div className="teacher-card-header-img">
                <div className="teacher-card-header-img-box border-blink">
                  <img
                    src={this.props.pPicture}
                    className="img-responsive"
                    onError={function (e) {
                      e.target.src = "../images/backuptutorpic.png";
                    }}
                  />
                </div>
              </div>
              <div className="teacher-card-header-info">
                <div className="teacher-card-header-info-box">
                  <div className="teacher-card-header-ibdp-1">
                    <span className="teacher-name">{this.props.pName}</span>
                    <br />
                    {/* IBDP Icon */}
                    {this.props.pIBDP ? (
                      <div className="teacher-card-header-ibdp">
                        <div className="teacher-card-header-ibdp-box">
                          <div className="teacher-card-header-ibdp-img">
                            <img src={ibdpIcon} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="teacher-university">
                    {this.props.pUniversity}
                  </span>
                  <br />
                  <span className="teacher-major color-gurume-gray">
                    {this.props.pMajor}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="teacher-profile-card-subject bg-premium-purple">
            {/* new teacher profile design */}
            {this.props.pFlagstring !== "" ? (
              <img className="search-marquee-img" src={gurumeLogoWhite} />
            ) : (
              ""
            )}
            <Marquee
              className="search-marquee"
              text={this.props.pCurriculum}
              trailing="0.3"
              leading="0.3"
            />
          </div>
          <div className="teacher-profile-card-content color-gurume-gray">
            {this.props.pIntro}
            {/* <div className="teacher-profile-card-time">
                            {
                                (this.props.tdRespondT == 0) ? <p><i className="fa fa-envelope"></i>&nbsp;보통 몇 시간 이내 응답</p> : ((this.props.tdRespondT > 0 && this.props.tdRespondT < 3) ? <p><i className="fa fa-envelope"></i>&nbsp;보통 몇 일 이내 응답</p> : ((this.props.tdRespondT >= 3 && this.props.tdRespondT <= 7)) ? <p><i className="fa fa-envelope"></i>&nbsp;보통 일주일 이내 응답</p> : "")

                            }
                            </div> */}
            {/* {
                            this.props.visibility === 0 ?
                                <div className="teacher-profile-card-hidden">
                                    당분간 수업을 받지 않습니다.
                                </div>
                                : ''
                        } */}
          </div>
          {/* average reply time */}
        </div>
        <div className="teacher-profile-card-footer">
          <div className="teacher-profile-footer-info">
            <p>
              {this.props.pHasDemo ? (
                <span>
                  <i
                    className="fa fa-youtube-play padding-right-3"
                    style={{ color: "red" }}
                  ></i>
                  데모
                </span>
              ) : (
                ""
              )}
              {this.props.pRecommendCount > 0 ? (
                <span>
                  <i className="fa fa-thumbs-o-up padding-right-3"></i>
                  {pRecommendCount} 추천
                </span>
              ) : (
                ""
              )}
              {this.props.pReviewCount ? (
                <span>
                  <i className="fa fa-comments-o padding-right-3"></i>
                  {pReviewCount} 후기
                </span>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

class TeacherProfileBox extends Component {
  moveToTdPage = () => {
    window.location.href = "/#/teachers/" + this.props.tdId;
  };
  render() {
    const tdReviewCount = this.props.tdReviewCount;
    const tdGoodPointCount = this.props.tdGoodPointCount;
    return (
      /* new IBDP card desigan */
      <div className="teacher-profile-card-main">
        <div onClick={this.moveToTdPage}>
          <div className="teacher-profile-card-header">
            <div className="teacher-card-header-box">
              <div className="teacher-card-header-img">
                <div className="teacher-card-header-img-box">
                  <img
                    src={this.props.tdPicture}
                    className="img-responsive"
                    onError={function (e) {
                      e.target.src = "../images/backuptutorpic.png";
                    }}
                  />
                </div>
              </div>
              <div className="teacher-card-header-info">
                <div className="teacher-card-header-info-box">
                  <div className="teacher-card-header-ibdp-1">
                    <span className="teacher-name">{this.props.tdName}</span>
                    <br />
                    {/* IBDP Icon */}
                    {this.props.tdIBDP ? (
                      <div className="teacher-card-header-ibdp">
                        <div className="teacher-card-header-ibdp-box">
                          <div className="teacher-card-header-ibdp-img">
                            <img src={ibdpIcon} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="teacher-university">
                    {this.props.tdUniversity}
                  </span>
                  <br />
                  <span className="teacher-major color-gurume-gray">
                    {this.props.tdMajor}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="teacher-profile-card-subject bg-gurume-blue">
            {/* new teacher profile design */}
            {this.props.tdFlagstring !== "" ? (
              <img className="search-marquee-img" src={gurumeLogoWhite} />
            ) : (
              ""
            )}
            <Marquee
              className="search-marquee"
              text={this.props.tdFullMajor}
              trailing="0.3"
              leading="0.3"
            />
          </div>
          <div className="teacher-profile-card-content color-gurume-gray">
            {this.props.tdIntro}
            <div className="teacher-profile-card-time">
              {this.props.tdRespondT == 0 ? (
                <p>
                  <i className="fa fa-envelope"></i>&nbsp;보통 몇 시간 이내 응답
                </p>
              ) : this.props.tdRespondT > 0 && this.props.tdRespondT < 3 ? (
                <p>
                  <i className="fa fa-envelope"></i>&nbsp;보통 몇 일 이내 응답
                </p>
              ) : this.props.tdRespondT >= 3 && this.props.tdRespondT <= 7 ? (
                <p>
                  <i className="fa fa-envelope"></i>&nbsp;보통 일주일 이내 응답
                </p>
              ) : (
                <p>
                  <i className="fa fa-envelope"></i>&nbsp;응답까지 시간이 좀
                  걸려요.
                </p>
              )}
            </div>
            {this.props.visibility === 0 ? (
              <div className="teacher-profile-card-hidden">
                당분간 수업을 받지 않습니다.
              </div>
            ) : (
              ""
            )}
          </div>
          {/* average reply time */}
        </div>
        <div className="teacher-profile-card-footer">
          <div className="teacher-profile-footer-info">
            <p>
              {this.props.tdHasDemo.length !== 0 ? (
                <span>
                  <i
                    className="fa fa-youtube-play padding-right-3"
                    style={{ color: "red" }}
                  ></i>
                  데모
                </span>
              ) : (
                ""
              )}
              {this.props.tdReviewCount !== 0 ? (
                <span>
                  <i className="fa fa-thumbs-o-up padding-right-3"></i>
                  {tdReviewCount} 추천
                </span>
              ) : (
                ""
              )}
              {this.props.tdGoodPointCount !== 0 ? (
                <span>
                  <i className="fa fa-comments-o padding-right-3"></i>
                  {tdGoodPointCount} 후기
                </span>
              ) : (
                ""
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

TeacherProfileBox.propTypes = TeacherProfileBoxPropTypes;
TeacherProfileBox.defaultProps = TeacherProfileBoxDefaultProps;

class Search extends Component {
  state = {
    isLoading: false,
    hasParam: false,
    userType: "",

    urlPaser: {},

    currentPage: 1, // DEFAULT 1
    totalCount: 0,
    currentPrfData: [],
    universitiesData: [],
    curriculumsData: [],

    PAGE_NATION_STEP: {
      STEP_A: 1,
      STEP_B: 2,
      STEP_C: 3,
    },
    isAjaxDone: false,
    tId: 1,
    temp_tutors: [],
    favorite_tutors: [],
    isFavorite: false,
  };

  componentDidMount = async () => {
    const urlStr_complete = window.location.hash;
    const urlStr = urlStr_complete.substring(urlStr_complete.indexOf("?") + 1);
    const parser = queryString.parse(urlStr);
    const allUrls = await GetAjaxURL();
    const get_search_api = allUrls.apiTeacherSearch;
    const res = await axios.get(get_search_api).catch((err) => {
      return console.log(err);
    });

    if (String(res.status) === "200") {
      const universitiesData = res.data.universities;
      const curriculumsData = res.data.curriculums;
      this.setState(
        {
          ...this.state,
          urlPaser: parser,
          universitiesData: universitiesData,
          curriculumsData: curriculumsData,
          userType: res.data.isLogin.type,
          currentPage: parser.current_page || 1,
        },
        () => {
          this.doInit();
        }
      );
    }
  };
  doInit = async () => {
    await this.getTutorProfilesRequest();
    this.setMetas();
  };
  setMetas = () => {
    const currentPrfData = this.state.currentPrfData;

    const _type = "";
    let _title = "나에게 맞는 선생님을 찾아보세요.";
    const _url = window.location.href.replace("#teacher-list-header", "");

    const tempArr = [];
    const tempFlag = [];
    for (let i = 0; i < currentPrfData.length; i++) {
      const user_profile = currentPrfData[i].user_profile;
      tempArr.push(user_profile.last_name + user_profile.first_name);
      if (user_profile.flagstring) {
        if (
          user_profile.flagstring !== "" &&
          user_profile.flagstring !== undefined
        ) {
          tempFlag.push(user_profile.flagstring);
        }
      }
    }
    let _description = tempFlag.join(", ");
    const _imageUrl = "base";
    const _keywords = tempArr.join(", ");
    const newMetaClass = new metaClass(
      _type,
      _title,
      _url,
      _description,
      _imageUrl,
      _keywords
    );
    applyMetaHeader(newMetaClass);
  };
  getTutorProfilesRequest = async (e) => {
    this.setState({
      ...this.state,
      isLoading: true,
    });
    let target;
    let pageNum = this.state.currentPage;
    if (e) {
      target = e.target;
      pageNum = target.dataset.pageNum; // data-page-num
    }
    const urlPaser = this.state.urlPaser;
    const currentPage = pageNum || 1;
    const getData = {
      params: {
        page: currentPage,
        subjects: urlPaser.sid,
        teachingstyles: "0",
        money_min: urlPaser.money_min,
        money_max: urlPaser.money_max,
        universityid: urlPaser.uid,
        teacher_levels: urlPaser.teacher_levels,
        gender: urlPaser.gender,
      },
    };
    const urlPaserKey = Object.keys(urlPaser);
    const hasParam = urlPaserKey.length > 2 ? true : false;

    const allUrls = await GetAjaxURL();
    const tutorProfilesURL = allUrls.tutorProfilesURL;
    const tutorProfilesRes = await axios
      .get(tutorProfilesURL, getData)
      .catch((err) => {
        return console.log(err);
      });

    const tutorPrData = tutorProfilesRes.data;
    const currentPrfData = tutorPrData.data;
    let tempTutorArr = [];
    for (let i = 0; i < currentPrfData.length; i++) {
      const tutor_profile = currentPrfData[i].tutor_profile;
      const tId = tutor_profile.id;

      tempTutorArr.push(tId);
      this.setState({
        temp_tutors: tempTutorArr,
        tId: tId,
      });
    }
    const totalCount = tutorPrData.total;

    this.setState({
      ...this.state,
      isLoading: false,
      hasParam: hasParam,
      currentPrfData: currentPrfData,
      totalCount: totalCount,
      isAjaxDone: true,
    });
    // () => {
    //     const pagination = document.getElementsByClassName('pagination')[0]
    //     for (let i = 0; i < pagination.childElementCount; i++) {
    //         const child = pagination.children[i];
    //         const childData = child.innerText
    //         if (childData === String(this.state.currentPage)) {
    //             child.classList.add('active');
    //         }else{
    //             child.classList.remove('active')
    //         }
    //     }
  };
  goToAcademy = () => {
    window.open("http://ibthetop.com");
  };
  goToNewTuitionRequest = () => {
    window.location.href = "/#/tuition_request/new";
  };
  goToTeachesrPerPage = (pageNumber) => {
    let currentHref = window.location.href;
    let newHref;
    if (currentHref.search("current_page") != -1) {
      newHref = currentHref.replace(
        /(current_page=).*?(&)/,
        "current_page=" + pageNumber + "&"
      );
    } else {
      if (currentHref.search("\\?") != -1) {
        newHref = currentHref + "current_page=" + pageNumber + "&";
      } else {
        newHref = currentHref + "?current_page=" + pageNumber + "&";
      }
    }
    if (window.history.replaceState) {
      window.history.replaceState(this.state, "", newHref);
    }
    this.setState(
      {
        ...this.state,
        currentPage: pageNumber,
      },
      () => {
        this.getTutorProfilesRequest();
      }
    );
  };

  createHtmlPagination = () => {
    const totalData = this.state.totalCount;
    const currentPage = this.state.currentPage;
    return (
      <div>
        <Pagination
          hideFirstLastPages
          activePage={currentPage}
          itemsCountPerPage={5}
          pageRangeDisplayed={7}
          totalItemsCount={totalData}
          onChange={(pageNumber) => this.goToTeachesrPerPage(pageNumber)}
          innerClass="pagination justify-content-center"
          itemClass="page-item"
          activeClass="active"
          linkClass="page-link"
        />
      </div>
    );
  };
  goToLink = () => {
    window.open("http://pf.kakao.com/_ddvDV/chat");
  };
  goToLink1 = () => {
    window.open(
      "https://www.aladin.co.kr/shop/wproduct.aspx?start=short&ItemId=269866535"
    );
  };
  goToTutorhive = () => {
    window.location.href="http://tutorhive.kr/"
  }
  render() {
    window.location.href="https://www.tutorhive.kr/tutors"

    const parseLocation = this.props.location;
    const locationHash = parseLocation.hash;
    const locationPath = parseLocation.pathname;
    const locationSearch = parseLocation.search;
    const locationState = parseLocation.state;

    const currentPrfData = this.state.currentPrfData;
    const tdComponents = [];
    for (let i = 0; i < currentPrfData.length; i++) {
      const tutor_profile = currentPrfData[i].tutor_profile;
      const curriculums = currentPrfData[i].curriculums;
      const major = currentPrfData[i].major;
      const user_profile = currentPrfData[i].user_profile;

      const tdIsDemo = tutor_profile.free_demo;
      const tdReviewCount = tutor_profile.all_review_count;
      const tdGoodPointCount = tutor_profile.viewable_review_count;
      const tdFlagstring = user_profile.flagstring;

      const tdHasDemo = tutor_profile.video_youtube_1;
      const TdOt = tutor_profile.ot;
      const tdId = tutor_profile.id;
      const visibility = tutor_profile.visibility;

      const tdPicture = tutor_profile.picture;
      const tdIBDP = tutor_profile.ibdp;
      const tdRespondT = tutor_profile.respond_time;
      const tdName = user_profile.last_name + "" + user_profile.first_name;

      let tdUniversity = "";
      if (tutor_profile.undergraduate_school) {
        if (tutor_profile.undergraduate_school.university_name) {
          tdUniversity = tutor_profile.undergraduate_school.university_name;
        }
      }
      let tdMajor = major[0];
      let tdFullMajor = "";
      let tdIntro = tutor_profile.about_myself;

      for (let k = 0; k < curriculums.length; k++) {
        tdFullMajor += curriculums[k].curriculum_name + ", ";
      }
      tdFullMajor = tdFullMajor.substr(0, tdFullMajor.length - 2);

      if (tdIntro.length > 45) {
        tdIntro = tdIntro.substr(0, 45) + "...";
      }

      if (tdMajor) {
        if (tdMajor.length > 15) {
          tdMajor = tdMajor.substr(0, 15) + "...";
        }
      }

      tdComponents.push(
        <TeacherProfileBox
          key={"_key_" + i}
          tdIsDemo={tdIsDemo}
          tdReviewCount={tdReviewCount}
          tdGoodPointCount={tdGoodPointCount}
          TdOt={String(TdOt)}
          tdId={String(tdId)}
          visibility={visibility}
          tdPicture={tdPicture}
          tdIBDP={tdIBDP}
          tdRespondT={tdRespondT}
          tdName={tdName}
          tdUniversity={tdUniversity}
          tdMajor={tdMajor}
          tdFullMajor={tdFullMajor}
          tdIntro={tdIntro}
          tdFlagstring={tdFlagstring}
          tdHasDemo={tdHasDemo}
          isFavorite={this.state.isFavorite}
          favorite_tutors={this.state.favorite_tutors}
        />
      );
    }
    return (
      <React.Fragment>
        <div className="search-wrapper">
          <div className="search-banner">
            <div className="search-banner-box">
              <div className="search-banner-main">
                <h1 className="boldest-font font-size-34">
                  전 세계 900명 이상의 과외 선생님과 원하는 맞춤형 수업을
                  함께하세요
                </h1>
                <button className="gurume-white-btn" onClick={this.goToAcademy}>
                  학원 강사진
                </button>
                <br />
                {this.state.userType === "student" ? (
                  <button
                    className="gurume-white-btn"
                    onClick={this.goToNewTuitionRequest}
                  >
                    새 수업요청 등록하기
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="color-overlay"></div>
            </div>
          </div>
          <div className="search-contents">
            <div className="search-contents-box">
              <div className="search-section">
                {this.state.isAjaxDone ? (
                  <TeacherSearchBox
                    universitiesData={this.state.universitiesData}
                    curriculumsData={this.state.curriculumsData}
                    hasParam={this.state.hasParam}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="profile-section">
                {/* <div className="teacher-premium-card">
                                    <div className="premium-title">
                                        <Wave text="이 달의 선생님" effect="stretch" speed={3} effectChange={2.0} />
                                    </div>
                                    <div className="teacher-premium-card-box">
                                        <TeacherPremiumBox
                                            pId="4195"
                                            pPicture={defaultTeacherImg}
                                            pName="박민우"
                                            pIBDP={false}
                                            pUniversity="UC Berkeley"
                                            pMajor="응용수학"
                                            pFlagstring=""
                                            pCurriculum="미국계 고학년, AP, SAT Subject, SAT"
                                            pIntro="안녕하세요. AP 수학, SAT 수학을 전문으로 하고 있는 박민우 선생님 입니다...."
                                            pHasDemo={false}
                                            pRecommendCount={6}
                                            pReviewCount="1"
                                        />
                                        <TeacherPremiumBox
                                            pId="3828"
                                            pPicture="https://s3.ap-northeast-2.amazonaws.com/gurume-tutor/anmiPRw9DZO4ZcbA2C52.png"
                                            pName="김예지"
                                            pIBDP={false}
                                            pUniversity="UCL"
                                            pMajor="Chemistry with..."
                                            pFlagstring=""
                                            pCurriculum="(i)GCSE, A Level, IB, 영국대학입학시험, Olympiad/Competition"
                                            pIntro="안녕하세요, 현재 UCL에서 Chemistry with Mathematics를 이..."
                                            pHasDemo={false}
                                            pRecommendCount=""
                                            pReviewCount=""
                                        />
                                        <TeacherPremiumBox
                                            pId="3272"
                                            pPicture="https://s3.ap-northeast-2.amazonaws.com/gurume-tutor/h3xFJ93Rn6cj81mkHwdt.png"
                                            pName="윤재영"
                                            pIBDP={false}
                                            pUniversity="Purdue University"
                                            pMajor="Mechanical Engi..."
                                            pFlagstring=""
                                            pCurriculum="(i)GCSE, AP, Pre-U, ACT, SAT Subject, SAT, Undergraduate"
                                            pIntro="안녕하세요, Purdue University에서 Mechanical Enginee..."
                                            pHasDemo={true}
                                            pRecommendCount={17}
                                            pReviewCount="8"
                                        />
                                        <TeacherPremiumBox
                                            pId="5816"
                                            pPicture="https://s3.ap-northeast-2.amazonaws.com/gurume-tutor/ct9Rdut0EPpxQPWftqC5.png"
                                            pName="허지나"
                                            pIBDP={false}
                                            pUniversity="The Chinese Uni..."
                                            pMajor="Global Economic..."
                                            pFlagstring=""
                                            pCurriculum="미국계 고학년, Master, Undergraduate, 석.박사, 커리어, Essay/Debate, Grammar, Reading, Coding/Programming"
                                            pIntro="홍콩 중문대에서 4년 장학금으로 Global Economics & Finance..."
                                            pHasDemo={false}
                                            pRecommendCount=""
                                            pReviewCount=""
                                        />
                                    </div>
                                </div> */}
                <div className="teacher-profile-card">
                  <div className="teacher-profile-card-box">
                    {this.state.isLoading ? (
                      <div className="teacher-search-loading">
                        <div className="loader"></div>
                      </div>
                    ) : tdComponents.length === 0 ? (
                      ""
                    ) : (
                      tdComponents
                    )}
                  </div>
                  <div>{this.createHtmlPagination()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Search);
