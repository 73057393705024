import React, { Component } from "react";
import { Route, useParams } from "react-router-dom";
import { Switch } from "react-router";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AuthService from "../modules/AuthService";
import * as RootBindingData from "../modules/RootBindingData";

import JsxParser from "react-jsx-parser";

import Main from "../components/bodies/Main";
import contact_us from "../components/bodies/contact_us";

// should be fixed. css a a little.
import career from "../components/bodies/career";
import academy from "../components/bodies/academy";
import events from "../components/bodies/events";
import admissions_consulting from "../components/bodies/admissions_consulting";
import admissions_consulting_us from "../components/bodies/admissions_consulting_us";
import admissions_consulting_uk from "../components/bodies/admissions_consulting_uk";
import admissions_consulting_korea from "../components/bodies/admissions_consulting_korea";

import Winter_vacation_workshop from "../components/bodies/academy/winter_vacation_workshop";
import Summer_vacation_workshop from "../components/bodies/academy/summer_vacation_workshop";
import Recruit from "../components/bodies/academy/recruit";
import mooc_preview from "../components/bodies/mooc/mooc_preview";
import mooc_logistics from "../components/bodies/mooc/mooc_logistics";

import online_tuition from "../components/bodies/online_tuition";
import Online_tuition_touchpad from "../components/bodies/online_tuition_touchpad";

import Demo_class_video from "../components/bodies/demo_class_video";

///////////////users
import login from "../components/bodies/users/login";
import forgotpassword from "../components/bodies/users/forgotpassword";
import accountactivation from "../components/bodies/users/accountactivation";
import resetpassword from "../components/bodies/users/resetpassword";
import useraccountsetting from "../components/bodies/users/useraccountsetting";
import userreg from "../components/bodies/users/userreg";

// teachers
import Tutorreg from "../components/bodies/teachers/Tutorreg";
import Editteacherprofile from "../components/bodies/teachers/Editteacherprofile";
import Teacherprofile from "../components/bodies/teachers/teacherprofile";
import Tdashboard from "../components/bodies/teachers/tdashboard";
import Search from "../components/bodies/teachers/search";
import OT from "../components/bodies/teachers/TeachersOT";

// students
import sdashboard from "../components/bodies/students/sdashboard";
import studentreg from "../components/bodies/students/studentreg";
import Editstudentprofile from "../components/bodies/students/studentreg";
import Studentprofile from "../components/bodies/students/studentprofile";

// import reviews_new from '../components/bodies/reviews/reviews_new';
import NewReviews_new from "../components/bodies/reviews/newReviews_new";

// tuitions_bill
// import tuitions_bill_edit from '../components/bodies/tuitions/tuitions_bill_edit';       // bill + edit the same page
import Tuitions_confirm from "../components/bodies/tuitions/tuitions_confirm";
import Tuitions_bill from "../components/bodies/tuitions/tuitions_bill";
import tuition_invoice from "../components/bodies/tuitions/tuition_invoice";
import siteusage from "../components/bodies/siteusage";

// tuition_request_index
import tuition_request_index from "../components/bodies/tuition_requests/tuition_request_index";
import Tuition_request_new from "../components/bodies/tuition_requests/tuition_request_new";
import Tuition_request_show from "../components/bodies/tuition_requests/tuition_request_show";

// credit
import credit_balance from "../components/bodies/credit/credit_balance";
import credit_withdraw from "../components/bodies/credit/credit_withdraw";
import credit_buy from "../components/bodies/credit/credit_buy";
import credit_approve from "../components/bodies/credit/credit_approve";
import credit_history from "../components/bodies/credit/credit_history";

import how_it_works_mooc from "../components/bodies/how_it_works/how_it_works_mooc";

// how_it_work
import how_it_works_tuition from "../components/bodies/how_it_works/how_it_works_tuition";
import native_tutors from "../components/bodies/native_tutors";
import studyroom from "../components/bodies/studyroom";

// mooc
import Moocshow from "../components/bodies/mooc/moocshow";
import Moocedit from "../components/bodies/mooc/moocedit";
import moocnew from "../components/bodies/mooc/moocedit";
import moocpay from "../components/bodies/mooc/moocpay";

// blog
import blogindex from "../components/bodies/blogs/blogindex";
import Blogshow from "../components/bodies/blogs/blogshow";
import Blogedit from "../components/bodies/blogs/Blogedit";
import blognew from "../components/bodies/blogs/Blogedit";
import bloginvitation from "../components/bodies/blogs/bloginvitation";

// messages
import messages from "../components/bodies/messages/messages";
import MessagesItem from "../components/bodies/messages/messagesItem";

// groupclass
import group_class_index from "../components/bodies/groupclass/groupclassindex";
import group_class_index_list from "../components/bodies/groupclass/groupclasslist";
import Group_class_show from "../components/bodies/groupclass/groupclassshow";
import group_class_new from "../components/bodies/groupclass/groupclassedit";
import Group_class_edit from "../components/bodies/groupclass/groupclassedit";
import group_class_logistics from "../components/bodies/groupclass/groupclasslogistics";

import index from "../components/extra/index";
import error from "../components/bodies/error/error";
import about_gurume from "../components/bodies/about_gurume";
import recruiting from "../components/bodies/recruiting";
import moocindex from "../components/bodies/mooc/moocindex";

// import TestRout from '../components/utils/TestRout';

class RootRoute extends Component {
  state = {
    currentUrlAndComponent: {
      name: "",
      url: "",
      validation: false,
    },
    routeType: "TYPE_A", // matching with app.js
    ROUTE_TYPES: {
      TYPE_A: "TYPE_A",
      TYPE_B: "TYPE_B",
    },
  };

  shouldComponentUpdate = (nextprops, nextstate) => {
    if (
      nextprops.currentUrlAndComponent.url != "" &&
      nextprops.currentUrlAndComponent.url ==
        this.props.currentUrlAndComponent.url &&
      nextprops.location.pathname == this.props.location.pathname &&
      nextprops.location.search == this.props.location.search
    ) {
      return false;
    }
    return true;
  };

  render() {
    return (
      <div>
        {
          // default type
          this.props.currentUrlAndComponent.routeType ===
          this.state.ROUTE_TYPES.TYPE_A ? (
            <JsxParser
              components={{
                Main,
                index,
                login,
                forgotpassword,
                accountactivation,
                resetpassword,
                useraccountsetting,
                userreg,
                admissions_consulting_us,

                bloginvitation,
                tuition_request_index,
                online_tuition,
                Online_tuition_touchpad,
                Demo_class_video,

                career,
                academy,
                events,
                admissions_consulting,
                admissions_consulting_uk,
                admissions_consulting_korea,
                mooc_preview,
                contact_us,

                credit_balance,
                credit_withdraw,
                credit_buy,
                credit_approve,
                credit_history,

                tuition_invoice,

                sdashboard,
                studentreg,
                how_it_works_tuition,
                how_it_works_mooc,
                siteusage,
                moocindex,
                moocnew,
                moocpay,

                blogindex,
                blognew,
                messages,
                group_class_index,
                group_class_index_list,
                group_class_new,
                group_class_logistics,

                about_gurume,
                recruiting,
                mooc_logistics,
                native_tutors,
                studyroom,
                Search,
                error,
              }}
              jsx={`<${this.props.currentUrlAndComponent.name} />`}
            />
          ) : this.props.currentUrlAndComponent.routeType ===
            this.state.ROUTE_TYPES.TYPE_B ? (
            <Switch>
              {/* THIS WILL BE TEACHER EDIT PAGE */}
              <Route
                exact
                path="/teachers"
                component={() => {
                  window.location.href = "https://www.tutorhive.kr/tutors";
                  return null;
                }}
              />
              <Route
                exact
                path="/teachers/:teacherId/edit"
                component={() => {
                  return (
                    <Editteacherprofile
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              {/* THIS IS FROM MAILING LINK */}
              <Route
                exact
                path="/teachers/register/"
                component={() => {
                  return (
                    <Tutorreg RootBindingData={this.props.RootBindingData} />
                  );
                }}
              />

              <Route
                exact
                path="/teachers/:tutorId"
                component={({ match }) => {
                  console.log(match.params);
                  window.location.href =
                    "https://www.tutorhive.kr/tutors/" + match.params.tutorId;
                  return null;
                }}
              />
              {/* Teacher Dashboard */}
              <Route
                exact
                path="/dashboard"
                component={() => {
                  return (
                    <Tdashboard RootBindingData={this.props.RootBindingData} />
                  );
                }}
              />

              <Route
                exact
                path="/ot"
                component={() => {
                  return <OT RootBindingData={this.props.RootBindingData} />;
                }}
              />

              <Route
                exact
                path="/students/:sid/edit"
                component={() => {
                  return (
                    <Editstudentprofile
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              <Route
                path="/students/:studentId"
                component={({ match }) => {
                  window.location.href =
                    "https://www.tutorhive.kr/students/" +
                    match.params.studentId;
                  return null;
                }}
              />

              <Route
                exact
                path="/reviews/new/:ordernum"
                component={() => {
                  return (
                    <NewReviews_new
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              {/* Original routes for mooc system */}
              <Route exact path="/mooc" component={moocindex} />

              <Route
                exact
                path="/mooc/:moocid"
                component={() => {
                  return (
                    <Moocshow RootBindingData={this.props.RootBindingData} />
                  );
                }}
              />
              <Route
                exact
                path="/mooc/:moocid/edit"
                component={() => {
                  return (
                    <Moocedit RootBindingData={this.props.RootBindingData} />
                  );
                }}
              />
              <Route exact path="/mooc/:moocid/pay" component={moocpay} />

              <Route
                exact
                path="/messages/:msgid"
                component={() => {
                  return (
                    <MessagesItem
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/blogs/:blogid/edit"
                component={() => {
                  return (
                    <Blogedit RootBindingData={this.props.RootBindingData} />
                  );
                }}
              />
              <Route
                exact
                path="/blogs/:blogid"
                component={() => {
                  return (
                    <Blogshow RootBindingData={this.props.RootBindingData} />
                  );
                }}
              />

              {/* Group Classes */}
              <Route
                exact
                path="/group_class/:gid/edit"
                component={() => {
                  return (
                    <Group_class_edit
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/group_class/:gid"
                component={() => {
                  return (
                    <Group_class_show
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              {/* Tuitions Parts */}
              {/* <Route
                exact
                path="/tuitions/bill"
                component={() => {
                  return (
                    <Tuitions_bill
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              <Route
                path="/tuitions/adminbill/:teacherid"
                component={() => {
                  return (
                    <Tuitions_bill
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/tuitions/bill/edit/:ordernum"
                component={() => {
                  return (
                    <Tuitions_bill
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              <Route
                path="/tuitions/copy/:ordernum"
                component={() => {
                  return (
                    <Tuitions_bill
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />
*/}
              <Route
                path="/tuitions/confirm/:ordernum"
                component={() => {
                  return (
                    <Tuitions_confirm
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              {/* TUITION REQUESTS */}
              {/* <Route
                path="/tuition_request/new"
                component={() => {
                  return (
                    <Tuition_request_new
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/tuition_requests/:rid"
                component={() => {
                  return (
                    <Tuition_request_show
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/tuition_request/:rid/edit"
                component={() => {
                  return (
                    <Tuition_request_new
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              /> */}

              <Route
                exact
                path="/academy/recruit"
                component={() => {
                  return (
                    <Recruit RootBindingData={this.props.RootBindingData} />
                  );
                }}
              />
              {/* <Route exact path='/online_tuition_tochpad' component={online_tuition_touchpad} /> */}
              <Route
                exact
                path="/online_tuition/touchpad"
                component={() => {
                  return (
                    <Online_tuition_touchpad
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/demo_class_video"
                component={() => {
                  return (
                    <Demo_class_video
                      RootBindingData={this.props.RootBindingData}
                    />
                  );
                }}
              />

              <Route
                exact
                path="/"
                component={() => {
                  window.location.href = "/#/mooc";
                  return null;
                }}
              />
            </Switch>
          ) : (
            ""
          )
        }
      </div>
    );
  }
}

let mapStateToProps = (state) => {
  return {
    authMsg: state.AuthService.authMsg,
    R_isLoading: state.RootBindingData.R_isLoading,
  };
};

let mapDispatchToProps = (dispatch) => {
  return {
    AuthService: bindActionCreators(AuthService, dispatch),
    RootBindingData: bindActionCreators(RootBindingData, dispatch),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RootRoute)
);

// export default withRouter(RootRoute);
