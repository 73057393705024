

export const INFI_STATE = {
    TOTAL_DATA : 'totalData',
    CURRENT_INDEX : 'currentIndex',
    START_COUNT : 30,
    REPEAT_COUNT : 10,
};


export function InfinityController(){
    this.currentState = {
        totalData : [],
        currentIndex : 0,
        isLoading : false,
    };
    this.setInit = function(_totalData){
        if(_totalData){
            this.currentState.totalData = _totalData;
            this.currentState.isLoading = true;
        }else{
        }
    }
    this.getDataWithCount = function(_callingCount){
        if(!this.currentState.isLoading) return;

        let currentIndex = this.currentState.currentIndex + _callingCount;
        const totalCount = this.currentState.totalData.length;

        if(currentIndex > totalCount){
            currentIndex = totalCount;
        }

        if(currentIndex === totalCount){
            this.currentState.currentIndex = currentIndex;
            return this.currentState.totalData;
        }
        const tmpData = [];
        for(let i = 0; i < currentIndex; i++){
            tmpData.push(this.currentState.totalData[i]);
        }
        this.currentState.currentIndex = currentIndex;
        this.currentState.isLoading = false;
        return tmpData;
    };
    this.scrollDetect = function(_domWapperId, _domScrollViewId){
        const domWapperId = document.getElementById(_domWapperId);
        const domScrollViewId = document.getElementById(_domScrollViewId);
        if(!domWapperId) return console.log("Not Setting : domWapperId");
        if(!domScrollViewId) return console.log("Not Setting : domScrollViewId");

        const sHeight = domScrollViewId.clientHeight - domWapperId.clientHeight;
        const currentScrollPosition = domWapperId.scrollTop;

        let isScollDone = false;
        if(sHeight-currentScrollPosition <= 0){
            // Showing Loading Action Here
            isScollDone = true;
        }

        if(this.currentState.totalData.length === this.currentState.currentIndex){
            this.finishLoading(_domWapperId, _domScrollViewId);
        }else{
            this.currentState.isLoading = isScollDone;
            this.createLoading(_domWapperId, _domScrollViewId);
        }
        return isScollDone;
    }
    this.finishLoading = function(_domWapperId, _domScrollViewId){

        const domScrollViewId = document.getElementById(_domScrollViewId);
        const domWapperId = domScrollViewId.parentNode;

        let findDom = '';
        for(let i = 0; i < domWapperId.childElementCount; i++){
            const child = domWapperId.children[i];
            for(let k = 0; k < child.classList.length; k++){
                if(child.classList[k] === _domScrollViewId+'-infi-loading'){
                    findDom = child;
                }
            }
        }

        if(!findDom) return;
        if(findDom.childElementCount !== 0){
            findDom.removeChild(findDom.children[0]);
        }
    }
    this.createLoading = function(_domWapperId, _domScrollViewId){
        const domScrollViewId = document.getElementById(_domScrollViewId);
        if(!domScrollViewId) return;
        const domWapperId = domScrollViewId.parentNode;

        let checker = false;
        for(let i = 0; i < domWapperId.childElementCount; i++){
            const child = domWapperId.children[i];
            for(let k = 0; k < child.classList.length; k++){
                if(child.classList[k] === _domScrollViewId+'-infi-loading'){
                    checker = true;
                }
            }
        }
        if(checker) return;

        let divDom = document.createElement("DIV");
        let loadingDom = document.createElement("DIV");
        divDom.className = _domScrollViewId+'-infi-loading';
        loadingDom.className = 'loader';
        loadingDom.style.margin = '0 auto';
        divDom.appendChild(loadingDom);
        domScrollViewId.parentNode.appendChild(divDom);
    }
}
