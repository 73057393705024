import React, { Component } from "react";
import { withRouter } from "react-router";
import { TrasHome } from "../../../Translate/EachTranslateC";

import axios from "../../../config/axios";
import { GetAjaxURL, NoImgContens } from "../../../config/AjaxConfig";
import { showNotify, STATE_NOTIFY } from "../../extra/cusUtil";
import { metaClass, applyMetaHeader } from "../../extra/metaController";
import MainModal from "../../../components/utils/MainModal";
import TwinModal from "../../../components/utils/TwinModal";

import gurumeModal from "../../../images/main_modal/psy_publication.jpg";
import gurumeModal_1 from "../../../images/main_modal/tutorhive_launching.jpg";
import "./moocindex.css";

class MoocindexCard extends Component {
  calculateDiscount = (fee, discountRate) => {
    return Math.round((fee * (100 - discountRate)) / 100);
  };

  render() {
    const discountFee = this.calculateDiscount(
      this.props.originalPrice,
      this.props.nowPrice
    ).toLocaleString("en");
    return (
      <div className="mooc-card-item" key={this.props.id}>
        <div className="mooc-card">
          <div className="mooc-card-top">
            <h3>
              {this.props.topic}
              <br />
              {this.props.topic2}
            </h3>
            <div className="mooc-tutor">
              <div className="mooc-tutor-name">
                <p>{this.props.tutorName} 강사</p>
              </div>
              <div className="mooc-tutor-pic">
                <div className="mooc-tutor-pic-box">
                  <img
                    src={this.props.tutorPic}
                    onError={function (e) {
                      e.target.src = "/images/backuptutorpic.png";
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mooc-card-bottom">
            <div className="mooc-price">
              {this.props.nowPrice == 0 ? (
                <span className="original-price">
                  ₩{this.props.originalPrice.toLocaleString("en")}
                </span>
              ) : (
                <span className="original-price is-discount">
                  ₩{this.props.originalPrice.toLocaleString("en")}
                </span>
              )}
              <span className="now-price">
                &nbsp;{this.props.nowPrice == 0 ? "" : "₩" + discountFee}
              </span>
            </div>
            {this.props.isSubscribed ? (
              <button className="mooc-apply-btn" onClick={this.props.onClick}>
                수강중
              </button>
            ) : (
              <button className="mooc-apply-btn" onClick={this.props.onClick}>
                상세보기
              </button>
            )}
            <p className="mooc-time">
              총 <span>{this.props.totalMooc}강</span>/
              <span>{this.props.totalMoocTime}시간/</span>
              <span>{this.props.totalMoocDate}일</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
class Moocnew_index extends Component {
  state = {
    currentState: { id: "1", topic: "Mathematics", domId: "domMathematics" },
    catalogs: [{ id: "", topic: "" }],
    currentCatalogList: [
      {
        id: "",
        mooc_subject_id: 1,
        topic: "",
        topic2: " ",
        picUrl: "",
        tutor_id: "",
        fee: 0,
        fee_discount: 0,
        note_doc_url: null,
        question_doc_url: null,
        tutor_pic: "",
        tutor_name: "",
        tutor_full_name: "",
        is_subscribed: false,
        subscription_days: 0,
        total_moocs: 0,
        total_duration: 0,
        school: "",
        degree: "",
      },
    ],
    originCatalogData: [],
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      window.location.reload();
    }
    return true;
  };

  componentDidMount = async () => {
    await this.doInit();
  };

  doInit = async () => {
    const apiurls = await GetAjaxURL();
    const url = apiurls.mooc.index;
    const res = await axios.get(url).catch((err) => {
      return err;
    });
    const data = res.data;

    const catalogs = [];
    let currentCatalogList = [];

    const compareParam = data[0] ? data[0].topic : null;
    let currentState = {};

    for (let i = 0; i < data.length; i++) {
      const eachCatalog = {
        id: String(data[i].id),
        topic: data[i].topic,
        domId: "dom" + data[i].topic,
      };
      catalogs.push(eachCatalog);

      if (String(compareParam) === String(data[i].topic)) {
        currentCatalogList = data[i]["mooc_topics"];
        currentState = eachCatalog;
      }
    }

    this.setState(
      {
        ...this.state,
        currentState: currentState,
        catalogs: catalogs,
        currentCatalogList: currentCatalogList,
        originCatalogData: data,
      },
      () => {
        this.setMetas();
        compareParam && this.doDomSelected();
      }
    );
  };

  setMetas = () => {
    const _type = "";
    const _title = "MOOC";
    const _url = window.location.href;
    const _description =
      "1. 인강 열람. “5분 핵심 개념 설명 + 유형별 문제풀이” (키워드/개념별 검색으로 필요한 부분만 열람 가능). 2. 기출문제 풀기. 연도별 기출시험지 그리고 출판사별 교재";
    const _imageUrl = "base";
    let _keywords = "";

    const newMetaClass = new metaClass(
      _type,
      _title,
      _url,
      _description,
      _imageUrl,
      _keywords
    );
    applyMetaHeader(newMetaClass);
  };

  createNav = () => {
    return (
      <div className="each-nav">
        <ol className="breadcrumb">
          <li>
            <a href="/#/">
              <TrasHome />
            </a>
          </li>
          <li className="active">인강</li>
        </ol>
      </div>
    );
  };

  clickToCatalog = (e) => {
    const target = e.target;

    const dataId = target.dataset["id"];
    const dataTopic = target.dataset["topic"];

    // 토픽 선정
    const domCatalog = "dom" + dataTopic;
    const currentState = {
      id: dataId,
      topic: dataTopic,
      domId: domCatalog,
    };

    // 데이터 리스트
    let currentCatalogList = [];
    const catalogs = this.state.originCatalogData;
    for (let i = 0; i < catalogs.length; i++) {
      if (String(catalogs[i].id) === String(dataId)) {
        currentCatalogList = catalogs[i]["mooc_topics"];
      }
    }
    this.setState(
      {
        ...this.state,
        currentState: currentState,
        currentCatalogList: currentCatalogList,
      },
      () => {
        this.doDomSelected();
      }
    );
  };

  doDomSelected = () => {
    const currentDom = document.getElementById(this.state.currentState.domId);
    const currentDomParent = currentDom.parentNode;
    for (let i = 0; i < currentDomParent.childElementCount; i++) {
      currentDomParent.children[i].classList.remove("active");
    }
    currentDom.classList.add("active");
  };

  createCatalogs = () => {
    const catalogs = this.state.catalogs;
    const list = [];

    const originalSubjects = this.state.originCatalogData;
    const currentSubjects = [];
    for (let k = 0; k < originalSubjects.length; k++) {
      currentSubjects.push(
        <option key={"_i_" + k} value={originalSubjects[k].id}>
          {originalSubjects[k].topic}
        </option>
      );
    }

    if (this.state.currentCatalogList.length > 0) {
      for (let i = 0; i < catalogs.length; i++) {
        list.push(
          <li
            key={"item_" + i}
            id={"dom" + catalogs[i].topic}
            data-id={catalogs[i].id}
            data-topic={catalogs[i].topic}
            onClick={this.clickToCatalog}
          >
            {catalogs[i].topic}
          </li>
        );
      }
      return (
        <div className="mooc-catalog-list">
          {/* <div className="mooc-index-title"> */}
            {/* <div className="mooc-subjects-select">
                            <select className="form-control base-select'">
                                {currentSubjects}
                            </select>
                        </div> */}
            {/* <span className="blue-font-color">IB</span> */}
          {/* </div> */}
          <ul>{list}</ul>
        </div>
      );
    } else {
      showNotify(
        "현재 수강할 수 있는 인강이 없습니다.",
        STATE_NOTIFY.TYPE_NEGATIVIE
      );
      window.location.href = "/#/";
    }
  };

  moveToMoocShow = (id) => {
    window.location.href = "/#/mooc/" + id;
  };

  createCatalogBodies = () => {
    const currentCatalogList = this.state.currentCatalogList;

    const list = [];
    if (currentCatalogList.length > 0) {
      for (let i = 0; i < currentCatalogList.length; i++) {
        list.push(
          <MoocindexCard
            id={currentCatalogList[i].id}
            topic={currentCatalogList[i].topic}
            topic2={currentCatalogList[i].topic2}
            tutorName={currentCatalogList[i].tutor_full_name}
            tutorPic={currentCatalogList[i].tutor_pic}
            originalPrice={currentCatalogList[i].fee}
            nowPrice={currentCatalogList[i].fee_discount}
            totalMooc={currentCatalogList[i].total_moocs}
            totalMoocTime={currentCatalogList[i].total_duration}
            totalMoocDate={currentCatalogList[i].subscription_days}
            onClick={() => this.moveToMoocShow(currentCatalogList[i].id)}
            isSubscribed={currentCatalogList[i].is_subscribed}
          />
        );
      }
      return (
        <div className="mooc-catalog-item-list">
          <ul>{list}</ul>
        </div>
      );
    }
  };
  createBodyHeader = () => {
    return (
      <header className="tutor-header moocindex-header mooc-index-header">
        <div className="moocindex-header-title">
          <h1>GuruMe Lectures</h1>
        </div>
      </header>
    );
  };

  createBody = () => {
    return (
      <div>
        {this.createBodyHeader()}
        <div>
          {this.createCatalogs()}
          {this.createCatalogBodies()}
        </div>
      </div>
    );
  };
  goToTutorhive = () => {
    window.location.href="http://tutorhive.kr/"
  }
  render() {
    return (
      <div>
        <MainModal
          id="main-modal-2"
          num={2}
          isModalNum={2}
          img={gurumeModal_1}
          goToLink={this.goToTutorhive}
        />
        {this.createNav()}
        {this.createBody()}
      </div>
    );
  }
}

export default withRouter(Moocnew_index);
