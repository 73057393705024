import { createAction, handleActions } from 'redux-actions';
import { GetAjaxURL, getEnvironment } from '../config/AjaxConfig';
import axios from '../config/axios';



const LANG_SERVICE = 'rootbindingdata/LANG_SERVICE';
const TUTOR_PROFILE = 'rootbindingdata/TUTOR_PROFILE';
const IS_LOADING = 'rootbindingdata/IS_LOADING';

const setLangService = createAction(LANG_SERVICE);
const setTutorProfile = createAction(TUTOR_PROFILE);
const setIsLoading = createAction(IS_LOADING);

export const doLoading = (_boolean) => dispatch => {
    dispatch(setIsLoading(_boolean));
}


export const setCurrentLanguage = (_LangType) => dispatch => {
    let LangType = _LangType;
    if(LangType !== 'ko' && LangType !== 'en' && LangType !== 'cn'){
        LangType = 'ko';
    }
    dispatch(setLangService(LangType))
}

// export const setDataRegex = () => dispatch => {
//     dispatch(setRegex(REGEXP()));
// }

export const setTutorPrifileData =  (_authKey) => async dispatch => {


    const getData = {
        params : {
            page : 1, 
            onlyPrimary : 1,
            authKey : _authKey
        }
    };

    const url = await GetAjaxURL();

    axios.get(url.tutorProfilesURL, getData).then((res)=>{
    
        const data = res.data.data;

        if(!data) return;
        
        const profiles = data; 
        const modProfiles = JSON.parse(JSON.stringify(profiles)); 
        const newProfiles = [];
        const profileCheckerInDom = () => {
            return {
                hasThumbnailIntro : null,
                hasDemo : null,
                hasViewableReviews : null,
                hasViewableRecommendations : null,
                curriculumsThumbnailStr : ''
        }}

        while(modProfiles.length > 0){
            const profileCheckerObj = profileCheckerInDom();
            const profile = modProfiles.shift();
            profileCheckerObj.hasThumbnailIntro = 
                profile.tutor_profile.thumbnail_intro.length !== 0 ? true : false;
            profileCheckerObj.hasDemo =
                (profile.tutor_profile.video_youtube_1.length !== 0 || profile.tutor_profile.video_youku_1 !== 0 ? true : false);
            profileCheckerObj.hasViewableReviews =
                (profile.tutor_profile.viewable_review_count > 0 ? true : false);
            profileCheckerObj.hasViewableRecommendations =
                (profile.tutor_profile.all_review_count > 0 ? true : false);
    
    
            let curriculumsThumbnailStr = '';
            for(let i = 0; i < profile.curriculums.length; i++){
                curriculumsThumbnailStr += profile.curriculums[i].curriculum_name + ' · ';
            }
            profileCheckerObj.curriculumsThumbnailStr = curriculumsThumbnailStr;
    
            newProfiles.push({profile, profileCheckerObj});
        }

        dispatch(setTutorProfile(newProfiles));


    }).catch((err)=>{return console.log(err)});

}



const initialState = {
    lang : 'ko',
    tutorProfile : [],
    R_isLoading : true,
    isLoadedtutorProfilesData : false
}


export default handleActions({
    [IS_LOADING] : (state, action) => {
        return {
            ...state,
            R_isLoading : action.payload
        }
    },
    [LANG_SERVICE] : (state, action) => {
        return {
            ...state,
            lang : action.payload
        }
    },
    [TUTOR_PROFILE] : (state, action) => {
        return {
            ...state,
            tutorProfile : action.payload,
            R_isLoading : false,
            isLoadedtutorProfilesData : true
        }
    }
   
}, initialState);