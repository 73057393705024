import React, {Component} from 'react';
import {
    TrasHome,
} from '../../../Translate/EachTranslateC';

import './mooc_logistics.css';

/* font awesome */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList, faPencilAlt
        ,faVideo,
        faCheck,
        faFilm
} from '@fortawesome/free-solid-svg-icons';


class mooc_logistics extends Component{

    state = {
    }


    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/">인강</a></li>
                    <li><a href="/#/">미리보기</a></li>
                </ol>
            </div>
        )
    }


    createFirstPart = () => {

        return (
            <div
                className="mooc-logistics-banner"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2)), url('/images/mooc_filming1.6811ae44.jpg')",
                    backgroundPosition: "center"
                }}>
                <div className="mooc-logistics-box">
                    <div className="mooc-logistics-content text-center">
                        <h2 className="boldest-font">  인강, IB 교육의 새로운 트렌드 </h2>
                        <h4 className="normal-font white-font-color"> 물리, 경영, 코리안, 심리학 모집중 </h4>
                        <div className='mooc-logistics-btn'>
                            <a href='https://gurume.kr/mooc/61'> 예시 인강 </a>
                            <a href='https://docs.google.com/document/d/1qBuh0S9a5O4D2hQ4pcDMDfaANRFR3XzK3PWSHu1LiIA/edit'> 참여 계약서 </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createSecondPart = () => {
        return (
            <div className="mooc-logistics-box2">
                <div className="admissionUs-map-box text-center margin-top-30 margin-bottom-30 academy-wapper-c">
                    <h1 className="color-gurume-blue bold-font margin-bottom-30">아래 선생님에게 적합합니다</h1>
                    <div className="mooc-logic-info">
                        <div className="promotion-box no-border">
                            <p >
                            <FontAwesomeIcon icon={faCheck} className='cols-gl' />
                            비전 - 수능 사교육계의 ‘메가스터디 현우진’처럼 유학 사교육계에서의 스타 강사를 꿈꾸시는 분
                            </p>
                            <p>
                            <FontAwesomeIcon icon={faCheck} className='cols-gl' />

                            실력 - 내용 전달력 (깔끔한 칠판 노트 포함), 재미 그리고 자신만의 교재/커리큘럼으로 수업이 가능하신 분
                            </p>
                        </div>
                    </div>
                </div>
                <div className="admissionUs-map-box text-center margin-top-30 margin-bottom-30">
                    <h1 className="color-gurume-blue bold-font margin-bottom-30">수입</h1>
                    <div className="mooc-logic-info">
                        <div className="promotion-box no-border">
                            <p >
                            <FontAwesomeIcon icon={faCheck} className='cols-gl' />
                            단원 별 수강료 결제 및 구독 (대략 3만 ~ 7만원/월)
                            </p>
                            <p>
                            <FontAwesomeIcon icon={faCheck} className='cols-gl' />
                            구독 수강료는 언제든지 환산할 수 있는 그루미 크레딧으로 저장 (www.gurume.kr/credit/balance 참조)
                            </p>
                            <p>
                            <FontAwesomeIcon icon={faCheck} className='cols-gl' />
                            선생님과 그루미 50:50 수입 분배
                            </p>
                            <p>
                            (오프라인/온라인 수업에 직접적인 영향)
                            </p>
                            <p>
                            <FontAwesomeIcon icon={faCheck} className='cols-gl' />
                            시급 인상
                            </p>
                            <p>
                            <FontAwesomeIcon icon={faCheck} className='cols-gl' />
                            (그룹)수업문의 증가
                            </p>
                            <p>*구독 수강료는 선생님께서 그루미와 상의하에 조율가능</p>
                            <p>*수입은 인강 인기와 비례</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createBodyTypeA = () => {
        return(
            <div className="mooc-logistics-box margin-top-30">
                <div className="admissionUs-map-box text-center margin-bottom-30 academy-wapper-c">
                    <h1 className="color-gurume-blue bold-font margin-bottom-30">참여 방법</h1>
                    <div className="mooc-content">

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faList}/>
                                    </span>
                                </h1>
                                <h3 className='mooc-gray'>
                                부단원과 영상별 제목 그리고 관련 키워드 정리
                                </h3>
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                    </span>
                                </h1>
                                <h3 className='mooc-gray'>
                                대사 (optional) 그리고 노트 준비
                                </h3>
                               
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faVideo}/>
                                    </span>
                                </h1>
                                <h3 className='mooc-gray'>
                                    2~3주의 집중 촬영
                                </h3>
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faFilm}/>
                                    </span>
                                </h1>
                                <h3 className='mooc-gray'>
                                편집 후 게시
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className='margin-top-30'>
                        <p>*기출문제집은 그루미에서 준비</p>
                        <p>*촬영은 짧은 기한 내에 완성</p>
                        <p>*한국어 혹은 영어 진행</p>
                        <p>*선생님 한 분께서 혹은 여러분께서 같이 한 과목 완성 가능</p>
                        <br />
                        <a href='https://docs.google.com/document/d/1I7jTy5vQire21rH8mZ1frvCNCNC31Af7YC-bbkLiT10/edit' className='mooc-log-btn'> 상세내용 </a>
                    </div>
                </div>
            </div>
        )
    }

    createSecondPartAA = () => {
        return (
            <div className="mooc-logistics-box2 padding-top-50">
                <div className='academy-wapper-c'>
                    <h1 className="color-gurume-blue bold-font text-center margin-bottom-60">구독 방법</h1>
                    <div className="admissionUs-map-box text-center margin-bottom-30">
                        <div className="mooc-logic-cardA">
                            <p > 1. 선결제한 크레딧으로 단원별 구매/구독 </p>
                            <div className="mooc-logic-cardAC">
                                <ul>
                                    <li><img src='images/mooc_logistics1.6ca99636.png' alt='...' /></li>
                                    <li><img src='images/mooc_logistics2.a3f02fe6.png' alt='...' /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    createSecondPartAASS = () => {
        return (
            <div className="mooc-logistics-box2">
                <div className='academy-wapper-c'>
                    <div className="admissionUs-map-box text-center margin-bottom-30">
                        <div className="mooc-logic-cardA">
                            <p > 2. 해당 단원에 대한 인강 영상 리스트 무제한 열람 </p>
                            <div className="mooc-logic-cardAC">
                                <ul>
                                    <li><img src='images/mooc_logistics3.ec80f500.png' alt='...' /></li>
                                    <li><img src='images/mooc_logistics4.cae619f2.png' alt='...' /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createSecondPartAASS_S = () => {
        return (
            <div className="mooc-logistics-box2">
                <div className='academy-wapper-c'>
                    <div className="admissionUs-map-box text-center margin-bottom-30">
                        <div className="mooc-logic-cardA">
                            <p > 3. 이해 안 되는 부분은 키노트 참조 혹은 게시판에 질문 코멘트 </p>
                            <div className="mooc-logic-cardAC">
                                <ul>
                                    <li><img src='images/mooc_logistics5.ef70a147.png' alt='...' /></li>
                                    <li><img src='images/mooc_logistics6.61b5d59d.png' alt='...' /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createSecondPartAASS_SS = () => {
        return (
            <div className="mooc-logistics-box2">
                <div className='academy-wapper-c'>
                    <div className="admissionUs-map-box text-center margin-bottom-30">
                        <div className="mooc-logic-cardA">
                            <p > 4. 관련 단원 기출문제 풀고 오답 체크 </p>
                            <div className="mooc-logic-cardAC">
                                <ul>
                                    <li><img src='images/mooc_logistics7.c5d25332.png' alt='...' /></li>
                                    <li><img src='images/mooc_logistics8.1fd9ef10.png' alt='...' /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createMidPart = () => {

        return (
            <div
                className="mooc-logistics-banner"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2)),url(/images/mooc_filming2.b7bf0a74.jpg)",
                    backgroundPosition: "center"
                }}>
                <div className="mooc-logistics-box">
                    <div className="mooc-logistics-content text-center">
                        <h2 className="boldest-font mooc-limit-h"> 최첨단 장비 (캠코더, 조명, 마이크) 설비 된 그루미 스튜디오 </h2>
                    </div>
                </div>
            </div>
        )
    }
    createLastPart = () => {
        return (
            <div className="mooc-logistics-box2 padding-bottom-50">
                <div className="admissionUs-map-box text-center margin-top-30 margin-bottom-10">
                    <h1 className="bold-font margin-bottom-30">참여 방법</h1>
                    <div className="mooc-logic-info">
                        <div className="promotion-box no-border">
                            <h2>1. harrylee1230@gmail.com 이메일</h2>
                            <h2>2. 그루미 사무실에서 혹은 녹음 된 데모수업 심사</h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    
    createHtml = () => {
        return(
            <div>
                {this.createNav()}
                {this.createFirstPart()}
                    {this.createSecondPart()}
                    {this.createBodyTypeA()}
                    {this.createSecondPartAA()}
                    {this.createSecondPartAASS()}
                    {this.createSecondPartAASS_S()}
                    {this.createSecondPartAASS_SS()}
                    {this.createMidPart()}
                {this.createLastPart()}
            </div>
        )
    }

    render(){
        return(
            <div className="default-wrapper mooc_logistics-pannel-size" >
                {this.createHtml()}
            </div>
        )
    }

}

export default mooc_logistics;