import React, { Component } from 'react';
import {
    TrasHome,
} from '../../../Translate/EachTranslateC';

import './mooc_preview.css';

/* font awesome */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faList, faPencilAlt, faBook, faEdit
    , faVideo
} from '@fortawesome/free-solid-svg-icons';

import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';
import PreviewTutor from '../../../images/mooc_preview_brian.jpg';

class mooc_preview extends Component {
    goToMooc = () => {
        window.open('https://youtu.be/JTvt3evTntg');
    }
    goToNote = () => {
        window.open('https://bit.ly/2LnhbKi');
    }
    goToQuestion = () => {
        window.open('https://forms.gle/jcvADcFBHx9cmAEJA');
    }
    state = {
    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/">인강</a></li>
                    <li><a href="/#/">미리보기</a></li>
                </ol>
            </div>
        )
    }

    createBanner = () => {
        return (
            <div className="mooc-banner">
                <div className="mooc-banner-box">
                    <div className="mooc-banner-content text-center">
                        <h1 className="boldest-font white-font-color">
                            IB 화학 인강
                        </h1>
                        <h4 className="normal-font white-font-color">
                            전문 강사진이 만든 인강과<br />기출문제집으로 스스로 공부하기
                        </h4>
                        <button className='btn-gurume-border-white' onClick={this.clickBox}>커리큘럼</button>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }

    createFirstPart = () => {
        return (
            <div className="mooc-banner">
                <div className="mooc-preview-banner-box">
                    <div className="mooc-preview-banner-content text-center">
                        <h2 className="boldest-font white-font-color">
                            IB 교육의 새로운 트렌드
                        </h2>
                        <h4 className="normal-font white-font-color">
                            최고의 선생님과 부담스럽지 않은 수업료로 학습
                        </h4>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }
    createSecondPart = () => {
        return (
            <div className="admissionUs-map">
                <div className="admissionUs-map-box text-center margin-bottom-30">
                    <div className="mooc-work-title margin-top-30">
                        <h2 className="color-gurume-blue bold-font">이용법</h2>
                    </div>
                    <div className="admissionUs-map-content">
                        <div className="admissionUs-map-item mooc-preview-item" onClick={this.goToMooc}>
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faVideo} />
                                    </span>
                                </h1>
                                <h3>
                                    인강
                                </h3>
                                <p>
                                    부단원 별 “핵심 개념설명”과 “유형별 문제풀이” 영상 열람
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item mooc-preview-item" onClick={this.goToNote}>
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faBook} />
                                    </span>
                                </h1>
                                <h3>
                                    개념 노트
                                </h3>
                                <p>
                                    가장 중요한 알짜배기 내용으로 “이것만 외우면” 되는 자료 참조
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item mooc-preview-item" onClick={this.goToQuestion}>
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </span>
                                </h1>
                                <h3>
                                    문제풀이
                                </h3>
                                <p>
                                    (부)단원별로 분류 된 연도별 기출문제 풀이 후 자동 답안 채점
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item mooc-preview-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faList} />
                                    </span>
                                </h1>
                                <h3>
                                    질의 응답
                                </h3>
                                <p>
                                    강의 선생님과 월 1회 미팅 (질의응답은 옵션으로서 추가 비용 有)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createBodyTypeA = () => {
        return (
            <div className="mooc-map">
                <div className="mooc-video">
                    <div className="videowrapper">
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/JTvt3evTntg" frameborder="0"></iframe>
                    </div>
                </div>
                <div className="mooc-video">
                    <div className="videowrapper">
                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/lFj4_p-dtno" frameBorder="0"></iframe>
                    </div>
                </div>
            </div>
        )
    }


    clickBox = () => {
        window.open('https://docs.google.com/document/d/19kknMbMX2k7gdF75lN1kXhY1nFqZdB26kYABxNlcqmI/edit')
    }

    createThirdPart = () => {
        return (
            <div className="mooc-fee">
                <div className="mooc-fee-box">
                    <div className="mooc-fee-content text-center">
                        <h2 className="boldest-font color-gurume-blue">
                            수강료 (SL 기준)
                        </h2>
                        <p>
                            <i className="fa fa-check"></i>&nbsp;3달 60만원/1년 220만원<br />
                            <i className="fa fa-check"></i>&nbsp;1년 구독시 1개월 무료<br/>
                            <i className="fa fa-check"></i>&nbsp;(~25% 디스카운트)
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    createFourthPart = () => {
        return (
            <div className="mooc-banner">
                <div className="mooc-apply-banner-box">
                    <div className="mooc-apply-banner-content text-center">
                        <h2 className="boldest-font white-font-color">
                            신청방법
                        </h2>
                        <p className="white-font-color">
                            <i className="fa fa-check"></i>&nbsp;신청서 작성 및 수강료 입금<br />
                            <i className="fa fa-check"></i>&nbsp;인강 계정 생성<br />
                            <i className="fa fa-check"></i>&nbsp;사용법 설명 및 세팅
                        </p>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }

    createFifthPart = () => {
        return (
            <div className="email-us">
                <div className="email-us-box">
                    <div className="email-us-content">
                        <h3 style={{ color: "white" }}>선생님 참여 문의는 마이페이지에 있는 인강 소개서 혹은 support@gurume.kr 이메일 부탁드립니다</h3>
                    </div>
                </div>
            </div>
        )
    }

    createSixthPart = () => {
        return (
            <div className="mooc-tutor">
                <div className="mooc-tutor-box">
                    <div className="mooc-tutor-content">
                        <div className="mooc-tutor-content-intro">
                            <h2>이재훈 선생님</h2>
                            <p>
                                <i className="fa fa-check"></i>&nbsp;필리핀 명문고 IBDP 이수<br />
                                <i className="fa fa-check"></i>&nbsp;고려대 환경 화학 졸업<br />
                                <i className="fa fa-check"></i>&nbsp;국내 국제학교 현직 화학 교사<br />
                                <i className="fa fa-check"></i>&nbsp;IB 화학 인강 및 교재 출판<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;(국내 유일 저자)
                            </p>
                        </div>
                        <div className="mooc-tutor-content-image">
                            <div className="mooc-tutor-content-image-box">
                                <img src={PreviewTutor} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                {this.createBanner()}
                <div className="mooc-preview-wrapper">
                    <div className='academy-wapper-c'>
                        {this.createSecondPart()}
                        {this.createBodyTypeA()}
                    </div>
                    {this.createFirstPart()}
                    {this.createSixthPart()}
                    {this.createFourthPart()}
                    {/* {this.createThirdPart()} */}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="default-wrapper mooc_preview-pannel-size" >
                {this.createHtml()}
            </div>
        )
    }

}

export default mooc_preview;