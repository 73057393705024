import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import {
    TrasHome,
    TrasInbox,
    TrasDetails,
    TrasTuitionBill,
    TrasSend,
    TrasBack,
    TrasViewProfile,
    TrasSending,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';

import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';
import { newDateFormat } from '../../extra/utils'

import './messages.css'

const $ = window.jQuery;

class MessagesItem extends Component {
    state = {
        lang: window.localStorage.lang || 'ko',
        userType: '',
        teacherId: '',
        toId: '',
        fromId: '',
        userData: {},
        chatMsg: '',
        thisUserName: '',
        chatDetail: {},
        chatContentList: [],
        isSenddingMsg: false,
        is_ot: false,
    }

    componentDidMount = async () => {

        const msgid = this.props.match.params.msgid;
        await this.getIsLogin();
        await this.getUserInfo(msgid);
        await this.getData(msgid);
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }


    getIsLogin = async () => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.isLogin;
        const res = await axios.get(url).catch((err) => { return err; })
        let data = res.data;
        if (data.isLogin === 'false') {
            let lang = this.state.lang
            let msg = (lang === 'cn' ? '请登录' : lang === 'en' ? 'Please login.' : '로그인 해주세요.', "danger");
            let type = STATE_NOTIFY.TYPE_NEGATIVIE;
            showNotify(msg, type);
            window.location.href = '/#/login'
        } else {
            this.setState({
                userType: data.type
            })
        }
    }

    getUserInfo = async (msgid) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.apiThisUser;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;
        let teacherId = this.state.userType === 'student' ? msgid : data.user.id;
        this.setState({
            userData: data,
            teacherId: teacherId,
            toId: msgid,
            fromId: data.user.id
        });
    }

    getData = async (msgid) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.message.RRR_ReactMsgShow;
        const urlWithReact = url + '/' + msgid;
        const formData = new FormData();
        formData.append('frameType', 'React');
        formData.append('msgOrder', 'asc');
        const res = await axios.post(urlWithReact, formData, postConfig).catch((err) => { return err; });
        let data = res.data;
        this.setState({
            thisUserName: data.current_name,
            chatContentList: data.chatContent,
            chatDetail: data,
            is_ot: data.is_ot,
        }, () => {
            this.props.RootBindingData.doLoading(false);
        })
    }

    goToSenderShow = (senderId) => {
        if (this.state.userType === 'student') {
            if (senderId !== this.state.fromId) {
                window.location.href = '/#/teachers/' + senderId
            }
        }

    }

    showChat = (mid) => {
        window.location.href = '/#/messages/' + mid
    }

    replaceTmp = (content) => {

        if (content.indexOf('<a href') !== -1) {
            return content;

        } else {
            return content.replace(/\n/g, '<br/>');
        }

    }

    sendMsg = async (Toid, FromId) => {
        let { lang, chatMsg, chatContentList, userData } = this.state
        if (chatMsg === undefined || chatMsg === '') {
            showNotify(lang === 'cn' ? '消息不能为空' : lang === 'en' ? 'You must input message.' : '메세지를 입력해주세요.', STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        this.setState({
            ...this.state,
            isSenddingMsg: true
        });

        var postNewChatMsg = $.param({
            msgInfo: chatMsg,
            receiver: Toid
        });




        const apiurls = await GetAjaxURL();
        const url = apiurls.message.send;
        const res = await axios.post(url, postNewChatMsg, postConfig).catch((err) => { return err; });

        let data = res.data;
        let isSucc = false;
        if (data.status === "success") {
            $('.msg-wrap').scrollTop($('.msg-wrap')[0].scrollHeight);
            isSucc = true;
        } else {
            if ('The receiver is blocked.' === data.msg) {
                showNotify(lang === 'en' ? 'The user is blocked.' : lang === 'cn' ?
                    '用户被阻止。' : '해당 유저는 차단되어 메세지 발송이 불가능합니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            } else {
                showNotify(lang === 'en' ? 'Failed to send, please try again' : lang === 'cn' ?
                    '发送失败，请重试' : '메세지 발송에 실패하였습니다. ' + data.msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            }
        }

        if (isSucc) {
            chatContentList.push({
                "id": 1,
                "sender_first_name": userData.user.first_name,
                "sender_last_name": userData.user.last_name,
                "sender": FromId, // 本人id
                "receiver": Toid, // 对方id
                "isRead": 0, // 是否被阅读
                "content": chatMsg,
                "created_at": newDateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                "updated_at": newDateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                "type": 1, // 1为本人发送的信息，2为对方发送的信息,
                "timestamp": new Date() / 1000,
                "createDate": {            // 리엑트에서 추가된 부분
                    "date": newDateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss.000'),
                },
                "pics": this.state.chatDetail.current_pic
            });

            this.setState({
                chatMsg: '',
                chatContentList: chatContentList,
                isSenddingMsg: false
            }, () => {
            })
        }

    }

    createBreadcrumb = () => {
        return (
            <div className="container">
                <ol className="breadcrumb mooc-index-breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/messages"><TrasInbox /></a></li>
                    <li className="active"><TrasDetails /></li>
                </ol>
            </div>
        )
    }

    FixFortmatDate = (_newDateFormat) => {
        const newDateFormat = _newDateFormat.substr(0, _newDateFormat.indexOf('.'));
        return newDateFormat;
    }

    goToOTPage = () => {
        window.location.href="/#/ot"
    }

    createContentHtml = () => {
        const { userType, teacherId, toId, fromId, chatDetail, chatContentList } = this.state
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-1 col-xs-12"></div>
                    <div className="col-md-10 col-xs-12">
                        <div className="card">
                            <div className="card-body">
                                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button> */}
                                {
                                    userType === 'student' ?
                                        <div>
                                            <h4 className="modal-title display-inline" id="myModalLabel">
                                                {
                                                    chatDetail.flagstring && chatDetail.flagstring.length > 0 ?
                                                        <i className="fa fa-graduation-cap blue-font-color cursor hover-shadow"
                                                            data-toggle="tooltip" data-placement="left" title={chatDetail.flagstring}></i> : ''
                                                }
                                                <span onClick={() => this.toTeacherProfile(teacherId)}>{chatDetail.last_name}{chatDetail.first_name} - <TrasViewProfile /></span>
                                            </h4>
                                            <p>선생님과 수업상담에 어려움이 있을시 <a href="/#/contact_us">그루미 고객센터</a>로문의 바랍니다.</p>
                                        </div>
                                        :
                                        <div className="d-flex align-items-center" style={{display:'flex',justifyContent:'flex-end'}}>
                                            <a href="/#/tuitions/bill" target="blank" className="btn cancel-btn inline-block">
                                                <span className="glyphicon glyphicon-credit-card"></span><TrasTuitionBill />
                                            </a>
                                        </div>
                                }
                            </div>
                            <div className="card-body">
                                <div className="msg-wrap">
                                    {
                                        chatContentList.map((item, index) => {
                                            let ColorfulClass = '';
                                            if (item.receiver === this.state.fromId) {
                                                ColorfulClass = 'media-heading';
                                            }


                                            return (
                                                <div className="media msg margin-bottom-10" key={'ChartItem' + index}>
                                                    <div className="media-body">
                                                        <div className="row margin-bottom-10">
                                                            <div className="col-md-12">
                                                                <div onClick={() => this.goToSenderShow(item.sender)} className={`float-left cursor d-flex align-items-center ${ColorfulClass}`}>
                                                                    <div className="msgitem-user-logo display-inlineb">
                                                                        <img src={item.pics}
                                                                            alt="64x64" className="img-fluid rounded-circle media-object margin-right-10"
                                                                            onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }.bind(this)}
                                                                        /></div>
                                                            &nbsp;{item.sender_last_name}{item.sender_first_name}
                                                                </div>
                                                                <span className="float-right time">
                                                                    <i className="fa fa-clock-o"></i>{this.FixFortmatDate(item.createDate.date)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            {
                                                                this.state.is_ot ?
                                                                    <div className='r-msg-link' dangerouslySetInnerHTML={{ __html: this.replaceTmp(item.content) }} /> :
                                                                    <button className="btn english-btn base-btn" onClick={this.goToOTPage}>OT가 필요합니다. OT 신청하기 </button>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="send-wrap">
                                    {this.state.is_ot ?
                                        <div>
                                            <input placeholder="여기에 답변을 작성해주세요" className="form-control send-message" type='text' onChange={(e) => this.setState({ ...this.state, chatMsg: e.target.value })}
                                                value={this.state.chatMsg}
                                            ></input>
                                        </div> : ""}
                                    <div className="margin-top-10">
                                        {this.state.is_ot ?
                                            <button className="btn submit-btn margin-right-10" onClick={() => this.sendMsg(toId, fromId)}>
                                                {
                                                    this.state.isSenddingMsg ?
                                                        <font className="loading"><TrasSending /></font>
                                                        :
                                                        <TrasSend />
                                                }
                                            </button> : ""}

                                        <a href="/#/messages" className="btn cancel-btn">
                                            <TrasBack />
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )
    }

    render = () => {
        return (
            <div className="messages-container"
            >
                {this.createBreadcrumb()}
                {this.createContentHtml()}
            </div>
        )
    }
}

export default withRouter(MessagesItem);
