import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll, faIdCard, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import "./recruit.css"

export default class Recruit extends Component {
    scrollToFulltime = () => {
        const $ = window.jQuery;
        const fulltimeDiv = $(".recruit-fulltime")

        $("html, body").animate({
            scrollTop: fulltimeDiv.offset().top
        }, 'slow')
        // window.open('https://docs.google.com/document/d/1k1ihoQpo6cuLHTJHOiWZNRGGHEBLGqyI1kcT3ZBzo6c/edit?usp=sharing')
    }
    scrollToHalfFulltime = () => {
        const $ = window.jQuery;
        const halfFulltimeDiv = $(".recruit-half-fulltime")

        $("html, body").animate({
            scrollTop: halfFulltimeDiv.offset().top
        }, 'slow')
        // window.open('https://docs.google.com/document/d/1S6nNNH34y0heMfYjYrRWmp1JVk7B5ExUrT91cmbTqXw/edit?usp=sharing')
    }
    recruitBanner = () => {
        return (
            <div className="recruit-banner">
                <div className="recruit-banner-box">
                    <div className="recruit-banner-content text-center">
                        <h1 className="boldest-font white-font-color">
                            IB 전문학원 선생님 채용
                            </h1>
                        <button className='btn-gurume-border-white' onClick={this.scrollToFulltime}>풀타임</button>
                        <button className='btn-gurume-border-white' onClick={this.scrollToHalfFulltime}>하프 풀타임</button>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }
    recruitProcess = () => {
        return (
            <div className="recruit-process">
                <div className="recruit-process-box">
                    <div className="recruit-process-content-wrapper text-center">
                        <h1 className="boldest-font color-gurume-blue">
                            상세내용
                        </h1>
                        <div className="recruit-process-content">
                            <div className="recruit-process-item">
                                <span className="recruit-process-icon">
                                    <FontAwesomeIcon icon={faScroll} />
                                </span>
                                <h4>지원자격</h4>
                                <p>
                                    - 전문 풀타임 : 졸업자 또는 졸업 예정자<br />
                                    - 전문 하프풀타임 : 대학생부터 가능
                                </p>
                            </div>
                            <div className="recruit-process-item">
                                <span className="recruit-process-icon">
                                    <FontAwesomeIcon icon={faIdCard} />
                                </span>
                                <h4>제출서류</h4>
                                <p>
                                    <b>- 1차 면접</b><br />
                                    재학생인 경우 재학증명서, 학생증<br />
                                    <b>- 2차 면접</b><br />
                                    해외대학 졸업자 : 아포스티유<br />
                                    국내대학 졸업자 : 졸업증명서(졸업예정증명서)<br />
                                    경력자일 경우, 경력을 증명할 수 있는 자료 (경력 증명서, 영상, 수업자료등)
                                </p>
                            </div>
                            <div className="recruit-process-item">
                                <span className="recruit-process-icon">
                                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                                </span>
                                <h4>채용절차</h4>
                                <p>
                                    서류 - 사전 전화 인터뷰 - 1차 면접(Test) - 2차 면접(시강) - 합격결정 - 서류제출 - 계약서 작성<br />
                                    - 데모수업 10분 개념설명 + 5분 문제풀이 @그루미<br />
                                    - 선생님 홍보자료 메이킹<br />
                                    <span className="indent-text">- 소개영상 (데모수업과는 별개), 예 <a target="_blank" className="gurume-blue-link" href="http://youtu.be/X_bq2axS7kE">youtu.be/X_bq2axS7kE</a></span><br />
                                    <span className="indent-text">- 포스터/브로셔, 예 <a target="_blank" className="gurume-blue-link" href="https://goo.gl/CF4GNa">goo.gl/CF4GNa</a></span><br />
                                    - 교육청 학원 강사 등록<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    goToAcademy = () => {
        window.open("http://ibthetop.com")
    }
    recruitMessage = () => {
        return (
            <div className="recruit-message">
                <div className="recruit-message-box">
                    <div className="recruit-message-content text-center">
                        <h1 className="boldest-font color-gurume-blue">
                            학원장의 메시지
                        </h1>
                        <p>
                            서울 역삼역 인근에 위치한 경력과 실력을 갖춘 선생님들과 함께 하는 All in one 유학 커뮤니티이자 서울권의 메이저 IB 전문학원 “그루미GuruMe”입니다.
                            그루미 과외를 시작으로 함께 성장한 경력, 실력있는 그루미 선생님들은 현재 그루미 학원으로 정착을 하여 보다 안정적이고 질 높은 수업을 제공할 수 있게 되었습니다. 또한  그루미 스텝들과의 소통과 협업하에 체계적인 학생관리와 과목 교재, *인강, 블로그 포럼, 영상등의 컨텐츠도 개발하고 있습니다.
                            그루미 스텝들도 다양하고 전문성 있게 선생님들의 발전과 활동을 위해 마케팅과 소스들을 제공하며 노력하고 있습니다.
                            국내외 대학생 네트워크를 만들어 저희 그루미가 지향하는 같은 목표를 가지고 교육에 대한 열정과 실력으로 학생들의 멘토가 되고 싶으신 선생님들의 많은 지원 바랍니다.
                        </p>
                    </div>
                    <button className='btn-gurume-border-grey' onClick={this.goToAcademy}>학원 소개</button>
                </div>
            </div>
        )
    }
    recruitFulltime = () => {
        return (
            <div className="recruit-fulltime">
                <div className="recruit-fulltime-box">
                    <div className="recruit-fulltime-content-wrapper">
                        <h1 className="boldest-font color-gurume-blue">
                            전문 풀타임이란?
                        </h1>
                        <h4>
                            학원 원장님 급 과목별 선생님으로서 학원보다 더 큰 목표로 “현우진 x 메가스터디”/ “이명학 x 대성마이맥”과 같은 관계
                        </h4>
                        <div className="recruit-fulltime-content">
                            <div className="recruit-fulltime-item">
                                <h4>조건</h4>
                                <p>
                                    1. 연 상시 수업 가능<br />
                                2. 다른 직업보다 수업이 1순위<br />
                                3. 매일 8시간 이상 수업 가능<br />
                                *대학원 재학 중이신 분은 풀타임이 아닙니다.
                            </p>
                            </div>
                            <div className="recruit-fulltime-item">
                                <h4>혜택</h4>
                                <p>
                                    1. 학원 소속 선생님이 아닌 선생님 자신만의 브랜드 설립<br />
                                2. 방학 외 학기중에도 꾸준한 수업량 (온,오프라인 수업)<br />
                                3. 학교별 커리큘럼/시험 유형 조사 및 인강*/교재/학습 자료 연구 및 제작<br />
                                4. 그루미 인보이싱 시스템으로 간편관리<br />
                                5. 1년차 대기업 과장급 고소득 가능하며 년차에 최고 3억까지도 가능
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    recruitHalfFulltime = () => {
        return (
            <div className="recruit-half-fulltime" id="half_fulltime">
                <div className="recruit-fulltime-box">
                    <div className="recruit-fulltime-content-wrapper">
                        <h1 className="boldest-font color-gurume-blue">
                            하프 풀타임이란?
                        </h1>
                        <h4>
                            학업 혹은 커리어 병행중이지만, 그루미 학원 소속으로 원하는 시간 만큼의 수업을 할 수 있는 파트타임 관계
                            </h4>
                        <div className="recruit-fulltime-content">
                            <div className="recruit-fulltime-item">
                                <h4>조건</h4>
                                <p>
                                    1. 기존 그루미 과외 이용방법과 동일하지만 기존 1:1 수업 보다는 더 많은 책임 필요<br />
                                2. 온라인 수업만 희망시 온라인 수업만으로 참여 가능하며 방학특강 수업에 참여시 학원강사 등록 필요<br />
                                3.  연 수입이 1200만원 이상일 경우 3.3% 환급 불가 및 소득 세금 정산 필요
                            </p>
                            </div>
                            <div className="recruit-fulltime-item">
                                <h4>혜택</h4>
                                <p>
                                    1. 방학 외 학기중에도 꾸준한 수업량 보장 (주 10 - 20시간)<br />
                                2. 연간 ‘반’상시 수업 가능 (미리 정한 가능 시간대에 들어오는 수업문의에 대해)<br />
                                3. 학교별 커리큘럼/시험 유형 조사 및 인강/교재/학습 자료 연구 및 제작<br />
                                4. 그루미 인보이싱 시스템으로 간편관리<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    recruitApply = () => {
        return (
            <div className="recruit-apply">
                <div className="recruit-apply-box">
                    <div className="recruit-apply-content text-center">
                        <h1 className="boldest-font white-font-color">
                            지원방법
                    </h1>
                        <h4 className="normal-font white-font-color">
                            그루미 웹사이트에서 선생님으로 회원등록 후 연락 또는 이메일 접수
                    </h4>
                        <h4>010-5723-7221 /  jessica@gurume.kr</h4>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }
    render() {
        return (
            <div className="recruit-wrapper">
                {this.recruitBanner()}
                {this.recruitProcess()}
                {this.recruitMessage()}
                {this.recruitFulltime()}
                {this.recruitHalfFulltime()}
                {this.recruitApply()}
            </div>
        )
    }
}