import React, { Component } from 'react';
import {
    TrasHome,
    TrasErrorPage,
    TrasSorrythepageyouarelookingfordoesntexist,
    TrasPleasedoublecheckthewebsiteurlIftheproblemcontinuespleasecontactGuruMesupportteam
} from '../../../Translate/EachTranslateC';

import './error.css';
import { cusUtilStart } from '../../extra/cusUtil';

class error extends Component {

    state = {
        wrapperSize: ''
    }

    createNav = () => {
        return (
            <ol className="breadcrumb">
                <li><a href="/#/"><TrasHome /></a></li>
                <li><a href="/#/blogs"><TrasErrorPage /></a></li>
            </ol>
        )
    }

    createBody = () => {
        return (
            <div className="col-md-offset-2 col-md-8" style={{ textAlign: "center" }}>
                <h2 className='error-h2-size' style={{ color: "#5b9aef" }}><TrasSorrythepageyouarelookingfordoesntexist /></h2>
                <br />
                <h4 className='error-h4-size'><TrasPleasedoublecheckthewebsiteurlIftheproblemcontinuespleasecontactGuruMesupportteam /></h4>
            </div>
        )
    }

    componentDidMount = () => {
        cusUtilStart(this);
    }
    calScreenSizeAndResizeWrapper = (wrapperSize) => {
        this.setState({
            wrapperSize
        })
    }


    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                {this.createBody()}
            </div>
        )
    }

    render() {
        return (
            <div className="default-wrapper error-pannel-size"
                style={{ height: this.state.wrapperSize + 'px' }}
            >
                {this.createHtml()}
            </div>
        )
    }

}

export default error;