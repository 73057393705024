import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasDashboard,
    TrasSubjectsofinterest,
    TrasEditProfile,
    TrasTutorsIhavebookmarked,
    TrasUnbookmark,
    TrasTuition,
    TrasBlog,
    TrasBookTuition,
    TrasBelowareaselectionofteachersyouhavetakenlessonswithcontactedorsavedScheduleanewlessonnow,
    TrasBlogsIhavebookmarked,
    TrasAuthor,
    TrasRead,
    TrasTutor,
    TrasCurriculumSubject,
    TrasStartingEndDate,
    TrasStatus,
    TrasTuitionFee,
    TrasDetails,
    TrasContactdetails,
    TrasDemo,
    TrasProgressReport,
    TrasInpreparation,
    TrasInProgress,
    TrasConfirmingPayment,
    TrasConfirmendoftuition,
    TrasTuitionfeeawaitingtransfertotutor,
    TrasTuitionfeetransfercomplete,
    TrasTuitionhasbeencompleted,
    TrasShowmoretuitions,
    TrasFoldtuitions,
    TrasTuitionRequest,
    Trassessionsweek,
    Trasothershavealreadyapplied,
    TrasHourlyrate,
    TrasTuitionBill,
    TrasYoucurrentlydonthaveanytuitiongoingon,
    TrasBookmoretuitionwithyourprevioustutors
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import qs from 'query-string';

import { GetAjaxURL, NoImgContens } from '../../../config/AjaxConfig';
import { STATE_NOTIFY, showNotify, moneyFormat } from '../../extra/cusUtil';
import defaultImgSrc from '../../../images/backuptutorpic.png';
import DashboardQuestions from '../../utils/DashboardQuestions';

import { InfinityController, INFI_STATE } from '../../extra/InfinityScroll';
import '../../utils/InfinityScroll.css';

import './student.css';
import './sdashboard.css';

//window
const $ = window.jQuery;

class SdMoocCard extends Component {
    calculateDiscount = (fee, discountRate) => {
        return Math.round(fee * (100 - discountRate) / 100);
    }

    render() {
        return (
            !this.props.isExpire ?
                <div className="sd-mooc-card-item">
                    <div className="sd-mooc-card">
                        <div className="sd-mooc-card-top">
                            {/* topic */}
                            <h3>{this.props.topic}<br />
                                {this.props.topic2}
                            </h3>
                            {/* tutor */}
                            <div className="sd-mooc-tutor" data-id={this.props.tutorId}>
                                <div className="sd-mooc-tutor-name">
                                    <p>{this.props.tutorName} 강사</p>
                                </div>
                                <div className="sd-mooc-tutor-pic">
                                    <div className="sd-mooc-tutor-pic-box">
                                        <img src={this.props.tutorPic} onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sd-mooc-card-bottom">
                            <div className="sd-mooc-price-box">
                                {
                                    this.props.discountedFee == 0 ?
                                        <span className="sd-mooc-original-price">₩{this.props.originalFee.toLocaleString('en')}</span> :
                                        <span className="sd-mooc-original-price is-discount">₩{this.props.originalFee.toLocaleString('en')}</span>
                                }
                                &nbsp;
                                <span className="sd-mooc-now-price">
                                    {this.props.discountedFee == 0 ? "" : "₩" + this.calculateDiscount(this.props.originalFee, this.props.discountedFee).toLocaleString('en')}
                                </span>
                            </div>
                            {/* detail info(totalMoocCount, totalMoocTime, totalMoocDay(total days of subscription)) */}
                            <button className="mooc-apply-btn" onClick={this.props.onClick}>수강중</button>

                            <p className="sd-mooc-time">총
                        <span>{this.props.totalMooc}강</span>/<span>{this.props.totalMoocTime}시간/</span>
                                <span>{this.props.totalMoocDay}일</span>
                            </p>
                        </div>
                    </div>
                </div>
                :
                <div className="sd-mooc-card-item">
                    <div className="sd-mooc-card expired-mooc-card">
                        <div className="sd-mooc-card-top">
                            {/* topic */}
                            <h3>{this.props.topic}
                                {this.props.topic2}
                            </h3>
                            {/* tutor */}
                            <div className="sd-mooc-tutor">
                                <div className="sd-mooc-tutor-name">
                                    <p>{this.props.tutorName} 강사</p>
                                </div>
                                <div className="sd-mooc-tutor-pic">
                                    <div className="sd-mooc-tutor-pic-box">
                                        <img src={this.props.tutorPic} onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sd-mooc-card-bottom">
                            <div className="sd-mooc-price-box">
                                {/* price */}
                                {
                                    this.props.discountedFee == 0 ?
                                        <span className="sd-mooc-original-price">₩{this.props.originalFee.toLocaleString('en')}</span> :
                                        <span className="sd-mooc-original-price is-discount">₩{this.props.originalFee.toLocaleString('en')}</span>
                                }
                                &nbsp;
                                <span className="sd-mooc-now-price">
                                    {this.props.discountedFee == 0 ? "" : "₩" + this.calculateDiscount(this.props.originalFee, this.props.discountedFee).toLocaleString('en')}
                                </span>
                            </div>
                            {/* detail info(totalMoocCount, totalMoocTime, totalMoocDay(total days of subscription)) */}
                            <button className="mooc-apply-btn" onClick={this.props.onClick}>상세보기</button>

                            <p className="sd-mooc-time">총
                    <span>{this.props.totalMooc}강</span>/<span>{this.props.totalMoocTime}시간/</span>
                                <span>{this.props.totalMoocDay}일</span>
                            </p>
                        </div>
                    </div>
                </div>
        )
    }
}
class StudentDashboard extends Component {
    state = {
        lang: 'ko',
        studentNav: 1,
        studentId: '',
        userData: {
            profile: {},
            user: {}
        },
        noTuition: true,

        moocs: [],

        // INFINITY SCROLL CONTROL
        mockOnlineTuition: [],                  // 전체 데이터
        currentmockOnlineData: [],             // 현재 데이터
        InfinityController: null,              // InfinityController

        tuitionRequests: [],
        currentTuitionRequests: [],
        InfinityControllerTuitionRequest: null,


        initDataCalling: false,

        needTuition: false,
        isCallingTuition: false,
        needTuitionRequest: false,
        isCallingTuitionRequest: false,


        showTuitionMode: 'less',
        contactModal: {},
        detailModal: {},
        itemState: {
            totalCount: 0,                             // DEFAULT 0
            currentCount: 0,                           // DEFAULT 0
            isMoreDone: false,
        }
    }
    componentDidMount = () => {
        this.getUserData();
    }

    getUserData = async () => {
        if (this.state.initDataCalling) return;
        this.setState({
            ...this.state,
            initDataCalling: true,
        });

        const apiurls = await GetAjaxURL();

        const thisUserurl = apiurls.apiThisUser;
        const thisUserRes = await axios.get(thisUserurl).catch((err) => { return err; })
        const thisUserData = thisUserRes.data;

        const thisMoocurl = apiurls.mooc.this_student;
        const thisMooRes = await axios.get(thisMoocurl).catch((err) => { return err; })
        const thisMoocData = thisMooRes.data;

        this.setState({
            ...this.state,
            userData: thisUserData,
            studentId: thisUserData.profile.id,
            showMoney: (parseFloat(thisUserData.profile.gcoin)).toFixed(2),
            moocs: thisMoocData,
        }, () => {
            const tuitionInit = document.getElementById('tuitionInit');
            if (tuitionInit) {
                tuitionInit.click();
            }
        });
    }

    getTuition = async () => {

        if (this.state.needTuition) return;

        this.setState({
            ...this.state,
            needTuition: true,
        });


        const apiurls = await GetAjaxURL();


        const url = apiurls.tuition;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;

        if (data.tuitions && data.tuitions.length > 0) {
            this.setState({
                noTuition: false
            });
        }
        let tuitionTmp = [];
        let tuitions = data.tuitions || [];
        for (let i = 0; i < tuitions.length; i++) {
            let obj = {};
            obj.currency = tuitions[i].currency;
            obj.picUrl = tuitions[i].tutor_info.picture;
            obj.name = tuitions[i].tutor_info.name;
            obj.full_name = tuitions[i].tutor_info.full_name;
            obj.startTime = tuitions[i].start_time;
            obj.endTime = tuitions[i].end_time;
            obj.session = tuitions[i].session_id;
            obj.status = tuitions[i].status;
            obj.currency = tuitions[i].student_currency;
            obj.contact = tuitions[i].tutor_info.contact_details;
            obj.report = tuitions[i].report;
            obj.ordernum = tuitions[i].ordernum;
            obj.demo = tuitions[i].demo;
            obj.detail = {};
            obj.detail.hours = tuitions[i].hours;
            obj.detail.isCredit = String(tuitions[i].credit_purchase) === "0" ? false : true;
            if (obj.detail.isCredit) {
                obj.detail.isPartialCredit = String(tuitions[i].credit_partial) === "0" ? false : true;
                obj.detail.amountPartialCredit = tuitions[i].credit_partial_amount;
            }

            obj.tutorId = tuitions[i].tutor_id;
            obj.tuition_request_id = tuitions[i].tuition_request_correct;

            if (tuitions[i].student_currency === 1) {
                obj.detail.hourlyRate = Number(tuitions[i].hourly_rate) + 6.72;
            } else if (tuitions[i].student_currency === 2) {
                obj.detail.hourlyRate = Number(tuitions[i].hourly_rate) +
                    (tuitions[i].is_package_tuition ? 100000 :
                        (tuitions[i].is_short_term ? 30000 : 10000)
                    );
            } else {
                obj.detail.hourlyRate = Number(tuitions[i].hourly_rate) + 58;
            }

            obj.detail.tuition = tuitions[i].student_total_amount;
            obj.id = tuitions[i].id;
            obj.detail.subject = [];
            obj.report = tuitions[i].report;

            obj.detail.curriculums = [];
            obj.detail.is_package_tuition = tuitions[i].is_package_tuition;
            obj.detail.is_short_term = tuitions[i].is_short_term;

            // Set curriculums
            for (let m = 0; m < tuitions[i].curriculum_details.length; m++) {
                // if(tuitions[i].curriculum_details[m] === null) continue;
                // if(tuitions[i].curriculum_details[m] === '') continue;

                let isNotAddedCurriculum = true;
                // Check whether curriculum already in array
                for (let n = 0; n < obj.detail.curriculums.length; n++) {
                    if (obj.detail.curriculums[n].title === tuitions[i].curriculum_details[m].curriculum) {
                        isNotAddedCurriculum = false;
                        break;
                    }
                }

                let title = '';
                if (tuitions[i].curriculum_details[m] !== null) {
                    title = tuitions[i].curriculum_details[m].curriculum;
                }

                if (isNotAddedCurriculum) {
                    let curriculum = {
                        title: title,
                        subjects: []
                    }

                    obj.detail.curriculums.push(curriculum);
                }
            }

            // Set subjects in curriculum
            for (let m = 0; m < tuitions[i].curriculum_details.length; m++) {
                for (let n = 0; n < obj.detail.curriculums.length; n++) {
                    let compareCurM = tuitions[i].curriculum_details[m];
                    if (compareCurM !== null) {
                        if (obj.detail.curriculums[n].title === tuitions[i].curriculum_details[m].curriculum) {
                            obj.detail.curriculums[n].subjects.push(tuitions[i].curriculum_details[m].subject);
                        }
                    }
                }
            }
            //obj.detail.subject=['IB Biology HL', 'IB Chemistry HL'];
            tuitionTmp.push(obj)

        }



        const totalCount = tuitionTmp.length;
        let isMoreDone = false;
        let currentCount = 0;
        if (totalCount > 10) {
            currentCount = 10;
        } else {
            currentCount = totalCount;
            isMoreDone = true;
        }


        const InfinityControllers = new InfinityController();
        InfinityControllers.setInit(tuitionTmp);
        const currentmockOnlineData = InfinityControllers.getDataWithCount(INFI_STATE.START_COUNT);



        this.setState({
            ...this.state,
            mockOnlineTuition: tuitionTmp,
            currentmockOnlineData: currentmockOnlineData,
            InfinityController: InfinityControllers,

            itemState: {
                ...this.state.itemState,
                totalCount: totalCount,
                currentCount: currentCount,
                isMoreDone: isMoreDone
            }
        });
    }

    parseRound = (num) => {
        return parseInt((parseFloat(num).toFixed(2)));
    }
    moneyFormat = (s) => {
        if (/[^0-9\\.]/.test(s)) return "invalid value";
        s = s.replace(/^(\d*)$/, "$1.");
        s = (s).replace(/(\d*\.\d\d)\d*/, "$1");
        // s=s.replace(/(\d*\.\d\d)\d*/,"$1");
        s = s.replace(".", ",");
        let re = /(\d)(\d{3},)/;
        while (re.test(s))
            s = s.replace(re, "$1,$2");
        s = s.replace(/,(\d\d)$/, ".$1");
        s = s.replace(/^\./, "0.")
        s = s.substring(0, s.length - 1)
        return s;
    }
    toTeacherProfilePage = (tid) => {
        window.location.href = `/#/teachers/${tid}`;
    }
    unbookTutor = async (tid, index) => {
        const lang = this.state.lang;
        const postData = qs.stringify({
            tutor_id: tid
        }, { arrayFormat: 'bracket' })
        const apiurls = await GetAjaxURL();
        const url = apiurls.unbookTutor;
        const res = await axios.post(url, postData).catch((err) => { return err; });
        const data = res.data;
        let msg = ('cn' === lang ? "发生了错误。" : 'en' === lang ? "An error has occurred." : "에러가 발생했습니다.");
        let type = STATE_NOTIFY.TYPE_NEGATIVIE;
        if (data.status === 'success') {
            msg = ('cn' === lang ? "取消收藏成功！" : 'en' === lang ? "Unbookmark Success" : "해당 선생님을 북마크에서 삭제하였습니다.", "success");
            type = STATE_NOTIFY.TYPE_POSITIVE;
            let userData = this.state.userData;
            userData.profile.bookmarked_tutors.splice(index, 1);
            this.setState({
                userData: userData
            })
        }
        showNotify(msg, type);
    }
    unbookBlog = async (bid, index) => {
        const lang = this.state.lang;
        const postData = qs.stringify({
            blog_id: bid
        }, { arrayFormat: 'bracket' })
        const apiurls = await GetAjaxURL();
        const url = apiurls.unbookmarkBlog;
        const res = await axios.post(url, postData).catch((err) => { return err; })
        const data = res.data
        let msg = ('cn' === lang ? "发生了错误。" : 'en' === lang ? "An error has occurred." : "에러가 발생했습니다.");
        let type = STATE_NOTIFY.TYPE_NEGATIVIE;
        if (data.status === 'success') {
            msg = ('cn' === lang ? "取消收藏成功！" : 'en' === lang ? "Unbookmark Success" : "해당 선생님을 북마크에서 삭제하였습니다.", "success");
            type = STATE_NOTIFY.TYPE_POSITIVE;
            let userData = this.state.userData
            userData.profile.bookmarked_blogs.splice(index, 1);
            this.setState({
                userData: userData
            })
        }
        showNotify(msg, type);
    }
    changeStudentNav = (value) => {

        this.setState({
            studentNav: value
        }, () => {
            if (String(this.state.studentNav) === '2') {
                // 버튼이 눌리는 순간, 데이터 호출되도록 변경
                this.ajaxTuitionRequest();
            }
        })
    }
    checkCreditHistory = () => {
        window.location.href = `/#/credit/balance`;
    }
    buyCredit = () => {
        window.location.href = `/#/credit/buy`;
    }
    changeContact = (key) => {
        debugger
        let mockOnlineTuition = this.state.mockOnlineTuition
        let data = this.deepClone(mockOnlineTuition[key].contact)
        data.name = mockOnlineTuition[key].name
        this.setState({
            contactModal: data
        })
    }
    deepClone(data) {
        const type = this.judgeType(data);
        let obj;
        if (type === 'array') {
            obj = [];
        } else if (type === 'object') {
            obj = {};
        } else { // 不再具有下一层次
            return data;
        }
        if (type === 'array') { // eslint-disable-next-line
            for (let i = 0, len = data.length; i < len; i++) {
                obj.push(this.deepClone(data[i]));
            }
        } else if (type === 'object') { // 对原型上的方法也拷贝了....
            // eslint-disable-next-line
            for (const key in data) {
                obj[key] = this.deepClone(data[key]);
            }
        }
        return obj;
    }
    judgeType(obj) { // tostring会返回对应不同的标签的构造函数
        const toString = Object.prototype.toString;
        const map = {
            '[object Boolean]': 'boolean',
            '[object Number]': 'number',
            '[object String]': 'string',
            '[object Function]': 'function',
            '[object Array]': 'array',
            '[object Date]': 'date',
            '[object RegExp]': 'regExp',
            '[object Undefined]': 'undefined',
            '[object Null]': 'null',
            '[object Object]': 'object',
        };
        if (obj instanceof Element) {
            return 'element';
        }
        return map[toString.call(obj)];
    }
    changeDetail = (key) => {
        let data = this.deepClone(this.state.mockOnlineTuition[key])
        this.setState({
            detailModal: data
        })
    }
    goToTuitionReview = (ordernum) => {
        window.location.href = '/#/reviews/new/' + ordernum;
    }
    changeShowMoreLessMode = (typeStr, modeStr) => {
        if ('tuition' === typeStr) {
            this.setState({
                ...this.state,
                showTuitionMode: modeStr,
                itemState: {
                    ...this.state.itemState,
                }
            })
            if ('full' === modeStr) {
                $('.more-tuition-tr').removeClass('ng-hide');
            } else if ('less' === modeStr) {
                $('.more-tuition-tr').addClass('ng-hide');
            }
        }
    }

    addMoreItems = () => {
        const totalCount = this.state.itemState.totalCount;
        let currentCount = this.state.itemState.currentCount;
        let isMoreDone = false;

        const sBozVolume = 5;

        if (totalCount >= currentCount + sBozVolume) {
            currentCount += sBozVolume;
        } else {
            currentCount = totalCount;
            isMoreDone = true;
        }
        this.setState({
            ...this.state,
            itemState: {
                ...this.state.itemState,
                currentCount: currentCount,
                isMoreDone: isMoreDone,
            }
        }, () => {
        })
    }

    removeMoreItems = () => {
        const isMoreDone = false;
        const currentCount = 10;
        this.setState({
            ...this.state,
            itemState: {
                ...this.state.itemState,
                currentCount: currentCount,
                isMoreDone: isMoreDone,
            }
        })
    }




    createBreadcrumb = () => {
        return (
            <div className="container">
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasDashboard /></li>
                </ol>
            </div>
        )
    }
    goToTutorhive = () => {
        if(window.confirm("튜터하이브로 이동하셔서 프로필을 수정해주시길 바랍니다. 이동하시겠습니까?")){
            window.location.href="https://tutorhive.kr"
        }
    }
    createLeftPanelA = () => {
        let personalInformation = this.state.userData
        if (personalInformation.user.last_name) {
            return (
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-offset-2 col-xs-offset-2 col-md-8 col-xs-8 margin-top-10 margin-bottom-10 text-center sd-img-fix">
                                <img alt="img" src={personalInformation.profile.picture || defaultImgSrc} alt="" className="img-circle img-responsive display-inline" />
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-md-12 user-info-widget">
                                <h4 className="name">
                                    {personalInformation.user.last_name}{personalInformation.user.first_name}
                                    <small> ({personalInformation.profile.school})</small>
                                </h4>
                                <p className="text-center gray2-font-color">
                                    {personalInformation.profile.about_myself}
                                </p>
                                <div className="text-center">
                                    {/* <a href={`/#/students/${personalInformation.profile.id}`} className="btn btn-xs btn-default">프로필 보기</a> */}
                                    <a onClick={this.goToTutorhive} className="btn btn-xs btn-default"><TrasEditProfile /></a>
                                </div>
                                <p className="contacts margin-top-10">
                                    {
                                        personalInformation.user.email.length > 0 ?
                                            <font>
                                                <i className="fa fa-envelope"></i>
                                                <span className={1 === personalInformation.user.is_email_verified ? 'color-gurume-blue' : null}>
                                                    {personalInformation.user.email}
                                                </span>
                                                <br />
                                            </font> : ''
                                    }
                                    {
                                        personalInformation.user.phone.length > 0 ?
                                            <font>
                                                <i className="fa fa-phone"></i>
                                                <span className={1 === personalInformation.user.is_kakao_verified ? 'color-gurume-blue' : null}>
                                                    {personalInformation.user.phone}</span>
                                                <br />
                                            </font> : ''
                                    }
                                    {
                                        personalInformation.user.skype.length > 0 ?
                                            <font>
                                                <i className="fa fa-skype"></i>
                                                {personalInformation.user.skype} <br />
                                            </font> : ''
                                    }
                                    {
                                        personalInformation.user.wechat.length > 0 ?
                                            <font>
                                                <i className="fa fa-wechat"></i>
                                                {personalInformation.user.wechat} <br />
                                            </font> : ''
                                    }
                                    {
                                        personalInformation.user.whatsapp.length > 0 ?
                                            <font>
                                                <i className="fa fa-whatsapp"></i>
                                                {personalInformation.user.whatsapp} <br />
                                            </font> : ''
                                    }
                                    {
                                        personalInformation.user.kakaotalk.length > 0 ?
                                            <font>
                                                <span className="kakaotalk-span">
                                                    <img alt="img" src="/images/kakao_icon.png" />
                                                </span>
                                                {personalInformation.user.kakaotalk} <br />
                                            </font> : ''
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }
    createLeftPanelB = () => {
        let personalInformation = this.state.userData
        let gcoin = parseFloat(personalInformation.profile.gcoin ? personalInformation.profile.gcoin : 0.00)
        return (
            <div className="panel">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-12 user-info-widget">
                            <div className="contacts margin-top-10">
                                <font>
                                    보유 크레딧: {moneyFormat(gcoin) + ' 원'}
                                </font>
                                <div className="text-center contacts-btns">
                                    <a href={'/#/credit/balance'} className="btn btn-xs btn-default">{'사용내역'}</a>
                                    <a href={'/#/credit/buy'} className="btn btn-xs btn-default">{'구매'}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // 관심 과목
    createLeftPanelC = () => {
        const personalInformation = this.state.userData
        const items = personalInformation.profile.expected_subjects || [];
        const list = items.map((item, index) =>
            <button key={'ExpectedSubjects' + index} className={['teacher-profile-subject-btn', item.test_type === '1' && 'pink-color-border', item.test_type === '2' && 'orange-color-border', item.test_type === '3' && 'green-color-border'].join(' ')}>{item.subject}</button>
        );
        return (
            <div className="panel">
                <div className="panel-body">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="blue-font-color"><TrasSubjectsofinterest /></h4>
                            {list}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // 관심 선생님
    createLeftPanelD = () => {
        const personalInformation = this.state.userData
        const items = personalInformation.profile.bookmarked_tutors || [];

        const list = items.map((item, index) => {
            let subjectData = '';
            if (item.u__degree_1) {
                if (item.u__degree_1['subject']) {
                    subjectData = item.u__degree_1['subject'];
                }
            }
            return (
                <div className="bookmark-tutors" key={'bookMark' + index}>
                    <div className="bookmark-tutors-wrapper">
                        <div key={'TeacherProfile' + index} className="bookmark-tutors-box">
                            <div className="bookmark-tutors-main">
                                <div className="bookmark-tutors-left">
                                    <div className="bookmark-tutors-img">
                                        <img alt="img" className="img-responsive img-circle cursor" src={item.picture || defaultImgSrc} onClick={() => this.toTeacherProfilePage(item.id)} />
                                    </div>
                                    <button className="btn btn-blue-border-sm" onClick={() => this.unbookTutor(item.id, index)}>
                                        <TrasUnbookmark />
                                    </button>
                                </div>
                                <div className="bookmark-tutors-right" onClick={() => this.toTeacherProfilePage(item.id)}>
                                    <p className="no-margin-bottom"><b>{item.full_name}</b> -{item.undergraduate_school['university_name']}&nbsp;|&nbsp;{subjectData}
                                    </p>
                                    {
                                        item.curriculums.map((curriculum, index2) => {
                                            return <button key={'TeacherProfileCurriculum' + index2} className={['teacher-profile-subject-btn', curriculum.test_type === '1' && 'pink-color-border', curriculum.test_type === '2' && 'orange-color-border', curriculum.test_type === '3' && 'green-color-border'].join(' ')}>{curriculum.curriculum}
                                            </button>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
        let tutors = personalInformation.profile.bookmarked_tutors || [];
        if (tutors.length > 0) {
            return (
                <div className="panel">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-md-12 infi-wapper">
                                <h4 className="blue-font-color"><TrasTutorsIhavebookmarked /></h4>
                                {list}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return ('')
        }
    }
    createRightNav = () => {
        const studentNav = this.state.studentNav;
        return (
            <div className="btn-group btn-group-justified select-nav">
                <button className={`btn btn-default ${studentNav === 1 ? "btn-gurume-blue" : ''}`} onClick={() => this.changeStudentNav(1)}><TrasTuition /></button>
                <button className={`btn btn-default ${studentNav === 2 ? "btn-gurume-blue" : ''}`} onClick={() => this.changeStudentNav(2)}>수업신청</button>
                <button className={`btn btn-default ${studentNav === 3 ? "btn-gurume-blue" : ''}`} onClick={() => this.changeStudentNav(3)}><TrasBlog /></button>
                <button className={`btn btn-default ${studentNav === 4 ? "btn-gurume-blue" : ''}`} onClick={() => this.changeStudentNav(4)}>MOOC</button>
            </div>
        )
    }
    createRightContent = () => {
        const studentNav = this.state.studentNav;
        return (studentNav === 1 ? this.createRightContentOne() : studentNav === 2 ? this.createRightContentTwo() : studentNav === 3 ? this.createRightContentThree() : this.createRightContentFour())
    }
    createTuitionModal = () => {
        return (
            <div id="tuitionModal" className="modal fade" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"><TrasBookTuition /></h4>
                            <button type="button" className="close" data-dismiss="modal">
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                <TrasBelowareaselectionofteachersyouhavetakenlessonswithcontactedorsavedScheduleanewlessonnow />
                            </p>
                            <div className="row">
                                {/* <div className="col-md-4"
                                 ng-repeat="(key, bookAgainItem) in bookAgainInfo">
                                <div className="book-tuition-tutor-thumbnail">
                                    <div className="col-md-4">
                                        <img alt="img" className="img-circle dash-img-size"
                                             ng-src={bookAgainItem.picUrl}>
                                    </div>
                                    <div className="col-md-8">
                                        <h4>{bookAgainItem.teacherName}</h4>
                                        <p>{bookAgainItem.subjects.join(",")}</p>
                                        <button data-dismiss="modal"
                                                ng-click="bookAgainHref(bookAgainItem.teacherId)"
                                                type="button">
                                                <TrasBookTuition/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createContactModal = () => {
        let modalData = this.state.contactModal
        // if(!modalData.name) return
        return (
            <div id="contactDetailsModal" className="modal fade" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{modalData.name} - <TrasContactdetails /></h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-left">
                            <p><img alt="contact_details_email" className="student-reg-contact-pic student-reg-contact-pic-radius"
                                src="../images/contact_details_email.png" />
                                {modalData.email}</p>
                            <p><img alt="contact_details_phone" className="student-reg-contact-pic"
                                src="../images/contact_details_phone.png" />
                                {modalData.phone}</p>
                            <p><img alt="contact_details_kakaotalk" className="student-reg-contact-pic"
                                src="../images/contact_details_kakaotalk.png" />
                                {modalData.kakaotalk}</p>
                            <p><img alt="contact_details_skype" className="student-reg-contact-pic"
                                src="../images/contact_details_skype.png" />
                                {modalData.skype}</p>
                            <p><img alt="contact_details_wechat" className="student-reg-contact-pic"
                                src="../images/contact_details_wechat.png" />
                                {modalData.wechat}</p>
                            <p><img alt="contact_details_whatsapp" className="student-reg-contact-pic"
                                src="../images/contact_details_whatsapp.png" />
                                {modalData.whatsapp}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createDetailsModal = () => {
        let modalData = this.state.detailModal;
        if (!modalData.detail) return;
        if (!modalData.detail.curriculums) return;

        let DisplayCurrency = 'KRW';
        if (modalData.currency === 1) {
            DisplayCurrency = 'RMB';
        } else if (modalData.currency !== 2 && modalData.currency !== 1) {
            DisplayCurrency = 'GBP';
        }

        return (
            <div id="viewDetailsModal" className="modal fade" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"><TrasDetails /></h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4 className="blue-font-color">결제</h4>
                            {
                                modalData.detail.isCredit ?
                                    (modalData.detail.isPartialCredit ? <h4>Partial 크레딧 {modalData.detail.amountPartialCredit}</h4> : <h4>크레딧</h4>)
                                    : <h4>계좌이체</h4>
                            }
                            <h4 className="blue-font-color">시간</h4>
                            <h4>{parseFloat((modalData.detail && modalData.detail.hours) || 0) +
                                parseFloat(modalData.demo ? 0.5 :
                                    0)}</h4>
                            {/* <h4 className="blue-font-color">
                                <TrasHourlyrate />
                            </h4> */}
                            <h4>{modalData.detail.hourlyRate} {' ' + DisplayCurrency} </h4>
                            <h4 className="blue-font-color"><TrasTuitionFee /></h4>
                            <h4><span>{modalData.detail.tuition}</span>{' ' + DisplayCurrency}</h4>
                            <div>
                                <a className="btn pop-up-btn"
                                    href={`/#/tuitions/confirm/${modalData.ordernum}`}
                                    target="_blank"><TrasTuitionBill /></a>
                            </div>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>

                </div>
            </div>
        )
    }


    scrollDectectFunc = () => {
        const isScrollDone = this.state.InfinityController.scrollDetect('infiWapper3', 'infiList3');
        if (isScrollDone) {
            const currentmockOnlineData = this.state.InfinityController.getDataWithCount(INFI_STATE.REPEAT_COUNT);
            this.setState({
                ...this.state,
                currentmockOnlineData: currentmockOnlineData,
            });
        }
    }

    scrollDectectFuncRequest = () => {
        const isScrollDone = this.state.InfinityControllerTuitionRequest.scrollDetect('infiWapper1', 'infiList1');
        if (isScrollDone) {
            const currentTuitionRequests = this.state.InfinityControllerTuitionRequest.getDataWithCount(5);
            this.setState({
                ...this.state,
                currentTuitionRequests: currentTuitionRequests,
            });
        }
    }


    ajaxTuitionRequest = async () => {

        if (this.state.isCallingTuitionRequest) return;

        this.setState({
            ...this.state,
            isCallingTuitionRequest: true,
        })

        const apiurls = await GetAjaxURL();

        const tuitionReqUrl = apiurls.tuitionMyRequest;
        const tuitionReqRes = await axios.get(tuitionReqUrl).catch((err) => { return err; })
        const tuitionRequestData = tuitionReqRes.data;

        const InfinityControllerTuitionRequest = new InfinityController();
        InfinityControllerTuitionRequest.setInit(tuitionRequestData);
        const currentTuitionRequests = InfinityControllerTuitionRequest.getDataWithCount(20);

        this.setState({
            ...this.state,
            needTuitionRequest: true,  // 누르면 이제 안 바뀌는 값.

            tuitionRequests: tuitionRequestData,
            currentTuitionRequests: currentTuitionRequests,
            InfinityControllerTuitionRequest: InfinityControllerTuitionRequest,
        })

    }


    createTuitionRequest = () => {

        if (!this.state.needTuitionRequest) {
            return (
                <div>
                    <button className='btn pop-up-btn'>
                        {this.state.isCallingTuitionRequest ?
                            '잠시만 기다려주세요.'
                            :
                            '로딩 중...'
                        }
                    </button>
                </div>
            )
        }


        const tuitionRequests = this.state.currentTuitionRequests || []
        const list2 = tuitionRequests.map((item, index) => {

            return (
                <div key={'TuitionRequests' + index} className="col-md-6 ">
                    <div className="card_thumbnail border-blue-2 border-radius-4">
                        <p className="ng-curriculum-subject"><TrasCurriculumSubject />:
                            {
                                item.curriculums.map((curriculum, index2) => {
                                    const tempCurriculum = curriculum;
                                    if (tempCurriculum) {
                                        return <span key={'TuitionCurriculum' + index2}>{tempCurriculum.curriculum} {tempCurriculum.subject}/ </span>
                                    } else {
                                        return <span key={'TuitionCurriculum' + index2}></span>;
                                    }
                                })
                            }
                        </p>
                        <p className="ng-start-end-date"><TrasStartingEndDate />:
                        {
                                item.end_time !== '2100-01-01' ?
                                    <span>
                                        {item.start_time} ~ {item.end_time} ({item.hours}<Trassessionsweek />)
                            </span>
                                    :
                                    <span>
                                        {item.start_time} ~ 일정 조율 예정 ({item.hours}<Trassessionsweek />)
                            </span>
                            }
                        </p>
                        {
                            item.status >= 0 ?
                                <div>
                                    <p><i>(*{item.status}<Trasothershavealreadyapplied />)</i></p>
                                </div>
                                :
                                <div className="ng-applier-count">
                                    <p><i>(*{(item.status) - 1}<Trasothershavealreadyapplied />)</i></p>
                                </div>
                        }
                        <a className="btn cancel-btn fix-top-margin" href={'/#/tuition_requests/' + item.id}>
                            <TrasDetails />
                        </a>
                    </div>
                </div>
            )
        }
        );

        return (
            <div className="panel">
                <div className="panel-body infi-wapper" id='infiWapper1' onScroll={this.scrollDectectFuncRequest}>
                    <h4 className="blue-font-color"><TrasTuitionRequest /></h4>
                    <div>
                        <div className="row" id='infiList1'>
                            {list2}
                        </div>
                    </div>

                </div>
            </div>
        )
    }


    createTuitionList = () => {


        if (!this.state.needTuition) {
            return (
                <div>
                    {this.state.isCallingTuition ?
                        <button className='btn pop-up-btn'>데이터를 불러오고 있습니다.</button>
                        :
                        <button className='btn pop-up-btn' id='tuitionInit' onClick={this.getTuition}>로딩 중...</button>
                    }
                </div>
            )

        }

        // needTuition : false,
        // isCallingTuition : false,


        const items = this.state.currentmockOnlineData;

        const list = items.map((item, index) => {

            let reportUrl = '';
            let reportType;
            if (item.report) {
                if (item.report.url) {
                    reportUrl = item.report.url;
                    reportType = String(item.report.demo) === '0' ? "Progress Report" : "Demo Class Report";
                }
            }

            let requestUrl = '/#/tuition_request/' + item.tuition_request_id;

            return (
                <tr key={'MockOnlineTuition' + index}>
                    <td style={{ width: "15%" }}>
                        <img alt="img" className="img-circle dash-img-size cursor"
                            src={item.picUrl ? item.picUrl : defaultImgSrc} onClick={() => this.toTeacherProfilePage(item.tutorId)}
                            onError={function (e) { e.target.src = '../images/backuptutorpic.png'; }}
                        />
                        <p>{item.full_name} {item.tutor_id}</p>
                        <button type="button" className="btn pop-up-btn" data-toggle="modal" data-target="#contactDetailsModal"
                            onClick={() => this.changeContact(index)}><TrasContactdetails />
                        </button>
                    </td>
                    <td style={{ width: "30%" }}>
                        {
                            item.detail.curriculums.map((curriculum, index2) => {
                                return <p key={'DetailsCurriculum' + index2}>
                                    (<b>{curriculum.title}</b>{curriculum.subjects.join(", ")})<br />
                                </p>;
                            })
                        }
                        {
                            (String(item.status) === '2' || String(item.status) === '4' || String(item.status) === '5' || String(item.status) === '6' || String(item.status) === '7') ?
                                <a href={reportUrl} target="_blank">
                                    <button className="btn pop-up-btn">{reportType}<br /></button>
                                </a>
                                : ''
                        }
                        {
                            item.tuition_request_id != 0 ?
                                <a href={requestUrl}>
                                    <br /><button className="btn pop-up-btn">Tuition Request</button>
                                </a> : ''
                        }
                    </td>
                    <td style={{ width: "15%" }}>
                        <div>
                            <p>
                                {item.startTime} ~
                                <br />
                                {item.endTime}
                                <br />
                                ({item.session})
                            </p>

                        </div>
                    </td>
                    <td style={{ width: "20%" }}>
                        <p>
                            {
                                item.status == '0' ? <TrasInpreparation /> : item.status == '2' ? <TrasInProgress /> : item.status == '3' ? <TrasConfirmingPayment /> :
                                    item.status == '4' ? <TrasInProgress /> : item.status == '5' ? <TrasConfirmendoftuition /> : item.status == '6' ? <TrasTuitionfeeawaitingtransfertotutor /> : <TrasTuitionfeetransfercomplete />
                            }
                        </p>
                        {
                            (String(item.status) === '2' || String(item.status) === '4' || String(item.status) === '5') && item.demo != 1 ?
                                <div>
                                    <button className="btn pop-up-btn margin-bottom-5" onClick={() => this.goToTuitionReview(item.ordernum)}>
                                        <TrasTuitionhasbeencompleted />
                                    </button>
                                    <div className="clearfix"></div>
                                    <DashboardQuestions />

                                </div>
                                : ''
                        }
                    </td>
                    <td className="text-center" style={{ width: "20%" }}>
                        <p>{this.moneyFormat(this.parseRound(item.detail.tuition).toString())} </p>
                        <button type="button" className="btn pop-up-btn text-center" data-toggle="modal" data-target="#viewDetailsModal" onClick={() => this.changeDetail(index)}>
                            <TrasDetails />
                        </button>
                    </td>
                </tr>
            )
        }
        );

        return list;
    }

    createRightContentOne = () => {
        const showTuitionMode = this.state.showTuitionMode
        const noTuition = this.state.noTuition

        return (
            <div>
                <div className="panel panel-default">
                    <div className="panel-body">
                        <h4 className="blue-font-color margin-bottom-20"><TrasTuition /></h4>
                        <div className="row dashboard-student-tuition">
                            <div className="col-md-12">

                                {
                                    noTuition === true ?
                                        <div className='fix-s-model-body'>

                                            <p><TrasYoucurrentlydonthaveanytuitiongoingon /></p>
                                            <p><TrasBookmoretuitionwithyourprevioustutors /></p>
                                            <button type="button" className="btn book-tutor blue-color" data-toggle="modal" data-target="#tuitionModal"><span
                                                className="glyphicon glyphicon-calendar"></span> <TrasBookTuition />
                                            </button>
                                            {this.createTuitionModal()}
                                        </div>
                                        : ''
                                }
                                {this.createContactModal()}
                                {this.createDetailsModal()}
                                <div className="table-responsive infi-wapper" id='infiWapper3' onScroll={this.scrollDectectFunc}>
                                    <table className="table">
                                        <thead>
                                            <tr className="text-center">
                                                <td><p><TrasTutor /></p></td>
                                                <td><p><TrasCurriculumSubject /></p></td>
                                                <td><p>기간</p></td>
                                                <td><p><TrasStatus /></p></td>
                                                <td><p><TrasTuitionFee /></p></td>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center" id='infiList3'>
                                            {this.createTuitionList()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }

    createRightContentTwo = () => {
        return this.createTuitionRequest();
    }


    createRightContentThree = () => {
        const lang = this.state.lang;
        const personalInformation = this.state.userData
        const items = personalInformation.profile.bookmarked_blogs || [];
        const list = items.map((item, index) =>
            <div key={'PersonalInformation' + index} className="col-sm-6 col-md-6">
                <div className="thumbnail blog-article">
                    <img alt="img" src={item.image} className="blog-article-thumbnail" onError={function (e) { e.target.src = NoImgContens; }} />
                    <div className="caption">
                        <p className="blog-p-inline blue-font-color">{item.created_at}</p>
                        {
                            lang == 'en' ?
                                <div>
                                    <h4>{item.title_en}</h4>
                                    <p className="dashboard-blogs-intro">{item.intro_en}</p>
                                </div>
                                : lang == 'cn' ?
                                    <div>
                                        <h4>{item.title_cn}</h4>
                                        <p className="dashboard-blogs-intro">{item.intro_cn}</p>
                                    </div>
                                    :
                                    <div>
                                        <h4>{item.title_kr}</h4>
                                        <p className="dashboard-blogs-intro">{item.intro_kr}</p>
                                    </div>
                        }
                        <p><b><TrasAuthor /></b>{item.user.last_name}{item.user.first_name}</p>
                        <a href={`/#/blogs/${item.id}`} className="btn blog-btn" >
                            <TrasRead />
                        </a>
                        <button className="btn btn-default" onClick={() => this.unbookBlog(item.id, index)}>
                            <TrasUnbookmark />
                        </button>
                    </div>
                </div>
            </div>
        );
        return (
            <div className="panel">
                <div className="panel-body">
                    <h4 className="blue-font-color margin-bottom-20 dash-p-title"><TrasBlogsIhavebookmarked /></h4>
                    <div className="row dashboard-student-questions">
                        {list}
                    </div>
                </div>
            </div>
        )
    }

    clickToTdMoocComponent = (e) => {
        window.location.href = '/#/mooc/new';
    }

    moveToMoocShow = (id) => {
        window.location.href = '/#/mooc/' + id;
    }

    createRightContentFour = () => {
        const mooc_list = [];
        for (let i = 0; i < this.state.moocs.length; i++) {
            let subscribe = this.state.moocs[i];
            let mooc_topic = subscribe['mooc_topic']
            mooc_list.push(
                <SdMoocCard
                    tutorId={mooc_topic['tutor_id']}
                    topic={mooc_topic['topic']}
                    topic2={mooc_topic['topic2']}
                    tutorName={mooc_topic["tutor_full_name"]}
                    tutorPic={mooc_topic['tutor_pic']}
                    originalFee={mooc_topic['fee']}
                    discountedFee={mooc_topic['fee_discount']}
                    totalMooc={mooc_topic['total_moocs']}
                    totalMoocTime={mooc_topic['total_duration']}
                    totalMoocDay={mooc_topic['subscription_days']}
                    isExpire={!mooc_topic['is_subscribed']}
                    onClick={() => this.moveToMoocShow(mooc_topic['id'])}
                />);
        }
        return (
            <div className="panel">
                <div className="panel-body">
                    <div className="sd-mooc-card-wrapper">
                        {mooc_list}
                    </div>
                </div>
            </div>
        )
    }

    createLeftPart = () => {
        return (
            <div className="col-md-3">
                {this.createLeftPanelA()}
                {this.createLeftPanelB()}
                {this.createLeftPanelC()}
                {this.createLeftPanelD()}
            </div>
        )
    }
    createRightPart = () => {
        return (
            <div className="col-md-9">
                {this.createRightNav()}
                {this.createRightContent()}
            </div>
        )
    }
    render = () => {
        return (
            <div className='default-wrapper sdashboard-wrapper sdashboard-container'>
                {this.createBreadcrumb()}
                <header className="bg-header student-edit-header"></header>
                <div className="row splitted-card-row">
                    <div className="container splitted-card-container">
                        <div className="row">
                            {this.createLeftPart()}
                            {this.createRightPart()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(StudentDashboard);