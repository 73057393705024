import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasLecture,
    TrasBuyCredit,
} from '../../../Translate/EachTranslateC';
import ReactTooltip from 'react-tooltip';
import axios from '../../../config/axios';
import { GetAjaxURL, NoImgContens, postConfig } from '../../../config/AjaxConfig';
import { STATE_NOTIFY, showNotify, moneyFormat } from '../../extra/cusUtil';
import { isAdmin } from '../../extra/utils';
import './moocpay.css';

class MoocnewPayCard extends Component {
    calculateDiscount = (fee, discountRate) => {
        return Math.round(fee * (100 - discountRate) / 100);
    }

    render() {
        const discountFee = this.calculateDiscount(this.props.originalPrice, this.props.nowPrice).toLocaleString('en')
        return (
            <div className="moocpay-card-item" key={this.props.id}>
                <div className="mooc-card">
                    <div className="mooc-card-top">
                        <h3>{this.props.topic}</h3>
                        <div className="mooc-tutor">
                            <div className="mooc-tutor-name">
                                <p>{this.props.tutorName} 강사</p>
                            </div>
                            <div className="mooc-tutor-pic">
                                <div className="mooc-tutor-pic-box">
                                    <img src={this.props.tutorPic} onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mooc-card-bottom">
                        <div className="mooc-price">
                            {
                                this.props.nowPrice == 0 ?
                                    <span className="original-price">₩{this.props.originalPrice.toLocaleString('en')}</span> :
                                    <span className="original-price is-discount">₩{this.props.originalPrice.toLocaleString('en')}</span>
                            }
                            <span className="now-price">
                                &nbsp;{
                                    this.props.nowPrice == 0 ? "" : '₩' + discountFee
                                }
                            </span>
                        </div>
                        {
                            this.props.isSubscribed ?
                                <button className="mooc-apply-btn">수강중</button>
                                :
                                <button className="mooc-apply-btn">상세보기</button>
                        }
                        <p className="mooc-time">총 <span>{this.props.totalMooc}강</span>/<span>{this.props.totalMoocTime}시간/</span><span>{this.props.totalMoocDate}일</span></p>
                    </div>
                </div>
            </div>
        )
    }
}
class Moocnew_pay extends Component {
    state = {

        r_isAdmin: false,
        isSubscribed: false,
        moocId: '',

        currentMoocList: '1',
        currentULink: '',
        showCredit: false,

        userInfo: {
            userId: '',
            full_name: '',
            picture: '',
            undergraduate_school: '',
            u__degree_1: '',
            moocTopic: '',
            moocPicUrl: '',
            canBuy: false,
            moocFee: '',
            moocFeeDiscount: 0,
            note_doc_url: '',
            question_doc_url: '',
            totalMoocs: 0,
            totalDuration: 0,
            subscriptionDays: 0,
        },

        moocsData: [],

        isDoCredit: false,


        defaultValues: {
            undergraduate_school: '학위가 선택되어 있지 않습니다.',
            u__degree_1: '전공과목이 선택되어 있지 않습니다.',
        },
        currentNav: '',
        NavObj: [
            {
                id: '1',
                topic: 'Mathematics',
            },
            {
                id: '2',
                topic: 'Biology',
            },
            {
                id: '3',
                topic: 'Chemistry',
            },
            {
                id: '4',
                topic: 'Physics',
            },
            {
                id: '5',
                topic: 'Economics',
            },
        ]
    }


    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.location.reload();
        }
        return true;
    }

    componentDidMount = () => {
        const moocid = this.props.match.params.moocid;

        if (moocid !== 'index' && moocid !== 'new') {
            this.doInit(moocid);
        }
    }



    doInit = async (_moocid) => {
        const moocid = _moocid;
        if (!moocid) {
            let msg = '링크가 잘못 되었습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const allUrls = await GetAjaxURL();
        const thisUserUrl = allUrls.apiThisUser;
        const userRes = await axios.get(thisUserUrl).catch((err) => { return console.log(err); });

        if (userRes.data.status === 'fail') {
            let msg = '로그인 후에 이용해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/login?redirect=mooc&mooc_id=' + moocid;
        }

        const moocDataUrl = allUrls.mooc.index;
        const moocDataRes = await axios.get(moocDataUrl + '/' + moocid).catch((err) => { return err; })
        const moocRes = moocDataRes.data;
        const coinUrl = allUrls.gcoin.getTotalAmount;
        const coinRes = await axios.get(coinUrl).catch((err) => { return err; })
        const coinData = coinRes.data;
        const TempAmount = coinData.amount;
        const moocFee = moocRes.fee;
        const moocFeeDiscount = moocRes.fee_discount;
        const splitAmount = String(TempAmount).split('.');
        const amount = splitAmount[0];
        const mooc_subject_id = moocRes.mooc_subject_id;
        const NavObj = this.state.NavObj;
        const moocsData = moocRes.moocs;
        const canBuy = moocFeeDiscount == 0 ? Number(TempAmount) >= Number(moocFee) : Number(TempAmount) >= Number(moocFeeDiscount);

        let currentNav = '';
        for (let i = 0; i < NavObj.length; i++) {
            if (NavObj[i].id === String(mooc_subject_id)) {
                currentNav = NavObj[i].topic;
            }
        }

        if (moocRes.status) {
            let msg = '해당 페이지가 없습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.history.back();
        }

        const moocTopic = moocRes.topic;
        const moocPicUrl = moocRes.poster;
        const note_doc_url = moocRes.note_doc_url;
        const question_doc_url = moocRes.question_doc_url;
        const totalMoocs = moocRes.total_moocs
        const totalDuration = moocRes.total_duration
        const subscriptionDays = moocRes.subscription_days
        let isSubscribed = moocRes.is_subscribed;


        if (isSubscribed) {
            window.location.href = '/#/mooc/' + moocid;
        }

        const r_isAdmin = isAdmin(userRes.data.user);

        if (r_isAdmin) isSubscribed = true;


        // 선생님 확인 및 어드민 유저가 아닐 시에 리디렉션.
        // 학생일 때와 어드민 유저일 때만.
        if (userRes.data.user.type !== 'student' && !r_isAdmin) {
            let msg = '선생님 회원만 이용 가능합니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/mooc';
        }



        const full_name = moocRes.tutor_full_name;
        const picture = moocRes.tutor_pic;

        let undergraduate_school = this.state.defaultValues.undergraduate_school;
        let u__degree_1 = this.state.defaultValues.u__degree_1;
        if (moocRes.hasOwnProperty('school') && moocRes.hasOwnProperty('degree')) {
            undergraduate_school = moocRes.school;
            u__degree_1 = moocRes.degree;
        }


        this.setState({
            ...this.state,
            r_isAdmin: r_isAdmin,
            isSubscribed: isSubscribed,
            moocId: moocid,
            userInfo: {
                ...this.state.userInfo,
                full_name: full_name,
                picture: picture,
                undergraduate_school: undergraduate_school,
                u__degree_1: u__degree_1,
                moocTopic: moocTopic,
                moocPicUrl: moocPicUrl,
                amount: amount,
                moocFee: moocFee,
                canBuy: canBuy,
                moocFeeDiscount: moocFeeDiscount,
                note_doc_url: note_doc_url,
                question_doc_url: question_doc_url,
                totalMoocs: totalMoocs,
                totalDuration: totalDuration,
                subscriptionDays: subscriptionDays
            },
            moocsData: moocsData,
            currentNav: currentNav
        })
    }


    calculateDiscount = (fee, discountRate) => {
        return Math.round(fee * (100 - discountRate) / 100);
    }

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/mooc"><TrasLecture /></a></li>
                    <li><a href="/#/mooc">{this.state.currentNav}</a></li>
                    <li><a href={"/#/mooc/" + this.state.moocId}>{this.state.userInfo.moocTopic}</a></li>
                    <li className="active">Pay</li>
                </ol>
            </div>
        )
    }

    onDoCredit = async () => {

        this.setState({
            ...this.state,
            isDoCredit: true,
        })

        const moocId = this.state.moocId;

        const formData = new FormData();
        formData.append('mooc_topic_id', moocId);

        const allUrls = await GetAjaxURL();
        const moocSubUrls = allUrls.mooc.subscript;
        const moocSubRes = await axios.post(moocSubUrls, formData, postConfig).catch((err) => { return console.log(err); });

        const reData = moocSubRes.data;

        if (reData.status === 'success') {
            let msg = '성공적으로 결제하였습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            this.setState({
                ...this.state,
                isSubscribed: true,
                isDoCredit: false,
            })
            window.location.href = "/#/mooc/" + moocId
            window.location.reload()
        } else {
            let msg = '결제에 실패했습니다. (' + reData.msg + ')';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({
                ...this.state,
                isDoCredit: false,
            })
        }
    }
    showCreditPannel = (moocFee, moocFeeDiscount) => {
        return (
            <div className='mooc-show-tooltips-wr'>
                <div className='mooc-show-tooltips'>
                    <span>{'결제 시 ' + moneyFormat(this.calculateDiscount(moocFee, moocFeeDiscount)) + '₩의 금액이 크레딧에서 차감됩니다.'}</span>

                    <div>
                        {
                            this.state.isDoCredit ?
                                <button><i className="fa fa-check-square"></i> 결제중...</button>
                                :
                                <button onClick={this.onDoCredit}><i className="fa fa-check-square"></i> 결제하기</button>
                        }
                        <button onClick={this.onHideCredit}><i className="fa fa-ban"></i> 결제취소</button>
                    </div>
                </div>
            </div>
        )
    }

    onShowCredit = () => {
        if (!this.state.userInfo.canBuy) {
            this.moveToBuyCredit()
        }
        this.setState({
            ...this.state,
            showCredit: true,
        })
    }
    onHideCredit = () => {
        this.setState({
            ...this.state,
            showCredit: false,
        })
    }

    moveToBuyCredit = () => {
        if (window.confirm('크레딧 구매하기 페이지로 이동하시겠습니까?')) {
            window.location.href = '/#/credit/buy';
        }
    }

    createSubscriptionContents = () => {

        const userInfo = this.state.userInfo;
        const tutorPic = userInfo.picture;
        const tutorName = userInfo.full_name;
        const undergraduate_school = userInfo.undergraduate_school;
        const u__degree_1 = userInfo.u__degree_1;
        const moocTopic = userInfo.moocTopic;
        const moocPicUrl = userInfo.moocPicUrl;

        const amount = userInfo.amount;
        const moocFee = userInfo.moocFee;
        const moocFeeDiscount = userInfo.moocFeeDiscount;
        const totalMooc = userInfo.totalMoocs;
        const totalMoocTime = userInfo.totalDuration;
        const totalMoocDate = userInfo.subscriptionDays;
        const canBuy = userInfo.canBuy;

        return (
            <div className='moocnew-pay-contents'>
                <div className="moocnew-pay-left">
                    <MoocnewPayCard
                        id={this.state.moocId}
                        topic={moocTopic}
                        tutorName={tutorName}
                        tutorPic={tutorPic}
                        originalPrice={moocFee}
                        nowPrice={moocFeeDiscount}
                        totalMooc={totalMooc}
                        totalMoocTime={totalMoocTime}
                        totalMoocDate={totalMoocDate}
                        isSubscribed={this.state.isSubscribed}
                    />
                </div>
                <div>
                    <div className='moocnew-pay-right'>
                        <div className='moocnew-pay-right-box'>
                            <div>
                                <h3>인강 수업 수강 안내</h3>
                            </div>
                            <div>
                                <ul>
                                    <li><i className="fa fa-calendar"></i> 결제 후 {totalMoocDate}일간 수강이 가능합니다.</li>
                                    <li>
                                        <i className="fa fa-headphones"></i> 궁금한 점은 <a className='color-gurume-blue' href="https://pf.kakao.com/_ddvDV" target="_blank">고객센터</a>에 문의 주시기
                                        바랍니다.
                                    </li>
                                    <li><i className="fa fa-money"></i> <a data-tip="data-tip" data-for="refundRule" data-event="click focus" className='color-gurume-blue'>환불 규정</a>을 확인해주세요.</li>
                                </ul>
                                <ReactTooltip
                                    effect="solid"
                                    place="right"
                                    id='refundRule'
                                    type='light'
                                    border={true}
                                    globalEventOff='click'
                                >
                                    <li className="font-size-14">
                                        1. 수강 기간의 1/3이전  판매 가격에 2/3에 해당하는 금액을 환불<br />
                                        2. 수간 기간의 1/2 이전, 판매가격에 1/2에 해당하는 금액을 환불<br />
                                        3. 수강 기간의 1/2 경과, 환불 불가<br />
                                        *기간제 상품, 이벤트성 할인을 받은 경우 별도의 수강취소, 변경 및 환불규정이 적용될수 있습니다.
                                    </li>
                                </ReactTooltip>
                            </div>
                        </div>
                        <div className='moocnew-pay-price'>
                            <h3>다음 금액이 크레딧에서 차감됩니다.</h3>
                            <div className="moocnew-pay-credit">
                                <p>
                                    보유 크레딧<br />
                                    <span className={[canBuy ? '' : 'color-gurume-red'].join(' ')}>₩{moneyFormat(amount)}</span>
                                </p>
                            </div>
                            <div className="moocnew-pay-fee">
                                <p>
                                    {
                                        moocFeeDiscount == 0 ?
                                            <span>{"₩" + moneyFormat(moocFee)}&nbsp;</span> :
                                            <span className="is-discount">{"₩" + moneyFormat(moocFee)}&nbsp;</span>
                                    }
                                    {moocFeeDiscount == 0 ? "" : "₩" + moneyFormat(this.calculateDiscount(moocFee, moocFeeDiscount))} <br />
                                </p>
                            </div>
                            <div className="mooc-cover">
                                {
                                    this.state.showCredit ?
                                        this.showCreditPannel(moocFee, moocFeeDiscount)
                                        : ''
                                }


                                <a
                                    className={['btn', 'mooc-show-btn', canBuy ? 'btn-gurume-primary' : 'btn-gurume-danger'].join(' ')}
                                    onClick={this.onShowCredit}
                                >{canBuy ? '구독' : '크레딧 구매'}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createSubscription = () => {
        return (
            <div className='moocnew-pay-wrapper'>
                <div className='moocnew-pay-box'>
                    <h3 className="blue-font-color">인강 구독하기</h3>
                    {this.createSubscriptionContents()}
                </div>
            </div>
        )
    }
    createBody = () => {

        const isSubscribed = this.state.isSubscribed;

        return (
            <div className='mooc-show-inner-wapper'>
                {this.createSubscription()}
            </div>
        )
    }

    createIFrame = () => {
        return (
            <iframe id='darkFrame' allowFullScreen={true} webkitallowfullscreen={'true'} mozallowfullscreen={'true'} style={{ width: '100%', height: '100%', padding: '0px', margin: '0px', border: '0' }} src=''></iframe>
        )
    }

    render = () => {
        return (
            <div className='mooc-show-wapper'>
                {this.createNav()}
                {this.createBody()}
            </div>
        )
    }
}

export default withRouter(Moocnew_pay);