import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    TrasMytuitionrequests,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBalanceScale, faBook, faClock
} from '@fortawesome/free-solid-svg-icons';
import './TApplyMsgs.css';
import queryString from 'query-string';
import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';


const TApplyMsgsPropTypes = {
    AddMessage: PropTypes.func,
    teacherId: PropTypes.number,

}
const TApplyMsgsDefaultProps = {
    AddMessage: () => { console.log("NOT SETTING AddMessage!") },
    teacherId: -1,
}

class TApplyMsgs extends Component {


    state = {
        data: [],
        secretMsg: [],
        currentTarget: [],
        selectedData: [],

        needShowingMsg: false,
        sendingShowAndHide: true,
        isSending: false,
        showMsg: '먼저 목록을 선택해주세요',
        normalMsg: '',
    }

    componentDidMount = () => {
        this.initStart();
    }

    initStart = async () => {
        const allurls = await GetAjaxURL();
        const tuitionMyRequest = allurls.tuitionMyRequest;
        const tuitionReq = await axios.get(tuitionMyRequest).catch((err) => { return console.log(err) });

        const messageUrl = allurls.message.list + '/' + this.props.teacherId;
        const messageReq = await axios.get(messageUrl, {}, postConfig).catch((err) => { console.log(err) });

        let data = tuitionReq.data;
        const secretMsg = messageReq.data.chatContent;

        if (data.status === 'fail') {
            data = [];
        }

        this.setState({
            ...this.state,
            data: data,
            secretMsg: secretMsg,
        })
    }


    clickToMove = () => {
        window.location.href = '/#/tuition_request/new?teacherId=' + this.props.teacherId;
    }



    selectedItem = (e) => {
        const target = e.target;
        let currentTarget = target;
        let tempId = currentTarget.id;
        while (tempId.indexOf('takeys_') === -1) {
            currentTarget = currentTarget.parentNode;
            tempId = currentTarget.id;
        }

        for (let i = 0; i < currentTarget.parentNode.parentNode.childElementCount; i++) {
            const eachP = currentTarget.parentNode.parentNode.children[i];
            for (let k = 0; k < eachP.childElementCount; k++) {
                eachP.children[k].classList.remove('sossosoosos');
            }
        }

        currentTarget.classList.add('sossosoosos');
        this.singleDataShow(currentTarget);
    }

    // single data
    singleDataShow = (_curTarget) => {
        const currentTarget = _curTarget;
        const itemId = currentTarget.dataset['itemId'];
        const normalMsg = currentTarget.dataset['sDatas'];


        this.setState({
            ...this.state,
            selectedData: [itemId],
            sendingShowAndHide: false,
            showMsg: '안녕하세요 선생님! 위와 같은 수업신청서를 확인 후 답변해 주시면 감사하겠습니다.',
            normalMsg: normalMsg,
        })

    }

    // more than 2
    setSelectedData = (_parentNode) => {
        const parentUlNode = _parentNode.parentNode;

        const selectedObj = [];

        for (let j = 0; j < parentUlNode.childElementCount; j++) {
            const parentNode = parentUlNode.children[j];

            for (let i = 0; i < parentNode.childElementCount; i++) {
                const eachMyChild = parentNode.children[i];
                for (let k = 0; k < eachMyChild.classList.length; k++) {
                    const eachChildClass = eachMyChild.classList[k];
                    if (eachChildClass === 'sossosoosos')
                        selectedObj.push(eachMyChild);
                }
            }

        }

        let tempStr = '';
        let k = 0;
        for (let i = 0; i < selectedObj.length; i++) {
            const items = selectedObj[i];
            let datItem = items.dataset['itemId'];
            if (k === 0) {
                datItem = datItem.substr(1, datItem.length);
            }
            tempStr += datItem;
            k++;
        }

        this.setState({
            ...this.state,
            selectedData: tempStr
        }, () => {
        });

    }

    showMsgPannel = () => {
        this.setState({
            ...this.state,
            needShowingMsg: !this.state.needShowingMsg
        })
    }

    clickToModf = (e) => {
        let target = e.target;
        let data = target.dataset['taDataType'];
        let dataId = target.dataset['taDataId'];
        if (!data) target = target.parentNode;
        data = target.dataset['taDataType'];
        dataId = target.dataset['taDataId'];

        window.location.href = '/#/tuition_request/' + dataId + '/edit'
    }


    createInTapplMsg = () => {
        const baseData = this.state.data;
        const tapply_msg_ul = [];
        let sLi = [];
        const gLi = [];


        const data = baseData.sort((a, b) => { return a.is_end === b.is_end ? 1 : a.is_end ? 1 : -1 });
        for (let i = 0; i < data.length; i++) {

            let itemId = data[i].id;

            const tempStr1 = data[i].curriculums;
            let aStr = '';
            let nStr = '';
            let sDatas = '';
            for (let k = 0; k < tempStr1.length; k++) {
                aStr += ' <' + tempStr1[k]['curriculum'] + '> ' + tempStr1[k]['subject'];
                sDatas += tempStr1[k]['subject'] + '/';
            }
            nStr += data[i]['start_time'] + ' ~ ' + data[i]['end_time'];

            const tempBool = data[i]['is_end'];
            const showStr = !tempBool ? '수정' : '다시 사용하기';
            const taDataType = !tempBool ? 'typeA' : 'typeB';

            let classExtraA = 't-apply-msgs-extra-a';
            let classExtraB = 't-apply-msgs-extra-b';
            if (tempBool) {
                classExtraB = 't-apply-msgs-extra-b-ms';
                classExtraA = 't-apply-msgs-extra-a-ms';
            }

            sLi.push(
                <li key={'_kes_' + i} id={`takeys_${i}`}
                    className={classExtraA}
                    data-item-id={itemId}
                    data-s-datas={sDatas}
                    onClick={this.selectedItem}
                >
                    <div className="apply-msg-wrapper">
                    <div>
                        <div>
                            <span><FontAwesomeIcon icon={faBook} /></span>
                            <span>{aStr}</span>
                        </div>
                        <div>
                            <span><FontAwesomeIcon icon={faClock} /></span>
                            <span>{nStr}</span>
                        </div>
                    </div>
                    <div className={classExtraB} onClick={this.clickToModf} data-ta-data-type={taDataType} data-ta-data-id={itemId}>
                        <button>{showStr}</button>
                    </div>
                    </div>
                </li>
            )
            if (sLi.length > 2) {
                tapply_msg_ul.push(<ul key={'_key_' + i}>{sLi}</ul>);
                sLi = [];
            } else {
                if (data.length - 3 <= i) {
                    const eachData = sLi.shift();
                    gLi.push(eachData);
                }
            }
        }

        tapply_msg_ul.push(<ul key={'temp'}>{gLi}</ul>);




        const secretMsg = this.state.secretMsg;
        const listData = [];

        for (let i = 0; i < secretMsg.length; i++) {
            let content = secretMsg[i].content;
            const created_at = secretMsg[i].created_at;
            const sender_full_name = secretMsg[i].sender_full_name;

            let tempStr = '';
            if (content.indexOf('<a href') !== -1) {
                tempStr = content.substr(content.indexOf('<a href'), content.indexOf('</a>') + 2);
                const tempStrr = content.substr(content.indexOf('</a>') + 4, content.length);
                content = tempStrr;
            }

            listData.push(
                <li key={`_${i}`}>
                    <div>
                        <span>
                            <img src='/images/backuptutorpic.png' alt='...' />
                        </span>
                        <span>{sender_full_name}</span>
                        <span>{created_at}</span>
                    </div>
                    <div>{content}</div>
                    <div dangerouslySetInnerHTML={{ __html: tempStr }}>
                    </div>
                </li>

            )
        }


        return (
            <div className='tapply-wrapper'>
                <h4><TrasMytuitionrequests /></h4>
                <div className='ta-good-wrapper'>
                    <h3 onClick={this.clickToMove}><i class="fa fa-plus-circle"></i>&nbsp;새로 추가</h3>
                </div>
                <div className='tapply-msg-ul'>
                    {tapply_msg_ul}
                </div>
                <div className='ta-body-rank'>
                    <h4 onClick={this.showMsgPannel}>
                        <i className='fa fa-comments t-apply-msgs'></i>
                        메시지 보기 ({listData.length})
                    </h4>
                    {
                        this.state.needShowingMsg ?
                            <ul>
                                {listData}
                            </ul>
                            : ''
                    }
                </div>
                {this.createTaMs()}

            </div>
        )
    }



    sendToMsgToMe = async () => {

        this.setState({
            ...this.state,
            isSending: true
        })

        if (this.state.sendingShowAndHide) {
            showNotify("먼저 커리큘럼을 선택해주세요.", STATE_NOTIFY.TYPE_NEGATIVIE);
            this.props.sendToMsgToMe2();
            return;
        }


        const allUrls = await GetAjaxURL();
        const thisUser = allUrls.apiThisUser;
        const res = await axios.get(thisUser).catch((err) => { return console.log(err) });

        const uData = res.data.user;
        const fullName = uData.last_name + uData.first_name;

        const uId = uData.id;
        const tId = this.props.teacherId;
        const isRead = 1;
        const content = "**<a href='http://gurume.kr/tuition_requests/"
            + this.state.selectedData[0]
            + "'>수업신청서 " + this.state.normalMsg + ' (' + this.state.selectedData[0] + ')'
            + '</a>**'
            + this.state.showMsg;



        const dataObj = {
            id: 1,
            sender_full_name: fullName,
            sender: uId,
            receiver: tId,
            isRead,
            content,
            timestamp: Math.floor(new Date().getTime() / 1000),
            created_at: this.formatDate(new Date()),
            updated_at: this.formatDate(new Date()),
            type: 1
        };

        const tempObj = {
            receiver: tId,
            msgInfo: content
        };

        const tempkeys = Object.keys(dataObj);
        let params = '';
        for (let i = 0; i < tempkeys.length; i++) {
            params += `&msgInfo[${i}][${tempkeys[i]}]=${dataObj[tempkeys[i]]}`
        }


        const registerUrl = '?' + queryString.stringify(tempObj);
        const msgStoreUrl = allUrls.message.send + registerUrl;
        const resMsg = await axios.post(msgStoreUrl, dataObj, postConfig).catch(err => { return console.log(err) });

        if (resMsg) {
            const resData2 = resMsg.data;

            if (resData2.status === 'success') {
                showNotify("성공적으로 메시지가 등록되었습니다.", STATE_NOTIFY.TYPE_POSITIVE);
            } else {
                showNotify("메시지 등록에 실패했습니다.", STATE_NOTIFY.TYPE_NEGATIVIE);
            }

        } 
        else {
            showNotify("성공적으로 메시지가 등록되었습니다.", STATE_NOTIFY.TYPE_POSITIVE);
            // showNotify("서버 전송에 실패했습니다.", STATE_NOTIFY.TYPE_NEGATIVIE);
        }

        this.setState({
            ...this.state,
            isSending: false
        }, () => {
            this.props.sendToMsgToMe2();
        })


    }

    formatDate(date) {
        var d = (typeof date === 'object' && date instanceof Date) ? date : new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            minute = d.getMinutes();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if (hour.length < 2) hour = '0' + hour;
        if (minute.length < 2) minute = '0' + minute;

        return [year, month, day].join('-') + ' ' + [hour, minute].join(':');
    }




    changeEve = (e) => {
        const target = e.target;
        const value = target.value;
        this.setState({
            ...this.state,
            showMsg: value
        })
    }

    createTaMs = () => {
        return (
            <div className='ta-god-rank'>
                <textarea disabled={this.state.sendingShowAndHide} value={this.state.showMsg} onChange={this.changeEve}></textarea>
                <div>
                    {this.state.isSending ?
                        <button className='btn btn-default loading'>발송 중</button>
                        :
                        <button onClick={this.sendToMsgToMe}>발송</button>
                    }
                </div>
            </div>
        )
    }


    createHtml = () => {
        return (
            <div className='tapply-inner-wrapper'>
                {this.createInTapplMsg()}
            </div>
        )
    }

    render() {
        return (
            <div id='TaItemList' className='ta-wra-super' onClick={this.props.AddMessage}>
                {this.createHtml()}
            </div>
        )
    }
}

TApplyMsgs.propTypes = TApplyMsgsPropTypes;
TApplyMsgs.defaultProps = TApplyMsgsDefaultProps;

export default TApplyMsgs;
