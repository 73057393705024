export const REGEXP = () => {

    // NEW REGEXP FROM REACT

    return {
        emailRegExp : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        phoneRegExp : /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/,
        englishAndNumber: /^[a-zA-Z0-9]+$/
    }



    // regexp from angular
    // return {
    //     phone: "^([()\- x+]*\d[()\- x+]*){4,16}$",
    //     number: "^[0-9]+$",
    //     englishAndNumber: "/^[a-zA-Z0-9]+$/;",
    //     url : "/^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?$/",
    //     email : "/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/"
    // }
}




////////
export const allRoutes = () => {
    return{
        admin : {
            url: '/gurume-admin',
            templateUrl: 'views/admin.html',

            controller: 'AdminCtrl',
            controllerAs: 'admin',
            data: {
                meta: {
                    'title': 'Admin',
                    'description': 'This is for admin page'
                }
            }
        },
        index : {
            url: '/',
            templateUrl: 'views/main.html',
            controller: 'MainCtrl',
            data: {
                meta: {
                    'title': '해외 명문대 입시 생들을 위한 과외 및 자료 공간',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        index1 : {
            url: '',
            templateUrl: 'views/main.html',
            controller: 'MainCtrl'
        },
        pay : {
            url: '/pay',
            templateUrl: 'views/paypage.html',
            controller: 'PayCtrl',
            controllerAs: 'pay'
        },
        login : {
            url: '/login',
            params: {
                'teacherId': null,
                'blogId': null,
                'questionId': null,
                'tuitionId': null,
                'returnId': null,
                'messageId': null,
                'moocId': null,
                'tuConfirmId': null,
                'tuitionRequestId': null,
                'reviewId': null,
                'goAfterLogin': null
            },
            templateUrl: 'views/users/login.html',
            controller: 'LoginCtrl',
            controllerAs: 'users/login'
        },
        userreg : {
            url: '/register',
            templateUrl: 'views/users/register.html',
            controller: 'UserRegCtrl',
            controllerAs: 'users/userreg'
        },
        forgotpassword : {
            url: '/forgot_password',
            templateUrl: 'views/users/forgot_password.html',
            controller: 'ForgotPasswordCtrl',
            controllerAs: 'users/forgotpsw'
        },
        resetpassword : {
            url: '/reset_password',
            templateUrl: 'views/users/reset_password.html',
            controller: 'ResetPasswordCtrl',
            controllerAs: 'users/resetpassword'
        },
        useraccountsetting : {
            url: '/users/account_setting',
            templateUrl: 'views/users/account_setting.html',
            controller: 'accountSetting',
            controllerAs: 'users/accountsetting'
        },
        accountactivation : {
            url: '/account_activation',
            params: {'postTmp': null},
            templateUrl: 'views/users/account_activation.html',
            controller: 'AccountActivationCtrl',
            controllerAs: 'users/accountactivation'
        },
        teachers_native : {
            url: '/teachers/native',
            templateUrl: 'views/teachers/native.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        tdashboard : {
            url: '/teachers/dashboard',
            templateUrl: 'views/teachers/dashboard.html',
            params: {'alertMode': null},
            cache: false,
            controller: 'TDashBoardCtrl',
            controllerAs: 'teachers/dashboard'
        },
        tutorreg : {
            url: '/teachers/register',
            templateUrl: 'views/teachers/register.html',
            controller: 'TutorRegCtrl',
            controllerAs: 'teachers/tutorreg'
        },
        editteacherprofile : {
            url: '/teachers/{tid}/edit',
            params: {'goToHash': null},
            templateUrl: 'views/teachers/register.html',
            controller: 'TeacherProfileEditCtrl',
            controllerAs: 'teachers/editteacherprofile'
        },
        teacherprofile : {
            url: '/teachers/{tutorId}',
            params: {'actionAfterLoad': null, 'tuitionRequestId': null},
            templateUrl: 'views/teachers/show.html',
            controller: 'TeacherprofileCtrl',
            controllerAs: 'teachers/teacherprofile',
            data: {
                meta: {
                    'title': '그루미 선생님',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        editTutorProfile : {
            url: '/teachers/edit',
            templateUrl: 'views/teachers/add-edit.html',
            controller: 'TutorProfileEditController',
            controllerAs: 'teachers/editTutorProfile'
        },
        newTutorProfile : {
            url: '/teachers/new',
            templateUrl: 'TutorProfileNewController',
            controllerAs: 'teachers/newTutorProfile',
        },
        search : {
            url: '/teachers?sid={id}&tid={Tid}&uid={Uid}&current_page={currentPage}&money_min={moneyMin}&money_max={moneyMax}&gender={prefGender}&teacher_levels={teacherLevelParam}',
            templateUrl: 'views/teachers/search.html',
            controller: 'SearchCtrl',
            controllerAs: 'teachers/search',
            data: {
                meta: {
                    'title': '나에게 맞는 선생님을 찾아보세요.',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }    
        },
        teacher_apply : {
            url: '/teacher/apply',
            templateUrl: 'views/teacher_apply.html',
            controller: 'TeacherprofileCtrl',
            controllerAs: 'teacherprofile',
        },
        siteusage : {
            url: '/site_usage',
                templateUrl: 'views/site_usage.html',
                controller: 'StaticPageCtrl',
                controllerAs: 'static_page',
                data: {
                    meta: {
                        'title': '그루미 이용 방법',
                        'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                    }
                }
        },
        editstudentprofile : {
            url: '/students/{sid}/edit',
            templateUrl: 'views/students/register.html',
            controller: 'StudentProfileEditCtrl',
            controllerAs: 'students/editstudentprofile'
        },
        studentreg : {
            url: '/students/register',
            templateUrl: 'views/students/register.html',
            controller: 'StudentRegCtrl',
            controllerAs: 'students/studentreg'
        },
        sdashboard : {
            url: '/students/dashboard',
            cache: false,
            templateUrl: 'views/students/dashboard.html',
            controller: 'SDashBoardCtrl',
            controllerAs: 'students/dashboard'
        },
        sitemessage : {
            url: '/sitemessage',
            templateUrl: 'views/sitemessage.html',
            controller: 'SiteMessageCtrl',
            controllerAs: 'sitemessage',
        },
        messagesItem : {
            url: '/messages/{mid}',
            templateUrl: 'views/messages/show.html',
            controller: 'MessageShowCtrl',
            controllerAs: 'messages/messageshow'
        },
        messages : {
            url: '/messages',
                templateUrl: 'views/messages/index.html',
                controller: 'MessageCtrl',
                controllerAs: 'messages/message',
                data: {
                    meta: {
                        'title': '메세지',
                        'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                    }
                }
        },
        questionsindex : {
            url: '/questions',
            templateUrl: 'views/questions/index.html',
            controller: 'QuestionIndexCtrl',
            controllerAs: 'questions/questionindex'
        },
        questionsnew : {
            url: '/questions/new',
            templateUrl: 'views/questions/new.html',
            controller: 'QuestionNewCtrl',
            controllerAs: 'questions/questionnew'
        },
        blogindex : {
            url: '/blogs?cid={Cid}&current_page={currentPage}',
            templateUrl: 'views/blogs/index.html',
            controller: 'BlogIndexCtrl',
            controllerAs: 'blogs/blogindex',
            data: {
                meta: {
                    'title': '블로그 포럼',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        blognew : {
            url: '/blogs/new',
            templateUrl: 'views/blogs/new.html',
            controller: 'BlogNewCtrl',
            controllerAs: 'blogs/blognew'
        },
        blogedit : {
            url: '/blogs/{blogId}/edit',
            templateUrl: 'views/blogs/new.html',
            controller: 'BlogEditCtrl',
            controllerAs: 'blogs/blogedit'
        },
        bloginvitation : {
            url: '/blogs/invitation',
            templateUrl: 'views/blogs/invitation.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        blogshow : {
            url: '/blogs/{id}',
                params: {'page': null},
                templateUrl: 'views/blogs/show.html',
                controller: 'BlogShowCtrl',
                controllerAs: 'blogs/blogshow',
                data: {
                    meta: {
                        'title': '블로그 포럼',
                        'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                    }
                }
        },
        moocindex : {
            url: '/mooc',
            templateUrl: 'views/mooc/index.html',
            controller: 'MoocIndexCtrl',
            controllerAs: 'moocindex',
            data: {
                meta: {
                    'title': '인강',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        moocsubscribe : {
            url: '/mooc/subscribe/{moocId}',
            templateUrl: 'views/mooc/subscription.html',
            controller: 'MoocSubscriptionCtrl',
            controllerAs: 'mooc/moocsubscribe',
            data: {
                meta: {
                    'title': '인강 구독',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        moocnew : {
            url: '/mooc/new',
            templateUrl: 'views/mooc/new.html',
            controller: 'MoocNewCtrl',
            controllerAs: 'mooc/moocnew'
        },
        moocedit : {
            url: '/mooc/{moocId}/edit',
            templateUrl: 'views/mooc/new.html',
            controller: 'MoocEditCtrl',
            controllerAs: 'mooc/moocedit'
        },
        moocshow : {
            url: '/mooc/{id}',
            templateUrl: 'views/mooc/show.html',
            controller: 'MoocShowCtrl',
            controllerAs: 'mooc/moocshow',
            data: {
                meta: {
                    'title': '인강 보기',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        mygcoin : {
            url: '/mygcoin',
            templateUrl: 'views/mygcoin.html',
            controller: 'MygcoinCtrl',
            controllerAs: 'mygcoin'
        },
        tuition_request_show : {
            url: '/tuition_requests/{rid}',
            params: {'page': null},
            templateUrl: 'views/tuition_requests/show.html',
            controller: 'TuitionRequestShowCtrl',
            controllerAs: 'tuition_requests/tuition_request_show',
            data: {
                meta: {
                    'title': '수업 요청',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        tuition_request_index : {
            url: '/tuition_requests?current_page={currentPage}',
            templateUrl: 'views/tuition_requests/index.html',
            controller: 'TuitionRequestIndexCtrl',
            controllerAs: 'tuition_requests/tuition_request_index',
            data: {
                meta: {
                    'title': '수업 요청',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        tuition_request_new : {
            url: '/tuition_requests_new',
            params: {teacherId: null},
            templateUrl: 'views/tuition_requests/new.html',
            controller: 'TuitionRequestNewCtrl',
            controllerAs: 'tuition_requests/tuition_request_new',
            data: {
                meta: {
                    'title': '수업 요청',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        tuition_request_edit : {
            url: '/tuition_requests/{rid}/edit',
            params: {'teacherId': null},
            templateUrl: 'views/tuition_requests/edit.html',
            controller: 'TuitionRequestEditCtrl',
            controllerAs: 'tuition_requests/tuition_request_edit',
            data: {
                meta: {
                    'title': '수업 요청',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        tuition_request_opportunity : {
            url: '/tuition_requests/opportunity',
            templateUrl: 'views/tuition_requests/opportunity.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        tuition_request_opportunity_abroad : {
            url: '/tuition_requests/opportunity_abroad',
            templateUrl: 'views/tuition_requests/opportunity_abroad.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        group_class_logistics : {
            url: '/group_class/logistics',
            templateUrl: 'views/group_class/logistics.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        about_gurume : {
            url: '/about_gurume',
            templateUrl: 'views/about_gurume.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page',
            data: {
                meta: {
                    'title': '그루미 소개',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        how_it_works_instant_qa : {
            url: '/how_it_works/instant_qa',
            templateUrl: 'views/how_it_works/instant_qa.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page',
        },
        how_it_works_tuition : {
            url: '/how_it_works/tuition',
            templateUrl: 'views/how_it_works/tuition.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page',
            data: {
                meta: {
                    'title': '수업 방법',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        how_it_works_group_class : {
            url: '/how_it_works/group_class',
            templateUrl: 'views/how_it_works/group_class.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page',
            data: {
                meta: {
                    'title': '그룹수업 방법',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.'
                }
            }
        },
        how_it_works_consulting : {
            url: '/how_it_works/consulting',
            templateUrl: 'views/how_it_works/consulting.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page',
            data: {
                meta: {
                    'title': '입시 컨설팅 방법',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.'
                }
            }
        },
        how_it_works_resources : {
            url: '/how_it_works/resources',
            templateUrl: 'views/how_it_works/resources.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page',
            data: {
                meta: {
                    'title': 'Gurume Resources',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.'
                }
            }
        },
        how_it_works_resources_example : {
            url: '/how_it_works/resources/example',
            templateUrl: 'views/how_it_works/resources_example.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page',
            data: {
                meta: {
                    'title': 'Gurume Resources',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.'
                }
            }
        },
        how_it_works_mooc : {
            url: '/how_it_works/mooc',
            templateUrl: 'views/how_it_works/mooc.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page',
            data: {
                meta: {
                    'title': '인강 이용 방법',
                    'description': '그루미는 해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.',
                }
            }
        },
        how_it_works_questions : {
            url: '/how_it_works/questions',
            templateUrl: 'views/how_it_works/questions.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        online_tuition : {
            url: '/online_tuition',
            templateUrl: 'views/online_tuition.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        online_tuition_touchpad : {
            url: '/online_tuition_touchpad',
            templateUrl: 'views/online_tuition_touchpad.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        contact_us : {
            url: '/contact_us',
            templateUrl: 'views/contact_us.html',
            controller: 'ContactUsCtrl',
            controllerAs: 'contact_us'
        },
        useredit : {
            url: '/users/edit',
            templateUrl: 'views/users/edit.html',
            controller: 'UserEditCtrl',
            controllerAs: 'useredit'
        },
        credit_balance : {
            url: '/credit/balance',
            templateUrl: 'views/credit/balance.html',
            controller: 'CreditBalanceCtrl',
            controllerAs: 'credit/creditbalance'
        },
        credit_history : {
            url: '/credit/history',
            templateUrl: 'views/credit/history.html',
            controller: 'CreditHistoryCtrl',
            controllerAs: 'credit/credithistory'
        },
        credit_buy : {
            url: '/credit/buy',
            templateUrl: 'views/credit/buy.html',
            controller: 'CreditBuyCtrl',
            controllerAs: 'credit/creditbuy'
        },
        credit_withdraw : {
            url: '/credit/withdraw',
            templateUrl: 'views/credit/withdraw.html',
            controller: 'CreditWithdrawCtrl',
            controllerAs: 'credit/creditwithdraw'
        },
        credit_approve : {
            url: '/credit/approve',
            templateUrl: 'views/credit/approve.html',
            controller: 'CreditApproveCtrl',
            controllerAs: 'credit/creditapprove'
        },
        tuition_invoice : {
            url: '/tuition/invoice',
            templateUrl: 'views/tuition/invoice.html',
            controller: 'TuitionInvoiceCtrl',
            controllerAs: 'tuition/tuitioninvoice'
        },
        tuitions_bill : {
            url: '/tuitions/bill',
            templateUrl: 'views/tuitions/bill.html',
            controller: 'TuitionBillCtrl',
            controllerAs: 'tuition/tuitionbill'
        },
        tuitions_bill_edit : {
            url: '/tuitions/bill/edit/{ordernum}',
            templateUrl: 'views/tuitions/bill_edit.html',
            controller: 'TuitionBillEditCtrl',
            controllerAs: 'tuition/tuitionbill_edit'
        },
        tuitions_copy : {
            url: '/tuitions/copy/{ordernum}',
            params: {'isCopyTuitionBill': true},
            templateUrl: 'views/tuitions/bill_edit.html',
            controller: 'TuitionBillEditCtrl',
            controllerAs: 'tuition/tuitionbill_edit'
        },
        tuitions_confirm : {
            url: '/tuitions/confirm/{Cid}',
            templateUrl: 'views/tuitions/confirm.html',
            controller: 'tuitionConfirmCtrl',
            controllerAs: 'tuition/tuitionconfirm'
        },
        tuitions_progress_report : {
            url: '/tuitions/progress_report',
            templateUrl: 'views/tuitions/progress_report.html',
            controller: 'tuitionConfirmCtrl',
            controllerAs: 'tuitionconfirm'
        },
        studyroom : {
            url: '/studyroom',
            templateUrl: 'views/studyroom.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        reviews_new : {
            url: '/reviews/new/{rid}',
            templateUrl: 'views/reviews/new.html',
            controller: 'tuitionReviewCtrl',
            controllerAs: 'reviews/tuitionreview'
        },
        group_class_new : {
            url: '/group_class/new',
            templateUrl: 'views/group_class/new.html',
            controller: 'groupClassNewCtrl',
            controllerAs: 'group_class/groupclassnew'
        },
        group_class_show : {
            url: '/group_class/{Gid}',
            templateUrl: 'views/group_class/show.html',
            controller: 'groupClassShowCtrl',
            controllerAs: 'group_class/groupclassshow'
        },
        group_class_edit : {
            url: '/group_class/{gid}/edit',
            templateUrl: 'views/group_class/new.html',
            controller: 'groupClassEditCtrl',
            controllerAs: 'group_class/groupclassedit'
        },
        group_class_index : {
            url: '/group_class',
            templateUrl: 'views/group_class/index.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_index_list : {
            url: '/group_class_list',
            templateUrl: 'views/group_class/index_list.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_ib_songdo : {
            url: '/group_class/ib-songdo',
            templateUrl: 'views/group_class/ib-songdo.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_ib_2018_summer : {
            url: "/group_class/2018/ib_summer",
            templateUrl: 'views/group_class/ib-summer-2018.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_ib_2018_summer_fee : {
            url: "/group_class/2018/ib_summer/fee",
            templateUrl: 'views/group_class/ib-summer-2018-fee.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_ib_2018_summer_fee2 : {
            url: "/group_class/2018/ib_summer/fee2",
            templateUrl: 'views/group_class/ib-summer-2018-fee2.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_ib_2018_summer_fee3 : {
            url: "/group_class/2018/ib_summer/fee3",
            templateUrl: 'views/group_class/ib-summer-2018-fee3.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_ib_2018_winter : {
            url: "/group_class/2018/ib_winter",
            templateUrl: 'views/group_class/ib-winter-2018.html',
            controller: 'groupClassEighteenWinterCtrl',
            controllerAs: 'group_class/groupclassEighteen'
        },
        group_class_ib_2018_winter_fee : {
            url: "/group_class/2018/ib_winter/fee",
            templateUrl: 'views/group_class/ib-winter-2018-fee.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_ib_jeju : {
            url: '/group_class/ib-jeju',
            templateUrl: 'views/group_class/2018-ib-jeju.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_ib_ggwg : {
            url: '/group_class/ib-gyeonggi-foreign-language-high-school',
            templateUrl: 'views/group_class/ib-gyeonggi-foreign-language-high-school.html',
            controller: 'groupClassCtrl',
            controllerAs: 'group_class/groupclass'
        },
        group_class_timetable : {
            url: '/group_class/timetable',
            templateUrl: 'views/group_class/2017-winter-timetable.html',
            controller: 'groupClassTimetableController',
            controllerAs: 'group_class/groupclasstimetable'
        },
        question_bank : {
            url: '/question_bank',
            templateUrl: 'views/question_bank/index.html',
            controller: 'questionBankCtrl',
            controllerAs: 'question_bank/questionbank'
        },
        question_bank_demo : {
            url: '/question_bank_demo',
            templateUrl: 'views/question_bank/demo.html',
            controller: 'questionBankDemoCtrl',
            controllerAs: 'question_bank/questionbankdemo'
        },
        question_bank_show : {
            url: '/question_bank/show',
            templateUrl: 'views/question_bank/show.html',
            controller: 'questionBankShowCtrl',
            controllerAs: 'question_bank/questionbankshow'
        },
        recruiting : {
            url: '/about_gurume/recruiting',
            templateUrl: 'views/recruiting.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        recruiting2 : {
            url: '/about_gurume/career',
            templateUrl: 'views/career.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        recruiting_developer : {
            url: '/about_gurume/recruiting/2018_summer',
            templateUrl: 'views/recruiting_2018_summer.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        mooc_preview : {
            url: '/mooc_preview',
            templateUrl: 'views/mooc_preview.html'
        },
        mooc_logistics : {
            url: '/mooc_logistics',
            templateUrl: 'views/mooc_logistics.html'
        },
        events : {
            url: '/events',
            templateUrl: 'views/events.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        native_tutors : {
            url: '/native_tutors',
            templateUrl: 'views/native_tutors.html',
            controller: 'StaticPageCtrl',
            controllerAs: 'static_page'
        },
        admissions_consulting : {
            url: '/admissions_consulting',
            templateUrl: 'views/admissions_consulting.html',
            controller: 'AdmissionConsultingCtrl',
            controllerAs: 'admission_consulting'
        },
        admissions_consulting_uk : {
            url: '/admissions_consulting/uk',
            templateUrl: 'views/admissions_consulting_uk.html'
        },
        admissions_consulting_us : {
            url: '/admissions_consulting/us',
            templateUrl: 'views/admissions_consulting_us.html'
        },
        admissions_consulting_korea : {
            url: '/admissions_consulting/korea',
            templateUrl: 'views/admissions_consulting_korea.html'
        },
        partners : {
            url: '/partners',
            templateUrl: 'views/partners.html'
        },
        academy : {
            url: '/academy',
            templateUrl: 'views/academy.html'
        },
        academies : {
            url: '/academies',
            templateUrl: 'views/academies.html'
        },
        academy_summer_vacation_workshop : {
            url: '/academy/vacation_workshop',
            templateUrl: 'views/academy/summer_vacation_workshop.html'
        },
        demo_class_video : {
            url: '/demo_class_video',
            templateUrl: 'views/demo_class_video.html'
        },
        errorpage : {
            url: '/errorpage',
            templateUrl: 'views/errorpage.html'
        },
        error : {
            url: '*path',
            templateUrl: 'views/errorpage.html'
        }
    }
}