import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { GetAjaxURL, postConfig, NoImgContens, getEnvironment } from '../../../../config/AjaxConfig';
import axios from '../../../../config/axios';




const TdBlogComponentPropTypes = {
    tdBlogsData: PropTypes.array,
    tdNameData: PropTypes.string,
}
const TdBlogComponentDefaultProps = {
    tdBlogsData: [],
    tdNameData: '',
}


class TdBlogComponent extends Component {

    state = {
        tdBlogMsg: '블로그 리스트 보기',
        isTheStateForMe: false,
        TdBlogComponentData: [
            {
            }
        ]
    }

    componentDidMount = () => {
        this.initStart();
    }


    initStart = async () => {
        const tdBlogsData = this.props.tdBlogsData;

        const modData = [];
        for (let i = 0; i < tdBlogsData.length; i++) {
            const blogTitle = tdBlogsData[i].title_kr;
            const BlogId = tdBlogsData[i].id;
            const BlogImg = tdBlogsData[i].image;
            const tdNameData = this.props.tdNameData;
            const BlogDate = tdBlogsData[i].created_at.split(' ')[0];

            modData.push({
                blogTitle, BlogId, BlogImg, tdNameData, BlogDate
            });
        }

        const sortingData = [];
        let count = 0;
        while (modData.length > 0) {
            const each = modData.pop();
            sortingData.push(each);

            count++;
            if (count > 100) break;
        }
        let isTheStateForMe = false;

        if (sortingData.length !== 0 && tdBlogsData.length)
            isTheStateForMe = true;

        if (tdBlogsData.length === 0)
            isTheStateForMe = true;

        if (this.state.isTheStateForMe) {
            window.location.href = '/#/blogs/new';
        }

        this.setState({
            ...this.state,
            TdBlogComponentData: sortingData,
            tdBlogMsg: '블로그 추가',
            isTheStateForMe: isTheStateForMe
        })


    }


    getInvitation = async () => {
        const http = getEnvironment();
        window.open(http + '/blogs/invitation');
    }


    moveToBlogsWithId = (e) => {
        const target = e.target;
        if (target.tagName === 'BUTTON') return;

        let data = target.dataset['tdBlogId'];
        if (!data) {
            let count = 0;
            let currentTarget = target;
            while (!data) {
                currentTarget = currentTarget.parentNode;
                data = currentTarget.dataset['tdBlogId'];
                count++;

                if (count > 10) break;
            }
        }

        window.location.href = '/#/blogs/' + data;
    }


    clickToBlogs = (e) => {
        const target = e.target;
        const data = target.dataset['tdBlogId'];

        window.location.href = '/#/blogs/' + data + '/edit';
    }

    render() {

        const li = [];
        const TdBlogComponentData = this.state.TdBlogComponentData;

        for (let i = 0; i < TdBlogComponentData.length; i++) {

            const blogTitle = TdBlogComponentData[i]['blogTitle'];
            const BlogId = TdBlogComponentData[i]['BlogId'];
            const BlogImg = TdBlogComponentData[i]['BlogImg'];
            const tdNameData = TdBlogComponentData[i]['tdNameData'];
            const BlogDate = TdBlogComponentData[i]['BlogDate'];

            let reDefinedImg = `https://s3.ap-northeast-2.amazonaws.com/gurume-blog/${BlogImg}`;
            // if(!BlogImg){
            //    reDefinedImg = '../images/backuptutorpic.png';
            // }

            li.push(
                <li key={'_' + i} className='td-blog-edit' data-td-blog-id={BlogId} onClick={this.moveToBlogsWithId}>
                    <div className='td-blog-inner-edit'>
                        <span><img src={reDefinedImg} alt='...img' onError={function (e) { e.target.src = NoImgContens; }} /></span>
                        <span>{BlogDate}</span>
                        <span>{blogTitle}</span>
                        <span><b>글쓴이 - </b>{tdNameData}</span>
                        <button onClick={this.clickToBlogs} data-td-blog-id={BlogId}>편집</button>
                    </div>
                </li>
            )
        }

        return (
            <div className='td-common-wrapper'>
                <div className='td-blog-component'>
                    <button onClick={this.getInvitation}><div></div>블로그 초청</button>
                </div>
                <div>
                    <ul className='stupid-computer'>
                        <li onClick={this.initStart} className='td-blog-list'>
                            <span><i className="fa fa-plus-circle">({this.props.tdBlogsData.length})</i></span>
                            <span>{this.state.tdBlogMsg}</span>
                        </li>
                        {li}
                    </ul>
                </div>
                <div className='td-blog-component-third'>
                    <button onClick={this.getInvitation}>선생님의 이야기를 들려주세요</button>
                </div>
            </div>
        )
    }

}

TdBlogComponent.propTypes = TdBlogComponentPropTypes;
TdBlogComponent.defaultProps = TdBlogComponentDefaultProps;

export default TdBlogComponent;