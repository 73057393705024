import React, { Component } from 'react';
import './Footer.css'
import naverBlog from '../../images/social/blog.png'

export default class Footer extends Component {
    render() {
        return (
            <div id="footer">
                <div>
                    <div className="footer-part1">
                        {/* <div className="margin-bottom-10">
                            <a href="https://www.facebook.com/pages/GuruMe-%EA%B7%B8%EB%A3%A8%EB%AF%B8/835390749864967?fref=ts" target="_blank">
                                <img className="social-image" src="/images/social/facebook.png" />
                            </a>
                            <a href="https://pf.kakao.com/_ddvDV" target="_blank">
                                <img className="social-image" src="/images/social/kakao.png" />
                            </a>
                            <a href="https://youtube.com/channel/UCUom_bzYisSFjFHjJoQ3rlQ" target="_blank">
                                <img className="social-image" src="/images/social/youtube.png" />
                            </a>
                            <a href="https://blog.naver.com/ecubs91" target="_blank">
                                <img className="social-image" src={naverBlog} />
                            </a>
                        </div> */}

                        {/* <p class="footer-text1">
                            <a href='/#/about_gurume'>
                                그루미 소개
                        </a>
                        &nbsp;|&nbsp;
                        <a href='/#/online_tuition'>
                                온라인 수업
                        </a>
                        &nbsp;|&nbsp;
                        <a href='/#/how_it_works/tuition'>
                                이용방법
                        </a>
                        &nbsp;|&nbsp;
                        <a href="/#/studyroom" style={{cursor: 'pointer'}}>스터디룸</a>
                        &nbsp;|&nbsp;
                        <a href="/#/contact_us" style={{cursor: 'pointer'}}>연락처</a>
                        </p> */}
                        <h4>
                            그루미
                    </h4>
                        <p>
                            사업자등록번호: 671-55-00156 | 통신판매업 신고번호: 제 2020-서울강남-02389 호
                    </p>
                        <p>
                            Copyright © GuruMe All Rights Reserved
                    </p>
                    </div>
                </div>
            </div>
        )
    }
}