import React, { Component } from 'react';

import './TdMoocComponent.css';

class TdMoocCard extends Component {
    calculateDiscount = (fee, discountRate) => {
        return Math.round(fee * (100 - discountRate) / 100);
    }

    render() {
        return (
            <div className="sd-mooc-card-item" onClick={this.props.onClick}>
                <div className="sd-mooc-card">
                    <div className="sd-mooc-card-top">
                        {/* topic */}
                        <h3>{this.props.topic}<br/>
                        {this.props.topic2}
                        </h3>
                        {/* tutor */}
                        <div className="sd-mooc-tutor">
                            <div className="sd-mooc-tutor-name">
                                <p>{this.props.tutorName}</p>
                            </div>
                            <div className="sd-mooc-tutor-pic">
                                <div className="sd-mooc-tutor-pic-box">
                                    <img src={this.props.tutorPic} onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sd-mooc-card-bottom">
                        <div className="sd-mooc-price-box">
                            {
                                this.props.discountedFee == 0 ?
                                    <span className="sd-mooc-original-price">₩{this.props.originalFee.toLocaleString('en')}</span> :
                                    <span className="sd-mooc-original-price is-discount">₩{this.props.originalFee.toLocaleString('en')}</span>
                            }
                            &nbsp;
                                <span className="sd-mooc-now-price">
                                {this.props.discountedFee == 0 ? "" : "₩" + this.calculateDiscount(this.props.originalFee, this.props.discountedFee).toLocaleString('en')}
                            </span>
                        </div>
                        {/* detail info(totalMoocCount, totalMoocTime, totalMoocDate) */}
                        <p className="sd-mooc-time">총<span>{this.props.totalMooc}강</span>/<span>{this.props.totalMoocTime}시간/</span>
                            <span>{this.props.totalMoocDays}일</span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
class TdMoocComponent extends Component {
    state = {
        tdState: false,
    }
    clickToTdMoocComponent = (e) => {
        window.location.href = '/#/mooc/new';
    }

    createMoocBtn = () => {
        return (
            <div className='TdMoocComponent-wrapper'>
                <button onClick={this.clickToTdMoocComponent}>
                    <span><i className="fa fa-plus-circle"></i></span>
                    <span>MOOC 추가</span>
                </button>
            </div>
        )
    }
    moveToPerMooc = (id) => {
        window.location.href= "/#/mooc/" + id
    }
    createMoocList = () => {
        const tdMoocsData = this.props.tdMoocsData
        return (
            tdMoocsData.map(mooc =>
                <TdMoocCard
                    topic={mooc.topic}
                    topic2={mooc.topic2}
                    tutorName={mooc.tutor_full_name}
                    tutorPic={this.props.tutorPic}
                    originalFee={mooc.fee}
                    discountedFee={mooc.fee_discount}
                    totalMooc={mooc.total_moocs}
                    totalMoocTime={mooc.total_duration}
                    totalMoocDays={mooc.subscription_days}
                    onClick={()=>this.moveToPerMooc(mooc.id)}
                />)
        )
    }
    createMoocContents = () => {
        return (
            <div className="sd-mooc-card-wrapper">
                {this.createMoocBtn()}
                {this.createMoocList()}
            </div>
        )
    }

    render() {
        return (
            <div className='td-common-wrapper'>
                {this.createMoocContents()}
            </div>
        )
    }

}

export default TdMoocComponent;