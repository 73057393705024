
import { combineReducers } from 'redux';
import RequestPage from './RequestPage'; // A SAMPLE
import RootBindingData from './RootBindingData';
import AuthService from './AuthService';
import UrlService from './UrlService';


const reducers = combineReducers({
    RequestPage,
    RootBindingData,
    AuthService,
    UrlService
});

export default reducers;