import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { GetAjaxURL } from '../../../config/AjaxConfig';
import {
    TrasHome,
    TrasGroupClass,
    TrasStartingEndDate,
    TrasTimetable,
    TrasLocation,
    TrasEnquiry,
    TrasFinished,
    TrasLearningOutcome,
    TrasClassCurriculum,
    TrasTextbook,
    TrasViewProfile,
    // TrasSignUp,
    // TrasSubmit,
    // TrasApply,
    TrasEdit,
    TrasBack
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';

// css
import './groupclass.css';
import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';
import { metaClass, applyMetaHeader } from '../../extra/metaController';

class GroupClassShow extends Component {
    state = {
        lang: window.localStorage.lang || 'ko',
        showData: {},
        educaBgTmp: []
    }

    componentDidMount = async () => {
        const gid = this.props.match.params.gid;
        await this.getData(gid);
        this.setMetas();
    }


    setMetas = () => {
        const showData = this.state.showData;

        const validChecker = Object.keys(showData);
        if (validChecker.length === 0) return;

        const _type = '';
        const _title = showData.title;
        const _url = window.location.href;
        let _description = showData.location + ' - ' + showData.title + '. ' + showData.outcome1;
        const _imageUrl = showData.thumbnail;
        let _keywords = showData.tutor.last_name + showData.tutor.first_name + ', ' + showData.title + ', ' + showData.location;
        const newMetaClass = new metaClass(_type, _title, _url, _description, _imageUrl, _keywords);
        applyMetaHeader(newMetaClass);
    }

    getData = async (gid) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.groupclass.list + '/' + gid;
        const res = await axios.get(url).catch((err) => { return err; })
        let data = res.data;

        let educaBgTmp = [];
        let educaBg = '';
        if (data.status === 'fail') {
            let msg = '그룹 클라스가 아직 승인되지 않았습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/group_class_list';
        }
        if (data) {
            if (data.tutor) {
                if (data.tutor.education_bg) {
                    educaBg = data.tutor.education_bg;
                }
            }
        }
        for (let m = 0; m < educaBg.length; m++) {
            if (educaBgTmp[educaBg[m].degree] !== undefined) {
                if (educaBg[m].nameOfMajor !== '') {
                    educaBgTmp[educaBg[m].degree].major.push(educaBg[m].nameOfMajor)
                } else {
                    educaBgTmp[educaBg[m].degree].major.push(educaBg[m].major)
                }
            } else {
                if (educaBg[m].nameOfMajor !== '') {
                    educaBgTmp[educaBg[m].degree] = {
                        "school": educaBg[m].school,
                        "degree": educaBg[m].degree,
                        "major": [educaBg[m].nameOfMajor]
                    }
                } else {
                    educaBgTmp[educaBg[m].degree] = {
                        "school": educaBg[m].school,
                        "degree": educaBg[m].degree,
                        "major": [educaBg[m].major]
                    }
                }
            }
        }
        this.setState({
            showData: data,
            educaBgTmp
        }, () => {
            this.props.RootBindingData.doLoading(false);
        })
    }

    createBreadcrumb = () => {
        return (
            <div className="container">
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/group_class"><TrasGroupClass /></a></li>
                    <li className="active">과목별 상세내용</li>
                </ol>
            </div>
        )
    }

    createContentHtml = () => {
        const { lang, showData, educaBgTmp } = this.state;

        return (
            <div className="container">
                <div className={showData.state === 2 ? 'opacity-50' : ''}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="group_class_jumbotron">
                                <h1 className="margin-bottom-20">{showData.title}</h1>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            {/* <td><p><TrasStartingEndDate/></p></td> */}
                                            {/* <td>
                                                {
                                                    showData.start_time === '2000-1-1' ?
                                                    <p>상시 수업중</p> :
                                                    <p>{showData.start_time} ~ {showData.end_time}</p>
                                                }
                                            </td> */}
                                        </tr>
                                        <tr>
                                            <td><p><TrasTimetable /></p></td>
                                            <td><p>{showData.timetable}</p></td>
                                        </tr>
                                        <tr>
                                            <td><p><TrasLocation /></p></td>
                                            <td><p>{showData.location}</p></td>
                                        </tr>
                                        {/* <tr>
                                            <td><p><TrasNumberofstudents/></p></td>
                                            <td><p>{showData.student_number} <i>(3 <Trasstudentshavealreadysignedup/>)</i>
                                            </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                                <button className="btn submit-btn"><a href="/#/contact_us"><TrasEnquiry /></a></button>
                                {
                                    showData.status === 2 ?
                                        <h4 className="brown-font-color"><TrasFinished /></h4> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            {/* <!--<img ng-src="/images/group_class_example.jpg" style="width: 100%">-->
                            <!--<iframe className="embed-responsive-item" ng-src="https://www.youtube.com/embed/nDE2DTDGLkE?list=PLCn-JhjRTGbNvgqr8aExX-SPUgua97DLB?ecver=1" frameborder="0"></iframe>--> */}
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="margin-bottom-10"><TrasLearningOutcome /></h3>
                                    <p><i className="fa fa-check blue-text"></i>&nbsp;{showData.outcome1}</p>
                                    {
                                        showData.outcome2 !== '' ?
                                            <p>
                                                <i className="fa fa-check blue-text"></i>&nbsp;{showData.outcome2}
                                            </p> : ''
                                    }
                                    {
                                        showData.outcome3 !== '' ?
                                            <p>
                                                <i className="fa fa-check blue-text"></i>&nbsp;{showData.outcome3}
                                            </p> : ''
                                    }
                                    {
                                        showData.outcome4 !== '' ?
                                            <p>
                                                <i className="fa fa-check blue-text"></i>&nbsp;{showData.outcome4}
                                            </p> : ''
                                    }
                                    <hr className="dotted-line" />
                                    <h3 className="margin-bottom-10"><TrasClassCurriculum /></h3>
                                    {/* <!--           <h4>1주차</h4>
                                            <li>predicting the direction of enthalpy/entropy changes (chemical and physical changes)</li>
                                            <h4>2주차</h4>
                                            <li>predicting the direction of enthalpy/entropy changes (chemical and physical changes)</li> --> */}

                                    <p dangerouslySetInnerHTML={{ __html: showData.description || '' }} className="group_class_curriculum table-highlight"></p>

                                    {
                                        showData.textbook && showData.textbook.length > 0 ?
                                            <div>
                                                <hr className="dotted-line" />
                                                <h3><TrasTextbook /></h3>
                                                {
                                                    showData.textbook.map((item, index) =>
                                                        <div className="d-inline-block">
                                                            <img alt="img" src={item} data-toggle="modal" onClick={() => this.showTextBook(index)} />
                                                        </div>
                                                    )
                                                }
                                            </div> : ''
                                    }
                                    {
                                        showData.youtubeEmbedUrls && showData.youtubeEmbedUrls.length > 0 ?
                                            <div>
                                                <hr className="dotted-line" />
                                                <h3>수업 영상</h3>
                                                {
                                                    showData.youtubeEmbedUrls.map((item, index) => {
                                                        return (
                                                            <div className="margin-bottom-10 embed-responsive embed-responsive-16by9">
                                                                <iframe title="iframe" className="embed-responsive-item" src={item} frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div> : ''
                                    }
                                    {
                                        showData.endOfTestDocUrls && showData.endOfTestDocUrls.length > 0 ?
                                            <div>
                                                <hr className="dotted-line" />
                                                <h3>수업 종강 시험</h3>
                                                <p className="margin-left-10">
                                                    {
                                                        showData.endOfTestDocUrls.map((item, index) =>
                                                            <span>{index + 1}. <i className="fa fa-file-word-o"></i> <a className="blue-font-color" href={item}>{item}</a> <br /> </span>

                                                        )
                                                    }
                                                </p>
                                            </div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            showData.tutor ?
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <img alt="img" className="rounded-circle blog-show-author-pic center-block" src={showData.tutor.picture} onError={function (e) { e.target.src = '../images/backuptutorpic.png'; }} />
                                            <h4>{lang === 'en' ? showData.tutor.first_name_en + showData.tutor.last_name_en :
                                                showData.tutor.last_name + showData.tutor.first_name}</h4>
                                            {
                                                educaBgTmp.Undergraduate ?
                                                    <h4>{educaBgTmp.Undergraduate.school} -
                                                        {
                                                            (educaBgTmp.Undergraduate.major || []).map((item, index) =>
                                                                <span key={'major' + index}>{item}</span>
                                                            )
                                                        }
                                                    </h4> : ''
                                            }
                                            <p className="teacher-info-p">{showData.tutor.about_myself}</p>
                                            <a href={'/#/teachers/' + showData.tutor_id} className="btn cancel-btn"><TrasViewProfile /></a>
                                        </div>
                                    </div>
                                    {/* <!--
                                    <div className="card">
                                    <div className="card-body">
                                        <center>
                                        <p>'그룹수업'이란 그루미의 검증된 선생님들이 자유롭게 수업을 모집할 수 있게 운영비를 최소화 한 플랫폼입니다.</p>
                                        <h3><span className="fa fa-check blue-text" aria-hidden="true"></span> 학생은 더 낮은 수업료</h3>
                                        <h3><span className="fa fa-check blue-text" aria-hidden="true"></span> 선생님은 더 많은 수입</h3>
                                        <p>3~5명 소수정예의 수업을 제공합니다.</p>
                                        </center>
                                    </div>
                                    </div>
                                    --> */}
                                </div> : ''
                        }
                    </div>
                    <div className="row margin-top-20 margin-bottom-20">
                        <div className="col-md-12">
                            {
                                showData.is_this_tutor === 1 ? <a href={'/#/group_class/' + showData.id + '/edit'} className="btn submit-btn"><TrasEdit /></a> : ''
                            }
                            <a href="/#/group_class_list" className="btn cancel-btn"><TrasBack /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // createApplyModal = () => { 
    //     return(
    //         <div id="apply" className="modal fade" role="dialog">
    //             <div className="modal-dialog">
    //                 <div className="modal-content">
    //                     <div className="modal-header">
    //                         <button type="button" className="close" data-dismiss="modal">&times;</button>
    //                         <h4 className="modal-title"><TrasSignUp/></h4>
    //                     </div>
    //                     <div className="modal-body">
    //                         <p>Please make sure you have contacted the tutor and discussed about your </p>
    //                         <p>Please transfer to GuruMe Bank account. After transfer has been confirmed, you sign
    //                             up will be
    //                             confirmed.</p>
    //                         <button type="button" className="btn submit-btn" data-dismiss="modal">
    //                             <TrasSubmit/>
    //                         </button>
    //                     </div>
    //                 </div>

    //             </div>
    //         </div>
    //     )
    // }

    // createConsultationModal = () => { 
    //     return(
    //         <div id="consultation" className="modal fade" role="dialog">
    //             <div className="modal-dialog">
    //                 <div className="modal-content">
    //                     <div className="modal-header">
    //                         <button type="button" className="close" data-dismiss="modal">&times;</button>
    //                         <h4 className="modal-title"><TrasApply/></h4>
    //                     </div>
    //                     <div className="modal-body">
    //                         <p>Please make sure you have contacted the tutor and discussed about your </p>
    //                         <p>Please transfer to GuruMe Bank account. After transfer has been confirmed, you sign
    //                             up will be
    //                             confirmed.</p>
    //                         <button type="button" className="btn submit-btn" data-dismiss="modal"
    //                                 ng-click="sendAppealMsg()">
    //                             <TrasSubmit/>
    //                         </button>
    //                     </div>
    //                 </div>

    //             </div>
    //         </div>
    //     )
    // }

    // createTextbookModal = () => { 
    //     return(
    //         <div id="textbook" className="modal fade" role="dialog">
    //             <div className="modal-dialog">
    //                 <div className="modal-content">
    //                     <div className="modal-body">
    //                         <img alt="" />
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    render = () => {
        return (
            <div className="group-class-show-container">
                {this.createBreadcrumb()}
                {this.createContentHtml()}
            </div>
        )
    }
}

export default withRouter(GroupClassShow);
