import React, { Component } from 'react';
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';

import {
    TrasHome,
    TrasShowdetail

} from '../../Translate/EachTranslateC';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPencilAlt, faBook } from '@fortawesome/free-solid-svg-icons';

import './academy.css';

/* fulltime_teachers profile pic */
import TeacherMath from '../../images/academy/full_time_tutor_intro_mathmatics.jpg';
import TeacherChemistry from '../../images/academy/full_time_tutor_intro_chemistry.jpg';
import TeacherPhysics from '../../images/academy/full_time_tutor_intro_physics.jpg';
import TeacherBiology from '../../images/academy/full_time_tutor_intro_biology.jpg';
import TeacherEnglish from '../../images/academy/full_time_tutor_intro_english.jpg';
import TeacherBusiness from '../../images/academy/full_time_tutor_intro_business.jpg';

class Academy extends Component {
    goToTeacherMath = () => {
        window.open('/#/teachers/1187');
    }
    goToTeacherChemistry = () => {
        window.open('/#/teachers/70');
    }
    goToTeacherBiology = () => {
        window.open('/#/teachers/2142');
    }
    goToTeacherPhysics = () => {
        window.open('/#/teachers/3430');
    }
    goToTeacherEnglish = () => {
        window.open('/#/teachers/1567');
    }
    goToTeacherBusiness = () => {
        window.open('/#/teachers/559');
    }

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active">그루미 아카데미</li>
                </ol>
            </div>
        )
    }

    createAcademy = () => {
        return (
            <div className="academy-banner">
                <div className="academy-banner-box">
                    <div className="academy-banner-main-wrapper">
                        <h1 className="text-center color-white font-size-56 boldest-font">IB 전문 학원 서비스</h1>
                        <h2 className="text-center color-white">학원 원장님급의 과목별 선생님들께서 함께 설립한 학원</h2>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }

    createGreyBackground = () => {
        return (
            <div className="greybackground padding-top-50 padding-bottom-50">
                <div className="academy-teachers">
                    <div className="academy-teachers-box">
                        <div className="academy-teachers-main-content">
                            <div className="academy-teachers-item" onClick={this.goToTeacherMath}>
                                <div className="academy-item-title bg-gurume-blue text-center">
                                    <h4 className="color-white">Math</h4>
                                </div>
                                <div className="academy-item-content">
                                    <div className="academy-item-content-wrapper">
                                        <div className="academy-item-content-img">
                                            <img
                                                src={TeacherMath}
                                                className="margin-bottom--10"
                                                alt="full_time_tutor_intro_mathmatics" />
                                        </div>
                                        <div className="academy-item-content-text">
                                            <h3 className="color-gurume-blue">WJ Bang</h3>
                                            <p className="color-gurume-blue">
                                                <i>IB, SAT II, IA/EE</i>
                                            </p>
                                            <p>- Warwick University, B.Sc. Mathematics</p>
                                            <p>- Hamburg International School, IB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="academy-teachers-item" onClick={this.goToTeacherPhysics}>
                                <div className="academy-item-title bg-gurume-brown text-center ">
                                    <h4 className="color-white">Physics</h4>
                                </div>

                                <div className="academy-item-content">
                                    <div className="academy-item-content-wrapper">
                                        <div className="academy-item-content-img">
                                            <img
                                                src={TeacherPhysics}
                                                className="margin-bottom--10"
                                                alt="full_time_tutor_intro_physics" />
                                        </div>
                                        <div className="academy-item-content-text">
                                            <h3 className="color-gurume-brown">Salomon Kim</h3>
                                            <p className="color-gurume-brown">
                                                <i>IB, SAT II, MYP</i>
                                            </p>
                                            <p>- Seoul National University, B.Sc. Physics</p>
                                            <p>- Chapel School Brazil, IB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="academy-teachers-item" onClick={this.goToTeacherChemistry}>
                                <div className="academy-item-title bg-gurume-green text-center">
                                    <h4 className="color-white">Chemistry</h4>
                                </div>

                                <div className="academy-item-content">
                                    <div className="academy-item-content-wrapper">
                                        <div className="academy-item-content-img">
                                            <img
                                                src={TeacherChemistry}
                                                className="margin-bottom--10"
                                                alt="full_time_tutor_intro_chemistry" />
                                        </div>
                                        <div className="academy-item-content-text">
                                            <h3 className="color-gurume-green">Brian Lee</h3>
                                            <p className="color-gurume-green">
                                                <i>IB, SAT II, AP, MYP</i>
                                            </p>
                                            <p>- Korea University, B.Sc. Chem Envi Engineering</p>
                                            <p>- Brent International School, IB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="academy-teachers-item" onClick={this.goToTeacherBiology}>
                                <div className="academy-item-title bg-gurume-red text-center ">
                                    <h4 className="color-white">Biology</h4>
                                </div>

                                <div className="academy-item-content">
                                    <div className="academy-item-content-wrapper">
                                        <div className="academy-item-content-img">
                                            <img
                                                src={TeacherBiology}
                                                className="margin-bottom--10"
                                                alt="full_time_tutor_intro_physics" />
                                        </div>
                                        <div className="academy-item-content-text">
                                            <h3 className="color-gurume-red">Mary Chung</h3>
                                            <p className="color-gurume-red">
                                                <i>IB, SAT II, MYP</i>
                                            </p>
                                            <p>- Seoul National University, Nursing</p>
                                            <p>- British International School of HCMC, IB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="academy-teachers-item" onClick={this.goToTeacherEnglish}>
                                <div className="academy-item-title bg-gurume-gray text-center ">
                                    <h4 className="color-white">English</h4>
                                </div>

                                <div className="academy-item-content">
                                    <div className="academy-item-content-wrapper">
                                        <div className="academy-item-content-img">
                                            <img
                                                src={TeacherEnglish}
                                                className="margin-bottom--10"
                                                alt="full_time_tutor_intro_english" />
                                        </div>
                                        <div className="academy-item-content-text">
                                            <h3 className="color-gurume-gray">Eric Ahn</h3>
                                            <p className="color-gurume-gray">
                                                <i>IB, iGCSE</i>
                                            </p>
                                            <p>- Yonsei University, B.A. English Literature</p>
                                            <p>- West Vancouver Secondary School, IB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="academy-teachers-item" onClick={this.goToTeacherBusiness}>
                                <div className="academy-item-title bg-gurume-dark-gray text-center ">
                                    <h4 className="color-white">Business</h4>
                                </div>

                                <div className="academy-item-content">
                                    <div className="academy-item-content-wrapper">
                                        <div className="academy-item-content-img">
                                            <img
                                                src={TeacherBusiness}
                                                className="margin-bottom--10"
                                                alt="full_time_tutor_intro_business" />
                                        </div>
                                        <div className="academy-item-content-text">
                                            <h3 className="color-gurume-dark-gray">Sara Shim</h3>
                                            <p className="color-gurume-dark-gray">
                                                <i>IB, iGCSE</i>
                                            </p>
                                            <p>- Seoul National University, B.A. Management</p>
                                            <p className="no-margin-bottom">- Overseas Family School, IB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="academy-other-teachers">
                        <p>
                            <a href="/#/teachers/3167" target="_blank">Nicky Lee</a>
                            &nbsp;- Northwestern B.A. Communication Studies
                        </p>
                        <p>
                            <a href="/#/teachers/407" target="_blank">Zoe Park</a>
                            &nbsp;- Yonsei, B.A. Tech Management
                        </p>
                        <p>
                            <a href="/#/teachers/28" target="_blank">David Lee</a>
                            &nbsp;- Duke, Ph.D. Bioengineering
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    // createItems = () => {
    //     const options = {
    //         items: 1,
    //         nav: true,
    //         video: true,
    //         rewind: true
    //     }
    //     return (
    //         <OwlCarousel {...options}>
    //             <div className="item">
    //                 <div
    //                     className="embed-responsive embed-responsive-21by9 embed-responsive-16by9-xs">
    //                     <iframe
    //                         key="ifram_1"
    //                         title="demo_1"
    //                         className="embed-responsive-item"
    //                         src="https://www.youtube.com/embed/X_bq2axS7kE"
    //                         allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    //                         allowFullScreen="allowFullScreen"
    //                         frameBorder="0"></iframe>
    //                 </div>
    //             </div>
    //             <div className="item">
    //                 <div
    //                     className="embed-responsive embed-responsive-21by9 embed-responsive-16by9-xs">
    //                     <iframe
    //                         key="ifram_1"
    //                         title="demo_2"
    //                         className="embed-responsive-item"
    //                         src="https://www.youtube.com/embed/-Qob2r6hmuw"
    //                         allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    //                         allowFullScreen="allowFullScreen"
    //                         frameBorder="0"></iframe>
    //                 </div>
    //             </div>
    //             <div className="item">
    //                 <div
    //                     className="embed-responsive embed-responsive-21by9 embed-responsive-16by9-xs">
    //                     <iframe
    //                         key="ifram_1"
    //                         title="demo_3"
    //                         className="embed-responsive-item"
    //                         src="https://www.youtube.com/embed/zm8bwbH2G3A"
    //                         allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    //                         allowFullScreen="allowFullScreen"
    //                         frameBorder="0"></iframe>
    //                 </div>
    //             </div>
    //         </OwlCarousel>
    //     )
    // }

    createMentions = () => {
        return (
            <div className="academy-ment">
                <div className="academy-ment-box">
                    <div className="academy-ment-main-wrapper text-center">
                        <div className="academy-ment-item">
                            <h1 className="padding-top-100 color-black bold-font">그루미 IB 팀에서 직접적으로 수업관리를 하여
                                <br />최상위권 내신과 목표 시험 성적을 이뤄드립니다.</h1>
                            <h4 className="padding-bottom-100 padding-top-20">*학교별 선생님 스타일을 파악하고 단원별 테스트 유형을 파악하여 내신과 예상성적을 책임집니다.</h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createExpert = () => {
        return (
            <div className="greybackground">
                <div className="academy-expert no-margin-left no-margin-right">
                    <div className="academy-expert-box">
                        <h1
                            className="text-center padding-top-50 color-gurume-blue padding-bottom-20 boldest-font">IB 전문성</h1>
                        <div
                            className="academy-expert-content text-center"
                            style={{
                                padding: "50px 0px 50px 0px"
                            }}>
                            <div className="academy-expert-content-item">
                                <span
                                    style={{
                                        color: "#5b9aef",
                                        fontSize: "60px"
                                    }}
                                    aria-hidden="true">
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                                <h4
                                    style={{
                                        paddingBottom: "30px"
                                    }}>그루미 700+ 선생님 네트워크에서<br />
                                    엄선 된 최정예 강사진</h4>
                            </div>
                            <div className="academy-expert-content-item">
                                <span
                                    style={{
                                        color: "#5b9aef",
                                        fontSize: "60px"
                                    }}
                                    aria-hidden="true">
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </span>
                                <h4
                                    style={{
                                        paddingBottom: "30px"
                                    }}>IB 인강/ 개념 교재/ 문제집<br />
                                    (최신 시험 문제 포함)</h4>
                            </div>
                            <div className="academy-expert-content-item">
                                <span
                                    style={{
                                        color: "#5b9aef",
                                        fontSize: "60px"
                                    }}
                                    aria-hidden="true">
                                    <FontAwesomeIcon icon={faBook} />
                                </span>
                                <h4
                                    style={{
                                        paddingBottom: "30px"
                                    }}>“IB 과정에 대한 모든 것"<br />그루미 도서 출간</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div className="academy-book no-margin-left no-margin-right">
                    <div className="academy-book-box">
                        <h1 className="text-center color-gurume-blue padding-bottom-20 boldest-font">IB 도서 출간</h1>
                        <div
                            className="academy-book-content text-center"
                            style={{
                                padding: "20px 0px 50px 0px"
                            }}>
                            <div className="academy-book-content-item">
                                <img
                                    src="images/academy/IB Book Prologue Cover.png"
                                    style={{
                                        maxWidth: "100%"
                                    }}
                                    alt="bookCover" />
                            </div>
                            <div className="academy-book-content-item">
                                <img
                                    src="images/academy/IB Book Prologue (Table of Contents).png"
                                    style={{
                                        maxWidth: "100%"
                                    }}
                                    alt="Contents" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createMaraton = () => {
        return (
            <div className="academy-ment">
                <div className="academy-ment-box text-center">
                    <div className="academy-ment-item">
                        <h1 className="padding-top-100 color-black bold-font padding-bottom-30">
                            IB는 2년 동안의 마라톤입니다.
                            <br />선생님이자 멘토로서 항상 옆에 동반하며 함께 완주합니다.</h1>
                        <h4 className="padding-bottom-100">방학에는 특강 학습, 학기 중에는 개인 수업 혹은 인강, 교재 및 문제집을 사용한
                            셀프스터디 그리고 IA/EE/ToK 과제 멘토링을 통해 IB 성적을 책임집니다.</h4>
                    </div>
                </div>
            </div>
        )
    }

    createSchool = () => {
        return (
            <div className="academy2">
                <div className="academy-ment">
                    <div className="adademy-ment-box text-center">
                        <div className="academy-ment-item">
                            <h1 className="color-white padding-top-100 bold-font padding-bottom-30">학교에 따라 커리큘럼이 다르기 때문에
                                <br />학생 학교 진도에 맞춤형으로 교육합니다.</h1>
                            <h4 className="padding-bottom-100 color-white">*학교별로 반을 구성하고 각 학교 진도에 맞추어 수업 진행하여 학교 내신을 책임집니다.</h4>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createInteractive = () => {
        return (
            <div className="academy-ment">
                <div className="academy-ment-box text-center">
                    <div className="academy-ment-item">
                        <h1 className="padding-top-100 color-black padding-bottom-30 bold-font">학교 수업의 연장이 아닌 효율적인 Interactive<br />
                            소규모 반으로 수업 진행 합니다.</h1>
                        <h4 className="padding-bottom-100">*일방적인 (One-way) 강의가 아닌 학생들과의 소통이 있는
                            <br />토론형 (Bi-directional) 강의로 학교에서 부족한 부분을 해결해드립니다.</h4>
                    </div>
                </div>
            </div>
        )
    }

    createVacation = () => {
        return (
            <div className="academy-vacation">
                <div className="academy-vacation-box">
                    <div className="academy-vacation-main text-center">
                        <div className="academy-vacation-item">
                            <h1
                                className="color-white font-size-50 boldest-font">그루미 방학 특강
                                </h1>
                            <br />
                            <a href="/#/ib/summer_class">
                                <button className="academy-vacation-btn"><TrasShowdetail /></button>
                            </a>
                        </div>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                {this.createAcademy()}
                {this.createGreyBackground()}
                {/* {this.createItems()} */}
                {this.createMentions()}
                {this.createExpert()}
                {this.createMaraton()}
                {this.createSchool()}
                {this.createInteractive()}
                {this.createVacation()}
            </div>
        )
    }

    render() {
        return (
            <div className="academy-wrapper">
                {this.createHtml()}
            </div>
        )
    }
}

export default Academy;