import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasCredit,
    TrasWithdraw,
    TrasPaymentMethod,
    TrasProcessingFee,
    TrasWithdrawHistory,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { showNotify, STATE_NOTIFY, moneyFormat } from '../../extra/cusUtil';

import './credit_withdraw.css';
import './credit_common.css';


class credit_withdraw extends Component {


    state = {

        withdrawState: {
            bankInfo: '',
            paypalInfo: '',
            total_amount: '',
            preferred_currency: '',
            latest_payment: {
                pay_method: '1',
                account_info: '',
            },
        },

        paramState: {
            currency: '2',
            credit_history_ids: '',
            money: '',
            gcoin: '',
            pay_method: '1',
            bank_info: '',
        },

        withdraw: [],
        creditHistories: [],

        isSubmit: false,
        isShowHistoryPannel: false,
        isAjaxDone: false,
    }

    componentDidMount = () => {

        this.doInit();
    }

    doInit = async () => {
        const allUrls = await GetAjaxURL();
        const withdrawHistoryUrl = allUrls.gcoin.withdrawHistoryList;
        const withdrawHistoryRes = await axios.get(withdrawHistoryUrl).catch((err) => { return err; });

        const data = withdrawHistoryRes.data;

        const current_info = data.current_info;
        let preferred_currency = current_info.preferred_currency;
        preferred_currency = preferred_currency === '1' ? '1' : preferred_currency === '3' ? '3' : '2';


        let total_amount = current_info.total_amount.split('.');
        if (total_amount.length !== 0) {
            total_amount = total_amount[0];
        }



        let latest_payment = data.latest_payment;

        const creditHistories = data.creditHistories;
        const credit_history_ids = [];

        for (let i = 0; i < creditHistories.length; i++) {
            credit_history_ids.push(
                creditHistories[i].id
            );
        }
        let payInfo = {};
        if (String(latest_payment.pay_method) === '1') {
            payInfo = { bankInfo: latest_payment.account_info };
        } else {
            payInfo = { paypalInfo: latest_payment.account_info };
        }


        const withdraw = data.withdraw;


        this.setState({
            ...this.state,
            withdrawState: {
                ...this.state.withdrawState,
                ...payInfo,
                total_amount: total_amount,
                latest_payment: latest_payment,
                preferred_currency: preferred_currency,
            },
            paramState: {
                ...this.state.paramState,
                currency: preferred_currency,
                credit_history_ids: credit_history_ids,
                money: total_amount,
                gcoin: total_amount,
                pay_method: latest_payment.pay_method,
            },

            withdraw: withdraw,
            creditHistories: creditHistories,
            isAjaxDone: true,
        }, () => {

            if (String(this.state.paramState.pay_method) === '1') {
                const bank = document.getElementById('bank');
                this.doToggle(bank);
            } else {
                const paypal = document.getElementById('paypal');
                this.doToggle(paypal);
            }
        })

    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/credit/balance"><TrasCredit /></a></li>
                    <li className='active'><TrasWithdraw /></li>
                </ol>
            </div>
        )
    }

    createWithdrawHeader = () => {
        return (
            <div className='credit-withdraw-head'>
                <h3 className="blue-font-color"><TrasCredit /> <TrasWithdraw /></h3>
                <div>
                    Credit awaiting withdraw: {moneyFormat(this.state.withdrawState.total_amount) + ' '}
                    {
                        String(this.state.withdrawState.preferred_currency) === '1' ? 'RMB' :
                            String(this.state.withdrawState.preferred_currency) === '2' ? 'KRW' : 'GBP'
                    }
                    <span>
                        <button className='credit-default-btn credit-default-red-btn' onClick={this.doShowAndHideHistory}><TrasWithdraw /> 기록</button>
                    </span>
                </div>
            </div>
        )
    }

    createWithdrawBoides = () => {

        const creditHistories = this.state.creditHistories;

        const li = [];

        for (let i = 0; i < creditHistories.length; i++) {
            const student_name = creditHistories[i].student_name;
            const created_at = creditHistories[i].created_at;
            const memo = creditHistories[i].memo;
            const gcoin = creditHistories[i].gcoin;

            let date = created_at.split(' ');
            let sDate = '';
            let sTime = '';
            if (date.length !== 0) {
                sDate = date[0];
                sTime = date[1];
            }

            const dateSpan =
                <span className='credit-bt-bodies-date'>
                    <div>{sDate}</div>
                    <div>{sTime}</div>
                </span>;

            const iconSpan = <span><i className="fa fa-star fa-1x"></i></span>;
            const typeSpan = <span>{student_name}</span>;

            let gcoinSpan = <span>₩ {moneyFormat(gcoin)}</span>;
            if (Number(gcoin) < 0) {
                // 표기 변경
                // -,150,000 으로 표기되니 150,000으로 표기되고 앞 쪽에 '-' 추가 함으로써 마이너스 표시함.
                let absGgoin = Math.abs(gcoin);
                gcoinSpan = <span>₩ -{moneyFormat(absGgoin)}</span>;
            }
            li.push(
                <li key={'gCoinList_' + i}>
                    {iconSpan}
                    {typeSpan}
                    <span>{memo}</span>
                    {gcoinSpan}
                    {dateSpan}
                </li>
            );
        }


        return (
            <div className='credit-default-body'>
                <div className='credit-default-table'>
                    <div className='credit-bt-header'>
                        <span><i className="fa fa-star fa-1x"></i></span>
                        <span>학생</span>
                        <span>수업 내용</span>
                        <span>Amount</span>
                        <span>년도</span>
                    </div>
                    <div className='credit-bt-bodies'>
                        <ul>
                            {li}
                        </ul>
                    </div>
                </div>
            </div>

        )
    }



    doToggle = (_dom) => {
        const dom = _dom;
        for (let i = 0; i < dom.classList.length; i++) {
            if (dom.classList[i] === 'credit-default-input-box-hide') {
                dom.classList.remove('credit-default-input-box-hide');
                dom.classList.add('credit-default-input-box-show');
                break;
            }
            if (dom.classList[i] === 'credit-default-input-box-show') {
                dom.classList.remove('credit-default-input-box-show');
                dom.classList.add('credit-default-input-box-hide');
                break;
            }
        }
    }

    onChangePaymentWay = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                pay_method: value,
            }
        }, () => {
            const bank = document.getElementById('bank');
            this.doToggle(bank);
            const paypal = document.getElementById('paypal');
            this.doToggle(paypal);
        })
        // pay_method
    }


    onChangeBankInfo = (e) => {
        const target = e.target;
        const value = target.value;
        this.setState({
            ...this.state,
            withdrawState: {
                ...this.state.withdrawState,
                bankInfo: value,
            },
            paramState: {
                ...this.state.paramState,
                bank_info: value,
            }
        });
    }
    onChangePaypalInfo = (e) => {
        const target = e.target;
        const value = target.value;
        this.setState({
            ...this.state,
            withdrawState: {
                ...this.state.withdrawState,
                paypalInfo: value,
            }
        });
    }

    createPaymentWay = () => {

        let bankRadio = <input onChange={this.onChangePaymentWay} type='radio' name='paymentWay' value='1' />;
        let paypalRadio = <input onChange={this.onChangePaymentWay} type='radio' name='paymentWay' value='2' />;
        if (String(this.state.paramState.pay_method) === '1') {
            bankRadio = <input onChange={this.onChangePaymentWay} type='radio' name='paymentWay' value='1' defaultChecked />;
        } else {
            paypalRadio = <input onChange={this.onChangePaymentWay} type='radio' name='paymentWay' value='2' defaultChecked />;
        }

        return (
            <div className='credit-default-body'>
                <h3 className="blue-font-color"><TrasPaymentMethod /></h3>
                <div className='credit-default-input-box'>
                    <label>
                        {bankRadio} 계좌이체
                    </label>
                    <span>
                        <TrasProcessingFee /> 0%
                    </span>
                    <div id='bank' className='credit-default-input-box-reponsive credit-default-input-box-hide'>
                        <div>계좌정보:</div>
                        <textarea onChange={this.onChangeBankInfo} value={this.state.withdrawState.bankInfo} />
                    </div>
                </div>

                <div className='credit-default-input-box'>
                    <label>
                        {paypalRadio} Paypal
                    </label>
                    <span>
                        <TrasProcessingFee /> 2.5%
                    </span>
                    <div id='paypal' className='credit-default-input-box-reponsive credit-default-input-box-hide'>
                        <div>Paypal email address:</div>
                        <input type='text' name='' onChange={this.onChangePaypalInfo} value={this.state.withdrawState.paypalInfo} />
                    </div>
                </div>


            </div>
        )
    }


    createWithdrawHistoryPannel = () => {

        const withdraw = this.state.withdraw;

        const li = [];
        for (let i = 0; i < withdraw.length; i++) {
            const created_at = withdraw[i].created_at;
            const gcoin = withdraw[i].gcoin;

            li.push(
                <li key={'_key_' + i}>
                    <span>{moneyFormat(gcoin)}</span>
                    <span>{created_at}</span>
                </li>
            );
        }

        return (
            <div className='credit-withdraw-pannel'>
                <div className='credit-withdraw-pannel-card'>
                    <h5> <TrasWithdrawHistory />  </h5>
                    <div className='credit-withdraw-table2'>
                        <div className='credit-withdraw-table2-header'>
                            <span>Amount</span>
                            <span>년도</span>
                        </div>
                        <div className='credit-withdraw-table2-body'>
                            <ul>
                                {li}
                            </ul>
                        </div>
                    </div>
                    <div className='credit-withdraw-table2-btns'>
                        <button className='credit-default-btn credit-default-blue-btn' onClick={this.doShowAndHideHistory}>close</button>
                    </div>
                </div>
            </div>
        )
    }

    onSubmitCreditWithdraw = async () => {

        if (this.state.isSubmit) return;
        this.setState({
            ...this.state,
            isSubmit: true,
        });


        const currency = this.state.paramState.currency;
        const credit_history_ids = this.state.paramState.credit_history_ids;
        const money = Math.round(this.state.paramState.money);
        const gcoin = this.state.paramState.gcoin;
        const pay_method = this.state.paramState.pay_method;
        const bank_info = this.state.paramState.bank_info;
        const creditHistoryIds = credit_history_ids.join('$');


        const formData = new FormData();
        formData.append('currency', currency);
        formData.append('credit_history_ids', creditHistoryIds);
        formData.append('money', money);
        formData.append('gcoin', gcoin);
        formData.append('pay_method', pay_method);
        formData.append('bank_info', bank_info);

        let cnMsg = '인출 금액 : ' + money + '이 맞으시나요?';
        if (!window.confirm(cnMsg)) {
            this.setState({
                ...this.state,
                isSubmit: false,
            });
            return;
        }

        const allUrls = await GetAjaxURL();
        const withdrawUrl = allUrls.gcoin.withdraw;
        const withdrawRes = await axios.post(withdrawUrl, formData, postConfig).catch((err) => { return console.log(err); });

        const withdrawData = withdrawRes.data;

        if (withdrawData.status === 'success') {
            this.setState({
                ...this.state,
                isSubmit: false,
            }, () => {
                let msg = '성공적으로 요청했습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
                window.location.href = '/#/credit/balance';
            });
        } else {
            this.setState({
                ...this.state,
                isSubmit: false,
            }, () => {
                let msg = '요청에 실패했습니다. 고객센터에 문의해주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            });
        }

    }

    // credit_history_ids: "26$27$30$31$33$37$139$141"
    createWithdrawBtn = () => {
        return (
            <div className='withdrawBtns'>

                <button className='credit-default-btn credit-default-blue-btn' onClick={this.onSubmitCreditWithdraw}>
                    {
                        !this.state.isSubmit ?
                            <TrasWithdraw />
                            : '잠시만 기다려주세요.'
                    }
                </button>
            </div>
        )
    }


    createHtml = () => {
        return (
            <div>
                <div className='academy-wapper-c credit-default-resize'>
                    {this.createWithdrawHeader()}
                    {this.createWithdrawBoides()}
                    {
                        this.state.isAjaxDone ?
                            this.createPaymentWay()
                            : '데이터를 불러오고 있습니다.'
                    }

                    <br />
                    {this.createWithdrawBtn()}

                </div>
            </div>
        )
    }


    doShowAndHideHistory = () => {
        this.setState({
            ...this.state,
            isShowHistoryPannel: !this.state.isShowHistoryPannel
        });
    }


    render() {
        return (
            <div className='credit-withdraw-wapper'>
                {this.createNav()}
                {this.createHtml()}

                {
                    this.state.isShowHistoryPannel ?
                        this.createWithdrawHistoryPannel()
                        : ''
                }

            </div>
        )
    }
}


export default withRouter(credit_withdraw);