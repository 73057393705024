export const cusUtilStart = (_this_) => {
    const wrapperSize = window.innerHeight - (260 + 112.5);
    if(!window.hasOwnProperty('onresize')){
        window.addEventListener('resize', _this_.calScreenSizeAndResizeWrapper);
    }
    return _this_.calScreenSizeAndResizeWrapper(wrapperSize);
}

export const STATE_NOTIFY = {TYPE_POSITIVE : 'TYPE_POSITIVE', TYPE_NEGATIVIE : 'TYPE_NEGATIVIE', TYPE_INFO : 'TYPE_INFO'}

export const showNotify = (_msg, _type) => {
    const showNotify = document.getElementById('showNotify');
    if(!showNotify) return console.log("CAN NOT FIND (DOM)showNotify");
    if(!_type) return console.log("CAN NOT FIND _type in showNotify");

    let divWrap = document.createElement("DIV");
    divWrap.classList.add("showNotify");
    
    let div = document.createElement("DIV");
    if(_type === "TYPE_POSITIVE"){
        div.classList.add('type-positive');
    }else if(_type === 'TYPE_INFO'){
        div.classList.add('type-info');
    }else{
        div.classList.add('type-negative');
    }

    let iconSpan = document.createElement("i");
    iconSpan.className = 'fa fa-info-circle';
    let span = document.createElement("SPAN");
    span.innerHTML = _msg;
    
    div.appendChild(iconSpan);
    div.appendChild(span);
    divWrap.appendChild(div);
    showNotify.appendChild(divWrap);

    div.addEventListener('animationend', ()=>{
        showNotify.removeChild(divWrap);
    });
}


let xsSound;
export const Inits = (_this) => {
    if(!xsSound){
        xsSound = setInterval(() => {
            _this.Inits();
        }, 5000);
    }
}


export const moneyFormat = (_money) => {
    const newMoney = [];
    const tdMoney = String(_money);
    const remainMoneyLength = Math.ceil(tdMoney.length / 3);

    for(let k = 0; k < remainMoneyLength; k++){
        let currentPos = (tdMoney.length - 3* (k+1));
        let currentLastPos = tdMoney.length - (3 * k);
        if(currentPos < 0){
            currentPos = 0;
        }
        const tempStr = tdMoney.slice(currentPos , currentLastPos);
        newMoney.push(tempStr);
    }
    let moneyWithFormat = '';
    while(newMoney.length > 0){
        const getStr = newMoney.pop();
        moneyWithFormat += getStr + ',';
    }
    moneyWithFormat = moneyWithFormat.slice(0, moneyWithFormat.length-1);

    return moneyWithFormat;
}


export const TypeConsume = (_type) => {
    let consumeType = '';
    switch(_type){
        case 1:
            consumeType="Charge";
            break;
        case 2:
            consumeType="Withdraw";
            break;
        case 3:
            consumeType="Class";
            break;
        case 4:
            consumeType="Q&A";
            break;
        case 5:
            consumeType="MOOC Fee";
            break;
        default:
            consumeType="Unknown";
            break;
    }
    return consumeType;
}