import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    TrasTest,
    TrasAdmission,
    TrasEnglish,

} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL } from '../../../config/AjaxConfig';


import './SubjectSelect.css';
import { showNotify, STATE_NOTIFY } from "../../extra/cusUtil";

const SubjectSelectPropTypes = {
    curriculumsData: PropTypes.any,
    sendDataToParent: PropTypes.func,
    // getEducationsDataFromSubjectSelect : PropTypes.func,
    teacherId: PropTypes.number,
    studentId: PropTypes.number,

}
const SubjectSelectDefaultProps = {
    curriculumsData: {},
    sendDataToParent: () => { console.log("sendDataToParent NOT SETTING IN SubjectSelect.js") },
    teacherId: null,
    studentId: null
}


class SubjectSelect extends Component {


    state = {
        lang: 'ko',
        translate: {
            ko: {
                teachingSubjects: '수업과목',
                search: '검색',
            },
            en: {
                teachingSubjects: 'Teaching Subjects": "',
                search: 'Search',
            },
            cn: {
                teachingSubjects: '教学科目',
                search: '搜索',
            }
        },
        currentSub: 'Test',
        SUBJECT_TYPES: {
            ADMISSION: 'Admission',
            ENGLISH: 'English',
            TEST: 'Test'
        },
        selectedIndex: [],     // key data
        education_bg: [],
        location: {},
        isFilterUp: false,
        filterData: []
    }

    shouldComponentUpdate = (nextProps, nextState) => {

        if (Object.keys(nextProps).length <= 0) return false;
        if (nextProps.length <= 0) return false;

        return true;
    }


    componentDidMount = async () => {

        // THIS WILL BE INIT STATE FOR AUTO SETTINGS
        if (this.props.teacherId !== -1) {
            await this.AJAX_teacherProfile(this.props.teacherId);
        }

        if (this.props.studentId !== -1) {
            await this.AJAX_studentProfile(this.props.studentId);
        }
    }

    AJAX_studentProfile = async (_studentId) => {
        const studentId = _studentId;
        const allUrls = await GetAjaxURL();

        if (studentId === null) return;

        const url = allUrls.apiThisUser;
        const response = await axios.get(url).catch((err) => { return console.log(err) });

        const profile = response.data.profile;
        const expected_subjects = profile.expected_subjects;

        const allCurriculumsData = this.props.curriculumsData;
        const allKeys = Object.keys(allCurriculumsData);
        if (allKeys.length <= 0) return;


        const resDataTeachingData = expected_subjects;
        const allResData = [];

        for (let i = 0; i < resDataTeachingData.length; i++) {
            const subs = resDataTeachingData[i];
            allResData.push(subs);
        }

        const allArrC = [];
        for (let i = 0; i < allKeys.length; i++) {
            const eachRank = allCurriculumsData[allKeys[i]];
            const eachRankKey = Object.keys(eachRank);
            for (let k = 0; k < eachRankKey.length; k++) {
                const innerArr = eachRank[eachRankKey[k]];
                for (let j = 0; j < innerArr.length; j++) {
                    const newObj = {
                        Major: allKeys[i],
                        Subject: eachRankKey[k],
                        Title: innerArr[j].content,
                        keyInOrigin: innerArr[j].id
                    };

                    allArrC.push(newObj);

                }
            }
        }

        const initData = [];
        for (let k = 0; k < allResData.length; k++) {
            for (let i = 0; i < allArrC.length; i++) {
                if (String(allArrC[i].keyInOrigin) === String(allResData[k].id)) {
                    initData.push(allArrC[i]);
                    break;
                }
            }
        }

        this.setState({
            ...this.state,
            selectedIndex: [
                ...initData
            ],
        }, () => {
            const ss_admission = document.getElementById('ss_admission');
            ss_admission.click();
            this.props.sendDataToParent(this.state.selectedIndex);
        });


    }


    AJAX_teacherProfile = async (_teacherId) => {

        const teacherID = _teacherId;
        const allUrls = await GetAjaxURL();

        if (teacherID === null) return;

        const url = allUrls.profile + '/' + teacherID;
        const response = await axios.get(url).catch((err) => { return console.log(err) });

        if (!response) return;
        if (!response['data']) return;
        if (!response['data']['teaching_major']) return;

        const education_bg = response['data']['education_bg'];
        const location = response['data']['location'];


        const allCurriculumsData = this.props.curriculumsData;
        const allKeys = Object.keys(allCurriculumsData);
        if (allKeys.length <= 0) return;


        const resDataTeachingData = response.data.teaching_major;
        const allResData = [];


        for (let i = 0; i < resDataTeachingData.length; i++) {
            const subs = resDataTeachingData[i]['subject_name'];

            allResData.push(...subs);

        }

        const allArrC = [];
        for (let i = 0; i < allKeys.length; i++) {
            const eachRank = allCurriculumsData[allKeys[i]];
            const eachRankKey = Object.keys(eachRank);
            for (let k = 0; k < eachRankKey.length; k++) {
                const innerArr = eachRank[eachRankKey[k]];
                for (let j = 0; j < innerArr.length; j++) {
                    const newObj = {
                        Major: allKeys[i],
                        Subject: eachRankKey[k],
                        Title: innerArr[j].content,
                        keyInOrigin: innerArr[j].id
                    };

                    allArrC.push(newObj);

                }
            }
        }

        const initData = [];
        for (let k = 0; k < allResData.length; k++) {
            for (let i = 0; i < allArrC.length; i++) {
                if (allArrC[i].keyInOrigin === allResData[k].id) {
                    initData.push(allArrC[i]);
                    break;
                }
            }
        }

        this.setState({
            ...this.state,
            selectedIndex: [
                ...initData
            ],
            education_bg: education_bg,
            location: location
        }, () => {
            this.setInitToParent();
        });
    }

    setInitToParent = () => {


        const eduInfo = this.state.education_bg;

        const bgData = [];

        let highSchoolEducationBg = {};
        let undergraduateEducationBg = {};
        let masterEducationBg = {};
        let phDEducationBg = {};
        for (let i = 0; i < eduInfo.length; i++) {

            const degree = eduInfo[i]['degree'];

            switch (degree) {
                case 'High School':
                    highSchoolEducationBg.degree = 'High School';
                    highSchoolEducationBg.highschool = eduInfo[i].testName;
                    highSchoolEducationBg.testName = eduInfo[i].testName;
                    highSchoolEducationBg.time = ' - ';
                    break;
                case 'Undergraduate':
                    undergraduateEducationBg.degree = 'Undergraduate';
                    undergraduateEducationBg.universityID = eduInfo[i].universityID;
                    undergraduateEducationBg.major = eduInfo[i].major;
                    undergraduateEducationBg.nameOfMajor = eduInfo[i].nameOfMajor;
                    undergraduateEducationBg.fieldOfStudy = eduInfo[i].fieldOfStudy;
                    undergraduateEducationBg.time = ' - ';
                    break;
                case 'Master':
                    masterEducationBg.degree = 'Master';
                    masterEducationBg.universityID = eduInfo[i].universityID;
                    masterEducationBg.nameOfMajor = eduInfo[i].nameOfMajor;
                    masterEducationBg.fieldOfStudy = eduInfo[i].fieldOfStudy;
                    masterEducationBg.time = ' - ';
                    break;
                case 'PhD':
                    phDEducationBg.degree = 'PhD';
                    phDEducationBg.universityID = eduInfo[i].universityID;
                    phDEducationBg.nameOfMajor = eduInfo[i].nameOfMajor;
                    phDEducationBg.fieldOfStudy = eduInfo[i].fieldOfStudy;
                    break;
            }
        }

        if (Object.keys(highSchoolEducationBg).length > 0)
            bgData.push(highSchoolEducationBg);
        if (Object.keys(undergraduateEducationBg).length > 0)
            bgData.push(undergraduateEducationBg);
        if (Object.keys(masterEducationBg).length > 0)
            bgData.push(masterEducationBg);
        if (Object.keys(phDEducationBg).length > 0)
            bgData.push(phDEducationBg);


        this.props.sendDataToParent(this.state.selectedIndex, bgData);
        // this.props.getEducationsDataFromSubjectSelect(bgData);
    }


    chagneSelectSubject = (e) => {
        const target = e.target;
        let data = target.dataset['subject'];

        // GET FROM PARENT NODE
        if (!data) {
            data = target.parentNode.dataset['subject'];
        }

        if (!data) return;

        this.setState({
            ...this.state,
            currentSub: data,
            isFilterUp: false,
            filterData: []
        }, () => {
            this.refreshList();
            this.initFilterValue();
        });
    }

    initFilterValue = () => {
        const filterId = document.getElementById('filterId');
        filterId.value = '';
    }

    refreshList = () => {
        const tutorregSubjectUl = document.getElementById('tutorreg-subject-ul');

        const selectedIndex = this.state.selectedIndex;
        const SELECT_CLASS_NAME = `tutorreg-${this.state.currentSub}-selected`;
        for (let i = 0; i < tutorregSubjectUl.childElementCount; i++) {

            for (let k = 0; k < tutorregSubjectUl.children[i].classList.length; k++) {

                // reset class expect tutorreg-subject-li
                if (tutorregSubjectUl.children[i].classList[k] !== 'tutorreg-subject-li') {
                    tutorregSubjectUl.children[i].classList.remove(tutorregSubjectUl.children[i].classList[k]);
                }
            }

            // selected again if there is data in this.state.selectedIndex
            const keyInOrigin = tutorregSubjectUl.children[i].dataset['keyInOrigin'];
            if (selectedIndex.length > 0) {
                for (let j = 0; j < selectedIndex.length; j++) {
                    if (keyInOrigin === String(selectedIndex[j].keyInOrigin)) {
                        tutorregSubjectUl.children[i].classList.add(SELECT_CLASS_NAME);
                    }
                }
            }
        }
    }

    createPartA = () => {
        return (
            <div className="subject-select-btns">
                <ul>
                    <li>
                        <button className="btn test-btn base-btn" data-subject={this.state.SUBJECT_TYPES.TEST}
                            onClick={this.chagneSelectSubject}>
                            <TrasTest />
                        </button>
                    </li>
                    <li>
                        <button id='ss_admission' className="admission-btn base-btn btn" data-subject={this.state.SUBJECT_TYPES.ADMISSION}
                            onClick={this.chagneSelectSubject}>
                            <TrasAdmission />
                        </button>
                    </li>
                    <li>
                        <button className="english-btn base-btn btn" data-subject={this.state.SUBJECT_TYPES.ENGLISH}
                            onClick={this.chagneSelectSubject}>
                            <TrasEnglish />
                        </button>
                    </li>
                </ul>
            </div>
        )
    }


    selectedCurriculums = (e) => {
        const target = e.target;
        const keyInOrigin = target.dataset['keyInOrigin'];
        const subject = target.dataset['subject'];
        const title = target.dataset['title'];

        const currentClassName = `tutorreg-${this.state.currentSub}-selected`;


        let checkBool = false;
        for (let i = 0; i < target.classList.length; i++) {

            if (target.classList[i] === currentClassName) {
                checkBool = true;
            }
        }
        if (checkBool) {
            target.classList.remove(currentClassName);
            this.deleteSubject(e);
        } else {
            if (this.state.selectedIndex.length >= 15) {
                showNotify("Please select at most 15 curriculums!", STATE_NOTIFY.TYPE_NEGATIVIE);
                return;
            }


            target.classList.add(currentClassName);

            const inputData = {
                Major: this.state.currentSub,
                Subject: subject,
                Title: title,
                keyInOrigin: keyInOrigin
            }
            this.setState({
                ...this.state,
                selectedIndex: [
                    ...this.state.selectedIndex,
                    inputData
                ]
            }, () => {
                this.props.sendDataToParent(this.state.selectedIndex);
            })


        }


    }

    filterSubjects = (e) => {

        if (e.target.value === '') {
            this.setState({
                ...this.state,
                isFilterUp: false,
                filterData: []
            });

        } else {

            if (Object.keys(this.props.curriculumsData).length <= 0) return;

            const allDataSubjects = this.props.curriculumsData;

            const inputData = e.target.value;


            const subj = allDataSubjects[this.state.currentSub];
            const keys = Object.keys(subj);

            const newArr = [];
            let temp = {};


            for (let i = 0; i < keys.length; i++) {
                const tempData =
                    subj[keys[i]].filter((dataObj) => {
                        return dataObj.content.toLowerCase().indexOf(inputData) >= 0;
                    });
                for (let k = 0; k < tempData.length; k++) {
                    const newObj = {
                        key: keys[i],
                        value: tempData[k],
                        keyInOrigin: tempData[k].id
                    }
                    newArr.push(newObj);
                }
            }
            newArr.sort((a, b) => {
                return a.value.content.toLowerCase().indexOf(inputData) - b.value.content.toLowerCase().indexOf(inputData);
            });

            temp = {
                Admission: newArr,
                Test: newArr,
                English: newArr
            };

            this.setState({
                ...this.state,
                isFilterUp: true,
                filterData: temp
            }, () => {
                this.refreshList();

            });
        }
    }

    createPartB = () => {

        let placeholderStr = '';
        if (this.state.lang === 'ko') {
            placeholderStr = this.state.translate.ko.teachingSubjects + this.state.translate.ko.search;
        } else if (this.state.lang === 'en') {
            placeholderStr = this.state.translate.en.teachingSubjects + this.state.translate.en.search;
        } else {
            placeholderStr = this.state.translate.cn.teachingSubjects + this.state.translate.cn.search;
        }

        let curriculumsData = [];
        if (this.state.isFilterUp) {
            curriculumsData = this.state.filterData;
        } else {
            curriculumsData = this.props.curriculumsData;
        }

        const subList_li = [];

        if (Object.keys(curriculumsData).length > 0) {


            const ajaxKey = this.state.currentSub;

            const allAdData = [];

            if (this.state.isFilterUp) {
                allAdData.push(...this.state.filterData[ajaxKey]);
            } else {
                const admissionKey = Object.keys(curriculumsData[ajaxKey]);
                for (let i = 0; i < admissionKey.length; i++) {
                    const innerData = curriculumsData[ajaxKey][admissionKey[i]];
                    for (let k = 0; k < innerData.length; k++) {
                        const jsonAdmission = {
                            key: admissionKey[i],
                            value: curriculumsData[ajaxKey][admissionKey[i]][k]
                        }
                        allAdData.push(jsonAdmission);
                    }
                }
            }


            for (let i = 0; i < allAdData.length; i++) {
                subList_li.push(
                    <li key={i} className="tutorreg-subject-li" onClick={this.selectedCurriculums}
                        data-key-in-origin={allAdData[i].value.id}
                        data-subject={allAdData[i].key}
                        data-title={allAdData[i].value.content}
                    >
                        {`(${allAdData[i].key}) ${allAdData[i].value.content}`}
                    </li>
                )
            }

        }

        return (
            <div className=" ">
                <div className="select-area">
                    <div className="no-padding base-select margin-top-5">
                        <div className="padding-10">
                            <input
                                id='filterId'
                                type="text" className="form-control subject-search-input"
                                onKeyUp={this.filterSubjects}
                            />
                        </div>
                        <div id="subject-selector" className="subject-select-wrapper with-search-input padding-5">
                            <ul id='tutorreg-subject-ul' className="tutorreg-subject-ul">
                                {subList_li}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    deleteSubject = (e) => {

        const target = e.target;
        const keyInOrigin = target.dataset['keyInOrigin'];
        const selectedItems = this.state.selectedIndex;
        const newArr = [];

        while (selectedItems.length !== 0) {
            const item = selectedItems.shift();

            if (String(item.keyInOrigin) === keyInOrigin) continue;

            newArr.push(item);
        }

        this.setState({
            ...this.state,
            selectedIndex: newArr
        }, () => {
            this.refreshList();
            this.props.sendDataToParent(this.state.selectedIndex);
        });

    }

    createPartC = () => {

        const subjectSelected = this.state.selectedIndex;

        const li = [];
        let CLASS_NAME = '';
        subjectSelected.map((item, i) => {

            let displayKrName = '시험';
            if (item.Major === this.state.SUBJECT_TYPES.TEST) {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.TEST}-selected`;
            }
            else if (item.Major === this.state.SUBJECT_TYPES.ENGLISH) {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.ENGLISH}-selected`;
                displayKrName = '영어';
            }
            else {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.ADMISSION}-selected`;
                displayKrName = '입시';
            }

            li.push(
                <li key={i}>
                    <span className={CLASS_NAME}>{displayKrName}</span>
                    <span>{item.Title}</span>
                    <img className="cursor" style={{ width: "25px", height: '25px' }}
                        src="../images/subbtn.png" alt='subbtn'
                        data-key-in-origin={item.keyInOrigin}
                        onClick={this.deleteSubject}
                    />
                </li>
            )
        });

        return (
            <div className=''>
                <div className="selected-area subject-select-wrapper">
                    <ul className='selected-area-ul'>
                        {li}
                    </ul>
                </div>
            </div>
        )
    }


    createHtml = () => {
        return (
            <div>

                {this.createPartA()}
                <div className='sub-sel-list-wrapper'>
                    <div className='sub-sel-resize'>
                        {this.createPartB()}
                        <div className="sub-sel-arrow" style={{ textAlign: "center" }}>
                            <div className="tutorreg-arrow-right center-block"></div>
                        </div>
                        {this.createPartC()}

                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.createHtml()}
            </div>
        )
    }
}

SubjectSelect.propTypes = SubjectSelectPropTypes;
SubjectSelect.defaultProps = SubjectSelectDefaultProps;

export default SubjectSelect;