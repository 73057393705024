import React, {Component} from 'react';
import {
    TrasGetStarted
} from '../../../Translate/EachTranslateC';
import './DefaultMain.css';


class DefaultMain extends Component{

    clickToBtn = () => {
        window.location.href = '/#/how_it_works/tuition';
    }

    SummonsMain_1 = () => {
        return(
            <div className='default-main-div'>
                <div className='default-main-inner'>
                    <div></div>
                    <h2>
                        <span>유학생들을 위한</span>
                        <span>교육/자료 공간</span>
                    </h2>
                    <p>영.미권 명문대생이 함께 합니다</p>
                    <button onClick={this.clickToBtn}><TrasGetStarted /></button>
                </div>
            </div>
        )
    }

    render(){
        return(
            <div className='main_wr_wrapper'>
                {this.SummonsMain_1()}
            </div>
        )
    }
}


export default DefaultMain;
