import React, {Component} from 'react';
import {
    TrasHome,
} from '../../Translate/EachTranslateC';

import './native_tutors.css';

class native_tutors extends Component{

    state = {
    }


    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/">원어민 강사진</a></li>
                </ol>
            </div>
        )
    }
    createFirstPart = () => {
        return (
            <div
                className="native-tutors-banner"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "linear-gradient(rgba(20,20,20,.3),rgba(20,20,20,.3)),url(../../images/header-bg/native-tutor-header-bg.jpg)",
                    backgroundPosition: "center"
                }}>
                <div className="native-tutors-box">
                    <div className="native-tutors-content text-center">
                        <h2 className="boldest-font native-col-white">  영국 원어민 선생님과 스카이프  <a href='/#/online_tuition' className='native-tutors-btns native-col-blue' > 온라인 수업 </a> </h2>
                    </div>
                </div>
            </div>
        )
    }

    createSecPart = () => {
        return(
            <div className="native-tutors-cons">
                <div className='native-tutors-cona'>
                <div className='native-tutors-header'> <span>그루미 원어민 강사진</span> </div>
                    <ul>
                        <li>
                            <div>
                                <img src='/images/native_tutors/cameron_h.66ba04ef.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Cameron H.</h5>
                                <h5 className='text-center light-c'>Cambridge | English/History</h5>

                                <div>
                                    I graduated last summer with a high 2.1 in English Literature and am also deeply interested in history, politics and the state of the world more generally. In terms of personality, I am very hard working and reliable, but in a friendly, driven way...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/453' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/katharine_c.55474d44.png' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Katharine C.</h5>
                                <h5 className='text-center light-c'>Oxford | English/History</h5>

                                <div>
                                    Hey! I'm Katie. I'm currently a second year undergraduate, studying the BA in Jurisprudence (Law) at Magdalen College, Oxford. I did the IB at a school in the UK and got a 45. Before that, I was living in China, where I did my iGCSEs and got 1...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/429' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/mark_w.cfeb3797.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Cameron H.</h5>
                                <h5 className='text-center light-c'>Oxford & Imperial | Biochemistry</h5>

                                <div>
                                    Hi – thanks for checking out my profile. I’m Mark and I am currently a third year PhD student at Imperial College London, researching in a laboratory that focuses on finding new anti-malarial drugs and vaccines. I completed an undergraduate an...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/345' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }


    createThirdPart = () => {
        return(
            <div className="native-tutors-cons">
                <div className='native-tutors-cona'>
                    <ul>
                        <li>
                            <div>
                                <img src='/images/native_tutors/ally_k.2b44bafc.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Ally K.</h5>
                                <h5 className='text-center light-c'>Oxford | Chemistry</h5>

                                <div>
                                I am a second year Chemistry student at Oriel College, Oxford. I enjoy drama and sport in my spare time.
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/411' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/sophie_p.254200b3.png' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Sophie P.</h5>
                                <h5 className='text-center light-c'>Oxford | Biology</h5>

                                <div>
                                I've just graduated from Oxford with a first class degree in Biological Sciences. I'm taking a 'gap year' this year, but have moved to London in order to work and save some money for my Master's in Science Communication next year.
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/411' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/charlotte_c.4825cc20.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Charlotte C.</h5>
                                <h5 className='text-center light-c'>Imperial College London | Biology</h5>

                                <div>
                                I am a year 2 Biomedical Science student at Imperial College London. I have been studying in the UK since GCSEs, and I am originally from Hong Kong. I am fluent in Cantonese, Mandarin and English.
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/266' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }


    createForthPart = () => {
        return(
            <div className="native-tutors-cons">
                <div className='native-tutors-cona'>
                    <ul>
                        <li>
                            <div>
                                <img src='/images/native_tutors/cassie_s.8e316256.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Cassie S.</h5>
                                <h5 className='text-center light-c'>Oxford | Biology</h5>

                                <div>
                                I am a 3rd year Medicine student at Oriel College, University of Oxford. I achieved 3 A*s at A level in Biology (AQA), Chemistry (OCR) and Maths (Edexcel), an A* in EPQ and an A in English Literature at AS level. I also achieved 14 A*s at GCSE lev...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/493' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/guillermo_v.e91b1e21.jpeg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Guillermo V.</h5>
                                <h5 className='text-center light-c'>Cambridge | Economics</h5>

                                <div>
                                I am 21 years old and I graduated from Trinity College, Cambridge in 2018 with a degree in Economics. I enjoy sport, playing tennis for Cambridge, and of course being part Spanish, football is also a real passion of mine! I like helping others thr...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/494' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/victor_t.16a83fd0.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Victor T.</h5>
                                <h5 className='text-center light-c'>University of Chicago | Economics</h5>

                                <div>
                                My name is Victor: Thank you for taking the time to visit my profile! Several months ago, I graduated from The University of Chicago, where I completed my Bachelor of Arts in Economics, on a full Public Service Department scholarship from the gove...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/414' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    createFifthPart = () => {
        return(
            <div className="native-tutors-cons">
                <div className='native-tutors-cona'>
                    <ul>
                        <li>
                            <div>
                                <img src='/images/native_tutors/kiran_s.6244485f.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Kiran S.</h5>
                                <h5 className='text-center light-c'>London School of Economics and Political Science (LSE) | Economics</h5>

                                <div>
                                * London School of Economics and Political Science (LSE) (2016) - BSc Accounting and Finance (Bachelors) * Heston Community School (2013) - A-Levels (College) * Heston Community School (2011) - GCSEs (School) - Successfully completed A-Levels i...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/452' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/bilal_m.5e553380.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Bilal M.</h5>
                                <h5 className='text-center light-c'>New York University | Maths</h5>

                                <div>
                                * London School of Economics and Political Science (LSE) (2016) - BSc Accounting and Finance (Bachelors) * Heston Community School (2013) - A-Levels (College) * Heston Community School (2011) - GCSEs (School) - Successfully completed A-Levels i...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/481' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/max_f.5632f099.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Max F.</h5>
                                <h5 className='text-center light-c'>Kings | Maths</h5>

                                <div>
                                I am a second year student of Maths with Finance at Kings College, with several years of tutoring experience to various levels of age and ability. Having also extensive experience at public speaking and having worked at Deloitte as an Actuaria...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/53' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }

    createTenPart = () => {
        return(
            <div className="native-tutors-cons">
                <div className='native-tutors-cona'>
                    <ul>
                        <li>
                            <div>
                                <img src='/images/native_tutors/alexander_b.856df8b9.png' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Alexander B.</h5>
                                <h5 className='text-center light-c'>Oxford | Physics</h5>

                                <div>
                                I have recently achieved a high 2.1 in my degree in Physics at St Peters College Oxford, and am looking forward to tutoring IB, A-level, and iGCSE/GCSE students in science and maths, as well as providing tailored tutoring for the Oxbridge applicat...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/455' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src='/images/native_tutors/tom_s.d29072fd.jpg' alt='...' />
                            </div>
                            <div>
                                <h5 className='text-center'>Tom S.</h5>
                                <h5 className='text-center light-c'>Oxford | Physics</h5>

                                <div>
                                Tom is a 3rd year undergraduate, reading at Brasenose College, Oxford for a Masters (MChem) in Chemistry. Prior to university, he was a student at Winchester College, with exemplary examination record. He achieved straight A*s at IGCSE, A-Level, a...
                                </div>                                
                            </div>
                            <div>
                                <a href='http://www.gurume.co.uk/tutor_profiles/228' className='native-tutors-profile-btn' > 프로필 보기 </a>
                            </div>
                        </li>
                        
                    </ul>
                </div>
            </div>
        )
    }
    createHtml = () => {
        return(
            <div>
                {this.createNav()}
                {this.createFirstPart()}
                <div className='margin-top-100 margin-bottom-100'>
                    {this.createSecPart()}
                    {this.createThirdPart()}
                    {this.createForthPart()}
                    {this.createFifthPart()}
                    {this.createTenPart()}
                    <div className='paddingG'>
                        선생님 찾으시는데 어려움이 있다면 <span>그루미 희라쌤</span>께 연락주세요.
                    </div>
                </div>
            </div>
        )
    }

    render(){
        return(
            <div className="default-wrapper native_tutors-pannel-size" >
                {this.createHtml()}
            </div>
        )
    }

}

export default native_tutors;