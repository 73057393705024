//
// 2020 01 14
//
import React, { Component, Fragment } from 'react';
import { TrasHome, TrasContactUs, TrasPleaserefertoHowitworksandFAQregardingGuruMesiteusageForanyotherenquiriespleasefeelfreetocontactusthroughanyofthefollowing } from '../../Translate/EachTranslateC';
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from "react-naver-maps";
import KakaoIconBlue from '../../images/kakao_icon.png';
import './contact_us.css';

class contact_us extends Component {

    createNav = () => {
        return (
            <div className="gurume-nav-wrapper">
                <ol className="breadcrumb">
                    <li>
                        <a href="/#/"><TrasHome /></a>
                    </li>
                    <li>
                        <a><TrasContactUs /></a>
                    </li>
                </ol>
            </div>
        )
    }

    createBody = () => {
        return (
            <Fragment>
                <div className="conatct-us">
                    <div className="contact-us-box">
                        <h1><TrasContactUs /></h1>
                        <h4>
                            <TrasPleaserefertoHowitworksandFAQregardingGuruMesiteusageForanyotherenquiriespleasefeelfreetocontactusthroughanyofthefollowing /></h4>
                        <p>*수업 매니징해주시는 그루미 스태프분이 여러명이기 때문에 특정 수업 관련해서는 문의시 담당 스태프분의 이름을 말씀해주시길 바랍니다. 또한
                        이메일/카톡/전화 문의를 담당하시는 분이 다르기 때문에 이메일/카톡/전화 중 하나로만 연락주시면 감사하겠습니다.</p>
                        <br />
                        <p>
                            <b>상담시간</b>&nbsp;(월-금 10am-7pm)
                    </p>
                        <div class="contact-box-wrapper-in-page">
                            <div class="contact-box-in-page">
                                <div class="contact-box-content-in-page">
                                    <div class="contact-btn-in-page focus-on">
                                        <a href="tel:010-5723-7221">
                                            <i class="fa fa-phone" style={{ color: '#5b9aef', fontSize: '4rem' }}></i>
                                            <p>전화상담</p>
                                        </a>
                                    </div>
                                    <div class="contact-btn-in-page focus-on">
                                        <a href="http://pf.kakao.com/_ddvDV/chat" target="_blank">
                                            <img style={{ width: '50px', height: '48px' }} src={KakaoIconBlue} />
                                            <p>카카오상담톡</p>
                                        </a>
                                    </div>
                                </div>
                                <div class="contact-box-content-in-page">
                                    <div class="contact-btn-in-page">
                                        <i class="fa fa-wechat" style={{ color: '#5b9aef', fontSize: '4rem' }}></i>
                                        <p>gurumeconsulting</p>
                                    </div>
                                    <div class="contact-btn-in-page">
                                        <h4 style={{ color: '#5b9aef' }}>상담시간</h4>
                                        <p>10:00~19:00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br /><br />

                        <p>
                            <b>오시는 길</b>
                        </p>
                        <p className="contact-place">
                            <i>학원</i>
                        </p>
                        <p>주소:&nbsp;서울시 강남구 테헤란로25길 31 동림빌딩 5층 (속초 대게 건물)</p>
                        <p>네이버 주소:&nbsp;
                        <a href="http://naver.me/FxjzaAuv" target="blank">http://naver.me/FxjzaAuv</a>
                        </p>
                        <p>지하철 오는길:&nbsp;
                        <a href="https://youtu.be/-iZD-teG9Fs" target="blank">https://youtu.be/-iZD-teG9Fs</a>
                            (역삼역 4번 출구/언주역 7번 출구)
                    </p>
                        <p>주차장 리스트:&nbsp;
                        <a href="https://goo.gl/vES8mM" target="blank">https://goo.gl/vES8mM</a>
                        </p>
                        <p>*그루미 건물 주차장이 협소하여 대중교통 혹은 근처 유료 주차장을 이용해주시면 감사하겠습니다.*</p>
                        <RenderAfterNavermapsLoaded
                            ncpClientId={'jo4jjfpxt2'}
                            error={<p>Maps Load Error</p>}
                            loading={<p>Maps Loading...</p>}
                        >
                            <NaverMap
                                style={{
                                    width: '100%',
                                    height: '400px',
                                  }}
                                  defaultCenter={{lat: 37.5026038, lng: 127.0349669}}
                                  defaultZoom={17}
                            >
                                <Marker 
                                    position={{lat: 37.5026038, lng: 127.0349669}}
                                />
                            </NaverMap>
                        </RenderAfterNavermapsLoaded>
                        <br/>
                        <p className="contact-place">
                            <i>사무실</i>
                        </p>
                        <p>주소: 서울시 강남구 테헤란로25길 39 MK빌딩 5층 (GS25 건물)</p>
                        <p>네이버 주소:&nbsp;
                        <a href="http://naver.me/xPwaDVAV" target="blank">http://naver.me/xPwaDVAV</a>
                        </p>
                        <br /><br />
                    </div>
                </div>
            </Fragment>
        )
    }

    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                {this.createBody()}
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.createHtml()}
            </div>
        )
    }

}

export default contact_us;