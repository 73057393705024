import React, { Component } from 'react';

import './TdApplyComponent.css';

class TdApplyComponent extends Component{

    state = {
    }


    render(){
        return(
            <div className='td-common-wrapper'>
                최근 한 달동안 지원하신 수업이 없습니다. (베타 테스트 중)
            </div>
        )
    }

}

export default TdApplyComponent;