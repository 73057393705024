import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    TrasRecommend,
    TrasREVIEWS,
    TrasDemo,
} from '../../../Translate/EachTranslateC';


import './MainRecommend.css';

//test
const MainRecommendPropTypes = {
    normalData: PropTypes.array,
}
const MainRecommendDefaultProps = {
    normalData: []
}
const sStatusRecor = {
    counts: [],
}

class MainRecommend extends Component {


    state = {
        normalData: [
        ],
        sData: [],
        originData: [
            {
                profile: { tutor_profile: {}, curriculums: Array(1), major: Array(1), user_profile: {} },
                profileCheckerObj: {}
            }
        ]
    }

    componentDidMount = () => {
        this.makeRecors();
    }

    makeRecors = () => {
        const normalData = this.props.normalData;

        const sData = [];                           // CUSTOM EVENTS
        const sortData = [];
        for (let i = 0; i < normalData.length; i++) {
            const newRecord = {};

            const tutor_profile = normalData[i].profile.tutor_profile;
            const uPro = normalData[i].profile.user_profile;
            const fullName = uPro.last_name + uPro.first_name;
            const picture = tutor_profile.picture;
            const university_name = tutor_profile.undergraduate_school.university_name;
            const major = tutor_profile.major[0];
            const thumbnail_intro = tutor_profile.thumbnail_intro;
            const normalId = uPro.id;
            const hasDemo = tutor_profile.video_youtube_1;

            newRecord.fullName = fullName;
            newRecord.picture = picture;
            newRecord.major = major;
            newRecord.thumbnail_intro = thumbnail_intro;
            newRecord.university_name = university_name;
            newRecord.profileCheckerObj = normalData[i].profileCheckerObj;
            newRecord.normalId = normalId;
            newRecord.video_youtube_1 = hasDemo;

            newRecord.profileCheckerObj.viewable_review_count = tutor_profile.viewable_review_count;
            newRecord.profileCheckerObj.all_review_count = tutor_profile.all_review_count;

            sortData.push(newRecord);


            //CUSTOM EVENTS
            const curriculumsThumbnailStr = normalData[i].profileCheckerObj.curriculumsThumbnailStr;
            sData.push(curriculumsThumbnailStr.split('·'));
        }


        this.setState({
            ...this.state,
            normalData: sortData,
            originData: normalData,
            sData
        }, () => {

            this.RRRregisterEv();
        })

    }


    getRecorIndexNum = (_target) => {
        let target = _target;
        let xNum = target.dataset['indexNum'];
        let count = 0;
        while (!xNum) {
            target = target.parentNode;
            xNum = target.dataset['indexNum'];

            count++;
            if (count > 15) break;
        }
        return xNum;
    }

    RRRregisterEv = () => {
        const sRecor = document.getElementsByClassName('sRecor');
        const xsData = this.state.sData;

        if (sRecor.length !== xsData.length) return console.log("NOT MATCHING MOMENTS");

        for (let i = 0; i < sRecor.length; i++) {
            // SET INIT
            if (sStatusRecor.counts.length !== sRecor.length)
                sStatusRecor.counts[i] = 1;

            sRecor[i].children[0].addEventListener('animationstart', function (status) {
                const target = status.target;
                const xIndex = this.getRecorIndexNum(target);

            }.bind(this));
            sRecor[i].children[0].addEventListener('animationiteration', function (status) {
                const target = status.target;
                const xIndex = this.getRecorIndexNum(target);
                const xsData = this.state.sData[xIndex];

                if (xsData.length - 1 <= sStatusRecor.counts[xIndex]) {
                    sStatusRecor.counts[xIndex] = 0;
                }
                target.innerHTML = xsData[sStatusRecor.counts[xIndex]];
                sStatusRecor.counts[xIndex] = sStatusRecor.counts[xIndex] + 1;

            }.bind(this));
            sRecor[i].children[0].classList.add('sdesa');
        }
    }

    getIndexId = (e) => {
        let target = e.target;
        let xData = target.dataset['recommendIndex'];

        let count = 0;
        while (!xData) {

            target = target.parentNode;
            xData = target.dataset['recommendIndex'];

            count++;
            if (count > 10) break;
        }

        window.location.href = '/#/teachers/' + xData;

    }

    createHtml = () => {

        const li = [];
        const data = this.state.normalData;
        const xsData = this.state.sData;

        let checkVal = false;
        if (data.length === xsData.length) checkVal = true;

        for (let i = 0; i < data.length; i++) {
            let sTexts = data[i].thumbnail_intro;
            const eachMarkCheckers = data[i].profileCheckerObj;

            if (sTexts.length > 100) {
                sTexts = sTexts.substr(0, 100) + '...';
            }


            // UNDER BTNS IN STRUCTRURES
            // profileCheckerObj : curriculumsThumbnailStr, hasDemo, hasThumbnailIntro, hasViewableRecommendations, hasViewableReviews
            const { curriculumsThumbnailStr, hasDemo, hasThumbnailIntro, hasViewableRecommendations, hasViewableReviews
                , viewable_review_count, all_review_count } = eachMarkCheckers;
            const GurumeSpans = [];

            let sSpan = <div className='sRecor' key={`sSpand_${i}`}><span className='sSpand'>{curriculumsThumbnailStr}</span></div>;
            if (checkVal)
                sSpan = <div className='sRecor' key={`sSpand_${i}`}><span className='sSpand'>{xsData[i][0]}</span></div>;


            // console.log(all_review_count)
            if (!hasDemo) {
                GurumeSpans.push(
                    <span key='sSpand_A' className="margin-right-5">
                        <i className="fa fa-youtube-play font-size-18" style={{ color: "red" }}></i>&nbsp;
                        <TrasDemo />
                    </span>
                );
            }
            if (hasViewableRecommendations) {
                GurumeSpans.push(
                    <span key='sSpand_B' className="margin-right-5">
                        <i className="fa fa-thumbs-o-up font-size-18"></i>&nbsp;
                        {all_review_count}
                        <TrasRecommend />
                    </span>
                );
            }
            if (hasViewableReviews) {
                GurumeSpans.push(
                    <span key='sSpand_C' className="margin-right-5">
                        <i className="fa fa-comments-o font-size-18" style={{ color: "red" }}></i>&nbsp;
                        {viewable_review_count}
                        <TrasREVIEWS />
                    </span>
                );
            }


            li.push(
                <li key={i} className='liwpaer' onClick={this.getIndexId} data-index-num={i} data-recommend-index={data[i].normalId}>
                    <div>
                        <div className="recommend-teacher-img"><img src={data[i].picture} alt='...' /></div>
                        <div>
                            <ul>
                                <li>{data[i].fullName}</li>
                                <li>{data[i].university_name}</li>
                                <li>{data[i].major}</li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        {sSpan}
                    </div>
                    <div>
                        {sTexts}
                    </div>
                    <div>
                        {GurumeSpans}
                    </div>
                </li>
            );
        }


        return (
            <div className='main-recommend-inner-wapper'>
                <div className="main-recommend-contents">
                    {li}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='main-recommend-wrapper'>
                {this.createHtml()}
            </div>
        )
    }
}

MainRecommend.propTypes = MainRecommendPropTypes;
MainRecommend.defaultProps = MainRecommendDefaultProps;

export default MainRecommend;
