import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './TdListComponent.css';


const TdListComponentPropTypes = {
    ChangeCondition : PropTypes.func,
    CURRENT_STATE : PropTypes.object,
}
const TdListComponentDefaultProps = {
    ChangeCondition : ()=>{console.log("")},
    CURRENT_STATE : {
        TdClassComponent : 'TdClassComponent',
        TdBlogComponent : 'TdBlogComponent',
        TdMoocComponent : 'TdMoocComponent',
        TdGoodClassComponent : 'TdGoodClassComponent',
        TdApplyComponent : 'TdApplyComponent',
    }
}


class TdListComponent extends Component{

    state = {
        tdState : [
            {
                apperance : 'fa fa-users',
                listWord : '수업',
                componentName : 'TdClassComponent',
            },
            {
                apperance : 'fa fa-pencil',
                listWord : '블로그 포럼',
                componentName : 'TdBlogComponent',
            },
            {
                apperance : 'fa fa-book',
                listWord : 'MOOC',
                componentName : 'TdMoocComponent',
            },
            {
                apperance : 'fa fa-laptop',
                listWord : '클래스',
                componentName : 'TdGoodClassComponent',
                needNew : true,
            },
            {
                apperance : 'fas fa-clipboard',
                listWord : '수업신청',
                componentName : 'TdApplyComponent',
            },
        ],
    }


    ChangeCondition = (e) => {
        let target = e.target;
        let componentName = target.dataset['componentName'];
        
        let i = 0; 
        while(!componentName){
            componentName = target.dataset['componentName'];
            if(componentName) break;
            target = target.parentNode;

            i++;
            if(i > 5) break;
        }

        let herParent = target.parentNode;
        for(let i = 0; i < herParent.childElementCount; i++){
            herParent.children[i].classList.remove('active');
        }
        target.classList.add('active');

        this.props.ChangeCondition(componentName);
    }
    
    createTdListComponent = () => {
        const li = [];
        const tdState = this.state.tdState;
        for(let i = 0; i < tdState.length; i++){
            const tdName = tdState[i].apperance;
            const componentName = tdState[i].componentName;
            const needNew = tdState[i].needNew;
            let extraSpan = '';
            if(needNew){
                extraSpan = 
                <span>N</span>
            }
            let classNameActive = '';
            if(i === 0) classNameActive = 'active';
            li.push(
                <li key={'key_' +i} className={classNameActive} onClick={this.ChangeCondition} data-component-name={componentName}>
                    <div>
                        <span> <i className={tdName}></i> </span>
                        <span>{tdState[i].listWord}</span>
                        {extraSpan}
                    </div>
                </li>
            );
        }
        return (
            <div className='TdListComponent-innerWrapper'>
                <ul>
                    {li}
                </ul>
            </div>
        )
    }

    render(){
        return(
            <div className='TdListComponent-wrapper'>
                {this.createTdListComponent()}
            </div>
        )
    }

}

TdListComponent.propTypes = TdListComponentPropTypes;
TdListComponent.defaultProps = TdListComponentDefaultProps;

export default TdListComponent;