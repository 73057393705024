
import React, { Component } from 'react';
import {
    TrasHome,
    TrasHowitworks,
    TrasTuition
} from '../../../Translate/EachTranslateC';

import './how_it_works_tuition.css';

/* icons */
import icon_1 from '../../../images/how_it_works/how_to_use_1.png'
import icon_2 from '../../../images/how_it_works/how_to_use_2.png'
import icon_3 from '../../../images/how_it_works/how_to_use_3.png'
import icon_3_1 from '../../../images/how_it_works/how_to_use_3_1.png'
import icon_3_2 from '../../../images/how_it_works/how_to_use_3_2.png'
import icon_4 from '../../../images/how_it_works/how_to_use_4.png'
import icon_5 from '../../../images/how_it_works/how_to_use_5.png'
import icon_6 from '../../../images/how_it_works/how_to_use_6.png'
import icon_7 from '../../../images/how_it_works/how_to_use_7.png'
import dot from '../../../images/how_it_works/how_it_works_dot.png'


class how_it_works_tuition extends Component {

    state = {
        isLogin: false,

    }

    constructor() {
        super();
    }


    SummNav = () => {
        return (
            <ol className="breadcrumb">
                <li><a href="/#/"><TrasHome /></a></li>
                <li><TrasHowitworks /></li>
                <li><TrasTuition /></li>
            </ol>
        )
    }

    DoFocus = (_ele_id) => {
        const xEle = document.getElementById(_ele_id);
        if (xEle) {
            xEle.scrollIntoView();
        } else {
            console.log("CAN NOT FIND ID - how_it_works_tuition");
        }
    }

    part_1 = () => {
        return (
            <div className="section-wrapper">
                <div className="section-contents">
                    <div className="section-left">
                        <div className="section-img">
                            <img src={icon_1} />
                        </div>
                        <div className="section-line">
                            <img src={dot} />
                            <img src={dot} />
                        </div>
                    </div>
                    <div className="section-right">
                        <p>방법 1. 수업 신청 게시판 - 선생님 지원 - 쪽지/대화</p>
                        <p>방법 2. 선생님 프로필 - 수업 신청 - 쪽지/대화</p>
                    </div>
                </div>
            </div>
        )
    }
    part_2 = () => {
        return (
            <div className="section-wrapper">
                <div className="section-contents">
                    <div className="section-left">
                        <div className="section-img">
                            <img src={icon_2} />
                        </div>
                        <div className="section-line">
                            <img src={dot} />
                            <img src={dot} />
                        </div>
                    </div>
                    <div className="section-right">
                        <p>- 정규 수업 전, 선생님께 데모수업 요청이 가능하며 1.5시간 이상 진행되는 수업에 한 해, 30분은 무료로 진행되고, 학부모님께서는 나머지 시간에 대한 비용만 지불하시게 됩니다. 단, 데모수업은 선택사항으로 선생님께서 거절하실 수 있습니다.</p>
                        <p>- 원활한 데모 수업 진행을 위해 사전에 수업 내용을 확정합니다.</p>
                        <p>- 데모수업준비의 상세내용은 ⌜선생님마이페이지- 수업 비법/요령⌟에서 확인 가능합니다.</p>
                    </div>
                </div>
            </div>
        )
    }
    part_3 = () => {
        return (
            <div className="section-wrapper">
                <div className="section-contents">
                    <div className="section-left">
                        <div className="section-img">
                            <img src={icon_3} />
                        </div>
                        <div className="section-line">
                            <img src={dot} />
                            <img src={dot} />
                            <img src={dot} />
                            <img src={dot} />
                            <img src={dot} />
                            <img src={dot} />
                        </div>
                    </div>
                    <div className="section-right">
                        <div className="section-right-sub">
                            <p><img src={icon_3_1} />&nbsp;1:1 수업료</p>
                            <div className="section-table">
                                <p className="color-gurume-blue">( 그루미 수업료/시급 가이드 라인 )</p>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>대학생 (1-2학년)</th>
                                            <th>대학생 (3-4학년)</th>
                                            <th>졸업생</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>2년 미만</th>
                                            <td>2만 +</td>
                                            <td>3만 ±</td>
                                            <td>4만 ±</td>
                                        </tr>
                                        <tr>
                                            <th>2년 이상</th>
                                            <td>4.5만 ±</td>
                                            <td>5만 ±</td>
                                            <td>6만 ±</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>- 수업료는 선생님의 경력 및 수업 방식 (온/오프라인)에 따라 변동 될 수 있으며, 정확한 수업료는 선생님과 합의 하에 조정 가능합니다.</p>
                                <p>- 첨삭수업료는 횟수 + 시간을 모두 고려하여 선생님과 합의하에 책정됩니다.</p>
                            </div>

                            <p className="margin-top-10"><img src={icon_3_2} />&nbsp;그룹 수업료</p>
                            <p className="section-table">
                                - 1:1 수업료에서 인당 1만원 추가 된 비용의 n분의 1로 측정 할 수 있고, 수업 주제 및 방식에 따라 변동될 수 있으므로 정확한 수업료는 선생님과 합의 하에 조정합니다.
                                <br />
                                예) 1:1 수업료 5만원 -> 1:2 수업료 3만원/인 (총 6만원)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    part_4 = () => {
        return (
            <div className="section-wrapper">
                <div className="section-contents">
                    <div className="section-left">
                        <div className="section-img">
                            <img src={icon_4} />
                        </div>
                        <div className="section-line">
                            <img src={dot} />
                            <img src={dot} />
                        </div>
                    </div>
                    <div className="section-right">
                        <p>- 그루미에서는 <span className="color-gurume-blue">‘안심결제서비스’</span> 제도 시행으로 모든 수업의 수업료는 그루미 계좌로 선입금 된 후, 수업이 완료되면 선생님께 강사료가 지급됩니다.</p>
                        <p>- 수업 시작 전, 선생님께서 인보이스를 발행하고 해당 인보이스 링크를 학생/학부모님께 발송합니다.</p>
                        <p>- 데모/일회성 수업을 제외한 모든 수업은 한 달 단위로 발행합니다.</p>
                        <p>- 수업 전까지 수업료가 입금되지 않을 경우, 약속된 수업이 무통보 자동 취소될 수 있습니다.</p>
                    </div>
                </div>
            </div>
        )
    }
    part_5 = () => {
        return (
            <div className="section-wrapper">
                <div className="section-contents">
                    <div className="section-left">
                        <div className="section-img">
                            <img src={icon_5} />
                        </div>
                        <div className="section-line">
                            <img src={dot} />
                            <img src={dot} />
                        </div>
                    </div>
                    <div className="section-right">
                        <p>- 수업은 온라인 ZOOM 또는 오프라인 (자택, 스터디룸)으로 상의하여 진행합니다.</p>
                        <p>- 수업 진행 전 교재 및 기출 문제 등의 수업 자료를 상의 후 준비합니다.</p>
                        <p>- 선생님께서 수업이 완료되면 수업 보고서를 작성하여 학생/학부모님께 전달합니다.</p>
                    </div>
                </div>
            </div>
        )
    }
    part_6 = () => {
        return (
            <div className="section-wrapper">
                <div className="section-contents">
                    <div className="section-left">
                        <div className="section-img">
                            <img src={icon_6} />
                        </div>
                        <div className="section-line">
                            <img src={dot} />
                            <img src={dot} />
                            <img src={dot} />
                        </div>
                    </div>
                    <div className="section-right">
                        <p>- 수업 시간 미엄수 / 시간 재 조율 / 취소 / 결석이 빈번하지 않을 경우 학생과 선생님 합의 하에 계속 진행하실 수 있지만 필요시 그루미에서 조치를 취할 수 있습니다.</p>
                        <div className="section-table">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>학생</th>
                                        <th>선생님</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>지각</th>
                                        <td>늦은 시간만큼<br />수업시간으로 간주</td>
                                        <td>본 수업 또는 다음 수업 중<br />늦은 시간만큼 무료로 진행</td>
                                    </tr>
                                    <tr>
                                        <th><span className="color-red">*</span>당일 취소 및 결석<br />(무통보)</th>
                                        <td>수업 이행으로 간주</td>
                                        <td>다음 수업 무료로 진행</td>
                                    </tr>
                                    <tr>
                                        <th>연락두절<br />(3일이상)</th>
                                        <td>해당사항 없음<br />(단, 선생님께서 수업을 포기 할 수 있음)</td>
                                        <td>부재 일자에 따라 다름</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p className="table-imp-text">*당일 취소 및 결석 규정은 학생-선생님과 상의 하에 재조정 할 수 있습니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    part_7 = () => {
        return (
            <div className="section-wrapper">
                <div className="section-contents">
                    <div className="section-left">
                        <div className="section-img">
                            <img src={icon_7} />
                        </div>
                    </div>
                    <div className="section-right">
                        <p>개인과외교습을 계속할 수 없는 경우, 회사의 환불정책 (「학원의 설립 운영 및 과외교습에 관한 법률 시행령」) 제 18조 제 2항, 제 3항 및 별표 4 근거)에 따라 교습비를 환불합니다.</p>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='how-it-works-wapper'>
                {this.SummNav()}
                <div className="how-it-works-section">
                    {this.part_1()}
                    {this.part_2()}
                    {this.part_3()}
                    {this.part_4()}
                    {this.part_5()}
                    {this.part_6()}
                    {this.part_7()}
                    <p className="how-it-works-footer">그루미는 서비스 내에서 회원들 간에 발생하는 분쟁 및 회원 스스로의 과실로 일어난 피해에 대해 관여하거나 이에 대하여 일체의 책임을 부담하지 않습니다. 다만 회원이 서비스 이용 중 다른 회원의 관련 법규, 회사 규정 위반으로 인하여 피해를 입은 경우, 회원은 이를 회사에 제보할 수 있으며 그 경우 회사는 신고내용을 확인하고 그에 따른 조치를 취할 수 있습니다.</p>
                </div>
            </div>
        )
    }

}

export default how_it_works_tuition;