import React, { Component } from 'react';
import { NavDropdown, Nav } from 'react-bootstrap'

import './Header.css';
import GurumeLogo from '../../images/logo_ko.jpg';

export default class Header extends Component {
    render() {
        return (
            <div class="nav-wrapper hidden-xs">
                <div class="nav-box">
                    <div class="nav-logo">
                        <a class='navbar-brand' href="/#/"> <img src={GurumeLogo} style={{ width: '150px' }} /></a>
                    </div>
                    <div class="nav-menu">
                        <div class="nav-menu-list">
                            {/* <div class="nav-menu-item">
                                <NavDropdown title="그루미 소개">
                                    <NavDropdown.Item href="/#/about_gurume">회사 소개</NavDropdown.Item>
                                    <NavDropdown.Item href="/#/studyroom">스터디룸</NavDropdown.Item>
                                    <NavDropdown.Item href="/#/contact_us">연락처</NavDropdown.Item>
                                </NavDropdown>
                            </div> */}
                            <div class="nav-menu-item">
                                <Nav.Link href="/#/mooc">인강</Nav.Link>
                            </div>
                            <div class="nav-menu-item">
                                <Nav.Link href="/#/blogs">블로그</Nav.Link>
                            </div>
                            {/* <div class="nav-menu-item">
                                <NavDropdown title="입시">
                                    <NavDropdown.Item href="/#/admissions_consulting">입시 컨설팅</NavDropdown.Item>
                                    <NavDropdown.Item href="http://www.peopleloving.co.kr/gcse/recommendationa.html" target="blank">영국 보딩</NavDropdown.Item>
                                </NavDropdown>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}