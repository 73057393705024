import React, { Component } from 'react';
import {
    TrasHome,
    TrasEvent,
    TrasForparticipationenquirypleaseemailsupportgurumekr
} from '../../Translate/EachTranslateC';

import ReactPlayer from 'react-player';
import './events.css';

class events extends Component {

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><a href="/#/"><TrasEvent /></a></li>
                </ol>
            </div>
        )
    }

    createFirstPart = () => {
        return (
            <div className="homepage-event">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6" style={{ marginTop: "20px" }}>
                            <h1>네트워킹 이벤트</h1>
                            <h4>설명회 NO!</h4>
                            <h4><span style={{ color: "#5b9aef" }}>학생들이 참여</span>하여 재학생, 졸업생 이야기를 듣고 <span
                                style={{ color: "#5b9aef" }}>자유롭게 교류</span>
                            </h4>
                        </div>
                        <div className="col-sm-6">
                            <a data-toggle="modal" data-target="#myModal0">
                                <img src="images/index_event_frame.jpg"
                                    style={{ maxWidth: "100%" }} /></a>
                            <div className="modal fade" id="myModal0" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" id="myModalLabel">네트워킹 이벤트 예시</h4>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                                aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="videowrapper">
                                                        <div className="vwrapper" style={{ position: "relative" }}>
                                                            <ReactPlayer
                                                                className='good-player'
                                                                url={'https://www.youtube.com/embed/OxKo6nbbu64?ecver=1'}
                                                                width='100%'
                                                                height='100%'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="videowrapper">
                                                        <div className="vwrapper" style={{ position: "relative" }}>
                                                            <ReactPlayer
                                                                className='good-player'
                                                                url={'https://www.youtube.com/embed/t0Mzgs7tKNQ?ecver=1'}
                                                                width='100%'
                                                                height='100%'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createSecondPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal23">
                    <div className="card_thumbnail art cursor">
                        <div className="event14">
                            <h3 className="event_thumbnail_text">&nbsp;</h3>
                        </div>
                        <p>위치: 항공우주호텔 (세미나룸)</p>
                        <p>날짜: 10월 16일 (화) 오전 10시 반 - 오후 1시</p>
                        <p>스피커: <br />
                            방원준 선생님 - IB 수학 전문<br />
                            안재현 선생님 - IB 영어 전문
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal23" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"제주 IB 학업/그루미 학원/영국 대입 설명회"</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <img className="img-responsive margin-bottom-40"
                                    src="/images/2018_10_16_jeju_ib_seminar.jpg" />

                                <h4 className="blue-font-color">주제/차례</h4>
                                <p>10:30~11:30 NLCS/Branksome 학교 IB 과정 x 그루미 <a href="http://www.gurume.kr/academy">IB
                                    학원</a> 수업</p>
                                <p>11:30~12:00 옥스브리지/의대 대입 준비 과정 및 필요한 스펙</p>
                                <p>12:00~12:30 저학년 동기부여 멘토링</p>
                                <p>12:30~13:00 개별상담</p>
                                <br />

                                <h4 className="blue-font-color">장소</h4>
                                <p>항공우주호텔 (세미나룸)</p>
                                <p>오시는 길 <a href="http://naver.me/GseTCZ2T" target="blank">naver.me/GseTCZ2T</a></p>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <a href="/#/teachers/14" target="blank"><p>이혁진 선생님 - Oxford 생화학, 영국에서 IB 이수</p></a>
                                <a href="/#/teachers/1187" target="blank"><p>방원준 선생님 - Warwick 수학과, 독일에서 IB 이수</p></a>
                                <a href="/#/teachers/1567" target="blank"><p>안재현 선생님 - Yonsei 영문학, 캐나다에서 IB 이수</p></a>
                                <br />

                                <h4 className="blue-font-color">참여 문의/신청</h4>
                                <p>참여 신청서 <a href="https://goo.gl/forms/VLOgFYSkAFfP9xYw1" target="blank">https://goo.gl/forms/VLOgFYSkAFfP9xYw1</a><br />
                                    카톡 ilovegurume / 010 6811 7221
                                </p>
                                <p>*참여 신청서를 작성해야 신청 등록이 완료됩니다. 예약한 분들 중 선착순 5분께 그루미 출간 도서 "영국 대입에 대한 모든 것" 그리고 “IB과정에 대한
                                    모든 것”을 드립니다.</p>
                                <div className="row text-center" style={{ padding: "20px 0px 50px 0px" }}>
                                    <div className="col-sm-6">
                                        <img src="images/academy/IB Book Prologue Cover.png" style={{ maxWidth: "100%" }} />
                                    </div>
                                    <div className="col-sm-6">
                                        <img src="images/academy/IB Book Prologue (Table of Contents).png"
                                            style={{ maxWidth: "100%" }} />
                                    </div>
                                </div>
                                <div className="row text-center" style={{ padding: "20px 0px 50px 0px" }}>
                                    <div className="col-sm-6">
                                        <img className="img-responsive"
                                            src="/images/temporary/All About UK Admissions Front Cover (Ko) 3D.jpg" />
                                    </div>
                                    <div className="col-sm-6">
                                        <br />
                                        <h3>저자</h3>
                                        <p><i>"영국 대입을 생각하고 있는 학생들에게 도움이 되고자 지난 수년간의 경험, 노하우를 바탕으로 이 책을 집필하게 되었습니다."</i>
                                        </p>
                                        <br />
                                        <p><b>이혁진</b>, <i>B.Sc. M.Sc. Oxford University, 생화학</i></p>
                                        <p>그루미 대표, 영국 유학 컨설턴트</p>
                                        <br />

                                        <p><b>키안 양 (Kian Yang)</b>, <i>B.A. Oxford University, 법학</i></p>
                                        <p>법대 입시 컨설턴트, Practising US Attorney</p>
                                    </div>
                                </div>
                                <h4 className="blue-font-color">이전 이벤트 영상</h4>
                                <div className="">
                                    <div className="">
                                        <div className="videowrapper">
                                            https://www.youtube.com/embed/LLd0Z2xsy5o?modestbranding=1
                                            <ReactPlayer
                                                className='good-player'
                                                url={'https://www.youtube.com/embed/OxKo6nbbu64?modestbranding=1'}
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createThirdPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal22">
                    <div className="card_thumbnail art cursor">

                        <div className="event13">
                            <h3 className="event_thumbnail_text">&nbsp;</h3>
                        </div>
                        <p>위치: 더샵센트럴파크 1차 연회실</p>
                        <p>날짜: 9월 18일 (화) 오전 10시 30분 - 12시 30분</p>
                        <p>스피커: <br />
                            방원준 선생님 - IB 수학 전문<br />
                            이혁진 선생님 - IB 화학 전문<br />
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal22" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"IB 학업/ 그루미 학원 설명회"</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h4 className="blue-font-color">주제/차례</h4>
                                <p>10:30~11:00 <a target="_blank" href="http://ibthetop.com">그루미 IB 학원</a> 설명</p>
                                <p>11:00~12:00 채드윅 학교 과정 x 그루미 수업 (수학, 화학, 영어) 설명</p>
                                <p>12:00~12:30 질의응답/개별상담</p>
                                <br />

                                <h4 className="blue-font-color">장소</h4>
                                <p>더샵센트럴파크 1차 101동 연회실</p>
                                <p>오시는 길 <a href="http://naver.me/5Ix4u2VV" target="blank">naver.me/5Ix4u2VV</a></p>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <a href="/#/teachers/1187" target="blank"><p>방원준 선생님 - Warwick 수학과, 독일에서 IB 이수</p></a>
                                <a href="/#/teachers/1567" target="blank"><p>안재현 선생님 - Yonsei 영문학, 캐나다에서 IB 이수</p></a>
                                <a href="/#/teachers/14" target="blank"><p>이혁진 선생님 - Oxford 생화학, 영국에서 IB 이수</p></a>
                                <br />

                                <h4 className="blue-font-color">참여 문의/신청</h4>
                                <p>참여 신청서 <a href="https://goo.gl/forms/TVINYuVTFTGHwaJu1" target="blank">goo.gl/forms/TVINYuVTFTGHwaJu1</a><br />
                                    카톡 ilovegurume/ 010 6811 7221
                                </p>
                                <p>*참여 신청서를 작성해야 신청 등록이 완료됩니다. 예약한 분들 중 선착순 5분께 그루미 출간 도서 “IB과정에 대한 모든 것”을 드립니다.</p>
                                <div className="row text-center" style={{ padding: "20px 0px 50px 0px" }}>
                                    <div className="col-sm-6">
                                        <img src="images/academy/IB Book Prologue Cover.png" style={{ maxWidth: "100%" }} />
                                    </div>
                                    <div className="col-sm-6">
                                        <img src="images/academy/IB Book Prologue (Table of Contents).png"
                                            style={{ maxWidth: "100%" }} />
                                    </div>
                                </div>
                                <h4 className="blue-font-color">이전 이벤트 영상</h4>
                                <div className="">
                                    <div className="">
                                        <div className="videowrapper">
                                            https://www.youtube.com/embed/LLd0Z2xsy5o?modestbranding=1
                                            <ReactPlayer
                                                className='good-player'
                                                url={'https://www.youtube.com/embed/OxKo6nbbu64?modestbranding=1'}
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    createForthPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal21">
                    <div className="card_thumbnail art cursor">

                        <div className="event12">
                            <h3 className="event_thumbnail_text">&nbsp;</h3>
                        </div>
                        <p>위치: 토즈 상암점</p>
                        <p>날짜: 9월 12일 (수) 오후 1시-3시</p>
                        <p>스피커: <br />
                            방원준 선생님 - IB 수학 전문<br />
                            이가영 선생님 - 드와이트 졸업생/UCL 역사학<br />
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal21" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"그루미 정기 이벤트_수학과 학업/커리어 경험담"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h4 className="blue-font-color">주제</h4>
                                <p>1. 국내/드와이트 IB 학업</p>
                                <p>2. 영.미권 대학 입시 전망</p>
                                <br />

                                <h4 className="blue-font-color">장소</h4>
                                <p>토즈 상암점 - 디지털미디어시티역 8번 출구 근처</p>
                                <p>오시는 길 <a href="http://naver.me/xJ4itw13" target="blank">http://naver.me/xJ4itw13</a>
                                </p>
                                <p>*주차 - 1시간 무료이며 10분 당 500원씩 요금이 추가 됩니다<br />
                                </p>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <p>방원준 선생님 - Warwick 수학과, IB @독일국제고등학교
                                    <a href="/#/teachers/1187" target="blank">www.gurume.kr/teachers/1187</a></p>
                                <p>이가영 선생님 - UCL 역사학, IB @드와이트
                                    <a href="/#/teachers/1473" target="blank">www.gurume.kr/teachers/1473</a></p>
                                <br />

                                <h4 className="blue-font-color">차례</h4>
                                <p>13:00~13:10 인사말씀</p>
                                <p>13:10~13:30 방원준 개인 발표</p>
                                <p>13:30~13:50 이가영 개인 발표</p>
                                <p>13:50~14:10 이혁진 개인 발표</p>
                                <p>14:00~14:20 <a href="http://www.gurume.kr/academy">그루미 IB 학습</a>/대입 컨설팅 과정 소개</p>
                                <p>14:20~14:50 그룹 Q&A</p>
                                <p>*선착순으로 학부모님 5분께 그루미 출간 도서 "IB 과정에 대한 모든 것"을 증정합니다.</p>

                                <br />

                                <h4 className="blue-font-color">참여 문의/신청</h4>
                                <p>참여 신청서 <a href="https://goo.gl/forms/YRq8uRvZoHNSFtks2" target="blank">https://goo.gl/forms/YRq8uRvZoHNSFtks2</a><br />
                                    카톡 ilovegurume/ 010 6811 7221
                                </p>
                                <p>*적절한 인원으로 양질의 설명회를 제공하기 예약 신청을 받고 있습니다. 참여 신청서를 제출하여야 신청 등록이 완료됩니다.</p>
                                <br />
                                <h4 className="blue-font-color">이전 이벤트 영상</h4>
                                <div className="">
                                    <div className="">
                                        <div className="videowrapper">
                                            <ReactPlayer
                                                className='good-player'
                                                url={'https://www.youtube.com/embed/OxKo6nbbu64'}
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="videowrapper">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/3rf08MGCaUI?ecver=1"
                                            frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                                    </div>
                                </div>

                                에세이 라이팅 수업
                                <div>
                                    <div className="videowrapper">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/WvZt7xxJoP8?ecver=1"
                                            frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                                    </div>
                                </div>

                                IB 영어 수업
                                <div>
                                    <div className="videowrapper">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/Fq4Rc_NKDao?ecver=1"
                                            frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                                    </div>
                                </div>

                                그루미 소개
                                <div>
                                    <div className="videowrapper">
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/oze3JxS191g?ecver=1"
                                            frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createFifthPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal21">
                    <div className="card_thumbnail art cursor">
                        <div className="event11">
                            <h3 className="event_thumbnail_text">&nbsp;</h3>
                        </div>
                        <p>위치: 그루미 사무실</p>
                        <p>날짜: 9월 1일 17:00-19:00</p>
                        <p>스피커: <br />
                            김기범 선생님 - 런던대학교(UCL)<br /><br />
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal21" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"그루미 정기 이벤트_수학과 학업/커리어 경험담"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><i>"설명회를 넘어선 분야 별 경험자이자 전문가와 이야기를 할 수 있는 주기적 모임"</i></p>
                                <br />

                                <h4 className="blue-font-color">주제</h4>
                                <p>1. UCL 수학/물리 전공 학업 경험담</p>
                                <p>2. 금융 산업(계리사/Actuary) 커리어 준비/경험담</p>
                                <br />

                                <h4 className="blue-font-color">장소</h4>
                                <p>그루미 사무실</p>
                                <p>오시는 길 <a href="http://naver.me/xRM3kVp4" target="blank">http://naver.me/xRM3kVp4</a>
                                </p>
                                <p>주차 공간이 협소하니 되도록 대중교통을 이용해주시기 바랍니다.<br />
                                </p>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <p>김기범 선생님 - 런던대학교(University College London) 학사 수학 물리학과, A-Level 이수 @ 뉴질랜드
                                    <a href="/#/teachers/1227" target="blank">http://www.gurume.kr/teachers/1227</a></p>
                                <br />

                                <h4 className="blue-font-color">차례</h4>
                                <p>17:00~17:15 UCL 수학/물리과 학업 경험담</p>
                                <p>17:15~17:30 계리사 커리어 준비/경험담</p>
                                <p>17:30~17:40 쉬는 시간</p>
                                <p>17:40~19:00 질의 응답</p>
                                <br />

                                <h4 className="blue-font-color">참여 문의/신청</h4>
                                <p>참여 신청서 <a href="https://goo.gl/forms/ewrwTHdxCcoFiHSj1"
                                    target="blank">https://goo.gl/forms/ewrwTHdxCcoFiHSj1</a><br />
                                    카톡 ilovegurume/ +82 10 6811 7221
                                </p>
                                <p>
                                    *예약필수<br />
                                </p>
                                <br />
                                <h4 className="blue-font-color">이벤트 영상</h4>
                                <div className="row">
                                    <div className="col-sm-">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/OxKo6nbbu64" frameBorder="0"
                                                allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createSixthPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal21">
                    <div className="card_thumbnail art cursor">

                        <div className="event10">
                            <h3 className="event_thumbnail_text">&nbsp;</h3>
                        </div>
                        <p>위치: 그루미 사무실</p>
                        <p>날짜: 8월 22일 11:00-13:00</p>
                        <p>스피커: <br />
                            이상진 - 캠브리지 석.박사 생물학<br /><br />
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal21" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"그루미 정기 이벤트_IB 생물 학업/커리어"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><i>"설명회를 넘어선 분야 별 경험자이자 전문가와 이야기를 할 수 있는 주기적 모임 "</i></p>
                                <br />

                                <h4 className="blue-font-color">주제</h4>
                                <p>1. IB 생물 학업 노하우</p>
                                <p>2. 생물학 관련 커리어 경험담(바이오 테크놀리지, 유전체 분야)</p>
                                <br />

                                <h4 className="blue-font-color">장소</h4>
                                <p>그루미 사무실</p>
                                <p>오시는 길 <a href="http://naver.me/xRM3kVp4" target="blank">http://naver.me/xRM3kVp4</a>
                                </p>
                                <p>*주차 공간이 협소하니 되도록 대중교통을 이용해주시기 바랍니다.</p>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <p>이상진 선생님 - 캠브리지 석.박사 생물학, IB 이수 @싱가폴
                                    <a href="/#/teachers/2083" target="blank">http://www.gurume.kr/teachers/2083</a></p>
                                <br />

                                <h4 className="blue-font-color">차례</h4>
                                <p>11:00~11:15 IB 생물 학업 노하우</p>
                                <p>11:00~11:30 생물학 커리어 경험담(바이오 테크놀리지, 유전체 분야)</p>
                                <p>11:30~11:40 쉬는 시간</p>
                                <p>11:40~13:00 질의 응답</p>
                                <br />

                                <h4 className="blue-font-color">참여 문의/신청</h4>
                                <p>참여 신청서 <a href="https://goo.gl/forms/FcGue28fv87tlLXq2"
                                    target="blank">https://goo.gl/forms/FcGue28fv87tlLXq2</a></p>
                                <p>
                                    카톡 ilovegurume/ +82 10 6811 7221<br />
                                    *예약필요</p>
                                <br />


                                <h4 className="blue-font-color">이벤트 영상</h4>
                                <div className="row">
                                    <div className="col-sm-">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/OxKo6nbbu64" frameBorder="0"
                                                allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createSeventhPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal21">
                    <div className="card_thumbnail art cursor">

                        <div className="event8">
                            <h3 className="event_thumbnail_text">IB IA, EE 집중설명 및 해설</h3>
                        </div>
                        <p>위치: 송도</p>
                        <p>날짜: 11월 22일(수) 저녁</p>
                        <p>스피커: <br />
                            방원준 - 워릭 수학<br />
                            정이지 - 콜럼비아 화학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal21" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"채드윅 IB 내신 및 입시 설명회: 수학 화학 IA/EE 집중설명 및
                                        해설"</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>

                            </div>
                            <div className="modal-body">

                                <p><i>"친근한 선생님이자 유학선배가 들려주는 경험 조언"</i></p>
                                <br />

                                <h4 className="blue-font-color">주제</h4>
                                <p>1. 수학 및 화학 IA/EE 주제선정 도움 및 예시</p>
                                <p>2. IB 이수 하며 입시준비</p>
                                <p>3. IB 공부하면서 도움되는 팁</p>
                                <br />

                                <h4 className="blue-font-color">장소</h4>
                                <p>더 샵 - 센트럴파크 2차 202동 1층 연회장</p>
                                <p>오시는 길 <a href="http://naver.me/FT1QVYQr" target="blank">http://naver.me/FT1QVYQr</a>
                                </p>
                                <p>*당일 주차*<br />
                                    1. 아파트 방문자 출입구로 들어오신 후 경비실 호출<br />
                                    2. 금일 연회장 이용객이라 말씀하신 후 상가 주차장에 파킹<br />
                                    3. 1층으로 올라와 202동 1층 컨시어지에게 연회장 왔다고 말하고 입장</p>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <p>방원준 선생님 - Warwick 수학과, 독일국제고등학교 IB
                                    <a href="/#/teachers/1187" target="blank">www.gurume.kr/teachers/1187</a></p>
                                <p>정이지 선생님 - Columbia 화학과, 퀸즐랜드 주립 과학고 IB
                                    <a href="/#/teachers/144" target="blank">www.gurume.kr/teachers/144</a></p>
                                <br />

                                <h4 className="blue-font-color">차례</h4>
                                <p>18:00 ~ 18:10 인사말씀</p>
                                <p>18:10 ~ 18:30 방원준 개인 발표</p>
                                <p>18:30 ~ 18:50 정이지 개인 발표</p>
                                <p>18:50 ~ 19:40 관심있는 선생님(들)과 그룹 Q&A 세션</p>
                                <p>19:40 ~ 21:00 관심있는 선생님과 1:1 상담 (따로 신청 필요)</p>
                                <br />

                                <h4 className="blue-font-color">참여신청</h4>
                                <p>참여 신청서 <a href="https://goo.gl/forms/apQMA5bwrgGUIhxQ2"
                                    target="blank">goo.gl/forms/apQMA5bwrgGUIhxQ2</a></p>
                                <p>
                                    *예약필수: IB DP1 학생+학부모님 10분 한정<br />
                                    *장소가 맞지 않아 오프라인 참여가 불가피한 경우 온라인(스카입)으로 참여 가능하니 온라인 참여가 필요하신 분은 그루미에게 연락주세요</p>
                                <br />

                                <h4 className="blue-font-color">참가비</h4>
                                <p>“이벤트 주최하는데 비용이 들어 어쩔 수 없이 2만원의 참가비를 받기로 했습니다. 값어치 하는 내용 전해드릴 자신 있습니다.”</p>
                                <br />


                                <h4 className="blue-font-color">발표 요약 내용</h4>
                                <p><i>문과생을 위한 HL/SL 과목 선택</i></p>
                                <p>Geography는 쉬워보이지만 과제 비중이 많은 만큼 데드라인이 빈번하여 학생들이 힘들어...</p>
                                <p>Psychology는 생물과 연관된 부분이 생각보다...</p>
                                <p>Language B란 외국어 수준/ Language A란 모국어 수준...</p><br />

                                <p><i>채드윅 수학 선생님별 성향</i></p>
                                <p>
                                    {/* <상세내용은 다음 이벤트에서...> */}
                                </p>
                                <br />

                                <p><i>문과생으로서의 possible 과외 활동</i></p>
                                <p>
                                    {/* <상세내용은 다음 이벤트에서...> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }


    createLFirstPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal20">
                    <div className="card_thumbnail art">

                        <div className="event8">
                            <h3 className="event_thumbnail_text">IB IA/EE 주제 정하는 법</h3>
                        </div>
                        <p>위치: 그루미 역삼역 사무실</p>
                        <p>날짜: 11월 3일(금) 저녁</p>
                        <p>스피커: <br />
                            방원준 - 워릭 수학<br />
                            정이지 - 콜럼비아 화학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal20" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"IB IA/EE 주제 정하는 법"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <p><i>"엄마가 전해주는 말보다 롤모델 형, 누나 말을 잘 듣습니다. 아이들 시험기간이어도, 학원 수업 한 타임 빠지더라도 동참하세요."</i></p>
                                <br />

                                <h4 className="blue-font-color">주제</h4>
                                <p>IA/EE 주제 정할 때> 학생들이 어려워하는 점/ 주의할 점/ 점수를 잃는 부분</p>
                                <br />

                                <h4 className="blue-font-color">장소</h4>
                                <p>그루미 사무실 - 역삼역 4번 출구 근처</p>
                                <p>오시는 길 <a href="http://www.gurume.kr/contact_us" target="blank">www.gurume.kr/contact_us</a>
                                </p>
                                <p>*주차 관련*<br />
                                    명신빌딩 주차장 (그루미 사무실 건물) 위 링크에서 “주차장” 버튼을 누르신 후 사진 약도를 참고해주세요.</p>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <p>방원준 선생님 - Warwick 수학과, 독일국제고등학교 IB
                                    <a href="/#/teachers/1187" target="blank">www.gurume.kr/teachers/1187</a></p>
                                <p>정이지 선생님 - Columbia 화학과, 퀸즐랜드 주립 과학고 IB
                                    <a href="/#/teachers/144" target="blank">www.gurume.kr/teachers/144</a></p>
                                <br />

                                <h4 className="blue-font-color">차례</h4>
                                <p>19:00 - 19:10 인사말씀</p>
                                <p>19:10 - 19:25 방원준 선생님 발표</p>
                                <p>19:25 - 19:40 정이지 선생님 발표</p>
                                <p>19:40 - 19:55 TBC 발표</p>
                                <p>20:00 - 20:45 선생님(들)과 그룹 Q&A</p>
                                <br />

                                <h4 className="blue-font-color">참여신청</h4>
                                <p>참여 신청서 <a href="http://goo.gl/forms/XvwnX6xDEwYlUyS13"
                                    target="blank">goo.gl/forms/XvwnX6xDEwYlUyS13</a></p>
                                <p>
                                    *예약필수: IB DP1 학생 10분 한정<br />
                                    *장소가 맞지 않아 오프라인 참여가 불가피한 경우 온라인(스카입)으로 참여 가능하니 온라인 참여가 필요하신 분은 그루미에게 연락주세요</p>
                                <br />

                                <h4 className="blue-font-color">참가비</h4>
                                <p>“이벤트 주최하는데 비용이 들어 어쩔 수 없이 2만원의 참가비를 받기로 했습니다. 값어치 하는 내용 전해드릴 자신 있습니다.”</p>
                                <br />

                                <h4 className="blue-font-color">이벤트 영상</h4>
                                <div className="row">
                                    <div className="col-sm-">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/H22eYYuJEpc"
                                                frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/WozVQGsszwM"
                                                frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/7_3iwiI6-1s"
                                                frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <h4 className="blue-font-color">발표 요약 내용</h4>
                                <p><i>수학</i></p>
                                <p>1. Divergent & Convergent 주제 정하는 방법</p>
                                <p>2. IA 주제는 교과서/ Option 단원/ 유투브 및 블로그에서 감명받기</p>
                                <p>3. 추천 IA 토픽 - Calculus, Vector, Induction, Trigonometry…</p>

                                <p><i>화학</i></p>
                                <p>1. EE의 주제를 구상하는 것을 어렵게 생각할 필요는 없고, 스스로가 가장 자신있는 과목을 골라 평상시에 늘 궁금했던 문제를 주제로 정해보자. 개인적인
                                    이야기가 들어간 주제일수록
                                    EE가 흥미롭고 창의로워진다.</p>
                                <p>2. EE는 formatting이 꽤 중요하다. IA처럼 구성이 확실해야하는 레포트가 아닌 에세이이기 때문에 너무 획일적으로 서론-본론-결론의 구성을
                                    따라하지 말고, 채점자가 잘
                                    읽을 수 있도록 문단 문단마다 한 결로 이어지도록 매끄럽게 쓰는 게 중요하다.</p>
                                <p>3. 내가 정한 주제에 real life application이 있을 수 있는지 다방면으로 생각해보자. 예제로, 화학 문제이지만 ecosystem에 대해
                                    이야기 하면서 사회의
                                    문제점과 경제적인 측면까지 고려한다면 정말 well-rounded, multi-faceted and sophisticated한 EE라고 할수 있지
                                    않은가? IB learner’s
                                    profile에서도 볼수 있듯, IB는 여러가지 과목의 융화를 굉장히 좋아한다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createLSecondPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal11">
                    <div className="card_thumbnail art">
                        <div className="event7 artItem">
                            <h3 className="event_thumbnail_text">IB 이수 & 미국 대입</h3>
                        </div>
                        <p>위치: 서울</p>
                        <p>날짜: 2017.8.13</p>
                        <p>스피커: <br />
                            김민하 - 스탠퍼드 수학<br /><br />

                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal11" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">그루美 - IB 학생들을 위한 미국 대입 경험담 공유</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h4 className="blue-font-color">주제</h4>
                                <p>IB 4HLs worth it?</p>
                                <p>pre-IB 학생들이 알아야 하는 대입 로드맵</p>
                                <p>현 재학생들의 현실적인 스펙 공유</p>
                                <p>시간 관리 Time Management</p>
                                <br />
                                <p>일시: 8.13 (일) 저녁 7시-9시 (간식거리 제공)</p>
                                <p>장소: <a href="/#/contact_us" target="blank">그루미 사무실</a> (역삼역 4번 출구)</p>
                                <p>대상: IB DP1 을 앞둔 미국 대학 지망생 5명</p>
                                <p>참가비: 2만원</p>
                                <p>진행: <a href="/#/teachers/1555" target="blank">김민하 선생님</a></p>
                                <br />

                                <h4 className="blue-font-color">이벤트 영상</h4>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/uaN2we0crP0?ecver=1"
                                                frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/rYr9wrwDwyw?ecver=1"
                                                frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createLThirdPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal10">
                    <div className="card_thumbnail art">
                        <div className="event8">
                            <h3 className="event_thumbnail_text">IB, 특례 입시</h3>
                        </div>
                        <p>위치: 상해</p>
                        <p>날짜: 2017.5.5</p>
                        <p>스피커: <br />
                            방원준 - 워릭 수학<br />
                            윤효원 - 서울대 심리학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal10" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">IB, 특례 입시</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h4 className="blue-font-color">카톡 공고</h4>
                                <a href="https://plus-talk.kakao.com/plus/mini/page/v3/261346.html" target="blank"><p>
                                    https://plus-talk.kakao.com/plus/mini/page/v3/261346.html (모바일 열람)</p></a>
                                <br />

                                <h4 className="blue-font-color">위쳇 공고</h4>
                                <a href="http://mp.weixin.qq.com/s/RLOX7kJby4inlhvgligpOw" target="blank"><p>
                                    http://mp.weixin.qq.com/s/RLOX7kJby4inlhvgligpOw</p></a>
                                <br />

                                <h4 className="blue-font-color">시간/위치</h4>
                                <p>상해 5.5 (금) 오후 7시-9시 @명도성 구락부 2층 연회장</p>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <a href="/#/teachers/1187" target="blank"><p>방원준 - IB 수학 전문 (영국 랭킹 3위 워릭 수학과 졸업/ 2010년 IB
                                    이수)</p></a>
                                <a href="/#/teachers/702" target="blank"><p>윤효원 - 상해 SAS 특례 12년 및 서울대 심리학과 재학중</p></a>
                                <br />

                                <h4 className="blue-font-color">발표 내용</h4>
                                <p>- IB 수학 HL과 SL 차이점/ HL을 선택해야 하는 이유?</p>
                                <p>- 수학 IA 학생들이 점수를 잃는 부분</p>
                                <p>- English Lang & Lit SL 트렌드/ 어려운 점/ 주의할 점</p>
                                <p>- ToK 10분 요약/ A 받기</p>
                                <p>- 12년 특례 대입 주의할 점/ 합격자들의 스펙 추세</p>
                                <br />

                                <p><i>"엄마가 전해주는 말보다 롤모델 형, 누나 말을 잘 듣습니다. 아이들 시험기간이어도, 학원 수업 한 타임 빠지더라도 같이 동참하세요."</i>
                                </p>
                                <br />

                                <h4 className="blue-font-color">이벤트 시간표</h4>
                                <p>19:00 - 20:00 IB/특례 대입 발표</p>
                                <p>20:00 - 20:15 여름특강</p>
                                <p>20:15 - 21:00 그룹 상담</p>
                                <p>21:00 - 21:30 개인 상담 (첫 10팀 예약 한정)</p>
                                <br />

                                <h4 className="blue-font-color">수학 IA 무료상담</h4>
                                <p>학생이 작성한 IA를 프린트하여 가져오면 개선 할 부분 무료상담 - 사전예약 신청서에서 IA 무료상담 신청 필요</p>
                                <p>*이벤트 시작 30분전 6:30분에 미리 오셔서 제출해야합니다.</p>
                                <br />

                                <p>“이벤트 주최하는데 비용이 들어 어쩔 수 없이 학부모님께는 100위안의 참가비를 받기로 했습니다 (학생은 입장료 없습니다). 값어치 하는 내용 전해드릴
                                    자신 있습니다.”</p>

                                <br />

                                <h4 className="blue-font-color">이벤트 영상</h4>
                                <div className="row">
                                    <div className="col-sm-">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/geAh7LjcZ-0?ecver=1"
                                                frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/aboPxbaevEo?ecver=1"
                                                frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col-sm-">
                                        <div className="videowrapper">
                                            <iframe className="embed-responsive-item"
                                                src="https://www.youtube.com/embed/7DRlkTKMHd0?ecver=1"
                                                frameBorder="0" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }


    createLForthPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal9">
                    <div className="card_thumbnail art">
                        <div className="event8">
                            <h3 className="event_thumbnail_text">IB 잘하는 법</h3>
                        </div>
                        <p>위치: 송도 채드윅</p>
                        <p>날짜: 2017.5.4</p>
                        <p>스피커: <br />
                            방원준 - 워릭 수학<br />
                            이혁진 - 옥스퍼드 생화학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal9" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">IB 잘하는 법</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h4 className="blue-font-color">카톡 공고</h4>
                                <a href="https://plus-talk.kakao.com/plus/mini/page/v3/261346.html" target="blank"><p>
                                    https://plus-talk.kakao.com/plus/mini/page/v3/261346.html (모바일 열람)</p></a>
                                <br />

                                <h4 className="blue-font-color">스피커</h4>
                                <a href="/#/teachers/1187" target="blank"><p>방원준</p></a>
                                <a href="/#/teachers/14" target="blank"><p>이혁진</p></a>
                                <br />

                                <h4 className="blue-font-color">발표 내용</h4>
                                <p>- IB 수학 HL과 SL 차이점/ HL을 선택해야 하는 이유?</p>
                                <p>- 수학, 과학 IA 학생들이 점수를 잃는 부분</p>
                                <p>- HL, SL 수학, 화학에서 항상 나오는 문제 유형</p>
                                <p>- 홍콩과기대 화공 입시 경험담</p>
                                <br />

                                <h4 className="blue-font-color">이벤트 시간표</h4>
                                <p>10:30 - 11:30 IB 발표</p>
                                <p>11:30 - 11:45 여름특강</p>
                                <p>11:45 - 12:30 그룹 상담</p>
                                <p>12:30 - 13:00 개인 상담 ( 첫 10팀 예약 한정)</p>
                                <br />

                                <h4 className="blue-font-color">당일 주차</h4>
                                <p>1. 아파트 방문자 출입구로 들어오신 후 경비실 호출</p>
                                <p>2. 금일 연회장 이용객이라 말씀하신 후 상가 주차장에 파킹</p>
                                <p>3. 1층으로 올라와 202동 1층 컨시어지에게 연회장 왔다고 말하고 입장</p>
                                <br />

                                <h4 className="blue-font-color">수학 IA 무료상담</h4>
                                <p>학생이 작성한 IA를 가져오면 개선 할 부분 무료상담</p>
                                <p>이벤트 신청서에서 IA 무료상담 신청 필요</p>
                                <p>“이벤트 주최하는데 비용이 들어 어쩔 수 없이 학부모님께는 1만원의 참가비를 받기로 했습니다 (학생은 입장료 없습니다). 값어치 하는 내용 전해드릴 자신
                                    있습니다.”</p>

                                <h4 className="blue-font-color">이벤트 영상</h4>
                                <div className="videowrapper">
                                    <iframe className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/qLEGNdPlFXI?ecver=1"
                                        frameBorder="0" allowFullScreen></iframe>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createLFifthPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal8">
                    <div className="card_thumbnail art">
                        <div className="event7 artItem">
                            <h3 className="event_thumbnail_text">미국, 홍콩, 영국 대입</h3>
                        </div>
                        <p>위치: 제주</p>
                        <p>날짜: 2017.4.29</p>
                        <p>스피커: <br />
                            변준경 - 코넬 도시건축학<br />
                            김가은 - 홍콩대 생명공학<br />
                            이혁진 - 옥스퍼드 생화학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal8" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">미국, 홍콩, 영국 대입</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><b>카톡 공고</b></p>
                                <a href="https://plus-talk.kakao.com/plus/mini/page/v3/261346.html" target="blank"><p>
                                    https://plus-talk.kakao.com/plus/mini/page/v3/261346.html (모바일 열람)</p></a>
                                <br />

                                <p><b>스피커</b></p>
                                <a href="/#/teachers/763" target="blank"><p>변준경</p></a>
                                <a href="/#/teachers/1256" target="blank"><p>김가은</p></a>
                                <a href="/#/teachers/14" target="blank"><p>이혁진</p></a>
                                <br />

                                <p><b>발표 내용</b></p>
                                <p>1. 국내 IB 출신의 코넬 합격 경험담</p>
                                <p>2. 컨설팅 업체에서 말하는 입시에 필요한 스펙이라고 하는 준비해야할 사항들을 어느정도 참고해야하는지? 현 재학생들 스펙 통계</p>
                                <p>3. 아이가 관심 가지고 전공하고 싶은 것과 관심있는 대학에 스스로의 노력과 참여할 수 있는 노하우</p>
                                <p>4. 홍콩대 재학생이 말하는 홍콩대 입시 현황</p>
                                <br />

                                <p><b>이벤트 시간표</b></p>
                                <p>18:30 - 19:15 미국, 홍콩, 영국 대입 발표</p>
                                <p>19:15 - 19:30 여름특강</p>
                                <p>19:30 - 20:15 그룹 상담</p>
                                <p>20:15 - 20:45 개인 상담 (첫 10팀 예약 한정)</p>
                                <br />

                                <p>“이벤트 주최하는데 비용이 들어 어쩔 수 없이 학부모님께는 1만원의 참가비를 받기로 했습니다 (학생은 입장료 없습니다). 값어치 하는 내용 전해드릴 자신
                                    있습니다.”</p>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <img src="images/event7_1.jpg" style={{ maxWidth: "100%" }} />
                                    </div>
                                    <div className="col-sm-6">
                                        <img src="images/event7_2.jpg" style={{ maxWidth: "100%" }} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createSixthPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal7">
                    <div className="card_thumbnail art">
                        <div className="event7 artItem">
                            <h3 className="event_thumbnail_text">IB 이수후 미국, 홍콩 대입</h3>
                        </div>
                        <p>위치: 경기외고</p>
                        <p>날짜: 2017.3.30</p>
                        <p>스피커: <br />
                            방원준 - 워릭 수학<br />
                            변준경 - 코넬 도시건축학<br />
                            구인회 - 홍콩과기대 경제/금융학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal7" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">IB 이수후 미국, 홍콩 대입</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><b>카톡 공고</b></p>
                                <a href="https://plus-talk.kakao.com/plus/mini/page/v3/240323.html" target="blank"><p>
                                    https://plus-talk.kakao.com/plus/mini/page/v3/261346.html (모바일 열람)</p></a>
                                <br />

                                <p><b>날짜 및 장소</b></p>
                                <p>3월 17일 (금) 11시- 1시 @IStudy학원 - 경기외고 맞은편 (신청서에 상세위치 참조)</p>
                                <p>*예약필수: 고학년 (IB) 학부모님 20명 한정</p>
                                <br />

                                <p><b>스피커</b></p>
                                <a href="/#/teachers/1187" target="blank"><p>방원준</p></a>
                                <a href="/#/teachers/763" target="blank"><p>변준경</p></a>
                                <a href="/#/teachers/1249" target="blank"><p>구인회</p></a>
                                <a href="/#/teachers/658" target="blank"><p>이예은 웨슬리안 생물학</p></a>
                                <br />

                                <p><b>발표 내용</b></p>
                                <p>1. 국내 IB 출신의 코넬 합격 경험담</p>
                                <p>2. 컨설팅 업체에서 말하는 입시에 필요한 스펙이라고 하는 준비해야할 사항들을 어느정도 참고해야하는지? 현 재학생들 스펙 통계</p>
                                <p>3. 아이가 관심 가지고 전공하고 싶은 것과 관심있는 대학에 스스로의 노력과 참여할 수 있는 노하우</p>
                                <p>4. 홍콩대 재학생이 말하는 홍콩대 입시 현황</p>
                                <br />

                                <p><b>이벤트 시간표</b></p>
                                <p><i>차례</i></p>
                                <p>11:00~11:10 인사말씀</p>
                                <p>11:10~11:25 변준경 선생님</p>
                                <p>11:40~11:55 구인회 선생님</p>
                                <p>11:55~12:10 이예은 선생님</p>
                                <p>12:10~1:00 관심있는 선생님(들)과 그룹 Q&A 세션</p>
                                <br />

                                <p>*개인발표=개인 유학 스토리, IB 이수 경험담, 자신만의 특별했던 입시 준비, 학교에서 채워지지 않는 학습적인 부분을 채워 줄 수 있는 수업</p>
                                <p>*그룹 Q&A 주제: 입시 추세, 졸업 후 취업 방향 및 추세 등</p>

                                <p>“이벤트 주최하는데 비용이 들어 어쩔 수 없이 학부모님께는 1만원의 참가비를 받기로 했습니다 (학생은 입장료 없습니다). 값어치 하는 내용 전해드릴 자신
                                    있습니다.”</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    createLSenventhPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal5">
                    <div className="card_thumbnail art">
                        <div className="event6 artItem">
                            <h3 className="event_thumbnail_text">미국 대학 입시</h3>
                        </div>
                        <p>위치: 선릉</p>
                        <p>날짜: 2017.2.14</p>
                        <p>스피커: <br />
                            변준경 - 코넬 도시건축학 <br />
                            이지원 - 하버드 정치학<br />
                            장준혁 - UCLA 컴퓨터공학 </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal5" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"아이비리그 입시"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><b>카톡 공고</b></p>
                                <a href="https://plus-talk.kakao.com/plus/mini/page/v3/226493.html" target="blank"><p>
                                    https://plus-talk.kakao.com/plus/mini/page/v3/226493.html (모바일 열람)</p></a>
                                <br />

                                <p><b>날짜 및 장소</b></p>
                                <p>2월 14일 (화) 오전 11시-오후 1시 @선릉 더모임 (신청서에 상세위치 참조)</p>
                                <p>*예약필수: 고학년 학부모님 15명 한정</p>
                                <p>장소가 맞지 않아 오프라인 참여가 불가피한 경우 온라인으로 참여 가능하니 온라인 참여가 필요하신 분은 그루미에게 연락주세요.</p>
                                <br />

                                <p><i>"하버드 및 코넬 재학생/졸업생들의 개인 유학 스토리 그리고 학교 입학 계기 나누기 이벤트 가 열립니다. 평소 미국 대학 입시에 관심이 있으시거나
                                    궁금한 점이 있으셨던 학생들과
                                    학부모님들을 그루미에서 초대합니다!"</i></p>
                                <br />

                                <p><b>스피커</b></p>
                                <a href="/#/teachers/763" target="blank"><p>변준경</p></a>
                                <a href="/#/teachers/183" target="blank"><p>이지원</p></a>
                                <a href="/#/teachers/1253" target="blank"><p>장준혁</p></a>
                                <br />

                                <p><b>이벤트 시간표</b></p>
                                <p><i>차례</i></p>
                                <p>11:00~11:15 인사말씀</p>
                                <p>11:15~11:30 변준경 선생님</p>
                                <p>11:30~11:45 장준혁 선생님</p>
                                <p>11:45~12:00 이지원 선생님 (온라인)</p>
                                <p>12:00~1:00 관심있는 선생님(들)과 그룹 Q&A 세션</p>
                                <br />

                                <p>*개인발표=개인 유학 스토리, IB 및 AP 이수 경험담, 자신만의 특별했던 입시 준비</p>
                                <p>*그룹 Q&A 주제: 입시 추세, 졸업 후 취업 방향 및 추세 등</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createMFirstPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal6">
                    <div className="card_thumbnail art">
                        <div className="event5 artItem">
                            <h3 className="event_thumbnail_text">홍콩 대학 입시</h3>
                        </div>
                        <p>위치: 선릉</p>
                        <p>날짜: 2016.12.23</p>
                        <p>스피커: <br />
                            장상진 - 홍콩과기대 생화학<br />
                            장준경 - 홍콩과기대 수학<br />
                            김도희 - 홍콩대x시엉스포 복수전공
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal6" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"홍콩 대학 입시"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <a href="https://plus-talk.kakao.com/plus/mini/page/v3/226493.html" target="blank"><p>
                                    https://plus-talk.kakao.com/plus/mini/page/v3/226493.html (모바일 열람)</p></a>
                                <br />

                                <p><b>날짜 및 장소</b></p>
                                <p>12월 23일 (화) 오전 10시-12시 @선릉 더모임 (신청서에 상세위치 참조)</p>
                                <p>*예약필수: 고학년 학부모님 15명 한정</p>
                                <p>장소가 맞지 않아 오프라인 참여가 불가피한 경우 온라인으로 참여 가능하니 온라인 참여가 필요하신 분은 그루미에게 연락주세요.</p>
                                <br />

                                <p><i>"홍콩대학교 재학생/졸업생과의 네트워킹 이벤트가 열립니다. 평소 홍콩대학 입시에 관심이 있으시거나 궁금한 점이 있으셨던 학생들과 학부모님들을
                                    그루미에서 초대합니다!"</i>
                                </p>
                                <br />

                                <p><b>스피커</b></p>
                                <a href="/#/teachers/223" target="blank"><p>장상진</p></a>
                                <a href="/#/teachers/270" target="blank"><p>차현서</p></a>
                                <a href=""><p>장준경</p></a>
                                <a href="/#/teachers/1346" target="blank"><p>김도희</p></a>
                                <br />

                                <p><b>이벤트 시간표</b></p>
                                <p><i>차례</i></p>
                                <p>10:00~10:15 인사말씀</p>
                                <p>10:15~10:30 장상진 선생님</p>
                                <p>10:30~10:45 차현서 선생님</p>
                                <p>10:45~11:00 장준경 선생님 (온라인)</p>
                                <p>11:00~11:15 김도희 선생님</p>
                                <p>11:15~12:00 관심있는 선생님(들)과 그룹 Q&A 세션</p>
                                <br />

                                <p>*개인발표=개인 유학 스토리, IB 및 AP 이수 경험담, 자신만의 특별했던 입시 준비</p>
                                <p>*그룹 Q&A 주제: 입시 추세, 졸업 후 취업 방향 및 추세 등</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createMSecondPart = () => {
        return (

            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal4">
                    <div className="card_thumbnail art">
                        <div className="event4 artItem">
                            <h3 className="event_thumbnail_text">옥스브리지 입시</h3>
                        </div>
                        <p>위치: 압구정</p>
                        <p>날짜: 2016.7.3</p>
                        <p>스피커: <br />
                            박재영 - 옥스퍼드 법대<br />
                            조승현 - 옥스퍼드 공대<br />
                            엄의용 - 케임브리지 경제학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal4" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"옥스브리지 입시"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><b>스피커</b></p>
                                <a href="/#/teachers/194" target="blank"><p>박재영</p></a>
                                <a href="/#/teachers/656" target="blank"><p>조승현</p></a>
                                <a href="/#/teachers/104" target="blank"><p>엄의용</p></a>
                                <img src="images/event4.png" style={{ maxWidth: "100%" }} />

                                <div className="videowrapper">
                                    <iframe className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/t0Mzgs7tKNQ?ecver=1"
                                        frameBorder="0" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createMThirdPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal3">
                    <div className="card_thumbnail art">
                        <div className="event3 artItem">
                            <h3 className="event_thumbnail_text">아이비리그 입시</h3>
                        </div>
                        <p>위치: 압구정</p>
                        <p>날짜: 2016.7.3</p>
                        <p>스피커: <br />
                            이소미나 - 유펜 경영<br />
                            이기준 - 스탠퍼드 재료공학<br />
                            방정태 - 다트머스 역사/경제학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal3" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"아이비리그 입시"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><b>스피커</b></p>
                                <a href="/#/teachers/647" target="blank"><p>이소미나</p></a>
                                <a href="/#/teachers/46" target="blank"><p>이기준</p></a>
                                <a href="/#/teachers/209" target="blank"><p>방정태</p></a>
                                <img src="images/event4.png" style={{ maxWidth: "100%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }


    createMForthPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal2">
                    <div className="card_thumbnail art">
                        <div className="event1 artItem">
                            <h3 className="event_thumbnail_text">특례, 홍콩대, 영국 대입</h3>
                        </div>

                        <p>위치: 상해 명도성구락부</p>
                        <p>날짜: 2016.6.10</p>
                        <p>스피커: <br />
                            김수연 - 홍콩대 생명의학<br />
                            심희영 - 서울대 자율전공학<br />
                            이혁진 - 옥스퍼드 생화학
                        </p>
                    </div>
                </a>
                <div className="modal fade" id="myModal2" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"특례, 홍콩대, 영국 대입"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><b>위쳇 공고</b></p>
                                <a href="http://mp.weixin.qq.com/s/2qv-weTgtjp2keM1rLaXzA" target="blank"><p>
                                    http://mp.weixin.qq.com/s/RLOX7kJby4inlhvgligpOw</p></a>
                                <br />

                                <p><b>스피커</b></p>
                                <a href="/#/teachers/406" target="blank"><p>김수연</p></a>
                                <a href="/#/teachers/559" target="blank"><p>심희영</p></a>
                                <a href="/#/teachers/14" target="blank"><p>이혁진</p></a>

                                <img src="images/event2.jpg" style={{ maxWidth: "100%" }} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createMFifthPart = () => {
        return (
            <div className="col-sm-12 col-md-4 item">
                <a data-toggle="modal" data-target="#myModal">
                    <div className="card_thumbnail art">
                        <div className="event1 artItem">
                            <h3 className="event_thumbnail_text">아이비리그, 옥스브리지 대입</h3>
                        </div>
                        <p>위치: 제주영어교육도시</p>
                        <p>날짜: 2016.5.25</p>
                        <p>스피커: <br />
                            이혁진 - 옥스퍼드 생화학<br />
                            정인식 - 유펜 와튼<br />
                            사혜원 - 옥스퍼드 법학</p>
                    </div>
                </a>

                <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="myModalLabel">"아이비리그, 옥스브리지 대입 경험담"</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                                    aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><b>스피커</b></p>
                                <a href="/#/teachers/14" target="blank"><p>이혁진</p></a>
                                <a href="/#/teachers/92" target="blank"><p>정인식</p></a>
                                <a href="/#/teachers/59" target="blank"><p>사혜원</p></a>
                                <img src="images/event1.png" style={{ maxWidth: "100%" }} />
                                <br />

                                <p><b>이벤트 영상</b></p>
                                <div className="videowrapper">
                                    <iframe className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/OxKo6nbbu64?ecver=1"
                                        frameBorder="0" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createLastPart = () => {
        return (
            <div className="email-us">
                <div className="email-us-box">
                    <div className="email-us-content">
                        <h3 style={{ color: "white" }}>스피커 <TrasForparticipationenquirypleaseemailsupportgurumekr /></h3>
                    </div>
                </div>
            </div>
        )
    }




    createHtml = () => {
        return (
            <div>

                {this.createNav()}
                {this.createFirstPart()}

                {/* FROM SECOND PART */}
                <div className="greybackground">
                    <div className="container">
                        <div className="row items-container">
                            {this.createSecondPart()}
                            {this.createThirdPart()}
                            {this.createForthPart()}
                            {this.createFifthPart()}
                            {this.createSixthPart()}
                            {this.createSeventhPart()}
                            {this.createLFirstPart()}
                            {this.createLSecondPart()}
                            {this.createLThirdPart()}
                            {this.createLForthPart()}
                            {this.createLFifthPart()}
                            {this.createSixthPart()}
                            {this.createLSenventhPart()}
                            {this.createMFirstPart()}

                            {this.createMSecondPart()}
                            {this.createMThirdPart()}
                            {this.createMForthPart()}
                            {this.createMFifthPart()}
                        </div>
                    </div>
                    {this.createLastPart()}
                </div>

            </div>
        )
    }

    render() {
        return (this.createHtml())
    }

}

export default events;