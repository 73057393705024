import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasStudent,
    TrasRegister,
    TrasSchool,
    TrasSubjectsofinterest,
    TrasTest,
    TrasAdmissions,
    TrasEnglish,
    TrasAboutMyself,
    TrasLocation,
    TrasItwouldbehelpfulifyoucouldprovidemoredetailedinformation,
    TrasAddsecondlocationtermtimeaddressforcurrentstudents,
    TrasContactdetails,
    TrasContactdetailsarenotpubliclydisplayedbutonlytoteachersthatyouhavelessonswith,
    TrasSubmit,
    TrasSubmitting,
    TrasYoucangetkakaotalkalarmwhenyoufillinphonenumberwhichislinkedwithkakaotalk,
    TrasPleasetypeinschoolname,
    TrasPleaseinputsubject,
    TrasPleaseinputlocation,
    TrasPleasetypeinphonenumber
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import queryString from 'query-string';
import { GetAjaxURL, GetAjaxURLFileUpload, multipartForm, postConfig } from '../../../config/AjaxConfig';

import SubjectSelect from '../template/SubjectSelect';
import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';

import './studentreg.css';


class studentreg extends Component {


    state = {
        lang: 'ko',
        studentId: -1,
        translate: {
            ko: {
                TrasPleaseenteryourprimaryphonenumber: '카톡알림 메세지를 받아보실 수 있도록 카톡에 연동되어있는 핸드폰 번호를 입력해 주세요.',
                TrasPleaseenteryoursecondaryphonenumber: '두번째 전화번호 (예: 해외 번호)',
                TrasKakaotalkID: '카카오톡 ID',
                TrasSkypeID: '스카이프 ID',
                TrasWechatID: '위쳇 ID',
                TrasWhatsappID: 'Whatsapp ID',
                TrasDistrictandtown: '구/동',
            },
            en: {
                TrasPleaseenteryourprimaryphonenumber: 'Please enter your primary phone number.',
                TrasPleaseenteryoursecondaryphonenumber: 'Second phone number (for example, overseas phone number)',
                TrasKakaotalkID: 'Kakaotalk ID *necessary',
                TrasSkypeID: 'Skype ID',
                TrasWechatID: 'Wechat ID',
                TrasWhatsappID: 'Whatsapp ID',
                TrasDistrictandtown: 'District and town',
            },
            cn: {
                TrasPleaseenteryourprimaryphonenumber: '请输入与微信链接的手机号码，以便收到微信通知信息。',
                TrasPleaseenteryoursecondaryphonenumber: '第二个电话号码（例如，海外电话号码）',
                TrasKakaotalkID: 'Kakaotalk账号 *necessary',
                TrasSkypeID: 'Skype账号',
                TrasWechatID: '微信号',
                TrasWhatsappID: 'Whatsapp账号',
                TrasDistrictandtown: '区路',
            }
        },
        dataParam: {
            locationsData: [],
            customLocationDetail: [],
            customLocationDetailSec: [],
            needSecondLocation: false,
            countriesData: [],
            curriculumsData: []
        },
        paramState: {
            location: '',
            secondLocation: '',
            locationMsg: '',
            secondLocationmsg: '',
            isReadStudentTermsAndCondition: false,
            isSubmitting: false,
            contact: {
                phone1_country_id: 200,      // DEFAULT  (kor)
                phone: '',
                phone2_country_id: 200,      // DEFAULT  (kor)
                phone2: '',
                kakao: '',
                skype: '',
                wechat: '',
                whatsapp: '',
            },
            about_myself: `학년/공부 스타일\n- \n- \n\n이수중인 과목\n- \n\n목표대학/전공\n- `,
            subjects: [],
            educationInfos: '',
            isRegister: false,             // Valid Token
            school: '',
            recommender: '',

        },
        isAjaxDone: false,
        isEdit: false,
    }

    componentDidMount = async () => {

        const l_url = window.location.href;
        let token = '';
        if (l_url.indexOf('token=') !== -1) {
            token = l_url.substr(l_url.indexOf('token=') + 'token='.length, l_url.length);
        }

        if (token !== '') {
            // register
            this.doRegisterProc(token);
        } else {
            // edit
            const getUrl = window.location.href;
            const splt = getUrl.split('/');
            let splitIndex = 0;
            for (let i = 0; i < splt.length; i++) {
                if (splt[i] === 'edit') {
                    splitIndex = i - 1;
                    break;
                }
            }
            const studentId = splt[splitIndex];
            if (!studentId) return window.location.href = '/#/';

            this.doEditProc(studentId);
        }

    }

    doEditProc = async (_studentId) => {
        const paramStudentId = _studentId;
        const isEdit = true;
        const isReadStudentTermsAndCondition = true;

        const allUrls = await GetAjaxURL();
        const locationsUrl = allUrls.locations + '?lan=' + this.state.lang; // 'api/locations?lan=' + lang
        const countriesUrl = allUrls.countries;
        const curriculumsUrl = allUrls.curriculums;
        const apiThisUser = allUrls.apiThisUser;

        const thisUserRes = await axios.get(apiThisUser).catch((err) => { return console.log(err) });

        if (thisUserRes.data.status === "fail") {
            let msg = '로그인을 먼저 해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/';
        }

        const studentId = thisUserRes.data.profile.id;
        if (String(paramStudentId) !== String(studentId)) {
            let msg = '해당 사용자 계정이 아닙니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/';
        }



        const locationsData = await axios.get(locationsUrl).catch((err) => { return console.log(err) });
        const countriesData = await axios.get(countriesUrl).catch((err) => { return console.log(err) });
        const curriculumsData = await axios.get(curriculumsUrl).catch((err) => { return console.log(err) });

        const userProfile = thisUserRes.data.profile;
        const userData = thisUserRes.data.user;

        const school = userProfile.school;
        const about_myself = userProfile.about_myself;
        const location = userData.location_id;
        const locationMsg = userData.location_note;
        const secondLocation = userData.location2;
        const secondLocationmsg = userData.location2_note;

        const contact = {
            phone1_country_id: userData.phone1_country_id,
            phone: userData.phone,
            phone2_country_id: userData.phone2_country_id,
            phone2: userData.phone2,
            kakao: userData.kakaotalk,
            skype: userData.skype,
            wechat: userData.wechat,
            whatsapp: userData.whatsapp,
        };


        const expected_subjects = userProfile.expected_subjects;


        // DO LOCATION
        const locationDataf = locationsData.data;
        let findKoreanName = '';
        const allLocation = [];
        for (let i = 0; i < locationDataf.length; i++) {
            const region = locationDataf[i]['region'];
            const detail = locationDataf[i]['detail'];
            for (let k = 0; k < detail.length; k++) {
                if (String(detail[k]['id']) === String(location)) {
                    findKoreanName = region;
                }
            }
        }

        const isAjaxDone = true;
        const isRegister = false;

        this.setState({
            ...this.state,
            studentId: studentId,
            dataParam: {
                ...this.state.dataParam,
                locationsData: locationsData.data,
                countriesData: countriesData.data,
                curriculumsData: curriculumsData.data,
            },
            paramState: {
                ...this.state.paramState,
                isRegister: isRegister,
                school: school,
                about_myself: about_myself,
                location: location,
                locationMsg: locationMsg,
                secondLocation: secondLocation,
                secondLocationmsg: secondLocationmsg,
                contact: contact,
                isReadStudentTermsAndCondition: isReadStudentTermsAndCondition,
            },
            isAjaxDone: isAjaxDone,
            isEdit: isEdit,
            findKoreanName: findKoreanName,
        }, () => {


            this.changeCountry(this.state.findKoreanName);
            this.props.RootBindingData.doLoading(false);
        })


    }




    doRegisterProc = async (_token) => {

        const token = _token;

        const allUrls = await GetAjaxURL();

        const confirmTokenUrl = allUrls.confirmToken + '?token=' + token;
        const locationsUrl = allUrls.locations + '?lan=' + this.state.lang; // 'api/locations?lan=' + lang
        const countriesUrl = allUrls.countries;
        const curriculumsUrl = allUrls.curriculums;

        const confirmTokenRes = await axios.post(confirmTokenUrl, {}, postConfig).catch((err) => { return console.log(err) });

        // CHECK TOKEN FIRST
        if (confirmTokenRes.data.status !== 'success') {
            showNotify(confirmTokenRes.msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/';
        }
        if (confirmTokenRes.data.type !== 'student') {
            let msg = '학생 회원만 가입 가능한 페이지입니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/';
        }


        const locationsData = await axios.get(locationsUrl).catch((err) => { return console.log(err) });
        const countriesData = await axios.get(countriesUrl).catch((err) => { return console.log(err) });
        const curriculumsData = await axios.get(curriculumsUrl).catch((err) => { return console.log(err) });

        const isAjaxDone = true;
        const isRegister = true;

        const phone = confirmTokenRes.data.phone;

        this.setState({
            ...this.state,
            dataParam: {
                ...this.state.dataParam,
                locationsData: locationsData.data,
                countriesData: countriesData.data,
                curriculumsData: curriculumsData.data,
            },
            paramState: {
                ...this.state.paramState,
                isRegister: isRegister,
                contact: {
                    ...this.state.paramState.contact,
                    phone: phone,
                }
            },
            isAjaxDone: isAjaxDone
        }, () => {
            this.changeCountry("영국");
        })


    }

    createNav = () => {
        return (
            <div>
                <ol className="breadcrumb">
                    <li><a href='/#/'><TrasHome /></a></li>
                    <li><a href="/#/login"><TrasStudent /></a></li>
                    <li className="active"><TrasRegister /></li>
                </ol>
            </div>
        )
    }



    clickShowSecondLoaction = (e) => {
        const value = e.target.checked;
        if (!value) {
            this.setState({
                ...this.state,
                dataParam: {
                    ...this.state.dataParam,
                    needSecondLocation: value
                },
                paramState: {
                    ...this.state.paramState,
                    secondLocation: '',
                    secondLocationmsg: '',
                }
            }, () => {
            })
        } else {
            this.setState({
                ...this.state,
                dataParam: {
                    ...this.state.dataParam,
                    needSecondLocation: value
                }
            }, () => {
                this.changeCountry("영국", true);
            })
        }
    }

    changeCountry = (e, isSec) => {
        let currentLocation;
        let locationType = 'first';

        if (isSec) {
            currentLocation = e;
            locationType = 'second';
        } else {
            if (e.target) {
                currentLocation = e.target.value;
                locationType = e.target.dataset['locationFirst'];
            } else {
                currentLocation = e;
            }
        }

        const locationsData = this.state.dataParam.locationsData;
        let regionDetail = [];

        for (let i = 0; i < locationsData.length; i++) {
            if (locationsData[i].region === currentLocation) {
                regionDetail = locationsData[i].detail;
                break;
            }
        }

        if (locationType === 'first') {

            let findKoreanName = '';
            if (e) {
                if (e.target) {
                    if (e.target.value) {
                        findKoreanName = e.target.value;
                    }
                }
            }
            if (findKoreanName !== '') {
                this.setState({
                    ...this.state,
                    dataParam: {
                        ...this.state.dataParam,
                        customLocationDetail: regionDetail
                    },
                    paramState: {
                        ...this.state.paramState,
                        location: regionDetail[0].id
                    },
                    findKoreanName: findKoreanName
                })
            } else {
                this.setState({
                    ...this.state,
                    dataParam: {
                        ...this.state.dataParam,
                        customLocationDetail: regionDetail
                    },
                    paramState: {
                        ...this.state.paramState,
                        location: regionDetail[0].id
                    },
                })

            }

        } else {
            this.setState({
                ...this.state,
                dataParam: {
                    ...this.state.dataParam,
                    customLocationDetailSec: regionDetail
                },
                paramState: {
                    ...this.state.paramState,
                    secondLocation: regionDetail[0].id
                }
            })
        }

    }

    selectDistrictToState = (e) => {
        const value = e.target.value;
        const locationType = e.target.dataset['locationFirst'];

        if (locationType === 'first') {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    location: value
                }
            });
        } else {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    secondLocation: value
                }
            });
        }
    }

    locationMsgToState = (e) => {
        const value = e.target.value;
        const locationType = e.target.dataset['locationFirst'];

        if (locationType === 'first') {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    locationMsg: value
                }
            });
        } else {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    secondLocationmsg: value
                }
            });
        }

    }



    setPhone1CountryId = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    phone1_country_id: value
                }
            }
        }, () => {
        })
    }
    setPhone = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    phone: value
                }
            }
        })
    }
    setPhone2CountryId = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    phone2_country_id: value
                }
            }
        })
    }
    setPhone2 = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    phone2: value
                }
            }
        })
    }
    setKakao = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    kakao: value
                }
            }
        })
    }
    setSkype = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    skype: value
                }
            }
        })
    }
    setWechat = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    wechat: value
                }
            }
        })
    }
    setWahtsApp = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    whatsapp: value
                }
            }
        })
    }

    createPartE = () => {

        const lang = this.state.lang;
        let TrasPleaseenteryourprimaryphonenumber = this.state.translate.ko.TrasPleaseenteryourprimaryphonenumber;
        let TrasPleaseenteryoursecondaryphonenumber = this.state.translate.ko.TrasPleaseenteryoursecondaryphonenumber;
        let TrasKakaotalkID = this.state.translate.ko.TrasKakaotalkID;
        let TrasSkypeID = this.state.translate.ko.TrasSkypeID;
        let TrasWechatID = this.state.translate.ko.TrasWechatID;
        let TrasWhatsappID = this.state.translate.ko.TrasWhatsappID;

        if (lang === 'en') {
            TrasPleaseenteryourprimaryphonenumber = this.state.translate.en.TrasPleaseenteryourprimaryphonenumber;
            TrasPleaseenteryoursecondaryphonenumber = this.state.translate.en.TrasPleaseenteryoursecondaryphonenumber;
            TrasKakaotalkID = this.state.translate.en.TrasKakaotalkID;
            TrasSkypeID = this.state.translate.en.TrasSkypeID;
            TrasWechatID = this.state.translate.en.TrasWechatID;
            TrasWhatsappID = this.state.translate.en.TrasWhatsappID;
        } else if (lang === 'cn') {
            TrasPleaseenteryourprimaryphonenumber = this.state.translate.cn.TrasPleaseenteryourprimaryphonenumber;
            TrasPleaseenteryoursecondaryphonenumber = this.state.translate.cn.TrasPleaseenteryoursecondaryphonenumber;
            TrasKakaotalkID = this.state.translate.cn.TrasKakaotalkID;
            TrasSkypeID = this.state.translate.cn.TrasSkypeID;
            TrasWechatID = this.state.translate.cn.TrasWechatID;
            TrasWhatsappID = this.state.translate.cn.TrasWhatsappID;
        }


        const countriesData = this.state.dataParam.countriesData;
        const countiresOptions = [];
        let tempText = '';

        // SET DEFAULT STATE
        for (let i = 0; i < countriesData.length; i++) {
            if (lang === 'ko') {
                tempText = countriesData[i].name_ko + ' (+' + countriesData[i].country_code + ')';
            } else {
                tempText = countriesData[i].name + ' (+' + countriesData[i].country_code + ')';
            }

            countiresOptions.push(
                <option key={i} value={countriesData[i].id}>{tempText}</option>
            );
        }


        return (
            <div>
                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_phone.png" />
                        </span>

                        <div className="">
                            <select className="form-control country-select student-reg-contact-input height35"
                                onChange={this.setPhone1CountryId}
                                value={this.state.paramState.contact.phone1_country_id}
                            >
                                <option disabled>번호 선택</option>
                                {countiresOptions}
                            </select>
                        </div>
                        <div className="">
                            <input type="tel" name="phone"
                                className="form-control student-reg-contact-input height35"
                                onChange={this.setPhone}
                                value={this.state.paramState.contact.phone}
                                required="required"
                                placeholder={TrasPleaseenteryourprimaryphonenumber}
                            />
                        </div>
                    </div>
                </div>
                <div className='tutorreg-line-a'>
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_phone.png" />
                        </span>

                        <div className="">
                            <select className="form-control country-select student-reg-contact-input height35"
                                onChange={this.setPhone2CountryId}
                                value={this.state.paramState.contact.phone2_country_id}
                            >
                                <option disabled>번호 선택</option>
                                {countiresOptions}
                            </select>
                        </div>

                        <div className="">
                            <input type="tel"
                                className="form-control student-reg-contact-input height35"
                                onChange={this.setPhone2}
                                value={this.state.paramState.contact.phone2}
                                placeholder={TrasPleaseenteryoursecondaryphonenumber}
                            />
                        </div>
                    </div>
                </div>

                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_kakaotalk.png" />
                        </span>
                        <input className="form-control student-reg-contact-input height35"
                            onChange={this.setKakao}
                            value={this.state.paramState.contact.kakao}
                            placeholder={TrasKakaotalkID}
                        />
                    </div>
                </div>

                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_skype.png" />
                        </span>
                        <input className="form-control student-reg-contact-input height35"
                            onChange={this.setSkype}
                            value={this.state.paramState.contact.skype}
                            placeholder={TrasSkypeID}
                        />
                    </div>
                </div>

                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_wechat.png" />
                        </span>
                        <input className="form-control student-reg-contact-input height35"
                            onChange={this.setWechat}
                            value={this.state.paramState.contact.wechat}
                            placeholder={TrasWechatID}
                        />
                    </div>
                </div>

                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_whatsapp.png" />
                        </span>
                        <input className="form-control student-reg-contact-input height35"
                            onChange={this.setWahtsApp}
                            value={this.state.paramState.contact.whatsapp}
                            placeholder={TrasWhatsappID}
                        />
                    </div>
                </div>

            </div>
        )
    }

    // CONTACT DETAILS
    createPartB_D = () => {
        return (

            <div className="tutor-part-a-component" id="contacts">
                <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasContactdetails /></h1>
                <p className='fontsz14'>
                    <TrasContactdetailsarenotpubliclydisplayedbutonlytoteachersthatyouhavelessonswith />
                </p>
                <p className='fontsz14'>
                    <TrasYoucangetkakaotalkalarmwhenyoufillinphonenumberwhichislinkedwithkakaotalk />
                </p>
            </div>
        )
    }

    createPartD = () => {

        const lang = this.state.lang;
        let TrasDistrictandtown = this.state.translate.ko.TrasDistrictandtown;
        if (lang === 'en') {
            TrasDistrictandtown = this.state.translate.en.TrasDistrictandtown;
        } else if (lang === 'cn') {
            TrasDistrictandtown = this.state.translate.cn.TrasDistrictandtown;
        }


        const locationsData = this.state.dataParam.locationsData;
        const customLocationDetail = this.state.dataParam.customLocationDetail;
        const customLocationDetailSec = this.state.dataParam.customLocationDetailSec;

        const regions = [];
        for (let i = 0; i < locationsData.length; i++) {
            regions.push(
                <option key={`region_${i}`} value={locationsData[i].region}>{locationsData[i].region}</option>
            )
        }

        const regionDetail = [];
        const regionDetailSec = [];
        for (let i = 0; i < customLocationDetail.length; i++) {
            regionDetail.push(
                <option key={`detail_${i}`} value={customLocationDetail[i].id}>{customLocationDetail[i].city}</option>
            );
        };
        for (let i = 0; i < customLocationDetailSec.length; i++) {
            regionDetailSec.push(
                <option key={`detail_${i}`} value={customLocationDetailSec[i].id}>{customLocationDetailSec[i].city}</option>
            );
        };

        return (
            <div>
                <h1 className="studentreg-font-sz-24 blue-font-color"><TrasLocation /></h1>
                <div className='reg-location'>
                    <div className='reg-location-part-a marginBottom10'>
                        <div className="">
                            <select className="form-control student-reg-contact-input height34 "
                                data-location-first='first'
                                onChange={this.changeCountry}
                                value={this.state.findKoreanName}
                            >
                                {regions}
                            </select>
                        </div>
                        <div className="">
                            <select className="form-control student-reg-contact-input height34 "
                                data-location-first='first'
                                onChange={this.selectDistrictToState}
                                value={this.state.paramState.location}
                            >
                                {regionDetail}
                            </select>
                        </div>
                    </div>
                    <div className='reg-location-part-b'>
                        <div className="tutor-part-a-component">
                            <input className="form-control student-reg-contact-input height34 marginBottom10"
                                onChange={this.locationMsgToState}
                                value={this.state.paramState.locationMsg}
                                data-location-first='first'
                                placeholder={TrasDistrictandtown} type="text"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    changeFromAboutSelf = (e) => {

        const value = e.target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                about_myself: value
            }
        })

    }

    createPartC = () => {
        const about_myself = this.state.paramState.about_myself;
        return (
            <div>
                <h1 className="studentreg-font-sz-24 blue-font-color"><TrasAboutMyself /></h1>
                <p><TrasItwouldbehelpfulifyoucouldprovidemoredetailedinformation /></p>
                <textarea className='studentreg-single-textarea' onChange={this.changeFromAboutSelf} value={about_myself} />
            </div>
        )
    }

    getDataFromSubjectSelect = (_teachingSubjects, _educationInfos) => {
        if (_educationInfos) {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    subjects: _teachingSubjects
                }
            })
        } else {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    subjects: _teachingSubjects,
                }
            })
        }

    }

    createPartB = () => {
        return (
            <div className="student-part-a-component marginBottom30">
                <h1 className="studentreg-font-sz-24 blue-font-color"><TrasSubjectsofinterest /></h1>
                <SubjectSelect
                    curriculumsData={this.state.dataParam.curriculumsData}
                    sendDataToParent={this.getDataFromSubjectSelect}
                    teacherId={-1}
                    studentId={this.state.studentId}
                />
            </div>
        )
    }


    schoolChange = (e) => {

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                school: e.target.value
            }
        })
    }

    createPartA = () => {
        return (
            <div className="student-part-a-component marginBottom30">
                <h1 className="studentreg-font-sz-24 blue-font-color"><TrasSchool /></h1>
                <div className='student-single-input-div'>
                    <input type='text' id='school' className='student-single-input' name='school' onChange={this.schoolChange} value={this.state.paramState.school} />
                </div>
            </div>
        )
    }



    openStudentTermsAndCondition = (e) => {
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                isReadStudentTermsAndCondition: e.target.checked
            }
        }, () => {
            const url = 'https://goo.gl/4hic7e';
            window.open(url, '_blank');
        })
    }


    doValidationCheck = () => {
        let valid = true;

        let msg = '';
        if (this.state.paramState.school === '') {
            msg = '학교를 입력해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }
        if (this.state.paramState.subjects.length === 0) {
            msg = '관심 과목을 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }
        if (this.state.paramState.about_myself === '') {
            msg = '자기 소개를 적어주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }
        if (this.state.paramState.location === '') {
            msg = '위치를 입력해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }
        if (this.state.paramState.locationMsg === '') {
            msg = '자세한 위치를 입력해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }
        if (this.state.paramState.contact.phone === '') {
            msg = '전화번호를 적어주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }
        // if(this.state.paramState.contact.kakao === ''){
        //     msg = '전화번호와 카톡을 적어주세요.';
        //     showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        //     return false;
        // }
        if (!this.state.paramState.isReadStudentTermsAndCondition) {
            msg = '그루미 학생/학부모 이용약관을 체크해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }
        if (!this.state.isEdit) {
            if (!this.state.paramState.isRegister) {
                msg = '정상 경로로 들어오지 않아 등록이 되지 않습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return false;
            }
        }

        return valid;
    }


    studentRegSubmit = async () => {

        const valid = this.doValidationCheck();
        if (!valid) return;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                isSubmitting: true
            }
        })

        const tempSubject = this.state.paramState.subjects;
        const subjects = [];
        for (let i = 0; i < tempSubject.length; i++) {
            subjects.push(tempSubject[i]['keyInOrigin']);
        }

        const school = this.state.paramState.school;
        const location = this.state.paramState.location;
        const locationMsg = this.state.paramState.locationMsg;
        const secondLocation = this.state.paramState.secondLocationmsg;
        const secondLocationMsg = this.state.paramState.secondLocationmsg;
        const isRegister = Number(this.state.paramState.isRegister);
        const lang = this.state.lang;
        const about_myself = this.state.paramState.about_myself;


        const postData = {
            school: school,
            location: location,
            locationMsg: locationMsg,
            secondLocation: secondLocation,
            secondLocationMsg: secondLocationMsg,
            isRegister: isRegister,
            lang: lang,
            about_myself: about_myself,
            recommender: this.state.paramState.recommender,
        }


        const contact = this.state.paramState.contact;
        const contactKeys = Object.keys(contact);
        let nestedC = '';
        for (let i = 0; i < contactKeys.length; i++) {
            nestedC += `&contact[${contactKeys[i]}]=${contact[contactKeys[i]]}`;
        }
        const formData = new FormData();
        formData.append('frameType', 'React');

        const textSubjects = subjects.join('$') + '$';

        const allUrls = await GetAjaxURL();
        const profileUrl = allUrls.profile;
        const sendUrl = profileUrl + '?' + queryString.stringify(postData) + nestedC + '&subjects=' + textSubjects;

        const profileRes = await axios.post(sendUrl, formData, postConfig).catch((err) => { return console.log(err) });

        if (!profileRes) return showNotify('서버에서 에러가 발생했습니다. (1)', STATE_NOTIFY.TYPE_NEGATIVIE);

        if (profileRes.data.status === 'success') {
            let msg = '';
            if (this.state.isEdit) {
                msg = lang === 'ko' ? '업데이트가 완료됐습니다.' : lang === 'en' ? 'Update completed!' : '修改成功,即将前往个人中心'
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
                return window.location.href = '/#/students/dashboard';
            } else {
                msg = lang === 'ko' ? '회원가입에 성공하였습니다!' : lang === 'en' ? 'Registration Success' : '注册成功,即将前往个人中心';
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
                return window.location.href = '/#/';
            }


        } else {
            let msg = lang === 'ko' ? '서버에 문제가 발생했습니다. (2)' : lang === 'en' ? 'Something went wrong!' : '出了一些问题';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                isSubmitting: false
            }
        })

    }

    changeRecommender = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                recommender: value
            }
        });
    }


    createRecommendBox = () => {
        return (
            <div>
                <h1 className="tutorreg-font-sz-24 blue-font-color">추천인</h1>
                <input className="form-control" placeholder="추천인의 이메일을 입력해주세요" onChange={this.changeRecommender} value={this.state.paramState.recommender} type="text" />
            </div>
        )
    }
    createPartB_H = () => {
        return (
            <div className="col-md-8 col-md-offset-2">

                {/* <p style={{color: "red", textAlign: "center"}}>{this.state.errorMessage}</p> */}

                <div className='tutorreg-submit-btn'>
                    {
                        this.state.paramState.isSubmitting ?
                            <button className="center-block btn blue-color tutorreg-btn-sub tutorreg-btn-fix fontsz17"><font
                                className="loading"><TrasSubmitting /></font>
                            </button>
                            :
                            <button className="btn center-block tutorreg-btn-sub tutorreg-btn-fix fontsz17"
                                onClick={this.studentRegSubmit}
                            >
                                <TrasSubmit />
                            </button>
                    }
                </div>

            </div>
        )
    }

    createPartSS = () => {
        return (
            <div className='students-register-agreement'>
                <label className="container-check tutorreg-font-sz-14">
                    <input type="checkbox" id="" name="" onChange={this.openStudentTermsAndCondition} />
                    <span><i className='fontsz14 colorBl'>그루미 학생/학부모 이용약관</i></span>을 읽었으며 동의합니다.
                    <span className="default-checkmark"></span>
                </label>
            </div>
        )
    }


    createHtml = () => {
        return (
            <div className='studentreg-html'>
                {this.createPartA()}

                {
                    !this.state.isAjaxDone ?
                        ''
                        :
                        this.createPartB()
                }

                {this.createPartC()}
                {this.createPartD()}

                <br />
                {this.createPartB_D()}
                <br />

                {this.createPartE()}
                {
                    !this.state.isEdit ?
                        this.createPartSS()
                        :
                        ''
                }
                {this.createRecommendBox()}
                {this.createPartB_H()}
            </div>
        )
    }

    render() {
        return (
            <div className='default-wrapper studentreg-wrapper'>
                {this.createNav()}
                {this.createHtml()}
            </div>
        )
    }

}

export default studentreg;