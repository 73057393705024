import React, { Component } from 'react';
import {
    TrasHome
} from '../../Translate/EachTranslateC';

import ReactTooltip from 'react-tooltip'
import './admissions_consulting_korea.css';

class admissions_consulting_korea extends Component {


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/admissions_consulting">입시 컨설팅</a></li>
                    <li className="active">국내</li>
                </ol>
            </div>
        )
    }


    createSecondPart = () => {
        return (
            <div
                className="admissionUs-banner"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "url('/images/background/bg-seoul-univ.jpg')",
                    backgroundPosition: "center"
                }}>
                <div className="admissionUs-banner-box">
                    <div className="admissionUs-banner-content text-center">
                        <h2 className="boldest-font white-font-color">
                            국내 대입
                        </h2>
                        <h4 className="normal-font white-font-color">
                            특례 3년, 특례 12년, 외국고, 수시, 국제학교, 의대, 재외국민, 외국인
                        </h4>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }


    createThirdPart = () => {
        return (
            <div className="container text-center">
                <div className="row">
                    <div className="col-sm-12 margin-top-30">
                        <h2 className="color-gurume-blue bold-font">대입 컨설팅/멘토링 분야
                        </h2>
                        <p>아래는 대입에 필요할 수 있는 모든 주요 컨설팅/멘토링 분야입니다. 지원하는 대학과 전공 과목에 따라, 필요한 분야가 다를 수 있습니다.</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <div className="col-md-12 margin-10 border-gray-1 border-radius-4">
                            <h2 className="bold-font padding-top-15">상담</h2>
                            <table className="table table-striped margin-top-30 margin-bottom-10">
                                <tbody>
                                    <tr>
                                        <td><h5>국내 특례 대입 과정 전반에 대한 설명</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>전문 컨설턴트의 학생 스펙 분석과 부모님 요구에 맞춘 학교 및 학과 선택</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>대학별 합격생들의 공인 성적/활동 분석</h5></td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="col-md-4 text-center">
                        <div className="col-md-12 margin-10 border-gray-1 border-radius-4">
                            <h2 className="bold-font padding-top-15">대학 선택</h2>
                            <table className="table table-striped margin-top-30 margin-bottom-10">
                                <tbody>
                                    <tr>
                                        <td><h5>대학 모집 요강의 분석을 통한 최신 경향성 파악</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>최신 5개년 입시 결과 기반의 6개 대학 및 지원 학과 추천</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>최대한으로 학생의 스펙과 부모님의 요구사항 반영</h5></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    <div className="col-md-4 text-center">
                        <div className="col-md-12 margin-10 border-gray-1 border-radius-4">
                            <h2 className="bold-font padding-top-15">자기소개서</h2>
                            <table className="table table-striped margin-top-30 margin-bottom-10">
                                <tbody>
                                    <tr>
                                        <td><h5>주제 잡는 것부터 자기소개서를 완성하는 것까지 4인의 전담 컨설턴트의 첨삭, 교정</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>학문적 관심사를 바탕으로 탄탄한 지원 동기 강조</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>체계적인 스케줄에 맞춘 각 대학별 첨삭/피드백/수정본 공유</h5></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    <div className="clear"></div>
                    <div className="col-md-4 text-center">
                        <div className="col-md-12 margin-10 border-gray-1 border-radius-4">
                            <h2 className="bold-font padding-top-15">지원 서류</h2>
                            <table className="table table-striped margin-top-30 margin-bottom-10">
                                <tbody>
                                    <tr>
                                        <td><h5>특례 입시는 해외대학 입시와 달리 복잡한 요구 지원 서류가 있으니 누락이 없도록 체계적 관리</h5></td>
                                    </tr>
                                    <tr className="">
                                        <td><h5>사전에 해외에서 공증 받아야 하는 서류 관리</h5></td>
                                    </tr>
                                    <tr className="">
                                        <td><h5>대학별 기한에 맞춘 공인성적 제출 관리</h5></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    <div className="col-md-4 text-center">
                        <div className="col-md-12 margin-10 border-gray-1 border-radius-4">
                            <h2 className="bold-font padding-top-15">포트폴리오</h2>
                            <table className="table table-striped margin-top-30 margin-bottom-10">
                                <tbody>
                                    <tr>
                                        <td><h5>학생의 강점과 지원대학의 경향성 분석을 통한 포트폴리오 구성</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>학생의 강점을 나타내어 최상의 결과를 만들 수 있도록 제공</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>교내/외 대회 상장, 성적, 교과외 활동 서류 포트폴리오화</h5></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    <div className="col-md-4 text-center">
                        <div className="col-md-12 margin-10 border-gray-1 border-radius-4">
                            <h2 className="bold-font padding-top-15">면접</h2>
                            <table className="table table-striped margin-top-30 margin-bottom-10">
                                <tbody>
                                    <tr>
                                        <td><h5>대학 및 학과별 최신 경향성 반영한 면접 이론 및 실전대비 모의면접 진행</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>일부 대학/학과에서 요구하는 면접 대비 수업 제공</h5></td>
                                    </tr>
                                    <tr>
                                        <td><h5>서울대, 카이스트, 의대, 치대, 수의대 별 지원 대학/학과 전공자 전문 지도</h5></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="clearfix"></div>
                        </div>
                    </div>

                    <div className="clear"></div>
                </div>
                <div className="clearfix margin-bottom-40"></div>
            </div>
        )
    }


    createForthPart = () => {
        return (
            <div className="parallax"
                style={{ backgroundSize: "cover", backgroundImage: "url('/images/background/doctors_on_operation.jpg')", backgroundPosition: "center center" }}>
                <div className="container ">
                    <div className="row">
                        <div className="col-md-12 text-center margin-top-50 margin-bottom-50">
                            <h2 className="color-white bold-font">대입 컨설팅 분야: 전문직 TRACK (의대/치대/수의대)</h2>
                            <h3 className="color-white margin-bottom-50">추가로 각 의대/치대/수의대 별 요구사항에 맞춘 각 지원 대학/학과 전공자의 기출 바탕 전문 면접
                                지도</h3>
                            <p className="color-white">*위와 같은 상담/학교 선정/포트폴리오/ 자기소개서 서비스 제공</p>

                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        )
    }

    createFifth = () => {
        return (
            <div className="container">
                <div className="row text-center margin-bottom-30">
                    <div className="col-sm-12 margin-top-30">
                        <h2 className="color-gurume-blue bold-font">권장 대입 로드맵</h2>
                    </div>
                    <div className="col-sm-3">
                        <div className="promotion-box no-border">
                            <h1><span className="glyphicon glyphicon-search color-gurume-blue" aria-hidden="true"></span></h1>
                            <h3><b>분석/상담</b></h3>
                            <p>
                                학생 분석<br />
                                    상담
                                </p>
                        </div>
                    </div>

                    <div className=" col-sm-3">
                        <div className="promotion-box no-border">
                            <h1><span className="glyphicon glyphicon-book color-gurume-blue" aria-hidden="true"></span></h1>
                            <h3><b>입시 전략/준비</b></h3>
                            <p>
                                대학/전공 선택<br />
                                    원시 일정<br />
                                    추천서

                                </p>
                        </div>
                    </div>

                    <div className=" col-sm-3">
                        <div className="promotion-box no-border">
                            <h1><span className="glyphicon glyphicon-pencil color-gurume-blue" aria-hidden="true"></span></h1>
                            <h3><b>원서 작성/제출</b></h3>
                            <p>
                                원서 준비 스케줄 확정<br />
                                    에세이 작성<br />
                                    원서 제출<br />
                                    인터뷰
                                </p>
                        </div>
                    </div>

                    <div className=" col-sm-3">
                        <div className="promotion-box no-border">
                            <h1><span className="glyphicon glyphicon-list-alt color-gurume-blue" aria-hidden="true"></span></h1>
                            <h3><b>결과 발표 후 관리</b></h3>
                            <p>
                                입시 결과 현황 관리<br />
                                    대기
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createLastPart = () => {
        return (
            <div
                className="parallax position-relative"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "url('/images/background/consulting_enquiry.jpg')",
                    backgroundPosition: "center center"
                }}>
                <div className="admissionUs-ment">
                    <div className="admissionUs-ment-box text-center">
                        <div className="admissionUs-ment-content">
                            <span
                                className="admission-apply"
                                data-tip="data-tip"
                                data-for='tooltipEx'
                                data-event='click focus'>
                                <button
                                    className="admissionUs-apply coming-soon-btn border-5 padding-top-30 padding-bottom-30 padding-left-60 padding-right-60 boldest-font white-font-color no-margin tooltip-h2">
                                    상담신청
                                </button>
                                <ReactTooltip
                                    effect="solid"
                                    place="right"
                                    id='tooltipEx'
                                    type='light'
                                    globalEventOff='click'>
                                    <span className='vd-tooltip-ment'>
                                        <span>그루미&nbsp;
                                        </span>
                                        <span>
                                            <a href='/#/contact_us' className='blue-font-color'>
                                                고객 센터</a>
                                        </span>
                                        <span>에 연락해 주세요.</span>
                                    </span>
                                </ReactTooltip>
                            </span>
                        </div>
                        <div className="color-overlay"></div>
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                {this.createSecondPart()}
                <div className='academy-wapper-c'>
                    {this.createThirdPart()}
                </div>
                {this.createForthPart()}
                <div className='academy-wapper-c'>
                    {this.createFifth()}
                </div>
                {this.createLastPart()}

            </div>
        )
    }

    render() {
        return (
            <div>
                {this.createHtml()}
            </div>
        )
    }
}

export default admissions_consulting_korea;