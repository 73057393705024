import React, {Component} from 'react';
import {TrasHome, TrasAboutGuruMe, TrasRecruiting} from '../../Translate/EachTranslateC';

/* react slick */
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './career.css';

//*** HELLENA ***//
class career extends Component {

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li>
                        <a href="/#/"><TrasHome/></a>
                    </li>
                    <li>
                        <a href="/#/about_gurume"><TrasAboutGuruMe/></a>
                    </li>
                    <li className="active"><TrasRecruiting/></li>
                </ol>
            </div>
        )
    }

    createFirstPart = () => {
        return (
            <div className="career">
                <div className="career-video">
                    <div className="career-video-box">
                        <div className="career-video-content">
                            <div className="career-video-item">
                                <a data-toggle="modal" data-target="#memberModal">
                                    <i className="fa fa-play-circle-o career-video-play-button"></i>
                                </a>
                                <div
                                    className="modal"
                                    id="memberModal"
                                    tabIndex="-1"
                                    role="dialog"
                                    aria-labelledby="memberModalLabel"
                                    aria-hidden="true">
                                    <div
                                        className="modal-dialog"
                                        style={{
                                            maxWidth: "80%"
                                        }}>
                                        <div className="modal-content">
                                            <div className="modal-body">
                                                <button
                                                    type="button"
                                                    className="close pull-right margin-bottom-10"
                                                    data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <div className="videowrapper">
                                                    <iframe
                                                        className="embed-responsive-item"
                                                        src="https://www.youtube.com/embed/9EVIqBYSyrU"
                                                        frameBorder="0"
                                                        allow="autoplay; encrypted-media"
                                                        allowFullScreen="allowFullScreen"></iframe>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createSecondPart = () => {
        return (
            <div className="greybackground">
                <div className="career-ment">
                    <div className="career-ment-box text-center">
                        <div className="career-ment-content">
                            <h1
                                style={{
                                    paddingTop: "100px"
                                }}>"목표, 역량, 책임감 있는 인재끼리 자유롭게 일하여 성과를 내고 성장합니다."</h1>
                            <a href="/#/about_gurume">
                                <button
                                    className="career-btn"
                                    style={{
                                        margin: "50px 0px 100px 0px"
                                    }}>그루미 팀원</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createThirdPart = () => {
        return (
            <div className="greybackground">
                <div className="career-intro">
                    <h1
                        className="text-center"
                        style={{
                            paddingTop: "50px"
                        }}>우리는 일하면서 세가지를 중요시 합니다</h1>
                    <div
                        className="career-intro-box text-center"
                        style={{
                            padding: "50px 0px 50px 0px"
                        }}>
                        <div className="career-intro-content">
                            <div className="career-intro-item">
                                <div className="card text-center">
                                    <img
                                        className="card-img-top"
                                        src="/images/about-us/gurume_class.png"
                                        alt="Card image cap"
                                        style={{
                                            maxWidth: "100%"
                                        }}/>
                                    <div
                                        className="bg-gurume-blue"
                                        style={{
                                            backgroundColor: "blue",
                                            margin: "0px -10px 0px -10px",
                                            padding: "5px 0px 5px 0px"
                                        }}>
                                        <h4
                                            style={{
                                                color: "white"
                                            }}>자율성</h4>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Independence"<br/>
                                            개개인이 생각하는 효율적인 환경 속에서 각자의 업무방식으로 업무를 진행합니다. 그루미 팀이 다함께 목표 달성을 위해 주도적으로 각자의 역량을
                                            기여합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="career-intro-item">
                                <div className="card text-center">
                                    <img
                                        className="card-img-top"
                                        src="/images/about-us/gurume_contents.png"
                                        alt="Card image cap"
                                        style={{
                                            maxWidth: "100%"
                                        }}/>
                                    <div
                                        className="bg-gurume-blue"
                                        style={{
                                            backgroundColor: "blue",
                                            margin: "0px -10px 0px -10px",
                                            padding: "5px 0px 5px 0px"
                                        }}>
                                        <h4
                                            style={{
                                                color: "white"
                                            }}>효율성/성장</h4>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Efficiency/Growth"<br/>
                                            조직의 성장과 개인의 성장을 모두 지향합니다. 최상의 업무 능률을 위해 필요한 소프트웨어/하드웨어 그리고 스킬 교육을 지원합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="career-intro-item">
                                <div className="card text-center">
                                    <img
                                        className="card-img-top"
                                        src="/images/about-us/gurume_consulting.png"
                                        alt="Card image cap"
                                        style={{
                                            maxWidth: "100%"
                                        }}/>
                                    <div
                                        className="bg-gurume-blue"
                                        style={{
                                            backgroundColor: "blue",
                                            margin: "0px -10px 0px -10px",
                                            padding: "5px 0px 5px 0px"
                                        }}>
                                        <h4
                                            style={{
                                                color: "white"
                                            }}>열정/소통</h4>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">
                                            "Passion/Communication"<br/>
                                            주기적인 업무, 성과 및 새로운 서비스를 위한 다양한 아이디어를 공유하는 시간을 가집니다. 수평적인 구조에서 모두가 의사결정권자로서 팀원들과
                                            공유와 협의를 통해 끊임없이 창의적인 솔루션을 만들어나갑니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createForthPart = () => {
        return (
            <div className="career-office">
                <div className="career-office-box text-center">
                    <div className="career-office-content">
                        <div className="career-office-content-title">
                        <h1
                            className="text-center"
                            style={{
                                paddingTop: "50px"
                            }}>일할 때 힘이 나는 심플하고 쾌적하고 아름다운 사무실에서 일을 하고 있습니다.
                            </h1>
                            </div>
                        <h4
                            className="text-center"
                            style={{
                                padding: "50px 0px 0px 0px"
                            }}>(개인용 책상 외에 다양한 업무 공간에서 일을 합니다.)</h4>
                        <div className="career-office-content-item">
                            <div
                                className="career-office-item"
                                style={{
                                    paddingBottom: "15px"
                                }}>
                                <div className="width-100 position-relative">
                                    <img src="/images/about-us/career/room.jpg" className="width-100"/>
                                    <div className="bottom-black-overlay">
                                        <h4 className="text-center">룸 - 일에 집중할 수 있는 개인 공간</h4>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="career-office-item"
                                style={{
                                    paddingBottom: "15px"
                                }}>
                                <div className="width-100 position-relative">
                                    <img src="/images/about-us/career/sofa.jpg" className="width-100"/>
                                    <div className="bottom-black-overlay">
                                        <h4 className="text-center">공용 소파/테이블간</h4>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="career-office-item"
                                style={{
                                    paddingBottom: "15px"
                                }}>
                                <div className="width-100 position-relative">
                                    <img src="/images/about-us/career/rooftop.jpg" className="width-100"/>
                                    <div className="bottom-black-overlay">
                                        <h4 className="text-center">루프탑</h4>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <h4
                            className="text-center"
                            style={{
                                padding: "30px 0px 0px 0px"
                            }}>(우리 사무실만의 특별함을 누립니다.)</h4>
                        <div
                            className="career-office-content-item"
                            style={{
                                padding: "0px 0px 50px 0px"
                            }}>
                            <div
                                className="career-office-item"
                                style={{
                                    paddingBottom: "15px"
                                }}>
                                <div className="width-100 position-relative">
                                    <img src="/images/about-us/career/gangnam_view.jpg" className="width-100"/>
                                    <div className="bottom-black-overlay">
                                        <h4 className="text-center">룸, 루프탑에서 즐길 수 있는 강남 전경</h4>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="career-office-item"
                                style={{
                                    paddingBottom: "15px"
                                }}>
                                <div className="width-100 position-relative">
                                    <img src="/images/about-us/career/siesta.jpg" className="width-100"/>
                                    <div className="bottom-black-overlay">
                                        <h4 className="text-center">졸릴 땐 짧고 굵게 낮잠자고 다시 시작</h4>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="career-office-item"
                                style={{
                                    paddingBottom: "15px"
                                }}>
                                <div className="width-100 position-relative">
                                    <img src="/images/about-us/career/standing.jpg" className="width-100"/>
                                    <div className="bottom-black-overlay">
                                        <h4 className="text-center">앉아서만 일하는게 아닌 서서 일하기</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="career-office-item">
                                <div className="width-100 position-relative">
                                    <img src="/images/about-us/career/gangnam_view.jpg" className="width-100"/>
                                    <div className="bottom-black-overlay">
                                        <h4 className="text-center">유동적인 출퇴근 및 원격 근무 제도</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="career-office-item">
                                <div className="width-100 position-relative">
                                    <img src="/images/about-us/career/siesta.jpg" className="width-100"/>
                                    <div className="bottom-black-overlay">
                                        <h4 className="text-center">동료들과의 한국어, 영어 및 중국어 소통</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    createLastPart = () => {
        let settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1        };
        return (
            <div className="greybackground">
                <div className="career-waiting">
                    <div className="career-waiting-box text-center">
                        <div className="career-waiting-title">
                            <h1
                                style={{
                                    paddingTop: "50px"
                                }}>우리는 이런 분들을 기다리고 있습니다.</h1>

                            <br/>
                            <div className="career-waiting-content">
                                <Slider {...settings}>
                                    <div className="career-waiting-item">
                                        <p>
                                            <span>“</span>교육+IT 산업에 관심 있고 자신이 일하고 싶은 분야를 명확히 알며, 회사와 이를 조율할 줄 아는 인재</p>
                                    </div>
                                    <div className="career-waiting-item">
                                        <p>
                                            <span>“</span>본인이 직접 기획하고 진행한 프로젝트와 서비스에 자부심을 가지고 팀과 시너지를 낼 수 있는 인재</p>
                                    </div>
                                    <div className="career-waiting-item">
                                        <p>
                                            <span>“</span>성장하고 있는 스타트업이 100명 이상 규모의 회사로 발전하는 데 공헌하고 싶은 열정적인 인재</p>
                                    </div>
                                </Slider>
                            </div>

                            {/* <script>
                                $('.owl-carousel').owlCarousel({
                                    loop:true,
                                    margin:10,
                                    nav:true,
                                    dots: false,
                                    responsive:{
                                        0:{
                                            items:1
                                        },
                                        600:{
                                            items:1
                                        },
                                        1000:{
                                            items:1
                                        }
                                    }
                                })
                            </script> */
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                {this.createFirstPart()}
                {this.createSecondPart()}
                <img
                    src="images/about-us/career/collaboration.jpg"
                    style={{
                        maxWidth: "100%"
                }}/> 
                {this.createThirdPart()}
                {this.createForthPart()}

                {this.createLastPart()}

            </div>
        )
    }

    render() {
        return (
            <div className="career-wrapper default-wrapper">
                {this.createHtml()}
            </div>
        )
    }

}

export default career;