import React, { Component } from 'react';
import { withRouter } from 'react-router';
import CKEditor from 'ckeditor4-react';
import * as CK_KOR from 'ckeditor4-react';
import * as CK_EN from 'ckeditor4-react';
import * as CK_CN from 'ckeditor4-react';

import { GetAjaxURL, GetAjaxURLFileUpload, postConfig } from '../../../config/AjaxConfig';
import { fileConfig } from '../../../config/other';
import {
    TrasHome,
    TrasBlog,
    TrasWrite,
    TrasAuthor,
    TrasBlogCategory,
    TrasYoutube,
    TrasLanguage,
    TrasTitle,
    TrasContent,
    TrasIntroductoryParagraph,
    TrasThumbnailPhoto,
    TrasPosting,
    TrasPost,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { getEnvironment } from '../../../config/AjaxConfig';

//share function
import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';
import { isAdmin } from '../../extra/utils';

//css
import './blogs.css';

// Util Components
import ImageUploader from '../../utils/ImageUploader';

//window
const $ = window.jQuery;
const confirm = window.confirm;

class Blogedit extends Component {
    state = {
        lang: window.localStorage.lang || 'ko',
        blogId: '',
        blogType: 'new',
        catalogueList: [],
        selectCatalogue: {},
        selectSubCatalogue: {},
        chooseCatalogueArr: [],
        selectSubCatalogueId: [],
        authorList: [],
        authorId: '',
        userIsAdmin: false,
        onPosting: false,
        onUploading: false,
        onUploaded: false,
        needToUploadServerBtn: false,
        originalImg: '',
        youtubeVideoUrls: '',
        languageType: 3,
        enTag: '',
        content_en: '',
        enIntro: '',
        cnTag: '',
        content_cn: '',
        cnIntro: '',
        krTag: '',
        content_kr: '',
        krIntro: '',
        photo: '',
        imageName: '',
        imageBase64: '',
        isDoneAjax: false,
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.location.reload();
        }
        return true;
    }

    componentWillUnmount = (e) => {
        const { lang } = this.state;
        let confirmLeavingMessage = ('cn' === lang ? '您确定要离开此页面么' : 'en' === lang ? 'Are you sure you want to leave this page?' : '편집한 내용이 있습니다. 이동하시겠습니까?');
        // confirm(confirmLeavingMessage);

        // // todo
        // if ($scope.validationForm.$dirty) {
        //     if (confirm(confirmLeavingMessage)) {
        //     //     // Just leave to find somewhere you want to arrive~
        //     } else {
        //     //     // e.preventDefault();
        //     }
        // }

    }

    componentDidMount = async () => {

        const blogId = this.props.match.params.blogid;
        await this.getIsLogin();
        await this.getUserInfo();
        await this.getCataloguesData();
        if (blogId) { // is edit page
            this.setState({
                blogType: 'edit',
                blogId: blogId
            })
            await this.getData(blogId, 'edit');
        } else {
            // blogs new
            this.setState({
                ...this.state,
                isDoneAjax: true
            }, () => {
            })
        }

    }

    getIsLogin = async () => {
        const { lang } = this.state
        const apiurls = await GetAjaxURL();
        const url = apiurls.isLogin;
        const res = await axios.get(url).catch((err) => { return err; });
        const data = res.data;
        if (data.isLogin === 'true') {
            if (data.type === 'student') {
                showNotify(lang === 'cn' ? '您不是老师' : lang === 'en' ? 'Only tutor can access.' : '선생님만 접근 가능합니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
                window.location.href = '/#/blogs'
            }
            this.setState({
                isLogin: true
            })
        } else {
            showNotify(lang === 'cn' ? '请登录' : lang === 'en' ? 'Please login.' : '로그인 후 이용가능합니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            this.goToLogin()
        }
    }

    getUserInfo = async () => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.apiThisUser;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;
        this.setState({
            userData: data,
            userDataId: data.user.id,
            userIsAdmin: isAdmin(data.user)
        });
        if (isAdmin(data.user)) {
            await this.getTutors();
        } else {
            // trick to put just logined 1 author to be array
            let list = [{ id: data.user.id, name: data.user.name }];
            this.setState({
                authorList: list,
                authorId: list[0].id
            })
        }
    }

    getCataloguesData = async (cid) => {
        const { lang } = this.state
        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.catalogues + '?lan=' + lang;
        const res = await axios.get(url).catch((err) => err)
        this.setState({
            catalogueList: res.data
        })
    }

    getTutors = async (currentid) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.apiTutors;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;
        // let author = data.filter(v=>v.id===currentid)
        // if(author.length<=0)author = data[0]
        this.setState({
            authorList: data,
            authorId: data[0].id
        })
    }

    getData = async (id, type = 'new') => {
        const { lang, userData, userIsAdmin } = this.state;

        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.index + '/' + id;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;
        // this.setState({
        //     originalImg: res.data.image
        // })
        // function init_image() {
        //     //cropit插件方法
        //     $('.image-editor').cropit({
        //         width: 250,
        //         height: 250,
        //         minZoom: 'fill',
        //         maxZoom: 2,
        //         initialZoom: 'min',
        //         exportZoom: 1,

        //         imageState: {
        //             src: $scope.picUrl // todo 不知道是啥
        //         }
        //     });
        // }

        // init_image();
        // $('#image-upload-form').submit(function () {
        //     // Move cropped image data to hidden input
        //     var imageData = $('.image-editor').cropit('export');
        //     $('.hidden-image-data').val(imageData);
        //     // Print HTTP request params
        //     var formValue = $(this).serialize();
        //     $.ajax({
        //         url: apiBaseUrl + 'blog/upload_background_image', // TODO
        //         xhrFields: {
        //             withCredentials: true
        //         },
        //         type: 'POST',
        //         data: formValue,
        //         success: function (data) {
        //             var index = data.lastIndexOf(":");
        //             var tmpStr = data.slice(index - 4, data.length - 2);
        //             //todo
        //             showNotify('cn' === lang ? "请将下列的地址复制到您的博客中：" + tmpStr :
        //                 'en' === lang ? 'Please copy the following address to your blog : ' + tmpStr : '다음 주소를 복사해주세요. : ' + tmpStr,
        //                 STATE_NOTIFY.TYPE_POSITIVE);

        //         },
        //         error: function (jqXHR) {
        //             $('#image-upload-btn').html('Upload');
        //         }
        //     });
        //     // Prevent the form from actually submitting, instead we use ajax
        //     return false;
        // });



        if (userData === undefined) return;

        let authorId = data.tutor_id;
        if (authorId !== userData.user.id && !userIsAdmin) {
            showNotify(lang === 'cn' ? '您无法编辑此博文' : lang === 'en' ? 'You can\'t edit this blog post.' : '해당 블로그 포스트를 편집하실 수 없습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            window.location.href = '/#/blogs'
        }

        // check languageType
        let languageType = 1
        if (data.title_kr) languageType = 3
        else if (data.title_cn) languageType = 2

        // check catalogue
        let cid = data.blog_catalogue_id
        if (cid) {
            let that = this
            cid.split('$').forEach(element => {
                that.chooseCatalogue(element)
            });
        }

        let onUploaded = false;
        if (data.image) {
            onUploaded = true;
        }


        this.setState({
            languageType: languageType,
            authorId: data.tutor_id,
            catalogues: data.catalogues,
            youtubeVideoUrls: data.youtube_embed_urls,
            // blogShowMessage: data,
            content_en: data.content_en,
            content_cn: data.content_cn,
            content_kr: data.content_kr,
            enTag: data.title_en,
            cnTag: data.title_cn,
            krTag: data.title_kr,
            enIntro: data.intro_en,
            cnIntro: data.intro_cn,
            krIntro: data.intro_kr,
            imageName: data.image.replace(/^.*[\\/]/, ''),
            imageBase64: data.image,
            onUploaded: onUploaded,
            isDoneAjax: true
        }, () => {
            this.props.RootBindingData.doLoading(false);
        })

    }

    chooseCatalogue = id => {
        let { chooseCatalogueArr, selectSubCatalogueId } = this.state

        if (chooseCatalogueArr[id] === undefined || chooseCatalogueArr[id].isSelected === false) {
            chooseCatalogueArr[id] = {};
            chooseCatalogueArr[id].isSelected = true;
            selectSubCatalogueId.push(id)
        } else {
            var indexInArr = selectSubCatalogueId.indexOf(id);
            selectSubCatalogueId.splice(indexInArr, 1);
            chooseCatalogueArr[id].isSelected = false;
        }
        this.setState({ chooseCatalogueArr, selectSubCatalogueId })
    }

    goToLogin = () => {
        window.location.href = '/#/login'
    }


    changeLanguageType = type => {
        // change language and clear title content intro
        this.setState({
            languageType: type,
            // enTag: '',
            // content_en:'',
            // enIntro: '',
            // cnTag:'',
            // content_cn:'',
            // cnIntro: '',
            // krTag: '',
            // content_kr:'',
            // krIntro: '',
        })
    }

    submitData = async () => {
        const { blogType, blogId, authorId, originalImg, selectSubCatalogueId, lang, imageName, onUploading, youtubeVideoUrls, enTag, content_en, enIntro, cnTag, content_cn, cnIntro, krTag, content_kr, krIntro } = this.state;

        // 벨리데이션 체크
        if (selectSubCatalogueId.length === 0) {
            let v_msg = '블로그 카테고리를 선택해주세요.';
            const blogCategory = document.getElementById('blogCategory');
            blogCategory.scrollIntoView();
            return showNotify(v_msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        };

        this.setState({
            onPosting: true
        })


        if (onUploading === true) {
            showNotify(lang === 'en' ? 'Please wait until image is uploaded' : lang === 'cn' ? '请确认图片已上传' : '이미지가 업로드 될때까지 기다려 주세요.', STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({
                onPosting: false
            })
            return;
        }

        let krTitle = krTag;
        let enTitle = enTag;
        let cnTitle = cnTag;

        let contentkr = content_kr;
        let contenten = content_en;
        let contentcn = content_cn;

        let krIntro2 = krIntro;
        let enIntro2 = enIntro;
        let cnIntro2 = cnIntro;

        let postData = $.param({
            catalogueId: selectSubCatalogueId.join('$'),
            author: authorId,
            youtubeEmbedUrls: youtubeVideoUrls,
            krTitle: krTitle,
            enTitle: enTitle,
            cnTitle: cnTitle,
            contentkr: contentkr,
            contenten: contenten,
            contentcn: contentcn,
            krIntro: krIntro2,
            enIntro: enIntro2,
            cnIntro: cnIntro2,
            image: imageName || originalImg
        });



        const apiurls = await GetAjaxURL();
        let url = apiurls.blogs.new;
        if (blogType === 'edit') url = apiurls.blogs.edit + '/' + blogId;
        const res = await axios.post(url, postData, postConfig).catch((err) => {
            showNotify(lang === 'en' ? 'Unknown Error - Please Refresh' :
                lang === 'cn' ? '未知错误－请刷新' : '알수없는 에러 - 새로고침 해주세요', STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({
                onPosting: false
            })
        });


        if (res.data.state === 'success') {
            showNotify(lang === 'cn' ? '发布成功' : lang === 'en' ? 'Edit Completed' : '수정되었습니다.', STATE_NOTIFY.TYPE_POSITIVE);
            window.location.href = '/#/blogs'
        }
    }

    changeValue = (key, value) => {
        this.setState({
            [key]: value
        }, () => {
        })
    }

    createCatalogue = () => {
        const { catalogueList, chooseCatalogueArr } = this.state
        return (
            <div id='blogCategory' className="row margin-bottom-20">
                {
                    catalogueList.map((catalogue, key) =>
                        <div className="col-md-12 margin-bottom-10" key={'catalogue' + key}>
                            <div className="blog-index-catalogue-name margin-bottom-5">{catalogue.catName}</div>
                            <div>
                                {
                                    (catalogue.detail || []).map((catalogueDetail, key) =>
                                        <button key={'catalogueDetail' + key}
                                            className={chooseCatalogueArr[catalogueDetail.id] && chooseCatalogueArr[catalogueDetail.id].isSelected === true ? 'blog-catalogue-btn blog-catalogue-btn-selected' : 'blog-catalogue-btn'}
                                            onClick={() => this.chooseCatalogue(catalogueDetail.id)}>
                                            {catalogueDetail.detailName}
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }



    CK_KR_Changer = (e) => {
        const value = e.editor.getData();
        this.setState({
            ...this.state,
            content_kr: value
        }, () => {
        })
    }
    CK_EN_Changer = (e) => {
        const value = e.editor.getData();
        this.setState({
            ...this.state,
            content_en: value
        }, () => {
        })
    }
    CK_CN_Changer = (e) => {
        const value = e.editor.getData();
        this.setState({
            ...this.state,
            content_cn: value
        }, () => {
        })
    }

    setTitleKr = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            krTag: value
        })
    }

    createTitleInput = () => {
        const { languageType } = this.state;
        return (
            <div className="row margin-bottom-20">
                <div className={['col-md-12', languageType === 3 ? '' : 'd-none'].join(' ')}>
                    <input className="form-control" value={this.state.krTag} onChange={(e) => { return this.setState({ ...this.state, krTag: e.target.value }) }} />
                </div>
                <div className={['col-md-12', languageType === 2 ? '' : 'd-none'].join(' ')}>
                    <input className="form-control" value={this.state.cnTag} onChange={(e) => { return this.setState({ ...this.state, cnTag: e.target.value }) }} />
                </div>
                <div className={['col-md-12', languageType === 1 ? '' : 'd-none'].join(' ')}>
                    <input className="form-control" value={this.state.enTag} onChange={(e) => { return this.setState({ ...this.state, enTag: e.target.value }) }} />
                </div>
            </div>
        )
    }


    createCKEdit = () => {
        const { languageType } = this.state;
        return (
            <div className="row margin-bottom-20">
                <div className={['col-md-12', languageType === 1 ? '' : 'd-none'].join(' ')}>
                    <CK_EN
                        key={'1'}
                        data={this.state.content_en}
                        config={{
                            // baseHref:[['/ckeditor/']],
                            language: [['en']],
                            filebrowserImageUploadUrl: `${getEnvironment()}/data/blog/upload`,
                            filebrowserUploadUrl: `${getEnvironment()}/data/blog/upload`,
                            height: 450,
                        }}
                        onChange={this.CK_EN_Changer}
                    // onChange={(e) => this.changeValue(langObj.contentKey, e.editor.getData())}
                    />
                </div>
                <div className={['col-md-12', languageType === 2 ? '' : 'd-none'].join(' ')}>
                    <CK_CN
                        key={'2'}
                        data={this.state.content_cn}
                        config={{
                            // baseHref:[['/ckeditor/']],
                            language: [['zh-cn']],
                            filebrowserImageUploadUrl: `${getEnvironment()}/data/blog/upload`,
                            filebrowserUploadUrl: `${getEnvironment()}/data/blog/upload`,
                            height: 450,
                        }}
                        onChange={this.CK_CN_Changer}
                    // onChange={(e) => this.changeValue(langObj.contentKey, e.editor.getData())}
                    />
                </div>
                <div className={['col-md-12', languageType === 3 ? '' : 'd-none'].join(' ')}>
                    <CK_KOR
                        key={'3'}
                        data={this.state.content_kr}
                        config={{
                            // baseHref:[['/ckeditor/']],
                            language: [['ko']],
                            filebrowserImageUploadUrl: `${getEnvironment()}/data/blog/upload`,
                            filebrowserUploadUrl: `${getEnvironment()}/data/blog/upload`,
                            height: 450,
                        }}
                        onChange={this.CK_KR_Changer}
                    // onChange={(e) => this.changeValue(langObj.contentKey, e.editor.getData())}
                    />
                </div>
            </div>
        )
    }

    IMAGE_CallbackFuc = (_urlData) => {
        this.setState({
            ...this.state,
            onUploading: false,
            onUploaded: true,
            imageName: _urlData
        })
    }

    createContentHtml = () => {
        const { userIsAdmin, authorId, youtubeVideoUrls, languageType, enTag, content_en, enIntro, cnTag, content_cn, cnIntro, krTag, content_kr, krIntro,
            imageBase64, onPosting, authorList } = this.state;
        let langObj = {}
        if (languageType === 1) {
            langObj.title = enTag
            langObj.titleKey = 'enTag'
            langObj.content = content_en
            langObj.contentKey = 'content_en'
            langObj.intro = enIntro
            langObj.introKey = 'enIntro';
            langObj.language = 'en';
        } else if (languageType === 2) {
            langObj.title = cnTag
            langObj.titleKey = 'cnTag'
            langObj.content = content_cn
            langObj.contentKey = 'content_cn'
            langObj.intro = cnIntro
            langObj.introKey = 'cnIntro';
            langObj.language = 'zh-cn';

        } else if (languageType === 3) {
            langObj.title = krTag
            langObj.titleKey = 'krTag'
            langObj.content = content_kr
            langObj.contentKey = 'content_kr'
            langObj.intro = krIntro
            langObj.introKey = 'krIntro'
            langObj.language = 'ko';

        }

        return (
            <div className="container blogedit-container">
                <div className="row">
                    <div className="col-md-10 margin-bottom-10 mx-auto">
                        {/* <form name="validationForm"> */}
                        <h5 className="blue-font-color"><TrasBlogCategory /></h5>
                        {this.createCatalogue()}
                        <h5 className="blue-font-color"><TrasAuthor /> (개인 프로필 아이디 선택)</h5>
                        <div className="row margin-bottom-20">
                            <div className="col-md-12 col-sm-5 col-md-4">
                                <select disabled={!userIsAdmin} className="form-control"
                                    onChange={(e) => this.changeValue('authorId', e.target.value)}
                                    value={authorId}>
                                    {
                                        authorList.map((author, key) => <option key={'author' + key} value={author.id}>{author.id + ')' + author.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <h5 className="blue-font-color"><TrasYoutube /></h5>
                        <div className="row margin-bottom-20">
                            <div className="col-md-12">
                                <input className="form-control" type="text" value={youtubeVideoUrls}
                                    onChange={(e) => this.changeValue('youtubeVideoUrls', e.target.value)}
                                    placeholder="https://www.youtube.com/embed/PEuDNiH02Uw" />
                            </div>
                        </div>
                        <h5 className="blue-font-color"><TrasLanguage /> (언어중 하나를 써주시면 나머지는 그루미에서 번역해서 업데이트)</h5>
                        <div className="row margin-bottom-20">
                            <div className="col-md-4">
                                <button className={languageType === 1 ? 'btn submit-btn' : 'btn cancel-btn'} onClick={() => this.changeLanguageType(1)}>
                                    English
                                    </button>
                            </div>
                            <div className="col-md-4">
                                <button className={languageType === 2 ? 'btn submit-btn' : 'btn cancel-btn'} onClick={() => this.changeLanguageType(2)}>
                                    中文
                                    </button>
                            </div>
                            <div className="col-md-4">
                                <button className={languageType === 3 ? 'btn submit-btn' : 'btn cancel-btn'} onClick={() => this.changeLanguageType(3)}>
                                    한국어
                                    </button>
                            </div>
                        </div>
                        <h5 className="blue-font-color"><TrasTitle /></h5>
                        {this.createTitleInput()}
                        {/* <div className="row margin-bottom-20">
                                <div className="col-md-12">
                                    <input className="form-control" value={langObj.title} onChange={(e) => this.changeValue(langObj.titleKey, e.target.value)}/>
                                </div>
                            </div> */}
                        <h5 className="blue-font-color"><TrasContent /></h5>

                        {
                            this.state.isDoneAjax ?
                                this.createCKEdit()
                                :
                                'Loading...'
                        }

                        <h5 className="blue-font-color"><TrasIntroductoryParagraph /> (썸네일에 나오는 글)</h5>
                        <div className="row margin-bottom-20">
                            <div className="col-md-12">
                                <textarea rows="4" className="form-control resizenone"
                                    value={langObj.intro} onChange={(e) => this.changeValue(langObj.introKey, e.target.value)}></textarea>

                            </div>
                        </div>
                        <h5 className="blue-font-color"><TrasThumbnailPhoto /> (800x500 추천)</h5>
                        <div className="row margin-bottom-20">
                            <div className="col-md-6">

                                {
                                    this.state.blogType === 'new' ?
                                        <React.Fragment>
                                            <h5 className="blue-font-color">이미지</h5>
                                            <ImageUploader
                                                autoLoadingDataUrl={this.state.imageBase64}
                                                IMAGE_CallbackFuc={this.IMAGE_CallbackFuc}
                                                formType={'blog'}
                                            />
                                        </React.Fragment>
                                        : this.state.onUploaded ?
                                            <React.Fragment>
                                                <h5 className="blue-font-color">이미지</h5>
                                                <ImageUploader
                                                    autoLoadingDataUrl={this.state.imageBase64}
                                                    IMAGE_CallbackFuc={this.IMAGE_CallbackFuc}
                                                    formType={'blog'}
                                                />
                                            </React.Fragment>
                                            : ''
                                }

                            </div>
                        </div>
                        <div className="row margin-bottom-20">
                            {
                                onPosting ?
                                    <button className="btn btn-processing mx-auto" disabled><font className="loading"><TrasPosting /></font></button>
                                    :
                                    <button className="btn submit-btn mx-auto" onClick={() => this.submitData()}><TrasPost /></button>

                            }
                        </div>
                        {/* </form> */}
                    </div>
                </div>
            </div>
        )
    }

    createBreadcrumb = () => {
        return (
            <div className="container">
                <ol className="breadcrumb mooc-index-breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/blogs"><TrasBlog /></a></li>
                    <li className="active"><TrasWrite /></li>
                </ol>
            </div>
        )
    }

    render() {
        return (
            <div className="blogs-container">
                {this.createBreadcrumb()}
                {this.createContentHtml()}
            </div>
        )
    }
}

export default withRouter(Blogedit);
