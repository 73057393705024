import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NoImgContens } from '../../../../config/AjaxConfig';

import './TdMoocComponent.css';

const TdMoocComponentPropTypes = {
    tdGoodClassData: PropTypes.array,
    tdNameData: PropTypes.string,
}
const TdMoocComponentDefaultProps = {
    tdGoodClassData: [],
    tdNameData: '',
}

class TdMoocComponent extends Component {

    state = {
        tdData: [],
        displayData: '클라스 보기',
        isTheStateForMe: false,
    }


    clickToMove = () => {
        window.location.href = '/#/group_class/new';
    }

    addList = async () => {
        const tdMoocComState = this.props.tdGoodClassData;

        // new Date($scope.groupClassList[i].end_time) > new Date()

        const newTdState = [];
        for (let i = 0; i < tdMoocComState.length; i++) {
            const thumbnailUrl = tdMoocComState[i].thumbnail;
            const moocTitle = tdMoocComState[i].title;
            const moocId = tdMoocComState[i].id;
            const moocStartTime = tdMoocComState[i].start_time;
            const moocEndTime = tdMoocComState[i].end_time;
            let moocMsgs = '상시 수업중';
            let isForever = true;
            const tdNameData = this.props.tdNameData;

            if (new Date(moocEndTime) > new Date()) {
                moocMsgs = '종료';
                isForever = false;
            }

            newTdState.push({
                thumbnailUrl,
                moocTitle, moocId, moocStartTime, moocEndTime, moocMsgs, isForever,
                tdNameData
            });
        }

        const tdDatas = [];
        const otherTdDatas = [];
        for (let i = 0; i < newTdState.length; i++) {
            if (newTdState[i].isForever) {
                otherTdDatas.push(newTdState[i]);
            } else {
                tdDatas.push(newTdState[i]);
            }
        }

        while (otherTdDatas.length > 0) {
            const eachData = otherTdDatas.shift();
            tdDatas.push(eachData);
        }

        let isTheStateForMe = false;

        let displayData = '그룹 추가';
        if (tdDatas.length !== 0) {
            displayData = '클라스 보기';
            isTheStateForMe = true;
        }

        if (tdMoocComState.length === 0) {
            displayData = '클라스 보기';
            isTheStateForMe = true;
        }



        if (this.state.isTheStateForMe) {
            window.location.href = '/#/group_class/new';
        }

        this.setState({
            ...this.state,
            tdData: tdDatas,
            displayData: displayData,
            isTheStateForMe: isTheStateForMe
        })


    }



    clickToId = (e) => {
        const target = e.target;
        const data = target.dataset['moocId'];

        window.location.href = '/#/group_class/' + data + '/edit';
    }


    openTheLink = (e) => {
        const target = e.target;
        if (target.tagName === 'BUTTON') return;

        let data = target.dataset['moocId'];
        if (!data) {
            let count = 0;
            let currentTarget = target;
            while (!data) {
                currentTarget = currentTarget.parentNode;
                data = currentTarget.dataset['moocId'];
                count++;

                if (count > 10) break;
            }
        }

        window.location.href = '/#/group_class/' + data;
    }


    createTdGoodClassInnerComponent = () => {

        const li = [];

        const tdData = this.state.tdData;

        for (let i = 0; i < tdData.length; i++) {

            const moocTitle = tdData[i]['moocTitle'];
            const moocId = tdData[i]['moocId'];
            const thumbnailUrl = tdData[i]['thumbnailUrl'];
            const tdNameData = tdData[i]['tdNameData'];

            // const moocStartTime = tdData[i]['moocStartTime'];
            // const moocEndTime = tdData[i]['moocEndTime'];
            // const moocMsgs = tdData[i]['moocMsgs'];
            const isForever = tdData[i]['isForever'];

            let tempStr = '';
            let tempSpan = <span></span>;
            if (isForever) {
                tempStr =
                    <span>종료</span>;
            } else {
                tempSpan =
                    <span>상시 수업중</span>;
            }
            li.push(
                <li key={'_' + i} className='td-blog-edit' data-mooc-id={moocId} onClick={this.openTheLink}>
                    <div className='td-blog-inner-edit'>
                        <span><img src={`https://s3.ap-northeast-2.amazonaws.com/gurume-groupclass-thumbnail/${thumbnailUrl}`} alt=' ... ... ' onError={function (e) { e.target.src = NoImgContens; }} /></span>
                        {tempSpan}
                        <span>{moocTitle}</span>
                        <span>{tdNameData}</span>
                        {tempStr}
                        <button onClick={this.clickToId} data-mooc-id={moocId}>편집</button>
                    </div>
                </li>
            )
        }

        return (
            <div className='TdMoocComponent-wrapper'>
                {/* <button>
                    <span><i className="fa fa-plus-circle"></i></span>
                    <span onClick={this.clickToMove}>그룹수업 추가</span>
                </button> */}
                <div>
                    <ul className='stupid-computer'>
                        <li className='td-blog-list' onClick={this.addList}>
                            <span><i className="fa fa-plus-circle">({this.props.tdGoodClassData.length})</i></span>
                            <span>{this.state.displayData}</span>
                        </li>
                        {li}
                    </ul>
                </div>
            </div>
        )
    }

    createTdGoodClassComponent = () => {
        return (
            <div>
                {this.createTdGoodClassInnerComponent()}
            </div>
        )
    }

    render() {
        return (
            <div className='td-common-wrapper'>
                {this.createTdGoodClassComponent()}
            </div>
        )
    }

}

TdMoocComponent.propTypes = TdMoocComponentPropTypes;
TdMoocComponent.defaultProps = TdMoocComponentDefaultProps;

export default TdMoocComponent;