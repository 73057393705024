import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { NoImgContens } from '../../config/AjaxConfig';

import './online_tuition.css';
import Online_tuition_1 from '../../images/online-tuition/smooth-draw-thumbnail.jpg';
import Online_tuition_2 from '../../images/online-tuition/awwapp-thumbnail.jpg';
import Online_tuition_3 from '../../images/online-tuition/google-doc-thumbnail.jpg';

export default class Online_tuition extends Component {
    goToOnlineTuitionTouchpad = () => {
        window.location.href="/#/online_tuition/touchpad"
    }
    
    render() {
        return (
            <div className="online-tuition-wrapper">
                <div className="online-tuition-banner">
                    <div className="online-tuition-banner-box">
                        <div className="online-tuition-banner-main">
                            <h2 className="boldest-font font-size-44">
                                언제 어디서든 원하는 선생님과
                            </h2>
                            <h4>스카이프로 얼굴을 보며 온라인 칠판으로 필기하는 수업</h4>
                        </div>
                        <div className="color-overlay"></div>
                    </div>
                </div>

                <div className="online-tuition-content">
                    <div className="online-tuition-content-box">
                        <div className="online-tuition-title">
                            <h2>온라인 칠판이란</h2>
                            <h4>실시간으로 선생님과 학생이 필기, 공식, 그래프/표를 쓰고 공유</h4>
                        </div>
                        <div className="online-tuition-content-main" id="online-tuition-board">
                            <div className="online-tuition-subjects">
                                <h4>이과 (수학 & 과학)</h4>
                                <h4>문과 (에세이, 레포트, 자기소개서)</h4>
                            </div>
                            <div className="online-tuition-pics">
                                <div className="online-tuition-pics-box">
                                    <img src={Online_tuition_1} alt='Online_tuition_1' onError={function(e){e.target.src = NoImgContens;}.bind(this)} />
                                    <h3><b>Smooth Draw</b></h3>
                                    <p>
                                        선생님이 학생에게 직접 화면을 공유해야 하지만, 다양한 펜 색을 쉽게 선택할 수 있고, 검정색 배경을 써서 F쉽게 읽히기 때문에 집중력을 최대한으로 높여줍니다.
                                        </p>
                                    <br />
                                    <p><a href="http://smoothdraw.com/sd" target="_blank">@www.smoothdraw.com</a></p>
                                </div>
                                <div className="online-tuition-pics-box">
                                    <img src={Online_tuition_2} alt='Online_tuition_2' onError={function(e){e.target.src = NoImgContens;}.bind(this)}  />
                                    <h3><b>Awwapp</b></h3>
                                    <p>
                                        학생과 선생님이 같은 칠판에서 실시간으로 필기를 공유할 수 있습니다. 이용하기 위해 선생님은 칠판 접속 링크를 보내 학생을 초대하여야 합니다.
                                        </p>
                                    <br />
                                    <p><a href="http://www.awwapp.com" target="_blank">@www.awwapp.com</a></p>
                                </div>
                                <div className="online-tuition-pics-box">
                                    <img src={Online_tuition_3} alt='Online_tuition_3' onError={function(e){e.target.src = NoImgContens;}.bind(this)} />
                                    <h3><b>Google Doc</b></h3>
                                    <p>
                                        이용하기 위해 선생님은 문서 접속 링크를를 보내 학생을 초대하여야 합니다.
                                                                    <br />
                                        Google Document는 마이크로소프트 Words의 온라인 버전으로 생각하시면 되나 다양한 참여자들에게 공유가 되고 동시에 여러 참여자가 자유롭게 조회 및 편집을 할 수 있습니다.
                                                                    </p>
                                    <br />
                                    <p>More information<a href="http://www.google.com/docs/about" target="_blank"> @www.google.com/docs/about/</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="online-tuition-content-box">
                    <div className="online-tuition-title">
                        <h2>면대면 수업만큼의 효과</h2>
                    </div>
                    <div className="online-tuition-content-main">
                        <div className="online-tuition-concerned">
                            <h3>우려 사항</h3>
                            <p><i className="fa fa-question"></i> 공식/그래프/표 필기</p>
                            <p><i className="fa fa-question"></i> 교재/노트 참고</p>
                            <p><i className="fa fa-question"></i> 집중력</p>
                        </div>
                        <div className="online-tuition-solution">
                            <h3>해결 방법</h3>
                            <p><i className="fa fa-check"></i> 터치패드 혹은 터치스크린 기기 (예, 아이패드)</p>
                            <p><i className="fa fa-check"></i> 온라인 칠판에 PDF 교재 업로드 및 화면공유</p>
                            <p><i className="fa fa-check"></i> 검정 배경에 색별 필기를 통한 시각적 명확함</p>
                        </div>
                    </div>
                </div>
                <div className="online-tuition-content-box">
                    <div className="online-tuition-title">
                        <h2>면대면 수업보다 좋은 이유</h2>
                    </div>
                    <div className="online-tuition-content-main" id="online-tuition-reason">
                        <p>수업료 감축</p>
                        <p>이동시간 감축</p>
                        <p>위치 제약 없이 원하는 선생님과 수업 가능</p>
                    </div>
                </div>

                <div className="online-tuition-touchpad-banner">
                    <div className="online-tuition-touchpad-banner-box">
                        <div className="online-tuition-touchpad-banner-main">
                            <button onClick={this.goToOnlineTuitionTouchpad} className="online-tuition-touchpad-btn">터치패드</button>
                            <h4>
                                <i>"이과 수업을 듣는 학생에게 무료 제공 (경제 포함)"</i></h4>
                        </div>
                        <div className="color-overlay"></div>
                    </div>
                </div>

                <div className="online-tuition-content-main" id="online-tuition-demo">
                    <h4>
                        원하는 선생님과 
                        <span data-tip="data-tip" data-for='tooltipEx' data-event='click focus'>
                        <button className="gurume-blue-back-btn">무료 데모 수업</button>
                        <ReactTooltip
                                    effect="solid"
                                    place="top"
                                    id='tooltipEx'
                                    type='light'
                                    border={true}
                                    globalEventOff='click'>
                                    <span className='vd-tooltip-ment'>
                                        <span>그루미&nbsp;
                                        </span>
                                        <span>
                                            <a href='/#/contact_us' className='blue-font-color'>
                                                고객 센터</a>
                                        </span>
                                        <span>에 원하시는<br/>과목/선생님을 요청해주세요.</span>
                                    </span>
                                </ReactTooltip>
                                </span>
                        <br />
                        지금 신청하세요
                    </h4>
                </div>
            </div>
        )
    }
}