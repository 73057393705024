import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import {
    TrasHome,
    TrasBlog,
    TrasViewProfile,
    TrasBookmark,
    TrasBookmarked,
    TrasEdit,
    TrasComment,
    TrasWritecommentifyouhavequestions,
    TrasSubmit,
    TrasNeedtologintoleavecomment,
    TrasReply,
    TrasCancel,
    TrasDelete,
    TrasBack,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';

//share function
import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';
import { isAdmin, newDateFormat } from '../../extra/utils';
import { metaClass, applyMetaHeader } from '../../extra/metaController';

// css
import './blogs.css';

//window
const $ = window.jQuery;
class Blog extends Component {

    state = {
        lang: window.localStorage.lang || 'ko',
        cataloguesId: '',
        blogShowMessage: {},
        blogId: '',
        isLogin: false,
        userData: {},
        userDataName: '',
        userDataId: '',
        userDataPic: '',
        indexTmp: '',
        inIndexTmp: '',
        outindexTmp: '',
        foreditInIndexTmp: '',
        foreditIndex: '',
        editcommentOther: '',
        editsubcommentOther: '',
        educaBgArr: [],
        youtubeEmbedUrlArr: [],
        educaBgTmp: '',
        comments: [],
        commentContent: '',
        commentOther: '',
        subcommentOther: '',
        educationBy: '',
        userIsAdmin: false
    }


    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.location.reload();
        }
        return true;
    }


    componentDidMount = async () => {

        if (window.location.href.indexOf('index') !== -1) return;

        const blogId = this.props.match.params.blogid;
        if (blogId === 'new') return;

        await this.getIsLogin();
        await this.getData(blogId);
        this.setState({ blogId: blogId })
        this.setMetas();
        this.props.RootBindingData.doLoading(false);
    }

    setMetas = () => {
        const _type = '';
        const _title = this.state.blogShowMessage.title_kr;
        const _url = window.location.href;
        const _description = this.state.blogShowMessage.intro_kr;
        const _imageUrl = this.state.blogShowMessage.image;
        const _keywords = '';

        const newMetaClass = new metaClass(_type, _title, _url, _description, _imageUrl, _keywords);
        applyMetaHeader(newMetaClass);
    }

    getIsLogin = async () => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.isLogin;
        const res = await axios.get(url).catch((err) => { return err; })
        if (res.data.isLogin === 'true') {
            this.setState({
                isLogin: true
            }, async () => {
                await this.getUserInfo();
            })
        }
    }

    getUserInfo = async () => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.apiThisUser;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;
        this.setState({
            userData: data,
            userDataName: data.user.name,
            userDataId: data.user.id,
            userDataPic: data.profile.picture,
            userIsAdmin: isAdmin(data.user)
        });
    }

    getData = async (blogId) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.index + '/' + blogId;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;

        if (data.status === 'fail') window.location.href = '/#/blogs';

        // ngMeta.setTag("description", data.intro_kr);
        // ngMeta.setTag("imageUrl", data.image);

        let catalogues = data.catalogues;
        let cataloguesIdArr = [];
        //合并sub_catalogue
        let subCatalogue = { 'cn': [], 'en': [], 'kr': [] };
        //组合用$连接的分类Id
        for (let j = 0; j < catalogues.length; j++) {
            cataloguesIdArr.push(catalogues[j].id);
            subCatalogue['cn'].push(catalogues[j].sub_catalogue_cn)
            subCatalogue['en'].push(catalogues[j].sub_catalogue_en)
            subCatalogue['kr'].push(catalogues[j].sub_catalogue_kr)
        }
        // todo 这也是个全局属性
        // $scope.subCatalogueCn = subCatalogue['cn'].join(',#');
        // $scope.subCatalogueEn = subCatalogue['en'].join(',#');
        // $scope.subCatalogueKo = subCatalogue['kr'].join(',#');

        let educaBgTmp = [];
        let educaBg = data.tutor.education_bg;

        for (let m = 0; m < educaBg.length; m++) {
            if (educaBgTmp[educaBg[m].degree] !== undefined) {
                if (educaBg[m].nameOfMajor !== '') {
                    educaBgTmp[educaBg[m].degree].major.push(educaBg[m].nameOfMajor)
                } else {
                    educaBgTmp[educaBg[m].degree].major.push(educaBg[m].major)
                }
            } else {
                if (educaBg[m].nameOfMajor !== '') {
                    educaBgTmp[educaBg[m].degree] = {
                        "school": educaBg[m].school,
                        "degree": educaBg[m].degree,
                        "major": [educaBg[m].nameOfMajor]
                    }
                } else {
                    educaBgTmp[educaBg[m].degree] = {
                        "school": educaBg[m].school,
                        "degree": educaBg[m].degree,
                        "major": [educaBg[m].major]
                    }
                }
            }
        }
        let _educaBgArr = [];
        for (let key in educaBgTmp) {
            _educaBgArr.push(educaBgTmp[key])
        }
        this.setState({
            educaBgArr: _educaBgArr,
            educaBgTmp: educaBgTmp,
            comments: data.comments,
            blogShowMessage: data,
            youtubeEmbedUrlArr: data.youtubeEmbedUrls || [],
            cataloguesId: cataloguesIdArr.join('$')
        })

        // Get the modal
        $(":image").click(function () {
            let modal = document.getElementById('image-modal');
            let img = this;
            let modalImg = document.getElementById("modal-image");
            img.onclick = function () {
                modal.style.display = "block";
                modalImg.src = this.src;
            }
            let span = document.getElementsByClassName("close")[0];
            span.onclick = function () {
                modal.style.display = "none";
            }
        });

    }

    goToLogin = () => {
        window.location.href = '/#/login'
    }

    goToLoginRedirect = () => {
        window.location.href = '/#/login?redirect=blog_comment&blog_id=' + this.state.blogId;
    }

    editSubReplay = async (id, comment, index, commentArr) => {
        const { userData, userDataName, userDataId, userDataPic } = this.state

        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.editcomment + '/' + id;
        const postData = $.param({
            comment_content: comment
        })
        const res = await axios.post(url, postData, postConfig).catch((err) => { showNotify(err, STATE_NOTIFY.TYPE_NEGATIVIE) }) // todo
        if (res.data.status === 'success') {
            let tmpData = {
                "id": id,
                "comment_content": comment,
                // "name": "评论的人",
                "full_name": userData.user.last_name + userData.user.first_name,
                "user_id": userDataId,
                "name": userDataName,
                "time": new Date() / 1000,
                "picture": userDataPic,
                "is_this_user": 1,
                "sub_comments": []
            };
            commentArr.splice(index, 1, tmpData);
            this.setState({ foreditInIndexTmp: -1 })
        }
    }
    submitComment = async () => {
        const { commentContent, blogId, lang, userData, userDataName, userDataId, userDataPic, comments } = this.state

        if (undefined === commentContent || commentContent.length < 5) {
            showNotify('cn' === lang ? '必须输入至少5个字符才能发表评论。' :
                'en' === lang ? 'At least 5 characters must be entered to leave a comment.' : "5글자 이상 입력하셔야 덧글을 작성할 수 있습니다.", STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.newcomment;
        const postData = $.param({
            comment_content: commentContent,
            blog_id: blogId,
            father_comment_id: 0,
            reply_to_id: 0
        })
        const res = await axios.post(url, postData, postConfig).catch((err) => { showNotify(err, STATE_NOTIFY.TYPE_NEGATIVIE) })
        const data = res.data
        if (data.status === 'success') {
            let tmpData = {
                "id": data.id,
                "comment_content": commentContent,
                // "name": "评论的人",
                "user_id": userDataId,
                "full_name": userData.user.last_name + userData.user.first_name,
                "name": userDataName,
                "time": new Date() / 1000,
                "picture": userDataPic,
                "is_this_user": 1,
                "sub_comments": []
            }
                ;
            comments.unshift(tmpData);
            this.setState({
                comments,
                commentContent: ''
            })
            showNotify('cn' === lang ? '成功添加新评论' : 'en' === lang ? 'Successfully added new comment' : '덧글을 성공적으로 등록하였습니다.', STATE_NOTIFY.TYPE_POSITIVE);
        }
    }

    replay = async (fatherCommentId, replyToId, comment = '', subcomment) => {
        const { blogId, isLogin, lang, userData, userDataName, userDataId, userDataPic } = this.state

        if (isLogin === false) {
            this.goToLogin()
        } else {
            const apiurls = await GetAjaxURL();
            const url = apiurls.blogs.newcomment;
            const postData = $.param({
                comment_content: comment,
                blog_id: blogId,
                father_comment_id: fatherCommentId,
                reply_to_id: replyToId
            })
            const res = await axios.post(url, postData, postConfig).catch((err) => { showNotify(err, STATE_NOTIFY.TYPE_NEGATIVIE); })
            const data = res.data
            if (data.status === 'success') {
                let tmpData = {
                    "id": data.id,
                    "comment_content": comment,
                    // "name": "评论的人",
                    "full_name": userData.user.last_name + userData.user.first_name,
                    "user_id": userDataId,
                    "name": userDataName,
                    "time": new Date() / 1000,
                    "picture": userDataPic,
                    "is_this_user": 1,
                    "sub_comments": []
                };
                subcomment.unshift(tmpData);
                this.setState({
                    subcomment,
                    inIndexTmp: -1
                })
                showNotify('cn' === lang ? '成功添加新评论' : 'en' === lang ? 'Successfully added new comment' : '덧글을 성공적으로 등록하였습니다.', STATE_NOTIFY.TYPE_POSITIVE);
            }
        }
    }

    cancel = () => {
        this.setState({
            indexTmp: -1
        })
    }

    subReplay = async (fatherCommentId, replyToId, subcommentcontent = '', subcomment, replayToname) => {
        const { blogId, isLogin, lang, userData, userDataName, userDataId, userDataPic } = this.state
        if (isLogin === false) {
            // todo
            this.goToLogin()
        } else {
            const apiurls = await GetAjaxURL();
            const url = apiurls.blogs.newcomment;
            const postData = $.param({
                comment_content: subcommentcontent,
                blog_id: blogId,
                father_comment_id: fatherCommentId,
                reply_to_id: replyToId
            })
            const res = await axios.post(url, postData, postConfig).catch((err) => showNotify(err, STATE_NOTIFY.TYPE_NEGATIVIE)) // todo
            if (res.data.status === 'success') {
                let tmpData = {
                    "id": res.data.id,
                    "comment_content": '@' + replayToname + subcommentcontent,
                    "full_name": userData.user.last_name + userData.user.first_name,
                    // "name": "评论的人",
                    "user_id": userDataId,
                    "name": userDataName,
                    "time": new Date() / 1000,
                    "picture": userDataPic,
                    "is_this_user": 1,
                    "sub_comments": []
                };
                subcomment.push(tmpData);
                this.setState({
                    subcomment,
                    inIndexTmp: -1
                })
                showNotify('cn' === lang ? '成功添加新评论' : 'en' === lang ? 'Successfully added new comment' : '덧글을 성공적으로 등록하였습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                // $scope.commentOther='';
            }
        }
    }

    editMain = async (id, comment, index) => {
        const { comments, userData, userDataName, userDataId, userDataPic } = this.state

        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.editcomment + '/' + id;
        const postData = $.params({
            comment_content: comment
        })
        const res = await axios.post(url, postData, postConfig).catch((err) => { showNotify(err, STATE_NOTIFY.TYPE_NEGATIVIE) }) // todo
        if (res.data.status === 'success') {
            let tmpData = {
                "id": id,
                "comment_content": comment,
                // "name": "评论的人",
                "full_name": userData.user.last_name + userData.user.first_name,
                "user_id": userDataId,
                "name": userDataName,
                "time": new Date() / 1000,
                "picture": userDataPic,
                "is_this_user": 1,
                "sub_comments": []
            };
            comments.splice(index, 1, tmpData);
            this.setState({ comments, foreditIndex: -1 })
        }
    }
    eidtcancel = () => {
        this.setState({
            foreditIndex: -1
        })
    }
    showReplay = index => {
        const { isLogin } = this.state;
        if (isLogin) {
            this.setState({
                indexTmp: index
            })
        }
        else {
            this.goToLogin()
        }
    }

    deleteComment = async (id, index) => {
        const { lang, comments } = this.state

        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.deletecomment + '/' + id;
        const res = await axios.post(url).catch((err) => { showNotify(err, STATE_NOTIFY.TYPE_NEGATIVIE) })
        if (res.data.status === 'success') {
            showNotify('en' === lang ? 'Successfully deleted.' : 'cn' === lang ? '已成功删除' : '성공적으로 삭제하였습니다.', STATE_NOTIFY.TYPE_POSITIVE);
            comments.splice(index, 1)
            this.setState({ comments })
        }
    }

    showEdit = (index, comment) => {
        this.setState({
            foreditIndex: index,
            editcommentOther: comment
        })
    }

    subCancel = () => {
        this.setState({
            inIndexTmp: -1
        })
    }

    editSubcancel = () => {
        this.setState({
            foreditInIndexTmp: -1
        })
    }

    showSubReplay = (index, outindex) => {
        const { isLogin } = this.state;
        if (isLogin) {
            this.setState({
                inIndexTmp: index,
                outindexTmp: outindex
            })
        } else {
            this.goToLogin()
        }
    }

    deleteSubComment = async (id, index, subcomment) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.deletecomment + '/' + id;
        const res = await axios.post(url).catch((err) => {
            showNotify(err, STATE_NOTIFY.TYPE_NEGATIVIE)
        })
        if (res.data.status === 'success') {
            subcomment.splice(index, 1)
            this.setState({
                subcomment
            })
        }
    }
    showeditSub = (index, outindex, comment) => {
        this.setState({
            foreditInIndexTmp: index,
            outindexTmp: outindex,
            editsubcommentOther: comment
        })
    }

    trustSrc = (src) => src

    translate = str => str



    createCommentsHtml = () => {
        const { userIsAdmin, blogShowMessage, youtubeEmbedUrlArr, educaBgArr, educaBgTmp, isLogin, comments, commentContent, foreditIndex, indexTmp, commentOther,
            editcommentOther, subcommentOther, editsubcommentOther, blogId, foreditInIndexTmp, inIndexTmp, educationBy, outindexTmp,
            lang, userData } = this.state
        return (
            <div className="blogs-comments">
                {
                    comments.map((item, key) =>
                        <div key={'comment' + key} className="blogs-comment-item">
                            <div className="d-flex align-items-center">
                                <img alt="img" src={'../images/backuptutorpic.png'} className="comment-item-pic" />
                                <b className="margin-left-5">{item.full_name}</b>
                                <i className="margin-left-5">{newDateFormat(item.time * 1000)}</i>
                            </div>
                            {
                                foreditIndex !== key ?
                                    <p className="display-inlineb">{item.comment_content}</p> : ''
                            }
                            {
                                indexTmp === key ?
                                    <div>
                                        <textarea className="form-control" rows="3"
                                            value={commentOther}
                                            onChange={(e) => this.setState({ commentOther: e.target.value })}></textarea>
                                        <div className="clearfix">
                                            <button className="btn btn-blue-bg float-right margin-top-5 margin-left-10"
                                                onClick={() => this.replay(item.id, item.id, commentOther, item.sub_comments)}>
                                                <TrasReply />
                                            </button>
                                            <button className="btn btn-blue-bg float-right margin-top-5 margin-left-10"
                                                onClick={() => this.cancel()}><TrasCancel />
                                            </button>
                                        </div>

                                    </div>
                                    : ''

                            }
                            {
                                foreditIndex === key ?
                                    <div>
                                        <textarea className="form-control" rows="3"
                                            value={editcommentOther}
                                            onChange={(e) => this.setState({ editcommentOther: e.target.value })}>{editcommentOther}</textarea>
                                        <div className="clearfix">
                                            <button className="btn btn-blue-bg float-right margin-top-5 margin-left-10"
                                                onClick={() => this.editMain(item.id, editcommentOther, key)}>
                                                <TrasEdit />
                                            </button>
                                            <button className="btn btn-blue-bg float-right margin-top-5 margin-left-10"
                                                onClick={() => this.eidtcancel()}><TrasCancel />
                                            </button>
                                        </div>
                                    </div> : ''
                            }
                            {
                                indexTmp !== key && item.is_this_user !== 1 ?
                                    <span className="cursor float-right margin-left-5"
                                        onClick={() => this.showReplay(key)}><TrasReply />
                                    </span>
                                    : null
                            }
                            {
                                item.is_this_user === 1 ?
                                    <span className="cursor float-right margin-left-5"
                                        onClick={() => this.deleteComment(item.id, key)}><TrasDelete />
                                    </span>
                                    : null
                            }
                            {
                                item.is_this_user === 1 && foreditIndex !== key ?
                                    <span className="cursor float-right margin-left-5"
                                        onClick={() => this.showEdit(key, item.comment_content)}><TrasEdit />
                                    </span>
                                    : null
                            }
                            <div className="margin-left-40">
                                {
                                    item.sub_comments.map((subitem, k) =>
                                        <div key={'subcomment' + k}>
                                            <div className="d-flex align-items-center">
                                                <img alt="img" src={'../images/backuptutorpic.png'} className="comment-item-pic" />
                                                <b className="margin-left-5">{subitem.full_name}</b>
                                                <i className="margin-left-5">{newDateFormat(subitem.time)}</i>
                                            </div>
                                            {
                                                !(foreditInIndexTmp === k && outindexTmp === key) ?
                                                    <p className="display-inlineb">{subitem.comment_content}</p> : null
                                            }
                                            {
                                                inIndexTmp === k && outindexTmp === key ?
                                                    <div>
                                                        <textarea className="form-control" rows="3"
                                                            value={subcommentOther}
                                                            onChange={(e) => this.setState({ subcommentOther: e.target.value })}></textarea>
                                                        <div className="clearfix">
                                                            <button className="btn btn-blue-bg float-right margin-top-5 margin-left-10"
                                                                onClick={() => this.subReplay(item.id, subitem.id, subcommentOther, item.sub_comments, subitem.full_name)}>
                                                                <TrasSubmit />
                                                            </button>
                                                            <button className="btn btn-blue-bg"
                                                                onClick={() => this.subCancel()}><TrasCancel />
                                                            </button>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                foreditInIndexTmp === k && outindexTmp === key ?
                                                    <div>
                                                        <textarea className="form-control" rows="3"
                                                            value={editsubcommentOther}
                                                            onChange={(e) => this.setState({ editsubcommentOther: e.target.value })}>{editsubcommentOther}</textarea>
                                                        <div className="clearfix">
                                                            <button className="btn btn-blue-bg float-right margin-top-5 margin-left-10"
                                                                onClick={() => this.editSubReplay(subitem.id, editsubcommentOther, key, item.sub_comments)}>
                                                                <TrasEdit />
                                                            </button>
                                                            <button className="btn btn-blue-bg float-right margin-top-5 margin-left-10"
                                                                onClick={() => this.editSubcancel()}><TrasCancel />
                                                            </button>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                inIndexTmp !== k && subitem.is_this_user !== 1 ?
                                                    <span className="cursor float-right margin-left-5"
                                                        onClick={() => this.showSubReplay(k, key)}><TrasReply /></span> : null

                                            }
                                            {
                                                subitem.is_this_user === 1 ?
                                                    <span className="cursor float-right margin-left-5"
                                                        onClick={() => this.deleteSubComment(subitem.id, k, item.sub_comments)}><TrasDelete /></span>
                                                    : null

                                            }
                                            {
                                                subitem.is_this_user === 1 && !(foreditInIndexTmp === k && outindexTmp === key) ?
                                                    <span className="cursor float-right margin-left-5"
                                                        onClick={() => this.showeditSub(k, key, subitem.comment_content)}><TrasEdit /></span>
                                                    : null

                                            }
                                        </div>
                                    )}
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }

    createContentHtml = () => {
        const { userIsAdmin, blogShowMessage, youtubeEmbedUrlArr, educaBgArr, educaBgTmp, isLogin, comments, commentContent, foreditIndex, indexTmp, commentOther,
            editcommentOther, subcommentOther, editsubcommentOther, blogId, foreditInIndexTmp, inIndexTmp, educationBy, outindexTmp,
            lang, userData } = this.state;

        let tutor_img = '';
        if (Object.keys(blogShowMessage).length > 0) {
            tutor_img = blogShowMessage.tutor.picture;
        }
        if (tutor_img.indexOf('backuptutorpic') !== -1) {
            tutor_img = '../images/backuptutorpic.png';
        }


        return (
            <div className="greybackground">
                <article className="container blogshow-container">
                    <div className="row">
                        <div className="col-md-12 col-lg-8">
                            <div className="card">
                                <h2>{'cn' === lang ? blogShowMessage.title_cn : 'ko' === lang ? blogShowMessage.title_kr : blogShowMessage.title_en}</h2>
                                <p className="italic float-left display-inline">
                                    <i className="fa fa-calendar"></i> {blogShowMessage.created_at ? blogShowMessage.created_at.split(' ')[0] : ''}
                                    {
                                        userIsAdmin ?
                                            <span>| <i className="fa fa-eye"></i> {blogShowMessage.view_count}</span>
                                            : null
                                    }
                                </p>
                                <div className="secret allow-content-image-modal force-image-responsive table-highlight">
                                    {
                                        youtubeEmbedUrlArr.map((youtubeEmbedUrl, key) =>
                                            <div key={'youtubeEmbedUrl' + key} className="margin-bottom-10 margin-top-10 embed-responsive embed-responsive-16by9">
                                                <iframe title="iframe" className="embed-responsive-item blog-youtube-iframe"
                                                    src={this.trustSrc(youtubeEmbedUrl)}
                                                    allowFullScreen frameBorder="0"></iframe>
                                            </div>)
                                    }
                                    <p className="blog-p-padding" dangerouslySetInnerHTML={{ __html: 'cn' === lang ? blogShowMessage.content_cn : 'ko' === lang ? blogShowMessage.content_kr : blogShowMessage.content_en }}>
                                    </p>
                                </div>
                                <div className="blog-show-bookmark">
                                    {
                                        userData.user && 'student' === userData.user.type && !blogShowMessage.isBooked ?
                                            <button className="btn btn-xs bookmark-btn white-font-color" onClick={() => this.bookmarkBlog()}>
                                                <i className="fa fa-heart"></i> <TrasBookmark />
                                            </button> : ''
                                    }
                                    {
                                        userData.user && 'student' === userData.user.type && blogShowMessage.isBooked ?
                                            <button className="btn btn-xs bookmarked-btn white-font-color">
                                                <span className="fa fa-heart"></span> <TrasBookmarked />
                                            </button> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <div className="card text-center blog-show-card-md">
                                <img alt="img" className="img-circle blog-show-author-pic mx-auto"
                                    src={tutor_img} onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }}
                                />
                                <h3>{blogShowMessage.tutor ? blogShowMessage.tutor.full_name : ''}</h3>
                                {
                                    educaBgArr.map((educationBg, key) =>
                                        <div key={'educationBg' + key} className="margin-bottom-5">
                                            <p>{this.translate(educationBg.degree)} | {educationBy.school} | <span>{educaBgTmp[educationBg.degree].major.join(" & ")}</span></p>
                                        </div>)
                                }
                                <p className="blog-show-author-intro"><i>{blogShowMessage.tutor ? blogShowMessage.tutor.about_myself : ''}</i></p>
                                <a href={`/#/teachers/${blogShowMessage.tutor && blogShowMessage.tutor.id}`} className="btn btn-blue-border margin-top-10">
                                    <TrasViewProfile />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="card blog-show-card">
                                <h3 className="blue-font-color"><TrasComment /></h3>
                                <p>"<TrasWritecommentifyouhavequestions />"</p>
                                {
                                    isLogin ?
                                        <div>
                                            <textarea className="form-control" rows="3" value={commentContent}
                                                onChange={(e) => this.setState({ commentContent: e.target.value })}>
                                            </textarea>
                                            <button className="btn btn-blue-bg float-right margin-top-10" onClick={() => this.submitComment()}>
                                                <TrasSubmit />
                                            </button>
                                        </div>
                                        :
                                        <div>
                                            <button onClick={() => this.goToLoginRedirect()} className="blog-comment-not-login"><a><TrasNeedtologintoleavecomment /></a></button>
                                        </div>
                                }
                                {this.createCommentsHtml()}
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                            <div className="card text-center">
                                <img alt="img" className="img-circle blog-show-author-pic mx-auto"
                                        src={tutor_img} onError={function(e){e.target.src = '/images/backuptutorpic.png';}.bind(this)}
                                        />
                                <h3>{blogShowMessage.tutor?blogShowMessage.tutor.full_name:''}</h3>
                                {
                                    educaBgArr.map((educationBg,key)=>
                                    <div key={'educationBg'+key} className="margin-bottom-5">
                                        <p>{this.translate(educationBg.degree )} | {educationBy.school} | <span>{educaBgTmp[educationBg.degree].major.join(" & ")}</span></p>
                                    </div>)
                                }
                                <p className="blog-show-author-intro"><i>{blogShowMessage.tutor?blogShowMessage.tutor.about_myself:''}</i></p>
                                <a href={`/#/teachers/${blogShowMessage.tutor&&blogShowMessage.tutor.id}`} className="btn btn-blue-border margin-top-10">
                                    <TrasViewProfile/>
                                </a>
                            </div>
                        </div> */}
                    </div>
                    <div className="row margin-bottom-20">
                        <div className="col-md-12">
                            <a href="/#/blogs" className="btn blog-back-btn"><TrasBack /></a>
                            {
                                blogShowMessage.canEdit ?
                                    <a href={`/#/blogs/${blogId}/edit`} className="btn submit-btn">
                                        <TrasEdit />
                                    </a>
                                    : ''
                            }
                        </div>
                    </div>
                </article>
            </div>
        )
    }
    createBreadcrumb = () => {
        const { lang, blogShowMessage } = this.state
        return (
            <div className="container">
                <ol className="breadcrumb mooc-index-breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/blogs"><TrasBlog /></a></li>
                    <li className="active">{'cn' === lang ? blogShowMessage.title_cn : 'ko' === lang ? blogShowMessage.title_kr : blogShowMessage.title_en}</li>
                </ol>
            </div>
        )
    }
    render() {
        return (
            <div className="blogs-container">
                {this.createBreadcrumb()}
                {this.createContentHtml()}
            </div>
        )
    }
}

export default withRouter(Blog);


