import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasTutor,
    TrasTermtimelocation,
    TrasTemporarilynotteaching,
    TrasEducationBackground,
    TrasViewCertificate,
    TrasTeachingStyle,
    TrasTeachingSubjects,
    TrasDoyoulikethisteacherthencontactteacher,
    TrasTuitionRequest,
    TrasCall,
    TrasLastUpdated,
    TrasAboutMyself,
    TrasTeachingExperience,
    TrasDemoClassVideo,
    TrasGroupClass,
    TrasDetails,
    TrasBlog,
    TrasMyMOOC,
    TrasHighSchool,
    TrasUndergraduate,
    TrasMaster,
    TrasPhD,
    TrasReviewslefttoAdmin,
    TrasActivelyleadthestudent,
    TrasBoostuptestgrades,
    TrasMotivateandinspireasanolderbrotherandsisterlikementor,
    TrasHelpwithhomework,
    TrasExplaindifficultconceptsclearly,
    TrasMakethesubjectmoreinteresting
} from '../../../Translate/EachTranslateC';
import './teacherprofile.css';
import './subTeacherProfile.css';
import * as imgSmile from '../../../images/smile.png';

import axios from '../../../config/axios';
import { GetAjaxURL, multipartForm, getEnvironment, NoImgContens, postConfig } from '../../../config/AjaxConfig';
import { isAdmin } from '../../extra/utils';
import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';

import ReactTooltip from 'react-tooltip';
import ReactPlayer from 'react-player'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMapMarkerAlt, faEnvelope, faMobileAlt, faAngleDoubleRight, faAngleDoubleLeft,
} from '@fortawesome/free-solid-svg-icons';

import * as bgImg from '../../../images/index_carousel_2.jpg';
import TApplyMsgs from '../template/TApplyMsgs';

import { InfinityController } from '../../extra/InfinityScroll';
import { metaRegExp, metaClass, applyMetaHeader } from '../../extra/metaController';
import kakaoIcon from '../../../images/kakao_icon_original.png'
import '../../utils/InfinityScroll.css';

// Wapper
const TextAreaWapper = (props) => {
    // Wapper Define

    // State
    // 해당 방법 :
    // 스테이트 데이터 쪽 정의 하는 것보다 UI쪽 정의해서 키 값으로 변경하는 게 좋을 듯함.

    // 리스너.
    // 이 부분은 생각해 보자.
    // 음 콜백처리는 props로 받아서 처리했지만 그닥 좋지는 않음.
    // 상위 -> 하위 -> 하위.. n+1 식으로 계속 늘어나는데 
    // 정의부가 뚜렷하지 않음. 디버깅으로 체크하는 귀찮음이 생김. 공부하는 입장에서는 이런 식으로 짜면 평생 가도 끝을 못 봄. 지못미 -_-;

    // 장점은 뚜렷함. 그냥 남이 만들어주면 가져다 쓰면 됨. -_-;
    // 글쎄. 이런 프로그래밍 방식은 호불호가 생길 듯함.
    // 개별단위로 분리시키는 것보다 그리드 단위로 분리시키는 편이 그나마 좋아보임. --> 해당 방법은 search페이지 참조.

    // 결론. 이런 방법 쓰지마셈 ㅡ.ㅡ;
    // 해당 방법을 써먹고 싶으면 InfinityScroller 만들어둔 것처럼 클레스 단위로 쪼개서 넘겨두는 걸 추천. 작은 기능별로는 그닥.

    return (
        <div className={props.className}>
            {
                props.showAndHide ?
                    <TextAreaEdits {...props} />
                    : props.displayer
            }
        </div>
    )
}
const TextAreaEdits = (props) => {
    const dataRes = props.data;

    // const autolink = () => {
    //     let originalData = dataRes;
    //     let expUrl = /(((http(s)?:\/\/)\S+(\.[^(\n|\t|\s,)]+)+)|((http(s)?:\/\/)?(([a-zA-z\-_]+[0-9]*)|([0-9]*[a-zA-z\-_]+)){2,}(\.[^(\n|\t|\s,)]+)+))+/gi;
    //     let changeData = originalData.replace(expUrl, '<a href="$&">$&</a>');
    return <textarea
        style={{ width: '100%', minHeight: '300px', borderRadius: '8px', border: '2px solid #5b9aef', padding: '6px 12px', color: '#6c767f' }}
        value={dataRes}
        onChange={props.callback}
    ></textarea>
    // return <textarea className={className} value={data}></textarea>;
}
class Teacherprofile extends Component {
    state = {
        teacherId: '',
        thisUserType: '',
        lang: 'ko',
        teacherInfo: {
            teacherName: { name: '' }
        },

        toggleUIController: {
            isNeedIntro: false,
            isNeedTeachingEx: false,
            isNeedMeetingSummary: false,

            isIntroEdit: false,
            isExperienceEdit: false,
            isMeetingSummaryEdit: false
        },

        originData: {
            about_myself: "",
            experience: "",
            meeting_summary: ""
        },

        ajaxParams: {
            DateH: "",
            HCul: "",
            Hname: "",
            about_myself: "",
            bankAccount: "",
            banned: 0,
            certificate: "",
            first_city_kr: "",
            first_region_kr: "",
            current_degree: "",
            curriculums: [],
            degreeH: "",
            degreeU: "",
            disable_until: "",
            experience: "",
            flagstring: "!",
            free_demo: 0,
            hourly_rate: 20000,
            hourly_rate_max: "",
            id: 0,
            kakaotalk: "",
            location2_note: "",
            location_note: "",
            master: 85,
            phd: 26,
            phone: "",
            phone1_country_id: 200,
            phone2: "",
            phone2_country_id: 200,
            preferred_currency: 2,
            second_city_kr: "",
            second_region_kr: "",
            schoolM: "",
            schoolP: "",
            schoolU: "",
            skype: "",
            teacherName: "",
            teaching_styles: "",
            thumbnailIntro: '',
            undergraduate: 76,
            updated_at: "",
            visibility: 1,
            wechat: "",
            whatsapp: "",
            ot: 1,
            email: '',
            tp_myself_update: '',
            flagstring: '',
            meeting_summary: ''
        },

        // INFINITY SCROLL CONTROL
        // reviews : [],                            // 전체 데이터
        currentReviews: [],                        // 현재 데이터
        InfinityController: null,                  // InfinityController - Review

        // adminReviews : [],                        // 전체 데이터
        currentAdminReviews: [],                    // 현재 데이터
        InfinityControllerAdmin: null,              // InfinityController - adminReviews


        videoData: [],
        groupClasses: [],
        blogs: [],
        moocs: [],
        reviews: [],
        displayReviews: [],
        adminReviews: [],

        needShowCertificatePannel: false,

        isDone: false,
        userIsAdmin: false,
        showSendingMsg: false
    }

    componentDidMount = async () => {
        const tempId = this.props.match.params.tutorId;
        if (tempId) {
            if (!Number(tempId)) {

                if (tempId === 'dashboard') {
                    window.location.href = '/#/dashboard';
                } else {
                    showNotify("잘못된 주소입니다.", STATE_NOTIFY.TYPE_NEGATIVIE);
                    window.location.href = '/#/';
                }
                return;
            }
            const apiurls = await GetAjaxURL();
            const url = apiurls.apiThisUser;
            const res = await axios.get(url).catch((err) => { return err; });
            const data = res.data;

            let isAdminCheck = false;
            let thisUserType = '';
            if (data.status !== 'fail') {
                isAdminCheck = isAdmin(data.user);
                thisUserType = data.user.type;
            }

            this.setState({
                ...this.state,
                teacherId: String(tempId),
                thisUserType: thisUserType,
                userIsAdmin: isAdminCheck
            }, () => {
                this.ajaxCallingData();
            });

        } else {
            // 여기는 안 탈 거임. app.js 쪽 라우트 보셈. 모르면 문자하셈. 전화 말고.
            window.location.href = '/#/error';
        }
    }

    setMetas = () => {

        const ajaxParams = this.state.ajaxParams;

        const _type = '';
        const _title = '그루미 선생님 ' + ajaxParams.teacherName;
        const _url = window.location.href;
        const _description = ajaxParams.about_myself.replace(/[\n\u00a0]/gi, ' ');
        const _imageUrl = ajaxParams.tp_picurl;
        const about_myself = ajaxParams.about_myself.replace(/[\n\.\-]/gi, ' ').split(' ');
        const newAboutMySelf = [];
        for (let i = 0; i < about_myself.length; i++) {
            if (about_myself[i] !== '') {
                const newWords = about_myself[i].replace(/\u00a0/gi, ' ');
                if (!newAboutMySelf.includes(newWords)) {
                    newAboutMySelf.push(newWords);
                }
            }
        }

        let _keywords = newAboutMySelf.join(', ') + ', ' + ajaxParams.teacherName;
        const newMetaClass = new metaClass(_type, _title, _url, _description, _imageUrl, _keywords);
        applyMetaHeader(newMetaClass);
    }


    ajaxCallingData = async () => {
        // const allUrls = await GetAjaxURL();
        const allUrls = await GetAjaxURL();
        const url = allUrls.apiGetTeacher;

        const formData = new FormData();
        formData.append('teacherId', this.state.teacherId);
        formData.append('code', '');

        const res = await axios.post(url, formData, multipartForm).catch((err) => { return err; });
        const data = res.data

        if (res.data.teacherprofile[Object.keys(res.data.teacherprofile)[0]].id === null) {
            window.location.href = "/#/teachers"
        }

        const validResChecker = Object.keys(res.data.teacherprofile[0]);
        let nullCount = 0;
        for (let i = 0; i < validResChecker.length; i++) {
            if (res.data.teacherprofile[0][validResChecker[i]] === null) {
                nullCount++;
            }
        }
        if (nullCount > 20) {
            // page redirect
            return;
        }


        if (typeof res.data.teacherprofile === 'string') {
            showNotify('데이터를 불러오는데 실패했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            // return window.location.href = '/';
        }

        const curData = res.data.curriculums;

        const teacherPr = res.data.teacherprofile[0];
        const tempArr = [];
        for (let i = 1; i < 7; i++) {
            tempArr.push(teacherPr['tp_v' + i]);
        }
        const videoData = this.getArrVedio(tempArr);
        if (typeof teacherPr !== 'string') {
            delete teacherPr.curriculums;
            teacherPr.curriculums = curData;
        }

        const about_myself = teacherPr.about_myself;
        const experience = teacherPr.experience;
        const meeting_summary = teacherPr.meeting_summary;




        // const formData3 = new FormData();
        // formData3.append('teacherId', this.state.teacherId);
        // formData3.append('code', 'tp_point');
        // const tp_pointRes = await axios.post(url, formData3, multipartForm).catch((err)=>{return err;});


        const tempGroupClasses = res.data.group_classes;
        const groupClasses = [];

        if (tempGroupClasses) {
            const tGC = tempGroupClasses;
            const curDate = new Date();
            for (let i = 0; i < tGC.length; i++) {
                const end_time = tGC[i]['end_time'];
                const calDate = new Date(end_time);

                if (curDate.getTime() < calDate.getTime()) {
                    groupClasses.push(tGC[i]);
                }
            }
        }


        const blogs = res.data.blogs;
        const moocs = res.data.moocs;
        const reviews = res.data.reviews;
        const admin_reviews = res.data.admin_reviews;


        const InfinityControllers = new InfinityController();
        InfinityControllers.setInit(reviews);
        const currentReviews = InfinityControllers.getDataWithCount(10);

        const InfinityControllerAdmin = new InfinityController();
        InfinityControllerAdmin.setInit(admin_reviews);
        const currentAdminReviews = InfinityControllerAdmin.getDataWithCount(10);

        this.setState({
            ...this.state,

            originData: {
                about_myself: about_myself,
                experience: experience,
                meeting_summary: meeting_summary
            },

            ajaxParams: {
                ...teacherPr
            },

            currentReviews: currentReviews,
            InfinityController: InfinityControllers,

            currentAdminReviews: currentAdminReviews,
            InfinityControllerAdmin: InfinityControllerAdmin,

            videoData,
            groupClasses: groupClasses,
            blogs: blogs,
            moocs: moocs,
            reviews: reviews,
            adminReviews: admin_reviews,
            isDone: true
        }, () => {
            this.checkEvent();
            this.setMetas();
            this.props.RootBindingData.doLoading(false);
        });

    }

    getArrVedio = (_arr) => {
        const newTemp = [];
        for (let i = 0; i < _arr.length; i++) {
            if (_arr[i] !== '')
                newTemp.push(_arr[i]);
        };
        return newTemp;
    }




    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/teachers"><TrasTutor /></a></li>
                    <li className="active">{this.state.ajaxParams.teacherName}</li>
                </ol>
            </div>
        )
    }


    createPart_A_Left_B = () => {

        const data = [];

        if (this.state.userIsAdmin) {
            const tHourlyRateMin = this.state.ajaxParams.hourly_rate;
            const tHourlyRateMax = this.state.ajaxParams.hourly_rate_max;
            if (tHourlyRateMin || tHourlyRateMax) {
                data.push({ key: 'money', value: `${tHourlyRateMin} ~ ${tHourlyRateMax}` });
            }
        }

        if (this.state.ajaxParams.email) {
            data.push({ key: 'email', value: this.state.ajaxParams.email });
        }
        if (this.state.ajaxParams.phone) {
            data.push({ key: 'phone', value: this.state.ajaxParams.phone });
        }
        if (this.state.ajaxParams.skype) {
            data.push({ key: 'skype', value: this.state.ajaxParams.skype });
        }
        if (this.state.ajaxParams.kakaotalk) {
            data.push({ key: 'kakaotalk', value: this.state.ajaxParams.kakaotalk });
        }
        if (this.state.ajaxParams.wechat) {
            data.push({ key: 'wechat', value: this.state.ajaxParams.wechat });
        }




        const li = [];
        for (let i = 0; i < data.length; i++) {
            let CLASSNAME = '';
            if (data[i]['key'] === 'email') CLASSNAME = 'fa fa-envelope';
            else if (data[i]['key'] === 'phone') CLASSNAME = 'fa fa-phone';
            else if (data[i]['key'] === 'skype') CLASSNAME = 'fa fa-skype';
            else if (data[i]['key'] === 'kakaotalk') CLASSNAME = 'tp-profile-sq-kakao';
            else if (data[i]['key'] === 'wechat') CLASSNAME = 'fa fa-wechat';
            else if (data[i]['key'] === 'money') CLASSNAME = 'tdmoney';

            li.push(
                <li key={i}>
                    {CLASSNAME !== 'tdmoney' ?
                        <span className=''> <i className={CLASSNAME}></i> </span>
                        : <span className=''> 시급 </span>
                    }
                    <span> {data[i].value} {this.state.ajaxParams.phone2 && data[i].key === 'phone' ? `(${this.state.ajaxParams.phone2})` : ''}</span>
                </li>
            )
        }





        return (
            <div className="contacts margin-top-10 tp-show-msa">
                <div className='tp-part-name-there'>
                    {li}
                </div>
            </div>

        )
    }

    createPart_A_Left = () => {

        const firstUrl = '';
        const secondUrl = this.state.ajaxParams.tp_picurl;
        let imgsUrl = firstUrl + secondUrl;

        if (imgsUrl === "https://s3.ap-northeast-2.amazonaws.com/gurume-tutor/") {
            imgsUrl = '../images/backuptutorpic.png';
        }
        let has_diabled_date = this.state.ajaxParams.disable_until.length > 0 &&
            this.state.ajaxParams.disable_until.substr(0, 4) !== '0000';

        return (
            <div className='tp-part-c-name-part'>
                <div className='tp-part-img-pic'>
                    <img src={imgsUrl} alt='...' onError={function (e) { e.target.src = NoImgContens; }} />

                </div>
                <div className='tp-part-prfile-wr'>
                    <h3 className="text-center black-font-color">
                        {this.state.ajaxParams.flagstring !== '' ?
                            <span
                                className="admission-apply"
                                data-tip={this.state.ajaxParams.flagstring}
                                data-for='tooltipFlagStr'>
                                <i className="fa fa-graduation-cap hover-shadow cursor blue-font-color"></i>
                                <ReactTooltip
                                    effect="solid"
                                    place="bottom"
                                    id='tooltipFlagStr'
                                    type='light'>
                                </ReactTooltip>
                            </span>
                            : ''
                        }
                        &nbsp;
                        {this.state.ajaxParams.teacherName}

                    </h3>
                    <div className="text-center margin-top-10">
                        <span style={{ color: "#b5b5b6" }}> <FontAwesomeIcon icon={faMapMarkerAlt} /> </span>
                        {`${this.state.ajaxParams.first_region_kr} | ${this.state.ajaxParams.first_city_kr}`}
                    </div>

                    {this.state.ajaxParams.second_region_kr ?
                        <div className="text-center">
                            <div>
                                <b><TrasTermtimelocation /></b>
                            </div>
                            <div>
                                {`${this.state.ajaxParams.second_region_kr} | ${this.state.ajaxParams.second_city_kr}`}
                            </div>
                        </div>
                        : ``}



                    <div className="text-center">


                        {(this.state.ajaxParams.tp_visibility !== 1 && has_diabled_date) ?
                            <small className="red-border border-radius-4 margin-top-5 padding-5 t-profile-small-size">
                                {this.state.ajaxParams.disable_until + " 까지 당분간 수업 불가능"}
                            </small>
                            : ''
                        }

                        {(this.state.ajaxParams.tp_visibility !== 1 && !has_diabled_date) ?
                            <small className="red-border border-radius-4 margin-top-5 padding-5 t-profile-small-size">
                                당분간 수업 불가능
                            </small>
                            : ''
                        }

                        {this.state.ajaxParams.ot !== 1 ?
                            <h4 className="no-padding-top"><i
                                className="fa fa-lock color-gurume-red"></i></h4>
                            : ''}

                    </div>

                    {
                        this.state.userIsAdmin ?
                            this.createPart_A_Left_B()
                            : ''
                    }

                </div>
            </div>
        )
    }

    createPart_A_Right = () => {


        const eduData = [];

        if (this.state.ajaxParams.Hname.length > 0) {
            const divData = <div key='high'
                className='tp-profile-lis'>
                <div className='tp-profile-cent'>
                    <div className="tp-profile-cent-box">
                        <TrasHighSchool />
                    </div>
                </div>
                <span className="label vertical-middle label-sm label-default">
                    {this.state.ajaxParams.Hname}
                </span>
                <span>
                    {`| ${this.state.ajaxParams.HCul}`}
                </span>
            </div>;
            eduData.push(divData);
        }
        if (String(this.state.ajaxParams.undergraduate).length > 0 && this.state.ajaxParams.undergraduate !== 0) {
            const divData = <div key='under'
                className='tp-profile-lis'>
                <div className='tp-profile-cent'>
                    <div className="tp-profile-cent-box">
                        학사
                    </div>
                </div>
                <span className="label vertical-middle label-sm label-default">
                    {this.state.ajaxParams.schoolU}
                </span>
                <span>
                    {`| ${this.state.ajaxParams.undergraduate_degree1_note == "" ? this.state.ajaxParams.subjectU : this.state.ajaxParams.undergraduate_degree1_note}`}
                </span>
            </div>;
            eduData.push(divData);
        }
        if (String(this.state.ajaxParams.master).length > 0 && this.state.ajaxParams.master !== 0) {
            const divData = <div key='master'
                className='tp-profile-lis'>
                <div className='tp-profile-cent'>
                    <div className="tp-profile-cent-box">
                        <TrasMaster />
                    </div>
                </div>
                <span className="label vertical-middle label-sm label-default">
                    {this.state.ajaxParams.schoolM}
                </span>
                <span>
                    {`| ${this.state.ajaxParams.subjectM}`}
                </span>
            </div>;
            eduData.push(divData);
        }
        if (String(this.state.ajaxParams.phd).length > 0 && this.state.ajaxParams.phd !== 0) {
            const divData = <div key='phd'
                className='tp-profile-lis'>
                <div className='tp-profile-cent'>
                    <div className="tp-profile-cent-box">
                        <TrasPhD />
                    </div>
                </div>
                <span className="label vertical-middle label-sm label-default">
                    {this.state.ajaxParams.schoolP}
                </span>
                <span>
                    {`| ${this.state.ajaxParams.subjectP}`}
                </span>
            </div>;
            eduData.push(divData);
        }

        const teachingStyles = [];
        if (this.state.ajaxParams.teaching_styles.length > 0) {
            const sTeachingData = this.state.ajaxParams.teaching_styles.split('$');
            for (const each of sTeachingData) {
                if (Number(each) === 1) {
                    teachingStyles.push(<span className="tp-profile-header-style" key='1' data-teach-key='1'><span>꼼꼼 내신 대비</span></span>);
                } else if (Number(each) === 2) {
                    teachingStyles.push(<span className="tp-profile-header-style" key='2' data-teach-key='2'><span>전략 위주 시험 대비</span></span>);
                } else if (Number(each) === 3) {
                    teachingStyles.push(<span className="tp-profile-header-style" key='3' data-teach-key='3'><span>동기부여</span></span>);
                } else if (Number(each) === 4) {
                    teachingStyles.push(<span className="tp-profile-header-style" key='4' data-teach-key='4'><span>디테일에 강한</span></span>);
                } else if (Number(each) === 5) {
                    teachingStyles.push(<span className="tp-profile-header-style" key='5' data-teach-key='5'><span>탄탄한 경력</span></span>);
                } else if (Number(each) === 6) {
                    teachingStyles.push(<span className="tp-profile-header-style" key='6' data-teach-key='6'><span>숙제량 많은</span></span>);
                } else if (Number(each) === 7) {
                    teachingStyles.push(<span className="tp-profile-header-style" key='7' data-teach-key='7'><span>스파르타 식</span></span>);
                } else {
                    teachingStyles.push(<span className="tp-profile-header-style" key='8' data-teach-key='8'><span>성과 보장</span></span>);
                }
            }
        }






        const curlDiv = [];
        if (this.state.ajaxParams.curriculums.length > 0) {
            let curls = this.state.ajaxParams.curriculums;
            let srtCurls = curls.sort((a, b) => { return a.test_type > b.test_type ? -1 : 0 });
            srtCurls = srtCurls.sort((a, b) => { return a.test_type === 2 ? -1 : 0 });

            let currentNode = srtCurls[0];
            let childSpan = [];
            if (srtCurls.length === 1) {
                let sClass = 'tp-profile-bc-op-lv-fl-liv-border-fls-btitle ';
                let sChildClass = 'tp-profile-bc-op-lv-fl-liv-border-fls-b ';
                if (currentNode.test_type === 1) {
                    sClass += 'tp-profile-bc-op';
                    sChildClass += 'tp-profile-bc-op-lv';
                } else if (currentNode.test_type === 2) {
                    sClass += 'tp-profile-bc-op-lv-fl';
                    sChildClass += 'tp-profile-bc-op-lv-fl-liv-border-fls';
                } else {
                    sClass += 'tp-profile-bc-op-lv-fl-liv';
                    sChildClass += 'tp-profile-bc-op-lv-fl-liv-border';
                }
                childSpan.push(<span key={`sub-ss0`} className={sChildClass}>{currentNode.subject}</span>);
                childSpan.unshift(<span key={`curs-0`} className={sClass}>{currentNode.curriculum}</span>);
                curlDiv.push(
                    <div className='tp-profile-children' key={'s' + 0}>
                        {childSpan}
                    </div>
                );
                childSpan = [];
            }
            for (let i = 1; i < srtCurls.length; i++) {
                let nextNode = srtCurls[i];
                let sClass = 'tp-profile-bc-op-lv-fl-liv-border-fls-btitle ';
                let sChildClass = 'tp-profile-bc-op-lv-fl-liv-border-fls-b ';
                if (currentNode.test_type === 1) {
                    sClass += 'tp-profile-bc-op';
                    sChildClass += 'tp-profile-bc-op-lv';
                } else if (currentNode.test_type === 2) {
                    sClass += 'tp-profile-bc-op-lv-fl';
                    sChildClass += 'tp-profile-bc-op-lv-fl-liv-border-fls';
                } else {
                    sClass += 'tp-profile-bc-op-lv-fl-liv';
                    sChildClass += 'tp-profile-bc-op-lv-fl-liv-border';
                }

                if (currentNode.curriculum !== nextNode.curriculum) {
                    childSpan.push(<span key={`sub-s${i}`} className={sChildClass}>{currentNode.subject}</span>);
                    childSpan.unshift(<span key={`cur-${i}`} className={sClass}>{currentNode.curriculum}</span>);
                    curlDiv.push(
                        <div className='tp-profile-children' key={i}>
                            {childSpan}
                        </div>
                    );
                    childSpan = [];
                } else {
                    childSpan.push(<span key={`sub-s${i}`} className={sChildClass}>{currentNode.subject}</span>);
                }
                currentNode = nextNode;

                if (i === srtCurls.length - 1) {
                    childSpan.push(<span key={`sub-ss${i}`} className={sChildClass}>{currentNode.subject}</span>);
                    childSpan.unshift(<span key={`curs-${i}`} className={sClass}>{currentNode.curriculum}</span>);
                    curlDiv.push(
                        <div className='tp-profile-children' key={'s' + i}>
                            {childSpan}
                        </div>
                    );
                    childSpan = [];

                }
            }

        }



        return (
            <div className='tp-p-profile-part'>
                <div className='tp-profile-titile font-size-22 bold-font blue-font-color'>
                    <b><TrasEducationBackground /></b>
                </div>

                {eduData}


                {
                    this.state.userIsAdmin ?
                        <div className="tp-profile-certi brown-font-color font-size-14" onClick={this.showCertificationPannel} >
                            <i className="fa fa-graduation-cap hover-shadow cursor blue-font-color"></i> (Admin) <TrasViewCertificate />
                        </div>
                        :
                        <div className="tp-profile-certi brown-font-color font-size-14 tp-profile-pointer">
                            <span
                                className="tp-profile-pointer"
                                data-tip={`원본 학생증/졸업증은 고객센터에 문의해주시기 바랍니다.`}
                                data-for='herPretty'>
                                <i className="fa fa-graduation-cap hover-shadow cursor blue-font-color hidden-md"></i>
                                <TrasViewCertificate />
                                <ReactTooltip
                                    effect="solid"
                                    place="bottom"
                                    id='herPretty'
                                    type='light'>
                                </ReactTooltip>
                            </span>
                        </div>
                }

                <div className='tp-profile-titile font-size-22 bold-font blue-font-color'>
                    <b>
                        <TrasTeachingStyle />
                    </b>
                </div>
                <div className="tp-profile-header-wrapper">
                    {teachingStyles}
                </div>

                <div className='tp-profile-titile font-size-22 bold-font blue-font-color'>
                    <b>
                        <TrasTeachingSubjects />
                    </b>
                </div>

                {curlDiv}

            </div>
        )
    }

    hideCertificationPannel = (e) => {
        let target = e.target;
        if (target.id !== 'tpCertWapper') {
            return;
        }
        this.setState({
            ...this.state,
            needShowCertificatePannel: false,
        })
    }

    showCertificationPannel = () => {
        this.setState({
            ...this.state,
            needShowCertificatePannel: true,
        })
    }

    showAndHideCertification = () => {
        // needShowCertificatePannel

        const certificate = this.state.ajaxParams.certificate;

        let lists = [];
        if (certificate !== '') {
            lists.push(<li key='alpha_1'> <img src={certificate} alt='...' onError={function (e) { e.target.src = NoImgContens; }} /> </li>);
        } else {
            lists.push(<li key='alpha_1'>업로드된 이미지가 없습니다.</li>);
        }

        return (
            <div id='tpCertWapper' className='tp-profile-cert-pannel' onClick={this.hideCertificationPannel}>
                <div className='tp-profile-card'>
                    <div className='tp-profile-cert-header'><h4><i className="fa fa-graduation-cap hover-shadow"></i> 졸업증</h4></div>
                    <div className='tp-profile-cert-body'>
                        <ul>
                            {lists}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }




    createPart_A = () => {
        return (
            <div className='tp-profile-part-a-wapper'>

                {this.createPart_A_Left()}
                {this.createPart_A_Right()}

            </div>
        )
    }

    scrollDectectAdminFunc = () => {
        const isScrollDone = this.state.InfinityControllerAdmin.scrollDetect('infiWapperAdmin', 'tempIdM');
        if (isScrollDone) {
            const currentAdminReviews = this.state.InfinityControllerAdmin.getDataWithCount(5);
            this.setState({
                ...this.state,
                currentAdminReviews: currentAdminReviews,
            });
        }
    }

    createPart_C_S_Reviews_Admin = () => {
        if (!this.state.userIsAdmin) return;
        if (!this.state.adminReviews) return;
        if (this.state.adminReviews.length === 0) return;


        const Reviews = this.state.currentAdminReviews;
        const currentCount = Reviews.length;
        const tempArr = [];
        let goodCount = 0;

        for (let i = 0; i < currentCount; i++) {
            goodCount++;
            const created_at = Reviews[i]['updated_at'].split(' ')[0].replace(/\b0/g, '');
            const tp_name = Reviews[i]['tp_name'];
            let temp_reviews = Reviews[i]['to_admin'];
            const SEX = Reviews[i]['SEX'];
            let temp_color = 'strong-man';
            if (SEX !== 1) temp_color = '';
            let needShowMore = <div className='tp-profile-start-anim tp-opa-zero' style={{ animationDelay: `${i * 0.0017}s` }}>
                <span>{temp_reviews}</span>
            </div>;
            if (temp_reviews.length > 120) {
                const originReview = temp_reviews;
                temp_reviews = temp_reviews.substr(0, 120) + '...';
                needShowMore = <div className='tp-profile-start-anim tp-opa-zero' style={{ animationDelay: `${i * 0.0017}s` }}>
                    <span>{temp_reviews}</span>
                    <span data-temp-r-p={originReview} onClick={this.showMoreEachReview}>+ 모두보기</span>
                </div>;
            }

            const tpcComponent =
                <li key={`tem_key_${i}`}>
                    <div className='tp-profile-start-anim23 tp-opa-zero' style={{ animationDelay: `${i * 0.0017}s` }}>
                        <span><img src={imgSmile} alt='...' onError={function (e) { e.target.src = 'https://via.placeholder.com/250x150?text=No+Image'; }} /></span>
                        <span>{tp_name}</span>
                        <span className={temp_color}>{created_at}</span>
                    </div>
                    {needShowMore}
                </li>;

            tempArr.push(tpcComponent);
        }


        return (
            <div className='tp-profile-intro'>
                <h4 className="blue-font-color tp-profile-font-size18">
                    <TrasReviewslefttoAdmin />
                    {` (${currentCount} / ${this.state.adminReviews.length})`}
                </h4>
                <div className='tp-profile-children-admin tp-profile-scroll' id='infiWapperAdmin' onScroll={this.scrollDectectAdminFunc}>
                    <ul id='tempIdM'>
                        {tempArr}
                    </ul>

                </div>

            </div>
        )
    }


    checkEvent = () => {
        const tempSId = document.getElementById('tempSId');
        if (!tempSId) return;
        tempSId.addEventListener('animationend', (e) => {
            const target = e.target;
            target.classList.remove('tp-opa-zero');
        })

        const tempIdM = document.getElementById('tempIdM');
        if (!tempIdM) return;
        tempIdM.addEventListener('animationend', (e) => {
            const target = e.target;
            target.classList.remove('tp-opa-zero');
        })
    }


    showMoreEachReview = (e) => {
        const target = e.target;
        const data = target.dataset['tempRP'];

        const parent = target.parentNode;
        parent.children[0].innerText = data;
        parent.removeChild(target);
    }

    scrollDectectFunc = () => {
        const isScrollDone = this.state.InfinityController.scrollDetect('infiWapper', 'tempSId');
        if (isScrollDone) {
            const currentReviews = this.state.InfinityController.getDataWithCount(5);
            this.setState({
                ...this.state,
                currentReviews: currentReviews,
            });
        }
    }




    createPart_C_S_Reviews = () => {

        if (!this.state.reviews) return;
        if (this.state.reviews.length === 0) return;


        // const Reviews = this.state.reviews;
        const Reviews = this.state.currentReviews;
        // const currentCount = this.state.tpController.currentReviewCount;
        const currentCount = Reviews.length;
        const tempArr = [];
        let goodCount = 0;

        for (let i = 0; i < Reviews.length; i++) {
            if (Number(Reviews[i]['like']) > 0) {
                goodCount++;
            }
        }

        for (let i = 0; i < currentCount; i++) {
            let created_at = '';
            if (!Reviews[i]) continue;
            if (Reviews[i]['created_at']) {
                created_at = Reviews[i]['created_at'].split(' ')[0].replace(/\b0/g, '');
            }
            let s_name = Reviews[i]['tp_name'];
            let temp_reviews = Reviews[i]['review'];
            const SEX = Reviews[i]['SEX'];
            let s_color = 'strong-man';
            if (SEX !== 1) s_color = 'shamed-girls';
            let needShowMore = <div className='tp-profile-start-anim tp-opa-zero' style={{ animationDelay: `${i * 0.0017}s` }}>
                <span>{temp_reviews}</span>
            </div>;
            if (temp_reviews.length > 120) {
                const originReview = temp_reviews;
                temp_reviews = temp_reviews.substr(0, 120) + '...';
                needShowMore = <div className='tp-profile-start-anim tp-opa-zero' style={{ animationDelay: `${i * 0.0017}s` }}>
                    <span>{temp_reviews}</span>
                    <span data-temp-r-p={originReview} onClick={this.showMoreEachReview}>+ 모두보기</span>
                </div>;
            }

            if (!this.state.userIsAdmin) {
                s_name = s_name.substr(0, 1) + '**';
            }

            const tpcComponent =
                <li key={`tempArr_${i}`}>
                    <div className='tp-profile-start-anim23 tp-opa-zero' style={{ animationDelay: `${i * 0.0017}s` }}>
                        <span><img src={imgSmile} alt='...' onError={function (e) { e.target.src = NoImgContens; }} /></span>
                        <span>{s_name}</span>
                        <span className={s_color}>{created_at}</span>
                    </div>
                    {needShowMore}
                </li>;

            tempArr.push(tpcComponent);
        }


        return (
            <div className='tp-profile-intro'>
                <h4 className="blue-font-color tp-profile-font-size18">
                    {/* <span>추천</span> <span>{goodCount}</span><span> / </span> */}
                    <span>후기</span> <span>{this.state.reviews.length}</span>
                    {/* <span>(</span><span>{tempArr.length}</span><span>)</span> */}
                </h4>

                <div className='tp-profile-children tp-profile-scroll' id='infiWapper' onScroll={this.scrollDectectFunc}>
                    <ul id='tempSId'>
                        {tempArr}
                    </ul>

                </div>
            </div>
        )
    }



    toggleTeachingEx = (e) => {
        this.setState({
            ...this.state,
            toggleUIController: {
                ...this.state.toggleUIController,
                isNeedTeachingEx: !this.state.toggleUIController.isNeedTeachingEx
            }
        })
    }

    createPart_C_S_TeachingEx = () => {
        if (this.state.ajaxParams.experience === null) return;

        let sTeacingEx = this.state.ajaxParams.experience;
        let foldBtn;
        let needToggle = false;

        if (!this.state.toggleUIController.isNeedTeachingEx) {
            if (sTeacingEx.length > 400) {
                sTeacingEx = sTeacingEx.substr(0, 400) + '...';
                needToggle = true;
            }
            // UNFOLD
            foldBtn = <span className='tp-profile-wqpr'>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
            </span>;
        } else {
            // FOLD
            foldBtn = <span className='tp-profile-wqpr'>
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </span>;
        }

        const showAndHide = this.state.toggleUIController.isExperienceEdit;
        let displayButten = 'Edit';
        if (showAndHide) {
            displayButten = 'Done';
        }


        return (
            <div className='tp-profile-intro'>

                <h4 className="blue-font-color dynamic-font-size">
                    <TrasTeachingExperience />
                    {
                        this.state.userIsAdmin ?
                            <input className='comm-btn-style' type='button' onClick={this.editExperienceEdit} value={displayButten} />
                            : ''
                    }
                    {
                        showAndHide ?
                            <input className='comm-btn-style' type='button' onClick={this.editExperienceCancel} value={'Cancel'} />
                            : ''
                    }
                </h4>

                <TextAreaWapper
                    className={'tp-profile-text-edit-wapper'}
                    showAndHide={this.state.toggleUIController.isExperienceEdit}
                    data={this.state.ajaxParams.experience}
                    displayer={sTeacingEx}
                    callback={this.callbackExperience}
                />

                {needToggle ?
                    <span onClick={this.toggleTeachingEx}>
                        {foldBtn}
                    </span>
                    : ''
                }

            </div>

        )

    }

    toggleMeetingSummary = (e) => {
        this.setState({
            ...this.state,
            toggleUIController: {
                ...this.state.toggleUIController,
                isNeedMeetingSummary: !this.state.toggleUIController.isNeedMeetingSummary
            }
        })
    }

    createPart_C_Meeting_summary = () => {
        if (this.state.ajaxParams.meeting_summary === null) return;

        let sMeetingSummary = this.state.ajaxParams.meeting_summary;
        let foldBtn;
        let needToggle = false;

        if (!this.state.toggleUIController.isNeedMeetingSummary) {
            if (sMeetingSummary.length > 400) {
                sMeetingSummary = sMeetingSummary.substr(0, 400) + '...';
                needToggle = true;
            }
            foldBtn = <span className='tp-profile-wqpr'>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
            </span>;
        } else {
            // FOLD
            foldBtn = <span className='tp-profile-wqpr'>
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </span>;
        }

        const showAndHide = this.state.toggleUIController.isMeetingSummaryEdit;
        let displayButten = 'Edit';
        if (showAndHide) {
            displayButten = 'Done';
        }

        return (
            this.state.userIsAdmin ?
                <div className="tp-profile-intro">
                    <h4 className="blue-font-color dynamic-font-size">
                        미팅 내용
                    {
                            this.state.userIsAdmin ?
                                <input className='comm-btn-style' type='button' onClick={this.editSummaryEdit} value={displayButten} />
                                : ''
                        }
                        {
                            showAndHide ?
                                <input className='comm-btn-style' type='button' onClick={this.editSummaryCancel} value={'Cancel'} />
                                : ''
                        }
                    </h4>

                    <TextAreaWapper
                        className={'tp-profile-text-edit-wapper'}
                        showAndHide={this.state.toggleUIController.isMeetingSummaryEdit}
                        data={this.state.ajaxParams.meeting_summary}
                        displayer={sMeetingSummary}
                        callback={this.callbackSummary}
                    />

                    {needToggle ?
                        <span onClick={this.toggleMeetingSummary}>
                            {foldBtn}
                        </span>
                        : ''
                    }
                </div> : ''
        )
    }



    toggleIntro = (e) => {
        this.setState({
            ...this.state,
            toggleUIController: {
                ...this.state.toggleUIController,
                isNeedIntro: !this.state.toggleUIController.isNeedIntro
            }
        })
    }


    // For Admin START
    editIntroCancel = () => {
        const about_myself = this.state.originData.about_myself;

        this.setState({
            ...this.state,
            toggleUIController: {
                ...this.state.toggleUIController,
                isIntroEdit: !this.state.toggleUIController.isIntroEdit,
            },
            ajaxParams: {
                ...this.state.ajaxParams,
                about_myself: about_myself,
            }
        })
    }

    editIntro = async () => {

        if (!this.state.toggleUIController.isIntroEdit) {
            this.setState({
                ...this.state,
                toggleUIController: {
                    ...this.state.toggleUIController,
                    isIntroEdit: !this.state.toggleUIController.isIntroEdit
                }
            })
        } else {

            if (!window.confirm('변경하시겠습니까?')) return;

            const allUrls = await GetAjaxURL();
            const url = allUrls.RRR_ReactEditor;

            const formData = new FormData();
            formData.append('code', 'about_myself');
            formData.append('id', this.state.teacherId);
            formData.append('contents', this.state.ajaxParams.about_myself);

            const req = await axios.post(url, formData, postConfig).catch((err) => { return console.log(err) });

            if (req) {
                if (req.data) {
                    let msg = req.data.msg;
                    if (req.data.status === 'success') {
                        showNotify('업데이트 되었습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                    } else {
                        showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                    }
                }
            }
            this.setState({
                ...this.state,
                originData: {
                    ...this.state.originData,
                    about_myself: this.state.ajaxParams.about_myself
                },
                toggleUIController: {
                    ...this.state.toggleUIController,
                    isIntroEdit: !this.state.toggleUIController.isIntroEdit
                }
            })
        }

    }
    callbackIntro = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            ajaxParams: {
                ...this.state.ajaxParams,
                about_myself: value
            }
        })
    }

    editExperienceCancel = () => {
        const experience = this.state.originData.experience;

        this.setState({
            ...this.state,
            toggleUIController: {
                ...this.state.toggleUIController,
                isExperienceEdit: !this.state.toggleUIController.isExperienceEdit
            },
            ajaxParams: {
                ...this.state.ajaxParams,
                experience: experience,
            }
        })

    }

    editExperienceEdit = async () => {

        if (!this.state.toggleUIController.isExperienceEdit) {
            this.setState({
                ...this.state,
                toggleUIController: {
                    ...this.state.toggleUIController,
                    isExperienceEdit: !this.state.toggleUIController.isExperienceEdit
                }
            })
        } else {

            if (!window.confirm('변경하시겠습니까?')) return;

            const allUrls = await GetAjaxURL();
            const url = allUrls.RRR_ReactEditor;

            const formData = new FormData();
            formData.append('code', 'experience');
            formData.append('id', this.state.teacherId);
            formData.append('contents', this.state.ajaxParams.experience);

            const req = await axios.post(url, formData, postConfig).catch((err) => { return console.log(err) });

            if (req) {
                if (req.data) {
                    let msg = req.data.msg;
                    if (req.data.status === 'success') {
                        showNotify('업데이트 되었습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                    } else {
                        showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                    }
                }
            }
            this.setState({
                ...this.state,
                originData: {
                    ...this.state.originData,
                    experience: this.state.ajaxParams.experience
                },
                toggleUIController: {
                    ...this.state.toggleUIController,
                    isExperienceEdit: !this.state.toggleUIController.isExperienceEdit
                }
            })
        }

    }

    callbackExperience = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            ajaxParams: {
                ...this.state.ajaxParams,
                experience: value
            }
        })
    }

    editSummaryCancel = () => {
        const summary = this.state.originData.meeting_summary;

        this.setState({
            ...this.state,
            toggleUIController: {
                ...this.state.toggleUIController,
                isMeetingSummaryEdit: !this.state.toggleUIController.isMeetingSummaryEdit
            },
            ajaxParams: {
                ...this.state.ajaxParams,
                meeting_summary: summary,
            }
        })
    }

    editSummaryEdit = async () => {
        if (this.state.userIsAdmin && !this.state.toggleUIController.isMeetingSummaryEdit) {
            this.setState({
                ...this.state,
                toggleUIController: {
                    ...this.state.toggleUIController,
                    isMeetingSummaryEdit: !this.state.toggleUIController.isMeetingSummaryEdit
                }
            })
        } else if (this.state.userIsAdmin) {
            if (!window.confirm('변경하시겠습니까?')) return;

            const allUrls = await GetAjaxURL();
            const url = allUrls.RRR_ReactEditor;

            const formData = new FormData();
            formData.append('code', 'meeting_summary');
            formData.append('id', this.state.teacherId);
            formData.append('contents', this.state.ajaxParams.meeting_summary);

            const req = await axios.post(url, formData, postConfig).catch((err) => { return console.log(err) });
            if (req) {
                if (req.data) {
                    let msg = req.data.msg;
                    if (req.data.status === 'success') {
                        showNotify('업데이트 되었습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                    } else {
                        showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                    }
                }
            }
            this.setState({
                ...this.state,
                originData: {
                    ...this.state.originData,
                    meeting_summary: this.state.ajaxParams.meeting_summary
                },
                toggleUIController: {
                    ...this.state.toggleUIController,
                    isMeetingSummaryEdit: !this.state.toggleUIController.isMeetingSummaryEdit
                }
            })
        }
    }

    callbackSummary = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            ajaxParams: {
                ...this.state.ajaxParams,
                meeting_summary: value
            }
        })
    }

    // For Admin END


    createPart_C_S_Intro = () => {

        if (this.state.ajaxParams.about_myself === null) return;

        let tpMyself = this.state.ajaxParams.about_myself;
        let foldBtn;
        let needToggle = false;
        if (!this.state.toggleUIController.isNeedIntro) {
            if (tpMyself.length > 400) {
                tpMyself = tpMyself.substr(0, 400) + '...';
                needToggle = true;
            }
            // UNFOLD
            foldBtn = <span className='tp-profile-wqpr'>
                <FontAwesomeIcon icon={faAngleDoubleRight} />
            </span>;
        } else {
            // FOLD
            foldBtn = <span className='tp-profile-wqpr'>
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </span>;
        }

        const showAndHide = this.state.toggleUIController.isIntroEdit;
        let displayButten = 'Edit';
        if (showAndHide) {
            displayButten = 'Done';
        }


        return (
            <div className='tp-profile-intro'>
                <h6 className="tp-profile-font-size11">*<TrasLastUpdated />* {this.state.ajaxParams.tp_myself_update.split(' ')[0]}</h6>
                <h4 className="blue-font-color dynamic-font-size"><TrasAboutMyself />
                    {
                        this.state.userIsAdmin ?
                            <input className='comm-btn-style' type='button' onClick={this.editIntro} value={displayButten} />
                            : ''
                    }
                    {
                        showAndHide ?
                            <input className='comm-btn-style' type='button' onClick={this.editIntroCancel} value={'Cancel'} />
                            : ''
                    }
                </h4>

                <TextAreaWapper
                    className={'tp-profile-text-edit-wapper'}
                    showAndHide={this.state.toggleUIController.isIntroEdit}
                    data={this.state.ajaxParams.about_myself}
                    displayer={tpMyself}
                    callback={this.callbackIntro}
                />

                {/* <div className='tp-profile-sjobGood'>
                    {
                        isIntroEdit?
                            <TextAreaEdits className='tp-profile-text-full' data={this.state.ajaxParams.about_myself}/>
                            // <textarea className='tp-profile-text-full' value={this.state.ajaxParams.about_myself}></textarea>
                        :
                        tpMyself

                    }
                </div> */}

                {needToggle ?
                    <span onClick={this.toggleIntro}>
                        {foldBtn}
                    </span>
                    : ''
                }


            </div>
        )
    }


    createPart_C = () => {
        return (
            <div className='tp-part-c-wapper'>
                {this.createPart_C_S_Intro()}
                {this.createPart_C_S_TeachingEx()}
                {this.createPart_C_Meeting_summary()}
                {this.createPart_C_S_Reviews()}
                {this.createPart_C_S_Reviews_Admin()}
            </div>
        )
    }


    clickToGet = (e) => {

        const target = e.target;
        let tmepKey = target.dataset['key'];

        if (!tmepKey) {

            let i = 0;
            let tParent = target.parentNode;
            while (tParent) {
                tmepKey = tParent.dataset['key'];

                if (tmepKey) break;
                if (i > 5) break;
                i++;
                tParent = tParent.parentNode;
            }

        }
        window.location.href = "/#/mooc/" + tmepKey;

    }
    createPart_B_S_Mooc = () => {

        if (!this.state.moocs) return;
        if (this.state.moocs.length === 0) return;

        const moocs = this.state.moocs;


        const sImgs = '';
        const tepArr = [];
        for (let i = 0; i < moocs.length; i++) {

            const tpImg = sImgs + moocs[i]['tp_img'];

            const tpTitles = moocs[i]['topic'];
            const tpName = moocs[i]['last_name'] + moocs[i]['first_name'];
            const mindKey = moocs[i]['id'];

            const tpcComponent = <div className="tp-profile-sqs-player tp-profile-times-sqs" key={'keys_' + i} onClick={this.clickToGet} data-key={mindKey}>
                <div className='tp-profile-wta'>
                    <img src={`${tpImg}`} alt={tpName} style={{ height: "150px", width: "100%" }} onError={function (e) { e.target.src = NoImgContens; }} />
                </div>

                <div className="caption">
                    <div className="blog-info-height">
                        <p className="tp-p-titile">
                            {tpTitles}
                        </p>
                    </div>
                    <div className='tp-profile-s-time' style={{ marginRight: "0px", display: "inline-block" }}>
                        <span><TrasTutor /></span>
                        <span>{tpName}</span>
                    </div>

                </div>
            </div>;
            tepArr.push(tpcComponent);
        }


        return (

            <div className='tp-side-pans'>
                <h4 className="blue-font-color tp-profile-font-size18 dynamic-font-size tp-profile-side-left">
                    <TrasMyMOOC />
                </h4>

                <div className='tp-profile-time-wqr'>
                    {tepArr}
                </div>

            </div>
        )
    }


    clickBlogs = (e) => {

        const target = e.target;
        let dataKey = target.dataset['key'];

        if (!dataKey) {

            let i = 0;
            let tpPnode = target.parentNode;
            while (tpPnode) {
                dataKey = tpPnode.dataset['key'];
                if (dataKey) break;
                if (i > 5) break;
                tpPnode = tpPnode.parentNode;
                i++;
            }
        }

        window.location.href = '/#/blogs/' + dataKey;

    }

    createPart_B_S_Blogs = () => {

        if (!this.state.blogs) return;
        if (this.state.blogs.length === 0) return;

        const blogs = this.state.blogs;


        const bUrl = 'https://s3.ap-northeast-2.amazonaws.com/gurume-blog/';
        const tmepArr = [];
        for (let i = 0; i < blogs.length; i++) {
            const tpImg = bUrl + blogs[i]['image'];
            const tpTitles = blogs[i]['title_kr'];
            const tpMyself = blogs[i]['intro_kr'];
            const tpDateTime = blogs[i]['created_at'].split(' ')[0];
            const tpName = blogs[i]['last_name'] + blogs[i]['first_name'];
            const tpIdKey = blogs[i]['id'];

            const tpcComponent = <div className="tp-profile-sqs-player tp-profile-times-sqs" key={'tmepArr_' + i} onClick={this.clickBlogs} data-key={tpIdKey}>
                <div className='tp-profile-wta'>
                    <img src={`${tpImg}`} alt={tpName} style={{ height: "150px", width: "100%" }} onError={function (e) { e.target.src = 'https://via.placeholder.com/250x150?text=No+Image'; }} />
                </div>

                <div className="tp-profile-blog">
                    <p className='tp-b-date italic gray3-font-color'>{tpDateTime}</p>
                    <div className="blog-info-height">
                        <p className="tp-b-title">
                            {tpTitles}
                        </p>
                        <p className="tp-b-content">{tpMyself}</p>
                    </div>
                    <div className='tp-profile-b-time' style={{ marginRight: "0px", display: "inline-block" }}>
                        <p><TrasTutor />
                        &nbsp;
                        <b>{tpName}</b>
                        </p>
                    </div>

                </div>
            </div>;
            tmepArr.push(tpcComponent);
        }




        return (

            <div className='tp-side-pans'>
                <h4 className="blue-font-color tp-profile-font-size18 dynamic-font-size tp-profile-side-left">
                    <TrasBlog />
                </h4>

                <div className='tp-profile-time-wqr'>
                    {tmepArr}
                </div>
            </div>

        )

    }



    clickDetail = (e) => {
        const target = e.target;
        let dataKey = target.dataset['key'];

        if (!dataKey) {
            let i = 0;
            let curPa = target.parentNode;
            while (curPa) {
                dataKey = curPa.dataset['key'];
                if (dataKey) {
                    break;
                }
                if (i < 5) break;
                i++;
            }
        }

        window.location.href = '/#/group_class/' + dataKey;

    }

    createPart_B_S_Group_Class = () => {

        if (!this.state.groupClasses) return;
        if (this.state.groupClasses.length === 0) return;

        const groupClasses = this.state.groupClasses;

        const tempArr = [];

        for (let i = 0; i < groupClasses.length; i++) {
            const tpTitles = groupClasses[i]['title'];
            const tpDateTime = groupClasses[i]['start_time'] + ' ~ ' + groupClasses[i]['end_time'];
            const tpLocation = groupClasses[i]['location'];
            const tpName = groupClasses[i]['last_name'] + groupClasses[i]['first_name'];
            const tpIdKey = groupClasses[i]['id'];
            let tpImg = '';
            if (groupClasses[i]['classroom'] !== '') {
                if (groupClasses[i]['classroom'].indexOf('http') === -1) {
                    tpImg = 'https://s3.ap-northeast-2.amazonaws.com/gurume-groupclass-classroom/' + groupClasses[i]['classroom'];
                } else {
                    tpImg = groupClasses[i]['classroom'];
                }
            } else if (groupClasses[i]['textbook'] !== '') {
                if (groupClasses[i]['classroom'].indexOf('http') === -1) {
                    tpImg = 'https://s3.ap-northeast-2.amazonaws.com/gurume-groupclass-textbook/' + groupClasses[i]['textbook'];
                } else {
                    tpImg = groupClasses[i]['textbook'];
                }
            } else if (groupClasses[i]['thumbnail'] !== '') {
                if (groupClasses[i]['classroom'].indexOf('http') === -1) {
                    tpImg = 'https://s3.ap-northeast-2.amazonaws.com/gurume-groupclass-thumbnail/' + groupClasses[i]['thumbnail'];
                } else {
                    tpImg = groupClasses[i]['thumbnail'];
                }
            }

            const tpcComponent = <div className="tp-profile-sqs-player tp-profile-times-sqs" key={'tempArr_' + i} onClick={this.clickDetail} data-key={tpIdKey}>
                <div className='tp-profile-wta' onClick={this.clickDetail} data-key={tpIdKey}>
                    <img src={`${tpImg}`} alt={tpName} style={{ height: "150px", width: "100%" }} onError={function (e) { e.target.src = NoImgContens; }} />
                </div>

                <div className="tp-profile-group">
                    <div className="blog-info-height">
                        <p className="tp-g-title" onClick={this.clickDetail} data-key={tpIdKey}>
                            {tpTitles}
                        </p>
                        {/*
                        <p className='tp-dating-time-with-me'>{tpDateTime}</p>
                        <p>{tpLocation}</p> */}
                    </div>
                    <div className='tp-g-tutor' style={{ marginRight: "0px", display: "inline-block" }}>
                        <p onClick={this.clickDetail} data-key={tpIdKey}><TrasTutor />
                        &nbsp;
                        <b>{tpName}</b>
                        </p>
                    </div>
                    {/* <div className='tp-profile-btns'>
                        <button className="btn btn-default" onClick={this.clickDetail} data-key={tpIdKey}>
                            <TrasDetails />
                        </button>
                    </div> */}
                </div>
            </div>;

            tempArr.push(tpcComponent);
        }




        return (

            <div className="tp-side-pans">
                <h4 className="blue-font-color tp-profile-font-size18 dynamic-font-size tp-profile-side-left">
                    {/* {{'Group className'|translate}} */}
                    <TrasGroupClass />
                </h4>
                <div className="tp-profile-sjob-wp">
                    {tempArr}
                </div>
            </div>
        )
    }

    showAlertToMsgs = () => {
        showNotify("데모 영상은 학생, 학부모님에게만 공유됩니다.", STATE_NOTIFY.TYPE_POSITIVE);
    }
    createPart_B_S_Demo = () => {
        const tpVedio = this.state.videoData;
        const tpThumbnailImgUrls = [];

        for (let i = 0; i < tpVedio.length; i++) {
            let sptu = tpVedio[i].split('/');
            let tpUrlsImg = sptu[4].substring(0, 11);
            tpThumbnailImgUrls.push("https://img.youtube.com/vi/" + tpUrlsImg + "/mqdefault.jpg");
        }

        if (tpThumbnailImgUrls.length === 0) return;


        const tempDiv = [];
        const temp = '&showinfo=0&enablejsapi=1&origin=http://localhost:3000';

        for (let i = 0; i < tpThumbnailImgUrls.length; i++) {
            tempDiv.push(
                <div className='tp-profile-sqs-player' key={i}>
                    {this.state.userIsAdmin || this.state.thisUserType === 'student' ?
                        <div className="margin-bottom-10" style={{ position: "relative" }}>
                            <ReactPlayer
                                className='good-player'
                                url={tpVedio[i] + temp}
                                width='100%'
                                height='100%'
                            />
                        </div>
                        :
                        <div className="margin-bottom-10" style={{ position: "relative" }} onClick={this.showAlertToMsgs}>
                            <img src={tpThumbnailImgUrls[i]} alt={'thumbnailImgUrl'} className="img-responsive img-youtube-thumbnail" onError={function (e) { e.target.src = NoImgContens; }} />
                            <button className="ytp-large-play-button ytp-button" aria-label="Play">
                                <svg>
                                    <path className="ytp-large-play-button-bg"
                                        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                        fill="#212121" fillOpacity="0.8"></path>
                                    <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                                </svg>
                            </button>
                        </div>
                    }

                </div>
            )
        }

        return (
            <div className="tp-side-pans">
                <h4 className="blue-font-color tp-profile-font-size18 tp-profile-side-left dynamic-font-size">
                    <TrasDemoClassVideo />
                </h4>
                <div id="player"></div>

                {/* AS student Login user */}
                {tempDiv}
            </div>
        )
    }


    clickToFee = () => {
        window.location.href = '/#/how_it_works/tuition';
    }

    clickToSendMail = async (e) => {


        const allUrls = await GetAjaxURL();
        const isLg = allUrls.isLogin;

        const Response = await axios.get(isLg).catch((err) => { return console.log(err) });

        const isLogin = Response.data.isLogin;
        if (isLogin !== "true") {
            const tempMsg = this.state.lang === 'ko' ? '로그인 후에 이용이 가능합니다.' : this.state.lang === 'en' ? 'Please Login' : '请登录';
            showNotify(tempMsg, STATE_NOTIFY.TYPE_NEGATIVIE);
            window.location.href = '/#/login?redirect=teacher&teacher_id=' + this.state.teacherId;
            return;
        }

        const theType = Response.data.type;

        if (theType !== "student") {
            const tempMsg = '학생 회원만 선생님에게 문의를 보낼 수 있습니다.';
            showNotify(tempMsg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        this.setState({
            ...this.state,
            showSendingMsg: !this.state.showSendingMsg
        });

    }

    AddMessage = (e) => {
        const target = e.target;
        if (target.id === 'TaItemList') {
            this.setState({
                ...this.state,
                showSendingMsg: !this.state.showSendingMsg
            });
        }
    }

    sendToMsgToMe2 = () => {
        this.setState({
            ...this.state,
            showSendingMsg: !this.state.showSendingMsg
        });
    }

    goToKakaoChannel = () => {
        window.open("https://pf.kakao.com/_ddvDV")
    }
    createPart_B = () => {
        return (
            <div className='tp-side-pans tp-profile-g-wrapper'>
                <div className='tp-profile-g-pn'>
                    <span className='tp-profile-font-size12'><TrasDoyoulikethisteacherthencontactteacher />&nbsp;</span>
                    {/* {this.state.ajaxParams.tp_Demo ?
                        <p className="font-size-12">*무료 데모수업 가능*</p>
                        : ''
                    } */}
                    {/* <span onClick={this.clickToFee}>(수업료?)</span> */}
                    <ul>
                        <li className="tp-tuition" onClick={this.clickToSendMail}>
                            <span>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                            <TrasTuitionRequest />
                        </li>
                        {
                            this.state.ajaxParams.tp_Demo ?
                                <li
                                    className="tp-demo"
                                    data-event='click focus'
                                    data-for='datalinke'
                                    data-tip
                                >
                                    <span>
                                        <FontAwesomeIcon icon={faMobileAlt} />
                                    </span>
                            무료 데모수업
                        </li>
                                : ''
                        }
                        {/* <li className="tp-kakao" onClick={this.goToKakaoChannel}>
                            <span><img src={kakaoIcon} /></span>카카오톡
                        </li> */}
                        <ReactTooltip effect="solid" place="top" globalEventOff='click' id='datalinke' type='light'
                            getContent={() => {
                                const tempMsg =
                                    <div className="free-demo-description">
                                        <p>
                                            무료 데모수업은 모든 선생님께서 제공하는 것이 아니며 특정 선생님께만 제공합니다.<br />
                                        그루미 고객센터에 희망하시는 선생님 성함을 말씀해주시며 무료 데모수업 신청을 해주시거나<br />
                                        선생님께 직접 수업신청을 해주세요
                                        </p>
                                    </div>
                                return tempMsg;
                            }}
                        />

                    </ul>
                </div>
            </div>

        )
    }


    createBody = () => {
        return (
            <div className="tp-profile-body-w" style={{ textAlign: "center" }}>
                <div className='tp-profile-body-bg'>
                    <img src={bgImg} alt='...' onError={function (e) { e.target.src = NoImgContens; }} />
                </div>
                <div className='tp-profile-contents-wapper'>
                    <div className='tp-profile-layer-a'>
                        {this.createPart_A()}
                        {this.createPart_C()}
                    </div>
                    <div className='tp-profile-layer-b'>
                        {this.createPart_B()}
                        {this.createPart_B_S_Demo()}
                        {this.createPart_B_S_Group_Class()}
                        {this.createPart_B_S_Blogs()}
                        {this.createPart_B_S_Mooc()}
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                {this.createBody()}
            </div>
        )
    }

    render() {
        return (
            <div className="default-wrapper">
                {
                    this.state.isDone ?
                        this.createHtml()
                        : <div className="teacher-profile-loading">
                            <div className="loader"></div>
                        </div>
                }

                {this.state.showSendingMsg ?
                    <TApplyMsgs
                        AddMessage={this.AddMessage}
                        sendToMsgToMe2={this.sendToMsgToMe2}
                        teacherId={Number(this.state.teacherId)}
                    />
                    : ''
                }

                {
                    this.state.needShowCertificatePannel ?
                        this.showAndHideCertification()
                        : ''
                }

            </div>
        )
    }

}

export default withRouter(Teacherprofile);