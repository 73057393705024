import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { GetAjaxURL } from '../../../config/AjaxConfig';
import {
    TrasHome,
    TrasGroupClass,
    TrasTutor,
    TrasDetails
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { NoImgContens } from '../../../config/AjaxConfig';

// css
import './groupclass.css';

class GroupClassShow extends Component {
    state = {
        lang: window.localStorage.lang || 'ko',
        groupClassList: []
    }

    componentDidMount = async () => {
        await this.getData();
    }

    getData = async () => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.groupclass.list;
        const res = await axios.get(url).catch((err) => { return err; })
        let data = res.data;
        let groupClassList = data;
        let ongoingGroupClassShowList = [];
        let finishedGroupClassShowList = [];
        let unapprovedGroupClassShowList = [];
        for (let i in groupClassList) {
            let splittedEndTimeStrs = groupClassList[i].end_time.split('-');
            let endTime = new Date(splittedEndTimeStrs[0], splittedEndTimeStrs[1], splittedEndTimeStrs[2]);
            let currentTime = new Date();
            if (endTime.getTime() > currentTime.getTime()) {
                if (String(groupClassList[i].approved) === '0') {
                    unapprovedGroupClassShowList.push(groupClassList[i]);
                } else {
                    ongoingGroupClassShowList.push(groupClassList[i]);
                }
            } else {
                if (String(groupClassList[i].approved) === '0') {
                    unapprovedGroupClassShowList.push(groupClassList[i]);
                } else {
                    groupClassList[i].finish = true;
                    finishedGroupClassShowList.push(groupClassList[i]);
                }
            }
        }

        this.setState({
            groupClassList: [...unapprovedGroupClassShowList, ...ongoingGroupClassShowList, ...finishedGroupClassShowList]
        })
    }

    createBreadcrumb = () => {
        return (
            <div className="container">
                <ol className="breadcrumb mooc-index-breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasGroupClass /></li>
                </ol>
            </div>
        )
    }

    gotoGroupClassShowShow = (e) => {
        let target = e.target;

        let gpc_id = target.dataset['groupClassId'];

        if (gpc_id === undefined) {
            let i = 0;
            while (gpc_id === undefined) {

                target = target.parentNode;
                gpc_id = target.dataset['groupClassId'];

                if (i > 10) break;
            }
        }

        if (gpc_id !== undefined) {
            window.location.href = '/#/group_class/' + gpc_id;
        }

    }

    createContentHtml = () => {
        const { lang, groupClassList } = this.state;

        return (
            <div className="container">
                <div className="row">
                    {
                        groupClassList.map((item, index) =>
                            <div className="col-sm-12 col-md-4 col-lg-3 group-class-list-item" key={'groupclass' + index}
                                id='gpc_id'
                                data-group-class-id={item.id} onClick={this.gotoGroupClassShowShow}
                            >
                                <div className="card">
                                    {
                                        item.finish ?
                                            <div className="ribbon-wrapper margin-right-15">
                                                <div className="ribbon ribbon-red">
                                                    <div>
                                                        종료
                                                </div>
                                                </div>
                                            </div> : ''
                                    }
                                    <div className="position-relative">
                                        <div className="group-class-list-img r-group-class-anim-img">
                                            <img alt="img" className="group-class-img" src={item.thumbnail} onError={function (e) { e.target.src = NoImgContens; }} />
                                        </div>
                                        {
                                            item.youtube_embed_urls && item.youtube_embed_urls.length > 0 ?
                                                <button className="ytp-large-play-button ytp-button" aria-label="Play">
                                                    <svg>
                                                        <path className="ytp-large-play-button-bg"
                                                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                                            fill="#212121" fillOpacity="0.8"></path>
                                                        <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                                                    </svg>
                                                </button> : ''
                                        }
                                    </div>
                                    <div className="card-body">
                                        <div className="blog-info-height">
                                            <h4 className="r-group-title margin-bottom-10">{item.title}</h4>
                                            {/* {
                                                item.start_time === '2000-1-1' ? 
                                                <p className="card-text margin-bottom-10">상시 수업중</p> : 
                                                <p className="card-text margin-bottom-10">{item.start_time} ~ {item.end_time}</p>
                                            }
                                            <p className="card-text margin-bottom-10">@{item.location}</p> */}
                                        </div>
                                        <div className="r-group-tutor">
                                            <div className="card-text">
                                                <p><TrasTutor />
                                                &nbsp;
                                                <b>{lang === 'en' ? item.tutor.first_name_en + item.tutor.last_name_en : item.tutor.last_name + item.tutor.first_name}</b>
                                                </p>
                                            </div>
                                            {/* <a href={'/#/group_class/' + item.id} className="btn btn-outline-primary float-right">
                                            {String(item.approved) === '0' ? '미승인' : <TrasDetails/>} 
                                            </a> */}
                                        </div>
                                        {/* <button className="btn btn-outline-primary float-right">미승인</button> */}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <div className="group-class-list-container">
                {this.createBreadcrumb()}
                {this.createContentHtml()}
            </div>
        )
    }
}

export default withRouter(GroupClassShow);
