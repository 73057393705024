import React, { Component } from 'react';
import {
    TrasHome,
    TrasSiteUsage,
    TrasTuitionFee,
    TrasTeachingExperience,
    TrasUniversityStudent,
    Trasgraduate,
    TrasLessthan2years,
    Tras1stcriterias,
    Tras2ndcriterias,
    TrasMorethan2years,
    Tras3rdcriterias,
    TrasAboveisageneralguidelinebuttuitionfeehourlyrateisdfferentforeachtutorandmaychangebasedontuitionperiodcurriculumandofflinevsonline,
    TrasClassRules,
    TrasDependsonduration,
    TrasExtra510minutesattheendorbeginningofnextlesson,
    TrasLessthan10mins,
    TrasLate,
    TrasMorethan10mins,
    TrasLatetimecountedaslessonconducted,
    TrasNextlessonconductedfreeofchargebythelatetimeamount,
    TrasReschedule,
    Tras05hourcountedaslessonconducted,
    TrasNext05hourlessonconductedfreeofcharge,
    TrasLessthan2hournotice,
    TrasCancellation,
    Tras1hourcountedaslessonconducted,
    TrasNext1hourlessonconductedfreeofcharge,
    TrasAbsence,
    TrasNonotice,
    TrasCountedaslessonconducted,
    TrasNextlessonconductedfreeofcharge,
    Tras1stcriteriat,
    Tras2ndcriteriat,
    Tras3rdcriteriat,

} from '../../Translate/EachTranslateC';

import '../../default.css';
import './siteusage.css';




// DONE
class siteusage extends Component {

    state = {

        currentTabs : 'TUITIONS',
        TYPE_TABS : {
            TUITIONS : 'TUITIONS',
            USAGE_FEE : 'USAGE_FEE',
            INVOICE : 'INVOICE',
            PROGRESS_REPORT : 'PROGRESS_REPORT',
            TUITION_PAYMENT : 'TUITION_PAYMENT',
            INCREASE_LIKELIHOOD : 'INCREASE_LIKELIHOOD',

        }        

    }


    componentDidMount = () => {
        if(document.getElementById('siteRemote'))
        document.getElementById('siteRemote').classList.add('active');
    }


    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasSiteUsage /></li>
                </ol>
            </div>
        )
    }

    createFirst = () => {
        return(
            <div>
                <div className="qa-message-list" id="wallmessages">
                    <div className="message-item site-type-a" id="m3">
                        <div className="message-inner">
                            <div className="message-head">
                                <div className="user-detail">
                                    <h3 className="handle site-remote-blue">수업 문의/상담</h3>
                                </div>
                            </div>
                            <div className="qa-message-content site-type-b">
                                <p>1. 학생/학부모님은 550+명의 다양한 선생님 프로필 열람 혹은 수업신청 공고 게시/선생님은 수업 지원</p>
                                <p>2. 사이트 쪽지 및 전화/카톡을 통하여 수업 시간/기간/장소/비용 등 상담</p>
                                <p>3. 데모수업 (옵션) - 데모수업을 통해 학생과 선생님 화합 및 수업 적합성 판단</p>
                            </div>
                        </div>
                    </div>
            
                    <div className='site-normal-component'>
                        <h2 className='site-comp-memo' ><span className='site-component-head'>데모</span>수업</h2>
                        <p className='site-type-c'><strong>“ 1.5~2시간 면대면/온라인 수업에서 30분을 무료 ”</strong></p>
                        <p>와 같은 규정은 무료 데모수업을 제공하시는 분도 계시는 반면에 데모수업료를 받으시는 선생님도 계시기 때문입니다. 하지만 선생님과 학생사이에 합의하에 시간/수업료 유무 여부 결정가능합니다.</p>
                    </div>
                   
                    <div className="message-item site-type-a" id="m3">
                        <div className="message-inner">
                            <div className="message-head">
                                <div className="user-detail">
                                    <h3 className="handle site-remote-blue">수업 확정</h3>
                                </div>
                            </div>
                            <div className="qa-message-content site-type-b">
                                <p>4. 수업료 인보이스 - 선생님께서 학생/학부모님께 그루미 수업료 인보이스 발행</p>
                                <p>5. 수업료 입금 - 인보이스 내역대로 그루미 계좌로 수업료 입금 “신한은행 110-338-435020 이혁진(그루미)”</p>
                                <br />
                            </div>
                        </div>
                    </div>
            
                    <div className='site-normal-component'>
                        <h2 className='site-comp-memo' >수업료 인보이스</h2>
                        <p className='site-type-c'>
                            <strong>1. 한달 미만 단기수업</strong>
                            <br />
                            <strong>2. 유동적 스케줄링 수업</strong>
                        </p>
                        <p>선생님은 그루미 사이트에 로그인하신 후 마이페이지에서 수업료 인보이스 발행 후 학부모님께 전달합니다.</p>
                        <p>유료 데모수업일 경우 마찬가지로 데모수업료 인보이스를 발행합니다.</p>
                        <p>수업료 인보이스는 보통 한달 단위로 발행되지만 위와 같은 예외가 있을 수 있습니다</p>
                    </div>
          
                    <div className='site-normal-component-sec'>
                        <h2 className='site-comp-memo' >선불 수업료</h2>
                        <p className='site-type-c'>
                            <strong>1. 수업 불평/우려사항 처리</strong>
                            <br />
                            <strong>2. 선생님 재배정 혹은 수업료 환불 (필요시)</strong>
                        </p>
                        <p>수업료는 그루미에게 선불 입금 그루미에서 선생님께 후불 입금 시스템으로 관리된 수업을 중재합니다.</p>
                        <p>유료 데모수업일 경우 마찬가지로 데모수업료 인보이스를 발행합니다.</p>
                        <p>*학생/학부모님은 첫 수업 전 수업료 지불/선생님은 수업진행 상태 확인하며 미입금일 경우 그루미 혹은 학부모님께 수업료 입금 요청 (수업이 진행 되었는데 수업료 미입금 상태에서 학부모님께서 withdraw 하시면 선생님 onus/responsibility)</p>
                    </div>

                    <div className='site-type-component-a'>
                        <h2 className='site-memo-color-blue'>수업 스케줄</h2>
                        <div className='site-comp-info'>기간<span>기간</span></div>
                        <p>학생/학부모님께서 수업 문의 당시 희망 수업 기간을 명시해주시고 선생님께서 수업진행에 동의하시면 선생님께서는 그 기간동안 수업에 대한 책임이 있습니다. 허나 피치못할 개인적인 이유로 수업을 조기종료 될 수 있으며 대체 선생님 배정을 위해 그루미 수업 매니저에게 최소 3주 전에 통지합니다.</p>
                        <p>*재학생의 경우 시험기간은 개인적인 이유가 되지 못합니다. 즉 제시된 희망 수업 기간에 학교시험기간이 포함되어있으면 선생님은 제한적인 기간동안만 수업 가능여부에 대해 말씀을 드리고 학생/학부모님은 제한적인 기간으로 수업을 진행하시거나 새로운 선생님께 문의를 합니다.</p>
                
                        <div className='site-comp-info'>유동적 스케줄<span>유동적 스케줄</span></div>
                        <p>학생이 주기적인 수업이 아닌 유동성있는 수업 스케줄을 원할시 유동성 스케줄이 가능하신 선생님과 매칭이 매우 중요합니다. 바쁜 스케줄을 가지고 있는 선생님과 수업 스케줄을 유동적으로 정하는건 매우 어렵고 수업시간 정하는데 너무 힘들다는 불평사항이 생길 수 있습니다. 때문에 양쪽은 수업 확정전에 유동성 스케줄에 commit 할 수 있는지 재고려를 건의 드립니다.</p>
                        <p>유동적인 스케줄이란 희망수업 최소 3일 전에 (하루 전이 아닌) 학생측에서 선생님께 수업 요청을 하는 것입니다. 학교 내신시험 준비용 수업일 경우 선생님이 요청하신 수업날에 시간이 안될 수도 있는 만일에 대해 안전하게 1주일 전에 요청하는 법이 있습니다.</p>
                        <p>하루전에 수업요청도 가능하지만 전문 선생님이 아니신 이상 수업에 대한 준비가 필요합니다. 하루전 수업요청이 가능한 유동적 스케줄링을 원하신다면 그루미 풀타임/전문 선생님과 수업을 권장합니다.</p>
                    </div>
                  
                    <div className="message-item site-type-a" id="m3">
                        <div className="message-inner">
                            <div className="message-head">
                                <div className="user-detail">
                                    <h3 className="handle site-remote-blue">수업 진행</h3>
                                </div>
                            </div>
                            <div className="qa-message-content site-type-b">
                                <p>5. 면대면 혹은 온라인 (스카입 + 온라인 칠판 + 터치패드 사용)</p>
                                <p>6. 수업 보고서 (Progress report) - 수업 진도내용 및 학업 현황 보고</p>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className="message-item site-type-a" id="m4">
                        <div className="message-inner">
                            <div className="message-head">
                                <div className="user-detail">
                                    <h3 className="handle site-remote-blue">수업 완료</h3>
                                </div>
                            </div>
                            <div className="qa-message-content site-type-b">
                                <p>7. 수업 완료 확인 - 학생/학부모님께서 수업완료 확인 중 수업후기를 적어주실 수 있습니다</p>
                                <p>8. 수업료 지급 - 그루미에서 선생님에게 수업료 계좌이체</p>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>

            <div className='site-padding-15'>
                <h2 className="margin-top-10 site-comp-memo"><TrasTuitionFee /></h2>
                    <table className="table">
                        <thead>
                            <tr>
                                <td><p><b><TrasTeachingExperience /></b></p></td>
                                <td><p><b><TrasUniversityStudent /></b></p></td>
                                <td><p><b>전문강사</b></p></td>
                            </tr>
                        </thead>
                    <tbody>
                    <tr>
                        <td><p><TrasLessthan2years /></p></td>
                        <td><p><Tras1stcriterias /></p></td>
                        <td><p><Tras2ndcriterias /></p></td>
                    </tr>
                    <tr>
                        <td><p><TrasMorethan2years /></p></td>
                        <td><p><Tras2ndcriterias /></p></td>
                        <td><p><Tras3rdcriterias /></p></td>
                    </tr>
                    </tbody>
                    </table>
                    <p>
                        <TrasAboveisageneralguidelinebuttuitionfeehourlyrateisdfferentforeachtutorandmaychangebasedontuitionperiodcurriculumandofflinevsonline />
                    </p>
                    <br />
            
                    <h2 className='site-memo-color-blue'> <TrasClassRules /> </h2>
                    <p>지각/시간 재조정/취소/결석 페널티 규정은 빈번하지 않을 경우 학생과 선생님 상호 합의하에 넘어갈 수 있습니다만 필요시 그루미에서 적용할 수 있습니다.</p>
                    <table className="table">
                        <thead>
                            <tr>
                                <td><p><b></b></p></td>
                                <td><p><b>학생</b></p></td>
                                <td><p><b>선생님</b></p></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><p>연락두절 (2일이상)</p></td>
                                <td><p>해당사항 무 (단, 선생님께서 수업을 포기할 수 있습니다.)</p></td>
                                <td><p><TrasDependsonduration /></p></td>
                            </tr>
                            <tr>
                                <td><p>지각<br /> (<TrasLessthan10mins />)</p></td>
                                <td><p><TrasExtra510minutesattheendorbeginningofnextlesson /></p></td>
                                <td><p><TrasExtra510minutesattheendorbeginningofnextlesson /></p></td>
                            </tr>
                            <tr>
                                <td><p><TrasLate /><br /> (<TrasMorethan10mins />)</p></td>
                                <td><p><TrasLatetimecountedaslessonconducted /></p></td>
                                <td><p><TrasNextlessonconductedfreeofchargebythelatetimeamount /></p></td>
                            </tr>
                            <tr>
                                <td><p><TrasReschedule /><br /> (<TrasLessthan2hournotice />)</p></td>
                                <td><p><Tras05hourcountedaslessonconducted /></p></td>
                                <td><p><TrasNext05hourlessonconductedfreeofcharge /></p></td>
                            </tr>
                            <tr>
                                <td><p><TrasCancellation /><br /> (<TrasLessthan2hournotice />)</p></td>
                                <td><p><Tras1hourcountedaslessonconducted /></p></td>
                                <td><p><TrasNext1hourlessonconductedfreeofcharge /></p></td>
                            </tr>
                            <tr>
                                <td><p><TrasAbsence /><br /> (<TrasNonotice />)</p></td>
                                <td><p><TrasCountedaslessonconducted /></p></td>
                                <td><p><TrasNextlessonconductedfreeofcharge /></p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        
        
                <p>리스케줄 및 취소는 최소 2시간 전에는 커뮤니케이션이 되어야 학생/선생님 모두 새로운 계획/스케줄을 정할 수 있습니다.</p>
                <p>리스케줄, 취소, 결석에 대해 학생은 환불/추가 수업시간을 요청할 수 있으며 선생님은 추가 수업료 인보이스/수업시간 감소를 요청할 수 있습니다.</p>
                <br />
        
                <div className='site-normal-component-sec'>
                    <h2 className='site-memo-color-blue'>수업 보고서 (Progress Report)</h2>
                    <p>수업확정이 되면 구글 닥을 기반으로 하고 있는 수업 보고서 링크가 선생님과 학생/학부모님 마이페이지에 생성이 됩니다. 선생님께서 매 수업 진도내용 및 월별 학업 성취도/현황 evaluation을 적어주시면 학생/학부모님께서 읽어보실 수 있습니다.</p>
                    <p>수업커리큘럼을 계획하실시 미리 작성 혹은 매 수업 후 작성함으로서 체계적인 수업진행을 합니다. 수업보고서 미작성일 경우 수업료 지급은 보류됩니다.</p>
                    <p>*선생님의 편안한 수업 보고서 작성을 위해 모바일에 구글닥 설치를 추천드립니다.</p>
                    <br />
                </div>
        
                <div className='site-normal-component-sec'>
                    <h2 className='site-comp-memo'>컴플레인</h2>
                    <p>수업을 하면서 학생, 선생님 양쪽 서로에게</p>
                    <p>1. Punctuality</p>
                    <p>2. 불성실한 수업 준비/태도</p>
                    <p>3. Teaching ability (선생님 해당)</p>
                    <p>관한 불평/우려가 있을 수 있는데 그루미 수업 매니저에게 연락주시면 중재하여 불평/우려사항 없애도록 하겠습니다.</p>
                    <br />
                </div>

                <div className='site-normal-component-sec'>
                    <h2 className='site-memo-color-blue'>수업 완료 확인</h2>
                    <p>선생님께서 수업완료 확인 후 학생/학부모님측에서도 수업완료 확인하시면 그루미에서 선생님께 수업료 지급</p>
                    <p>학생/학부모님께서는 수업완료 확인 하시며 수업후기를 선생님께 혹은 그루미에게 적어주실 수 있습니다.</p>
                    <p>*학생/학부모님께서 수업완료일로부터 5일내로 미확인시 수업완료로 간주도</p>
                    <br />
                </div>
        </div>


        )
    }

    clickToTabs = (e) => {

        const target = e.target;
        const dataset = target.dataset['tab'];
        if(!dataset) return;

        let currentTabs = '';
        
        if(dataset === this.state.TYPE_TABS.TUITIONS){
            currentTabs = this.state.TYPE_TABS.TUITIONS;
        }else if(dataset === this.state.TYPE_TABS.USAGE_FEE){
            currentTabs = this.state.TYPE_TABS.USAGE_FEE;
        }else if(dataset === this.state.TYPE_TABS.INVOICE){
            currentTabs = this.state.TYPE_TABS.INVOICE;
        }else if(dataset === this.state.TYPE_TABS.PROGRESS_REPORT){
            currentTabs = this.state.TYPE_TABS.PROGRESS_REPORT;
        }else if(dataset === this.state.TYPE_TABS.TUITION_PAYMENT){
            currentTabs = this.state.TYPE_TABS.TUITION_PAYMENT;
        }else if(dataset === this.state.TYPE_TABS.INCREASE_LIKELIHOOD){
            currentTabs = this.state.TYPE_TABS.INCREASE_LIKELIHOOD;
        }


        const parent = target.parentNode;
        for(let i = 0; i < parent.childElementCount; i++){
            parent.children[i].classList.remove('active');
        }
        target.classList.add('active');

        this.setState({
            ...this.state,
            currentTabs : currentTabs
        })


    }


    createSecondTabs = () => {
        return(
            <div className='site-remote-ex'>
                <div className='site-remote-contr'>
                    <ul onClick={this.clickToTabs}>
                        <li id='siteRemote' data-tab={this.state.TYPE_TABS.TUITIONS}>Tuition Fee</li>
                        <li data-tab={this.state.TYPE_TABS.USAGE_FEE}>GuruMe Usage Fee</li>
                        <li data-tab={this.state.TYPE_TABS.INVOICE}>Tuition Invoice</li>
                        <li data-tab={this.state.TYPE_TABS.PROGRESS_REPORT}>Progress Report</li>
                        <li data-tab={this.state.TYPE_TABS.TUITION_PAYMENT}>End of Tuition/Payment</li>
                        <li data-tab={this.state.TYPE_TABS.INCREASE_LIKELIHOOD}>Ways to increase the likelihood of receiving tuition enquiries</li>
                    </ul>

                    
                    <div className='site-remote-cont2'>
   
                        {
                            this.state.currentTabs === this.state.TYPE_TABS.TUITIONS?
                                this.createTuitionFee()
                            : this.state.currentTabs === this.state.TYPE_TABS.USAGE_FEE?
                                this.createGurumeUsageFee()
                            : this.state.currentTabs === this.state.TYPE_TABS.INVOICE?
                                this.createTuitionInvoice()
                            : this.state.currentTabs === this.state.TYPE_TABS.PROGRESS_REPORT?
                                this.createProgressReport()
                            : this.state.currentTabs === this.state.TYPE_TABS.TUITION_PAYMENT?
                                this.createEndOfTuitionPayment()
                            : this.state.currentTabs === this.state.TYPE_TABS.INCREASE_LIKELIHOOD?
                                this.createWaysToIncreaseTheLikelihood()
                            : 
                                ''
                        }

                    </div>
                </div>
            </div>
        )
    }



    createWaysToIncreaseTheLikelihood = () => {
        return(
            <div className="tab-pane">
                <h3 className="blue-font-color site-type-b">Ways to increase the likelihood of receiving tuition enquiries</h3>

                <p>We also have, <a href="/#/demo_className_video">Demo className Video</a>, <a href="https://docs.google.com/document/d/1STO_b4h7ocA_U4OUMm8dfrh-RvrsVsbVajs6miR9KRo/edit?usp=sharing" target="blank" className="link">Blog Posting</a>
                and
                <a href="https://docs.google.com/document/d/1U3KVUGQqer_jDiF_Mdea84eZK3GJjLDJY3SzVt1wjiE/edit?usp=sharing" target="blank" className="link">MOOC-online teaching</a> that teachers can participate and display on their profile.
                </p>

                <p>All these contents will be displayed on teacher’s profile. E.g. www.gurume.kr/teachers/14</p>
                <p>GuruMe teachers search page has an algorithm that lists teacher profiles with as many of the contents above to the top.</p>
                <p>For those interested in participating in Blog/ MOOC initiatives, please get in touch with GuruMe support to discuss about how to go on about producing these contents.</p>
            </div>
        )
    }

    createEndOfTuitionPayment = () => {
        return(
            <div className="tab-pane">
                <h3 className="blue-font-color site-type-b">End of Tuition/Payment</h3>
                <p>If student/parent does not confirm end of tuition within 5 days of tutor's confirmation, acknowledgement is assumed and payment scheduled for transfer on the coming Friday.</p>
            </div>
        )
    }

    createProgressReport = () => {
        return(
            
            <div className="tab-pane">
                <h3 className="blue-font-color site-type-b">Progress Report</h3>
                <p>When tuition fee payment is confirmed, a <a href="https://docs.google.com/document/d/10ro9LMQrot2y52hw7fr3qA_ihC6kUzETgEGyeAe7HpM/edit?usp=sharing" target="blank" className="link">progress report</a> (Google Doc) will be generated in Dashboard. On this you can make brief notes on each lesson coverage and also write the end of month feedback.</p>
                <p>Tutors must send the progress report to receive payment. Lesson records may be filled in after each lesson or filled in advanced as a planning sort </p>
                <p className='site-type-c'>*Google Doc on mobile recommended for convenience</p>
            </div>

        )
    }

    createTuitionInvoice = () => {
        return(
            <div className="tab-pane">
                <h3 className="blue-font-color site-type-b">Tuition Invoice</h3>
                <p>Before the className begins, tutors create an invoice at <a href="/#/teachers/dashboard">dashboard</a> send the <a href="/#/tuitions/bill">tuition invoice</a> link to parents. The same goes for demo className.</p>
                <p>Invoicing can either be submitted biweekly or monthly.</p>
                <p>Tuition fee will be sent to the tutors upon completion of each tuition and can be sent out via direct bank transfer or paypal</p>
            </div>
        )
    }

    createGurumeUsageFee = () => {
        return(
            <div className="tab-pane">
                <h3 className="blue-font-color site-type-b">GuruMe Usage Fee</h3>
                <p>GuruMe charges usage fee of ₩10,000/hr or 15% of university admissions service fee from the parents.</p>
                <p>When negotiating tuition fee with the parents, you should quote the <b>usage fee added price</b> to the parents.</p>
                <p>E.g. if hourly rate is ₩50,000, you should quote ₩60,000 or if admissions service fee is ₩1,000,000, quote ₩1,150,000.</p>
            </div>
        )
    }

    createTuitionFee = () => {
        return(
            <div className="tab-pane" >
                    
                <h3 className="blue-font-color site-type-b">Tuition Fee</h3>
                <table className="table">
                <thead>
                <tr>
                    <td><p><b><TrasTeachingExperience /></b></p></td>
                    <td><p><b><TrasUniversityStudent /></b></p></td>
                    <td><p><b>전문강사</b></p></td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><p><TrasLessthan2years /></p></td>
                    <td><p><Tras1stcriteriat /></p></td>
                    <td><p><Tras2ndcriteriat /></p></td>
                </tr>
                <tr>
                    <td><p><TrasMorethan2years /></p></td>
                    <td><p><Tras2ndcriteriat /></p></td>
                    <td><p><Tras3rdcriteriat /></p></td>
                </tr>
                </tbody>
                </table>
                <p><TrasAboveisageneralguidelinebuttuitionfeehourlyrateisdfferentforeachtutorandmaychangebasedontuitionperiodcurriculumandofflinevsonline /></p>
                <p>It is expected from parents that hourly rate is lower for online and for long period tuition.</p>

            </div>
        )
    }


    createSlideDown = () => {
        return(

            <div className="panel-group" id="accordion">
                <div className="panel panel-default">
                    <div className="panel-heading site-white-consulting">
                        <p className="accordion-title">
                        <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse10">Consulting</span>
                        </p>
                    </div>
                    <div id="collapse10" className="panel-collapse collapse">
                        <div className="panel-body site-border-white">
                            <p>Likely to be 1-2만원 higher as there are pre-consulting work needed such as the admissions grades stats
                                research as well as the following:</p>
                            <p>IB, Toefl, HSK 입시 조건 (현실적인 합격자들의 점수 통계)</p>
                            <p>학생의 예상점수 및 현 학업 상태로 봤을 때 합격 가능성</p>
                            <p>자소서 작성 방향 및 추가 스펙 만들수 있는 방안</p>
                            <p>컨설팅 전에 학생의 CV (경험, 이력, 스펙 리스트) 확인 필수</p>
                        </div>
                    </div>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading site-white-consulting">
                        <p className="accordion-title">
                        <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse11">Essay Editing/Proofreading</span>
                        </p>
                    </div>
                    <div id="collapse11" className="panel-collapse collapse">
                        <div className="panel-body site-border-white">
                            <p>브레인스토밍: 선택 토픽/주제 조사, 결론을 위한 가설과 논거 그리고 평가</p>
                            <p>첨삭: 단어 선택, 추가, 삭제 그리고 적절한 요점의 강조 (Most students can do English proofreading themselves. Please focus on
                                the academic contents).
                            </p>
                            <p>수업은 스카입 혹은 만나서 가능하며 브레인스토밍 및 피드백 위주이며 첨삭은 선생님의 own-time으로...</p>
                            <p>Charged on hourly rate too</p>
                            <p>1시간 단위 수업은 유동성있게 스케줄링 가능합니다.</p>
                            <p>첨삭 드래프트는 이메일로 왕래하며 서면 피드백이 포함되어 있습니다.</p>
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td>스카입 수업x3 (매회 1시간)</td>
                                        <td>스카입 수업x6 (매회 1시간)</td>
                                    </tr>
                                    <tr>
                                        <td>첨삭x5 (매회 1시간)</td>
                                        <td>첨삭x10 (매회 1시간)</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>Personal Statement</p>
                            <p>Tutors invoice GuruMe for personal statement mentoring with a rough plan on a monthly basis (including
                                no. of Skype sessions and time spent on editing at own time)</p>
                
                            <p>“Brian, what we will do is you spend the next 5 days making the 4th draft based on my guidelines and I’ll
                                have a look at them before our next meeting. Of course drop me a message in-between if you are stuck or
                                have a question. We will make our drafts this week on a weekly basis so that at the end of this month we
                                will have our 8th (hopefully) final draft"
                            </p>
                        </div>
                    </div>
                </div>
            </div>
    
        )
    }



    createMustDo = () => {
        return(
            <div>
                <h3 id="must-do">Must Dos</h3>
                <div className="panel-group" id="accordion">
                    <div className="panel panel-default">
                    <div className="panel-heading site-white-consulting">
                        <p className="accordion-title">
                        <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse21">Familiarise with whiteboard system and request GuruMe for touchpad</span>
                        </p>
                    </div>
                    <div id="collapse21" className="panel-collapse collapse">
                        <div className="panel-body site-border-white">
                        <p><a href="/#/online_tuition_touchpad">Huion 420</a> GuruMe sends out online touchpad for free to new
                            tutors. Do request GuruMe for it</p>
                        <p>www.awwapp.com is a good tool to start with. Please make sure you can:</p>
                        <li>draw equations smoothly</li>
                        <li>erase</li>
                        <li>change pencil thickness, colors</li>
                        <li>upload pictures and documents</li>
                        </div>
                    </div>
                    </div>
                    <div className="panel panel-default">
                    <div className="panel-heading site-white-consulting">
                        <p className="accordion-title">
                        <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse22">예정된 수업기간 보다 일찍 끝내야 한다면 최소 2주 notice 및 replace 선생님 배정</span>
                        </p>
                    </div>
                    <div id="collapse22" className="panel-collapse collapse">
                        <div className="panel-body site-border-white">
                        <p>만약 어떤 이유에서라도 예정된 수업기간 보다 일찍 끝내야 한다면 최소 2주 전에는 말씀해주시고 그루미와 같이 replace 선생님을 배정해드립니다</p>
                        <li>그루미가 가능하신 다른 그루미 선생님 채택</li>
                        <li>혹은 선생님이 가능하신 개인지인 채택</li>
                        <li>학생의 수업진도 현황 및 앞으로의 학습 계획 인수인계</li>
                        </div>
                    </div>
                    </div>
                </div>

            </div>

        )
    }

    createMustNotDo = () => {
        return(
            <div>
                <h3 id="must-not-do">Must Not Dos</h3>
                    <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                            <div className="panel-heading site-white-consulting">
                                <p className="accordion-title">
                                <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse30">카페에서 온라인 수업</span>
                                </p>
                            </div>
                            <div id="collapse30" className="panel-collapse collapse">
                                <div className="panel-body site-border-white">
                                <p>대부분의 카페가 배경음악 때문에 시끄러워서 학생이 듣는 걸 어려워 하기 때문에 카페에서 온라인 수업은 자제해주시길 바랍니다.</p>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading site-white-consulting">
                                <p className="accordion-title">
                                <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse31">수업 문의 상담 중 학생 신상 정보 공개</span>
                                </p>
                            </div>
                            <div id="collapse31" className="panel-collapse collapse">
                                <div className="panel-body site-border-white">
                                <p>수업 문의가 들어와 상담할 때 현재 혹은 과거에 맡았던 학교의 학부모님을 만난다면 ooo 아세요? 제가 가르쳤어요.’ 가 아닌 ‘학생 프라이버시가 있으니 실명을 거론은 못하지만 oo 학교
                                    학생(들) 가르쳐 본적 있습니다.’ 라고 말씀 부탁드립니다.</p>
                                <p>학부모님들 사이에 소문이 돌기도 하고 소문이 나다 보면 부풀려 져서 학생에게 프라이버시 침해가 되는 일이 될 수 있기 때문입니다. 때문에 그루미 선생님으로 꼭 학생 신상을 타인에게 말하는
                                    일이 없길 당부드립니다.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        )
    }

    createFAQ = () => {
        return(
            <div>
                <h3>FAQ</h3>
                <div className="panel-group" id="accordion">
                    <div className="panel panel-default">
                        <div className="panel-heading site-white-consulting">
                            <p className="accordion-title">
                            <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse3">I have received demo className request. How should I proceed?</span>
                            </p>
                        </div>
                        <div id="collapse3" className="panel-collapse collapse">
                            <div className="panel-body site-border-white">
                            <p>Submit 'Tuition Bill' to the student/parent taking into account the free first 30 minutes.</p>
                
                            <p>For successful demo className, GuruMe encourages teachers to chase up students to send materials for the demo
                                className and prepare them in advance.</p>
                
                            <p>Be aware, on rare occasions, there may some extreme students who may end the demo className after the first
                                30 minutes arguing that they know they will not proceed with further tuition and demand payback of demo
                                className fee.</p>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading site-white-consulting">
                            <p className="accordion-title">
                            <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse4">What if the parent wants to pay to me at the end of each tuition directly?</span>
                            </p>
                        </div>
                        <div id="collapse4" className="panel-collapse collapse">
                            <div className="panel-body site-border-white">Please kindly ask them to pay through GuruMe and direct the parent to GuruMe support
                            team.
                            </div>
                        </div>
                    </div>
            
                    <div className="panel panel-default">
                        <div className="panel-heading site-white-consulting">
                            <p className="accordion-title">
                            <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse5">What do I do when approached by an agency asking me to directly work for them?</span>
                            </p>
                        </div>
                        <div id="collapse5" className="panel-collapse collapse">
                            <div className="panel-body site-border-white">Agencies can also use GuruMe to find tutors and GuruMe tutors can teach for them if
                            the conditions are agreeable. But agencies must do so through GuruMe support. Please let GuruMe know of the
                            name of the agency that asked you to directly work for you.
                            </div>
                        </div>
                    </div>
            
                    <div className="panel panel-default">
                        <div className="panel-heading site-white-consulting">
                            <p className="accordion-title">
                            <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse6">학부모님이 문의 전화해서 필요한 정보만 알아가고 수업 신청은 안하세요</span>
                            </p>
                        </div>
                        <div id="collapse6" className="panel-collapse collapse">
                            <div className="panel-body site-border-white">
                            <p>입시 지도의 큰 비중이 정보공유인 만큼 문의가 들어왔을 때 모든 정보를 주시지 마시고 정보만 받으려고 하는 전화문의 같으면 30분/1시간의 컨설팅 시간을 잡으셔서 만약 수업으로 이루어 지지
                                않더라도 이 정보에 할당하는 수업을 받으세요. 전화는 10분안에 끝내는 방향으로...</p>
                            <p>학부모님: "마지막으로 하나만 더 여쭤볼게요..."</p>
                            <p>선생님: "예 어머님 제가 조금 더 제대로 된 컨설팅/답변을 드리고 싶어서 학생에 대한 정보를 먼저 받은 다음 시간을 잡은 후 스카입이나 면대면 (혹은 전화) 상담을 드리고 싶은데 이렇게
                                진행해도 될까요? 제 컨설팅 비용은 x만/시간 입니다."</p>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading site-white-consulting">
                            <p className="accordion-title">
                            <span style={{cursor: "pointer"}} data-toggle="collapse" data-parent="#accordion" data-target="#collapse7">불규칙적인 수업은 어떻게 인보이스를 해야하나요?</span>
                            </p>
                        </div>
                        <div id="collapse7" className="panel-collapse collapse">
                            <div className="panel-body site-border-white">
                            <p>학생이 필요할 때만 수업이 요청되는 경우가 많습니다. 이러한 수업도 표준적으로 일주일에 한번 1.5시간 씩 수업진행이 되곤 하는데 학생과 임시로 매달 x시간을 정한 후 x시간으로 인보이스를
                                발행 후 x시간이 다 완료되면 그루미에서 선생님께 수업료를 지급 드립니다.</p>
                            <p>한달 이상의 공백기간이 있을 경우 이수된 시간만큼의 수업료를 그루미에 요청하시면 바로 지급 드립니다.</p>
                            </div>
                        </div>
                    </div>
            
            
                </div>
            </div>
        )
    }



    createSecond = () => {
        return(
            <div>
                <div>
                    <h3>Logistics for tutors</h3>
                    <div className="qa-message-list" id="wallmessages">
                        <div className="message-item site-type-a" id="m3">
                            <div className="message-inner">
                                <div className="message-head">
                                    <div className="user-detail">
                                        <h3 className="handle">1. Communicate with student/parent</h3>
                                    </div>
                                </div>
                                <div className="qa-message-content">
                                    <li>Receive enquiry from student/parent via site message</li>
                                    <li>Discuss about tuition needs and goals</li>
                                    <p>Refer to tuition fee guidelines, 
                                        GuruMe Usage Fee and
                                        How to increase the likelihood of receiving enquiries
                                    </p>
                                </div>
                            </div>
                        </div>
                
                        <div className="message-item site-type-a" id="m3">
                            <div className="message-inner">
                                <div className="message-head">
                                    <div className="user-detail">
                                        <h3 className="handle">2. Demo className</h3>
                                    </div>
                                </div>
                                <div className="qa-message-content">
                                    <li>You might be requested a 1-2 hr demo className before the lesson confirmation.</li>
                                    <li>Either face to face or <a href="/online_tuition" className="link">online</a></li>
                                    <li>First 30 minutes of the className are to be free.</li>
                                    <br />
                                </div>
                            </div>
                        </div>
                
                        <div className="message-item site-type-a" id="m3">
                            <div className="message-inner">
                                <div className="message-head">
                                    <div className="user-detail">
                                        <h3 className="handle">3. Tuition Invoice/Progress</h3>
                                    </div>
                                </div>
                                <div className="qa-message-content">
                                    <li>
                                        Tuition Invoice - create a tuition invoice and send it
                                        to the parent/student
                                    </li>
                                    <li>
                                        Payment Confirmation - after parent pays, GuruMe confirms payment and className begins. If 'Pending' is
                                        displayed on the My Dashboard/Tuition, please actively chase up the student/parent to confirm the tuition.
                                    </li>
                                    <li>
                                        className in Progress - progress report is written
                                        throughout and given to student/parent at the end.
                                    </li>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="message-item site-type-a" id="m4">
                            <div className="message-inner">
                                <div className="message-head clearfix">
                                    <div className="user-detail">
                                        <h3 className="handle">4. Tuition Fee Payment</h3>
                                    </div>
                                </div>
                                <div className="qa-message-content">
                                    <li>
                                        Confirm End of Tuition - Tutor clicks 'Confirm End of
                                        Tuition' button at My Dashboard
                                    </li>
                                    <li>Student/parent confirms end of tuition during which a tuition review can be written</li>
                                    <li>GuruMe pays tutors on Fridays.</li>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <br />



                    {this.createSecondTabs()}
                
                    {this.createSlideDown()}
                
                    {this.createMustDo()}
                
                    {this.createMustNotDo()}
                
                    {this.createFAQ()}
                
                </div>
        )
    }


    createHtml = () => {
        return(
            <div className='academy-wapper-c'>
                <div className='sitieusage-wapper'>
                        {this.createFirst()}
                        {this.createSecond()}
                </div>
            </div>
        )
    }


    render(){
        return(
            <div className='site-remote-wrapper default-wrapper'>
                {this.createNav()}
                {this.createHtml()}
            </div>
        )
    }
}

export default siteusage;