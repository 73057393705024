import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasTutor,
    TrasRegister,
    TrasProfileVisibility,
    TrasTemporarilynotteaching,
    TrasPleaseenteryouryourdisableuntildatedescription,
    TrasProfileImage,
    TrasHavingaprofilepicturelooksmorecommitted,
    TrasParent,

    TrasEducationalBackground,
    TrasItisrecommendedtouploadcertificateimages,
    TrasTeachingSubjects,
    TrasThumbnailIntro,
    TrasAboutMyself,
    TrasTeachingExperience,
    TrasTeachingstyle,
    TrasLocation,
    TrasAddsecondlocationtermtimeaddressforcurrentstudents,
    TrasHourlyRate,
    TrasIfyouchangeyouraccountPleaseupdateyourprofileandinformGuruMecustomercenteraboutyourbankaccountchanges,
    TrasContactdetails,
    TrasContactdetailsarenotpubliclydisplayedbutonlytoteachersthatyouhavelessonswith,
    TrasYoucangetkakaotalkalarmwhenyoufillinphonenumberwhichislinkedwithkakaotalk,
    TrasSubmit,
    TrasSubmitting,
    TrasActivelyleadthestudent,
    TrasBoostuptestgrades,
    TrasMotivateandinspireasanolderbrotherandsisterlikementor,
    TrasHelpwithhomework,
    TrasExplaindifficultconceptsclearly,
    TrasMakethesubjectmoreinteresting,
} from '../../../Translate/EachTranslateC';
import '../../../default.css';
import './Tutorreg.css';
import './TutorregSliderbar.css';

import SchoolInputGroup from '../template/SchoolInputGroup';
import SubjectSelect from '../template/SubjectSelect';

import axios from '../../../config/axios';
import queryString from 'query-string';

import { GetAjaxURL, GetAjaxURLFileUpload, multipartForm, postConfig } from '../../../config/AjaxConfig';
import { REGEXP } from '../../../config/configAllRoutes';
import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';


import { Button, Icon } from 'semantic-ui-react';
import Calendar from 'react-calendar'
import ReactTooltip from 'react-tooltip';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { Handle, Track, Tick } from '../../extra/Slider';

import defaultImgSrc from '../../../images/backuptutorpic.png';

// Util Components
import ImageUploader from '../../utils/ImageUploader';

const contact = {
    phone1_country_id: 200, // DEFAULT  (kor)
    phone: '',
    phone2_country_id: 200, // DEFAULT  (kor)
    phone2: '',
    kakao: '',
    skype: '',
    wechat: '',
    whatsapp: '',
}


class Editteacherprofile extends Component {

    state = {
        errorMessage: '',
        uploadMessage: '',
        teacherId: -1,
        lang: 'ko',
        translate: {
            ko: {
                TrasIfyouruniversityisnotlistedtemporarilyselectOtheraskGuruMesupporttoaddinyouruniversitysothatyoucaneditafterwards: '해당 대학교가 없다면 임의로 Other를 선택해 주시고 고객센터에 문의해 주시면 해당 대학교 추가드리겠습니다.',
                TrasFillinbankaccount: 'e.g. 신한은행 110-338-435020 이혁진',
                TrasPleaseenteryourprimaryphonenumber: '카톡알림 메세지를 받아보실 수 있도록 카톡에 연동되어있는 핸드폰 번호를 입력해 주세요.',
                TrasPleaseenteryoursecondaryphonenumber: '두번째 전화번호 (예: 해외 번호)',
                TrasKakaotalkID: '카카오톡 ID *필수',
                TrasSkypeID: '스카이프 ID *필수',
                TrasWechatID: '위쳇 ID',
                TrasWhatsappID: 'Whatsapp ID',
                TrasDistrictandtown: '구/동',
            },
            en: {
                TrasIfyouruniversityisnotlistedtemporarilyselectOtheraskGuruMesupporttoaddinyouruniversitysothatyoucaneditafterwards: 'If your university is not listed, temporarily select Other, ask GuruMe support to add in your university so that you can edit afterwards',
                TrasFillinbankaccount: 'e.g. 신한은행 110-338-435020 이혁진',
                TrasPleaseenteryourprimaryphonenumber: 'Please enter your primary phone number.',
                TrasPleaseenteryoursecondaryphonenumber: 'Second phone number (for example, overseas phone number)',
                TrasKakaotalkID: 'Kakaotalk ID *necessary',
                TrasSkypeID: 'Skype ID *necessary',
                TrasWechatID: 'Wechat ID',
                TrasWhatsappID: 'Whatsapp ID',
                TrasDistrictandtown: 'District and town',
            },
            cn: {
                TrasIfyouruniversityisnotlistedtemporarilyselectOtheraskGuruMesupporttoaddinyouruniversitysothatyoucaneditafterwards: '如果您的大学没有列出，请暂时选择其他，请求GuruMe支持添加您的大学，以便您可以在之后进行编辑',
                TrasFillinbankaccount: '新韩银行110-338-435020李贤金',
                TrasPleaseenteryourprimaryphonenumber: '请输入与微信链接的手机号码，以便收到微信通知信息。',
                TrasPleaseenteryoursecondaryphonenumber: '第二个电话号码（例如，海外电话号码）',
                TrasKakaotalkID: 'Kakaotalk账号 *necessary',
                TrasSkypeID: 'Skype账号 *necessary',
                TrasWechatID: '微信号',
                TrasWhatsappID: 'Whatsapp账号',
                TrasDistrictandtown: '区路',
            }
        },


        tutorregState: {
            notTeaching: false,
            isImgUploading: false,
            needSecondLocation: false,
            demoBox: false,
            isAjaxDone: false,
            isReadTeacherTermsAndCondition: true,
            noEndNotTeaching: false,
        },

        tutorregAjaxData: {
            universitiesData: [],
            curriculumsData: [],
            locationsData: [],
            customLocationDetail: [],
            customLocationDetailSec: [],
            majorsData: [],
            countriesData: [],
        },

        calendarState: {
            // date : new Date(),
            formattingDate: '',
            disableUntilMemo: '',
        },
        eventState: {
            needCalendar: false,
        },

        // SEND DATA TO SERVER
        paramState: {
            educationInfos: [],
            teachingSubjects: [],
            selfIntro: '',
            thumbnailIntro: '',
            teachingStyle: [],
            picture: '',
            teachingExp: [],

            hourlyRate: 20000,        // DEFAULT

            experience: '',
            location: -1,
            locationMsg: '',
            is_current_undergraduate: 0,    // DEFAULT
            is_current_master: 0,           // DEFAULT
            is_current_phd: 0,              // DEFAULT
            secondLocation: '',
            secondLocationmsg: '',

            charge: 20000,            // DEFAULT
            hourly_rate_max: 40000,   // DEFAULT
            contact: {
                phone1_country_id: 200, // DEFAULT  (kor)
                phone: '',
                phone2_country_id: 200, // DEFAULT  (kor)
                phone2: '',
                kakao: '',
                skype: '',
                wechat: '',
                whatsapp: '',
            },
            certificate: '',
            isRegister: false,            // NUMBER
            visibility: 0,             // NUMBER
            preferred_currency: 2,
            bank_account: '',
            freeDemo: 0                // NUMBER
        },

        stateEducationInfoFromSchoolInputGroup: {
            highSchoolName: '',
            highSchoolPeriodStartYear: '',
            highSchoolPeriodEndYear: '',
            selectedHighSchoolTest: '',

            is_current_undergraduate: 1,  // DEFAULT
            is_current_master: 0,         // DEFAULT
            is_current_phd: 0,            // DEFAULT
            undergraduatePeriodStartYear: '',
            undergraduatePeriodEndYear: '',
            selectedUndergraduateUniversity: '',
            selectedUndergraduateMajors: '',
            undergraduateSpecificMajor: '',

            selectedMasterUniversity: '',
            masterPeriodStartYear: '',
            masterPeriodEndYear: '',
            selectedMasterMajors: '',
            masterSpecificMajor: '',

            selectedPhDUniversity: '',
            phDPeriodStartYear: '',
            phDPeriodEndYear: '',
            selectedPhDMajors: '',
            phDSpecificMajor: ''
        },

        extraStyle: {
            picScale: 100
        },

        curImgState: '',
        imgSetState: {
            LOADING: 'LOADING',
            SETTING: 'SETTING',
            FAILING: 'FAILING'
        },
        imgBlob: null,
        dataUrl: null,
        newTeachingStyles: [],
        getCertDataUrl: '',
        // getCertDataFromServer : '',

        lazyLoading: false,
        isSubmitting: false,
    }



    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.match.params.teacherId !== nextProps.match.params.teacherId)
            window.location.href = nextProps.location.pathname;

        return true;
    }


    componentDidMount = async () => {


        const teacherId = Number(this.props.match.params.teacherId);
        const allUrls = await GetAjaxURL();
        const apiThisUser = allUrls.apiThisUser;
        const res = await axios(apiThisUser).catch(err => { return console.log(err) });

        if (!res) return;
        if (!res.data) return;
        if (res.data.status === "fail") return this.doRedirection();

        // CHECK FOR THE A RIGHT USER
        const chkRightUser = res.data.user;
        if (chkRightUser.id !== teacherId || chkRightUser.type !== 'tutor') {
            return this.doRedirection();
        }

        const teacherProfileData = res.data.profile;
        const teacherUserInfo = res.data.user;

        this.initTutoreg(teacherProfileData, teacherUserInfo);


    }

    doRedirection = () => {
        const msg = this.state.lang === 'ko' ? '해당 페이지에 접근할 권한이 없습니다.'
            : this.state.lang === 'en' ? 'You can\'t edit this tutor info.'
                : '您无法编辑此导师信息。';

        showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        window.location.href = '/#/';
    }


    findUniversityId = (_uniName, _uniData) => {

        const universityName = _uniName;
        const universityData = _uniData;

        let findKey;
        for (let i = 0; i < universityData.length; i++) {
            if (universityData[i]['university_name'] === universityName) {
                findKey = universityData[i]['id'];
                break;
            }
        }

        return findKey;
    }


    initTutoreg = async (_teacherProfileData, _teacherUserInfo) => {

        let teacherId = _teacherProfileData.id;
        if (!teacherId) teacherId = -1;

        const allUrls = await GetAjaxURL();
        const universityUrl = allUrls.universities;
        const curriculumsUrl = allUrls.curriculums;
        const locationsUrl = allUrls.locations + '?lan=' + this.state.lang; // 'api/locations?lan=' + lang
        const majorUrl = allUrls.majors;
        const countriesUrl = allUrls.countries;

        const universitiesData = await axios.get(universityUrl).catch((err) => { return console.log(err) });
        const curriculumsData = await axios.get(curriculumsUrl).catch((err) => { return console.log(err) });
        const locationsData = await axios.get(locationsUrl).catch((err) => { return console.log(err) });
        const majorsData = await axios.get(majorUrl).catch((err) => { return console.log(err) });
        const countriesData = await axios.get(countriesUrl).catch((err) => { return console.log(err) });

        // teachingStyles : array<string>
        let tempStyle = _teacherProfileData.teaching_styles;
        let teachingStyles = tempStyle.split('$');
        if (teachingStyles.length === 0) teachingStyles = [];


        const free_demo = _teacherProfileData.free_demo;
        const visibility = _teacherProfileData.visibility === 1 ? '1' : '0';
        const unavailable_until = visibility == "1" ? "" : _teacherProfileData.disable_until;
        const no_unavailable_end = (unavailable_until == "0000-00-00") ? true : false;

        const location = _teacherUserInfo.location;
        const tempSLoc = _teacherUserInfo.location_2;
        let secondLocation = '';
        let needSecondLocation = false;
        if (tempSLoc) {
            // second location part
            needSecondLocation = true;
            secondLocation = tempSLoc.id;

            for (let i = 0; i < locationsData.length; i++) {
                const eachRegion = locationsData[i]['region'];
                const eachDetail = locationsData[i]['detail'];
                for (let k = 0; k < eachDetail.length; k++) {
                    if (eachDetail[k]['id'] === secondLocation) {

                    }
                }
            }

        }
        const secondLocationmsg = _teacherUserInfo.location2_note;


        contact.phone1_country_id = _teacherUserInfo.phone1_country_id;
        contact.phone = _teacherUserInfo.phone;
        contact.phone2_country_id = _teacherUserInfo.phone2_country_id;
        contact.phone2 = _teacherUserInfo.phone2;
        contact.kakao = _teacherUserInfo.kakaotalk;
        contact.skype = _teacherUserInfo.skype;
        contact.wechat = _teacherUserInfo.wechat;
        contact.whatsapp = _teacherUserInfo.whatsapp;


        const certificate = _teacherProfileData.certificate;
        const getCertDataUrl = certificate;

        let eduInfos = _teacherProfileData.education_bg;

        const msData = majorsData.data;
        const uniData = universitiesData.data;

        let stateEducationInfoFromSchoolInputGroup = {
            highSchoolName: "",
            highSchoolPeriodStartYear: "",
            highSchoolPeriodEndYear: "",
            selectedHighSchoolTest: "",
            is_current_undergraduate: String(_teacherProfileData.is_current_undergraduate),
            is_current_master: String(_teacherProfileData.is_current_master),
            is_current_phd: String(_teacherProfileData.is_current_phd),
            undergraduatePeriodStartYear: "",
            undergraduatePeriodEndYear: "",
            selectedUndergraduateUniversity: "",
            selectedUndergraduateMajors: "",
            undergraduateSpecificMajor: "",

            selectedMasterUniversity: "",
            masterPeriodStartYear: "",
            masterPeriodEndYear: "",
            selectedMasterMajors: "",
            masterSpecificMajor: "",

            selectedPhDUniversity: "",
            phDPeriodStartYear: "",
            phDPeriodEndYear: "",
            selectedPhDMajors: "",
            phDSpecificMajor: ""
        };


        // FOR FINDING KEYS
        let undergraduateMajors = [];
        let masterMajors = [];
        let phDMajors = [];
        for (let k = 0; k < msData.length; k++) {
            if (msData[k]['degree'] === 'Undergraduate') {
                undergraduateMajors = msData[k]['detail'];
            }
            if (msData[k]['degree'] === 'Master') {
                masterMajors = msData[k]['detail'];
            }
            if (msData[k]['degree'] === 'PhD') {
                phDMajors = msData[k]['detail'];
            }
        }


        for (let i = 0; i < eduInfos.length; i++) {
            const each = eduInfos[i];
            const uniName = each['school'];
            const major = each['major'];
            const degree = each['degree'];
            if (!degree) continue;
            if ("High School" === degree) {
                stateEducationInfoFromSchoolInputGroup.highSchoolName = each['highschool'];
                stateEducationInfoFromSchoolInputGroup.highSchoolPeriodStartYear = each['time'].split('-')[0];
                stateEducationInfoFromSchoolInputGroup.highSchoolPeriodEndYear = each['time'].split('-')[1];
                stateEducationInfoFromSchoolInputGroup.selectedHighSchoolTest = each['testName'];
                continue;
            }
            if ("Undergraduate" === degree) {
                stateEducationInfoFromSchoolInputGroup.is_current_undergraduate = 1;
                stateEducationInfoFromSchoolInputGroup.undergraduatePeriodStartYear = each['time'].split('-')[0];
                stateEducationInfoFromSchoolInputGroup.undergraduatePeriodEndYear = each['time'].split('-')[1];
                stateEducationInfoFromSchoolInputGroup.selectedUndergraduateUniversity = each['universityID'];

                let findMajorKey = '';
                for (let k = 0; k < undergraduateMajors.length; k++) {
                    if (undergraduateMajors[k]['subject'] === each['major']) {
                        findMajorKey = undergraduateMajors[k]['id'];
                        break;
                    }
                }
                if (!findMajorKey) findMajorKey = each['major'];

                eduInfos[i]['major'] = findMajorKey;
                stateEducationInfoFromSchoolInputGroup.selectedUndergraduateMajors = findMajorKey;
                stateEducationInfoFromSchoolInputGroup.undergraduateSpecificMajor = each['nameOfMajor'];
            }
            if ("Master" === degree) {
                stateEducationInfoFromSchoolInputGroup.is_current_master = 1;
                stateEducationInfoFromSchoolInputGroup.masterPeriodStartYear = each['time'].split('-')[0];
                stateEducationInfoFromSchoolInputGroup.masterPeriodEndYear = each['time'].split('-')[1];
                stateEducationInfoFromSchoolInputGroup.selectedMasterUniversity = each['universityID'];

                let findMajorKey = '';
                for (let k = 0; k < masterMajors.length; k++) {
                    if (masterMajors[k]['subject'] === each['major']) {
                        findMajorKey = masterMajors[k]['id'];
                        break;
                    }
                }
                if (!findMajorKey) findMajorKey = each['major'];

                eduInfos[i]['major'] = findMajorKey;
                stateEducationInfoFromSchoolInputGroup.selectedMasterMajors = findMajorKey;
                stateEducationInfoFromSchoolInputGroup.masterSpecificMajor = each['nameOfMajor'];
            }
            if ("PhD" === degree) {
                stateEducationInfoFromSchoolInputGroup.is_current_phd = 1;
                stateEducationInfoFromSchoolInputGroup.phDPeriodStartYear = each['time'].split('-')[0];
                stateEducationInfoFromSchoolInputGroup.phDPeriodEndYear = each['time'].split('-')[1];
                stateEducationInfoFromSchoolInputGroup.selectedPhDUniversity = each['universityID'];

                let findMajorKey = '';
                for (let k = 0; k < phDMajors.length; k++) {
                    if (phDMajors[k]['subject'] === each['major']) {
                        findMajorKey = phDMajors[k]['id'];
                        break;
                    }
                }
                if (!findMajorKey) findMajorKey = each['major'];

                eduInfos[i]['major'] = findMajorKey;
                stateEducationInfoFromSchoolInputGroup.selectedPhDMajors = findMajorKey;
                stateEducationInfoFromSchoolInputGroup.phDSpecificMajor = each['nameOfMajor'];
            }

        }


        const is_current_undergraduate = String(stateEducationInfoFromSchoolInputGroup.is_current_undergraduate);
        const is_current_master = stateEducationInfoFromSchoolInputGroup.is_current_master;
        const is_current_phd = stateEducationInfoFromSchoolInputGroup.is_current_phd;




        this.setState({
            ...this.state,
            teacherId,
            tutorregState: {
                ...this.state.tutorregState,
                isAjaxDone: true,
                needSecondLocation: needSecondLocation,
                notTeaching: visibility == 0 ? true : false,
                noEndNotTeaching: no_unavailable_end,
            },
            tutorregAjaxData: {
                ...this.state.tutorregAjaxData,
                universitiesData: universitiesData.data,
                curriculumsData: curriculumsData.data,
                locationsData: locationsData.data,
                majorsData: majorsData.data,
                countriesData: countriesData.data
            },
            paramState: {
                ...this.state.paramState,
                educationInfos: eduInfos,
                selfIntro: _teacherProfileData.about_myself,
                thumbnailIntro: _teacherProfileData.thumbnail_intro,
                teachingStyle: teachingStyles,    // make an array from string
                picture: _teacherProfileData.picture,
                hourlyRate: _teacherProfileData.salary,
                charge: _teacherProfileData.salary,
                hourly_rate_max: _teacherProfileData.hourly_rate_max,
                experience: _teacherProfileData.experience,
                bank_account: _teacherProfileData.bank_account,
                location: location.id,
                locationMsg: location.locationMsg,
                secondLocation: secondLocation,
                secondLocationmsg: secondLocationmsg,
                certificate: certificate,

                is_current_undergraduate,
                is_current_master,
                is_current_phd,

                free_demo: free_demo,
                visibility: visibility,
                contact: contact,
            },
            calendarState: {
                ...this.state.calendarState,
                formattingDate: unavailable_until,
            },
            stateEducationInfoFromSchoolInputGroup: stateEducationInfoFromSchoolInputGroup,
            lazyLoading: true,
            getCertDataUrl: getCertDataUrl,

        }, () => {

            if (this.state.paramState.visibility === '0') {
                const visibility_teaching = document.getElementById('visibility_teaching');
                if (visibility_teaching)
                    visibility_teaching.click();
            }
            if (this.state.paramState.free_demo) {
                const f_demo = document.getElementById('f_demo');
                if (f_demo)
                    f_demo.click();
            }


            // Setting Img
            // if(this.state.paramState.picture){
            //     const output = document.getElementById('tutorregDisplayImg');
            //     output.src = this.state.paramState.picture;
            // }

            this.setDefaultLocations(this.state.paramState.location);
            this.props.RootBindingData.doLoading(false);
        })

    }

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href='/#/'><TrasHome /></a></li>
                    <li><a href='/#/dashboard'><TrasTutor /></a></li>
                    <li className="active"><TrasRegister /></li>
                </ol>
            </div>
        )
    }



    createPartA_wrapper = () => {
        return (

            <div className="">
                <div className="margin-top-10">
                    <p className='tutorreg-font-sz-14'>N.B.// 30분 이상 페이지가 가만히 있으면 ‘페이지 타임아웃’ 때문에 제출이 안됩니다. ‘자기소개’, ‘수업경력’을 작성하는데 오랜 시간이 걸리는 만큼 작성이 완료 된 후 페이지 재고침을 한번 하신 후 제출부탁드립니다</p>
                </div>
                {this.createPartA_A()}
                {this.createPartA_B()}
                {/* TrasEducationalBackground */}
                {this.createPartA_C()}

                {/* A Certificate */}
                {this.createPartA_D()}

                {this.createPartA_E()}
                {this.createPartA_F()}
                {this.createPartA_G()}
                {this.createPartA_H()}

            </div>
        )
    }



    calendarFormat = (_date) => {
        // format : new Date();
        const date = _date;
        const yyyy = date.getFullYear().toString();
        const mm = (date.getMonth() + 1).toString();
        const dd = date.getDate().toString();

        return yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0]);

    }

    showCalendarPannel = () => {
        this.setState({
            ...this.state,
            eventState: {
                ...this.state.eventState,
                needCalendar: !this.state.eventState.needCalendar
            }
        });
    }

    calendarOnChange = (date) => {
        const dateFormatting = this.calendarFormat(date);
        this.setState({
            ...this.state,
            calendarState: {
                ...this.state.calendarState,
                formattingDate: dateFormatting,
            }
        }, () => {
            this.showCalendarPannel();
        });
    }
    funcMemoWithCal = (e) => {
        const msg = e.target.value;

        const SPACE = 1;
        const dateStr = this.state.calendarState.formattingDate;
        const stateMsg = msg;
        const sliceMsg = msg.substr((dateStr.length + SPACE), stateMsg.length);

        this.setState({
            ...this.state,
            calendarState: {
                ...this.state.calendarState,
                disableUntilMemo: sliceMsg
            }
        });
    }

    clickNotTeaching = (e) => {
        const value = e.target.checked;

        this.setState({
            ...this.state,
            tutorregState: {
                ...this.state.tutorregState,
                notTeaching: value
            },
            paramState: {
                ...this.state.paramState,
                visibility: value ? '0' : '1'
            }
        })
    }

    noUnvailableEnd = (e) => {
        const value = e.target.checked;
        const new_date = value ? "0000-00-00" : this.state.calendarState.formattingDate;

        this.setState({
            ...this.state,
            tutorregState: {
                ...this.state.tutorregState,
                noEndNotTeaching: value
            },
            calendarState: {
                ...this.state.calendarState,
                formattingDate: new_date,
            }
        })
    }


    // TrasProfileVisibility
    createPartA_A = () => {
        // if(!this.state.paramState.isRegister) return;
        return (
            <div className="tutor-part-a-component marginBottom30">

                <h1 className="blue-font-color tutorreg-font-sz-24 margin-top-10"><TrasProfileVisibility /></h1>
                <div>
                    <div className='padding-left-5 tutorreg-font-sz-14'>
                        <label className="container-check tutorreg-font-sz-14">
                            <input type="checkbox" id="visibility_teaching" name="" onChange={this.clickNotTeaching} />
                            <TrasTemporarilynotteaching />
                            <span className="default-checkmark"></span>
                        </label>
                    </div>
                </div>

                {this.state.tutorregState.notTeaching ?
                    <div>
                        <TrasPleaseenteryouryourdisableuntildatedescription />
                            &emsp;
                            <input type="checkbox" id="visibility_noend" name="" checked={this.state.tutorregState.noEndNotTeaching} onChange={this.noUnvailableEnd} />
                            수업 종료 날짜 미정
                            <span className="default-checkmark"></span>

                        <br />
                        <div className="input-group">
                            <div className='tutorreg-calendar'>
                                <Button basic onClick={this.showCalendarPannel} disabled={this.state.tutorregState.noEndNotTeaching}> <i className="fa fa-calendar"></i> </Button>
                                <span className='tutorreg-input-span'>
                                    <input type="text" className="form-control" placeholder="yyyy-mm-dd" id="disableUntilMemo" disabled={this.state.tutorregState.noEndNotTeaching}
                                        value={`${this.state.calendarState.formattingDate} ${this.state.calendarState.disableUntilMemo}`}
                                        onChange={this.funcMemoWithCal}
                                    />
                                </span>

                            </div>
                            <div className='tutorreg-line-fixer'>
                                {
                                    this.state.eventState.needCalendar ?
                                        <Calendar
                                            onChange={this.calendarOnChange}
                                            value={new Date()}
                                        />
                                        : ''
                                }
                            </div>
                        </div>
                        <div className='padding-left-5 tutorreg-font-sz-14'>
                            <label className="container-check tutorreg-font-sz-14">

                            </label>
                        </div>

                    </div>
                    : ''}

            </div>
        )
    }


    IMAGE_CallbackFuc = (_urlData) => {
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                picture: _urlData
            }
        }, () => {
        })
    }
    IMAGE_CallbackCancel = () => {
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                picture: ''
            }
        }, () => {
        })
    }




    // TrasProfileImage
    createPartA_B = () => {

        const ldefaultImgSrc = defaultImgSrc;

        const fixedScale = this.state.extraStyle.picScale / 100;
        const imgScalStyle = {
            transform: `scale(${fixedScale})`
        };

        return (
            <div className="tutor-part-a-component marginBottom30">
                <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasProfileImage /></h1>
                <p className='tutorreg-p'><i className='tutorreg-i'>"<TrasHavingaprofilepicturelooksmorecommitted />"</i> - <TrasParent /></p>

                <div className='tutorreg-img-wrraper'>
                    {
                        !this.state.lazyLoading ?
                            ''
                            :
                            <React.Fragment>
                                <h5 className="blue-font-color">이미지</h5>
                                <ImageUploader
                                    autoLoadingDataUrl={this.state.paramState.picture}
                                    IMAGE_CallbackFuc={this.IMAGE_CallbackFuc}
                                    IMAGE_CallbackCancel={this.IMAGE_CallbackCancel}
                                    formType={'tutor'}
                                />
                            </React.Fragment>
                    }
                </div>
            </div>
        )
    }

    putEducationDataToTutoregState = (_data) => {
        const data = _data;

        if (!data) return alert('no data');

        switch (data.type) {
            case 'highSchoolName':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        highSchoolName: data.value
                    }
                });
                break;
            case 'highSchoolPeriodStartYear':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        highSchoolPeriodStartYear: data.value
                    }
                });
                break;
            case 'highSchoolPeriodEndYear':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        highSchoolPeriodEndYear: data.value
                    }
                });
                break;
            case 'selectedHighSchoolTest':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        selectedHighSchoolTest: data.value
                    }
                });
                break;
            case 'is_current_undergraduate':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        is_current_undergraduate: Number(data.value)
                    }
                });
                break;
            case 'is_current_master':

                let selectedMasterUniversity = '';
                let masterPeriodStartYear = '';
                let masterPeriodEndYear = '';
                let selectedMasterMajors = '';
                let masterSpecificMajor = '';

                if (Number(data.value) === 0) {

                } else {
                    selectedMasterUniversity = this.state.stateEducationInfoFromSchoolInputGroup.selectedMasterUniversity;
                    masterPeriodStartYear = this.state.stateEducationInfoFromSchoolInputGroup.masterPeriodStartYear;
                    masterPeriodEndYear = this.state.stateEducationInfoFromSchoolInputGroup.masterPeriodEndYear;
                    selectedMasterMajors = this.state.stateEducationInfoFromSchoolInputGroup.selectedMasterMajors;
                    masterSpecificMajor = this.state.stateEducationInfoFromSchoolInputGroup.masterSpecificMajor;

                }
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        is_current_master: Number(data.value),
                        selectedMasterUniversity,
                        masterPeriodStartYear,
                        masterPeriodEndYear,
                        selectedMasterMajors,
                        masterSpecificMajor,
                    }
                }, () => { }
                );
                break;
            case 'is_current_phd':


                let selectedPhDUniversity = '';
                let phDPeriodStartYear = '';
                let phDPeriodEndYear = '';
                let selectedPhDMajors = '';
                let phDSpecificMajor = '';

                if (Number(data.value) === 0) {

                } else {
                    selectedPhDUniversity = this.state.stateEducationInfoFromSchoolInputGroup.selectedPhDUniversity;
                    phDPeriodStartYear = this.state.stateEducationInfoFromSchoolInputGroup.phDPeriodStartYear;
                    phDPeriodEndYear = this.state.stateEducationInfoFromSchoolInputGroup.phDPeriodEndYear;
                    selectedPhDMajors = this.state.stateEducationInfoFromSchoolInputGroup.selectedPhDMajors;
                    phDSpecificMajor = this.state.stateEducationInfoFromSchoolInputGroup.phDSpecificMajor;

                }
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        is_current_phd: Number(data.value),
                        selectedPhDUniversity,
                        phDPeriodStartYear,
                        phDPeriodEndYear,
                        selectedPhDMajors,
                        phDSpecificMajor,
                    }
                }, () => {
                });
                break;
            case 'undergraduatePeriodStartYear':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        undergraduatePeriodStartYear: data.value
                    }
                });
                break;
            case 'undergraduatePeriodEndYear':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        undergraduatePeriodEndYear: data.value
                    }
                });
                break;
            case 'selectedUndergraduateUniversity':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        selectedUndergraduateUniversity: data.value,
                        is_current_undergraduate: Number(1)
                    }
                });
                break;
            case 'selectedUndergraduateMajors':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        selectedUndergraduateMajors: data.value
                    }
                });
                break;
            case 'undergraduateSpecificMajor':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        undergraduateSpecificMajor: data.value
                    }
                });
                break;
            case 'selectedMasterUniversity':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        selectedMasterUniversity: data.value
                    }
                });
                break;
            case 'masterPeriodStartYear':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        masterPeriodStartYear: data.value
                    }
                });
                break;
            case 'masterPeriodEndYear':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        masterPeriodEndYear: data.value
                    }
                });
                break;
            case 'selectedMasterMajors':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        selectedMasterMajors: data.value
                    }
                });
                break;
            case 'masterSpecificMajor':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        masterSpecificMajor: data.value
                    }
                });
                break;
            case 'selectedPhDUniversity':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        selectedPhDUniversity: data.value
                    }
                });
                break;
            case 'phDPeriodStartYear':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        phDPeriodStartYear: data.value
                    }
                });
                break;
            case 'phDPeriodEndYear':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        phDPeriodEndYear: data.value
                    }
                });
                break;
            case 'selectedPhDMajors':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        selectedPhDMajors: data.value
                    }
                });
                break;
            case 'phDSpecificMajor':
                this.setState({
                    ...this.state,
                    stateEducationInfoFromSchoolInputGroup: {
                        ...this.state.stateEducationInfoFromSchoolInputGroup,
                        phDSpecificMajor: data.value
                    }
                });
                break;

        }

    }

    makeEduParms = () => {

        const schoolEdus = this.state.stateEducationInfoFromSchoolInputGroup;

        const highSchoolEducationBg = {
            degree: "High School",
            highschool: schoolEdus.highSchoolName,
            school: schoolEdus.highSchoolName,
            testName: schoolEdus.selectedHighSchoolTest,
            time: schoolEdus.highSchoolPeriodStartYear + '-' + schoolEdus.highSchoolPeriodEndYear
        };
        const undergraduateEducationBg = {
            degree: 'Undergraduate',
            universityID: schoolEdus.selectedUndergraduateUniversity,
            major: schoolEdus.selectedUndergraduateMajors,     // THIS HAS TO CHECK THE DATA
            nameOfMajor: schoolEdus.undergraduateSpecificMajor,
            fieldOfStudy: schoolEdus.selectedUndergraduateMajors, // THIS HAS TO CHECK THE DATA
            time: schoolEdus.undergraduatePeriodStartYear + '-' + schoolEdus.undergraduatePeriodEndYear
        };
        const masterEducationBg = {
            degree: 'Master',
            universityID: schoolEdus.selectedMasterUniversity,
            nameOfMajor: schoolEdus.masterSpecificMajor,
            fieldOfStudy: schoolEdus.selectedMasterMajors,
            time: schoolEdus.masterPeriodStartYear + '-' + schoolEdus.masterPeriodEndYear
        };
        const phDEducationBg = {
            degree: 'PhD',
            universityID: schoolEdus.selectedPhDUniversity,
            nameOfMajor: schoolEdus.phDSpecificMajor,
            fieldOfStudy: schoolEdus.selectedPhDMajors,
            time: schoolEdus.phDPeriodStartYear + '-' + schoolEdus.phDPeriodEndYear
        };

        const is_current_undergraduate = schoolEdus.is_current_undergraduate;
        const is_current_master = schoolEdus.is_current_master;
        const is_current_phd = schoolEdus.is_current_phd;


        // INPUT AREA
        const educationBgs = [];
        educationBgs.push(highSchoolEducationBg);
        educationBgs.push(undergraduateEducationBg);
        if (masterEducationBg.fieldOfStudy !== '') {
            educationBgs.push(masterEducationBg);
        }
        if (phDEducationBg.fieldOfStudy !== '') {
            educationBgs.push(phDEducationBg);
        }

        return new Promise((resolve) => {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    educationInfos: educationBgs,
                    is_current_undergraduate,
                    is_current_master,
                    is_current_phd
                }
            }, () => {
                resolve(true);
            })
        })
    }


    getDataFromSchoolInputGroup = (_data) => {
        this.putEducationDataToTutoregState(_data);
    }


    /// PUT DISPLAYS AND DATA TO STATE
    getCertDataFromSchoolInputGroup = (_dataUrl) => {
        const dataUrl = _dataUrl;

        this.setState({
            ...this.state,
            getCertDataUrl: _dataUrl.dispalyDataUrl,

            paramState: {
                ...this.state.paramState,
                certificate: _dataUrl.certDataUrl
            }
            // getCertDataFromServer : _dataUrl.certDataUrl
        });

    }

    removeCertDataUrl = () => {
        this.setState({
            ...this.state,
            getCertDataUrl: '',
            paramState: {
                ...this.state.paramState,
                certificate: '',
            }

        });
    }


    createPartA_C = () => {


        const lang = this.state.lang;
        let trasIfyouruniversityis = this.state.translate.ko.TrasIfyouruniversityisnotlistedtemporarilyselectOtheraskGuruMesupporttoaddinyouruniversitysothatyoucaneditafterwards;
        if (lang === 'en') {
            trasIfyouruniversityis = this.state.translate.en.TrasIfyouruniversityisnotlistedtemporarilyselectOtheraskGuruMesupporttoaddinyouruniversitysothatyoucaneditafterwards;
        } else if (lang === 'cn') {
            trasIfyouruniversityis = this.state.translate.cn.TrasIfyouruniversityisnotlistedtemporarilyselectOtheraskGuruMesupporttoaddinyouruniversitysothatyoucaneditafterwards;
        }

        return (

            <div className="tutor-part-a-component marginBottom30">
                <h1 className="tutorreg-font-sz-24 blue-font-color fixed-tutor-h2">
                    <TrasEducationalBackground />
                </h1>
                <span>대학교 리스트</span>

                <span className="">
                    <i className="fa fa-question-circle color-gurume-red schools" data-tip={trasIfyouruniversityis} data-for='tooltipSchool' data-event='mouseover'></i>
                    <ReactTooltip effect="solid" place="bottom" id='tooltipSchool' type='light'
                        globalEventOff='mouseout' >
                    </ReactTooltip>
                </span>

                <i className='gurume-red'> * 가입시 적용된 학력은 수정 요청을 해주셔야 됩니다. </i>
                {
                    this.state.tutorregState.isAjaxDone ?
                        <SchoolInputGroup
                            universitiesData={this.state.tutorregAjaxData.universitiesData}
                            majorsData={this.state.tutorregAjaxData.majorsData}
                            getDataFromSchoolInputGroup={this.getDataFromSchoolInputGroup}
                            getCertDataFromSchoolInputGroup={this.getCertDataFromSchoolInputGroup}
                            educationInfos={this.state.paramState.educationInfos}
                            teacherId={this.state.teacherId}
                            is_current_undergraduate={this.state.paramState.is_current_undergraduate}
                        />
                        : ''
                }

            </div>

        )
    }

    createPartA_D = () => {
        return (
            <div className="tutor-part-a-component education-bg marginBottom30">
                <div>
                    {this.state.getCertDataUrl === '' ?
                        <h5 className="brown-font-color fontsz14">
                            <TrasItisrecommendedtouploadcertificateimages />
                        </h5>
                        :
                        <div className="tutor-cert-div">
                            <div className='tutor-cert-inner-div'>
                                <i className="fa fa-times cursor tutor-x-btn" onClick={this.removeCertDataUrl}></i>
                                <img className="img-responsive" src={this.state.getCertDataUrl} alt='responsive' />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }


    getDataFromSubjectSelect = (_teachingSubjects, _educationInfos) => {

        if (_educationInfos) {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    teachingSubjects: _teachingSubjects
                }
            })
        } else {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    teachingSubjects: _teachingSubjects,
                    educationInfos: _educationInfos
                }
            })
        }

    }


    createPartA_E = () => {
        return (
            <div className="subject-teach marginBottom30" style={{ float: "left", width: "100%" }}>
                <div className="col-md-12">
                    <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasTeachingSubjects /></h1>
                </div>
                {
                    this.state.tutorregState.isAjaxDone ?
                        <SubjectSelect
                            curriculumsData={this.state.tutorregAjaxData.curriculumsData}
                            sendDataToParent={this.getDataFromSubjectSelect}
                            teacherId={this.state.teacherId}
                        />
                        : ''
                }

            </div>
        )
    }

    createPartA_F = () => {

        const placeHolder = '예시: 어렸을 때부터 오랜 해외유학 시절을 보내며 고득점으로 IB를 이수하고 신약개발 연구 커리어를 목표로 현재 약대에 재학중인 여선생님입니다. 풍부한 수업 경력을 바탕으로 화학, 생물, 수학 성적을 책임져 드리겠습니다...';
        return (
            <div className="tutor-part-a-component margin-top-10 marginBottom30">
                <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasThumbnailIntro />
                    {/* <small> ''</small> */}
                </h1>
                <p><i className='fontsz14'>프로필을 눌러보고 싶게 만들 썸네일 "펀치라인"에 해당되는 부분을 포함해주세요!</i></p>
                <p className='fontsz14'>학생과 학부모님께서 선생님을 인터넷 검색을 통해 찾을 때 그루미 선생님께서 첫 번째 고려대상이 되실 수 있도록 검색결과에서 상위 페이지에 검색 수 있도록 SEO를 해놓았습니다. "AP 화학 선생님", "IB 수학 과외", "SAT II 물리 수업" 등의 구글 검색에서 선생님의 프로필 페이지가 상위 페이지가 나올 수 있도록 아래 세 종류의 키워드를 최대한 많이 사용해주세요: <br />
                1. #선생님, #과외, #수업<br />
                2. 과목 이름 (한국>영어)<br />
                3. IB, AP, SAT II, 에이레벨
                </p>
                <div className="row fontsz14 colorBl marginBottom10">
                    <div className="col-sm-6">
                        - Best teaching subject<br />
                        - Extensive teaching experience<br />
                    </div>
                    <div className="col-sm-6">
                        - Future career plan<br />
                        - Amazing IB/AP/A-Level score
                    </div>
                </div>
                <textarea className="tutorreg-single-textarea" name="thumbnailIntro" maxLength="255"
                    onChange={(e) => { this.setState({ ...this.state, paramState: { ...this.state.paramState, thumbnailIntro: e.target.value } }) }}
                    placeholder={placeHolder}
                    value={this.state.paramState.thumbnailIntro}
                >
                </textarea>
                <small className="help-block color-red tutorreg-font-sz-14">
                    {
                        this.state.paramState.thumbnailIntro.length > 60 ?
                            ''
                            :
                            '한 60자를 입력하세요.'
                    }
                    {
                        this.state.paramState.thumbnailIntro.length > 200 ?
                            '200자 미만으로 입력하세요.'
                            :
                            ''
                    }
                </small>

            </div>
        )
    }


    // self introduce
    createPartA_G = () => {
        const placeholderNextLine =
            `1. Favourite degree subject interest areas and future/career plans
2. Curriculum qualifications (+ grades if possible)
3. Teaching resources (appears as prepared, professional tutor)
4. Teaching style (appears as dedicated and committed)`;

        return (
            <div className="tutor-part-a-component margin-top-10 marginBottom30">
                <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasAboutMyself /></h1>
                <p className='fontsz14'>모델 선생님 프로필을 참조하시면서 자기소개 내용을 작성해주세요!</p>
                <p className='fontsz14'>
                    IB - <a href="http://www.gurume.kr/teachers/1643" target="blank">www.gurume.kr/teachers/1643</a><br />
                    A-Level - <a href="http://www.gurume.kr/teachers/1425" target="blank">www.gurume.kr/teachers/1425</a><br />
                    AP - <a href="http://www.gurume.kr/teachers/1185" target="blank">www.gurume.kr/teachers/1185</a>
                </p>
                <textarea className="tutorreg-single-textarea"
                    onChange={(e) => { this.setState({ ...this.state, paramState: { ...this.state.paramState, selfIntro: e.target.value } }) }}
                    placeholder={placeholderNextLine}
                    value={this.state.paramState.selfIntro}
                >
                </textarea>
            </div>
        )
    }

    createPartA_H = () => {
        const placeholderNextLint =
            `#Example 1 (preferred)
2017
IB HL Chemistry - Singapore (5->7)
IB HL Chemistry - Brzail (6)
IB SL Chemistry - Hong Kong (7)

2016
iGCSE Chemistry - Singapore (A*)
IB ChesItry SL- Singapore (7)
IB Chemistry EE - Korea (A)

#Example 2
2014~current
I taught more than 10 students in IB/iGCSE/SAT II Chemistry and helped them imrprove their exam scores. There were 5 IB chemistry students who achieved 7 in total/ 3 students achieved full 800 in SAT II.
`;
        return (
            <div className="tutor-part-a-component margin-top-10 marginBottom30">
                <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasTeachingExperience /></h1>

                <textarea className="tutorreg-single-textarea"
                    onChange={(e) => { this.setState({ ...this.state, paramState: { ...this.state.paramState, experience: e.target.value } }) }}
                    placeholder={placeholderNextLint}
                    value={this.state.paramState.experience}>

                </textarea>
            </div>
        )
    }


    createPartB_wrapper = () => {
        return (
            <div className='marginBottom30'>
                {/* teachingStyle */}
                {this.createPartB_A()}
                {/* location */}
                {this.createPartB_B()}
                {/* hourly rate */}
                {this.createPartB_C()}
                {this.createPartB_D()}
                {this.createPartB_F()}
                {this.createPartB_G()}
                {this.createPartB_H()}
            </div>
        )
    }


    selectThisTeachingApproach = (e) => {

        const CLASS_NAME = 'teach-approach-btn-selected';

        let target = e.target;
        let parentN = target.parentNode;
        if (target.tagName === 'SPAN') {
            // GET PARENT UP
            target = target.parentNode;
            parentN = target.parentNode;
        }


        // SELECT ONLY TWO OPTIONS
        const teachKey = target.dataset['teachKey'];

        const teachingStyle = this.state.paramState.teachingStyle;

        if (!teachingStyle.includes(teachKey)) {
            if (teachingStyle.length >= 4) {
                const oldData = teachingStyle.shift();
                teachingStyle.push(teachKey);
            } else {
                teachingStyle.push(teachKey);
            }
        }


        for (let i = 0; i < parentN.childElementCount; i++) {
            parentN.children[i].classList.remove(CLASS_NAME);
        }

        for (let i = 0; i < parentN.childElementCount; i++) {
            const childData = parentN.children[i].dataset['teachKey'];
            for (let k = 0; k < teachingStyle.length; k++) {
                if (childData === teachingStyle[k]) {
                    parentN.children[i].classList.add(CLASS_NAME);
                }
            }
        }

    }

    getComponent_PartB_A = (_index) => {

        const index = _index;
        let compo;

        if (index === 1) {
            compo = "꼼꼼 내신 대비"
        }
        if (index === 2) {
            compo = "전략 위주 시험 대비"
        }
        if (index === 3) {
            compo = "동기부여"
        }
        if (index === 4) {
            compo = "디테일에 강한"
        }
        if (index === 5) {
            compo = "탄탄한 경력"
        }
        if (index === 6) {
            compo = "숙제량 많은"
        }
        if (index === 7) {
            compo = "스파르타 식"
        }
        if (index === 8) {
            compo = "성과 보장"
        }
        return compo;
    }

    createPartB_A = () => {

        const teachingApproachDiv = [];
        let teachingStyles = [];
        let teachingStylesRes = [];
        const NOR_CLASS_NAME = 'btn teach-approach-btn cursor';
        const CLASS_NAME = ' teach-approach-btn-selected';
        const teachings = this.state.paramState.teachingStyle;

        // for(let k=0; k<=teachings.length; k++){
        //     if(teachings[k] === 4){
        //         teachings[k] = 1
        //     }else if(teachings[k] === 6){
        //         teachings[k] = 3
        //     }
        // }

        for (let i = 1; i < 9; i++) {

            let CLASS_NAMES = `${NOR_CLASS_NAME}`;

            for (let k = 0; k < teachings.length; k++) {
                if (Number(teachings[k]) === i) {
                    CLASS_NAMES = `${NOR_CLASS_NAME}${CLASS_NAME}`;
                }
            }

            const tempObj = {
                component: this.getComponent_PartB_A(i),
                dataKey: i
            }
            teachingStyles.push(tempObj.component)
            const tempDiv = <div key={tempObj.dataKey}
                data-teach-key={tempObj.dataKey}
                onClick={this.selectThisTeachingApproach}
                className={CLASS_NAMES} >
                {tempObj.component}
            </div>;

            teachingApproachDiv.push(tempDiv);
        }
        teachingStylesRes = new Set(teachingStyles)

        return (
            <div className="teach-approach-area tutor-part-a-component marginBottom30">
                <h1 style={{ marginBottom: "20px" }} className="tutorreg-font-sz-24 blue-font-color">
                    <TrasTeachingstyle />
                </h1>
                <div className="teach-approach-standard">
                    {teachingApproachDiv}
                </div>
            </div>
        )
    }




    clickShowSecondLoaction = (e) => {
        const value = e.target.checked;
        this.setState({
            ...this.state,
            tutorregState: {
                ...this.state.tutorregState,
                needSecondLocation: value
            }
        }, () => {
        })
    }




    setDefaultLocations = async (_locationId, _afterLoaded) => {

        const locationsData = this.state.tutorregAjaxData.locationsData;
        const locationId = _locationId;

        const allData = [];
        for (let j = 0; j < locationsData.length; j++) {
            const region = locationsData[j]['region'];
            const detail = locationsData[j]['detail'];

            for (let k = 0; k < detail.length; k++) {
                const id = detail[k]['id'];
                const city = detail[k]['city'];
                allData.push({ id: id, region: region, city: city });
            }

        }

        let Items = {};
        let SecItems = {};
        for (let i = 0; i < allData.length; i++) {
            if (_afterLoaded) {
                if (String(allData[i].region) === String(locationId)) {
                    Items = allData[i];
                    break;
                }
            } else {
                if (String(allData[i].id) === String(locationId)) {
                    Items = allData[i];
                }
                if (this.state.tutorregState.needSecondLocation) {
                    if (this.state.paramState.secondLocation !== '') {
                        if (String(allData[i].id) === String(this.state.paramState.secondLocation)) {
                            SecItems = allData[i];
                        }
                    }
                }
            }
        }
        let regionDetail = [];
        let secRegionDetail = [];
        for (let i = 0; i < locationsData.length; i++) {
            if (locationsData[i].region === Items.region) {
                regionDetail = locationsData[i].detail;
            }

            if (this.state.tutorregState.needSecondLocation) {
                if (this.state.paramState.secondLocation !== '') {
                    if (String(SecItems.region) === String(locationsData[i].region)) {
                        secRegionDetail = locationsData[i].detail;
                    }
                }
            }

        }

        this.setState({
            ...this.state,
            tutorregAjaxData: {
                ...this.state.tutorregAjaxData,
                customLocationDetail: regionDetail,
                customLocationDetailSec: secRegionDetail
            },
            paramState: {
                ...this.state.paramState,
                location: Items.id,
            }
        }, () => {
            if (this.state.paramState.location) {
                const regLoc = document.getElementById('reg-location-selector');
                const regDst = document.getElementById('reg-district-selector');
                regLoc.value = Items.region;
                regDst.value = Items.id;

                if (this.state.paramState.secondLocation) {
                    const secondLoDom = document.getElementById('secondLoDom');
                    const sregLoc = document.getElementById('reg-location-selector-sec');
                    const sregDst = document.getElementById('reg-district-selector-sec');

                    secondLoDom.checked = true;
                    sregLoc.value = SecItems.region;
                    sregDst.value = SecItems.id;
                }
            }
        });

        // return Items;
    }

    setDefaultLocationSec = (_locationId) => {

        const locationsData = this.state.tutorregAjaxData.locationsData;
        const locationId = _locationId;

        const allData = [];
        for (let j = 0; j < locationsData.length; j++) {
            const region = locationsData[j]['region'];
            const detail = locationsData[j]['detail'];

            for (let k = 0; k < detail.length; k++) {
                const id = detail[k]['id'];
                const city = detail[k]['city'];
                allData.push({ id: id, region: region, city: city });
            }

        }

        let Items = {};
        for (let i = 0; i < allData.length; i++) {
            if (String(allData[i].id) === String(locationId)) {
                Items = allData[i];
                break;
            }
        }
        let regionDetail = [];
        for (let i = 0; i < locationsData.length; i++) {
            if (locationsData[i].region === Items.region) {
                regionDetail = locationsData[i].detail;
            }
        }

        this.setState({
            ...this.state,
            tutorregAjaxData: {
                ...this.state.tutorregAjaxData,
                customLocationDetailSec: regionDetail
            },
            paramState: {
                ...this.state.paramState,
                secondLocation: Items.id,
            }
        }, () => {
        });
    }



    changeCountry = (e) => {

        const target = e.target;
        const dataType = target.dataset['locationFirst'];

        if (dataType === 'first') {
            this.setDefaultLocations(target.value, true);
        } else {
            this.setDefaultLocationSec(target.value);
        }


    }

    locationMsgToState = (e) => {
        const value = e.target.value;
        const locationType = e.target.dataset['locationFirst'];

        if (locationType === 'first') {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    locationMsg: value
                }
            });
        } else {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    secondLocationmsg: value
                }
            });
        }

    }


    selectDistrictToState = (e) => {
        const value = e.target.value;
        const locationType = e.target.dataset['locationFirst'];


        if (locationType === 'first') {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    location: value
                }
            });
        } else {
            this.setState({
                ...this.state,
                paramState: {
                    ...this.state.paramState,
                    secondLocation: value
                }
            });
        }

    }


    // location
    createPartB_B = () => {

        const lang = this.state.lang;
        let TrasDistrictandtown = this.state.translate.ko.TrasDistrictandtown;
        if (lang === 'en') {
            TrasDistrictandtown = this.state.translate.en.TrasDistrictandtown;
        } else if (lang === 'cn') {
            TrasDistrictandtown = this.state.translate.cn.TrasDistrictandtown;
        }


        const locationsData = this.state.tutorregAjaxData.locationsData;


        const regions = [];

        for (let i = 0; i < locationsData.length; i++) {
            regions.push(
                <option key={`region_${i}`} value={locationsData[i].region}>{locationsData[i].region}</option>
            )
        }

        const customLocationDetail = this.state.tutorregAjaxData.customLocationDetail;
        const customLocationDetailSec = this.state.tutorregAjaxData.customLocationDetailSec;
        const regionDetail = [];
        const regionDetailSec = [];
        for (let i = 0; i < customLocationDetail.length; i++) {
            regionDetail.push(
                <option key={`detail_${i}`} value={customLocationDetail[i].id}>{customLocationDetail[i].city}</option>
            );
        };
        for (let i = 0; i < customLocationDetailSec.length; i++) {
            regionDetailSec.push(
                <option key={`detail_${i}`} value={customLocationDetailSec[i].id}>{customLocationDetailSec[i].city}</option>
            );
        };


        return (
            <div className="student-reg-location marginBottom30 marginTop30">
                <div className="tutor-part-a-component">
                    <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasLocation /></h1>
                </div>
                <div className='reg-location'>
                    <div className='reg-location-part-a marginBottom10'>
                        <div className="">
                            <select id='reg-location-selector'
                                className="form-control student-reg-contact-input height34 "
                                data-location-first='first'
                                onChange={this.changeCountry}
                            >
                                {regions}
                            </select>
                        </div>
                        <div className="">
                            <select
                                id='reg-district-selector'
                                className="form-control student-reg-contact-input height34 "
                                data-location-first='first'
                                onChange={this.selectDistrictToState}
                                defaultValue={this.state.paramState.location}
                            >
                                {regionDetail}
                            </select>
                        </div>
                    </div>
                    <div className='reg-location-part-b'>
                        <div className="tutor-part-a-component">
                            <input className="form-control student-reg-contact-input height34 marginBottom10"
                                onChange={this.locationMsgToState}
                                data-location-first='first'
                                placeholder={TrasDistrictandtown} type="text"
                                defaultValue={this.state.paramState.locationMsg}
                            />
                        </div>
                    </div>

                    <div className='tutor-part-a-component fontsz14' >
                        <label className="container-check tutor-check-fix">
                            <input type="checkbox" id="secondLoDom" name="" onChange={this.clickShowSecondLoaction} />
                            <span className='fontsz14 '><TrasAddsecondlocationtermtimeaddressforcurrentstudents /></span>
                            <span className="default-checkmark"></span>
                        </label>
                    </div>
                </div>
                {
                    this.state.tutorregState.needSecondLocation ?
                        <div className='reg-location'>
                            <div className='reg-location-part-a marginBottom10'>
                                <div className="">
                                    <select id='reg-location-selector-sec'
                                        className="form-control student-reg-contact-input height34 "
                                        data-location-first='second'
                                        onChange={this.changeCountry}
                                    >
                                        {regions}
                                    </select>
                                </div>
                                <div className="">
                                    <select id='reg-district-selector-sec'
                                        className="form-control student-reg-contact-input height34 "
                                        data-location-first='second'
                                        onChange={this.selectDistrictToState}
                                        defaultValue={this.state.paramState.secondLocation}
                                    >
                                        {regionDetailSec}
                                    </select>
                                </div>
                            </div>
                            <div className='reg-location-part-b'>
                                <div className="tutor-part-a-component">
                                    <input className="form-control student-reg-contact-input height34 marginBottom10"
                                        onChange={this.locationMsgToState}
                                        data-location-first='second'
                                        placeholder={TrasDistrictandtown} type="text"
                                        defaultValue={this.state.paramState.secondLocationmsg}
                                    />
                                </div>
                            </div>
                        </div>
                        : ''

                }




            </div>
        )
    }




    setHourlyRate = (val) => {
        // val typeof Array

        const hourlyRate = val[0];
        const hourlyRateMax = val[1];

        this.setState({

            ...this.state,
            paramState: {
                ...this.state.paramState,
                hourlyRate: hourlyRate,
                charge: hourlyRate,
                hourly_rate_max: hourlyRateMax
            }
        });

    }


    setBankAccount = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                bank_account: value
            }
        })
    }

    clickToToggle = () => {
        const toggleOversea = document.getElementById('toggle-oversea');

        let index = 0;
        let isShow = false;
        for (let i = 0; i < toggleOversea.childElementCount; i++) {
            if (toggleOversea.children[i].tagName === 'DIV') {
                index = i;
                for (let k = 0; k < toggleOversea.children[i].classList.length; k++) {
                    if (toggleOversea.children[i].classList[k] === 'show-tg')
                        isShow = true;
                }
            }
        }
        if (index !== 0) {
            if (isShow) {
                toggleOversea.children[index].classList.remove('show-tg');
                toggleOversea.children[index].classList.add('hide-tg');
            } else {
                toggleOversea.children[index].classList.remove('hide-tg');
                toggleOversea.children[index].classList.add('show-tg');
            }
        }
    }

    setDemoFree = (e) => {
        const value = e.target.checked;

        const parseValue = Number(value);   /// THIS WILL BE A NUMBER

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                freeDemo: parseValue
            }
        })
    }

    // HOURLY RATE
    createPartB_C = () => {


        const lang = this.state.lang;
        let fillInBankAccount = this.state.translate.ko.TrasFillinbankaccount;
        if (lang === 'en') {
            fillInBankAccount = this.state.translate.en.TrasFillinbankaccount;
        } else if (lang === 'cn') {
            fillInBankAccount = this.state.translate.cn.TrasFillinbankaccount;
        }


        const sliderStyle = {  // Give the slider some width
            position: 'relative',
            width: '100%',
            height: 80,
        };

        const railStyle = {
            position: 'absolute',
            width: '100%',
            height: 10,
            marginTop: 35,
            borderRadius: 5,
            backgroundColor: '#8B9CB6',
        };

        const hourly_rate_min = this.state.paramState.hourlyRate;
        const hourly_rate_max = this.state.paramState.hourly_rate_max;


        return (

            <div className="tutor-part-a-component marginBottom30 marginTop30 display-inlineb">
                <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasHourlyRate /></h1>
                <div className="col-md-12 no-padding">
                    <p id="hourly-rate-description" className="no-margin-bottom fontsz14">
                        * 선생님의 1:1 수업 시급은 {`${this.state.paramState.hourlyRate}~${this.state.paramState.hourly_rate_max}`}만원 입니다.
                    </p>
                </div>

                <div className="col-md-12 margin-top-10">

                    <Slider
                        rootStyle={sliderStyle}
                        domain={[20000, 100000]}
                        step={10000}
                        mode={1}
                        values={[hourly_rate_min, hourly_rate_max]}
                        onChange={this.setHourlyRate}
                    >
                        <Rail>
                            {({ getRailProps }) => (
                                <div style={railStyle} {...getRailProps()} />
                            )}
                        </Rail>
                        <Handles>
                            {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                    {handles.map(handle => {

                                        return (<Handle
                                            key={handle.id}
                                            handle={handle}
                                            getHandleProps={getHandleProps}
                                        />);

                                    })}
                                </div>
                            )}
                        </Handles>
                        <Tracks left={false} right={false}>
                            {({ tracks, getTrackProps }) => (
                                <div className="slider-tracks">
                                    {tracks.map(({ id, source, target }) => (
                                        <Track
                                            key={id}
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Tracks>
                        <Ticks values={[20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000]}>
                            {({ ticks }) => (
                                <div className="slider-ticks">
                                    {ticks.map(tick => ( // get back ids, values and percents (to place them)
                                        <Tick key={tick.id} tick={tick} count={ticks.length} />
                                    ))}
                                </div>
                            )}
                        </Ticks>
                    </Slider>
                </div>

                <div className="col-md-12 no-padding margin-top-15">
                    <p className="no-margin-bottom fontsz14">* 학생/학부모님은 +1만원이 추가된 금액을 지불합니다.</p>
                    <p className="margin-bottom-5 fontsz14">* 계좌 번호 -
                        <TrasIfyouchangeyouraccountPleaseupdateyourprofileandinformGuruMecustomercenteraboutyourbankaccountchanges />
                    </p>
                    <textarea className="form-control student-reg-contact-input height34 fontsz14"
                        onChange={this.setBankAccount}
                        rows="1"
                        value={this.state.paramState.bank_account}
                        placeholder={fillInBankAccount} />


                    <div className='padding-left-5 tutorreg-font-sz-14 tutorreg-demo-fix'>
                        <label className="container-check tutorreg-font-sz-14">
                            <input type="checkbox" id="f_demo" name="" onChange={this.setDemoFree} />
                            <a href="https://goo.gl/PPWx72" target="blank">
                                <i className='fontsz14'>데모수업 무료로 가능합니다 (1~2시간)</i>
                            </a>가능합니다
                            <span className="default-checkmark"></span>
                        </label>
                    </div>


                    <p className='fontsz14'>기존 30분 무료 데모수업과는 별개로 전체 데무수업시간을 무료로 하여 수업문의량을 늘릴 수 있는 방법입니다.</p>

                    <br />

                    <div id='toggle-oversea' className='toggle-oversea-wapper' onClick={this.clickToToggle}>
                        <h5 className='toggle-oversea'>해외송금</h5>
                        <div className='hide-tg'>
                            <p>
                                해외송금은 카카오뱅크로 이루어지며 당일 카카오뱅크 환율로 매 건당 수수료 5000원에 이루어집니다 (선생님 부담).<br /><br />

                                나라마다 필요한 정보가 다르지만 공통적으로 수취인 현지주소가 필요로 됩니다.
                                해외송금을 원하시는 분은 그루미 고객센터에 따로 메세지를 남겨주세요.<br />
                                *해외송금은 3-5 근무일이 소요됩니다.<br />
                                US, UK, HK<br />
                                <img src="/images/how_it_works/oversea-transfer.png" className="img-responsive" alt='oversea-transfer' />
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }


    // CONTACT DETAILS
    createPartB_D = () => {
        return (

            <div className="tutor-part-a-component" id="contacts">
                <h1 className="tutorreg-font-sz-24 blue-font-color"><TrasContactdetails /></h1>
                <p className='fontsz14'>
                    <TrasContactdetailsarenotpubliclydisplayedbutonlytoteachersthatyouhavelessonswith />
                </p>
                <p className='fontsz14'>
                    <TrasYoucangetkakaotalkalarmwhenyoufillinphonenumberwhichislinkedwithkakaotalk />
                </p>
            </div>
        )
    }






    setPhone1CountryId = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    phone1_country_id: value
                }
            }
        })
    }
    setPhone = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    phone: value
                }
            }
        })
    }
    setPhone2CountryId = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    phone2_country_id: value
                }
            }
        })
    }
    setPhone2 = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    phone2: value
                }
            }
        })
    }

    setKakao = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    kakao: value
                }
            }
        })
    }
    setSkype = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    skype: value
                }
            }
        })
    }
    setWechat = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    wechat: value
                }
            }
        })
    }
    setWahtsApp = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                contact: {
                    ...this.state.paramState.contact,
                    whatsapp: value
                }
            }
        })
    }


    // CONTACT DETAILS
    createPartB_F = () => {

        const lang = this.state.lang;
        let TrasPleaseenteryourprimaryphonenumber = this.state.translate.ko.TrasPleaseenteryourprimaryphonenumber;
        let TrasPleaseenteryoursecondaryphonenumber = this.state.translate.ko.TrasPleaseenteryoursecondaryphonenumber;
        let TrasKakaotalkID = this.state.translate.ko.TrasKakaotalkID;
        let TrasSkypeID = this.state.translate.ko.TrasSkypeID;
        let TrasWechatID = this.state.translate.ko.TrasWechatID;
        let TrasWhatsappID = this.state.translate.ko.TrasWhatsappID;

        if (lang === 'en') {
            TrasPleaseenteryourprimaryphonenumber = this.state.translate.en.TrasPleaseenteryourprimaryphonenumber;
            TrasPleaseenteryoursecondaryphonenumber = this.state.translate.en.TrasPleaseenteryoursecondaryphonenumber;
            TrasKakaotalkID = this.state.translate.en.TrasKakaotalkID;
            TrasSkypeID = this.state.translate.en.TrasSkypeID;
            TrasWechatID = this.state.translate.en.TrasWechatID;
            TrasWhatsappID = this.state.translate.en.TrasWhatsappID;
        } else if (lang === 'cn') {
            TrasPleaseenteryourprimaryphonenumber = this.state.translate.cn.TrasPleaseenteryourprimaryphonenumber;
            TrasPleaseenteryoursecondaryphonenumber = this.state.translate.cn.TrasPleaseenteryoursecondaryphonenumber;
            TrasKakaotalkID = this.state.translate.cn.TrasKakaotalkID;
            TrasSkypeID = this.state.translate.cn.TrasSkypeID;
            TrasWechatID = this.state.translate.cn.TrasWechatID;
            TrasWhatsappID = this.state.translate.cn.TrasWhatsappID;
        }


        const countriesData = this.state.tutorregAjaxData.countriesData;
        const countiresOptions = [];
        let tempText = '';



        // SET DEFAULT STATE
        for (let i = 0; i < countriesData.length; i++) {
            if (lang === 'ko') {
                tempText = countriesData[i].name_ko + ' (+' + countriesData[i].country_code + ')';
            } else {
                tempText = countriesData[i].name + ' (+' + countriesData[i].country_code + ')';
            }

            countiresOptions.push(
                <option key={i} value={countriesData[i].id}>{tempText}</option>
            );

        }

        return (
            <div className='marginBottom30'>
                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_phone.png" />
                        </span>

                        <div className="">
                            <select className="form-control country-select student-reg-contact-input height35"
                                onChange={this.setPhone1CountryId} >
                                <option value="" disabled>번호 선택</option>
                                {countiresOptions}
                            </select>
                        </div>
                        <div className="">
                            <input type="tel" ng-model="contact.phone" name="phone"
                                className="form-control student-reg-contact-input height35"
                                onChange={this.setPhone}
                                value={this.state.paramState.contact.phone}
                                required="required"
                                placeholder={TrasPleaseenteryourprimaryphonenumber}
                            />
                        </div>
                    </div>
                </div>
                <div className='tutorreg-line-a'>
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_phone.png" />
                        </span>

                        <div className="">
                            <select className="form-control country-select student-reg-contact-input height35"
                                // ng-options="country.id as (('ko' === lang ? country.name_ko : country.name) + ' (+' + country.country_code + ')') for country in countries"
                                // ng-model="contact.phone2_country_id"
                                onChange={this.setPhone2CountryId} >
                                <option value="" disabled>번호 선택</option>
                                {countiresOptions}
                            </select>
                        </div>

                        <div className="">
                            <input type="tel"
                                className="form-control student-reg-contact-input height35"
                                onChange={this.setPhone2}
                                value={this.state.paramState.contact.phone2}
                                placeholder={TrasPleaseenteryoursecondaryphonenumber} />
                        </div>
                    </div>
                </div>


                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_kakaotalk.png" />
                        </span>
                        <input onChange={this.setKakao} className="form-control student-reg-contact-input height35"
                            placeholder={TrasKakaotalkID}
                            value={this.state.paramState.contact.kakao}
                        />
                    </div>
                </div>

                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_skype.png" />
                        </span>
                        <input onChange={this.setSkype} className="form-control student-reg-contact-input height35"
                            placeholder={TrasSkypeID}
                            value={this.state.paramState.contact.skype}
                        />
                    </div>
                </div>

                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_wechat.png" />
                        </span>
                        <input onChange={this.setWechat} className="form-control student-reg-contact-input height35"
                            placeholder={TrasWechatID}
                            value={this.state.paramState.contact.wechat}
                        />
                    </div>
                </div>

                <div className="tutorreg-line-a">
                    <div className="input-group">
                        <span className="input-group-addon clear-input-group-addon">
                            <img className="student-reg-contact-pic" src="../images/contact_details_whatsapp.png" />
                        </span>
                        <input onChange={this.setWahtsApp} className="form-control student-reg-contact-input height35"
                            value={this.state.paramState.contact.whatsapp}
                            placeholder={TrasWhatsappID} />
                    </div>
                </div>
            </div>
        )
    }


    openTeacherTermsAndCondition = (e) => {
        if (e) {
            if (!this.state.tutorregState.isReadTeacherTermsAndCondition) {
                this.setState({
                    ...this.state,
                    tutorregState: {
                        ...this.state.tutorregState,
                        isReadTeacherTermsAndCondition: true
                    }
                }, () => {
                    const url = 'https://goo.gl/PPWx72';
                    window.open(url, '_blank');
                })
            } else {
                this.setState({
                    ...this.state,
                    tutorregState: {
                        ...this.state.tutorregState,
                        isReadTeacherTermsAndCondition: false
                    }
                })
            }

        } else {
        }


    }

    createPartB_G = () => {
        return (
            <div className="tutorreg-term-of-use">
                <div className='tutorreg-term-of-use-inner'>
                    <label className="container-check tutorreg-font-sz-14">
                        <input type="checkbox" id="" name="" onChange={this.openTeacherTermsAndCondition} checked />
                        <span>
                            <i className='fontsz14 colorBl'>그루미 선생님 이용약관</i>
                        </span>을 읽었으며 동의합니다.
                        <span className="default-checkmark"></span>
                    </label>
                </div>
            </div>
        )
    }




    validationCheckerPhone = (_num) => {
        const phoneNum = _num;
        const regex = REGEXP();

        // PHOEN MIN LENGTH 6
        if (!phoneNum.trim()) return new Promise((resolve) => { resolve(false) });
        if (phoneNum.trim().length < 6) return new Promise((resolve) => { resolve(false) });
        // CAN BE OTHER COUNTRIES LIKE UK
        // if(!regex.phoneRegExp.test(this.state.formData.phone.trim())) return this.noValidationDisplayer(this.state.DISPLAY_STATE.PHONE);
        // else await this.setState({...this.state, formData : { ...this.state.formData, phone : this.state.formData.phone.trim().replace(/-/g, '') }});

        return new Promise((resolve) => {
            resolve(true)
        });

    }
    validationCheckerNecessarySubject = (_parm) => {
        const value = _parm;

        if (!value) return new Promise((resolve) => { resolve(false) });

        if (typeof value === 'object') {
            if (value.length <= 0) return new Promise((resolve) => { resolve(false) });
            else return new Promise((resolve) => resolve(true));
        } else if (typeof value === 'number') {
            if (value < 0) return new Promise((resolve) => { resolve(false) });
            else return new Promise((resolve) => { resolve(true) });
        }
        if (!value.trim()) return new Promise((resolve) => { resolve(false) });

        return new Promise((resolve) => {
            resolve(true)
        })
    }

    validationChecker = async () => {
        const necessaryEdu = await this.validationCheckerNecessarySubject(this.state.paramState.educationInfos);
        if (!necessaryEdu) return this.showErrorSentance('Please input EducationBackground');

        const necessarySubject = await this.validationCheckerNecessarySubject(this.state.paramState.teachingSubjects);
        if (!necessarySubject) return this.showErrorSentance('Please input subject');

        const necessaryTeachStyle = await this.validationCheckerNecessarySubject(this.state.paramState.teachingStyle);
        if (!necessaryTeachStyle) return this.showErrorSentance('Please choose teachingStyle');


        const necessaryTeachExp = await this.validationCheckerNecessarySubject(this.state.paramState.experience);
        if (!necessaryTeachExp) return this.showErrorSentance('Please input teach experience');


        const necessaryLocation = await this.validationCheckerNecessarySubject(this.state.paramState.location);
        if (!necessaryLocation) return this.showErrorSentance('Please input location');


        const necessaryCharge = await this.validationCheckerNecessarySubject(this.state.paramState.charge);
        if (!necessaryCharge) return this.showErrorSentance('Please input charge');


        const validationPhone = await this.validationCheckerPhone(this.state.paramState.contact.phone);
        if (!validationPhone) return this.showErrorSentance("Please input your contact");


        const necessaryKakaotalk = await this.validationCheckerNecessarySubject(this.state.paramState.contact.kakao);
        if (!necessaryKakaotalk) return this.showErrorSentance('Please input your kakao ID');


        const necessarySkype = await this.validationCheckerNecessarySubject(this.state.paramState.contact.skype);
        if (!necessarySkype) return this.showErrorSentance('Please input your Skype ID');

        if (!this.state.tutorregState.isReadTeacherTermsAndCondition) return this.showErrorSentance('Please check teacher term and condition.');


        return new Promise((resolve) => {
            resolve(true)
        });

    }



    tutorRegSubmit = async () => {
        this.setState({
            ...this.state,
            errorMessage: '',
            tutorregState: {
                ...this.state.tutorregState,
            }
        });
        // To Set State for EduInfos
        const checkSetEdu = await this.makeEduParms();
        const checker = await this.validationChecker();

        if (!checker || !checkSetEdu) {
            return this.setState({
                ...this.state,
                isSubmitting: false,
                tutorregState: {
                    ...this.state.tutorregState
                }
            });
        };

        const edu = this.state.paramState.educationInfos;
        const nestedEducationInfo = [];
        let eduC = '';
        if (edu) {
            for (let i = 0; i < edu.length; i++) {
                const eduKey = Object.keys(edu[i]);
                for (let k = 0; k < eduKey.length; k++) {
                    eduC += `&educationInfos[${i}][${eduKey[k]}]=${encodeURIComponent(edu[i][eduKey[k]])}`;
                }
            }
        }


        const teachSubj = this.state.paramState.teachingSubjects;
        if (teachSubj.length > 15) {
            showNotify("Please select at most 15 curriculums!", STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }
        let teachSubjC = '';
        for (let i = 0; i < teachSubj.length; i++) {
            const teachSubjKey = Object.keys(teachSubj[i]);
            for (let k = 0; k < teachSubjKey.length; k++) {
                if (teachSubjKey[k] === 'keyInOrigin') {
                    teachSubjC += `&teachingSubjects[${i}]=${teachSubj[i][teachSubjKey[k]]}`;
                }
            }
        }
        const nestedTeachingSubj = teachSubjC;


        const contact = this.state.paramState.contact;
        const contactKeys = Object.keys(contact);
        let nestedC = '';
        for (let i = 0; i < contactKeys.length; i++) {
            nestedC += `&contact[${contactKeys[i]}]=${contact[contactKeys[i]]}`;
        }

        const teachings = this.state.paramState.teachingStyle;
        let teachingStyle = '';
        for (let i = 0; i < teachings.length; i++) {
            teachingStyle += '&teachingStyle[' + i + ']=' + teachings[i];
        }

        //picture
        const picData = this.state.paramState.picture;
        const picSplit = picData.split('/');
        const fileName = picSplit[picSplit.length - 1];


        const formData = new FormData();
        formData.append('selfIntro', this.state.paramState.selfIntro);
        formData.append('thumbnailIntro', this.state.paramState.thumbnailIntro);
        formData.append('picture', fileName);
        formData.append('teachingExp', this.state.paramState.teachingExp);
        formData.append('hourlyRate', this.state.paramState.hourlyRate);
        formData.append('experience', this.state.paramState.experience);
        formData.append('location', this.state.paramState.location);
        formData.append('locationMsg', this.state.paramState.locationMsg);
        formData.append('is_current_undergraduate', this.state.paramState.is_current_undergraduate);
        formData.append('is_current_master', this.state.paramState.is_current_master);
        formData.append('secondLocation', this.state.paramState.secondLocation);
        formData.append('secondLocationmsg', this.state.paramState.secondLocationmsg);
        formData.append('charge', this.state.paramState.charge);
        formData.append('hourly_rate_max', this.state.paramState.hourly_rate_max);
        formData.append('certificate', this.state.paramState.certificate);
        formData.append('isRegister', Number(this.state.paramState.isRegister));
        formData.append('visibility', this.state.paramState.visibility);
        formData.append('disable_until', this.state.calendarState.formattingDate);
        formData.append('lang', this.state.lang);
        formData.append('preferred_currency', this.state.paramState.preferred_currency);
        formData.append('bank_account', this.state.paramState.bank_account);
        formData.append('freeDemo', this.state.paramState.freeDemo);

        const allUrls = await GetAjaxURL();

        const profileUrl = allUrls.profile;
        const url = profileUrl + '?var=1' + nestedC + teachSubjC + eduC + teachingStyle;
        const res = await axios.post(url, formData, postConfig).catch((err) => { console.log(err) });

        if (!res) {
            this.setState({
                ...this.state,
                errorMessage: "Try Again"
            });
            return;
        }
        if (!res.data) return;
        if (res.data.status !== "success") {
            showNotify(res.data.msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            // window.location.href = '/';
        } else {
            // SUCCESS 
            this.setState({ isSubmitting: true })
            const lang = this.state.lang;
            const msg = (lang === 'ko') ? '수정이 완료되었습니다.' : (lang === 'en') ? 'Successfully Modified.' : 'Successfully Modified.';

            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            if (res.data.type === 'tutor') {
                window.location.href = '/#/dashboard';
            } else {
                window.location.href = '/#/';
            }

            return;
        }

        this.setState({
            ...this.state,
            isSubmitting: false,
            tutorregState: {
                ...this.state.tutorregState,
            }
        })

    }


    showErrorSentance = (_val) => {
        const value = _val;

        this.setState({
            ...this.state,
            errorMessage: value

        });

        return new Promise((resolve) => { resolve(false) });
    }



    createPartB_H = () => {
        return (
            <div className="col-md-8 col-md-offset-2 margin-top-20">
                <p style={{ color: "red", textAlign: "center" }}>{this.state.errorMessage}</p>
                <div className='tutorreg-submit-btn'>
                    <button className="btn center-block tutorreg-btn-sub tutorreg-btn-fix fontsz17"
                        onClick={this.tutorRegSubmit}
                    >
                        {
                            this.state.isSubmitting ?
                                "제출중..."
                                : "제출"
                        }

                    </button>
                </div>

            </div>
        )
    }




    createHtml = () => {
        return (
            <div className='tutorreg-inner-wrapper'>
                {
                    this.state.lazyLoading ?
                        this.createPartA_wrapper()
                        : ''
                }
                {
                    this.state.lazyLoading ?
                        this.createPartB_wrapper()
                        : ''
                }
            </div>
        )
    }


    render() {
        return (
            <div className='default-wrapper tutorreg-wrapper'>
                {this.createNav()}
                {this.createHtml()}
            </div>
        )
    }
}

export default withRouter(Editteacherprofile);