import { createAction, handleActions } from 'redux-actions';
import { getEnvironment, GetAjaxURL } from '../config/AjaxConfig';
import {localPath} from '../config/localPath';

import axios from '../config/axios';

const GET_ALL_URLS = 'UrlService/GET_ALL_URLS';
const setAllURLs = createAction(GET_ALL_URLS);

export const callAllUrls = () => async dispatch => {
    dispatch(setAllURLs(localPath));
}


const initialState = {
    allUrls : []
}


export default handleActions({
    [GET_ALL_URLS]: (state, action) => {
        return {
            ...state,
            allUrls : action.payload
        };
    }
   
}, initialState);