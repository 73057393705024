import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasUser,
    TrasAccountSetting,
    TrasName,
    TrasEnglishName,
    TrasEmail,
    TrasSelectgender,
    TrasMale,
    TrasFemale,
    TrasNewPassword,
    TrasPassworddoesnotmatch,
    TrasConfirmNewPassword,
    TrasPleaseconfirmthepassword,
    TrasCurrentPassword,
    TrasPleaseentercurrentpassword,
    TrasEdit,
    TrasBack
} from '../../../Translate/EachTranslateC';


import './useraccountsetting.css';
import '../../../default.css';
import './common.css';
import '../users/inputradio.css';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import queryString from 'query-string';

import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';


class useraccountsetting extends Component {


    state = {
        lang: 'ko',
        translate: {
            ko: {
                TrasNeededtoconfirmyourchanges: '정보수정에 필요',
                TrasLeaveblankifyoudontwanttochangeit: '변경을 원하지 않으면 비워두세요',
            },
            en: {
                TrasNeededtoconfirmyourchanges: 'Needed to confirm your changes',
                TrasLeaveblankifyoudontwanttochangeit: 'Leave blank if you dont want to change it',
            },
            cn: {
                TrasNeededtoconfirmyourchanges: '请输入信息才能确认修改',
                TrasLeaveblankifyoudontwanttochangeit: '如果不想修改，请留空',
            },
        },

        userType: '',
        params: {
            first_name: '',
            last_name: '',
            first_name_en: '',
            last_name_en: '',
            email: '',
            password: '',
            password_confirmation: '',
            current_password: '',
            gender: '',
        },

        isSetPassW: true,
        isSetPassWCon: true,
        isSetPassWCurrent: true,
        isEdit: false,
    }


    componentDidMount = async () => {
        const allUrls = await GetAjaxURL();
        const thisUserUrls = allUrls.apiThisUser;
        const thisUser = await axios.get(thisUserUrls).catch((err) => { return console.log(err) });

        if (!thisUser) {
            showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }
        if (!thisUser.data) {
            showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }
        if (!thisUser.data.user) {
            showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const userData = thisUser.data.user;

        const userType = userData.type;

        const first_name = userData.first_name;
        const last_name = userData.last_name;
        const email = userData.email;
        const first_name_en = userData.first_name_en;
        const last_name_en = userData.last_name_en;
        const sex = userData.gender;

        const params = {
            first_name: first_name,
            last_name: last_name,
            first_name_en: first_name_en,
            last_name_en: last_name_en,
            email: email,
            gender: sex,
        };

        this.setState({
            ...this.state,
            userType: userType,
            params: {
                ...this.state.params,
                ...params,
            }
        }, () => {

            if (this.state.params.gender === '1') {
                const male = document.getElementById('male');
                male.click();
            } else {
                const female = document.getElementById('female');
                female.click();
            }

        })


    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasUser /></li>
                    <li className="active"><TrasAccountSetting /></li>
                </ol>
            </div>
        )
    }

    onChangeNewPW = (e) => {
        const target = e.target;
        const value = target.value;

        let isSetPassW = false;
        if (value !== '') {
            isSetPassW = true;
        }
        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                password: value,
            },
            isSetPassW: isSetPassW
        })
    }
    onChangeComparePW = (e) => {
        const target = e.target;
        const value = target.value;

        let isSetPassWCon = false;
        if (value !== '') {
            isSetPassWCon = true;
        }

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                password_confirmation: value,
            },
            isSetPassWCon: isSetPassWCon
        })
    }
    onChangeOldPW = (e) => {
        const target = e.target;
        const value = target.value;

        let isSetPassWCurrent = false;
        if (value !== '') {
            isSetPassWCurrent = true;
        }

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                current_password: value,
            },
            isSetPassWCurrent: isSetPassWCurrent
        }, () => {
        })
    }



    onChangeName = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                first_name: value,
            }
        })
    }

    onChangeSName = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                last_name: value,
            }
        })
    }


    onChangeEnNameFirst = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                first_name_en: value,
            }
        })
    }
    onChangeEnNameLast = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                last_name_en: value,
            }
        })
    }

    createBody = () => {

        const lang = this.state.lang;
        let TrasNeededtoconfirmyourchanges = this.state.translate.ko.TrasNeededtoconfirmyourchanges;
        let TrasLeaveblankifyoudontwanttochangeit = this.state.translate.ko.TrasLeaveblankifyoudontwanttochangeit;

        if (lang === 'en') {
            TrasNeededtoconfirmyourchanges = this.state.translate.en.TrasNeededtoconfirmyourchanges;
            TrasLeaveblankifyoudontwanttochangeit = this.state.translate.en.TrasLeaveblankifyoudontwanttochangeit;
        } else if (lang === 'cn') {
            TrasNeededtoconfirmyourchanges = this.state.translate.cn.TrasNeededtoconfirmyourchanges;
            TrasLeaveblankifyoudontwanttochangeit = this.state.translate.cn.TrasLeaveblankifyoudontwanttochangeit;
        }


        return (
            <div className="u-ac-se-wrapper">
                <div className="common-body-inner-wrap common-body-padding">
                    <div className="common-body-margin-bot ac-header">
                        <h1 className='common-body-h3-positive common-body-margin-bot'><TrasAccountSetting /></h1>
                        <hr style={{ border: '0.5px solid #5b9aef' }}></hr>
                    </div>

                    <div className="u-ac-se-col-type-a">
                        <div className="ac-label">
                            <TrasName /> :
                        </div>
                        <div className="ac-input">
                            <input type="text" className="form-control" onChange={this.onChangeName} value={this.state.params.first_name} />
                        </div>
                        <div className="ac-input">
                            <input type="text" className="form-control" onChange={this.onChangeSName} value={this.state.params.last_name} />
                        </div>
                    </div>
                    <div className="u-ac-se-col-type-a">
                        <div className="ac-label">
                            <TrasEnglishName /> :
                        </div>
                        <div className="ac-input">
                            <input type="text" className="form-control" onChange={this.onChangeEnNameFirst} value={this.state.params.first_name_en} />
                        </div>
                        <div className="ac-input">
                            <input type="text" className="form-control" onChange={this.onChangeEnNameLast} value={this.state.params.last_name_en} />
                        </div>
                    </div>

                    <div className="u-ac-se-col-type-b">
                        <div className="ac-label">
                            <TrasEmail /> :
                        </div>
                        <div className="ac-input">
                            <input type="text" className="form-control" value={this.state.params.email} disabled="disabled" />
                        </div>
                    </div>

                    {this.createSexOrGender()}

                    <div className="u-ac-se-col-type-b">
                        <div className="ac-label">
                            <TrasNewPassword /> :
                        </div>
                        <div className="ac-input">
                            <input type="password" className="form-control" onChange={this.onChangeNewPW}
                                placeholder={TrasLeaveblankifyoudontwanttochangeit} />
                            {
                                !this.state.isSetPassW ?
                                    <span style={{ textAlign: "center", color: "red" }} >
                                        {/* <TrasPassworddoesnotmatch /> */}
                                    </span>
                                    : ''
                            }

                        </div>
                    </div>

                    <div className="u-ac-se-col-type-b">
                        <div className="ac-label">
                            <TrasConfirmNewPassword /> :
                        </div>
                        <div className="ac-input">
                            <input type="password" className="form-control" onChange={this.onChangeComparePW} />
                            {/* <span style={{textAlign: "center", color: "red"}}>
                                <TrasPleaseconfirmthepassword />
                            </span> */}
                            {
                                !this.state.isSetPassWCon ?
                                    <span style={{ textAlign: "center", color: "red" }}>
                                        <TrasPassworddoesnotmatch />
                                    </span>
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="u-ac-se-col-type-b">
                        <div className="ac-label">
                            <TrasCurrentPassword /> :
                        </div>
                        <div className="ac-input">
                            <input type="password" className="form-control" onChange={this.onChangeOldPW}
                                placeholder={TrasNeededtoconfirmyourchanges} />
                            {
                                !this.state.isSetPassWCurrent ?
                                    <span style={{ textAlign: "center", color: "red" }}>
                                        <TrasPleaseentercurrentpassword />
                                    </span>
                                    : ''
                            }
                        </div>
                    </div>

                    <div className="u-ac-se-col-type-a">
                        <div className="ac-label">
                        </div>
                        <div className="ac-input">
                            <button className="center-block btn blue-color userreg-tutor-btn" onClick={this.doSubmit} >
                                {
                                    this.state.isEdit ?
                                        "수정중..."
                                        : <TrasEdit />
                                }
                            </button>
                        </div>
                        <div className="ac-input">
                            <a href="/#/" className="center-block btn blue-color userreg-student-btn"><TrasBack /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    doSubmit = async () => {
        this.setState({ isEdit: true })

        if (this.state.params.password === '') {
            const msg = '새로운 비밀번호를 입력해주세요.';
            return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (this.state.params.password_confirmation === '') {
            return this.setState({
                ...this.state,
                isSetPassWCon: false,
            }, () => {
                const msg = '새로운 비밀번호 확인란을 입력해주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            });
        };
        if (this.state.params.current_password === '') {
            return this.setState({
                ...this.state,
                isSetPassWCurrent: false,
            }, () => {
                const msg = '현재 비밀번호를 입력해주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            });
        }

        const postData = {
            first_name: this.state.params.first_name,
            last_name: this.state.params.last_name,
            first_name_en: this.state.params.first_name_en,
            last_name_en: this.state.params.last_name_en,
            password: this.state.params.password,
            password_confirmation: this.state.params.password_confirmation,
            current_password: this.state.params.current_password,
            gender: this.state.params.gender
        };

        // const strParam = queryString.stringify(postData);
        const strParam =
            'first_name=' + this.state.params.first_name
            + '&last_name=' + this.state.params.last_name
            + '&first_name_en=' + this.state.params.first_name_en
            + '&last_name_en=' + this.state.params.last_name_en
            + '&password=' + this.state.params.password
            + '&password_confirmation=' + this.state.params.password_confirmation
            + '&current_password=' + this.state.params.current_password
            + '&gender=' + this.state.params.gender;

        // const formData = new FormData();
        // formData.append('data', postData);

        const allUrls = await GetAjaxURL();
        const editUserUrl = allUrls.editUser;
        const editUserUrlWithParams = editUserUrl + '?' + strParam;
        const editUserRes = await axios.post(editUserUrlWithParams, {}, postConfig).catch((err) => { return console.log(err); });
        if (!editUserRes) {
            const msg = '서버에서 에러가 발생했습니다.';
            return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (!editUserRes.data) {
            const msg = '서버에서 에러가 발생했습니다.';
            return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (!editUserRes.data.status) {
            const msg = '서버에서 에러가 발생했습니다.';
            return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }

        if (editUserRes.data.status === 'fail') {
            let msg = editUserRes.data.msg;
            const code = editUserRes.data.code;

            if (String(code) === '2') {
                msg = '패스워드가 맞지 않습니다.';
            } else if (String(code) === '3') {
                msg = '패스워드는 6자리 이상만 가능합니다.';
            }

            return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        } else {
            const msg = '성공적으로 변경했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            if (this.state.userType === 'tutor') {
                this.setState({ isEdit: false })
                return window.location.href = '/#/dashboard';
            } else {
                return window.location.href = '/#/students/dashboard';
            }
        }

    }


    clickToMaleAndFemale = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                gender: value
            }
        });
    }


    createSexOrGender = () => {
        return (
            <div className='u-ac-se-col-type-c'>
                <div className="ac-label">
                    <TrasSelectgender /> :
                </div>
                <div className='ac-input'>
                    <div className='ac-input-radio'>
                        <label className=''><input type='radio' id='male' onClick={this.clickToMaleAndFemale} defaultChecked="checked" name='sex' value='1' />
                            <span className="checkmark"></span>
                            <span> <TrasMale /> </span> </label>
                    </div>
                    <div className='ac-input-radio'>
                        <label className=''><input type='radio' id='female' onClick={this.clickToMaleAndFemale} name='sex' value='2' />
                            <span className="checkmark"></span>
                            <span> <TrasFemale /> </span></label>
                    </div>
                </div>
            </div>
        )
    }



    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                <div className='academy-wapper-c'>
                    {this.createBody()}
                </div>
            </div>
        )
    }


    render() {
        return (
            <div className='default-wrapper'>
                {this.createHtml()}
            </div>
        )
    }
}

export default withRouter(useraccountsetting);