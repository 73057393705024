/**
 * Check whether is admin
 * @param user : object
 * @returns {*|boolean}
 */
function isAdmin(user) {
    return (_isAdminType(user.type) || _isAdminEmail(user.email));
}
/**
 * Check whether is adminemail      ----> 참고 url : RRR_isManagerChecker 괜히 연동시키지 마셈. 다음 사람 보기 존나 불편해질 거임.
 * @param email : String
 * @returns {*|boolean}
 */
function isAdminEmail(email) {
    if ("harrylee1230@gmail.com" === email 
        || "support@gurume.kr" === email 
        || "immanuel.kim@gurume.co.uk" === email 
        || "ecubs91@naver.com" === email 
        || "heera@gurume.co.uk" === email 
        || "dilevski.tan@gmail.com" === email 
        || "jessica@gurume.co.uk" === email 
        || "ruby@gurume.kr" === email

        // 2020 03 05 추가된 매니져
        || "jessica@gurume.kr" === email
        || "lisa@gurume.kr" === email
        || "jay@gurume.co.uk" === email
        || "diana@gurume.kr" === email
        || "theresa@gurume.kr" === email
        || "jimmy@gurume.kr" === email
        || "songyi@gurume.kr" === email
        || "leen@gurume.kr" === email
        || "sally@gurume.kr" === email
        || "layla@gurume.co.uk" === email
        || "sooji@gurume.kr" === email
         
        ) {
        return true;
    }
    return false;
}
/**
 * Check whether param typeslug is admin type
 * @param typeSlug
 * @returns {boolean}
 * @private
 */
function _isAdminType(typeSlug) {
    return ("admin" === typeSlug);
}

/**
 * @param email : string
 * @returns {boolean}
 * @private
 */
function _isAdminEmail(email) {
    return isAdminEmail(email);
}

/**
 * get url query
 * @returns {string || null}
 */
function getHashQuery() {
    let query = window.location.hash.split("?")[1];
    if (query) {
        let obj = {};
        query.split("&").forEach(x => {
            let t = x.split('=');
            obj[t[0]] = t[1];
        });
        return obj;
    }
    return query || {};
}

/**
 * format date
 * @param timestamp : number 
 * @param fmt : string  eq: yyyy-MM-dd hh:mm
 * @returns {string || ''}
 */
function newDateFormat(timestamp, fmt='yyyy-MM-dd hh:mm:ss') {
    let date = new Date(timestamp || '')
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //% 12 == 0 ? 12 : date.getHours() % 12, 小时
        "H+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds() //毫秒
    };
    var week = {
        "0": "/u65e5",
        "1": "/u4e00",
        "2": "/u4e8c",
        "3": "/u4e09",
        "4": "/u56db",
        "5": "/u4e94",
        "6": "/u516d"
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? "/u661f/u671f" : "/u5468") : "") + week[date.getDay() + ""]);
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}

export {
    isAdmin,
    getHashQuery,
    newDateFormat,
    isAdminEmail
}