import React, {Component} from 'react';
import {TrasHome} from '../../Translate/EachTranslateC';

import ReactTooltip from 'react-tooltip';
import './admissions_consulting_us.css';
import '../../default.css';

/* font awesome */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList, faPencilAlt, faBook, faSearch} from '@fortawesome/free-solid-svg-icons';


//*** HELLENA ***//
class admissions_consulting_us extends Component {

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li>
                        <a href="/#/"><TrasHome/></a>
                    </li>
                    <li>
                        <a href="/#/admissions_consulting">입시 컨설팅</a>
                    </li>
                    <li className="active">미국</li>
                </ol>
            </div>
        )
    }

    createFirstPart = () => {
        return (
            <div
                className="admissionUs-banner"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "url('/images/background/bg-ivy-league.jpg')",
                    backgroundPosition: "center"
                }}>
                <div className="admissionUs-banner-box">
                    <div className="admissionUs-banner-content text-center">
                        <h2 className="boldest-font white-font-color">
                            미국 대입
                        </h2>
                        <h4 className="normal-font white-font-color">
                            (준)아이비리그/리버럴아츠
                        </h4>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }
    createSecondPart = () => {
        return (
            <div className="admissionUs-map">
                <div className="admissionUs-map-box text-center margin-bottom-30">
                    <div className="admissionUs-map-title margin-top-30">
                        <h2 className="color-gurume-blue bold-font">권장 대입 로드맵</h2>
                    </div>
                    <div className="admissionUs-map-content">
                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </span>
                                </h1>
                                <h3>
                                    분석/상담
                                </h3>
                                <p>
                                    학생 분석<br/>
                                    상담
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faBook}/>
                                    </span>
                                </h1>
                                <h3>
                                    입시 전략
                                </h3>
                                <p>
                                    대학/전공 선택<br/>
                                    원서 시기<br/>
                                    추천서<br/>
                                    재정보조/장학금
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                    </span>
                                </h1>
                                <h3>
                                    원서 준비/제출
                                </h3>
                                <p>
                                    원서 준비 스케줄 확정<br/>
                                    에세이 작성<br/>
                                    원서제출<br/>
                                    인터뷰
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faList}/>
                                    </span>
                                </h1>
                                <h3>
                                    결과 발표
                                </h3>
                                <p>
                                    입시 결과 현황 관리<br/>
                                    디퍼럴/대기<br/>
                                    I-20 비자
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createThirdPart = () => {
        return (
            <div className="admissionUs-field text-center">
                <div className="admissionUs-field-box">
                    <div className="admissionUs-field-title margin-top-30">
                        <h2 className="color-gurume-blue bold-font">대입 컨설팅/멘토링 분야</h2>
                        <p>아래는 대입에 필요할 수 있는 모든 주요 컨설팅/멘토링 분야입니다. 지원하는 대학과 전공 과목에 따라, 필요한 분야가 다를 수 있습니다.</p>
                    </div>
                    <div className="admissionUs-field-content">
                        <div className="admissionUs-item-wrapper">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">학생 분석</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>전화/스카입/면대면 설문 & 인터뷰</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>GPA/표준 시험 성적/활동 리스트 분석</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>학업 장/단점 및 학업 외/커리어 관심 분야 분석</h5>
                                            </td>
                                        </tr>
                                        <tr className="consulting-detail collapse">
                                            <td>
                                                <h5>학업 및 학업 외 ‘훅' 발견</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    className="blue-font-color cursor collapse-btn pull-right margin-bottom-10"
                                    data-toggle="collapse"
                                    data-target=".consulting-detail">더 보기 &raquo;</a>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">상담</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>미국 대입 과정 설명 (선택가능)</h5>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td>
                                                <h5>희망 대학/전공 출신의 재학생/졸업생과 Q&A
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td>
                                                <h5>대학별 합격생들의 GPA/표준 시험 성적/활동 분석</h5>
                                            </td>
                                        </tr>
                                        <tr className="self-introduction-detail collapse">
                                            <td>
                                                <h5>대학/전공 추천 리스트</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    className="blue-font-color cursor collapse-btn pull-right margin-bottom-10"
                                    data-toggle="collapse"
                                    data-target=".self-introduction-detail">더 보기 &raquo;</a>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">재정 보조/장학금</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>Need-blind vs Need-aware 칼리지 조사/정리</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>재정보조/장학금 외국인 지원 칼리지 리스트</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>CSS Profile & FAFSA 서류 작성
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">대학/전공 선택 I</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>칼리지 별 표준 시험 성적 조건 정리</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>대학별 합격생들의 GPA/표준 시험 성적/활동 분석</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>사립 vs 주립/주내 vs 주외 vs 해외 입시 통계 분석</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">대학/전공 선택 II</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>
                                                    칼리지 별 Supplement 에세이 주제 비교
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                    Early Decision/Action 칼리지 선택
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                    각 지원 학교 별 입시 전략
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr className="interview-detail collapse">
                                            <td>
                                                <h5>
                                                    원서 어필을 위한 입시사정관 연락
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    className="blue-font-color cursor collapse-btn pull-right margin-bottom-10"
                                    data-toggle="collapse"
                                    data-target=".interview-detail">더 보기 &raquo;</a>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">추천서</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>
                                                    가장 유리한 추천서를 써주실 선생님 선택
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                    ‘Brag Sheet’ 준비
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                    카운슬러와 커뮤니케이션 가이드
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">에세이 I</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>레슈메/추가자료 정리</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>합격생들의 에세이 분석/평가</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>개성/성장/난관 극복과정/대학 커뮤니티에 공헌 역량을 나타내는 이야기 브레인스토밍</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">에세이 II</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>에세이 스토리라인/전달 메세지 브레인스토밍</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>라이팅 스타일/톤 “어조” 다듬기</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>최대 5번의 드래프팅</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">원서 준비/제출</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>Common/Supplement/UC/기타 App</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>표준 시험 성적 리포팅</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>소득증명 서류</h5>
                                            </td>
                                        </tr>
                                        <tr className="application-form-submission-detail collapse">
                                            <td>
                                                <h5>학교 이메일 회신 도움</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    className="blue-font-color cursor collapse-btn pull-right margin-bottom-10"
                                    data-toggle="collapse"
                                    data-target=".application-form-submission-detail">더 보기 &raquo;</a>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">인터뷰</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>지원 대학 재학생/졸업생과 모의 인터뷰</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>인터뷰 기출문제 풀이
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">디퍼럴/대기 관리</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>학교와 편지 소통</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>대학에 대한 헌신적인 의사 표현</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>표준 시험 성적 향상/활동 업데이트</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">I-20 비자</h2>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>
                                                    I-20 비자 신청 서류 제출
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="clearfix margin-bottom-40"></div>
                    </div>
                </div>
            </div>
        )
    }
    createLastPart = () => {
        return (
            <div
                className="parallax position-relative"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "url('/images/background/bg-ivy-league2.jpg')",
                    backgroundPosition: "center center"
                }}>
                <div className="admissionUs-ment">
                    <div className="admissionUs-ment-box text-center">
                        <div className="admissionUs-ment-content">
                            <span
                                className="admission-apply"
                                data-tip="data-tip"
                                data-for='tooltipEx'
                                data-event='click focus'>
                                <button
                                    className="admissionUs-apply coming-soon-btn border-5 padding-top-30 padding-bottom-30 padding-left-60 padding-right-60 boldest-font white-font-color no-margin tooltip-h2">
                                    상담신청
                                </button>
                                <ReactTooltip
                                    effect="solid"
                                    place="right"
                                    id='tooltipEx'
                                    type='light'
                                    globalEventOff='click'>
                                    <span className='vd-tooltip-ment'>
                                        <span>그루미&nbsp;
                                        </span>
                                        <span>
                                            <a href='/#/contact_us' className='blue-font-color'>
                                                고객 센터</a>
                                        </span>
                                        <span>에 연락해 주세요.</span>
                                    </span>
                                </ReactTooltip>
                            </span>
                        </div>
                        <div className="color-overlay"></div>
                    </div>
                </div>
            </div>
        )
    }
    createHtml = () => {
        return (
            <div className="admission-wrapper">
                {this.createNav()}
                {this.createFirstPart()}
                <div className='academy-wapper-c'>
                    {this.createSecondPart()}
                    {this.createThirdPart()}
                </div>
                {this.createLastPart()}
            </div>
        )
    }
    render() {
        return (<div className='default-wrapper'>
            {this.createHtml()}
        </div>)
    }
}
export default admissions_consulting_us;