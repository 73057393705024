
import React from 'react';


export function Handle({ // your handle component
  handle: { id, value, percent }, 
  disabled,
  getHandleProps
}) {

    let newval = percent;
    if(value === 20000){
        newval = 0;
    }

    return (
        <div
            style={{
            left: `${newval}%`,
            position: 'absolute',
            marginLeft: -15,
            marginTop: 28,
            zIndex: 2,
            width: 25,
            height: 25,
            border: 0,
            textAlign: 'center',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: '#5b9aef',
            color: '#333'
            }}
            {...getHandleProps(id)}
        >
            <div style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: -35, width : 35}}>
            {`${value / 10000}만원`}
            </div>
        </div>
        )

}

export function Track({ source, target, getTrackProps }) { // your own track component
    return (
      <div
        style={{
          position: 'absolute',
          height: 10,
          zIndex: 1,
          marginTop: 35,
          backgroundColor: '#8ac1ef',
          borderRadius: 5,
          cursor: 'pointer',
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
        }}
        {...getTrackProps()} // this will set up events if you want it to be clickeable (optional)
      />
    )
}

export function Tick({ tick, count }) {  // your own tick component
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: 52,
          marginLeft: -0.5,
          width: 1,
          height: 8,
          backgroundColor: 'silver',
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: 60,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {tick.value}
      </div>
    </div>
  )
}