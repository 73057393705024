import React, { Component } from 'react';
import {
    TrasHome
} from '../../Translate/EachTranslateC';

import './admissions_consulting.css';

import ReactTooltip from 'react-tooltip';
import { NoImgContens } from '../../config/AjaxConfig';
import { STATE_NOTIFY, showNotify } from '../extra/cusUtil';
import AdmissionUS from '../../images/background/bg-princeton-univ-thumbnail.jpeg';
import AdmissionUK from '../../images/background/bg-oxbridge-thumbnail.jpg';
import AdmissionKOR from '../../images/background/bg-seoul-univ-thumbnail.jpg';
import AdmissionHong from '../../images/background/bg-hongkong-univ-thumbnail.jpeg';

class admissions_consulting extends Component {


    state = {
        repeatImgs: [
            { url: '/images/consulting/consultant-1.jpg' },
            { url: '/images/consulting/consultant-2.jpg' },
            { url: '/images/consulting/consultant-5.jpg' },
            { url: '/images/consulting/consultant-8.jpg' },
            { url: '/images/consulting/consultant-3.jpg' },
            { url: '/images/consulting/consultant-4.jpg' },
            { url: '/images/consulting/consultant-6.jpg' },
            { url: '/images/consulting/consultant-7.jpg' }

        ]

    }


    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active">입시 컨설팅</li>
                </ol>
            </div>
        )
    }


    onClickUs = () => {
        window.location.href = '/#/admissions_consulting/us';
    }

    onClickUk = () => {
        window.location.href = '/#/admissions_consulting/uk';
    }
    onClickKo = () => {
        window.location.href = '/#/admissions_consulting/korea';
    }

    onClickalt = () => {
        const msg = '준비중입니다.';
        showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);

    }


    createNewPartA = () => {
        return (
            <div className="admission-country text-center margin-top-30">
                <h2 className="color-gurume-blue bold-font margin-bottom-20">대입 컨설팅/멘토링</h2>
                <div className="admission-country-box">
                    <div
                        className="admission-country-item cursor"
                        onClick={this.onClickUs}>
                        <div className="admission-country-item-text">
                            <img src={AdmissionUS} alt='...' onError={function(e){e.target.src = NoImgContens;}} />
                            <h2 className="no-margin text-center white-font-color font-shadow">미국</h2>
                        </div>
                    </div>
                    <div
                        className="admission-country-item cursor"
                        onClick={this.onClickUk}>
                        <div className="admission-country-item-text">
                            <img src={AdmissionUK} alt='...' onError={function(e){e.target.src = NoImgContens;}} />
                            <h2 className="no-margin text-center white-font-color font-shadow">영국</h2>
                        </div>
                    </div>

                    <div
                        className="admission-country-item cursor"
                        onClick={this.onClickKo}>
                        <div className="admission-country-item-text">
                            <img src={AdmissionKOR} alt='...' onError={function(e){e.target.src = NoImgContens;}} />
                            <h2 className="no-margin text-center white-font-color font-shadow">국내</h2>
                        </div>
                    </div>
                    <div
                        className="admission-country-item cursor"
                        data-tip="data-tip"
                        data-for='prepare'
                        data-event='click focus'>
                        <div className="admission-country-item-text">
                            <img src={AdmissionHong} alt='...' onError={function(e){e.target.src = NoImgContens;}} />
                            <h2 className="no-margin text-center white-font-color font-shadow">홍콩</h2>
                        </div>
                    </div>
                    <ReactTooltip
                        effect="solid"
                        place="top"
                        id='prepare'
                        type='light'
                        globalEventOff='click'>
                        <span className='vd-tooltip-ment'>
                            <span>준비중입니다.</span>
                        </span>
                    </ReactTooltip>
                </div>
            </div>
        )
    }

    createNewPartB = () => {
        return (
            <div className="admission-consultant text-center margin-top-40 margin-bottom-2                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    0">
                <div className="admission-consultant-box">
                    <div className="admission-consultant-content">
                        <h2 className="color-gurume-blue bold-font">1+1 컨설턴트/멘토</h2>
                        <p>
                            <i>"매니징 컨설턴트 & 전공자 멘토"</i>
                        </p>
                        <p className="text-left oxbridge-long-term margin-bottom-30">
                            <span className="first-emphasize-letter blue-font-color bold-font">전</span>공자
                            멘토는 입학 심사에 반영되는 부분에 대한 입시 전략/준비를 담당합니다. 모든 전공자 멘토는 아이비리그 및 옥스브릿지를 포함한 학생의 목표 대학
                            출신이며 입시 과정을 성공적으로 치룬 경험자로서 학생들이 입시 과정에서 느끼는 걱정을 이해하며, 수년간의 다수 성공사례 경험을 바탕으로 합격을
                            위한 입시 전략/준비를 전문적으로 제공합니다. 한국을 포함한 영국, 싱가폴, 홍콩 등의 다양한 국적의 원어민 멘토진이 계십니다.
                            <br /><br />
                            매니징 컨설턴트는 상담 후 학생에게 알맞는 멘토 배정을 합니다. 멘토, 학생 그리고 학부모님과의 지속적인 소통을 통해 멘토링이 원활하게
                            이루어지고 학부모님께서 멘토링 현황을 보고 받으실 수 있도록 관리를 합니다. 원서가 제대로 준비되고 기한에 맞추어 제출 되도록 학생과 소통하며
                            결과 발표 후 대학 입학까지 필요한 수속 처리를 담당합니다.
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    createNewPartC = () => {
        const imgsData = this.state.repeatImgs;
        const div = [];

        imgsData.map((data, i) => {
            const url = data.url;
            div.push(
                <div key={i} className="admission-picture-item no-padding cursor">
                    <img src={url} alt='...' onError={function(e){e.target.src = NoImgContens;}} />
                </div>
            )
        })
        return (
            <div className="admission-pictures">
                {div}
            </div>
        )
    }


    createNewPartD = () => {
        return (
            <div className="admission-progress text-center">
                <div className="admission-progress-box">
                    <div className="admission-progress-content margin-bottom-40">
                        <h2 className="color-gurume-blue bold-font margin-top-40">진행 방법</h2>
                        <h4 className="line-height-1-5">
                            <span className="black-font-color font-size-22">면대면</span>
                            <span> @서울, 북경 혹은 스카이프</span><br />
                            <span>그루미에서 각각의 선생님과 연락하거나 그루미 컨설팅 지원팀에 연락하여 전문적인 서비스를 제공받을 수 있습니다.</span>
                        </h4>
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div className='admission-wrapper'>
                {this.createNav()}
                {this.createNewPartA()}
                {this.createNewPartB()}
                {this.createNewPartC()}
                {this.createNewPartD()}
            </div>
        )
    }

    render() {
        return (this.createHtml())
    }

}

export default admissions_consulting;