import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    TrasHome,
    TrasLogin,
    TrasRememberme,
    TrasPleaseenteranemailandpassword,
    TrasEmailnotregistered,
    TrasAccountisnotactivatedyetPleasecheckyouremailandactivateyouraccount,
    TrasWrongpassword,
    TrasForgotyourpassword,
    TrasRegister,
    TrasDonthaveanaccountyet,
    TrasYouareblocked

} from '../../../Translate/EachTranslateC';

import './login.css';
import '../../../default.css';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import axios from '../../../config/axios';
import * as AuthService from '../../../modules/AuthService';

/* font awesome */
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* HELLENA END */
import { cusUtilStart, showNotify, STATE_NOTIFY } from '../../extra/cusUtil';


// INFO
// [ need : already got login, should be redirect ]
// [ --- can be cache problems ]


class login extends Component {


    state = {
        lang: 'ko',        // this will bring from localstorage
        lang_email: {
            ko: "이메일",
            en: "Email",
            cn: "邮箱"
        },
        lang_password: {
            ko: "비밀번호",
            en: "Password",
            cn: "密码"
        },
        lang_login: {
            ko: "로그인",
            en: "Login",
            cn: "登录"
        },
        displayLangEmail: "",
        displayLangPassword: "",
        displayLogin: "",

        validationChecker: {
            wrongEmail: false,
            isEmpty: false,
            notRegister: false,
            notActive: false,
            notPassword: false,
            blocked: false,
            remember: false
        },
        validationState: {
            wrongEmail: 'wrongEmail',
            isEmpty: 'isEmpty',
            notRegister: 'notRegister',
            notActive: 'notActive',
            notPassword: 'notPassword',
            blocked: 'blocked',
            remember: 'remember'
        },
        wrapperSize: ''
    }


    shouldComponentUpdate = (nextProps, nextState) => {

        if (nextProps.needRedirect) {
            //redirect

            window.location.href = '/#/';
        }

        return true;
    }


    componentDidMount = async () => {

        const allUrls = await GetAjaxURL();
        const isLoginUrl = allUrls.isLogin;
        const isLoginRes = await axios.get(isLoginUrl).catch((err) => { return console.log(err); });
        if (isLoginRes.data.isLogin === 'true') {
            let msg = '이미 로그인이 되어 있습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            return window.location.href = '/#/';
        }

        this.settingDisplayLang();
        cusUtilStart(this);
    }
    calScreenSizeAndResizeWrapper = (wrapperSize) => {
        this.setState({
            wrapperSize
        })
    }

    settingDisplayLang = () => {
        let langEmail = '';
        let langPw = '';
        let langLogin = '';

        if (this.state.lang === "cn") {
            langEmail = this.state.lang_email.cn;
            langPw = this.state.lang_password.cn;
            langLogin = this.state.lang_login.cn;
        }
        else if (this.state.lang === "en") {
            langEmail = this.state.lang_email.en;
            langPw = this.state.lang_password.en;
            langLogin = this.state.lang_login.en;
        }
        else {
            langEmail = this.state.lang_email.ko;
            langPw = this.state.lang_password.ko;
            langLogin = this.state.lang_login.ko;
        }





        this.setState({
            ...this.state,
            displayLangEmail: langEmail,
            displayLangPassword: langPw,
            displayLogin: langLogin
        });
    }



    checkEnter = (e) => {
        if (e.keyCode === 13)
            this.onSubmit();
    }

    onSubmit = async () => {


        const email = document.getElementById('email');
        const pw = document.getElementById('password');


        if (! await this.idAndPwValidationChecker(email.value, pw.value)) return;

        const allUrls = await GetAjaxURL();
        let url = allUrls.getLogin;
        url += `?email=${email.value}&password=${pw.value}`;

        const uData = {
        }

        const userData = await axios.post(url, uData, postConfig).catch((err) => { return console.log(err) });

        if (!userData.data) return;
        if (userData.data.status === 'fail') {
            // fail
            if (userData.data.code === 2) {
                this.MsgHandling(this.state.validationState.notRegister);
            }
            if (userData.data.code === 3) {
                this.MsgHandling(this.state.validationState.notActive);
            }
            if (userData.data.code === 4) {
                this.MsgHandling(this.state.validationState.notPassword);
            }
            if (userData.data.code === 5) {
                this.MsgHandling(this.state.validationState.blocked);
            }

        } else if (userData.data.status === 'success') {
            //success
            // First check redirect
            const query = new URLSearchParams(this.props.location.search);
            const rd = query.get('redirect');
            if (rd == 'request_new') {
                window.location.replace('/#/tuition_requests/new');
            } else if (rd == 'blog_comment') {
                const bid = query.get('blog_id');
                window.location.replace('/#/blogs/' + bid);
            } else if (rd == 'review') {
                const rid = query.get('review_id');
                window.location.replace('/#/reviews/new/' + rid);
            } else if (rd == 'teacher') {
                const tid = query.get('teacher_id');
                window.location.replace('/#/teachers/' + tid);
            } else if (rd == 'mooc') {
                const mid = query.get('mooc_id');
                window.location.replace('/#/mooc/' + mid + "/pay");
            } else {
                if (userData.data.type === 'student') {
                    window.location.replace('/#/students/dashboard');
                } else if (userData.data.type === 'tutor') {
                    window.location.replace('/#/dashboard');
                }
            }
            window.location.reload();
        }

    }

    idAndPwValidationChecker = async (_email, _pw) => {

        let validationChk = false;
        if (_email === '' || _pw === '') {
            this.MsgHandling(this.state.validationState.isEmpty);
        } else {
            const emailValidation = _email.split('@');
            if (emailValidation.length > 1) {
                // is fine.
                validationChk = true;
            } else {
                // wrong email address
                this.MsgHandling(this.state.validationState.wrongEmail);
            }
        }

        if (validationChk) {
            return new Promise((resolve) => {
                return resolve(true);
            })
        } else {
            return new Promise((resolve) => {
                return resolve(false);
            })
        }

    }

    MsgHandling = (msg) => {
        switch (msg) {
            case this.state.validationState.isEmpty:
                this.setState({
                    ...this.state,
                    validationChecker: {
                        ...this.state.validationChecker,
                        isEmpty: true
                    }
                });
                break;
            case this.state.validationState.wrongEmail:
                this.setState({
                    ...this.state,
                    validationChecker: {
                        ...this.state.validationChecker,
                        wrongEmail: true
                    }
                }, () => {
                    const email = document.getElementById('email');
                    const pw = document.getElementById('password');
                    email.value = '';
                    pw.value = '';
                });
                break;
            case this.state.validationState.notRegister:
                this.setState({
                    ...this.state,
                    validationChecker: {
                        ...this.state.validationChecker,
                        notRegister: true
                    }
                });
                break;
            case this.state.validationState.notActive:
                this.setState({
                    ...this.state,
                    validationChecker: {
                        ...this.state.validationChecker,
                        notActive: true
                    }
                });
                break;
            case this.state.validationState.notPassword:
                this.setState({
                    ...this.state,
                    validationChecker: {
                        ...this.state.validationChecker,
                        notPassword: true
                    }
                });
                break;
            case this.state.validationState.blocked:
                this.setState({
                    ...this.state,
                    validationChecker: {
                        ...this.state.validationChecker,
                        blocked: true
                    }
                });
                break;
            default:
                this.setState({
                    ...this.state,
                    validationChecker: {
                        ...this.state.validationChecker,
                        wrongEmail: false,
                        isEmpty: false,
                        notRegister: false,
                        notActive: false,
                        notPassword: false,
                        blocked: false,
                        remember: false
                    }
                });
                break;
        }

    }

    changeRem = () => {
    }


    idAndPwChange = (e) => {
        if (this.state.validationChecker.wrongEmail
            || this.state.validationChecker.isEmpty
            || this.state.validationChecker.notActive
            || this.state.validationChecker.notPassword
            || this.state.validationChecker.notRegister
            || this.state.validationChecker.remember
            || this.state.validationChecker.blocked) {

            this.MsgHandling();
        }
    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasLogin /></li>
                </ol>
            </div>
        )
    }
    createBody = () => {

        let wrongEmail = '';
        let isEmpty = '';
        let notRegister = '';
        let notActive = '';
        let notPassword = '';
        let blocked = '';



        if (this.state.validationChecker.wrongEmail)
            wrongEmail = <p style={{ textAlign: "center", color: "red" }}>이메일 주소를 잘못 입력하셨습니다.</p>;

        if (this.state.validationChecker.isEmpty)
            isEmpty = <p style={{ textAlign: "center", color: "red" }}><TrasPleaseenteranemailandpassword /></p>;

        if (this.state.validationChecker.notRegister)
            notRegister = <p className="alert-text"><TrasEmailnotregistered /></p>;

        if (this.state.validationChecker.notActive)
            notActive = <p className="alert-text"><TrasAccountisnotactivatedyetPleasecheckyouremailandactivateyouraccount /></p>;

        if (this.state.validationChecker.notPassword)
            notPassword = <p className="alert-text"><TrasWrongpassword /></p>;

        if (this.state.validationChecker.blocked)
            blocked = <p className="alert-text"><TrasYouareblocked /></p>;

        return (

            <div className="login-wrap">
                <div className='login-sub-wrap' >
                    <div className="login-h2" style={{ opacity: "0.9" }}>
                        <TrasLogin />
                    </div>
                    <div className="login-panel">
                        <div className="input-group userlogin-input-email">
                            <span className="boot-add-on" id="userlogin-username-addon">
                                {/* HELLENA START */}
                                <span style={{ color: "#b5b5b6" }}> <FontAwesomeIcon icon={faEnvelope} /> </span>
                                {/* HELLENA END */}
                            </span>
                            <input id="email" onChange={this.idAndPwChange} type="email" className="form-control login-input-email" placeholder={this.state.displayLangEmail} aria-describedby="userlogin-username-addon" />
                        </div>
                        <div className="input-group userlogin-input-psw">
                            <span className="boot-add-on" id="userlogin-password-addon">
                                {/* HELLENA */}
                                <span style={{ color: "#b5b5b6" }}> <FontAwesomeIcon icon={faLock} /> </span>
                                {/* HELLENA END */}
                            </span>
                            <input id="password" onChange={this.idAndPwChange} onKeyDown={this.checkEnter} type="password" className="form-control login-input-email" placeholder={this.state.displayLangPassword} aria-describedby="userlogin-password-addon" />
                        </div>

                        <div className='font-size-remember-me'>
                            <label className="container-check">
                                <input type="checkbox" id="remember" name="remember" onChange={this.changeRem} />
                                <p><TrasRememberme /></p>
                                <span className="default-checkmark"></span>
                            </label>
                        </div>
                        {wrongEmail}
                        {isEmpty}
                        {notRegister}
                        {notActive}
                        {notPassword}
                        {blocked}
                        <div className="login-btn">
                            <button className="btn blue-color center-block userlogin-login-btn" id="submit" onClick={this.onSubmit}>{this.state.displayLogin}</button>
                        </div>
                        <div className='login-extra-info'>
                            <a href="/#/forgot_password" className="center-block gray4-font-color p-width" style={{ textAlign: "center", marginTop: "4px" }}><TrasForgotyourpassword />?</a>
                            <p style={{ textAlign: "center" }} className="gray4-font-color"><TrasDonthaveanaccountyet /> <a href="/#/register"><TrasRegister /></a></p>
                            <hr style={{ marginTop: "20px", marginBottom: "20px", border: "0", borderTop: "1px solid #eee" }} />
                        </div>

                    </div>
                </div>
            </div>
        )
    }




    createHtml = () => {
        return (
            <section className="login-container default-wrapper" style={{ height: this.state.wrapperSize + 'px' }}>
                {this.createNav()}
                {this.createBody()}
            </section>
        )
    }


    render() {
        return (
            this.createHtml()
        )
    }

}

let mapStateToProps = (state) => {
    return {
        isAuthkey: state.AuthService.isAuthkey,
        authMsg: state.AuthService.authMsg,
        userMessage: state.AuthService.userMessage,
        needRedirect: state.AuthService.needRedirect,
    };
}

let mapDispatchToProps = (dispatch) => {
    return {
        AuthService: bindActionCreators(AuthService, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(login));
// export default login;