import React, { Component } from 'react';
import { withRouter } from 'react-router';
import axios from '../../../config/axios';
import { GetAjaxURL, multipartForm, getEnvironment, NoImgContens, postConfig } from '../../../config/AjaxConfig';

import { isAdmin } from '../../extra/utils';
import { showNotify, STATE_NOTIFY, moneyFormat } from '../../extra/cusUtil';

import ReactTooltip from 'react-tooltip';
import { InfinityController, INFI_STATE } from '../../extra/InfinityScroll';
import { metaRegExp, metaClass, applyMetaHeader } from '../../extra/metaController';


import './studentprofile.css';
import * as imgSmile from '../../../images/smile.png';
import DefaultImg from '../../../images/backuptutorpic.png';
import KakaoIcon from '../../../images/kakao_icon_black.png';
import {
    TrasTutor, TrasCurriculumSubject, TrasStatus, TrasTuitionFee, TrasDetails
    , TrasHourlyrate, TrasTuitionBill, TrasTuition,
    TrasContactdetails,
    TrasInpreparation,
    TrasTuitionhasbeencompleted,

    TrasDemo,
    TrasProgressReport,
    TrasInProgress,
    TrasConfirmingPayment,
    TrasConfirmendoftuition,
    TrasTuitionfeeawaitingtransfertotutor,
    TrasTuitionfeetransfercomplete,
    TrasStartingEndDate,
    Trassessionsweek,
    Trasothershavealreadyapplied,

} from '../../../Translate/EachTranslateC';
import DashboardQuestions from '../../utils/DashboardQuestions';
import TextareaAutosize from 'react-textarea-autosize';

var selectedIdx = [];
class StudentProfile extends Component {

    state = {
        studentId: '',
        thisUserType: '',
        userIsAdmin: false,
        originData: {
            details: '',
        },
        currentDate: 'test : 2020-4-10',
        clickedMonitorNumber: [],
        mockOnlineTuition: [],
        contactModal: {},
        detailModal: {},
        paramData: {
            // id: 2099,
            // name: "희라",
            // email: "heera@gurume.co.uk",
            // updated_at: "2020-03-06 18:03:20",
            // type: "student",
            // first_name: "희라",
            // last_name: "그루미",
            // wechat: "ecubs919",
            // phone: "010 6811 7221",
            // skype: "support@gurume.kr",
            // kakaotalk: "ilovegurume",
            // whatsapp: "",
            // location_id: 37,
            // location_note: "강남",
            // first_name_en: "Consultant",
            // last_name_en: "GuruMe",
            // location2: 0,
            // location2_note: "",
            // preferred_currency: 0,
            // lang: "ko",
            // gender: 2,
            // gcoin: "910000.00",
            // picture: "",
            // expected_subjects: "1",
            // school: "관리자",
            // expected_tutors: "",
            // favorite_questions: "",
            // favorite_moocs: "",
            // favorite_blogs: "44",
            // about_myself: "  about_myself",
            // bank_account: null,
            // parent: 0,
            // memo: null,
            // manager_id: 0,
            // summaries: ""
        },

        // INFINITY SCROLL CONTROL
        tuitionData: [],                        // 전체 데이터
        currentTuitionData: [],                // 현재 데이터
        InfinityController: null,              // InfinityController
        curriculumData: [],
        tuitionRequestData: [],                               // 전체 데이터
        currentTuitionRequestData: [],                        // 현재 데이터
        InfinityControllerTuitionRequest: null,              // InfinityControllerTuitionRequest
        realMonitor: 0,
        tdContactData: {},
        needContact: false,

        isEditDetails: false,
        isEditSummary: false,
        isAjaxDone: false,
        thisUserId: 1
    }

    async componentDidMount() {
        const tempId = this.props.match.params.studentId;

        if (tempId) {

            const apiurls = await GetAjaxURL();
            const url = apiurls.apiThisUser;
            const res = await axios.get(url).catch((err) => { return err; });
            const data = res.data;

            let isAdminCheck = false;
            let thisUserType = '';
            if (data.status !== 'fail') {
                isAdminCheck = isAdmin(data.user);
                thisUserType = data.user.type;
            }
            let thisUserId = data.user.id

            this.setState({
                ...this.state,
                studentId: String(tempId),         // 변경하면 안 되는 값.
                thisUserType: thisUserType,
                userIsAdmin: isAdminCheck,
                thisUserId: thisUserId
            }, () => {
                this.doInit();
            });

        } else {
            window.location.href = '/#/error';
        }
        return;
    }
    doInit = async () => {
        const apiurls = await GetAjaxURL();
        const http = await getEnvironment();
        let url = http + '/react/getFormData';
        url.replace(/ /gi, "");

        let formData = new FormData();
        formData.append('studentId', this.state.studentId);
        formData.append('code', 'studentprofile');

        const res = await axios.post(url, formData, postConfig).catch((err) => { return err; });


        formData = new FormData();
        formData.append('studentId', this.state.studentId);
        formData.append('code', 'studentTuitions');

        const tuitionRes = await axios.post(url, formData, postConfig).catch((err) => { return err; });


        formData = new FormData();
        formData.append('studentId', this.state.studentId);
        formData.append('code', 'studentCurriculums');

        const curriculumsRes = await axios.post(url, formData, postConfig).catch((err) => { return err; });


        if (!res) return;
        if (!res.data) return;
        if (res.data[0] === undefined) window.location.href = "/#/students/2298"
        if (!tuitionRes) return;
        if (!tuitionRes.data) return;


        const data = res.data[0];
        const tuitions = tuitionRes.data.tuitions;
        const curriculums = curriculumsRes.data;

        let tuitionTmp = [];
        for (let i = 0; i < tuitions.length; i++) {
            let obj = {};
            obj.currency = tuitions[i].currency;
            obj.picUrl = tuitions[i].tutor_info.picture;
            obj.name = tuitions[i].tutor_info.name;
            obj.full_name = tuitions[i].tutor_info.full_name;
            obj.startTime = tuitions[i].start_time;
            obj.endTime = tuitions[i].end_time;
            obj.status = tuitions[i].status;
            obj.currency = tuitions[i].student_currency;
            obj.contact = tuitions[i].tutor_info.contact_details;
            obj.report = tuitions[i].report;
            obj.ordernum = tuitions[i].ordernum;
            obj.demo = tuitions[i].demo;
            obj.detail = {};
            obj.detail.hours = tuitions[i].hours;
            obj.detail.isCredit = String(tuitions[i].credit_purchase) === "0" ? false : true;
            obj.tutorId = tuitions[i].tutor_id;
            obj.session = tuitions[i].session_id;
            obj.created_at = tuitions[i].created_at;
            obj.monitor = tuitions[i].monitor;
            obj.monitor_date = tuitions[i].monitor_date;
            obj.date_3_to_4 = tuitions[i].date_3_to_4 == "" ? "N/A" : tuitions[i].date_3_to_4;
            obj.tuition_request_id = tuitions[i].tuition_request_correct;

            if (tuitions[i].student_currency === 1) {
                obj.detail.hourlyRate = Number(tuitions[i].hourly_rate) + 6.72;
            } else if (tuitions[i].student_currency === 2) {
                obj.detail.hourlyRate = Number(tuitions[i].hourly_rate) +
                    (tuitions[i].is_package_tuition ? 100000 :
                        (tuitions[i].is_short_term ? 30000 : 10000)
                    );
            } else {
                obj.detail.hourlyRate = Number(tuitions[i].hourly_rate) + 58;
            }

            obj.detail.tuition = tuitions[i].student_total_amount;
            obj.id = tuitions[i].id;
            obj.detail.subject = [];
            obj.report = tuitions[i].report;

            obj.detail.curriculums = [];
            obj.detail.is_package_tuition = tuitions[i].is_package_tuition;
            obj.detail.is_short_term = tuitions[i].is_short_term;

            // Set curriculums
            for (let m = 0; m < tuitions[i].curriculum_details.length; m++) {

                let isNotAddedCurriculum = true;
                for (let n = 0; n < obj.detail.curriculums.length; n++) {
                    if (obj.detail.curriculums[n].title === tuitions[i].curriculum_details[m].curriculum) {
                        isNotAddedCurriculum = false;
                        break;
                    }
                }

                let title = '';
                if (tuitions[i].curriculum_details[m] !== null) {
                    title = tuitions[i].curriculum_details[m].curriculum;
                }

                if (isNotAddedCurriculum) {
                    let curriculum = {
                        title: title,
                        subjects: []
                    }

                    obj.detail.curriculums.push(curriculum);
                }
            }

            for (let m = 0; m < tuitions[i].curriculum_details.length; m++) {
                for (let n = 0; n < obj.detail.curriculums.length; n++) {
                    let compareCurM = tuitions[i].curriculum_details[m];
                    if (compareCurM !== null) {
                        if (obj.detail.curriculums[n].title === tuitions[i].curriculum_details[m].curriculum) {
                            obj.detail.curriculums[n].subjects.push(tuitions[i].curriculum_details[m].subject);
                        }
                    }
                }
            }
            tuitionTmp.push(obj)

        }


        const tuitionRequestData = tuitionRes.data.requests;

        const InfinityControllers = new InfinityController();
        InfinityControllers.setInit(tuitionTmp);
        const currentTuitionData = InfinityControllers.getDataWithCount(10);

        const InfinityControllerTuitionRequest = new InfinityController();
        InfinityControllerTuitionRequest.setInit(tuitionRequestData);
        const currentTuitionRequestData = InfinityControllerTuitionRequest.getDataWithCount(10);


        this.setState({
            ...this.state,
            paramData: data,
            tuitionData: tuitionTmp,
            curriculumData: curriculums,
            currentTuitionData: currentTuitionData,
            InfinityController: InfinityControllers,
            tuitionRequestData: tuitionRequestData,
            currentTuitionRequestData: currentTuitionRequestData,
            InfinityControllerTuitionRequest: InfinityControllerTuitionRequest,
            isAjaxDone: true,
        }, () => {
            this.props.RootBindingData.doLoading(false);
        });
    }



    createContact = () => {

        const userInfo = this.state.paramData;

        const email = userInfo.email;
        const wechat = userInfo.wechat;
        const phone = userInfo.phone;
        const phone2 = userInfo.phone2
        const skype = userInfo.skype;
        const kakaotalk = userInfo.kakaotalk;
        const whatsapp = userInfo.whatsapp;

        return (
            <div className="student-info">
                {
                    email.length > 0 ?
                        <li><i className="fa fa-envelope"></i>{email}</li>
                        : ''
                }
                {
                    phone.length > 0 ?
                        <li><i className="fa fa-phone"></i>{phone}
                            {
                                phone2.length > 0 ?
                                    <span>({phone2})</span>
                                    : ''
                            }
                        </li>
                        : ''
                }
                {
                    skype.length > 0 ?
                        <li><i className="fa fa-skype"></i>{skype}</li>
                        : ''
                }
                {
                    wechat.length > 0 ?
                        <li><i className="fa fa-wechat"></i>{wechat}</li>
                        : ''
                }
                {
                    kakaotalk.length > 0 ?
                        <li><img src={KakaoIcon} width="14px" height="14px" />{kakaotalk}</li>
                        : ''
                }
            </div>
        )
    }
    deepClone(data) {
        const type = this.judgeType(data);
        let obj;
        if (type === 'array') {
            obj = [];
        } else if (type === 'object') {
            obj = {};
        } else { // 不再具有下一层次
            return data;
        }
        if (type === 'array') { // eslint-disable-next-line
            for (let i = 0, len = data.length; i < len; i++) {
                obj.push(this.deepClone(data[i]));
            }
        } else if (type === 'object') { // 对原型上的方法也拷贝了....
            // eslint-disable-next-line
            for (const key in data) {
                obj[key] = this.deepClone(data[key]);
            }
        }
        return obj;
    }

    judgeType(obj) { // tostring会返回对应不同的标签的构造函数
        const toString = Object.prototype.toString;
        const map = {
            '[object Boolean]': 'boolean',
            '[object Number]': 'number',
            '[object String]': 'string',
            '[object Function]': 'function',
            '[object Array]': 'array',
            '[object Date]': 'date',
            '[object RegExp]': 'regExp',
            '[object Undefined]': 'undefined',
            '[object Null]': 'null',
            '[object Object]': 'object',
        };
        if (obj instanceof Element) {
            return 'element';
        }
        return map[toString.call(obj)];
    }

    changeDetail = (key) => {
        let data = this.deepClone(this.state.mockOnlineTuition[key])
        this.setState({
            detailModal: data
        })
    }

    clickToPoint5 = (e) => {
        const target = e.target;
        let isRightDom = false;
        if (target.id === 'td-tmp-data5') {
            isRightDom = true;
        }
        if (isRightDom) {
            this.setState({
                ...this.state,
                needContact: false
            })
        }
    }

    createContactAddr = () => {

        const tdContactData = this.state.tdContactData;
        let email = tdContactData.email;
        let kakaotalk = tdContactData.kakaotalk;
        let phone = tdContactData.phone;
        let skype = tdContactData.skype;
        let wechat = tdContactData.wechat;
        let whatsapp = tdContactData.whatsapp;
        const stuName = tdContactData.stuName;

        if (!email) email = "입력되지 않았습니다.";
        if (!kakaotalk) kakaotalk = "입력되지 않았습니다.";
        if (!phone) phone = "입력되지 않았습니다.";
        if (!skype) skype = "입력되지 않았습니다.";
        if (!wechat) wechat = "입력되지 않았습니다.";
        if (!whatsapp) whatsapp = "입력되지 않았습니다.";

        return (
            <div className='tdashboard-td-invoice' onClick={this.clickToPoint5}>
                <div id='td-tmp-data5' className='tdash-normal-state'>
                    <div className='tdash-left-contact-s'>
                        <h3>{stuName} - 연락처</h3>
                        <div className='tdash-left-common-advice'>연락처를 이용해 연락해보세요!</div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_email.png' alt='...' /></span><span>{email}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_phone.png' alt='...' /></span><span>{phone}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_kakaotalk.png' alt='...' /></span><span>{kakaotalk}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_wechat.png' alt='...' /></span><span>{wechat}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_skype.png' alt='...' /></span><span>{skype}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createDetailsModal = () => {
        let modalData = this.state.currentTuitionData;
        if (!modalData.detail) return;
        if (!modalData.detail.curriculums) return;

        let DisplayCurrency = 'KRW';
        if (modalData.currency === 1) {
            DisplayCurrency = 'RMB';
        } else if (modalData.currency !== 2 && modalData.currency !== 1) {
            DisplayCurrency = 'GBP';
        }

        return (
            <div id="viewDetailsModal" className="modal fade" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title"><TrasDetails /></h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body text-center">
                            <h4 className="blue-font-color">결제</h4>
                            {
                                modalData.detail.isCredit ?
                                    <h4>크레딧</h4>
                                    : <h4>계좌이체</h4>
                            }
                            <h4 className="blue-font-color">시간</h4>
                            <h4>{parseFloat((modalData.detail && modalData.detail.hours) || 0) +
                                parseFloat(modalData.demo ? 0.5 :
                                    0)}</h4>
                            <h4 className="blue-font-color">
                                <TrasHourlyrate />
                            </h4>
                            <h4>{modalData.detail.hourlyRate} {' ' + DisplayCurrency} </h4>
                            <h4 className="blue-font-color"><TrasTuitionFee /></h4>
                            <h4><span>{modalData.detail.tuition}</span>{' ' + DisplayCurrency}</h4>
                            <div>
                                <a className="btn pop-up-btn"
                                    href={`/#/tuitions/confirm/${modalData.ordernum}`}
                                    target="_blank"><TrasTuitionBill /></a>
                            </div>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    scrollDectectFunc = () => {
        const isScrollDone = this.state.InfinityController.scrollDetect('infiWapper3', 'infiList3');
        if (isScrollDone) {
            const currentTuitionData = this.state.InfinityController.getDataWithCount(5);
            this.setState({
                ...this.state,
                currentTuitionData: currentTuitionData,
            }, () => {
            });
        }
    }

    scrollDectectFuncRequest = () => {
        const isScrollDone = this.state.InfinityControllerTuitionRequest.scrollDetect('infiWapper1', 'infiList1');
        if (isScrollDone) {
            const currentTuitionRequestData = this.state.InfinityControllerTuitionRequest.getDataWithCount(5);
            this.setState({
                ...this.state,
                currentTuitionRequestData: currentTuitionRequestData,
            }, () => {
            });
        }
    }
    confirmMonitor = async (idx) => {
        /* confirm message */
        const msg = "정말로 확인하시겠습니까?"


        if (window.confirm(msg)) {
            const http = await getEnvironment();
            let url = http + '/react/getFormData';
            url.replace(/ /gi, "");

            const cDate = new Date()
            const cMonth = cDate.getMonth() + 1
            const clickDate = cDate.getFullYear() + '-' + cMonth + '-' + cDate.getDate();


            let formData = new FormData();
            formData.append('tuitionId', idx);
            formData.append('monitorDate', clickDate);
            formData.append('code', 'monitor_confirm');

            const res = await axios.post(url, formData, postConfig).catch((err) => { return err; });
            window.location.reload();
        } else {
            return;
        }
    }

    showingNewChildren = (_data) => {
        this.setState({
            ...this.state,
            needContact: true,
            tdContactData: _data
        });
    }


    clickedToSms = (x) => {


        const email = x.email;
        const kakaotalk = x.kakaotalk;
        const phone = x.phone;
        const skype = x.skype;
        const wechat = x.wechat;
        const whatsapp = x.whatsapp;
        const stuName = "";

        const data = {
            email, kakaotalk, phone, skype, wechat, whatsapp, stuName
        }

        this.showingNewChildren(data);
    }


    createTuitions = () => {
        const items = this.state.currentTuitionData;

        const getLocalstorage = JSON.parse(window.localStorage.getItem('selectedArr'))
        selectedIdx = getLocalstorage

        const clickedMonitorNums = selectedIdx
        const list = items.map((item, index) => {
            let reportUrl = '';
            let reportType;
            if (item.report) {
                if (item.report.url) {
                    reportUrl = item.report.url;
                    reportType = item.demo === 0 ? "Progress Report" : "Demo Class Report";

                }
            }
            let requestUrl = '/#/tuition_request/' + item.tuition_request_id;
            return (
                <tr key={'MockOnlineTuition' + index}>
                    <td>
                        <img alt="img" className="img-circle dash-img-size cursor"
                            src={item.picUrl ? item.picUrl : DefaultImg}
                            onError={function (e) { e.target.src = '../images/backuptutorpic.png'; }}
                        />
                        <p>{item.full_name} {item.tutor_id}</p>
                        <button type="button" className="student-tuition-table-btn"
                            onClick={() => this.clickedToSms(item.contact)}
                        ><TrasContactdetails />
                        </button>
                    </td>
                    <td>
                        {
                            item.detail.curriculums.map((curriculum, index2) => {
                                return <p key={'DetailsCurriculum' + index2}>
                                    <b>{curriculum.title}</b>{curriculum.subjects.join(", ")}<br />
                                </p>;
                            })
                        }
                        {/* {
                            item.demo == 1 ? <p className="blue-font-color"><a href={reportUrl} target="_blank"><TrasDemo /></a></p> : ''
                        } */}
                        {
                            (String(item.status) === '2' || String(item.status) === '4' || String(item.status) === '5' || String(item.status) === '6' || String(item.status) === '7') ?
                                <a href={reportUrl} target="_blank">
                                    <button className="student-tuition-table-btn" onClick={this.clickToInfo}>{reportType}<br /></button>
                                </a>
                                : ''
                        }
                        {
                            item.tuition_request_id != 0 ?
                                <a href={requestUrl}>
                                    <br /><button className="student-tuition-table-btn">Tuition Request</button>
                                </a> : ''
                        }
                    </td>
                    <td>
                        <div>
                            <p>
                                {item.startTime}~
                                <br />
                                {item.endTime}
                                <br />
                                ({item.session})
                            </p>
                        </div>
                    </td>
                    <td>
                        <p>
                            {
                                item.status == '0' ? <TrasInpreparation /> : item.status == '2' ? <TrasInProgress /> : item.status == '3' ? <TrasConfirmingPayment />
                                    : item.status == '4' ? <TrasInProgress /> : item.status == '5' ? <TrasConfirmendoftuition /> : item.status == '6' ? <TrasTuitionfeeawaitingtransfertotutor />
                                        : <TrasTuitionfeetransfercomplete />
                            }
                        </p>
                        {
                            (String(item.status) === '2' || String(item.status) === '4' || String(item.status) === '5') && item.demo != 1 ?
                                <div>
                                    <button className="student-tuition-table-btn margin-bottom-5" onClick={this.clickToInfo}>
                                        <TrasTuitionhasbeencompleted />
                                    </button>
                                    <div className="clearfix"></div>
                                    <DashboardQuestions />

                                </div>
                                : ''
                        }
                        {
                            String(item.monitor) === '0' && item.session % 2 == 1 ?
                                <p>
                                    모니터&nbsp;
                                    <button className="gurume-grey-back-btn" onClick={() => this.confirmMonitor(item.id)}>완료</button>
                                    &nbsp;
                                </p> :
                                <p>
                                    모니터&nbsp;
                                    <span>{item.monitor_date}</span>
                                    &nbsp;
                                </p>
                        }
                    </td>
                    {
                        this.state.userIsAdmin ?
                            <td className="text-center">
                                <p>{moneyFormat(item.detail.tuition)} </p>
                                <p>({item.date_3_to_4})</p>
                                <button type="button" className="btn pop-up-btn text-center" data-toggle="modal" data-target="#viewDetailsModal" onClick={() => this.changeDetail(index)}>
                                    <TrasDetails />
                                </button>
                            </td>
                            : ''
                    }
                </tr>
            )
        }
        );

        return (
            <div className="student-tuition-table-wrapper">
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="row dashboard-student-tuition">
                            <div className="col-md-12">
                                {this.createDetailsModal()}
                                <div className="table-responsive infi-wapper" id='infiWapper3' onScroll={this.scrollDectectFunc}>
                                    <table className="table">
                                        <thead>
                                            <tr className="text-center">
                                                <td><p><TrasTutor /></p></td>
                                                <td><p><TrasCurriculumSubject /></p></td>
                                                <td><p>기간</p></td>
                                                <td><p><TrasStatus /></p></td>
                                                {
                                                    this.state.userIsAdmin ?
                                                        <td><p><TrasTuitionFee /></p></td>
                                                        : ''
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="text-center" id='infiList3'>
                                            {list}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createTuitionRequest = () => {

        const tuitionRequestData = this.state.currentTuitionRequestData;

        const list2 = tuitionRequestData.map((item, index) => {

            return (
                <div key={'TuitionRequests' + index} className="card_thumbnail border-blue-2 border-radius-4">
                    <i><p>{item.created_at.split(' ')[0]}</p></i>
                    <p className="ng-curriculum-subject"><TrasCurriculumSubject />:
                            {
                            item.curriculums.map((curriculum, index2) => {
                                const tempCurriculum = curriculum;
                                if (tempCurriculum) {
                                    return <span key={'TuitionCurriculum' + index2}>{tempCurriculum.curriculum} {tempCurriculum.subject}/ </span>
                                } else {
                                    return <span key={'TuitionCurriculum' + index2}></span>;
                                }
                            })
                        }
                    </p>

                    <p className="ng-start-end-date"><TrasStartingEndDate />:
                        {
                            item.end_time !== '2100-01-01' ?
                                <span>
                                    {item.start_time} ~ {item.end_time} ({item.hours}<Trassessionsweek />)
                            </span>
                                :
                                <span>
                                    {item.start_time} ~ 일정 조율 예정 ({item.hours}<Trassessionsweek />)
                            </span>
                        }
                    </p>
                    {
                        item.status >= 0 ?
                            <div>
                                <p><i>(*{item.status}<Trasothershavealreadyapplied />)</i></p>
                            </div>
                            :
                            <div className="ng-applier-count">
                                <p><i>(*{(item.status) - 1}<Trasothershavealreadyapplied />)</i></p>
                            </div>
                    }
                    <button className="student-tuition-request-detail-btn fix-top-margin">
                        <a href={'/#/tuition_requests/' + item.id}>
                            <TrasDetails />
                        </a>
                    </button>
                </div>
            )
        }
        )

        return list2;
    }

    editMemoDetailsCancel = () => {
        const details = this.state.originData.details;

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                details: details,
            },
            isEditDetails: !this.state.isEditDetails
        })
    }
    editSummaryDetailsCancel = () => {
        const details = this.state.originData.details;

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                details: details,
            },
            isEditSummary: !this.state.isEditSummary
        })
    }

    // isEditDetails : false,
    // isEditSummary : false,

    editDetails = async () => {

        if (!this.state.isEditDetails) {
            this.setState({
                ...this.state,
                isEditDetails: !this.state.isEditDetails
            })
        } else {
            if (!window.confirm('변경하시겠습니까?')) return;

            const allUrls = await GetAjaxURL();
            const url = allUrls.RRR_ReactEditor;

            const formData = new FormData();
            formData.append('code', 'studentprofile_detail');
            formData.append('id', this.state.studentId);
            formData.append('contents', this.state.paramData.memo);

            const req = await axios.post(url, formData, postConfig).catch((err) => { return console.log(err) });

            if (req) {
                if (req.data) {
                    let msg = req.data.msg;
                    if (req.data.status === 'success') {
                        showNotify('업데이트 되었습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                    } else {
                        showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                    }
                }
            }

            this.setState({
                ...this.state,
                originData: {
                    ...this.state.originData,
                    details: this.state.paramData.details,
                },
                isEditDetails: !this.state.isEditDetails
            })
        }


    }
    changeMemo = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                memo: value
            }
        })
    }
    EditSummary = async () => {

        if (!this.state.isEditSummary) {
            this.setState({
                ...this.state,
                isEditSummary: !this.state.isEditSummary
            })
        } else {
            if (!window.confirm('변경하시겠습니까?')) return;

            const allUrls = await GetAjaxURL();
            const url = allUrls.RRR_ReactEditor;

            const formData = new FormData();
            formData.append('code', 'studentprofile_summary');
            formData.append('id', this.state.studentId);
            formData.append('contents', this.state.paramData.summaries);

            const req = await axios.post(url, formData, postConfig).catch((err) => { return console.log(err) });

            if (req) {
                if (req.data) {
                    let msg = req.data.msg;
                    if (req.data.status === 'success') {
                        showNotify('업데이트 되었습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                    } else {
                        showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                    }
                }
            }

            this.setState({
                ...this.state,
                originData: {
                    ...this.state.originData,
                    details: this.state.paramData.details,
                },
                isEditSummary: !this.state.isEditSummary
            })
        }


    }
    changeSummary = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                summaries: value
            }
        })
    }

    render() {
        if (!this.state.isAjaxDone) {
            return <div className="teacher-profile-loading"><div className="loader"></div></div>
        }

        const userInfo = this.state.paramData;


        const userImage = userInfo.picture;
        const name = userInfo.last_name + userInfo.first_name;
        const area = userInfo.location_note;

        const gender = userInfo.gender;
        const updated_at = userInfo.updated_at.split(' ')[0];

        const school = userInfo.school;
        const memo = userInfo.memo || '';
        const summaries = userInfo.summaries || '';
        const manager_name = userInfo.manager_name || '-';

        let gcoin = userInfo.gcoin.split('.');
        if (gcoin.length !== 1) {
            gcoin = gcoin[0];
        }


        let displayMemo = 'Edit';
        if (this.state.isEditDetails) {
            displayMemo = 'Done';
        }
        let displaySummary = 'Edit';
        if (this.state.isEditSummary) {
            displaySummary = 'Done';
        }
        const editTextarea = {
            width: '100%',
            borderRadius: '8px',
            border: '2px solid #5b9aef',
            resize: 'none',
            overflow: 'hidden',
            padding: '6px 12px',
            color: '#6c767f'
        }


        const curriculums = this.state.curriculumData;

        const curlDiv = [];
        if (curriculums != null && curriculums.length > 0) {
            let curls = curriculums;
            let srtCurls = curls.sort((a, b) => { return a.test_type > b.test_type ? -1 : 0 });
            srtCurls = srtCurls.sort((a, b) => { return a.test_type === 2 ? -1 : 0 });

            let currentNode = srtCurls[0];
            let childSpan = [];
            if (srtCurls.length === 1) {
                let sClass = 'tp-profile-bc-op-lv-fl-liv-border-fls-btitle ';
                let sChildClass = 'tp-profile-bc-op-lv-fl-liv-border-fls-b ';
                if (currentNode.test_type === 1) {
                    sClass += 'tp-profile-bc-op';
                    sChildClass += 'tp-profile-bc-op-lv';
                } else if (currentNode.test_type === 2) {
                    sClass += 'tp-profile-bc-op-lv-fl';
                    sChildClass += 'tp-profile-bc-op-lv-fl-liv-border-fls';
                } else {
                    sClass += 'tp-profile-bc-op-lv-fl-liv';
                    sChildClass += 'tp-profile-bc-op-lv-fl-liv-border';
                }
                childSpan.push(<span key={`sub-ss0`} className={sChildClass}>{currentNode.subject}</span>);
                childSpan.unshift(<span key={`curs-0`} className={sClass}>{currentNode.curriculum}</span>);
                curlDiv.push(
                    <div className='tp-profile-children' key={'s' + 0}>
                        {childSpan}
                    </div>
                );
                childSpan = [];
            }
            for (let i = 1; i < srtCurls.length; i++) {
                let nextNode = srtCurls[i];

                let sClass = 'tp-profile-bc-op-lv-fl-liv-border-fls-btitle ';
                let sChildClass = 'tp-profile-bc-op-lv-fl-liv-border-fls-b ';
                if (currentNode.test_type === 1) {
                    sClass += 'tp-profile-bc-op';
                    sChildClass += 'tp-profile-bc-op-lv';
                } else if (currentNode.test_type === 2) {
                    sClass += 'tp-profile-bc-op-lv-fl';
                    sChildClass += 'tp-profile-bc-op-lv-fl-liv-border-fls';
                } else {
                    sClass += 'tp-profile-bc-op-lv-fl-liv';
                    sChildClass += 'tp-profile-bc-op-lv-fl-liv-border';
                }

                if (currentNode.curriculum !== nextNode.curriculum) {
                    childSpan.push(<span key={`sub-s${i}`} className={sChildClass}>{currentNode.subject}</span>);
                    childSpan.unshift(<span key={`cur-${i}`} className={sClass}>{currentNode.curriculum}</span>);
                    curlDiv.push(
                        <div className='tp-profile-children' key={i}>
                            {childSpan}
                        </div>
                    );
                    childSpan = [];
                } else {
                    childSpan.push(<span key={`sub-s${i}`} className={sChildClass}>{currentNode.subject}</span>);
                }
                currentNode = nextNode;

                if (i === srtCurls.length - 1) {
                    childSpan.push(<span key={`sub-ss${i}`} className={sChildClass}>{currentNode.subject}</span>);
                    childSpan.unshift(<span key={`curs-${i}`} className={sClass}>{currentNode.curriculum}</span>);
                    curlDiv.push(
                        <div className='tp-profile-children' key={'s' + i}>
                            {childSpan}
                        </div>
                    );
                    childSpan = [];
                }
            }
        }



        return (
            <div className="student-show">
                <div className="student-show-wrapper">
                    <div className="student-show-box">
                        <div className="student-show-contents">
                            <div className="student-show-left">
                                <div className="student-intro-card">
                                    <div className="student-intro-left">
                                        <div className="student-profile-img">
                                            <img src={`${userImage}`} onError={function (e) { e.target.src = DefaultImg; }} />

                                        </div>
                                        <div className="student-profile-explanation">
                                            <h3 className="text-center">
                                                <span className="student-genter">
                                                    {
                                                        String(gender) === '1' ?
                                                            <i className="fa fa-mars"></i>
                                                            :
                                                            <i className="fa fa-venus"></i>
                                                    }


                                                </span>
                                                <span className="student-name">{name}</span>
                                            </h3>
                                            <h3 className="text-center">
                                                <span className="student-name">Manager: {manager_name}</span>
                                            </h3>
                                            <p className="text-center"><i className="fa fa-map-marker"></i>{area}</p>
                                            {
                                                this.state.userIsAdmin ?
                                                    this.createContact()
                                                    : ''
                                            }
                                            {
                                                this.state.needContact ?
                                                    this.createContactAddr()
                                                    : ''
                                            }

                                        </div>
                                    </div>
                                    <div className="student-intro-right">
                                        <p>학력</p>
                                        <div className="school-info">
                                            <span className="school-label">고등</span>
                                            <span>{school}</span>
                                        </div>
                                        <p>수업과목</p>
                                        {curlDiv}
                                    </div>
                                </div>
                                {
                                    this.state.userIsAdmin || this.state.thisUserId === 1187 || this.state.thisUserId === 70 || this.state.thisUserId === 1567 || this.state.thisUserId === 2142 || this.state.thisUserId === 3430 || this.state.thisUserId === 559 ?
                                        <div className="student-memo-card">
                                            <p className="last-updated">{updated_at}</p>
                                            <h4 className="blue-font-color ng-binding">학생메모
                                        {
                                                    this.state.userIsAdmin ?
                                                        <input className='comm-btn-style' type='button'
                                                            onClick={this.editDetails}
                                                            value={displayMemo} />
                                                        : ''
                                                }
                                                {
                                                    this.state.isEditDetails ?
                                                        <input className='comm-btn-style' type='button' onClick={this.editMemoDetailsCancel} value={'Cancel'} />
                                                        : ''
                                                }
                                            </h4>

                                            {
                                                this.state.isEditDetails ?
                                                    <TextareaAutosize value={this.state.paramData.memo} onChange={this.changeMemo} style={editTextarea}></TextareaAutosize>
                                                    :
                                                    <TextareaAutosize value={memo} disabled></TextareaAutosize>
                                            }
                                        </div>
                                        : ''
                                }
                                {
                                    this.state.userIsAdmin || this.state.thisUserId === 1187 || this.state.thisUserId === 70 || this.state.thisUserId === 1567 || this.state.thisUserId === 2142 || this.state.thisUserId === 3430 || this.state.thisUserId === 559 ?
                                        <div className="student-summary-card">
                                            <h4 className="blue-font-color ng-binding">상담내용
                                        {
                                                    this.state.userIsAdmin ?
                                                        <input className='comm-btn-style' type='button'
                                                            onClick={this.EditSummary}
                                                            value={displaySummary} />
                                                        : ''
                                                }
                                                {
                                                    this.state.isEditSummary ?
                                                        <input className='comm-btn-style' type='button' onClick={this.editSummaryDetailsCancel} value={'Cancel'} />
                                                        : ''
                                                }
                                            </h4>
                                            {
                                                this.state.isEditSummary ?
                                                    <TextareaAutosize value={this.state.paramData.summaries} onChange={this.changeSummary} style={editTextarea}></TextareaAutosize>
                                                    :
                                                    <TextareaAutosize value={summaries} disabled></TextareaAutosize>

                                            }
                                        </div> : ''
                                }
                                {
                                    this.state.userIsAdmin || this.state.thisUserId === 1187 || this.state.thisUserId === 70 || this.state.thisUserId === 1567 || this.state.thisUserId === 2142 || this.state.thisUserId === 3430 || this.state.thisUserId === 559 ?
                                        <div className="student-tuition-card">
                                            <h4 className="blue-font-color ng-binding">수업</h4>
                                            <div>
                                                {this.createTuitions()}
                                            </div>
                                        </div> : ''
                                }
                            </div>

                            {
                                this.state.userIsAdmin ?
                                    <div className="student-show-right">
                                        <div className="student-credit-card">
                                            <h4 className="blue-font-color ng-binding">크레딧</h4>
                                            <p>보유 크레딧 : {moneyFormat(gcoin)}원</p>
                                        </div>
                                        <div className="student-tuition-requests infi-wapper" id='infiWapper1' onScroll={this.scrollDectectFuncRequest}>
                                            <h4 className="blue-font-color ng-binding">수업신청</h4>
                                            <div className="student-tuition-requests-wrapper" id='infiList1'>
                                                {this.createTuitionRequest()}
                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withRouter(StudentProfile);