import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    TrasHighSchool,
    TrasUndergraduate,
    TrasMaster,
    TrasPhD,
    TrasUploadcertificate,
    TrasUploading,

} from '../../../Translate/EachTranslateC';


import './SchoolInputGroup.css';
import axios from '../../../config/axios';
import { GetAjaxURLFileUpload, multipartForm } from '../../../config/AjaxConfig';
import ReactTooltip from 'react-tooltip';


// Field of Study


const SchoolInputGroupPropTypes = {
    universitiesData: PropTypes.any,
    majorsData: PropTypes.any,
    getDataFromSchoolInputGroup: PropTypes.func,
    educationInfos: PropTypes.any,
    teacherId: PropTypes.any,
    is_current_undergraduate: PropTypes.any,
}
const SchoolInputGroupDefaultProps = {
    universitiesData: [],
    majorsData: [],
    getDataFromSchoolInputGroup: () => { return console.log("getDataFromSchoolInputGroup NOT SETTING") },
    educationInfos: [],
    teacherId: -1,
    is_current_undergraduate: '0',
}


const schoolInputObj = () => {
    return {

        highSchoolName: '',
        highSchoolPeriodStartYear: '',
        highSchoolPeriodEndYear: '',
        selectedHighSchoolTest: '',

        is_current_undergraduate: false,
        undergraduatePeriodStartYear: '',
        undergraduatePeriodEndYear: '',
        selectedUndergraduateUniversity: '',
        selectedUndergraduateMajors: '',
        undergraduateSpecificMajor: '',

        selectedMasterUniversity: '',
        masterPeriodStartYear: '',
        masterPeriodEndYear: '',
        selectedMasterMajors: '',
        masterSpecificMajor: '',

        selectedPhDUniversity: '',
        phDPeriodStartYear: '',
        phDPeriodEndYear: '',
        selectedPhDMajors: '',
        phDSpecificMajor: '',
    }

}



class SchoolInputGroup extends Component {


    state = {
        lang: 'ko',
        translate: {
            ko: {
                highSchoolName: '고등학교 이름',
                specificMajor: '특정 전공',
                leaveBlankIf: '과목명이 선택한 전공과 일치하면 비워두시면 됩니다.',
                TrasSelecttest: '시험 선택',
                TrasUniversityname: '대학교 이름',
                TrasFieldofStudy: '전공분야',

            },
            en: {
                highSchoolName: 'High school name',
                specificMajor: 'Specific Major',
                leaveBlankIf: 'Leave blank if your major name is identical to one of the major fields in the list above',
                TrasSelecttest: 'Select test',
                TrasUniversityname: 'University name',
                TrasFieldofStudy: 'Field of Study',

            },
            cn: {
                highSchoolName: '高中的名字',
                specificMajor: '专业方向',
                leaveBlankIf: '如果您的主要名称与上面列表中的某个主要字段相同，请留空',
                TrasSelecttest: '选择测试',
                TrasUniversityname: '大学名称',
                TrasFieldofStudy: '专业范围',

            }
        },
        needMasterDegree: false,
        needDoctoralDegree: false,
        highSchoolTestList: ['IB', 'AP', 'A Level', 'Pre-U'],

        SchoolInputParams: {
            highSchoolName: '',
            highSchoolPeriodStartYear: '',
            highSchoolPeriodEndYear: '',
            selectedHighSchoolTest: '',

            is_current_undergraduate: false,
            undergraduatePeriodStartYear: '',
            undergraduatePeriodEndYear: '',
            selectedUndergraduateUniversity: '',
            selectedUndergraduateMajors: '',
            undergraduateSpecificMajor: '',

            selectedMasterUniversity: '',
            masterPeriodStartYear: '',
            masterPeriodEndYear: '',
            selectedMasterMajors: '',
            masterSpecificMajor: '',

            selectedPhDUniversity: '',
            phDPeriodStartYear: '',
            phDPeriodEndYear: '',
            selectedPhDMajors: '',
            phDSpecificMajor: '',
        },
        needLazyLoading: true,

        curImgState: '',
        imgSetState: {
            LOADING: 'LOADING',
            SETTING: 'SETTING',
            FAILING: 'FAILING'
        },
        dataUrl: null,
        certDataFromServer: '',

    }


    componentDidMount = () => {
        setTimeout(() => {
            this.setState({
                ...this.state,
                needLazyLoading: false,
            }, () => {
                // DOES NOT NEED IN TUTOREREG
                // this.setEducationInfo();

                this.setInitvalue();
            })
        }, 1000);
    }

    setInitvalue = () => {
        if (this.props.teacherId === -1) return;

        const teacherId = this.props.teacherId;
        const educationInfos = this.props.educationInfos;

        // THIS PART WILL BE DIRTY
        // THIS SHOULD BE CHANGED AFTER FINISHING

        const newObj = schoolInputObj();

        const checkSteps = educationInfos.length;


        if (educationInfos.length > 0) {
            // INPUT HIGH SCHOOL

            const highS = educationInfos[0];
            const hName = highS['highschool'];
            const originTime = highS['time'].split('-');
            const hStartYear = originTime[0];
            const hEndYear = originTime[1];
            const hTestName = highS['testName'];

            newObj.highSchoolName = educationInfos[0]['highschool'];
            newObj.highSchoolPeriodStartYear = hStartYear;
            newObj.highSchoolPeriodEndYear = hEndYear;
            newObj.selectedHighSchoolTest = hTestName;

            // UNDERGRADUATE
            if (educationInfos.length > 1) {

                const uGrad = educationInfos[1];
                const uOriginTime = uGrad.time.split('-');
                const uStartYear = uOriginTime[0];
                const uEndYear = uOriginTime[1];
                const uUnivercityKey = uGrad['universityID'];
                const uMajor = uGrad['major'];
                const uSpecMajor = uGrad['nameOfMajor'];

                newObj.undergraduatePeriodStartYear = uStartYear;
                newObj.undergraduatePeriodEndYear = uEndYear;
                newObj.selectedUndergraduateUniversity = uUnivercityKey;
                newObj.selectedUndergraduateMajors = uMajor;
                newObj.undergraduateSpecificMajor = uSpecMajor;

                // MASTER
                if (educationInfos.length > 2) {
                    const mDgr = educationInfos[2];
                    const mOriginTime = mDgr.time.split('-');
                    const mStartYear = mOriginTime[0];
                    const mEndYear = mOriginTime[1];
                    const mUnivercityKey = mDgr['universityID'];
                    const mMajor = mDgr['major'];
                    const mSpecMajor = mDgr['nameOfMajor'];

                    newObj.masterPeriodStartYear = mStartYear;
                    newObj.masterPeriodEndYear = mEndYear;
                    newObj.selectedMasterUniversity = mUnivercityKey;
                    newObj.selectedMasterMajors = mMajor;
                    newObj.masterSpecificMajor = mSpecMajor;

                    // PHD
                    if (educationInfos.length > 3) {
                        const pdhDgr = educationInfos[3];
                        const pdhOriginTime = pdhDgr.time.split('-');
                        const pdhStartYear = pdhOriginTime[0];
                        const pdhEndYear = pdhOriginTime[1];
                        const pdhUnivercityKey = mDgr['universityID'];
                        const pdhMajor = pdhDgr['major'];
                        const pdhSpecMajor = pdhDgr['nameOfMajor'];


                        newObj.phDPeriodStartYear = pdhStartYear;
                        newObj.phDPeriodEndYear = pdhEndYear;
                        newObj.selectedPhDUniversity = pdhUnivercityKey;
                        newObj.selectedPhDMajors = pdhMajor;
                        newObj.phDSpecificMajor = pdhSpecMajor;
                    }
                }

            }

        }


        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                ...newObj
            }

        }, () => {
            // //
            if (checkSteps > 3) {
                this.clickMasterButton(true);
            } else if (checkSteps > 2) {
                this.clickMasterButton();
            }
            if (this.props.is_current_undergraduate !== '0') {
                const is_current_undergraduate = document.getElementById('is_current_undergraduate');
                is_current_undergraduate.click();
            }

        })


    }



    // Only High School And UnderGraduate
    // WILL BE CHANGED IN LATER 2019-10-26 RRR
    setEducationInfo = () => {

        const educationInfos = this.props.educationInfos;

        if (educationInfos.length <= 0) return;


        let highSchoolName = '';
        let highSchoolPeriodStartYear = '';
        let highSchoolPeriodEndYear = '';
        let selectedHighSchoolTest = '';

        let is_current_undergraduate = false;
        let undergraduatePeriodStartYear = '';
        let undergraduatePeriodEndYear = '';
        let selectedUndergraduateUniversity = '';
        let selectedUndergraduateMajors = '';
        let undergraduateSpecificMajor = '';

        let selectedMasterUniversity = '';
        let masterPeriodStartYear = '';
        let masterPeriodEndYear = '';
        let selectedMasterMajors = '';
        let masterSpecificMajor = '';

        let selectedPhDUniversity = '';
        let phDPeriodStartYear = '';
        let phDPeriodEndYear = '';
        let selectedPhDMajors = '';
        let phDSpecificMajor = '';




        for (let i = 0; i < educationInfos.length; i++) {

            if (Object.keys(educationInfos[i]).length <= 0) continue;

            if (educationInfos[i].degree === 'High School') {
                highSchoolName = educationInfos[i].degree;
                // highSchoolPeriodStartYear = educationInfos[i].time;
                // highSchoolPeriodEndYear = educationInfos[i].time;
                selectedHighSchoolTest = educationInfos[i].school;
            } else if (educationInfos[i].degree === 'Undergraduate') {
                // is_current_undergraduate = false;
                // undergraduatePeriodStartYear = '';
                // undergraduatePeriodEndYear = '';
                selectedUndergraduateUniversity = this.findUniversityKey(educationInfos[i].universityID);
                selectedUndergraduateMajors = this.findMajorKey(educationInfos[i].major);
                undergraduateSpecificMajor = educationInfos[i].nameOfMajor;
            }

        }

        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                highSchoolName,
                selectedHighSchoolTest,

                selectedUndergraduateUniversity,
                selectedUndergraduateMajors,
                undergraduateSpecificMajor
            }
        }, () => {
        })
    }

    subFindMajorKey = (key) => {
        const majors = this.props.majorsData;
        return majors[key].detail;
    }


    // PARAMS DEGREE AND NAME
    // THIS WILL RETURN KEY VALUE
    findMajorKey = (_degree, _name) => {
        const majors = this.props.majorsData;

        const degree = _degree;
        const name = _name;

        let findKey;
        if (degree === 'Undergraduate') {
            findKey = 0;
        } else if (degree === 'Master') {
            findKey = 1;

        } else if (degree === 'PhD') {
            findKey = 2;

        } else if (degree === '학사') {
            findKey = 3;

        } else if (degree === '석.박사') {
            findKey = 4;

        } else if (degree === '커리어') {
            findKey = 5;
        }

        const degreeMajor = this.subFindMajorKey(findKey);

        let majorKey = 999999999;
        for (let i = 0; i < degreeMajor.length; i++) {
            if (degreeMajor[i].subject === name) {
                majorKey = degreeMajor[i].id;
            }
        }


        return majorKey;
    }

    findUniversityKey = (_name) => {
        const univercities = this.props.universitiesData;

        const name = _name;
        let univercityKey = '';

        if (typeof name === 'number') {
            univercityKey = name;
        } else {
            for (let i = 0; i < univercities.length; i++) {

                if (univercities[i].university_name === name) {
                    univercityKey = univercities[i].id;
                    break;
                }
            }
        }

        return univercityKey;

    }

    setHighSchoolName = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                highSchoolName: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'highSchoolName',
                value: this.state.SchoolInputParams.highSchoolName
            });
        })
    }

    setHighSchoolPeriodStartYear = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                highSchoolPeriodStartYear: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'highSchoolPeriodStartYear',
                value: this.state.SchoolInputParams.highSchoolPeriodStartYear
            })
        })
    }

    setHighSchoolPeriodEndYear = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                highSchoolPeriodEndYear: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'highSchoolPeriodEndYear',
                value: this.state.SchoolInputParams.highSchoolPeriodEndYear
            })
        })
    }

    setSelectedHighSchoolTest = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                selectedHighSchoolTest: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'selectedHighSchoolTest',
                value: this.state.SchoolInputParams.selectedHighSchoolTest
            })
        })
    }

    // HIGH SCHOOL
    createPartA = () => {

        const lang = this.state.lang;

        let TrasSelecttest = this.state.translate.ko.TrasSelecttest;
        if (lang === 'en') {
            TrasSelecttest = this.state.translate.en.TrasSelecttest;
        } else if (lang === 'cn') {
            TrasSelecttest = this.state.translate.cn.TrasSelecttest;
        }


        const highSchoolTestList = this.state.highSchoolTestList;

        const highSchoolTestListDom = [];

        for (let i = 0; i < highSchoolTestList.length; i++) {

            if (this.state.SchoolInputParams.selectedHighSchoolTest === highSchoolTestList[i]) {
                highSchoolTestListDom.push(
                    <option key={i} value={highSchoolTestList[i]} selected>{highSchoolTestList[i]}</option>
                );
            } else {
                highSchoolTestListDom.push(
                    <option key={i} value={highSchoolTestList[i]}>{highSchoolTestList[i]}</option>
                );
            }
        }

        return (
            <div className="school-input-group-part-a">
                <label
                    className="school-input-group-part-a-label col-xs-12 padding-left-5 padding-right-5 no-padding-bottom static control-label font-size-20 color-black">
                    <TrasHighSchool />
                </label>
                <div className='line-wrapper'>
                    <div className="group-part-a-input">
                        <div>
                            <input className="form-control height34" onChange={this.setHighSchoolName} type="text"
                                placeholder={this.state.lang === 'ko' ? this.state.translate.ko.highSchoolName : this.state.lang === 'en' ? this.state.translate.en.highSchoolName : this.state.translate.cn.highSchoolName}
                                value={this.state.SchoolInputParams.highSchoolName}
                            />
                        </div>
                        <div className="input-group">
                            <input onChange={this.setHighSchoolPeriodStartYear} placeholder="YYYY" className="form-control no-border-right height34"
                                type="number"
                                value={this.state.SchoolInputParams.highSchoolPeriodStartYear}
                            />
                            <span className="input-group-addon no-border-left-right no-padding-left no-padding-right no-border-left no-border-right" style={{ backgroundColor: "transparent" }}>~</span>
                            <input onChange={this.setHighSchoolPeriodEndYear} placeholder="YYYY" className="form-control no-border-left"
                                type="number"
                                value={this.state.SchoolInputParams.highSchoolPeriodEndYear}
                            />
                        </div>
                    </div>
                    <div className="group-part-a-input school-fix">
                        <select className="form-control height34 fontsz14" onChange={this.setSelectedHighSchoolTest}>
                            <option value="">-- {TrasSelecttest} --</option>
                            {highSchoolTestListDom}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    set_is_current_undergraduate = (e) => {
        const value = e.target.checked;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                is_current_undergraduate: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'is_current_undergraduate',
                value: this.state.SchoolInputParams.is_current_undergraduate
            })
        })
    }

    setUndergraduatePeriodStartYear = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                undergraduatePeriodStartYear: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'undergraduatePeriodStartYear',
                value: this.state.SchoolInputParams.undergraduatePeriodStartYear
            })
        })
    }

    setUndergraduatePeriodEndYear = (e) => {
        const value = e.target.value;

        var d = new Date();
        var n = d.getFullYear();
        const is_current_or_not = document.getElementById("is_current_undergraduate");
        if (value >= n) {
            is_current_or_not.checked = true;
        } else {
            is_current_or_not.checked = false;
        }

        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                undergraduatePeriodEndYear: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'undergraduatePeriodEndYear',
                value: this.state.SchoolInputParams.undergraduatePeriodEndYear
            })
        })
    }

    setSelectedUndergraduateUniversity = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                selectedUndergraduateUniversity: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'selectedUndergraduateUniversity',
                value: this.state.SchoolInputParams.selectedUndergraduateUniversity
            })
        })
    }

    setSelectedUndergraduateMajors = (e, _isEdit) => {

        const value = e.target.value;

        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                selectedUndergraduateMajors: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'selectedUndergraduateMajors',
                value: this.state.SchoolInputParams.selectedUndergraduateMajors
            })
        })
    }

    setUndergraduateSpecificMajor = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                undergraduateSpecificMajor: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'undergraduateSpecificMajor',
                value: this.state.SchoolInputParams.undergraduateSpecificMajor
            })
        })
    }

    // undergraduate
    createPartB = () => {

        let TrasUniversityname = this.state.translate.ko.TrasUniversityname;
        let TrasFieldofStudy = this.state.translate.ko.TrasFieldofStudy;
        let placeholderStr = this.state.translate.ko.specificMajor + this.state.translate.ko.leaveBlankIf;

        if (this.state.lang === 'en') {
            placeholderStr = this.state.translate.en.specificMajor + this.state.translate.en.leaveBlankIf;
            TrasUniversityname = this.state.translate.en.TrasUniversityname;
            TrasFieldofStudy = this.state.translate.en.TrasFieldofStudy;
        } else if (this.state.lang === 'cn') {
            placeholderStr = this.state.translate.cn.specificMajor + this.state.translate.cn.leaveBlankIf;
            TrasUniversityname = this.state.translate.cn.TrasUniversityname;
            TrasFieldofStudy = this.state.translate.cn.TrasFieldofStudy;
        }


        const universitiesData = this.props.universitiesData;
        const majorsData = this.props.majorsData[0].detail; //  "Undergraduate"

        const universityDoms = [];
        const majorDoms = [];

        for (let i = 0; i < universitiesData.length; i++) {

            if (this.state.SchoolInputParams.selectedUndergraduateUniversity === universitiesData[i].id) {
                universityDoms.push(
                    <option key={`uni_${i}`} value={universitiesData[i].id} selected> {universitiesData[i].university_name} </option>
                );
            } else {
                universityDoms.push(
                    <option key={`uni_${i}`} value={universitiesData[i].id}> {universitiesData[i].university_name} </option>
                );
            }

        }
        for (let i = 0; i < majorsData.length; i++) {
            if (this.state.SchoolInputParams.selectedUndergraduateMajors === universitiesData[i].id) {
                majorDoms.push(
                    <option key={`uni_${i}`} value={majorsData[i].id} selected> {majorsData[i].subject} </option>
                );
            } else {
                majorDoms.push(
                    <option key={`uni_${i}`} value={majorsData[i].id}> {majorsData[i].subject} </option>
                );
            }
        }

        return (
            <div className="school-input-group-part-a">
                <label
                    className="school-input-group-part-b-label col-xs-12 padding-left-5 padding-right-5 no-padding-bottom static control-label font-size-20 color-black">
                    <TrasUndergraduate />
                    <label htmlFor="is_current_undergraduate" className="font-size-12 color-gray2 container-check ">
                        <input type='checkbox' id='is_current_undergraduate' onChange={this.set_is_current_undergraduate} />
                        <span>예비 대학생 혹은 재학생입니다.</span>
                        <span className="default-checkmark"></span>
                    </label>
                </label>

                <div className='line-wrapper'>
                    <div className="group-part-a-input">
                        <div className="input-group">
                            <select className="form-control height34 fontsz14"
                                onChange={this.setSelectedUndergraduateUniversity}
                                value={this.state.SchoolInputParams.selectedUndergraduateUniversity}
                            >
                                <option value="">-- {TrasUniversityname} --</option>
                                {universityDoms}
                            </select>
                        </div>
                        <div className="input-group">
                            <input placeholder="YYYY" className="form-control no-border-right height34"
                                onChange={this.setUndergraduatePeriodStartYear}
                                type="number"
                                value={this.state.SchoolInputParams.undergraduatePeriodStartYear}
                            />
                            <span className="input-group-addon no-border-left-right no-padding-left no-padding-right no-border-left no-border-right" style={{ backgroundColor: "transparent" }}>~</span>
                            <input onChange={this.setUndergraduatePeriodEndYear} placeholder="YYYY" className="form-control no-border-left height34"
                                type="number"
                                value={this.state.SchoolInputParams.undergraduatePeriodEndYear}
                            />
                        </div>
                    </div>

                    <div className="group-part-a-input">
                        <div className="input-group">
                            <select className="form-control height34 fontsz14"
                                onChange={this.setSelectedUndergraduateMajors}
                                value={this.state.SchoolInputParams.selectedUndergraduateMajors}
                            >
                                <option value="">-- {TrasFieldofStudy} --</option>
                                {majorDoms}
                            </select>

                        </div>
                        <div className="input-group">
                            <input onChange={this.setUndergraduateSpecificMajor} className="form-control height34" type="text"
                                placeholder={placeholderStr}
                                value={this.state.SchoolInputParams.undergraduateSpecificMajor}
                            />
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    setSelectedMasterUniversity = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                selectedMasterUniversity: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'selectedMasterUniversity',
                value: this.state.SchoolInputParams.selectedMasterUniversity
            })
        })
    }

    setMasterPeriodStartYear = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                masterPeriodStartYear: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'masterPeriodStartYear',
                value: this.state.SchoolInputParams.masterPeriodStartYear
            })
        })
    }

    setMasterPeriodEndYear = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                masterPeriodEndYear: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'masterPeriodEndYear',
                value: this.state.SchoolInputParams.masterPeriodEndYear
            })
        })
    }

    setSelectedMasterMajors = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                selectedMasterMajors: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'selectedMasterMajors',
                value: this.state.SchoolInputParams.selectedMasterMajors
            })
        })
    }

    setMasterSpecificMajor = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                masterSpecificMajor: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'masterSpecificMajor',
                value: this.state.SchoolInputParams.masterSpecificMajor
            })
        })
    }

    // MASTER
    createPartC = () => {

        let TrasUniversityname = this.state.translate.ko.TrasUniversityname;
        let TrasFieldofStudy = this.state.translate.ko.TrasFieldofStudy;
        let placeholderStr = this.state.translate.ko.specificMajor + this.state.translate.ko.leaveBlankIf;

        if (this.state.lang === 'en') {
            placeholderStr = this.state.translate.en.specificMajor + this.state.translate.en.leaveBlankIf;
            TrasUniversityname = this.state.translate.en.TrasUniversityname;
            TrasFieldofStudy = this.state.translate.en.TrasFieldofStudy;
        } else if (this.state.lang === 'cn') {
            placeholderStr = this.state.translate.cn.specificMajor + this.state.translate.cn.leaveBlankIf;
            TrasUniversityname = this.state.translate.cn.TrasUniversityname;
            TrasFieldofStudy = this.state.translate.cn.TrasFieldofStudy;
        }

        const universitiesData = this.props.universitiesData;
        const majorsData = this.props.majorsData[1].detail; //  "TrasMaster"

        const universityDoms = [];
        const majorDoms = [];

        for (let i = 0; i < universitiesData.length; i++) {
            universityDoms.push(
                <option key={`uni_${i}`} value={universitiesData[i].id}> {universitiesData[i].university_name} </option>
            );
        }
        for (let i = 0; i < majorsData.length; i++) {
            majorDoms.push(
                <option key={`uni_${i}`} value={majorsData[i].id}> {majorsData[i].subject} </option>
            );
        }


        return (


            <div className="school-input-group-part-a">
                <label
                    className="col-xs-12 no-padding-bottom padding-left-5 padding-right-5 static control-label font-size-20 color-black">
                    <TrasMaster />
                </label>

                <div className='line-wrapper'>
                    <div className="group-part-a-input">
                        <div className="input-group">
                            <select className="form-control height34 fontsz14"
                                onChange={this.setSelectedMasterUniversity}
                                value={this.state.SchoolInputParams.selectedMasterUniversity}
                            >
                                <option value="">-- {TrasUniversityname} --</option>
                                {universityDoms}
                            </select>
                        </div>
                        <div className="input-group">
                            <input onChange={this.setMasterPeriodStartYear} placeholder="YYYY" className="form-control no-border-right height34"
                                type="number"
                                value={this.state.SchoolInputParams.masterPeriodStartYear}
                            />
                            <span className="input-group-addon no-border-left-right no-padding-left no-padding-right no-border-left no-border-right" style={{ backgroundColor: "transparent" }}>~</span>
                            <input onChange={this.setMasterPeriodEndYear} placeholder="YYYY" className="form-control no-border-left height34"
                                type="number"
                                value={this.state.SchoolInputParams.masterPeriodEndYear}
                            />
                        </div>
                    </div>

                    <div className="group-part-a-input">
                        <div className="input-group">
                            <select className="form-control height34 fontsz14"
                                onChange={this.setSelectedMasterMajors}
                                value={this.state.SchoolInputParams.selectedMasterMajors}
                            >
                                <option value="">-- {TrasFieldofStudy} --</option>
                                {majorDoms}
                            </select>
                        </div>
                        <div className="input-group">
                            <input id="" onChange={this.setMasterSpecificMajor} className="form-control height34" type="text"
                                placeholder={placeholderStr}
                                value={this.state.SchoolInputParams.masterSpecificMajor}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    setSelectedPhDUniversity = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                selectedPhDUniversity: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'selectedPhDUniversity',
                value: this.state.SchoolInputParams.selectedPhDUniversity
            })
        })
    }
    setPhDPeriodStartYear = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                phDPeriodStartYear: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'phDPeriodStartYear',
                value: this.state.SchoolInputParams.phDPeriodStartYear
            })
        })
    }
    setPhDPeriodEndYear = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                phDPeriodEndYear: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'phDPeriodEndYear',
                value: this.state.SchoolInputParams.phDPeriodEndYear
            })
        })
    }
    setSelectedPhDMajors = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                selectedPhDMajors: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'selectedPhDMajors',
                value: this.state.SchoolInputParams.selectedPhDMajors
            })
        })
    }
    setPhDSpecificMajor = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            SchoolInputParams: {
                ...this.state.SchoolInputParams,
                phDSpecificMajor: value
            }
        }, () => {
            this.props.getDataFromSchoolInputGroup({
                type: 'phDSpecificMajor',
                value: this.state.SchoolInputParams.phDSpecificMajor
            })
        })
    }

    // Phd
    createPartD = () => {

        let TrasUniversityname = this.state.translate.ko.TrasUniversityname;
        let TrasFieldofStudy = this.state.translate.ko.TrasFieldofStudy;
        let placeholderStr = this.state.translate.ko.specificMajor + this.state.translate.ko.leaveBlankIf;

        if (this.state.lang === 'en') {
            placeholderStr = this.state.translate.en.specificMajor + this.state.translate.en.leaveBlankIf;
            TrasUniversityname = this.state.translate.en.TrasUniversityname;
            TrasFieldofStudy = this.state.translate.en.TrasFieldofStudy;
        } else if (this.state.lang === 'cn') {
            placeholderStr = this.state.translate.cn.specificMajor + this.state.translate.cn.leaveBlankIf;
            TrasUniversityname = this.state.translate.cn.TrasUniversityname;
            TrasFieldofStudy = this.state.translate.cn.TrasFieldofStudy;
        }


        const universitiesData = this.props.universitiesData;
        const majorsData = this.props.majorsData[2].detail; //  "TrasPhD"

        const universityDoms = [];
        const majorDoms = [];

        for (let i = 0; i < universitiesData.length; i++) {
            universityDoms.push(
                <option key={`uni_${i}`} value={universitiesData[i].id}> {universitiesData[i].university_name} </option>
            );
        }
        for (let i = 0; i < majorsData.length; i++) {
            majorDoms.push(
                <option key={`uni_${i}`} value={majorsData[i].id}> {majorsData[i].subject} </option>
            );
        }


        return (
            <div className="school-input-group-part-a" >
                <label
                    className="col-xs-12 no-margin-bottom no-padding-bottom padding-left-5 padding-right-5 static control-label font-size-20 color-black">
                    <TrasPhD />
                </label>


                <div className='line-wrapper'>
                    <div className="group-part-a-input">
                        <div className="input-group">
                            <select className="form-control height34 fontsz14"
                                onChange={this.setSelectedPhDUniversity}
                                value={this.state.SchoolInputParams.selectedPhDUniversity}
                            >
                                <option value="">-- {TrasUniversityname} --</option>
                                {universityDoms}
                            </select>
                        </div>
                        <div className="input-group">
                            <input onChange={this.setPhDPeriodStartYear} placeholder="YYYY" className="form-control no-border-right height34"
                                type="number"
                                value={this.state.SchoolInputParams.phDPeriodStartYear}
                            />
                            <span className="input-group-addon no-border-left-right no-padding-left no-padding-right no-border-left no-border-right" style={{ backgroundColor: "transparent" }}>~</span>
                            <input onChange={this.setPhDPeriodEndYear} placeholder="YYYY" className="form-control no-border-left height34"
                                type="number"
                                value={this.state.SchoolInputParams.phDPeriodEndYear}
                            />
                        </div>
                    </div>

                    <div className="group-part-a-input">
                        <div className="input-group marginBottom10">
                            <select className="form-control height34 fontsz14" onChange={this.setSelectedPhDMajors}
                                value={this.state.SchoolInputParams.selectedPhDMajors}
                            >
                                <option value="">-- {TrasFieldofStudy} --</option>
                                {majorDoms}
                            </select>
                        </div>
                        <div className="input-group">
                            <input onChange={this.setPhDSpecificMajor} className="form-control height34" type="text"
                                placeholder={placeholderStr}
                                value={this.state.SchoolInputParams.phDSpecificMajor}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    clickMasterButton = (_next) => {
        if (this.state.needMasterDegree) {
            this.setState({
                ...this.state,
                needMasterDegree: !this.state.needMasterDegree,
                needDoctoralDegree: false,
            }, () => {
                const phdDom = document.getElementById('phdDom');
                const CLASS_NAME = ['active', 'none'];
                phdDom.classList.remove(CLASS_NAME[0]);
                phdDom.classList.add(CLASS_NAME[1]);
                this.props.getDataFromSchoolInputGroup({
                    type: 'is_current_master',
                    value: this.state.needMasterDegree
                })
            })
        } else {
            this.setState({
                ...this.state,
                needMasterDegree: !this.state.needMasterDegree,
            }, () => {
                const phdDom = document.getElementById('phdDom');
                const CLASS_NAME = ['active', 'none'];
                phdDom.classList.remove(CLASS_NAME[1]);
                phdDom.classList.add(CLASS_NAME[0]);
                this.props.getDataFromSchoolInputGroup({
                    type: 'is_current_master',
                    value: this.state.needMasterDegree
                })

                if (_next === true) {
                    this.clickDoctoralButton();
                }
            })
        }

    }

    clickDoctoralButton = () => {
        if (!this.state.needMasterDegree) return alert('먼저 석사를 선택해주세요.');

        let is_current_phd = 0;
        if (this.state.needDoctoralDegree) is_current_phd = 1;

        this.setState({
            ...this.state,
            needDoctoralDegree: !this.state.needDoctoralDegree,
        }, () => {
            const phdDom = document.getElementById('phdDom');
            const CLASS_NAME = ['active', 'none'];
            if (this.state.needDoctoralDegree) {
                phdDom.classList.remove(CLASS_NAME[1]);
                phdDom.classList.add(CLASS_NAME[0]);
            } else {
                phdDom.classList.remove(CLASS_NAME[0]);
                phdDom.classList.add(CLASS_NAME[1]);
            }
            this.props.getDataFromSchoolInputGroup({
                type: 'is_current_phd',
                value: this.state.needDoctoralDegree
            })
        })
    }

    clickCertificate = async () => {
        //         import axios from '../../../config/axios';
        // import { GetAjaxURLFileUpload } from '../../../config/AjaxConfig';

        // $http.post(apiBaseUrl + 'helper/s3/upload', postMsg, postConfig).then(function (res) {
        //     var data = res.data;
        //     if (data.status === 'success') {
        //         showNotify(lang == 'cn' ? '图片已上传' : lang == 'en' ? 'Image has successfully been uploaded.'
        //             : '이미지를 성공적으로 업로드하였습니다.', "success");

        //         $scope.certificateImages.push(data.message);
        //         $scope.onUploading = false;
        //         $scope.uploaded = true;
        //         $scope.certificate = data.message;

        //     }
        // })

        const schoolCert = document.getElementById('schoolCert');
        schoolCert.click();




        // https://gurume-tutor-certificate.s3.ap-northeast-2.amazonaws.com/y7Enyqx1h9Blie1nfFzv.png
        // this.props.getCertDataFromSchoolInputGroup(this.state.dataUrl);


    }

    clickBox = async () => {

        if (this.state.dataUrl === null) return alert('NO DATA OF A CERTIFICATE');

        const formData = new FormData();
        formData.append('image-data', this.state.dataUrl);
        formData.append('type', 'tutor');

        const urlObj = await GetAjaxURLFileUpload();
        const fileLoadUrl = urlObj.uploadPic;
        const res = await axios.post(fileLoadUrl, formData, multipartForm).catch((err) => { console.log(err) });

        if (!res) return;
        if (!res.data) return;

        if (res.data.status === 'success') {

            // 2019.10.04 From Database, THIS HAS DEFERENT DATA FROM A PICTURE OF TEACHERS.
            // certificate Colum has urls like 'https://gurume-tutor-certificate.s3.ap-northeast-2.amazonaws.com/y7Enyqx1h9Blie1nfFzv.png'
            // picture Colum has file name like 'pVE5qAtoQ0oXGj5nFxYx.png'
            // THIS CAN BE MODIFIED AFTER FINISHING.
            // IT'S BETTER TO HAVE THE SAME TYPE

            // message
            this.setState({
                ...this.state,
                certDataFromServer: res.data.message
            },
                () => {
                    const tempObj = {
                        dispalyDataUrl: this.state.dataUrl,
                        certDataUrl: this.state.certDataFromServer
                    }
                    this.props.getCertDataFromSchoolInputGroup(tempObj);
                });

        }

    }



    createPartE = () => {

        // temp mark

        return (
            <div className='school-btns-wrapper'>
                <div className='school-btn'>
                    <input type="file" id='schoolCert' style={{ display: 'none' }} onChange={this.changeToFileUpLoad} />
                    <ul>
                        <li className='active' onClick={this.clickCertificate}>
                            <i className="fa fa-plus-square"></i>
                            <TrasUploadcertificate />
                            <TrasUploading />

                            <span className="">
                                <i className="fa fa-question-circle color-gurume-red" data-tip={`학생증/졸업증은 프로필에 비공개이며 수업문의를 주신 학부모님으로부터 요청시에만 공유됩니다.`} data-for='tooltipExInfo' data-event='mouseover'></i>
                                <ReactTooltip effect="solid" place="bottom" id='tooltipExInfo' type='light'
                                    globalEventOff='mouseout' >
                                </ReactTooltip>
                            </span>

                        </li>
                        <li className='active' onClick={this.clickMasterButton}>
                            <i className="fa fa-plus-square"></i>
                            석사
                        </li>
                        <li id='phdDom' className='none' onClick={this.clickDoctoralButton}>
                            <i className="fa fa-plus-square"></i>
                            박사
                        </li>
                    </ul>
                </div>
            </div>

        )
    }



    changeToFileUpLoad = (file) => {
        const input = file.target;
        if (input.files.length <= 0) return;

        const chk_file = input.files[0];
        //VALID FORMAT OF A IMAGE
        const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        let chkBool = false;
        for (let i = 0; i < validImageTypes.length; i++) {
            if (validImageTypes[i] === chk_file.type) {
                chkBool = true;
            }
        }

        if (!chkBool) {
            alert('이미지 확장자는 "gif/jpeg/png"만 가능합니다.');
            this.setState({
                curImgState: this.state.imgSetState.FAILING
            });
            return;
        }

        const validFile = chk_file;

        const imgName = validFile.name;
        // const imgName = new Date().getTime();
        const imgLastModified = validFile.lastModified;
        const imgLastModifiedDate = validFile.lastModifiedDate;

        const reader = new FileReader();
        reader.onload = e => {
            const image = new Image();
            image.src = e.target.result;
            // FILE DATE
            // NAME, LAST_MODIFIED, LAST_MODIFIED_DATE
            image.name = imgName;
            image.lastModified = imgLastModified;
            image.lastModifiedDate = imgLastModifiedDate;
            image.onload = imageEvent => {
                this.resize_image(image);
            };

        }
        reader.readAsDataURL(validFile);
    }

    resize_image = image => {
        let canvas = document.createElement("canvas"),
            //   max_size = 1280,
            max_size = 200,
            width = image.width,
            height = image.height;

        if (width > height) {
            if (width > max_size) {
                height *= max_size / width;
                width = max_size;
            }
        } else {
            if (height > max_size) {
                width *= max_size / height;
                height = max_size;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL("image/png");


        this.setState({
            curImgState: this.state.imgSetState.SETTING,
            dataUrl: dataUrl
        }, () => {

            this.clickBox();
            // this.props.getCertDataFromSchoolInputGroup(this.state.dataUrl, false);
        });
    };



    createHtml = () => {
        return (
            <div className='school-input-group-sub-wrapper'>

                {!this.state.needLazyLoading ?
                    /* HIGH SCHOOL */
                    this.createPartA()
                    : ''}
                {!this.state.needLazyLoading ?
                    /* UNDER GRADUATE */
                    this.createPartB()
                    : ''}

                {this.state.needMasterDegree ?
                    this.createPartC()
                    : ''}
                {this.state.needDoctoralDegree ?
                    this.createPartD()
                    : ''}

                {this.createPartE()}
            </div>
        )
    }


    render() {
        return (
            <div className='school-input-group-wrapper'>
                {this.createHtml()}
            </div>
        )
    }
}

SchoolInputGroup.propTypes = SchoolInputGroupPropTypes;
SchoolInputGroup.defaultProps = SchoolInputGroupDefaultProps;

export default SchoolInputGroup;