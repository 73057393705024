import React, { Component } from 'react';
import {withRouter} from 'react-router';
import {
    TrasHome,
    TrasCredit,
    TrasBalance,
    TrasBalanceHistory,
    TrasQuestion,

} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { showNotify, STATE_NOTIFY, moneyFormat, TypeConsume } from '../../extra/cusUtil';

import './credit_balance.css';
import './credit_common.css';

class credit_balance extends Component{


    state = {
        totalUserCoin : '0',
        currentCurrencySimbol : '',
        currentCurrency : '',
        gCoinList : [],

        currencyRate : {
            currencRateRmb : 0.0058,
            currencRateKrw : 1,
            currencRateGbp : 0.00067,
        },
        isAjaxDone : false,
    }



    componentDidMount = () => {
        this.doInit();
    }

    doInit = async () => {
        const allUrls = await GetAjaxURL();

        // Login Check
        const isLogin = allUrls.isLogin;
        const isLoginRes = await axios.get(isLogin).catch((err) => { return console.log(err); });
        const isLoginData = isLoginRes.data;

        if(isLoginData.isLogin === 'false'){
            let msg = '로그인 후 이용해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            return;
        }


        const gCoinUrl = allUrls.gcoin.index;
        const gCoinRes = await axios.get(gCoinUrl).catch((err) => { return console.log(err); });
        if(!gCoinRes){
            let msg = '서버에서 에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }
        const gCoinData = gCoinRes.data;

        let tempCoin = gCoinData.total_gcoin.split('.');
        if(tempCoin.length !== 0){
            tempCoin = tempCoin[0];
        }

        const totalUserCoin = tempCoin;
        let currentCurrency = String(gCoinData.preferred_currency);
        const gCoinList = gCoinData.transactions;
        
        currentCurrency = currentCurrency === '1' ? '1' : currentCurrency === '3' ? '3' : '2';
        const currentCurrencySimbol = currentCurrency === '1' ? '￥' : currentCurrency === '3' ? '£' : '₩';

        this.setState({
            ...this.state,
            totalUserCoin : totalUserCoin,
            currentCurrencySimbol : currentCurrencySimbol,
            currentCurrency : currentCurrency,
            gCoinList : gCoinList,
            isAjaxDone : true,
        }, () => {
        })
    }


    setPreference = async (e) => {
        const target = e.target;
        const value = target.value;

        const allUrls = await GetAjaxURL();
        const setPreferenceUrl = allUrls.gcoin.setPreference;
        const formData = new FormData();
        formData.append('preferred_currency', value);

        const setPreferenceRes = await axios.post(setPreferenceUrl, formData, postConfig).catch((err)=>{return console.log(err)});
        const setPreferenceData = setPreferenceRes.data;


        const currentCurrencySimbol = value === '1' ? '￥' : value === '3' ? '£' : '₩';

        if(setPreferenceData.status === 'fail'){
            let msg = '통화 변경에 실패했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }else{
            this.setState({
                ...this.state,
                currentCurrencySimbol : currentCurrencySimbol,
                currentCurrency : String(value),
            }, () => {
                let msg = '유저의 기본 통화 설정이 변경되었습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_INFO);
            })
        }
    }


    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/credit/balance"><TrasCredit/></a></li>
                    <li className='active'><TrasBalance/></li>
                </ol>
            </div>
        )
    }

    clickToMove = () => {
        window.location.href = '/#/credit/withdraw';
    }

    createBalanceHead = () => {

        const totalUserCoin = this.state.totalUserCoin;
        const currentCurrency = this.state.currentCurrency;
        const currencyRate = this.state.currencyRate;

        let totalCoins = totalUserCoin;
        if(currentCurrency === '1'){
            totalCoins = parseFloat(totalCoins * currencyRate.currencRateRmb).toFixed(2);
        }else if(currentCurrency === '2'){
            totalCoins = parseFloat(totalCoins * currencyRate.currencRateKrw).toFixed(2);
        }else if(currentCurrency === '3'){
            totalCoins = parseFloat(totalCoins * currencyRate.currencRateGbp).toFixed(2);
        }

        let tempCoins = totalCoins.split('.');
        if(tempCoins.length !== 0){
            totalCoins = tempCoins[0];
        }


        return(
            <div className='credit-balance-head'>
                <h3 className="blue-font-color"><TrasBalance /></h3>
                <div className='credit-balance-head-won'>
                    <div>
                        {this.state.currentCurrencySimbol}
                        <span className='color-gurume-red'>{' ' + moneyFormat(totalCoins)}</span>
                    </div>
                    <div>
                        <div>
                            <span>
                                <div>선호 통화 : </div>
                                <div>
                                    <select onChange={this.setPreference} value={currentCurrency}>
                                        <option value='1'>RMB</option>
                                        <option value='2'>KRW</option>
                                        <option value='3'>GBP</option>
                                    </select>
                                </div>
                            </span>
                            <span>
                                <div>크레딧 관리 : </div>
                                <div>
                                    <button onClick={this.clickToMove} className='credit-default-btn credit-default-blue-btn'>Withdraw</button>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createBody = () => {
        const gCoinList = this.state.gCoinList;
        const currentCurrency = this.state.currentCurrency;
        const li = [];
        for(let i = 0; i < gCoinList.length; i++){
            const type = String(gCoinList[i].type);
            const tempDate = gCoinList[i].date.date.split('.');
            const gcoin = gCoinList[i].gcoin;
            const name = gCoinList[i].name;
            const picture = gCoinList[i].picture;


            let date = tempDate;
            let sDate = '';
            let sTime = '';
            if(date.length !== 0){
                date = date[0];
                date = date.split(' ');
                if(date.length !== 0){
                    sDate = date[0];
                    sTime = date[1];
                }
            }
            
            const dateSpan = 
                        <span className='credit-bt-bodies-date'>
                            <div>{sDate}</div>
                            <div>{sTime}</div>
                        </span>;

            let textCurrency = 'KRW';
            if(currentCurrency === '1'){
                textCurrency = 'RMB';
            }else if(currentCurrency === '2'){
                textCurrency = 'KRW';
            }else if(currentCurrency === '3'){
                textCurrency = 'GBP';
            }

            let iconSpan = '';
            let typeSpan = '';
            if(type === '3'){
                iconSpan = 
                    <span>
                        <img src={picture} alt='...' onError={function(e){e.target.src = '/images/backuptutorpic.png';}.bind(this)} />
                    </span>;
                typeSpan = 
                    <span>
                        <span>
                            {name}
                        </span>
                    </span>;
            }else if(type === '4'){
                iconSpan = 
                    <span>
                        <i className="fa fa-question-circle fa-2x"></i>
                    </span>;
                typeSpan =
                    <span>
                        <TrasQuestion />
                    </span>
            }else{
                iconSpan = 
                    <span>
                        <i className="fa fa-credit-card fa-2x"></i>
                    </span>;
                typeSpan =
                    <span>
                        <TrasCredit />
                    </span>
            }




            // 리스트는 한화로 통일
            textCurrency = 'KRW';

            li.push(
                <li key={'gCoinList_' + i}>
                    {iconSpan}
                    {typeSpan}
                    <span>{TypeConsume(Number(type))}</span>
                    <span>{moneyFormat(gcoin)} {textCurrency}</span>
                    {dateSpan}
                </li>
            )
        }


        return(
            <div className='credit-default-body'>
                <h3 className="blue-font-color"><TrasBalanceHistory /></h3>
                <div className='credit-default-table'>
                    <div className='credit-bt-header'>
                        <span><i className="fa fa-star fa-1x"></i></span>
                        <span>종류</span>
                        <span>출처</span>
                        <span>금액</span>
                        <span>일자</span>
                    </div>
                    <div className='credit-bt-bodies'>
                        <ul>
                            {li}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return(
            <div className=''>
                <div className='academy-wapper-c credit-default-resize'>
                    {
                        this.state.isAjaxDone ? 
                            this.createBalanceHead()
                        : ''
                    }
                    {this.createBody()}
                </div>
            </div>
        )
    }

    render(){
        return(
            <div className='credit-balance-wapper'>
                {this.createNav()}
                {this.createHtml()}
            </div>
        )
    }

}

export default withRouter(credit_balance);