import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NoImgContens } from '../../../config/AjaxConfig';
import './MainReviews.css';
import {
    TrasViewMoreTutors
} from '../../../Translate/EachTranslateC';


const MainReviewsPropTypes = {
    normalData: PropTypes.array,
}
const MainReviewsDefaultProps = {
    normalData: []
}

class MainReviews extends Component {


    state = {
        normalData: []
    }

    componentDidMount = () => {
        if (this.props) {
            this.setState({
                normalData: this.props.normalData
            });
        }
    }

    moveToThere = (e) => {
        let target = e.target;
        let dataId = target.dataset['id'];
        if (!dataId) dataId = target.parentNode.dataset['id'];
        if (!dataId) dataId = target.parentNode.parentNode.dataset['id'];

        window.location.href = '/#/teachers/' + dataId;
    }

    createBodys = () => {

        const normalData = this.state.normalData;

        const li = [];
        for (let i = 0; i < normalData.length; i++) {
            const id = normalData[i].id;
            const imageUrl = normalData[i].imageUrl;
            const review = normalData[i].review;
            const name = normalData[i].name;
            const study = normalData[i].study;
            const school = normalData[i].school;
            const representativeDegree = normalData[i].representativeDegree;

            let newReview = review;
            if (newReview.length > 100) {
                newReview = newReview.substr(0, 100) + '...';
            }

            li.push(
                <li key={'list_key_' + i} data-id={id} onClick={this.moveToThere}>
                    <div> <img src={imageUrl} alt='' onError={function (e) { e.target.src = NoImgContens; }.bind(this)} /></div>
                    <div className="main-review-tutor-info">
                        <div className="main-review-top"></div>
                        <div className="main-review-bottom">
                            <p> {study} </p>
                            <p> {school + ' | ' + representativeDegree} </p>
                        </div>
                    </div>
                    <div className="main-review-text"> {newReview} </div>
                </li>
            )
        }

        return (
            <div className="main-review-item">
                {li}
            </div>
        )
    }
    render() {
        return (
            <div className='main-review-item-wrapper'>
                {this.createBodys()}
                <div className="main-review-more-btn">
                    <a href="/#/teachers#teacher-list-header" style={{ float: "right" }}>
                        <button className="submit-btn"><TrasViewMoreTutors /></button>
                    </a>
                </div>
            </div>
        )
    }
}

MainReviews.propTypes = MainReviewsPropTypes;
MainReviews.defaultProps = MainReviewsDefaultProps;

export default MainReviews;