import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import { REGEXP } from '../../../config/configAllRoutes';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';

import {
    TrasHome,
    TrasRegister,
    TrasStudent,
    TrasName,
    TrasPleaseenteraname,
    TrasPleaseenterasurname,
    TrasEnglishName,
    TrasPleaseenteranEnglishName,
    TrasEmail,
    TrasPleaseenteranemailaddress,
    TrasPhone,
    TrasPleaseenterphonenumber,
    TrasPassword,
    TrasConfirmPassword,
    TrasPleaseenterapassword,
    TrasThepasswordisnotstrongenough,
    TrasPleaseconfirmthepassword,
    TrasPassworddoesnotmatch,
    TrasSelectgender,
    TrasMale,
    TrasFemale,
    TrasTutorRegistration,
    TrasRegistering,
    TrasStudentRegistration,
    TrasParentRegistration,
    TrasAlreadyhaveanaccount,
    TrasLogin,
    TrasAreyouacademyuser,
    TrasPleasecontactGuruMesupportforacademyregistration,
} from '../../../Translate/EachTranslateC';
import '../../../default.css';
import './userreg.css';
import './inputradio.css';

import axios from '../../../config/axios';
import queryString from 'query-string';

/// Please enter phone number

class userreg extends Component {

    state = {
        lang: 'ko',
        TrasInfo: {    // do translate for placeholders
            firstName: '',
        },
        selectionState: {
            teacher: false,
            student: false,
            parent: false
        },
        formData: {
            first_name: '',
            last_name: '',
            first_name_en: '',
            last_name_en: '',
            email: '',
            phone: '',
            phone2: '',
            is_parent: '',
            password: '',
            password_confirmation: '',
            type: '',
            gender: '1',               // default value
            is_email_verified: '',     // not use anymore 2019.9.22 check from userreg in angular source
            is_kakao_verified: '',     // not use anymore 2019.9.22 check from userreg in angular source
        },
        DISPLAY_STATE: {
            FIRST_NAME: 'FIRST_NAME',
            LAST_NAME: 'LAST_NAME',
            FIRST_NAME_EN: 'FIRST_NAME_EN',
            LAST_NAME_EN: 'LAST_NAME_EN',
            EMAIL: 'EMAIL',
            PHONE: 'PHONE',
            PASSWORD: 'PASSWORD',
            PASSWORD_NOT_SECURE: 'PASSWORD_NOT_SECURE',
            PASSWORD_CONFIRMATION: 'PASSWORD_CONFIRMATION',
            PASSWORD_NOT_THE_SAME: 'PASSWORD_NOT_THE_SAME',
        },
        display_type: {
            isFirstName: false,
            isLastName: false,
            isFirstNameEn: false,
            isLastNameEn: false,
            isEmail: false,
            isPhone: false,
            isPassword: false,
            notSecurePassword: false,
            isPasswordConfirmation: false,
            notSamePassword: false,
        },

        errorMessage: ''
    }




    regTutor = async () => {

        const valid = await this.validationChecker();
        if (!valid) return;

        if (this.state.selectionState.teacher) return;
        if (this.state.selectionState.student) return;
        if (this.state.selectionState.parent) return;


        this.setState({
            ...this.state,
            selectionState: {
                ...this.state.selectionState,
                teacher: true,
                student: false,
                parent: false
            }
        }, () => {
        });


        const allUrls = await GetAjaxURL();
        let registerUrl = allUrls.register;
        const userData = {
            first_name: this.state.formData.first_name,
            last_name: this.state.formData.last_name,
            first_name_en: this.state.formData.first_name_en,
            last_name_en: this.state.formData.last_name_en,
            email: this.state.formData.email,
            phone: this.state.formData.phone,
            phone2: this.state.formData.phone2,
            password: this.state.formData.password,
            password_confirmation: this.state.formData.password_confirmation,
            type: 'tutor',
            lang: this.state.lang,
            gender: this.state.formData.gender,
            is_email_verified: this.state.formData.is_email_verified,
            is_kakao_verified: this.state.formData.is_kakao_verified

        };

        registerUrl += ('?' + queryString.stringify(userData));
        const res = await axios.post(registerUrl, userData, postConfig).catch((err) => { return console.log(err) });
        if (!res) return;
        if (!res.data) return;
        if (res.data.status === "fail") {
            if (res.data.code === 2) {
                if (this.state.lang === 'ko') {
                    this.setState({
                        ...this.state,
                        errorMessage: '이미 다른 계정에서 사용중인 이메일입니다.',
                        selectionState: {
                            ...this.state.selectionState,
                            teacher: false,
                            student: false,
                            parent: false
                        }
                    });
                } else if (this.state.lang === 'en') {
                    this.setState({
                        ...this.state,
                        errorMessage: 'The email address has already been taken',
                        selectionState: {
                            ...this.state.selectionState,
                            teacher: false,
                            student: false,
                            parent: false
                        }
                    });
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: '此邮箱已被注册',
                        selectionState: {
                            ...this.state.selectionState,
                            teacher: false,
                            student: false,
                            parent: false
                        }
                    })
                }
            } else if (res.data.code === 4) {
                this.noValidationDisplayer(this.state.DISPLAY_STATE.PASSWORD_NOT_SECURE);
            }

        }
        this.setState({
            ...this.state,
            selectionState: {
                teacher: false,
                student: false,
                parent: false
            }
        }, () => { });

        if (res.data.status === 'success') {
            window.location.href = '/#/account_activation?email=' + this.state.formData.email;

        }


    }
    regStudent = async () => {

        const valid = await this.validationChecker();
        if (!valid) return;

        if (this.state.selectionState.teacher) return;
        if (this.state.selectionState.student) return;
        if (this.state.selectionState.parent) return;


        this.setState({
            ...this.state,
            selectionState: {
                ...this.state.selectionState,
                teacher: false,
                student: true,
                parent: false
            }
        }, () => {
        });


        const allUrls = await GetAjaxURL();
        let registerUrl = allUrls.register;
        const userData = {
            first_name: this.state.formData.first_name,
            last_name: this.state.formData.last_name,
            first_name_en: this.state.formData.first_name_en,
            last_name_en: this.state.formData.last_name_en,
            email: this.state.formData.email,
            phone: this.state.formData.phone,
            phone2: this.state.formData.phone2,
            is_parent: false,
            password: this.state.formData.password,
            password_confirmation: this.state.formData.password_confirmation,
            type: 'student',
            lang: this.state.lang,
            gender: this.state.formData.gender,
            is_email_verified: this.state.formData.is_email_verified,
            is_kakao_verified: this.state.formData.is_kakao_verified

        };
        registerUrl += ('?' + queryString.stringify(userData));
        const res = await axios.post(registerUrl, userData, postConfig).catch((err) => { return console.log(err) });
        if (!res) return;
        if (!res.data) return;
        if (res.data.status === "fail") {
            if (res.data.code === 2) {
                if (this.state.lang === 'ko') {
                    this.setState({
                        ...this.state,
                        errorMessage: '이미 다른 계정에서 사용중인 이메일입니다.',
                        teacher: false,
                        student: false,
                        parent: false
                    });
                } else if (this.state.lang === 'en') {
                    this.setState({
                        ...this.state,
                        errorMessage: 'The email address has already been taken',
                        teacher: false,
                        student: false,
                        parent: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: '此邮箱已被注册',
                        teacher: false,
                        student: false,
                        parent: false
                    })
                }
            } else if (res.data.code === 4) {
                this.noValidationDisplayer(this.state.DISPLAY_STATE.PASSWORD_NOT_SECURE);
            }

        }
        this.setState({
            ...this.state,
            selectionState: {
                teacher: false,
                student: false,
                parent: false
            }
        }, () => { });

        if (res.data.status === 'success') {
            window.location.href = '/#/account_activation?email=' + this.state.formData.email;

        }


    }
    regParent = async () => {

        const valid = await this.validationChecker();
        if (!valid) return;

        if (this.state.selectionState.teacher) return;
        if (this.state.selectionState.student) return;
        if (this.state.selectionState.parent) return;


        this.setState({
            ...this.state,
            selectionState: {
                ...this.state.selectionState,
                teacher: false,
                student: false,
                parent: true
            }
        }, () => {
        });


        const allUrls = await GetAjaxURL();
        let registerUrl = allUrls.register;
        const userData = {
            first_name: this.state.formData.first_name,
            last_name: this.state.formData.last_name,
            first_name_en: this.state.formData.first_name_en,
            last_name_en: this.state.formData.last_name_en,
            email: this.state.formData.email,
            phone: this.state.formData.phone,
            phone2: this.state.formData.phone2,
            is_parent: true,
            password: this.state.formData.password,
            password_confirmation: this.state.formData.password_confirmation,
            type: 'student',
            lang: this.state.lang,
            gender: this.state.formData.gender,
            is_email_verified: this.state.formData.is_email_verified,
            is_kakao_verified: this.state.formData.is_kakao_verified

        };
        registerUrl += ('?' + queryString.stringify(userData));

        const res = await axios.post(registerUrl, userData, postConfig).catch((err) => { return console.log(err) });

        if (!res) return;
        if (!res.data) return;
        if (res.data.status === "fail") {
            if (res.data.code === 2) {
                if (this.state.lang === 'ko') {
                    this.setState({
                        ...this.state,
                        errorMessage: '이미 다른 계정에서 사용중인 이메일입니다.',
                        teacher: false,
                        student: false,
                        parent: false
                    });
                } else if (this.state.lang === 'en') {
                    this.setState({
                        ...this.state,
                        errorMessage: 'The email address has already been taken',
                        teacher: false,
                        student: false,
                        parent: false
                    });
                } else {
                    this.setState({
                        ...this.state,
                        errorMessage: '此邮箱已被注册',
                        teacher: false,
                        student: false,
                        parent: false
                    })
                }
            } else if (res.data.code === 4) {
                this.noValidationDisplayer(this.state.DISPLAY_STATE.PASSWORD_NOT_SECURE);
            }

        }
        this.setState({
            ...this.state,
            selectionState: {
                teacher: false,
                student: false,
                parent: false
            }
        }, () => { });

        if (res.data.status === 'success') {
            window.location.href = '/#/account_activation?email=' + this.state.formData.email;

        }

    }


    validationChecker = async () => {
        const regex = REGEXP();

        if (!this.state.formData.first_name.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.FIRST_NAME);
        if (this.state.formData.first_name.trim().length > 254) return this.noValidationDisplayer(this.state.DISPLAY_STATE.FIRST_NAME);
        if (regex.englishAndNumber.test(this.state.formData.first_name.trim())) return this.noValidationDisplayer(this.state.DISPLAY_STATE.FIRST_NAME);
        else await this.setState({ ...this.state, formData: { ...this.state.formData, first_name: this.state.formData.first_name.trim() } });

        if (!this.state.formData.last_name.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.LAST_NAME);
        if (this.state.formData.last_name.trim().length > 254) return this.noValidationDisplayer(this.state.DISPLAY_STATE.LAST_NAME);
        if (regex.englishAndNumber.test(this.state.formData.last_name.trim())) return this.noValidationDisplayer(this.state.DISPLAY_STATE.LAST_NAME);
        else await this.setState({ ...this.state, formData: { ...this.state.formData, last_name: this.state.formData.last_name.trim() } });

        if (!this.state.formData.first_name_en.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.FIRST_NAME_EN);
        if (this.state.formData.first_name_en.trim().length > 254) return this.noValidationDisplayer(this.state.DISPLAY_STATE.FIRST_NAME_EN);
        if (!regex.englishAndNumber.test(this.state.formData.first_name_en.trim())) return this.noValidationDisplayer(this.state.DISPLAY_STATE.FIRST_NAME_EN);
        else await this.setState({ ...this.state, formData: { ...this.state.formData, first_name_en: this.state.formData.first_name_en.trim() } });


        if (!this.state.formData.last_name_en.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.LAST_NAME_EN);
        if (this.state.formData.last_name_en.trim().length > 254) return this.noValidationDisplayer(this.state.DISPLAY_STATE.LAST_NAME_EN);
        if (!regex.englishAndNumber.test(this.state.formData.last_name_en.trim())) return this.noValidationDisplayer(this.state.DISPLAY_STATE.LAST_NAME_EN);
        else await this.setState({ ...this.state, formData: { ...this.state.formData, last_name_en: this.state.formData.last_name_en.trim() } });

        // // unique check
        // // EMAIL MAX LENGTH 255
        if (!this.state.formData.email.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.EMAIL);
        if (!this.state.formData.email.trim().length > 254) return this.noValidationDisplayer(this.state.DISPLAY_STATE.EMAIL);
        if (!regex.emailRegExp.test(this.state.formData.email.trim())) return this.noValidationDisplayer(this.state.DISPLAY_STATE.EMAIL);
        else await this.setState({ ...this.state, formData: { ...this.state.formData, email: this.state.formData.email.trim() } });

        // PHOEN MIN LENGTH 6
        if (!this.state.formData.phone.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.PHONE);
        if (this.state.formData.phone.trim().length < 6) return this.noValidationDisplayer(this.state.DISPLAY_STATE.PHONE);
        // CAN BE OTHER COUNTRIES LIKE UK
        // if(!regex.phoneRegExp.test(this.state.formData.phone.trim())) return this.noValidationDisplayer(this.state.DISPLAY_STATE.PHONE);
        // else await this.setState({...this.state, formData : { ...this.state.formData, phone : this.state.formData.phone.trim().replace(/-/g, '') }});

        if (!this.state.formData.password.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.PASSWORD);
        if (this.state.formData.password.trim().length < 6) return this.noValidationDisplayer(this.state.DISPLAY_STATE.PASSWORD_NOT_SECURE);

        const pw = this.state.formData.password.trim();
        if (!this.state.formData.password_confirmation.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.PASSWORD_CONFIRMATION);
        if (pw !== this.state.formData.password_confirmation.trim()) return this.noValidationDisplayer(this.state.DISPLAY_STATE.PASSWORD_NOT_THE_SAME);



        return new Promise((resolve) => {
            resolve(true)
        });


    }

    noValidationDisplayer = (_display_state) => {
        switch (_display_state) {
            case this.state.DISPLAY_STATE.FIRST_NAME:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isFirstName: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.FIRST_NAME_EN:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isFirstNameEn: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.LAST_NAME:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isLastName: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.LAST_NAME_EN:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isLastNameEn: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.EMAIL:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isEmail: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.PHONE:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isPhone: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.PASSWORD:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isPassword: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.PASSWORD_NOT_SECURE:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        notSecurePassword: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.PASSWORD_CONFIRMATION:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isPasswordConfirmation: true
                    }
                })
                break;
            case this.state.DISPLAY_STATE.PASSWORD_NOT_THE_SAME:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        notSamePassword: true
                    }
                })
                break;
            default:
                this.setState({
                    ...this.state,
                    display_type: {
                        ...this.state.display_type,
                        isFirstName: false,
                        isLastName: false,
                        isFirstNameEn: false,
                        isLastNameEn: false,
                        isEmail: false,
                        isPhone: false,
                        isPassword: false,
                        notSecurePassword: false,
                        isPasswordConfirmation: false,
                        notSamePassword: false,
                    }
                })
                break;
        }
    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasRegister /></li>
                </ol>
            </div>
        )
    }

    createPartA = () => {
        return (
            <div className="userreg-part-a">
                <div className='userreg-part-a-sub-wrapper'>
                    <h2 className="blue-font-color userreg-part-a-h2"><TrasRegister /></h2>
                    {this.createPartA_Name()}
                    {this.createPartB_En_Name()}
                    {this.createPartA_Email_Phone()}
                    {this.createPart_Password()}
                    {this.createSexOrGender()}
                    <div className="margin-bottom-10">
                        <p style={{ textAlign: "center", color: "red" }}>{this.state.errorMessage}</p>
                    </div>
                    {this.createButtons()}
                </div>
            </div>
        )
    }


    createPartA_Name = () => {
        return (
            <div className='userreg-part-a-component-a'>
                <div className='userreg-component-a-header'>
                    <p className="font-size-20"><TrasName /></p>
                </div>
                <div className='userreg-input-area'>
                    <div>
                        <input onChange={(e) => {
                            this.setState({
                                ...this.state,
                                formData: {
                                    ...this.state.formData,
                                    first_name: e.target.value
                                }
                            }, () => {
                                this.noValidationDisplayer();
                            });
                        }}
                            value={this.state.formData.first_name}
                            type="text" className="form-control userreg-input" placeholder='이름' />
                        {this.state.display_type.isFirstName ?
                            <span style={{ textAlign: "center", color: "red" }}><TrasPleaseenteraname /></span>
                            : ''
                        }
                    </div>
                    <div>
                        <input onChange={(e) => {
                            this.setState({
                                ...this.state,
                                formData: {
                                    ...this.state.formData,
                                    last_name: e.target.value
                                }
                            }, () => {
                                this.noValidationDisplayer();
                            });
                        }}
                            value={this.state.formData.last_name}
                            type="text" className="form-control userreg-input" placeholder='성' />
                        {this.state.display_type.isLastName ?
                            <span style={{ textAlign: "center", color: "red" }} ><TrasPleaseenterasurname /></span>
                            : ''
                        }
                    </div>
                </div>
            </div>
        )
    }

    createPartB_En_Name = () => {
        return (

            <div className='userreg-part-a-component-a'>
                <div className='userreg-component-a-header'>
                    <p className="font-size-20"><TrasEnglishName /></p>
                </div>
                <div className='userreg-input-area'>
                    <div>
                        <input onChange={(e) => {
                            this.setState({
                                ...this.state,
                                formData: {
                                    ...this.state.formData,
                                    first_name_en: e.target.value
                                }
                            }, () => {
                                this.noValidationDisplayer();
                            });
                        }}
                            value={this.state.formData.first_name_en}
                            type="text" className="form-control userreg-input" placeholder='이름' />
                        {this.state.display_type.isFirstNameEn ?
                            <span style={{ textAlign: "center", color: "red" }}><TrasPleaseenteranEnglishName /></span>
                            : ''
                        }
                    </div>
                    <div>
                        <input onChange={(e) => {
                            this.setState({
                                ...this.state,
                                formData: {
                                    ...this.state.formData,
                                    last_name_en: e.target.value
                                }
                            }, () => {
                                this.noValidationDisplayer();
                            });
                        }}
                            value={this.state.formData.last_name_en}
                            type="text" className="form-control userreg-input" placeholder='성' />
                        {this.state.display_type.isLastNameEn ?
                            <span style={{ textAlign: "center", color: "red" }} ><TrasPleaseenteranEnglishName /></span>
                            : ''
                        }
                    </div>
                </div>
            </div>

        )
    }


    createPartA_Email_Phone = () => {
        return (

            <div className='userreg-part-a-component-a'>
                <div className='userreg-component-a-header-b'>
                    <p className="font-size-20"><TrasEmail /></p>
                </div>
                <div className='userreg-component-a-header-b padding-left-20'>
                    <p className="font-size-20"><TrasPhone /></p>
                </div>
                <div className='userreg-input-area'>
                    <div>
                        <input onChange={(e) => {
                            this.setState({
                                ...this.state,
                                formData: {
                                    ...this.state.formData,
                                    email: e.target.value
                                }
                            }, () => {
                                this.noValidationDisplayer();
                            });
                        }}
                            value={this.state.formData.email}
                            type="email" className="form-control userreg-input" placeholder='이메일' />
                        {this.state.display_type.isEmail ?
                            <span style={{ textAlign: "center", color: "red" }}><TrasPleaseenteranemailaddress /></span>
                            : ''
                        }
                    </div>
                    <div>
                        <input onChange={(e) => {
                            this.setState({
                                ...this.state,
                                formData: {
                                    ...this.state.formData,
                                    phone: e.target.value
                                }
                            }, () => {
                                this.noValidationDisplayer();
                            });
                        }}
                            value={this.state.formData.phone}
                            type="text" className="form-control userreg-input" placeholder='핸드폰' />
                        {this.state.display_type.isPhone ?
                            <span style={{ textAlign: "center", color: "red" }} ><TrasPleaseenterphonenumber /></span>
                            : ''
                        }
                    </div>
                </div>
            </div>
        )
    }


    createPart_Password = () => {
        return (

            <div className='userreg-part-a-component-a'>
                <div className='userreg-component-a-header-b'>
                    <p className="font-size-20"><TrasPassword /></p>
                </div>
                <div className='userreg-component-a-header-b padding-left-20'>
                    <p className="font-size-20"><TrasConfirmPassword /></p>
                </div>
                <div className='userreg-input-area'>
                    <div>
                        <input onChange={(e) => {
                            this.setState({
                                ...this.state,
                                formData: {
                                    ...this.state.formData,
                                    password: e.target.value
                                }
                            }, () => {
                                this.noValidationDisplayer();
                            });
                        }}
                            type="password" className="form-control userreg-input" placeholder='비밀번호' />
                        {this.state.display_type.isPassword ?
                            <span style={{ textAlign: "center", color: "red" }}><TrasPleaseenterapassword /></span>
                            : ''
                        }
                        {this.state.display_type.notSecurePassword ?
                            <span style={{ textAlign: "center", color: "red" }}><TrasThepasswordisnotstrongenough /></span>
                            : ''
                        }
                    </div>
                    <div>
                        <input onChange={(e) => {
                            this.setState({
                                ...this.state,
                                formData: {
                                    ...this.state.formData,
                                    password_confirmation: e.target.value
                                }
                            }, () => {
                                this.noValidationDisplayer();
                            });
                        }}
                            type="password" className="form-control userreg-input" placeholder='비밀번호 확인' />
                        {this.state.display_type.isPasswordConfirmation ?
                            <span style={{ textAlign: "center", color: "red" }} ><TrasPleaseconfirmthepassword /></span>
                            : ''
                        }
                        {this.state.display_type.notSamePassword ?
                            <span style={{ textAlign: "center", color: "red" }} ><TrasPassworddoesnotmatch /></span>
                            : ''
                        }
                    </div>
                </div>
            </div>

        )
    }


    createSexOrGender = () => {
        return (
            <div className='userreg-part-a-component-sex'>
                <div><TrasSelectgender /></div>

                <div className='userreg-flex'>
                    <ul>
                        <li>
                            <label className='container'><input
                                type='radio' defaultChecked="checked" name='sex' value='1'
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        formData: {
                                            ...this.state.formData,
                                            gender: '1'
                                        }
                                    }, () => {
                                        this.noValidationDisplayer();
                                    })
                                }
                                }
                            />
                                <span className="checkmark"></span>
                            </label> <span> <TrasMale /> </span>
                        </li>
                        <li>
                            <label className='container'><input type='radio' name='sex' value='2'
                                onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        formData: {
                                            ...this.state.formData,
                                            gender: '2'
                                        }
                                    }, () => {
                                        this.noValidationDisplayer();
                                    })
                                }
                                }
                            />
                                <span className="checkmark"></span>
                            </label> <span> <TrasFemale /> </span>
                        </li>
                    </ul>
                </div>


            </div>
        )
    }


    // REGISTER BUTTONS
    createButtons = () => {

        return (
            <div className='user-btns-wrapper'>
                <div className="user-btns-teacher">
                    {
                        !this.state.selectionState.teacher ?
                            <button onClick={this.regTutor} className="center-block btn blue-color userreg-tutor-btn">
                                <TrasTutorRegistration />
                            </button>
                            :
                            <button className="center-block btn blue-color userreg-tutor-btn" ><font className="loading">
                                <TrasRegistering />
                            </font>
                            </button>
                    }
                </div>
                <div className="user-btns-student">
                    {
                        !this.state.selectionState.student ?
                            <button onClick={this.regStudent} className="center-block btn blue-color userreg-student-btn">
                                <TrasStudentRegistration />
                            </button>
                            :
                            <button className="center-block btn blue-color userreg-student-btn">
                                <font className="loading">
                                    <TrasRegistering />
                                </font>
                            </button>
                    }
                </div>
                <div className="user-btns-student">
                    {
                        !this.state.selectionState.parent ?
                            <button onClick={this.regParent} className="center-block btn blue-color userreg-student-btn">
                                <TrasParentRegistration />
                            </button>
                            :
                            <button className="center-block btn blue-color userreg-student-btn">
                                <font className="loading">
                                    <TrasRegistering />
                                </font>
                            </button>
                    }
                </div>

                <div className='user-btns-explain'>
                    <div>
                        <TrasAlreadyhaveanaccount />?
                        <a href="/#/login">  <TrasLogin /></a>
                    </div>
                    <div>
                        <span className="" data-tip data-for='tooltipEx' data-event='mouseover'>
                            <p className="gray2-font-color text-center cursor"><TrasAreyouacademyuser /></p>
                            <ReactTooltip effect="solid" place="bottom" id='tooltipEx' type='light'
                                globalEventOff='click' >
                                <TrasPleasecontactGuruMesupportforacademyregistration />
                            </ReactTooltip>
                        </span>
                    </div>
                </div>
            </div>
        )
    }


    createHtml = () => {
        return (
            <div className="row">
                {this.createPartA()}
            </div>
        )
    }

    render() {
        return (
            <div className='default-wrapper userreg-wrapper'>
                {this.createNav()}
                {this.createHtml()}
            </div>
        )
    }
}

export default userreg;
