import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import LocaleSwitcher from './Translate/LocaleSwitcher';
// import Greeter from './Translate/Greeter';

import Header from './components/header/Header';
import Footer from './components/common/Footer';
import TopHeader from './components/header/TopHeader';
import ShowNotification from './components/utils/ShowNotification';
import MainModal from './components/utils/MainModal';
import PrePrepare from './components/extra/PrePrepare';
import RootRoute from './Route/RootRoute';
import './App.css';
import counterpart from 'counterpart';

import * as RootBindingData from './modules/RootBindingData';
import * as AuthService from './modules/AuthService';
import * as UrlService from './modules/UrlService';

import axios from './config/axios';
import jQuery from "jquery";

import { getEnvironment, GetAjaxURL, multipartForm } from './config/AjaxConfig';
import { showNotify, STATE_NOTIFY } from './components/extra/cusUtil';
import { metaClass, applyMetaHeader } from './components/extra/metaController';
import MobileHeader from './components/header/MobileHeader';

function urlObj(_name, _url, _validation, _routeType) {
  this.name = _name;
  this.url = _url;
  this.validation = _validation;
  this.routeType = _routeType;
};


const getURlParser = (slideUrl, get_params) => {

  const UrlParser = new urlObj();

  let parsingUrl = '';

  let isTeacherPage = false;
  let isTeachersRegister = false;
  let isMooc = false;
  let isMoocIndex = false;
  let isMoocShow = false;
  let isMoocNew = false;

  let isBlogs = false;
  let isBlogsIndex = false;
  let isBlogsEdit = false;

  let isMessages = false;
  let isGroupClass = false;

  let isTuitions = false;
  let isTuitionBill = false;
  let isTuitionBillAdmin = false;
  let isTuitionEdit = false;
  let isTuitionCopy = false;
  let isTuitionConfirm = false;

  let isTuitionRequest = false;
  let isTuitionRequestNew = false;
  let isTuitionRequestShow = false;
  let isTuitionRequestEdit = false;

  let isStudents = false;
  let isStudentsShow = false;
  let isStudentsEdit = false;
  let isStudentsRegister = false;

  let isReviews = false;
  let isReviewsNew = false;


  for (let i = 0; i < slideUrl.length; i++) {
    if (slideUrl[i] === 'teachers') {
      isTeacherPage = true;
    }
    if (slideUrl[i] === 'register') {
      isTeachersRegister = true;
    }

    /* original mooc */
    if (slideUrl[i] === 'mooc') {
      isMooc = true;
      isMoocShow = true;
    }
    if (isMooc) {
      if (slideUrl[i] === 'new') {
        isMoocNew = true;
      }
    }
    if (slideUrl[i] === 'index') {
      isMoocIndex = true;
    }


    if (slideUrl[i] === 'blogs') {
      isBlogs = true;
    }
    if (isBlogs) {
      isBlogsIndex = true;

      if (slideUrl[i] === 'edit') {
        isBlogsEdit = true;
      }
    }

    if (slideUrl[i] === 'messages') {
      isMessages = true;
    }
    if (slideUrl[i] === 'group_class') {
      isGroupClass = true;
    }

    if (slideUrl[i] === 'tuitions') {
      isTuitions = true;
    }
    if (isTuitions) {
      if (slideUrl[i] === 'bill') {
        isTuitionBill = true;
      }
      if (slideUrl[i] === 'adminbill') {
        isTuitionBillAdmin = true;
      }
      if (slideUrl[i] === 'edit') {
        isTuitionEdit = true;
      }
      if (slideUrl[i] === 'copy') {
        isTuitionCopy = true;
      }
      if (slideUrl[i] === 'confirm') {
        isTuitionConfirm = true;
      }
    }
    // if (slideUrl[i] === 'tuition_requests' || slideUrl[i] === 'tuition_request' || slideUrl[i] === 'tuition_req') {
    //   isTuitionRequest = true;
    // }
    // if (isTuitionRequest) {
    //   if (slideUrl[i] === 'new') {
    //     isTuitionRequestNew = true;
    //   }
    //   if (slideUrl[i] === 'show') {
    //     isTuitionRequestShow = true;
    //   }
    //   if (slideUrl[i] === 'edit') {
    //     isTuitionRequestEdit = true;
    //   }
    // }
    // if (slideUrl[i] === 'tuition_request_index') {
    //   isTuitionRequest = true;
    // }

    if (slideUrl[i] === 'students') {
      isStudents = true;
    }

    if (isStudents) {
      if (slideUrl[i] === 'register') {
        isStudentsRegister = true;
      }
      if (slideUrl[i] === 'edit') {
        isStudentsEdit = true;
      }
      if (!isNaN(Number(slideUrl[i]))) {
        isStudentsShow = true;
      }
    }

    if (slideUrl[i] === 'reviews') {
      isReviews = true;
    }
    if (isReviews) {
      if (slideUrl[i] === 'new') {
        isReviewsNew = true;
      }
    }


  }

  if (isMessages) {
    for (let i = 0; i < slideUrl.length; i++) {
      if (slideUrl[i]) {
        parsingUrl += `/${slideUrl[i]}`;
      }
    }

    UrlParser.name = 'messagesItem';
    UrlParser.routeType = 'TYPE_B';
    UrlParser.url = parsingUrl;
    UrlParser.validation = true;

  }


  if (isBlogs) {
    for (let i = 0; i < slideUrl.length; i++) {
      if (slideUrl[i]) {
        parsingUrl += `/${slideUrl[i]}`;
      }
    }

    if (isBlogsIndex) {
      UrlParser.name = 'blogindex';
      UrlParser.routeType = 'TYPE_A';
      parsingUrl += get_params;
    }

    if (!isNaN(Number(slideUrl[slideUrl.length - 1]))) {
      UrlParser.name = 'blogshow';
      UrlParser.routeType = 'TYPE_B';
    }

    if (isBlogsEdit) {
      UrlParser.name = 'blogedit';
      UrlParser.routeType = 'TYPE_B';
    }



    UrlParser.url = parsingUrl;
    UrlParser.validation = true;
  }

  /* original mooc */
  if (isMooc) {

    for (let i = 0; i < slideUrl.length; i++) {
      if (slideUrl[i]) {
        parsingUrl += `/${slideUrl[i]}`;
      }
    }

    if (isMoocIndex) {
      UrlParser.name = 'moocindex';
      UrlParser.routeType = 'TYPE_B';
    } else if (isMoocShow) {
      UrlParser.name = 'moocshow';
      if (isMoocNew) {
        UrlParser.name = 'moocedit';
      }
      UrlParser.routeType = 'TYPE_B';
    } else {
      UrlParser.name = 'moocindex';
      UrlParser.routeType = 'TYPE_B';
    }

    UrlParser.url = parsingUrl;
    UrlParser.validation = true;

  }


  if (isTeacherPage) {
    parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}/${slideUrl[3]}`;

    UrlParser.name = 'Editteacherprofile';
    UrlParser.url = parsingUrl;
    UrlParser.validation = true;
    UrlParser.routeType = 'TYPE_B';


    if (isTeachersRegister) {
      if (get_params) {
        parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}${get_params}`;
      } else {
        parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}/${slideUrl[3]}`;
      }

      UrlParser.name = 'Tutorreg';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    }

    // if (slideUrl[2] === undefined && slideUrl[3] === undefined) {
    //   // URL : /teachers 만으로 프로필 보기 페이지 접근이 되어야 함.
    //   UrlParser.name = 'Search';
    //   UrlParser.url = '/teachers#teacher-list-header';
    //   UrlParser.validation = true;
    //   UrlParser.routeType = 'TYPE_A';
    // }
    // if (slideUrl[2] === '' && slideUrl[3] === undefined) {
    //   // URL : /teachers 만으로 프로필 보기 페이지 접근이 되어야 함.
    //   UrlParser.name = 'Search';
    //   UrlParser.url = '/teachers#teacher-list-header';
    //   UrlParser.validation = true;
    //   UrlParser.routeType = 'TYPE_A';
    // }

  }

  if (isGroupClass) {
    parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}/${slideUrl[3]}`;
    UrlParser.name = 'group_class';
    UrlParser.url = parsingUrl;
    UrlParser.validation = true;
    UrlParser.routeType = 'TYPE_B';
  }

  if (isTuitions) {

    if (isTuitionBill) {
      parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}/${slideUrl[3]}`;
      UrlParser.name = 'tuitions_bill';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    }

    if (isTuitionBillAdmin) {
      parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}/${slideUrl[3]}`;
      UrlParser.name = 'tuitions_bill_admin';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    }

    if (isTuitionEdit) {
      parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}/${slideUrl[3]}`;

      UrlParser.name = 'tuitions_bill_edit';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    } else if (isTuitionCopy) {
      parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}/${slideUrl[3]}`;

      UrlParser.name = 'tuitions_copy';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    } else if (isTuitionConfirm) {
      parsingUrl = `/${slideUrl[1]}/${slideUrl[2]}/${slideUrl[3]}`;

      UrlParser.name = 'tuitions_confirm';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    }
  }

  // if (isTuitionRequest) {
  //   for (let i = 0; i < slideUrl.length; i++) {
  //     if (i !== 0) {
  //       parsingUrl += '/' + slideUrl[i];
  //     }
  //   }

  //   let paramChecker = Number(slideUrl[2]);

  //   if (paramChecker) {

  //     if (isTuitionRequestEdit) {
  //       if (parsingUrl.indexOf('tuition_requests') !== -1) return window.location.href = '/#/tuition_request/' + paramChecker + '/edit';
  //       UrlParser.name = 'tuition_request_edit';
  //       UrlParser.url = parsingUrl;
  //       UrlParser.validation = true;
  //       UrlParser.routeType = 'TYPE_B';
  //     } else {
  //       if (
  //         parsingUrl.indexOf('tuition_requests') === -1
  //       ) return window.location.href = '/#/tuition_requests/' + paramChecker;
  //       UrlParser.name = 'tuition_requests_show';
  //       UrlParser.url = parsingUrl;
  //       UrlParser.validation = true;
  //       UrlParser.routeType = 'TYPE_B';
  //     }

  //   } else {
  //     UrlParser.name = 'tuition_request_index';
  //     UrlParser.url = parsingUrl;
  //     UrlParser.validation = true;
  //     UrlParser.routeType = 'TYPE_A';
  //     if (isTuitionRequestNew) {

  //       if (parsingUrl.indexOf('tuition_requests') !== -1) return window.location.href = '/#/tuition_request/new';

  //       UrlParser.name = 'tuition_requests_new';
  //       UrlParser.url = parsingUrl;
  //       UrlParser.validation = true;
  //       UrlParser.routeType = 'TYPE_B';
  //     }

  //   }

  // }

  if (isStudents) {

    for (let i = 0; i < slideUrl.length; i++) {
      if (i !== 0) {
        parsingUrl += '/' + slideUrl[i];
      }
    }

    UrlParser.name = 'editstudentprofile';
    UrlParser.url = parsingUrl;
    UrlParser.validation = true;
    UrlParser.routeType = 'TYPE_B';

    if (isStudentsShow) {
      UrlParser.name = 'stduentProfile';
      UrlParser.url = '/students/show';
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    }

    if (isStudentsRegister) {
      UrlParser.name = 'editstudentprofile';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    }

    if (isStudentsEdit) {
      UrlParser.name = 'editstudentprofile';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    }



  }


  if (isReviews) {
    for (let i = 0; i < slideUrl.length; i++) {
      if (i !== 0) {
        parsingUrl += '/' + slideUrl[i];
      }
    }
    if (isReviewsNew) {
      UrlParser.name = 'reviews_new';
      UrlParser.url = parsingUrl;
      UrlParser.validation = true;
      UrlParser.routeType = 'TYPE_B';
    }
  }


  return new Promise((resolve) => resolve(UrlParser));
}


//////// INFO
/////// [ need : should check login info.]
/////// laravel session can be changed login info. so need to check both sides
/////// ->> can be server cache problems 2020.01.16
/////// also can guess the conflic with test server, real server and light server.

// PAGE TRANSITION TIME
const transition_time = 500;

class App extends Component {

  state = {
    showLang: false,
    localStorageItems: {
      LANG: 'lang'
    },
    stateUpdateChecker: false,
    isLoading: false,
    currentUrlAndComponent: {
      name: '',
      url: '',
      routeType: '',
    },
    routeType: 'TYPE_A',
    ROUTE_TYPES: {
      TYPE_A: 'TYPE_A',
      TYPE_B: 'TYPE_B',
    }
  }

  shouldComponentUpdate = (nextprops, nextstate) => {
    if (nextstate.currentUrlAndComponent.url != "" && nextstate.currentUrlAndComponent.url == this.state.currentUrlAndComponent.url) {
      return false;
    }
    if (nextprops.allUrls.length > 0) {
      return true;
    } else {
      this.props.AuthService.isLoginFromAuthService();
      this.props.UrlService.callAllUrls();    // get urls
      return false;
    }
  }


  componentDidMount = () => {
    this.Eventlistenner();
    counterpart.setLocale('kr');
    // this.setDefaultValues();

  }


  reflectOutSide = (_bool) => {
    // const loginInfo = window.localStorage.removeItem('person');

    if (!_bool) {
      const parentDom = document.getElementById('loginPannel');
      const childrenDomLi = [];
      while (parentDom.firstChild) {

        // Login Info
        if (parentDom.firstChild.id !== 'after_login')
          childrenDomLi.push(parentDom.firstChild);

        parentDom.removeChild(parentDom.firstChild);
      }
      for (let i = 0; i < childrenDomLi.length; i++) {
        parentDom.appendChild(childrenDomLi[i]);
      }

    } else {
      // true
      const parentDom = document.getElementById('loginPannel');
      const childrenDomLi = [];
      while (parentDom.firstChild) {
        // 'EN|中文|한글'
        if (parentDom.firstChild.innerText === 'EN|中文|한글')
          childrenDomLi.push(parentDom.firstChild);

        // Login Info
        if (parentDom.firstChild.id === 'after_login')
          childrenDomLi.push(parentDom.firstChild);

        parentDom.removeChild(parentDom.firstChild);
      }
      for (let i = 0; i < childrenDomLi.length; i++) {
        parentDom.appendChild(childrenDomLi[i]);
      }

      const dom_id = document.getElementById('login_id');
      dom_id.dataset['hKeyNum'] = this.props.authMsg.name;
      dom_id.innerHTML = `HI ${this.props.authMsg.name} ${'&#9662;'}`;

    }


  }


  Eventlistenner = () => {

    window.addEventListener('load', () => {
      const stateReady =
        setInterval(() => {
          if (this.props.allUrls.length > 0) {
            this.setCurrentPath();
            const newNav = document.getElementById('newNav');
            if (newNav) {
              newNav.remove();
            }
            clearInterval(stateReady);
          }
        }, 1000 / 60);
      const loginStateReady =
        setInterval(() => {

          if (!this.props.authMsg) return;

          if (Object.keys(this.props.authMsg).length > 0) {
            if (this.props.authMsg.isLogin === "false") {
              this.reflectOutSide(false);
            } else {
              this.reflectOutSide(true);
            }
            clearInterval(loginStateReady);
          }

        }, 1000 / 60);

    });


    // window hash change
    window.addEventListener("hashchange", (event) => {
      this.props.RootBindingData.doLoading(true);

      const _type = '';
      const _title = '그루미 GuruMe';
      const _url = window.location.href;
      const _description = '해외 명문대 입시 생들을 위한 과외 및 자료 공간입니다.';
      const _imageUrl = '/apple-touch-icon.png';
      const _keywords = 'IB, 에이레벨 A-Level, iGCSE, AP,수학(Maths),생물(Biology),화학(Chemistry),영어(English),만다린/중국어(Mandarin,Chinese),경영(Business),수업(Class),과외(Tuition),특강(Vacation workshop),영국 대입,대학 입시,옥스브리지,옥스퍼드,케임브리지,입시지도,컨설팅,멘토링,자기소개서,입시시험,인터뷰';

      const newMetaClass = new metaClass(_type, _title, _url, _description, _imageUrl, _keywords);
      applyMetaHeader(newMetaClass);

      this.setCurrentPath();
    });
    window.addEventListener("popstate", (event) => {
      // window.location.reload();
      // this.setCurrentPath();
    });

    const goDashBoard = document.getElementById('goDashBoard');
    const goMessageInbox = document.getElementById('goMessageInbox');
    const accountSet = document.getElementById('accountSet');
    const doLogout = document.getElementById('doLogout');
    // const lang_id = document.getElementById('lang_id');

    goDashBoard.addEventListener('click', () => {
      this.moveToDashBoard();
    })
    goMessageInbox.addEventListener('click', () => {
      this.moveToMessageInbox();
    })
    accountSet.addEventListener('click', () => {
      this.moveToAccountSet();
    })
    doLogout.addEventListener('click', () => {
      this.moveToDoLogout();
    })

    // lang_id.addEventListener('click', () => {
    //   this.showLangPannel();
    // });

  }

  react_redirect = (url) => {
    window.history.replaceState({}, "", '/#' + url);
    this.props.location.pathname = url;
    this.setCurrentPath();
  }


  moveToLogin = () => {
    window.location.href = '/#/login';
  }
  moveToRegister = () => {
    window.location.href = '/#/register';
  }


  moveToDashBoard = async () => {

    // const allUrls = await GetAjaxURL();
    // const thisUserUrls = allUrls.apiThisUser;
    // const thisUser = await axios.get(thisUserUrls).catch((err) => { return console.log(err) });
    const thisUser = this.props.authMsg;


    if (!thisUser) {
      showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
      return;
    }

    const userType = thisUser.type;
    if (userType === 'student') {
      return window.location.href = '/#/students/dashboard';
    } else {
      // return window.location = '/#/dashboard';
      // return window.location.href = '/#/dashboard';
      this.react_redirect("/dashboard");
    }

  }
  moveToMessageInbox = () => {
    window.location.href = '/#/messages';
  }
  moveToAccountSet = () => {
    window.location.href = '/#/users/account_setting';
  }
  moveToDoLogout = async () => {

    const allUrls = await GetAjaxURL();
    const url = allUrls.LoginOut;

    const data =
      await axios.get(url).catch((err) => { return console.log(err) });

    window.location.href = '/';

  }

  showLangPannel = () => {
    this.setState({
      ...this.state,
      showLang: true
    })
  }
  hideLangPannel = () => {
    this.setState({
      ...this.state,
      showLang: false
    })
  }




  // THIS FUNCTION SHOULD BE PROTOTYPE
  // THIS WILL BE BETTER BY DEVELPING.
  // IF YOU DON'T KNOW IT, plz ASK TO ME.
  setCurrentPath = async () => {
    const path = this.props.location.pathname;
    const getParams = this.props.location.search;
    // const current_url = path.substring(1, path.length);



    let componentNameAndUrl = '';
    if (window.location.hash.indexOf('teachers#teacher-list-header') !== -1) {
      componentNameAndUrl = new urlObj();
      componentNameAndUrl.name = 'Search';
      componentNameAndUrl.url = '/teachers#teacher-list-header';
      // componentNameAndUrl.url = '/teachers#teacher-list-header';
      componentNameAndUrl.validation = true;
      componentNameAndUrl.routeType = 'TYPE_A';
    } else {
      componentNameAndUrl = await this.findComponentNameFromUrl(path, getParams);
    }

    let UrlParser = new urlObj();
    UrlParser.name = 'error';
    UrlParser.url = '/error';
    UrlParser.validation = false;
    UrlParser.routeType = 'TYPE_A';

    if (!componentNameAndUrl) {
      this.setState({
        currentUrlAndComponent: UrlParser
      }, () => {
        if (this.state.currentUrlAndComponent.routeType === 'TYPE_A') {
          this.dontLoading();
        }
      });
      return;
    }

    if (componentNameAndUrl.validation) {
      this.setState({
        currentUrlAndComponent: componentNameAndUrl
      }, () => {
        if (this.state.currentUrlAndComponent.routeType === 'TYPE_A') {
          this.dontLoading();
        }
      });
    } else {

      this.setState({
        currentUrlAndComponent: UrlParser
      }, () => {
        if (this.state.currentUrlAndComponent.routeType === 'TYPE_A') {
          this.dontLoading();
        }
      });
    }
  }

  dontLoading = () => {
    this.props.RootBindingData.doLoading(false);
  }




  findComponentNameFromUrl = async (_current_url, _get_params) => {
    // const currentUrl = _current_url;
    const allurls = this.props.allUrls;

    const get_params = _get_params;



    let UrlParser = {
      name: '',
      url: '',
      validation: false,
      routeType: this.state.ROUTE_TYPES.TYPE_A
    };

    if (_current_url === '/admin') {
      const http = getEnvironment();
      return window.location.href = http + '/admin';
    }

    const slideUrl = _current_url.split('/');
    UrlParser = await getURlParser(slideUrl, get_params);


    if (UrlParser === null)
      UrlParser = new urlObj();


    if (_current_url === '/') {
      // Main component
      UrlParser.name = 'Main';
      UrlParser.url = '/';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    } else if (_current_url === '/teachers/register') {
      UrlParser.name = 'Tutorreg';
      UrlParser.url = '/teachers/register';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    } else if (_current_url === '/students/dashboard') {
      UrlParser.name = 'sdashboard';
      UrlParser.url = '/students/dashboard';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_A;
    }
    else if (_current_url === '/messages') {
      UrlParser.name = 'messages';
      UrlParser.url = '/messages';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_A;
    } else if (_current_url === '/messages/630') {
      UrlParser.name = 'messagesitem';
      UrlParser.url = '/messages/630';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    } else if (_current_url === '/about_gurume/career') {
      // for temp
      // not complete
      UrlParser.name = 'career';
      UrlParser.url = '/about_gurume/career';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_A;
    }
    else if (_current_url === '/dashboard') {
      UrlParser.name = 'tdashboard';
      UrlParser.url = '/dashboard';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    }
    else if (_current_url === '/ot') {
      UrlParser.name = 'ot';
      UrlParser.url = '/ot';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    }
    else if (_current_url === '/academy/recruit') {
      UrlParser.name = 'recruit';
      UrlParser.url = '/academy/recruit';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    } else if (_current_url === '/online_tuition/touchpad') {
      UrlParser.name = 'online_tuition_touchpad';
      UrlParser.url = '/online_tuition/touchpad';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    } else if (_current_url === '/demo_class_video') {
      UrlParser.name = 'demo_class_video';
      UrlParser.url = '/demo_class_video';
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    } else {

      for (let i = 0; i < allurls.length; i++) {
        const eachUrl = allurls[i].component_path;
        if (allurls[i].component_name === 'tuitions_bill') continue;

        if (eachUrl === _current_url) {
          UrlParser.name = allurls[i].component_name;
          UrlParser.url = allurls[i].component_path;
          UrlParser.validation = true;
          UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_A;
        }
      }
    }

    // for test url
    if (_current_url === '/test') {
      UrlParser.name = 'InfinityScroll';
      UrlParser.url = _current_url;
      UrlParser.validation = true;
      UrlParser.routeType = this.state.ROUTE_TYPES.TYPE_B;
    }

    return new Promise((resolve) => {
      resolve(UrlParser);
    })


  }



  setDefaultValues = async () => {
    counterpart.setLocale('kr');

    const allUrls = await GetAjaxURL();
    const isLoginUrls = allUrls.isLogin;
    const isLoginRes = await axios.get(isLoginUrls).catch((err) => { return console.log(err) });


    if (!isLoginRes) {
      showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
      return;
    }
    if (!isLoginRes.data) {
      showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
      return;
    }
    if (!isLoginRes.data.isLogin) {
      showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
      return;
    }

    if (isLoginRes.data.isLogin === 'true') {

      const unReadMsgUrls = allUrls.unreadMessageCount;
      const unReadMsgRes = await axios.get(unReadMsgUrls).catch((err) => { return console.log(err); });

      if (!unReadMsgRes) {
        showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
        return;
      }
      if (!unReadMsgRes.data) {
        showNotify('서버에서 데이터를 받아오지 못했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
        return;
      }

    }


  }


  setRootBindingLang = (_LangType) => {
    const LangType = _LangType;
    this.props.RootBindingData.setCurrentLanguage(LangType);
  }
  render() {
    const loadingGurumeBack = {
      backgroundColor: '#fff',
      position: 'fixed !important',
      width: '100%',
      height: '100%',
      zIndex: '999',
    }
    const loadingGurumeFont = {
      position: 'absolute',
      fontWeight: '500',
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'center',
    }
    const loadingGurumeWhite = {
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      zIndex: '9999999'
    }
    return (
      <div className="App" style={{ minHeight: '600px' }}>
        <ShowNotification />
        <TopHeader />
        <Header /><MobileHeader />
        <RootRoute
          currentUrlAndComponent={this.state.currentUrlAndComponent}
        />
        <PrePrepare />
        {/* For Translate */}
        <LocaleSwitcher
          showLangPannel={this.showLangPannel}
          hideLangPannel={this.hideLangPannel}
          showLang={this.state.showLang}
          callback={this.setRootBindingLang}
        />
        <Footer />

      </div>
    );
  }
  /* do not open modal for one day */
  showModal() {
    let self;
    return {
      init: function () {
        self = this;

        // popup close
        $('.modal-close-btn').on('click', function () {
          if ($('.do-not-modal').is(':checked')) {
            self.setCookie('todayCookie', 'done', 1);
          }
          $('.main-modal').slideUp();
        });
        // Do not open today.
        self.getCookie();
      },
      setCookie(name, value, expiredays) {
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate() + expiredays);
        document.cookie = name + '=' + escape(value) + '; path=/; expires=' + todayDate.toGMTString() + ';'
      },
      getCookie() {
        let cookiedata = document.cookie;
        if (cookiedata.indexOf('todayCookie=done') < 0) {
          $('.main-modal').show();
        } else {
          $('.main-modal').hide();
        }
      }
    }
  }
}


/* contact box button */
const $ = window.jQuery = jQuery;

let contactButton = $('.help-contact');
let contactBox = $('.contact-box-wrapper');

contactButton.on("click", function () {
  contactBox.toggle('slow');
})


let goToTopButton = $('.help-go-to-top');
goToTopButton.on("click", function () {
  $("html, body").animate({ scrollTop: 0 }, "slow");
})

let mapStateToProps = (state) => {
  return {
    isAuthkey: state.AuthService.isAuthkey,
    authMsg: state.AuthService.authMsg,
    userMessage: state.AuthService.userMessage,
    needRedirect: state.AuthService.needRedirect,

    tutorProfile: state.RootBindingData.tutorProfile,
    R_isLoading: state.RootBindingData.R_isLoading,
    lang: state.RootBindingData.lang,
    allUrls: state.UrlService.allUrls
  };
}

let mapDispatchToProps = (dispatch) => {
  return {
    RootBindingData: bindActionCreators(RootBindingData, dispatch),
    AuthService: bindActionCreators(AuthService, dispatch),
    UrlService: bindActionCreators(UrlService, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
