import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasGroupClass
} from '../../../Translate/EachTranslateC';

// css
import './groupclass.css';

class GroupClassShow extends Component {
    state = {
        lang: window.localStorage.lang || 'ko'
    }

    componentDidMount = async () => {
    }

    createBreadcrumb = () => {
        return (
            <div className="container">
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/group_class"><TrasGroupClass /></a></li>
                    <li className="active">Logistics</li>
                </ol>
            </div>
        )
    }

    createHeaderBanner = () => {
        return (
            <div className="homepage-mooc">
                <div className="container">
                    <div className="homepage-mooc-box">
                        <div className="row">
                            <div className="col-md-12">
                                <h1><TrasGroupClass /></h1>
                                <h4>Plan, Organise, Sell your own group class</h4>
                                <h4>Students pays less. Tutors earn more.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createContentHtml = () => {
        return (
            <div className="container margin-top-20 margin-bottom-20">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="blue-text">When</h3>
                        <p>*Vacation*</p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>Summer (8 weeks)</td>
                                    <td>Winter (3 weeks)</td>
                                    <td>Easter (3 weeks)</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Jun, Jul, Aug</td>
                                    <td>Dec, Jan</td>
                                    <td>Mar, Apr</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Many students have a very tight vacation class schedule with lots of subjects. In order to prevent any lesson timing conflict, a subject-specific timeslot based schedule will be administered by GuruMe, example timetable.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <h3 className="blue-text">Tuition Fee</h3>
                        <p>Student number specific</p>
                        <p>For example, if your 1:1 hourly rate is 6만</p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>Student No.</td>
                                    <td>Tutor Earns</td>
                                    <td>GuruMe Earns</td>
                                    <td>Each Student Pays</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>3</td>
                                    <td>90,000</td>
                                    <td>30,000</td>
                                    <td>40,000</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>10,000</td>
                                    <td>40,000</td>
                                    <td>35,000</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>10,000</td>
                                    <td>40,000</td>
                                    <td>35,000</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>*Each student in the class pays 10,000/hr</p>
                        <p>*Pricing plan is submitted by the tutor and confirmed with GuruMe.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="blue-text">Classroom</h3>
                        <p></p>
                        <p>Gangnam/Apgujeong study center</p>
                        <p>Booking by GuruMe</p>
                        <p>Classroom fee to be paid by each user including tutor/students (1/n)</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <h3 className="blue-text">Terms</h3>
                        <p>*Student drop out*</p>
                        <p>수업 불만족 이유로 중도하차일 경우 남은 수업에 대한 수업료는 전액 환불됩니다. 때문에 선생님께서 받으시는 수업료 금액이 줄어들 수 있습니다. 수업 불만족 합리성은 그루미가 선생님, 학생 측 이야기를 들어본 후 판단합니다.</p>
                        <p>피치 못할 사정을 포함한 수업 불만족 외 이유로 중도하차일 경우 부분 환불이 됩니다. 반크기에 따라 수업료가 정해진 만큼 n-1 학생수 수업료 차액이 공제된 금액이 환불됩니다. (예, 4명 수업료 (시급 3.5만) 3명 수업료 (시급 4만) 일 경우 시급 0.5만 공제) 때문에 수업료 금액이 줄어들지 않습니다.</p>

                        <p></p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>*Lateness*</td>
                                    <td>Student</td>
                                    <td>Tutor</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Less than 10 minutes</td>
                                    <td>May kindly ask tutor for extra reading materials or ask for 5 minutes after the class or 5 minutes before the class</td>
                                    <td>Corresponding extra time needs to be made up at the end or beginning of next lesson</td>
                                </tr>
                                <tr>
                                    <td>More than 10 minutes</td>
                                    <td>""</td>
                                    <td>TBC</td>
                                </tr>
                            </tbody>
                        </table>

                        <p></p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>*Absence*</td>
                                    <td>Student</td>
                                    <td>Tutor</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Successful rescheduling (+ 3 working day notice)</td>
                                    <td>May kindly ask tutor for extra reading materials or ask for 5 minutes after the class or 5 minutes before the class</td>
                                    <td>Next lesson conducted free of charge</td>
                                </tr>
                                <tr>
                                    <td>Unsuccessful rescheduling (+ Less than 3 working day notice)</td>
                                    <td>Paid make-up class (30 mins) before or after next class</td>
                                    <td>Next 2 lesson conducted free of charge</td>
                                </tr>
                                <tr>
                                    <td>No notice</td>
                                    <td>No make-up session</td>
                                    <td>Next 3 lessons conducted free of charge</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Above strict lateness/absence rules for any reason are applied in order to ensure group class schedule runs smoothly.</p>

                        <p>*Rescheduling*</p>
                        <p>1. Rescheduling is led by the requesting party</p>
                        <p>2. Rescheduling can be requested once during the course</p>
                        <p>3. If any of the classroom students objects to rescheduling, rescheduling is regarded as unsuccessful</p>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <h3 className="blue-text">Procedure</h3>
                        <p></p>
                        <p>- Class consulting/ enquiry may be directly dealt by tutor</p>
                        <p>- Consult enquiries from students/parents</p>
                        <p>- Invoice is submitted/progress report written for each student</p>
                    </div>
                </div>
            </div>
        )
    }
    createEmailusHtml = () => {
        return (
            <div class="email-us">
                <div class="email-us-box">
                    <div class="email-us-content">
                        <h3>For participation enquiry, please email support@gurume.kr</h3>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <div className="group-class-log-container">
                {this.createBreadcrumb()}
                {this.createHeaderBanner()}
                {this.createContentHtml()}
                {this.createEmailusHtml()}
            </div>
        )
    }
}

export default withRouter(GroupClassShow);
