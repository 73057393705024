import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasNew,
    TrasEdit,
    TrasTutor,
    TrasLecture,
    TrasLectureCategory,
    TrasTopic,
    TrasPreview,
    TrasPosting,
    TrasSubTopic,
    TrasAdd,
    TrasVideoLink,
    TrasAddMoreSubTopic,
    TrasPost,
    TrasKeyword
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig, NoImgContens } from '../../../config/AjaxConfig';

import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';
import { isAdmin } from '../../extra/utils';
import ImageUploader from '../../utils/ImageUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import './moocedit.css';


function MoocObject(_index, _chapter, _subtopic, _youku, _youtube, _youtube2, _quiz, _keywords) {
    this.index = _index;
    this.chapter = _chapter;
    this.subtopic = _subtopic;
    this.youku = _youku;
    this.youtube = _youtube;
    this.youtube2 = _youtube2;
    this.quiz = _quiz;
    this.keywords = _keywords;
}
let dropArea = document.createElement('div')
dropArea.id = 'drop-area'

class Moocnew_edit extends Component {
    state = {
        currentMoocId: 0,
        isEdit: true,
        r_isAdmin: false,
        isSubscribed: false,
        userInfo: {
            userId: '',
            full_name: '',
            picture: '',
            totalTutorList: [],
            undergraduate_school: '',
            u__degree_1: '',
        },
        categories: [],

        paramState: {
            mooc_subject_id: '',
            tutor_id: '',
            topic: '',
            topic2: '',
            sub_topic: [],
            picUrl: '',
            fee: 0,
            note_doc_url: '',
            sampleVideo1: '', //sample video url
            sampleVideo2: '', //sample video url
            question_doc_url: '',
            tutor_full_name: '',
            tutor_pic: '',
            total_mooc_time: "",
            subscribePeriod: 0,
        },
        discountedFee: 0,
        discountRate: 0,
        is_discount: false,

        defaultValues: {
            undergraduate_school: '학위가 선택되어 있지 않습니다.',
            u__degree_1: '전공과목이 선택되어 있지 않습니다.',
        },
        isAjaxDone: false,
        isSubmit: false,
        subTopicList: [
            {
                index: 1,
                subtopic: '',
                youku: '',
                youtube: '',
                youtube2: '',
                quiz: '',
                keywords: [],
            }
        ],
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.location.reload();
        }
        return true;
    }

    componentDidMount = () => {
        const moocid = this.props.match.params.moocid;
        // do login check
        let isEdit = false;
        if (moocid) {
            isEdit = true;
        }
        this.initNew(isEdit, moocid);
    }

    initEdit = async (currentMoocId) => {

        const moocid = currentMoocId;
        const apiurls = await GetAjaxURL();
        const url = apiurls.mooc.index;
        const res = await axios.get(url + '/' + moocid).catch((err) => { return err; })

        const moocRes = res.data;

        return moocRes;
    }

    initNew = async (_isEdit, moocid) => {

        const allUrls = await GetAjaxURL();
        const thisUserUrl = allUrls.apiThisUser;
        const res = await axios.get(thisUserUrl).catch((err) => { return console.log(err); });

        const normalMoocList = allUrls.mooc.index;
        const normalMoocListRes = await axios.get(normalMoocList).catch((err) => { return err; });
        const wholeMoocList = allUrls.mooc.subjects;
        const wholeMoocListRes = await axios.get(wholeMoocList).catch((err) => { return err; });

        if (res.data.status === 'fail') {
            let msg = '로그인 후에 이용해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/login';
        }

        if (!wholeMoocListRes) return console.log("Can not bring mooc topic data");


        const isEdit = _isEdit;
        const currentMoocId = moocid;
        const r_isAdmin = isAdmin(res.data.user);


        // 선생님 확인 및 어드민 유저가 아닐 시에 리디렉션.
        if (res.data.user.type !== 'tutor') {
            let msg = '선생님 회원만 이용 가능합니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/mooc';
        }

        // 일반 선생님 회원 - 공통
        const userProfile = res.data.profile;
        const userInfo = res.data.user;
        const userId = userInfo.id;
        const full_name = userInfo.last_name + userInfo.first_name;
        const picture = userProfile.picture;

        let undergraduate_school = this.state.defaultValues.undergraduate_school;
        let u__degree_1 = this.state.defaultValues.u__degree_1;
        if (userProfile.hasOwnProperty('undergraduate_school') && userProfile.hasOwnProperty('u__degree_1')) {
            undergraduate_school = userProfile.undergraduate_school.university_name;
            u__degree_1 = userProfile.u__degree_1.subject;
        }


        let totalTutorList = [];
        // 관리자 회원
        if (r_isAdmin) {
            const tutorsUrl = allUrls.apiTutors;
            const res = await axios.get(tutorsUrl).catch((err) => { return err; })
            totalTutorList = res.data;
        } else {
            totalTutorList.push({
                id: userId, name: full_name
            })
        }

        //It's for edit
        const editMoocList = normalMoocListRes.data;
        const editMoocs = [];
        for (let i = 0; i < editMoocList.length; i++) {
            editMoocs.push({ id: editMoocList[i].id, topic: editMoocList[i].topic, level: editMoocList[i].level });
        }

        //It's for new
        const newMoocList = wholeMoocListRes.data;
        const newMoocs = [];
        for (let i = 0; i < newMoocList.length; i++) {
            newMoocs.push({ id: newMoocList[i].id, topic: newMoocList[i].topic, level: newMoocList[i].level });
        }

        let moocRes = {};
        let mooc_subject_id = '';
        let tutor_id = '';
        let topic = '';
        let sub_topic = [];
        let picUrl = '';
        let fee = 0;
        let note_doc_url = '';
        let question_doc_url = '';
        let tutor_full_name = "";
        let tutor_pic = ""
        let isSubscribed = false

        if (isEdit) {
            // edit
            moocRes = await this.initEdit(currentMoocId);

            if (moocRes.status) {
                // status가 있으면 에러 페이지
                let msg = '해당 페이지가 없습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return window.history.back();
            }

            mooc_subject_id = moocRes.mooc_subject_id;
            tutor_id = moocRes.tutor_id;
            picUrl = moocRes.poster;
            tutor_full_name = moocRes.tutor_full_name;
            tutor_pic = moocRes.tutor_pic;
            fee = moocRes.fee;
            note_doc_url = moocRes.note_doc_url || '';
            question_doc_url = moocRes.question_doc_url || '';
            isSubscribed = moocRes.is_subscribed
            sub_topic = moocRes.moocs;

            if (!r_isAdmin) {
                if (String(userId) !== String(tutor_id)) {
                    let msg = '해당 페이지에 대한 권한이 없습니다.';
                    showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                    return window.history.back();
                }
            }

            const newSubTopicList = [];
            for (let i = 0; i < sub_topic.length; i++) {
                const moocObj = new MoocObject(sub_topic[i].id, sub_topic[i].chapter, sub_topic[i].sub_topic, sub_topic[i].youku, sub_topic[i].youtube, sub_topic[i].youtube2, sub_topic[i].quiz, [sub_topic[i].keywords]);
                const ObjKey = Object.keys(moocObj);
                let newMooc = {};
                for (let k = 0; k < ObjKey.length; k++) {
                    if (ObjKey[k] === 'keywords') {
                        const strKeywords = moocObj[ObjKey[k]][0];
                        if (String(strKeywords) === '0') {
                            newMooc[ObjKey[k]] = [''];
                        } else {
                            newMooc[ObjKey[k]] = strKeywords.split('$');
                        }
                    } else {
                        newMooc[ObjKey[k]] = moocObj[ObjKey[k]];
                    }
                }
                newSubTopicList.push(newMooc);
            }

            // newSubTopicList.sort((a, b) => {
            //     return a.index - b.index;
            // });

            let discount_rate = moocRes.fee_discount;
            if (discount_rate > 0) {
                const $ = window.jQuery;
                $("#check-discount").prop("checked", true);
                this.checkDiscount({ "target": { "checked": true } });
            }

            this.setState({
                ...this.state,
                isEdit: isEdit,
                currentMoocId: currentMoocId,
                r_isAdmin: r_isAdmin,
                userInfo: {
                    ...this.state,
                    userId: userId,
                    full_name: full_name,
                    picture: picture,
                    totalTutorList: totalTutorList,
                    undergraduate_school: undergraduate_school,
                    u__degree_1: u__degree_1,
                },
                paramState: {
                    ...this.state.paramState,
                    mooc_subject_id: mooc_subject_id,
                    tutor_id: tutor_id,
                    topic: moocRes.topic,
                    topic2: moocRes.topic2,
                    sub_topic: sub_topic,
                    picUrl: picUrl,
                    fee: fee,
                    note_doc_url: note_doc_url,
                    question_doc_url: question_doc_url,
                    tutor_full_name: tutor_full_name,
                    tutor_pic: tutor_pic,
                    sampleVideo1: moocRes.sampleVideo1,
                    sampleVideo2: moocRes.sampleVideo2,
                    subscribePeriod: moocRes.subscription_days,
                },
                subTopicList: newSubTopicList,
                setSubTopicList: newSubTopicList,
                categories: editMoocs,
                isAjaxDone: true,
                discountRate: discount_rate,
                discountedFee: fee - Math.round(fee * (discount_rate / 100)),
                is_discount: discount_rate == 0,
                isSubscribed: isSubscribed
            }, () => {
                console.log("Edit 페이지입니다.")
                this.props.RootBindingData.doLoading(false);
            });
        } else {
            // new
            this.setState({
                ...this.state,
                isEdit: isEdit,
                currentMoocId: currentMoocId,
                r_isAdmin: r_isAdmin,
                userInfo: {
                    ...this.state,
                    userId: userId,
                    full_name: full_name,
                    picture: picture,
                    totalTutorList: totalTutorList,
                    undergraduate_school: undergraduate_school,
                    u__degree_1: u__degree_1,
                },
                paramState: {
                    ...this.state.paramState,
                    tutor_id: totalTutorList[0].id,
                    mooc_subject_id: newMoocs[0].id,
                },
                categories: newMoocs,
                isAjaxDone: true
            }, () => {
                console.log("New 페이지입니다.");
            });
        }
    }

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/mooc"><TrasLecture /></a></li>
                    {
                        !this.state.isEdit ?
                            <li className="active"><TrasNew /></li>
                            :
                            <li className="active"><TrasEdit /></li>
                    }
                </ol>
            </div>
        )
    }



    IMAGE_CallbackFuc = (_dataUrl) => {
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                picUrl: _dataUrl
            }
        }, () => {
            console.log('이미지가 업로드 되었습니다 : ' + this.state.paramState.picUrl);
        })
    }
    IMAGE_CallbackCancel = () => {
        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                picUrl: ''
            }
        })
    }

    setTopic1 = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                topic: value
            }
        }, () => { })
    }
    setTopic2 = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                topic2: value
            }
        }, () => { })
    }

    setFee = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                fee: value
            },
            discountedFee: Math.round(value * (100 - this.state.discountRate) / 100)
        })
    }

    setMoocSubjectId = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                mooc_subject_id: value
            }
        }, () => { })
    }

    setTutorId = (e) => {

        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                tutor_id: value
            }
        }, () => { })
    }
    setSubscribePeriod = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            paramState: {
                ...this.state.paramState,
                subscribePeriod: value
            }
        })
    }
    setDiscountRate = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            discountRate: value,
            discountedFee: this.state.paramState.fee - Math.round(this.state.paramState.fee * (value / 100))
        })
    }
    checkDiscount = (e) => {
        const $ = window.jQuery;
        if (e.target.checked) {
            $("#discount-box").css("display", "block")
        } else {
            $("#discount-box").css("display", "none")
            this.setState({
                ...this.state,
                discountRate: 0,
                discountedFee: this.state.paramState.fee
            })
        }
    }
    createBodyLeft = () => {
        const totalTutorList = this.state.userInfo.totalTutorList;

        const Option_tutorList = [];
        for (let i = 0; i < totalTutorList.length; i++) {
            Option_tutorList.push(
                <option key={'key_' + i} value={totalTutorList[i].id}>{totalTutorList[i].id + ') ' + totalTutorList[i].name}</option>
            )
        }
        const categories = this.state.categories;
        const Option_catalogues = [];
        for (let i = 0; i < categories.length; i++) {
            Option_catalogues.push(
                <option key={'ca_key_' + i} value={categories[i].id}>{categories[i].topic}</option>
            );
        }
        return (
            <div className='mooc-left'>
                {/* tutor */}
                <div className='mooc-form-item'>
                    <h5 className='blue-font-color'><TrasTutor /></h5>
                    <div className='mooc-form-item-tutor'>
                        <select className='form-control' onChange={this.setTutorId} value={String(this.state.paramState.tutor_id)}>
                            {Option_tutorList}
                        </select>
                    </div>
                </div>

                {/* category */}
                <div className='mooc-form-item'>
                    <h5 className='blue-font-color'><TrasLectureCategory /></h5>
                    <div className='mooc-form-item-category'>
                        <div>
                            <select className='form-control' onChange={this.setMoocSubjectId} value={String(this.state.paramState.mooc_subject_id)}>
                                {Option_catalogues}
                            </select>
                        </div>
                    </div>
                </div>
                {/* days of subscription */}
                <div className='mooc-form-item'>
                    <h5 className='blue-font-color'>구독기간</h5>
                    <div className="input-group-append">
                        <input className="form-control" type="number" onChange={this.setSubscribePeriod} value={this.state.paramState.subscribePeriod} />
                    </div>
                </div>

                {/* fee */}
                <div className='mooc-form-item' id="lecture-pay">
                    <h5 className='blue-font-color'>
                        수강료
                    </h5>
                    <div className='mooc-form-item-else'>
                        <div className="input-group-append">
                            <input type="number" name="fee" className="form-control" onChange={this.setFee} value={this.state.paramState.fee} />
                            <div> <span className='input-group-text'>KRW</span> </div>
                        </div>
                        <div className="mooc-discount">
                            <input type="checkbox" name="if-check-discount" id="check-discount" onChange={this.checkDiscount} /> 할인 적용
                            <div id="discount-box">
                                <span>%</span>
                                <input type="number" name="discountRate" onChange={this.setDiscountRate} value={this.state.discountRate} />
                            &nbsp;
                            <span>₩</span>
                                <input type="text" name="discountedFee" value={this.state.discountedFee} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* topic */}
                <div className='mooc-form-item'>
                    <h5 className='blue-font-color'>과목명</h5>
                    <div className='mooc-form-item-else'>
                        <div className="input-group-append">
                            <input type="text" name="form-control" value={this.state.paramState.topic} onChange={this.setTopic1} className="form-control" />
                        </div>
                    </div>
                </div>

                <div className='mooc-form-item'>
                    <h5 className='blue-font-color'>레벨명</h5>
                    <div className='mooc-form-item-else'>
                        <div className="input-group-append">
                            <input type="text" name="form-control" value={this.state.paramState.topic2} onChange={this.setTopic2} className="form-control" />
                        </div>
                    </div>
                </div>

                {this.createBodyLeftInner('note')}
                {this.createBodyLeftInner('sample')}
                {
                    this.state.isAjaxDone ?

                        <React.Fragment>
                            <h5 className="blue-font-color">포스터</h5>
                            <ImageUploader
                                autoLoadingDataUrl={this.state.paramState.picUrl}
                                IMAGE_CallbackFuc={this.IMAGE_CallbackFuc}
                                IMAGE_CallbackCancel={this.IMAGE_CallbackCancel}
                                formType={'mooc'}
                            />
                        </React.Fragment>
                        : '데이터를 불러오고 있습니다.'
                }

                {/* lecture list */}
                {this.createSubTopic()}
                {this.createSubmitBtn()}
            </div>
        )
    }


    setNote = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                note_doc_url: value
            }
        }, () => { })
    }

    setSampleVideo = (e, num) => {
        let target1, target2
        let value1 = this.state.paramState.sampleVideo1;
        let value2 = this.state.paramState.sampleVideo2;
        if (num === 1) {
            target1 = e.target
            value1 = target1.value
        } else {
            target2 = e.target
            value2 = target2.value
        }

        this.setState({
            ...this.state,
            paramState: {
                ...this.state.paramState,
                sampleVideo1: value1,
                sampleVideo2: value2
            }
        }, () => { })
    }

    createBodyLeftInner = (type) => {
        let itemTitle = ''
        let itemExplanation = ''
        let itemInput = ''
        let itemInput2 = ''
        if (type === 'note') {
            itemTitle = '노트 문서 URL'; //'노트 문서 URL - ' + type;
            itemExplanation =
                <p className="mooc-form-item-explanation"><span className="badge badge-info">참고</span>
                    &nbsp; 해당 인강과 관련된 노트를 작성한 Google Docs의 문서 url을 입력해 주세요.
                        <small className='mooc-small'>
                        (e.g. https://docs.google.com/document/d/e/2PACX-1vQz9WnVW5iJkQRUQ8bKDgpapHvyML3ko8ChNYaqZYXO2TGruAlBwaJ875rSCNC57YUCsKi8c6QPHnP-/pub?embedded=true)
                        </small>
                </p>;
            itemInput = <input type="text" name="form-control" onChange={this.setNote} value={this.state.paramState.note_doc_url} className="form-control" />;
        } else if (type === "sample") {
            itemTitle = '샘플 영상 URL' //sample video URL
            itemExplanation =
                <p className="mooc-form-item-explanation">
                    <span className="badge badge-info">참고</span>
                &nbsp;샘플로 보여줄 영상의 url을 입력해 주세요. 최대 2개까지 업로드 가능합니다.
            </p>
            itemInput = <input type="text" name="form-control" onChange={(e) => this.setSampleVideo(e, 1)} value={this.state.paramState.sampleVideo1} className="form-control" />;
            itemInput2 = <input type="text" name="form-control" onChange={(e) => this.setSampleVideo(e, 2)} value={this.state.paramState.sampleVideo2} className="form-control margin-top-10" />;
        }
        return (
            <div>
                <div className='mooc-form-item'>
                    <h5 className='blue-font-color'>{itemTitle}</h5>
                    <div className='mooc-form-item-else'>
                        <div>
                            {itemInput}
                            {itemInput2}
                            <div>{itemExplanation}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createBodyRight = () => {
        const userInfo = this.state.userInfo;
        let userName = userInfo.full_name;
        let picUrl = this.state.paramState.picUrl.replace('https://s3.ap-northeast-2.amazonaws.com/gurume-mooc/', '');
        picUrl = 'https://s3.ap-northeast-2.amazonaws.com/gurume-mooc/' + picUrl;


        const totalTutorList = userInfo.totalTutorList;
        for (let i = 0; i < totalTutorList.length; i++) {
            if (String(this.state.paramState.tutor_id) === String(totalTutorList[i].id)) {
                userName = totalTutorList[i].name;
            }
        }

        return (
            <div className='mooc-right'>
                <div className='moocnew-preview'>
                    <h5 className='blue-font-color'><TrasPreview /></h5>
                    <div className='moocnew-preview-card'>
                        <div className="moocnew-preview-card-top">
                            <h3>{this.state.paramState.topic}</h3>
                            {/* It's for topic2 */}
                            <h3>{this.state.paramState.topic2}</h3>
                            <div className="moocnew-preview-tutor">
                                <div className="moocnew-preview-tutor-name">
                                    <p>{userInfo.full_name} 강사</p>
                                </div>
                                <div className="moocnew-preview-tutor-pic">
                                    <div className="moocnew-preview-tutor-pic-box">
                                        <img src={userInfo.picture} alt='...' onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="moocnew-preview-card-bottom">
                            <div className="moocnew-preview-price">
                                {
                                    this.state.discountRate == 0 ?
                                        <span className="moocnew-preview-original-price">₩{this.state.paramState.fee.toLocaleString('en')}</span> :
                                        <span className="moocnew-preview-original-price is-discount">₩{this.state.paramState.fee.toLocaleString('en')}</span>
                                }
                                {
                                    this.state.discountRate !== 0 ?
                                        <span className="moocnew-preview-now-price">&nbsp;₩{this.state.discountedFee.toLocaleString('en')}</span>
                                        : ''
                                }
                            </div>
                            {
                                this.state.isSubscribed ?
                                    <button className="moocnew-preview-apply-btn">수강중</button>
                                    : <button className="moocnew-preview-apply-btn">상세보기</button>
                            }
                            <p className="moocnew-preview-time">총 <span>{this.state.paramState.sub_topic.length}강</span>/<span>{this.state.paramState.total_mooc_time}시간/</span><span>{this.state.paramState.subscribePeriod}일</span></p>
                        </div>
                    </div>
                </div>

                <div className='mooc-recommedation'>
                    <i>
                        <h5 className="blue-font-color font-size-18">더 좋은 인강을 만드는 방법</h5>
                        <ol className="gray-font-color">
                            <li>이미지 비율은 16:9 를 맞추어 주세요.</li>
                            <li>권장 이미지 사이즈는 900 * 400(px) 입니다.</li>
                            <li>하위 주제에 알맞은 키워드를 입력하시면 노출될 확률이 올라갑니다.</li>
                            <li>내용은 짧고 알차게 구성해주세요.</li>
                        </ol>
                    </i>
                </div>
            </div>
        )
    }

    createBody = () => {
        return (
            <div className="mooc-wrapper">
                <div className="mooc-form">
                    {this.createBodyLeft()}
                    {this.createBodyRight()}
                </div>
            </div>
        )
    }



    addSubTopic = () => {
        const subTopicList = this.state.subTopicList;
        let nextId = 1;
        let currentId = 0;
        for (let i = 0; i < subTopicList.length; i++) {
            if (currentId <= subTopicList[i].index) {
                currentId = subTopicList[i].index;
            }
        }
        nextId = currentId + 1;

        const tempMooc = new MoocObject(nextId, '', '', '', '', '', []);

        const ObjKey = Object.keys(tempMooc);
        let newMooc = {};
        for (let k = 0; k < ObjKey.length; k++) {
            newMooc[ObjKey[k]] = tempMooc[ObjKey[k]];
        }
        this.setState({
            ...this.state,
            subTopicList: [
                ...this.state.subTopicList,
                newMooc,
            ]
        })
    }

    removeSubTopic = (subIdx) => {
        let cMsg = '정말로 삭제하시겠습니까?';
        if (window.confirm(cMsg)) {
            const subTopicList = this.state.subTopicList;
            let count = 0;
            const newSubTopicList = [];

            while (subTopicList.length > 0) {
                const each = subTopicList.shift();
                if (String(each.index) !== String(subIdx)) {
                    newSubTopicList.push(each);
                }
                count++;
                // if (count > 50) break;
            }

            this.setState({
                ...this.state,
                subTopicList: newSubTopicList
            })
        } else {
            return
        }
    }
    /* It's for chapter section  */
    putChapter = (e) => {
        const target = e.target;
        const indexData = target.dataset['index'];
        const value = target.value;

        const subTopicList = this.state.subTopicList;
        for (let i = 0; i < subTopicList.length; i++) {
            const tempObj = subTopicList[i];
            if (String(tempObj.index) === String(indexData)) {
                tempObj.chapter = value;
            }
        }

        this.setState({
            ...this.state,
            subTopicList: subTopicList
        }, () => {
        })
    }
    putSubTitle = (e) => {
        const target = e.target;
        const indexData = target.dataset['index'];
        const value = target.value;

        const subTopicList = this.state.subTopicList;
        for (let i = 0; i < subTopicList.length; i++) {
            const tempObj = subTopicList[i];
            if (String(tempObj.index) === String(indexData)) {
                tempObj.subtopic = value;
            }
        }

        this.setState({
            ...this.state,
            subTopicList: subTopicList
        }, () => {
        })
    }
    putYoutube = (e) => {
        const target = e.target;
        const indexData = target.dataset['index'];
        const value = target.value;

        const subTopicList = this.state.subTopicList;
        for (let i = 0; i < subTopicList.length; i++) {
            const tempObj = subTopicList[i];
            if (String(tempObj.index) === String(indexData)) {
                tempObj.youtube = value;
            }
        }

        this.setState({
            ...this.state,
            subTopicList: subTopicList
        })
    }
    onDragStart = (e) => {
        this.dragged = e.currentTarget
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('text/html', this.dragged)
    }
    onDragEnd = (e) => {
        this.dragged.style.display = "block"
        const dropAreaEl = document.getElementById('drop-area')
        dropAreaEl.remove()

        let data = this.state.subTopicList
        let from = Number(this.dragged.dataset.index)
        let to = Number(this.over.dataset.index)
        if (from > to) {
            to--;
        }
        data.splice(to, 0, data.splice(from, 1)[0])
        this.setState({ subTopicList: data })
    }
    onDragOver = (e) => {
        const $ = window.jQuery
        e.preventDefault()
        this.dragged.style.display = "none"
        if (e.target.className === "drop-area") return
        this.over = e.target

        let moocDndInput = $(".moocnew-dnd-input > #drop-area")
        let moocnewInputs = $(".moocnew-input-wrapper > #drop-area")
        let moocnewButtons = $(".moocnew-button-wrapper > #drop-area")
        e.target.parentNode.insertBefore(dropArea, e.target)
        if (moocnewInputs) {
            moocDndInput.show('fast')
            if (moocnewInputs) {
                moocnewInputs.hide('fast')
            }
            if (moocnewButtons) {
                moocnewButtons.hide('fast')
            }
        }

    }

    createSubTopic = () => {
        const subTopicList = this.state.subTopicList;
        const domSubTopicList = [];

        for (let i = 0; i < subTopicList.length; i++) {
            const listKey = i + 1;
            const subIndex = subTopicList[i].index;
            const subtopic = subTopicList[i].subtopic;
            const youtube = subTopicList[i].youtube;
            const chapter = subTopicList[i].chapter;

            domSubTopicList.push(
                <div className="moocnew-lecture-item" draggable>
                    <div className="moocnew-lecture-dnd">
                        <div className="moocnew-dnd-input"
                            key={listKey}
                            data-index={listKey}
                        >
                            <p>{listKey}</p>
                            <div className="moocnew-input-wrapper">
                                <input type="text" className="form-control" data-index={subIndex} onChange={this.putChapter} value={chapter} />
                                <input type="text" className="form-control" data-index={subIndex} onChange={this.putSubTitle} value={subtopic} />
                                <input type="text" className="form-control" data-index={subIndex} onChange={this.putYoutube} value={youtube} />
                            </div>
                            <div className="moocnew-button-wrapper">
                                <button className='moocnew-cancel-btn' data-index={subIndex} onClick={() => this.removeSubTopic(subIndex)}><FontAwesomeIcon icon={faTimesCircle} /></button>
                                <button className="moocnew-dnd-btn"><FontAwesomeIcon icon={faEllipsisV} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="moocnew-lecture">
                <div className="moocnew-lecture-list">
                    <div className="moocnew-lecture-title">
                        <p>강의</p>
                        {/* Chapter */}
                        <p>챕터</p>
                        <p>제목</p>
                        <p>URL</p>
                    </div>
                    <div className="moocnew-lecture-video">
                        {domSubTopicList}
                    </div>
                    <button className='moocnew-lecture-add-btn' onClick={this.addSubTopic}>
                        강의 추가하기
                    </button>
                </div>
            </div>
        )
    }


    doValidation = async () => {
        let valid = false;


        const paramState = this.state.paramState;

        let msg = '';
        if (paramState.tutor_id === '' || paramState.tutor_id === undefined) {
            msg = '선생님이 선택되지 않았습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({ isSubmit: false })
            return valid;
        }
        if (paramState.mooc_subject_id === '' || paramState.mooc_subject_id === undefined) {
            msg = '강의 카테고리가 선택되지 않았습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({ isSubmit: false })
            return valid;
        }
        if (paramState.subscribePeriod <= 0 || paramState.subscribePeriod === undefined) {
            msg = '구독 기간을 설정해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({ isSubmit: false })
            return valid;
        }
        if (paramState.fee === '' || paramState.fee === undefined) {
            msg = '수강료를 입력해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({ isSubmit: false })
            return valid;
        }
        if (paramState.fee <= 0) {
            msg = '수강료는 0보다 낮을 수 없습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({ isSubmit: false })
            return valid;
        }
        if (paramState.topic === '' || paramState.topic === undefined) {
            msg = '과목명을 입력해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({ isSubmit: false })
            return valid;
        }
        // if (paramState.sampleVideo1 === '' || paramState.sampleVideo1 === undefined) {
        //     msg = '샘플 영상을 하나 이상은 올려주세요.';
        //     showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        //     this.setState({ isSubmit: false })
        //     return valid;
        // }
        if (paramState.picUrl === '' || paramState.picUrl === undefined) {
            msg = '포스터를 올려주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({ isSubmit: false })
            return valid;
        }
        return true;
    }

    clickToSubmit = async () => {
        this.setState({
            isSubmit: true
        })
        const isOkay = await this.doValidation();
        if (!isOkay) return;

        const apiurls = await GetAjaxURL();
        let url = apiurls.mooc.new;

        if (this.state.isEdit) {
            if (!this.state.currentMoocId) {
                let errMsg = '실행 중 오류가 발생했습니다.';
                showNotify(errMsg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return;
            }
            url = apiurls.mooc.edit + '/' + this.state.currentMoocId;
        }
        const paramState = this.state.paramState;
        let postData = {
            mooc_subject_id: Number(paramState.mooc_subject_id),
            tutor_id: paramState.tutor_id,
            topic: paramState.topic,
            topic2: paramState.topic2,
            sub_topic: this.state.subTopicList,
            picUrl: paramState.picUrl.replace('https://s3.ap-northeast-2.amazonaws.com/gurume-mooc/', ''),
            fee: paramState.fee,
            discount: this.state.discountRate,
            subscription_period: paramState.subscribePeriod,
            note_doc_url: paramState.note_doc_url,
            question_doc_url: paramState.question_doc_url,
            sampleVideo1: paramState.sampleVideo1,
            sampleVideo2: paramState.sampleVideo2,
        };
        const res = await axios.post(url, postData, postConfig).catch((err) => { return err; })
        const data = res.data;
        if (data.status === 'success') {
            this.setState({
                isSubmit: false
            })
            showNotify('성공적으로 작성하였습니다.', STATE_NOTIFY.TYPE_POSITIVE);
            const confirmLeavingMessage = ('게시되었습니다. 이동하시겠습니까?');
            if (window.confirm(confirmLeavingMessage)) {
                if (this.state.isEdit) {
                    window.location.href = "/#/mooc/" + this.state.currentMoocId;
                } else {
                    window.location.href = "/#/mooc/" + data.id;
                }
            }
        } else {
            let msg = '서버에 오류가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
    }

    createSubmitBtn = () => {
        return (
            <div className='mooc-submit'>
                {
                    this.state.isSubmit ?
                        <button className="btn submit-btn"><font className="loading"><TrasPosting /></font></button>
                        :
                        <button className="btn submit-btn" onClick={this.clickToSubmit}><TrasPost /></button>
                }
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.createNav()}
                {this.createBody()}
            </div>
        )
    }
}

export default withRouter(Moocnew_edit);