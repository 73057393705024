import React, { Component } from 'react';
import axios from '../../config/axios';
import { GetAjaxURL } from '../../config/AjaxConfig';
import './studyroom.css';

import StudyroomImg_1 from '../../images/GuruMe_Office_Room6_1.jpg';
import StudyroomImg_2 from '../../images/GuruMe_Office_Room5_1.jpg';
import StudyroomImg_3 from '../../images/GuruMe_Office_Room2_1.jpg';
import StudyroomImg_4 from '../../images/GuruMe_Office_Room1_2.jpg';
import { data } from 'jquery';

class StudyroomBanner extends Component {
    render() {
        return (
            <div className="studyroom-banner">
                <div className="studyroom-banner-box">
                    <div className="studyroom-banner-main">
                        <h1 className="font-size-44 boldest-font">그루미 스터디룸</h1>
                        <h4><i className="fa fa-calendar" />&nbsp;이용시간 : 평일/주말 오전 9시~오후 10시</h4>
                        <h4><i className="fa fa-map-marker" />&nbsp;위치 : 동림빌딩 5층 | MK빌딩 5층</h4>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }
}

class StudyroomContent extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="studyroom-content-main">
                    <h4>기존 스터디룸 보다</h4>
                    <p>
                        <i className="fa fa-check" />&nbsp;쾌적한 수업교실<br />
                        <i className="fa fa-check" />&nbsp;수업 전.후 무료 자습실 이용가능<br />
                        <i className="fa fa-check" />&nbsp;조용한 공간, 집중할 수 있는 환경<br />
                        <i className="fa fa-check" />&nbsp;용이한 접근성
                </p>
                </div>
                <div className="studyroom-content-main">
                    <h4>제공</h4>
                    <p>
                        <i className="fa fa-check" />&nbsp;Wi-Fi<br />
                        <i className="fa fa-check" />&nbsp;모니터<br />
                        <i className="fa fa-check" />&nbsp;식.음료
                        </p>
                </div>
            </React.Fragment>
        )
    }
}
class StudyroomPicBox extends Component {
    render() {
        return (
            <div className="studyroom-pic-box">
                <img src={this.props.img} />
            </div>
        )
    }
}
class StudyroomPic extends Component {
    render() {
        const images = [
            {
                img: StudyroomImg_1
            },
            {
                img: StudyroomImg_2
            },
            {
                img: StudyroomImg_3
            },
            {
                img: StudyroomImg_4
            }
        ]
        return (
            <div className="studyroom-pic">
                {
                    images.map(image => <StudyroomPicBox img={image.img} />)
                }
            </div>
        )
    }
}

export default class Studyroom extends Component {
    state = {
        isTutor: false
    }
    componentDidMount = async () => {
        const allUrls = await GetAjaxURL();
        const apiThisUser = allUrls.apiThisUser;
        const res = await axios.get(apiThisUser).catch((err) => { return console.log(err) })
        const data = res.data

        let thisUserType = ''
        if (data.status !== "fail") {
            thisUserType = data.user.type
            if(thisUserType === 'tutor'){
                this.setState({
                    isTutor : true
                })
            }
        }
    }
    goToStudyroom = () => {
        window.open('https://docs.google.com/spreadsheets/d/1KavpPmRX9qwSk9OBbelEGlGBL97RRZI2pTxO0HPORFo/edit?usp=sharing')
    }
    render() {
        return (
            <div className="studyroom-wrapper">
                <StudyroomBanner />
                <div className="studyroom-content">
                    <div className="studyroom-content-box">
                        <p>
                            <i>"오프라인 수업은 학생자택 방문, 스터디룸 (토즈Toz) 그리고 <span className="color-gurume-blue">그루미 스터디룸</span>에서 진행 가능하도록 준비하였습니다."</i>
                        </p>
                        <StudyroomContent />
                    </div>
                </div>
                <StudyroomPic />
                <div className="studyroom-reserve">
                    <div className="studyroom-reserve-box">
                        <div className="studyromm-reserve-notice">
                            <div className="studyroom-reserve-title">
                                <h2>스터디룸 예약 스케줄표</h2>
                                <h4>"스터디룸 예약은 선생님께서 아래와 같은 방법으로 예약합니다. 룸 번호는 선생님께서 예약 후 학생들에게 안내해주세요."</h4>
                            </div>
                            <div className="studyroom-reserve-content">
                                <div className="studyroom-reserve-header">
                                    <h4><i className="fa fa-graduation-cap" />스터디룸 예약방법</h4>
                                </div>
                                <div className="studyroom-reserve-explanation">
                                    <h4>스터디룸 예약</h4>
                                    <p>
                                        <b>* 전화 예약 불가능, Excel Sheet에서만 예약 가능합니다 *</b>
                                        <br />
                                        <b>* PC 사용을 권장 드리고, 스마트폰 이용시, 구글 스프레드 시트 앱을 이용해야만 스터디룸 예약이 가능합니다. *</b>
                                    </p>
                                    <p>
                                        - 그루미 사이트 www.gurume.kr 에서 강사 ID로 로그인 후 사용가능
                            <br />
                                    - 수업 할 강의실과 시간 선택 후 셀 병합, 이름과 ID Number 기입하시기 바랍니다.
                            <br />
                                    - 정시, 30분 시작으로 최소 1시간 예약가능
                            </p>
                                </div>

                                <div className="studyroom-reserve-explanation">
                                    <h4>스터디룸 예약 가능시간</h4>
                                    <p>
                                        - 동림 빌딩(1층 속초대게건물) : 상시
                                <br />
                                    *그루미 학원 강사와 학생만 이용가능
                                <br />
                                    - MK 빌딩(1층 GS25건물): 월~금 7am~11pm & 토 8am~6pm (일요일 이용불가)
                                <br />
                                    *위의 시간에만 건물이 열려있으니 룸 예약시 꼭 참고하시기 바립니다.
                                <br />
                                    - 사용가능한 스터디룸이 없으면 학생과 상의하셔서 선생님께서 다른 스터디룸을 이용하시기 바랍니다.
                                <br />
                                    - 그루미 학생 수업이외 다른 개인지도 학생수업이 연결되어 있으면 그루미 스터디룸에서 5분 거리 길 건너편에 위치한 역삼역 토즈((네이버 지도: http://naver.me/GkseUPtA/ 연락처: 02-3452-0811/ 이용료: 직접 문의) 이용하시기 바랍니다.
                            </p>
                                </div>

                                <div className="studyroom-reserve-explanation">
                                    <h4>스터디룸 취소</h4>
                                    <p>
                                        - 수업 스케줄 변동으로 인하여 스터디룸이 더 이상 필요로 없을 경우, 다른 선생님들이 강의실을 사용할 수 있도록 스터디룸 스케줄표에서 예약을 반드시 취소해 주셔야 합니다.
                            <br />
                                    - 평달에는 스터디룸 이용 취소는 6시간전, 강의실이 많이 필요로 되는 방학특강 기간에는 하루 전을 기준으로 스터디룸 예약표에 취소하셔야 합니다. 만약 취소를 안하셨을 경우 강의실 사용을 안하셨어도 선생님께 이용료가 청구 됩니다.
                            </p>
                                </div>

                                <div className="studyroom-reserve-explanation">
                                    <h4>스터디룸 이용 시 지켜야 할 항목</h4>
                                    <p>
                                        - 절대 금연 및 담배 냄새 철저히 제거 (학생들이 공부하는 장소입니다. 엄격하게 준수해 주셔야 합니다.)
                            <br />
                                    - 퇴실 시, 다음 수업을 위해 물티슈로 칠판과 책상을 깨끗이 닦고 쓰레기 정리정돈 해주세요.
                            <br />
                                    - 스터디룸 사용 중 파손 된 가구 및 전자제품은 선생님께 선생님께 (부분)청구 됩니다.
                            </p>
                                </div>

                                <div className="studyroom-reserve-explanation">
                                    <h4>주차장 이용</h4>
                                    <p>
                                        - 건물 주차장은 상시 1대만 이용 가능하기 때문에 낮에는 학부모님 응대용으로만 이용하고 있습니다.
                            <br />
                                    - 주차장을 이용하셔야 할 경우, 오후 8시 이후로 건물 주변에 있는 외부주차 공간 또는 유로 주차장을 이용해주시기 바랍니다.
                            <br />
                                    - *MK빌딩 주차장은 그루미 스태프와 사전에 이용여부를 확정했을 때에만 이용 가능합니다.
                            </p>
                                </div>

                                {
                                    this.state.isTutor ?
                                        <div className="studyroom-reserve-explanation">
                                            <button onClick={this.goToStudyroom} className="tuition-req-common-btn"><i className="fa fa-pencil" />&nbsp;예약</button>
                                &nbsp;
                                <button className="tuition-req-common-btn">주말이용</button>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="studyroom-reserve-table">
                            <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSocsQgC__A4qrrS0X7Da1LA2yM78IcZmgVjmntIUbKM6qeT8R7f8ZlNfN_N7KP92LN9o9AdRHJngYS/pubhtml?widget=true&amp;headers=false" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}