import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    TrasOnlineLecture,
    TrasNewTrendinStudyAbroad,
    TrasTryNow
} from '../../../Translate/EachTranslateC';
import './MainMooc.css';


class MainMooc extends Component{


    clickToMainMooc = () => {
        window.location.href = '/#/mooc_preview';
    }


    Summons = () => {
        return(
            <div className='main-mooc-wapper'>
                <div className='main-mooc-div'>
                    <h1>
                        <span><TrasOnlineLecture />&nbsp;MOOC</span>
                    </h1>
                    <h2>
                        <TrasNewTrendinStudyAbroad />
                    </h2>
                    <button onClick={this.clickToMainMooc} ><TrasTryNow /></button>
                </div>
            </div>
        )
    }

    render(){
        return this.Summons();
    }
}


export default MainMooc;