import React, { Component } from 'react';
import {
    TrasHome,
} from '../../../Translate/EachTranslateC';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

import './common_vacation_workshop.css';

class Summer_vacation_workshop extends Component {
    componentDidMount() {
        this.props.RootBindingData.doLoading(false);
    }
    createNav = () => {
        return (
            <ol className="breadcrumb">
                <li><a href="/#/"><TrasHome /></a></li>
                <li><a target="_blank" href="http://ibthetop.com">그루미 아카데미</a></li>
                <li>그루미 IB 방학특강</li>
            </ol>
        )
    }


    createAcademy = () => {
        return (
            <div className="vacation-banner">
                <div className="vacation-banner-box">
                    <div className="vacation-banner-main">
                        <h1 className="font-size-54 boldest-font color-white padding-bottom-30">그루미 IB 방학특강</h1>
                        <h3 className="font-size-20 color-white">
                            <p>
                                <FontAwesomeIcon icon={faCalendarAlt} /> 기간 : 6.22~7.17 (1차) / 7.20~8.14 (2차)
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faMapMarkerAlt} /> 위치 : <a href="http://naver.me/5Joku7jy" target="_blank" className="hover-sub">그루미 학원</a> & 유튜브 라이브 참여
                            </p>
                        </h3>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }

    createPartA = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <div className="vacation-content-main">
                        <h4 className="line-height-1-5 thin-font margin-top-50 margin-bottom-50 font-size-18">
                            <i>
                                * 코로나 사태 대비 방안 *<br />
                            현강 & 온라인 병행 및 감염자 출시시 즉시 온라인화<br />
                            본원 내 상시 마스크 착용<br />
                            손 소독제, 항균비누 비치<br />
                            등원 시 체온 검사<br />
                            주2회 청소 시 자체 방역 실시<br />
                            </i>
                        </h4>
                        <h4 className="line-height-1-5 thin-font margin-top-50 margin-bottom-50 font-size-18">
                            <p>
                                IB를 이수한 국내, 외 명문대 출신의 과목별 전문 강사진이 준비한 개념 정리 & 문제 풀이 선행 학습<br />
                                DP1 학년 - 개념설명 > 문제풀이<br />
                                DP2 학년- 문제풀이 > 개념설명
                            </p>
                        </h4>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartB = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">강사진</h2>

                    <div className="vacation-content-main">
                        <h4 className="line-height-1-5 margin-bottom-30 font-size-18">
                            <i>“그루미에서 엄선한 최고의 선생님이자 학원 소속으로서 방학 뿐만이 아니라 학기중에도 연 상시 수업 가능"</i>
                        </h4>
                        <div className="margin-bottom-30">
                            <p>
                                [수학]
                                <a href='/#/teachers/1187' target="_blank"> 방원준 - 워릭 수학</a>
                                <br />

                                [물리]
                                <a href='/#/teachers/3430' target="_blank"> 김정환 - 서울대 물리학</a>
                                <br />

                                [화학]
                                <a href='/#/teachers/70' target="_blank"> 이재훈 - 고려대 환경 화학</a>
                                <br />

                                [생물]
                                <a href='/#/teachers/2142' target="_blank"> 정다솔 - 서울대 간호학</a>
                                <br />

                                [영어]
                                <a href='/#/teachers/1567' target="_blank"> 안재현 - 연세대 영문학</a>
                                <br />

                                [경영]
                                <a href='/#/teachers/559' target="_blank"> 심희영 - 서울대 경영학</a>
                                <br />

                                [심리]
                                <a href='/#/teachers/1708' target="_blank"> 강수민 - 연세대 UIC</a>
                                <br />

                                [스페인어]
                                <a href='/#/teachers/3609' target="_blank"> 장정임 - 남미 UNSLP</a>
                                <br />

                                [ToK]
                                <a href='/#/teachers/3167' target="_blank"> 이승은 - 미국 Northwestern 커뮤니케이션</a>
                            </p>
                        </div>
                        <div className="margin-bottom-30 color-gurume-gray">
                            <i>
                                * 모든 선생님 100% 영어 수업 가능<br />
                                * 경제, 코리안 과목은 문의 시 선생님 확인 가능<br />
                                * 과목별 담당쌤과 Semi Class(유료 1시간 - 테스트&수업&상담포함)를 통해 해당과목 특강 상세 커리큘럼, 학생실력 및 문제점 체크 가능
                            </i>
                        </div>
                    </div>
                </div>
                <hr className="vacation-line" />

                <div className="vacation-content">
                    <div className="vacation-content-box">
                        <h2 className="vacation-content-title">실시간 온라인 수업</h2>
                        <p>
                            Youtube Live Streaming(수업)<br />
                            Zoom(자습)<br />
                            Kakaotalk(관리 소통)
                        </p>
                        <p>
                            <i>수업가능 과목</i><br />
                        수학, 물리, 화학, 생물, 경제<br />
                        영어, 경제, 경영, 심리 (수업 특성 고려한 Zoom화상 수업, 인원수 6명 제한, 녹화본 제공)<br />
                        주말반(문제풀이, 수학 공통부분)<br />
                        수업에 최대한 집중할 수 있는 시간 고려시, 회차당 최대 2과목 추천
                        </p>
                        <p>
                            <i>교재(교재비 별도) 및 Test</i><br />
                            구글 클래스룸으로 학생개별 폴더를 통해 제공<br />
                            매 수업 전 단원별로 PDF, 선생님 제작 Key Note 제공(구글 클래스룸 - 불법 유출금지)<br />
                            수업에 필요한 Past Paper 및 자료(구글 클래스룸)<br />
                            진도 체크 Quiz Form(구글 폼), 숙제(구글 폼), Weekly Test(구글 폼)<br />
                            메이저 출판사 교재 가이드북으로 활용
                        </p>
                        <p>
                            <i>학습 및 자습</i><br />
                        온,오프라인 수업별 통합 그룹쳇(카카오톡, 담당 선생님과 TA, 학생들) 운영<br />
                        수업 별 그룹쳇(카카오톡)에 해당 수업 날 공지된 링크를 통해 스트리밍 접속하여 수업 참여<br />
                        수업 시작 5분 전 Youtube 스트리밍 접속 대화창에서 출석체크<br />
                        유튜브 라이브 채팅창을 통해 수업 시 질의응답 가능<br />
                        실시간 온라인 통합 자습방 운영(Zoom, TA관리)<br />
                        등록 시 학생마다 필수 자습 시간을 정한 후 자습방 이용<br />
                        구글 클래스룸 학생별 폴더를 통하여 학습 상황 밀착 관리<br />
                        매 수업 공유된 링크로 수업 이후 1주일간 복습 가능<br />
                        온라인 면담을 통해 학생 IBDP 학습 플래너 작성으로 개학 후 어려움없이 학교 생활 할 수 있도록 컨설팅 도움
                        </p>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartC = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">학교반</h2>
                    <div className="vacation-content-main display-row">
                        <div className="vacation-class-type">
                            <h4><b>특강</b></h4>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>IB 국제학교에서 공통적으로 진행되는 커리큘럼에 맞춤화 된 '시험용' 선행/복습 과정
                            </p>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>다른 학교 학생들과 함께
                            </p>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>정해진 수업시간 스케줄
                            </p>
                        </div>
                        <div className="vacation-class-type">
                            <h4><b>학교반</b></h4>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>학생 학교 진도에 맞춤화 된 '내신용' 선행/복습 과정
                            </p>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>같은 학교 학생들과 함께
                            </p>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>원하는 수업시간 스케줄
                            </p>
                        </div>
                    </div>
                </div>
                <div className="vacation-content-box">
                    {/* <h2 className="vacation-content-title">학교반 시간표</h2> */}
                    <p>
                        (과목 담당 선생님과 상의 후 일정 및 커리큘럼 조율 가능)<br />
                        특강 스케줄이 맞지 않거나 특정 학교 커리큘럼에 따라 수업 진행을 원하실 경우<br />
                        학업 사정상 학교반 수업이 특강 기간보다 좀 더 일찍 혹은 늦게 시작되어야 할 경우<br />
                        <br />
                        *수학, 물리, 화학, 생물, 경제, 영어 과목 한정<br />
                        *특강과 달리 HL과 SL과정 모두 주 5회 또는 3회 진행 가능<br />
                        *특정 과목은 오전 시간 외에도 화목토 오후와 저녁에 가능 (별도 문의 필요)<br />
                        *학교마다 방학 시작/끝 날짜와 기간이 다르기 때문에 요청에 따라 수업 기간 배정<br />
                        *3~5명 학생들의 공통 가능시간으로 요청시 선착순 배정
                    </p>
                    <div className='vacation-schedule'>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td rowspan="2">과목 - 선생님</td>
                                    <td colspan="2">주 5회 월~금 / 주 3회 월수금 or 화목토</td>
                                </tr>
                                <tr>
                                    <td>9:00 - 10:30am</td>
                                    <td>10:30am - 12:00pm</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>물리 - 김정환</td>
                                    <td>O</td>
                                    <td>X</td>
                                </tr>

                                <tr>
                                    <td>화학 - 이재훈</td>
                                    <td>X</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <td>생물/지리 - 정다솔</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <td>영어 - 안재현</td>
                                    <td>X(월수금 only)</td>
                                    <td>X(월수금 only)</td>
                                </tr>
                                <tr>
                                    <td>경영 - 심희영</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <td>경제/수학 별도 문의</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h5 className="bold-font font-size-14">O 예약가능</h5>
                    <p>*자리가 웹사이트 업데이트 전에 마감 되었을 수 있으니 전화.카톡 문의바랍니다</p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartD = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">특강 시간표</h2>
                    <small className="font-size-16">*매 수업 1시간 25분</small>

                    <div className="vacation-schedule">
                        {/* <p className="visible-xs">(* 시간표를 좌우로 움직여 자세히 확인이 가능합니다.)</p> */}
                        <table className="table table-bordered border-default-1">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>
                                        <b>시간</b>
                                    </td>
                                    <td>
                                        <b>월</b>
                                    </td>
                                    <td>
                                        <b>화</b>
                                    </td>
                                    <td>
                                        <b>수</b>
                                    </td>
                                    <td>
                                        <b>목</b>
                                    </td>
                                    <td>
                                        <b>금</b>
                                    </td>
                                    <td>
                                        <b>토</b>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowspan="3">
                                        학교반/개인 수업
                                </td>
                                </tr>
                                <tr>
                                    <td>09:00-10:25</td>
                                    <td colspan="6">1교시</td>
                                </tr>
                                <tr>
                                    <td>10:30-11:55</td>
                                    <td colspan="6">2교시</td>
                                </tr>
                                <tr>
                                    <td>Break</td>
                                    <td>12:00-12:30</td>
                                    <td colspan="6">Lunch</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">DP2</td>
                                    <td>12:30-13:55</td>
                                    <td colspan="5">1교시</td>
                                    <td rowspan="3">문제<br />풀이</td>
                                </tr>
                                <tr>
                                    <td>14:00-15:25</td>
                                    <td colspan="5">2교시</td>
                                </tr>
                                <tr>
                                    <td>15:30-16:55</td>
                                    <td colspan="5">3교시</td>
                                </tr>
                                <tr>
                                    <td>Break</td>
                                    <td>17:00-17:30</td>
                                    <td colspan="6">Dinner</td>
                                </tr>
                                <tr>
                                    <td rowspan="3">DP1</td>
                                    <td>17:30-18:55</td>
                                    <td colspan="5">1교시</td>
                                    <td rowspan="3">선택<br />수업</td>
                                </tr>
                                <tr>
                                    <td>19:00-20:25</td>
                                    <td colspan="5">2교시</td>
                                </tr>
                                <tr>
                                    <td>20:30-21:55</td>
                                    <td colspan="5">3교시</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            *11월 시험의 학생들은 희망 단원 선택 혹은 담당 선생님의 권장 위주로 DP1 그리고 DP2 과정을 부분적으로 수강합니다.<br />
                            <br />
                            *DP2 ToK 에세이 (개인지도) : 별도 문의<br />
                        </p>
                    </div>
                    <div className="vacation-schedule">
                        <p>
                            전 주요과목 HL 주 5회<br />
                            수학/과학 SL 주 5회<br />
                            기타 과목 SL 주 3회 (기본 월수금 / 요청시 화목토 추가 개설 가능)<br />
                            4주 동안 최대 3과목 수강 (추가 과목은 전.후 특강 회차, 개인수업 또는 학교반 학습)<br />
                            경영, 지리 (DP2) (HL 주 5회/SL 주 3회)<br />
                            영어 통합, 심리, 지리 (DP1) 통합 수업 (주 3회)<br />
                            경영, 심리 2차만 가능<br />
                            DP1과 DP2 과정의 1, 2, 3교시 과목 시간표 차이 有<br />
                            시간 상 약간의 변동이 있을 수 있습니다.
                        </p>

                        <p class="no-margin-bottom">DP1</p>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="30%">1교시</td>
                                    <td>
                                        Maths AA HL (1반), Physics HL, Chem SL, Psychology, Geography
                                    </td>
                                </tr>
                                <tr>
                                    <td>2교시</td>
                                    <td>
                                        Maths AA HL (2반), Physics SL, Chem HL (1반), Bio SL, Econ SL, Business, English A (1반)
                                    </td>
                                </tr>
                                <tr>
                                    <td>3교시</td>
                                    <td>
                                        Maths AA SL, Chem HL (2반), Bio HL, Econ HL, English A (2반), Comp Sci HL,
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            *DP1 수학은 Core 위주로 나가기 때문에 AA 또는 AI 차이 없이 통합 진행
                        </p>

                        <p class="no-margin-bottom margin-top-30">DP2</p>
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="30%">1교시</td>
                                    <td>
                                        Maths AA HL (1반), Physics HL, Chem SL, Psychology, Geography
                                </td>
                                </tr>
                                <tr>
                                    <td>2교시</td>
                                    <td>
                                        Maths AA HL (2반), Physics SL, Chem HL (1반), Bio SL, Econ SL, Business, English A (1반)                                </td>
                                </tr>
                                <tr>
                                    <td>3교시</td>
                                    <td>
                                        Chem HL (2반), Bio HL, Econ HL, English A (2반), Comp Sci HL                              </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartE = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">

                </div>

            </div>
        )
    }


    createPartF = () => {
        return (
            <div>
                <div className="vacation-content">
                    <div className="vacation-content-box">
                        <h2 className="vacation-content-title">특강 커리큘럼</h2>
                        <p>
                            DP1 특강은 남은 1학년 과정의 75~100% 내용 선행 및 가을 학기 과정 복습<br />
                            DP2 특강은 남은 2학년의 전 과정 선행 및 2학년 가을 학기 과정 복습
                    </p>
                        <div className="vacaton-content-main">
                            <div className="margin-bottom-30 margin-top-30">
                                <a target="_blank" href="https://www.gurume.kr/group_class/62" className="gurume-blue-link">수학</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/65" className="gurume-blue-link">물리</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/1" className="gurume-blue-link">화학</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/61" className="gurume-blue-link">생물</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/104" className="gurume-blue-link">지리</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/70" className="gurume-blue-link">컴싸</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/49" className="gurume-blue-link">영어</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/102" className="gurume-blue-link">경제</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/5" className="hover-sub">경영</a>,&nbsp;
                                <a target="_blank" href="https://www.gurume.kr/group_class/71" className="gurume-blue-link">심리</a>,&nbsp;
                                <a>ToK</a>
                            </div>

                            <p>
                                *해당 과목을 클릭하시면 커리큘럼 상세내용 확인 가능<br />
                                *기타 과목 커리큘럼은 담당 과목 선생님과 상담
                            </p>
                            <div className="vacation-youtube">
                                <div className="videowrapper">
                                    <iframe className="embed-responsive-item" src="https://youtube.com/embed/91XJ50SWYTw" frameborder="0"></iframe>
                                </div>
                                <p>(2018 겨울방학 DP2 수학 HL 특강 중)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartG = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">Pre-DP 특강</h2>
                    <i>“Zoom"</i>
                    <br />
                    <p>
                        (기간)<br />
                    2020.6.29 ~ 2020.8.7<br />
                    6주)/ 주 3회/ 매 회 1.5시간
                    </p>
                    <br />
                    <p>
                        (강사진)<br />
                        <a href='/#/teachers/4442' target="_blank">[수학] 김승민 - 서울대 화학공학</a><br />
                        <a href='/#/teachers/1708' target="_blank">[영어] 강수민 - 연세대 UIC</a><br />
                    </p>
                    <p>
                        (시간표)<br />
                    [수학] 월, 수, 금 2시~3시 반<br />
                    [영어] 월, 수, 금 3시 반~5시<br />
                    *한국시간 기준
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartH = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">학습관리</h2>
                    <div className="vacation-table">
                        <table className="table table-bordered" width="90%">
                            <thead>
                                <tr>
                                    <td width="30%;">위클리 테스트</td>
                                    <td width="30%;">자습/보충 수업</td>
                                    <td width="30%;">IA/EE/ToK 과제</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        - 매주 배운 내용에 대한 학습 성취도 체크용 단원별 시험<br />
                                        - 각 과목별 선생님께서 디자인 해주셨기 때문에 과목별로 테스트 스타일과 포멧에 차이 有<br />
                                        - 과학의 경우 DP2는 점수 유실이 가장 많은 Paper 2 주간식 위주로 DP1은 개념확인을 하기 위한 Paper 1 객관식 위주로 문제 배정<br />
                                    </td>
                                    <td>
                                        - 과목별 TA 선생님과 자유로운 질의응답<br />
                                        - 감독관이 관리하는 수업 전.후 필수 자습시간<br />
                                        - 학부모님 요청시 학생 등.하원 보고<br />
                                        - 특강 수업 진도가 너무 빠르게 느껴지는 학생들은 탄탄한 습득을 위해 당직 TA 혹은 대학생 선생님과 보충 수업<br />
                                        - <a href="https://gurume.kr/mooc_preview" style={{ borderBottom: '1px solid #5b9aef', color: '#5b9aef', textDecoration: 'none' }} target="_blank">인강 학습</a>을 통한 수업내용 복습 (수학, 화학, 물리 한정)
                                    </td>
                                    <td>
                                        - DP1 학생은 주제를 가장 중요시 하기 때문에 특강 수업 중 1회는 IA 수업으로 샘플 IA와 주제 종류에 대해 소개<br />
                                        - DP2 학생은 여름방학 동안 과목별로 주제선정 및 초안 작성/  특강 전에 그루미와 IA 멘토링을 이미 시작하였다면 파이널 작성<br />
                                        - 주말반에서 ToK 발표 혹은 에세이 준비
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
    createPartI = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <div className="vacation-content-main width-60">
                        <h2>
                            <small>학부모 간담회</small>
                        </h2>
                        <p>
                            *특강기간의 중간시점에서 선생님께서 학부모님과 학생에게 학업 성취도와 학습진도에 대해 공유하여 남은 기간동안 부족한 부분에 더욱 신경써야하는 부분을 파악 및 개선<br />
                            *학교에서 진행되는 Parents Evening과 같이 학생도 동행하여 학생 또한 직접 선생님으로부터 피드백을 들을 필요有 (면대면 참여를 못하시는 학부모님은 보이스톡)<br />
                            *구글클래스 매니지먼트 시스템 사용
                        </p>
                        <p className="boldest-font">2020년 여름방학 학부모 간담회<br />
                            1차: 7월 4일 토요일 10:00am-1:30pm<br />
                            2차: 8월 1일 토요일 10:00am~1:30pm
                        </p>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartJ = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">수업 교재/학습 자료</h2>
                    <p>
                        구글 클래스룸으로 학생개별 폴더를 통해 제공<br />
                    매 수업 전 단원별로 PDF, 선생님 제작 Key Note 제공(구글 클래스룸 - 불법 유출금지)<br />
                    수업에 필요한 Past Paper 및 자료(구글 클래스룸)<br />
                    진도 체크 Quiz Form(구글 폼), 숙제(구글 폼), Weekly Test(구글 폼)<br />
                    메이저 출판사 교재 가이드북으로 활용<br />
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartK = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">성취 (Learning Outcome)</h2>
                    <p>
                        핵심개념 정리노트<br />
                    유형별 문제풀이 고득점 테크닉 터득<br />
                        IA/ToK/EE 과제물 이해 및 초본 완성
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartL = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">Amenities</h2>
                    <p>
                        쾌적한 자습실<br />
                        매 교실 대형 TV 스크린<br />
                        무료 음료/ 스낵
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartM = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">추후 서비스</h2>
                    <div className="vacation-schedule">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td>셀프스터디 패키지</td>
                                    <td>1:1 면대면/온라인수업</td>
                                    <td>대입 컨설팅</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>인강, 교재, 문제집를 사용하여 학기중에 학생 스스로 학습할 수 있는 프로그램을 제공합니다.</td>
                                    <td>특강이 끝난 후에도 지속적인 개별 수업을 통하여 학생 학업관리 및 성적을 책임집니다.</td>
                                    <td>학생의 포텐셜을 최대한 끌어올려 최상위권 대학에 원서를 넣을 수 있도록 멘토링을 제공합니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }
    createPartN = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">상담/수강신청</h2>
                    <p>
                        <b>
                            2020 여름방학 DP1 특강 신청서&nbsp;
                                    <a href="https://forms.gle/HEDXR2W144VLu4DH7" target="_blank">https://forms.gle/HEDXR2W144VLu4DH7</a><br />
                            2020 여름방학 DP2 특강 신청서&nbsp;
                                    <a href="https://forms.gle/5cHkNGz3XbrqpoGNA" target="_blank">https://forms.gle/5cHkNGz3XbrqpoGNA</a><br />
                            2020 여름방학 학교반 신청서&nbsp;
                                    <a href="https://forms.gle/E77xntiFpjChNLQC7" target="_blank">https://forms.gle/E77xntiFpjChNLQC7</a><br />
                        </b>
                        수강신청은 특강 시작 하루 전까지 가능하며, 피치못할 사정으로 특강 시작 후 부분수강을 하셔야 할 경우 연락주세요.<br />
                        정원 모집시 조기마감 될 수 있으니 방학 스케줄이 확정되면 빠른 시일 내에 등록 완료 하시길 바랍니다.
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartO = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">자주 묻는 질문 (FAQ)</h2>
                    <p className="margin-bottom-30">
                        반 구성 / 특강 선생님 / 학습 & 보충 / 부분 수강 & 중도하차 / 수업료<br />
                        <a href="https://goo.gl/VHTiFc" target="_blank">https://goo.gl/VHTiFc</a>
                    </p>
                </div>
            </div>
        )
    }

    createPartP = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">개인학습</h2>
                    <p>
                        학원 대표 선생님들은 방학기간 동안 특강 스케줄 때문에 개인수업이 불가하기 때문에 학기중에 개인수업을 하던 학생들은 방학기간 동안은 특강 학습을 권장드립니다.
                        하지만, 특강 수업을 원치 않을시 방학기간 동안의 임시 개인수업 선생님을 배정드립니다.
                    </p>
                    <p>
                        *특강이 불가한 과목 제외, 영문학<br />
                        *약 특강 시작 전 1달 전 부터 신청 받음<br />
                        *기존 학생에게 우선 배정하고 신규학생은 이후 예약 가능<br />
                        *다양한 영어 실력의 학생들의 니즈를 맞추기 위해 수업은 평균적으로 영어와 한국어 반반 사용으로 진행됩니다. 하지만 등록한 학생 그룹에 따라 주로 영어 혹은 한국어가 사용 될 수 있습니다.
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }
    createHtml = () => {
        return (
            <div className="vacation-wrapper">
                {this.createNav()}
                {this.createAcademy()}
                {this.createPartA()}
                {this.createPartB()}
                {this.createPartD()}
                {this.createPartF()}
                {this.createPartC()}
                {this.createPartG()}
                {this.createPartP()}
                {this.createPartH()}
                {this.createPartI()}
                {this.createPartJ()}
                {this.createPartK()}
                {this.createPartL()}
                {this.createPartM()}
                {this.createPartN()}
                {this.createPartO()}
            </div>
        )
    }

    render() {
        return (
            <div className="default-wrapper" >
                {this.createHtml()}
            </div>
        )
    }

}

export default Summer_vacation_workshop;