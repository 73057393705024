import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import {
    TrasTuitionhasbeencompleted,
    TrasRecommend,
    TrasDoNotRecommend,
    TrasToAdmin,
    TrasSkip,
    TrasAdmin,
    TrasSubmit,
    TrasSubmitting,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';

import '../credit/credit_common.css';


class NewReviews_new extends Component{

    state = {
        lang : 'ko',
        like: '',
        review: '',
        toAdmin: '',
        reviewsDetail: '',
        showAdminReviewContainerFlag: false,
        canWriteReviewFlag: false,
        onReviewSubmitting: false,
        tutorData: {},
        ordernum:''
    }

    getData = async (ordernum) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.tuitionIsReviewVaild;
        const res = await axios.get(url + '/' + ordernum).catch((err) => {return console.log(err); });

        if (res.data.status === 'fail') {
            const error_code = res.data.code;
            const msg = res.data.msg;
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            if (error_code === 0) {
                window.location.href = '/#/login?redirect=review&review_id='+ordernum;
            } else {
                const type = res.data.type;
                if (type === "student") {
                    window.location.href = '/#/students/dashboard';
                } else if (type === "tutor") {
                    window.location.href = '/#/dashboard';
                } else {
                    window.location.href = '/#/'
                }
            }
            return;
        }

        this.setState({
            ...this.state,
            tutorData: res.data.tutor,
            ordernum: ordernum,
        }, () => {
            this.props.RootBindingData.doLoading(false);
        });
    }

    componentDidMount = async () =>  {
        const ordernum =  this.props.match.params.ordernum;

        if(ordernum){
            await this.getData(ordernum);
        }else{
            let msg = '올바른 접근이 아닙니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
    }

    submitReview = async () => {

        this.setState({
            ...this.state,
            onReviewSubmitting: true
        });

        const lang = this.state.lang;
        const like = this.state.like;
        const canWriteReviewFlag = this.state.canWriteReviewFlag;
        const reviewsDetail = this.state.reviewsDetail;
        const showAdminReviewContainerFlag = this.state.showAdminReviewContainerFlag;
        let toAdminDetail = this.state.toAdminDetail;
        const ordernum = this.state.ordernum;

        // const confMsg = lang === 'ko' ? '정말 리뷰를 제출하시겠습니까? 더 자세하게 작성해주시면 선생님께 도움이 될 수 있습니다.'
        //                         :'en' ? 'Would you like to submit this review? A more detailed review can help you.'
        //                         : '你想提交这个评论吗？ 更详细的审查可以帮助你。';
        // if(!window.confirm(confMsg)){
        //     this.setState({
        //         ...this.state,
        //         onReviewSubmitting: false
        //     });
        //     return;
        // }

        if (like != '1' && like != '2') {
            this.setState({
                ...this.state,
                onReviewSubmitting: false
            }, () => {
                let msg = '선생님 추천 여부를 선택해 주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            });
            return;
        }

        if(!canWriteReviewFlag){
            if (reviewsDetail.length < 10) {
                this.setState({
                    ...this.state,
                    onReviewSubmitting: false
                }, () => {
                    let msg = '리뷰를 10글자 이상 입력해 주세요.';
                    showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                });
                return;
            }
        }

        if(showAdminReviewContainerFlag && toAdminDetail === ''){
            this.setState({
                ...this.state,
                onReviewSubmitting: false
            }, () => {
                let msg = '관리자에게 메시지를 작성해주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            });
            return;
        }

        if(toAdminDetail === '' || toAdminDetail === undefined){
            toAdminDetail = '';
        }


        const apiurls = await GetAjaxURL();
        const url = apiurls.tuitionReview + '/' + ordernum;

        const formData = new FormData();
        formData.append('like', like);
        formData.append('review', reviewsDetail);
        formData.append('to_admin', toAdminDetail);

        const res = await axios.post(url, formData, postConfig).catch((err) => {this.setState({onReviewSubmitting: false}); return err; })
        const data = res.data;
        this.setState({
            onReviewSubmitting: false
        });
        

        if (data.status === 'success') {
            alert({
                en: 'Successfully reviewed.',
                cn: '留言成功。',
                ko: '후기가 게시되었습니다.'
            }[lang||'ko'])
            window.location.href = '/#/teachers/' + data.tutor_id
        } else {
            alert({
                '1':{
                    en: 'Cannot find tuition.',
                    cn: '找不到这个tuition。',
                    ko: '강의를 찾을 수 없습니다.'
                },
                '2':{
                    en: 'You cannot review this tuition.',
                    cn: '你不能审查这个学费。',
                    ko: '해당 강의를 리뷰하실 수 없습니다.'
                },
                '3':{
                    en: 'Already reviewed.',
                    cn: '已留言过。',
                    ko: '후기가 이미 게시되었습니다.'
                },
                '4':{
                    en: 'Tuition cannot be reviewed in the current stage.',
                    cn: '目前阶段无法对学费进行审查。',
                    ko: '현재 해당 강의를 리뷰하실 수 없습니다. 올바르지 않은 접근입니다.'
                },
                '5':{
                    en: 'Cannot find the tutor.',
                    cn: '找不到导师。',
                    ko: '선생님을 찾을 수 없습니다.'
                },
                'invalid':{
                    en: 'Invalid satisfaction code.',
                    cn: '满意代码无效。',
                    ko: '올바르지 않은 접근입니다.'
                }
            }[['1','2','3','4','5'].includes(data.code) ? data.code : 'invalid'][lang||'ko'])
        }
    }


    doReviewOrNot = (e) => {
        const target = e.target;
        const isChecked = target.checked;
        const lang = this.state.lang;
    
        if(isChecked){
            const tutorData = this.state.tutorData;
            const teacherName = tutorData.last_name + tutorData.first_name;
            let msg = lang === 'en' ? 'Are you sure? ' + teacherName + ' will be happier if you wrote something about his/her teaching!' :
                      lang === 'cn' ? '你确定？ 如果你写了关于他/她的教学的话，“' + teacherName + '”将会更快乐。' :
                                      '리뷰를 작성하지 않으시겠습니까? ' + teacherName + ' 선생님의 강의에 대한 리뷰를 남겨주시면 선생님께 큰 도움이 됩니다.';
            if(!window.confirm(msg)){
                const skipReview = document.getElementById('skip-review');
                skipReview.checked = false;
                return;
            }
        }

        let reviewsDetail = this.state.reviewsDetail;
        if(isChecked) reviewsDetail = '';

        this.setState({
            ...this.state,
            canWriteReviewFlag : isChecked,
            reviewsDetail : reviewsDetail,
        }, () => {
            const writeReviewC = document.getElementById('writeReviewC');
            writeReviewC.disabled = this.state.canWriteReviewFlag;
        })

    }


    setShowAdminReviewContainerFlag = (e) => {
        const target = e.target;
        const isChecked = target.checked;

        let toAdminDetail = this.state.toAdminDetail;

        if(!isChecked) toAdminDetail = '';

        this.setState({
            ...this.state,
            showAdminReviewContainerFlag : isChecked,
            toAdminDetail : toAdminDetail
        }, () => { })
    }


    changerToAdminDetail = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            toAdminDetail : value
        })
    }

    changerReviewsDetail = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            reviewsDetail : value
        })
    }

    changerLikeValue = (e) => {
        const target = e.target;
        const value = target.value;
        this.setState({
            ...this.state,
            like : value,
        })
    }


    createHtml = () => {
        const showAdminReviewContainerFlag = this.state.showAdminReviewContainerFlag;
        const onReviewSubmitting = this.state.onReviewSubmitting;

        return(
            <div className="col-md-6 col-xs-12 mx-auto">
                <h2 className="margin-bottom-30"><TrasTuitionhasbeencompleted/></h2>
                <div className="form-group">
                    <input type="radio" name="review" value="1" id="recommend-radio" className="margin-right-5" onClick={this.changerLikeValue}/>
                    <label htmlFor="recommend-radio"><p><TrasRecommend/></p></label>
                    <input type="radio" name="review" value="2" id="not-recommend-radio" className="margin-right-5 margin-left-20" onClick={this.changerLikeValue}/>
                    <label htmlFor="not-recommend-radio"><p><TrasDoNotRecommend/></p></label>
                </div>

                <div className="form-group">
                    <input type="checkbox" onClick={this.setShowAdminReviewContainerFlag}
                            id="admin-review-container-toggle-checkbox" className="margin-right-5"/>
                    <label htmlFor="admin-review-container-toggle-checkbox"><p><TrasToAdmin/></p></label>
                    <input type="checkbox" id="skip-review"  className="margin-right-5 margin-left-20" 
                    onClick={this.doReviewOrNot}
                    />
                    <label htmlFor="skip-review"><p><TrasSkip/></p></label>
                </div>

                <div id="write-review-container">
                    <textarea id='writeReviewC'
                                className="form-control"
                                placeholder={'What was the tuition focused on? Did you find it useful? Were you satisfied with your tutors teaching and punctuality?'}
                                rows="4" onChange={this.changerReviewsDetail} 
                                value={this.state.reviewsDetail}
                                ></textarea>
                    {
                        showAdminReviewContainerFlag ?
                            <div id="admin-review-container" >
                                <p className="margin-top-20">To <TrasAdmin/></p>

                                <textarea className="form-control"
                                            placeholder="What was the tuition focused on? Did you find it useful? Were you satisfied with your tutor? Please share with us your opinion"
                                            rows="4" onChange={this.changerToAdminDetail}
                                            value={this.state.toAdminDetail}
                                            ></textarea>
                            </div>
                            : ''
                    }
                </div>

                {
                    onReviewSubmitting ?
                        <button className="btn submit-btn margin-bottom-10" disabled >
                            <font className="loading"><TrasSubmitting/></font>
                        </button>
                        :
                        <button className="credit-default-btn credit-default-blue-btn margin-top-10 margin-bottom-10" onClick={this.submitReview}>
                            <TrasSubmit/>
                        </button>
                }
                <br/><br/>
                <table className="table">
                    <thead>
                    <tr>
                        <td>직접 게시 (선생님 프로필 하단) - 비선택</td>
                        <td>“관리자에게” 선택</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            추후 학생/학부모님께서 선생님과의 수업을 고려시 많은 도움이 됩니다.<br/>
                            수업 후 학부모님 보다는 학생이 솔직하고 자세하게 수업후기를 작성하기를 권장합니다.<br/>
                            학생의 프라이버시를 위해 후기는 익명으로 게시됩니다.
                        </td>
                        <td>수업의 만족도에 대한 개선사항이 있을 경우 작성</td>
                    </tr>
                    </tbody>
                </table>
                <p className="gray2-font-color">*선생님과의 불화를 우려한 애로사항은 그루미 수업 매니저에게 연락주세요</p>
            </div>
        )
    }

    render(){
        return(
            <div className="container">
                {this.createHtml()}
            </div>
        )
    }

}

export default withRouter(NewReviews_new);

