import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { GetAjaxURL, postConfig, getEnvironment } from '../../../config/AjaxConfig';
import { fileConfig } from '../../../config/other';
import CKEditor from 'ckeditor4-react';
import {
    TrasHome,
    TrasGroupClass,
    TrasThumbnailPhoto,
    TrasClassTitle,
    TrasCurriculumSubject,
    TrasLearningOutcome,
    TrasClassCurriculum,
    TrasStartingEndDate,
    TrasTimetable,
    TrasLocation,
    TrasNumberofstudents,
    TrasTuitionFee,
    TrasPost,
    TrasBack,
    TrasNew
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//share function
import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';
import { isAdmin } from '../../extra/utils';

// Util Components
import ImageUploader from '../../utils/ImageUploader';


// css
import './groupclass.css';

//window
const $ = window.jQuery;
const confirm = window.confirm;


class GroupClassEdit extends Component {
    state = {
        lang: window.localStorage.lang || 'ko',
        isEditPage: false,
        gid: '',
        userData: {},
        userDataId: '',
        userIsAdmin: false,
        startDate: '',
        endDate: '',
        thumbnailPicList: [],
        showData: {
            status: 1,
            title: '',
            curriculums: [],
            timetable: '',
            description: '',
            price: '',
            location: '',
            student_number: '',
            outcome1: '',
            outcome2: '',
            outcome3: '',
            outcome4: '',
            youtube_embed_urls: '',
            end_of_test_doc_urls: '',
            thumbnail: '',
            classroom: [],
            textbook: [],
            start_time: '',
            end_time: '',
            isContinuousSelected: false
        },
        subjectTitle: '',
        subjectSelectedList: [],
        choosedSubject: [],
        choosedSubjectKey: [],
        isContinuousSelected: '',
        timeTable: '',
        location: '',
        studentNumber: '',
        learningoutComing1: '',
        learningoutComing2: '',
        learningoutComing3: '',
        learningoutComing4: '',
        teachContent: '',
        classRoomPicList: [],
        teachTextPicList: [],
        price: '',
        youtubeEmbedUrls: '',
        endOfTestDocUrls: '',

        isLoadingDone: false,
    }


    componentDidMount = async () => {
        const gid = this.props.match.params.gid;
        await this.getIsLogin();
        await this.getUserInfo();
        if (gid) {
            this.setState({
                isEditPage: true,
                gid,
            })
            await this.getData(gid);
        } else {
            this.setState({
                ...this.state,
                isLoadingDone: true,
            })
        }
    }

    getIsLogin = async () => {
        const { lang } = this.state
        const apiurls = await GetAjaxURL();
        const url = apiurls.isLogin;
        const res = await axios.get(url).catch((err) => { return err; });
        const data = res.data;
        if (data.isLogin === 'true') {
            if (data.type === 'student') {
                showNotify(lang === 'cn' ? '您不是老师' : lang === 'en' ? 'Only tutor can access.' : '선생님만 접근 가능합니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
                window.location.href = '/#/group_class'
            }
            this.setState({
                isLogin: true
            })
        } else {
            showNotify(lang === 'cn' ? '请登录' : lang === 'en' ? 'Please login.' : '로그인 후 이용가능합니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            this.goToLogin()
        }
    }

    getUserInfo = async () => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.apiThisUser;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;


        this.setState({
            userData: data,
            userDataId: data.user.id,
            userIsAdmin: isAdmin(data.user)
        });
        await this.getCurriculums(data);
        // if (isAdmin(data.user)) {
        //     await this.getTutors();
        // } else {
        //     // trick to put just logined 1 author to be array
        //     let list = [{id: data.user.id, name: data.user.name}];
        //     this.setState({
        //         authorList: list,
        //         authorId: list[0].id
        //     })
        // }
    }

    getCurriculums = async (userData) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.curriculums;
        const res = await axios.get(url).catch((err) => { return err; })
        const data = res.data;
        let subjectSelectedList = [];
        for (var i = userData.profile.teaching_major.length - 1; i >= 0; i--) {
            var selectMajorTmp, selectSubjectTmp, subjectSelectsTmp;
            var tmpData = userData.profile.teaching_major[i]
            //获取selectMajor
            switch (tmpData.test_type) {
                case '1':
                    selectMajorTmp = "Admission";
                    break;
                case '2':
                    selectMajorTmp = "Test";
                    break;
                case '3':
                    selectMajorTmp = "English";
                    break;
                default:
                    break;
            };
            selectSubjectTmp = tmpData.curriculum_name;
            subjectSelectsTmp = data[selectMajorTmp][selectSubjectTmp];
            var subjectNamesTmp = userData.profile.teaching_major[i].subject_name;
            for (var j = 0; j < subjectNamesTmp.length; j++) {
                var key = subjectNamesTmp[j].id;
                subjectSelectedList[key] = new SubjectSelectedItem(selectMajorTmp, selectSubjectTmp, subjectNamesTmp[j].name, key);
                subjectSelectedList[key].isSelected = false;
            }
        }
        function SubjectSelectedItem(major, subject, title, keyInOrgin) {
            this.Major = major;
            this.Subject = subject;
            this.Title = title;
            this.keyInOrgin = keyInOrgin;
        }
        this.setState({
            subjectSelectedList,
        })
    }

    getData = async (gid) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.groupclass.list + '/' + gid;
        const res = await axios.get(url).catch((err) => { return err; })
        let data = res.data;

        if (data.status === 'fail') {
            let msg = '그룹 클라스가 아직 승인되지 않았습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/group_class_list';
        }


        data.isContinuousSelected = data.end_time === '2100-1-1';
        data.start_time = new Date(data.start_time);
        data.end_time = new Date(data.end_time);

        if (data.isContinuousSelected) {
            const continuity_checkbox = document.getElementById('continuity_checkbox');
            if (continuity_checkbox)
                continuity_checkbox.click();
        }


        if (!data.classroom) {
            data.classroom = []
        } else {
            data.classroom = data.classroom.split('&')
        }
        if (!data.textbook) {
            data.textbook = []
        } else {
            data.textbook = data.textbook.split('&')
        }
        if (!data.youtube_embed_urls) data.youtube_embed_urls = ''
        if (!data.end_of_test_doc_urls) data.end_of_test_doc_urls = ''


        let { subjectSelectedList } = this.state
        let curriculums = data.curriculums.split('$')
        let that = this;

        subjectSelectedList.forEach((item, index) => {
            for (let i = 0; i < curriculums.length; i++) {
                if (curriculums[i].indexOf(item.keyInOrgin) > -1) {
                    that.chooseSubject(item, index, item.Title, item.Subject, item.Major);
                }
            }
        });
        this.setState({ ...this.state, showData: data, isLoadingDone: true }, () => {

        });

    }

    goToLogin = () => {
        window.location.href = '/#/login'
    }

    componentWillUnmount = (e) => {
        const { lang } = this.state;
        let confirmLeavingMessage = ('cn' === lang ? '您确定要离开此页面么' : 'en' === lang ? 'Are you sure you want to leave this page?' : '편집한 내용이 있습니다. 이동하시겠습니까?');
        // // todo
        // // if ($scope.validationForm.$dirty) {
        //     if (confirm(confirmLeavingMessage)) {
        //     //     // Just leave to find somewhere you want to arrive~
        //     } else {
        //     //     // e.preventDefault();
        //     }
        // // }
    }

    uploadPic = async (e, type) => { // type : thumbnail,classroom,textbook
        let target = e.target
        this.readFile(target.files)
        let photo = target.value
        const { lang } = this.state

        this.setState({
            onUploading: true
        })

        const imageReg = /^image\//;
        const filetype = target.files[0].type
        const filesize = target.files[0].size
        if (!imageReg.test(filetype)) {
            showNotify(lang === 'cn' ? '请上传正确的图片！' : lang === 'en' ? 'Only image is allowed to be upload.' : '이미지만 업로드 하실 수 있습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            photo = null;
            return;
        }

        if (filesize > fileConfig.fileUploadMaxSize) {
            showNotify(lang === 'cn' ? '图片过大，请压缩后再上上传。' : lang === 'en' ? 'Your image is too big to upload. please use another image' : '용량이 너무 커 업로드가 불가능합니다. 다른 이미지를 이용해 주세요.', STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }
        const postData = $.param({
            'image-data': 'data:image/png;base64,' + photo.base64,
        })

        const apiurls = await GetAjaxURL();
        let url = '';
        if (type === 'thumbnail') {
            url = apiurls.groupclass.uploadThumbnail;
        } else if (type === 'classroom') {
            url = apiurls.groupclass.uploadClassroom;
        } else {
            url = apiurls.groupclass.uploadTextbook;
        }
        const res = await axios.post(url, postData).catch((err) => { return err; })
        const data = res.data;

        if (data.status === 'success') {
            let { showData } = this.state
            if (type === 'thumbnail') {
                showData.thumbnail = data.message
            } else if (type === 'classroom') {
                showData.classroom.push(data.message);
                if (showData.classroom.length > 3) {
                    showData.classroom.splice(3)
                }
            } else {
                showData.textbook.push(data.message);
                if (showData.textbook.length > 3) {
                    showData.textbook.splice(3)
                }
            }
            this.setState({ showData })
            // showNotify(lang === 'cn' ? '图片已上传' : lang === 'en' ? 'Image has successfully been uploaded.'
            //     : '이미지를 성공적으로 업로드하였습니다.', STATE_NOTIFY.TYPE_POSITIVE);
            // this.setState({
            //     onUploading:false,
            //     onUploaded:true,
            //     imageName:data.name
            // })
        }
    }

    readFile(files) {
        let that = this
        if (files && files[0]) {
            let FR = new FileReader();
            FR.onload = function (e) {
                const pic = e.target.result;
                that.setState({
                    imageBase64: pic
                })

            };
            FR.readAsDataURL(files[0]);
        }
    }

    changeValue = (key, value) => {
        let { showData } = this.state
        showData[key] = value
        this.setState({ showData })
    }

    chooseSubject = (e, key, subject, supsubject, major) => {
        let { subjectSelectedList, choosedSubjectKey, choosedSubject } = this.state
        subjectSelectedList[key].isSelected = true
        if (choosedSubjectKey.indexOf(key) === -1) {
            choosedSubjectKey.push(key);
            choosedSubject.push(new ChoosedSubject(key, subject, supsubject, major))
        }
        this.setState({ subjectSelectedList, choosedSubjectKey, choosedSubject },
            () => { });

        function ChoosedSubject(key, subject, supsubject, major) {
            this.key = key;
            this.subject = subject;
            this.supsubject = supsubject;
            this.major = major;
        }
    }

    deleteSubject = (e, sub) => {
        let { choosedSubjectKey, choosedSubject, subjectSelectedList } = this.state
        for (var i in choosedSubject) {
            if (choosedSubject[i].subject === sub) {
                subjectSelectedList[choosedSubject[i].key].isSelected = false
                for (var j in choosedSubjectKey) {
                    if (choosedSubjectKey[j] === choosedSubject[i].key) {
                        choosedSubjectKey.splice(j, 1)
                    }
                }
                choosedSubject.splice(i, 1)
            }
        }
        this.setState({ choosedSubjectKey, choosedSubject, subjectSelectedList })
    }

    changeStartDate = (date) => {
        this.changeValue('start_time', date)
    }

    changeEndDate = (date) => {
        this.changeValue('end_time', date)
    }

    submitData = async () => {

        let { lang, showData, choosedSubjectKey, isEditPage, gid } = this.state
        if (showData.isContinuousSelected) {
            showData.start_time = new Date();
            showData.end_time = new Date("2100-01-01");
        } else {
            if (!showData.start_time || !showData.end_time) {
                showNotify('cn' === lang ? '请输入时间' : 'en' === lang ? 'Please fill in dates.' : '날짜를 입력해주세요.', "warning");
                return;
            }
            if (showData.start_time > showData.end_time) {
                showNotify('cn' === lang ? "开始时间不能大于结束时间" :
                    'en' === lang ? 'Start date cannot be later than end date' :
                        '시작일이 마지막날 보다 늦을 수 없습니다.', "warning");
                return;
            }
        }
        if (showData.outcome1 === undefined || showData.outcome1 === '') {
            showNotify('cn' === lang ? "\"请填写outcome\"" :
                'en' === lang ? 'Please input outcome' :
                    '기대 결과를 입력해주세요.', "warning");
            return;
        }

        showData.classroom = showData.classroom.join('$');
        showData.textbook = showData.textbook.join('$');
        showData.curriculums = choosedSubjectKey.join("$")
        showData.thumbnail = showData.thumbnail.replace('https://s3.ap-northeast-2.amazonaws.com/gurume-groupclass-thumbnail/', '');

        var month = showData.start_time.getMonth() + 1;
        var day = showData.start_time.getDate();
        var year = showData.start_time.getFullYear();
        var endMonth = showData.end_time.getMonth() + 1;
        var endDay = showData.end_time.getDate();
        var endYear = showData.end_time.getFullYear();
        showData.start_time = year + '-' + month + '-' + day;
        showData.end_time = endYear + '-' + endMonth + '-' + endDay;



        // 유튜브 처리
        showData.youtubeEmbedUrls = showData.youtube_embed_urls;
        showData.endOfTestDocUrls = showData.end_of_test_doc_urls;



        const postData = $.param(showData)
        const apiurls = await GetAjaxURL();
        let url = apiurls.groupclass.new;
        if (isEditPage) url = apiurls.groupclass.edit + '/' + gid;
        const res = await axios.post(url, postData, postConfig).catch((err) => {
            showNotify(lang === 'en' ? 'Unknown Error - Please Refresh' :
                lang === 'cn' ? '未知错误－请刷新' : '알수없는 에러 - 새로고침 해주세요', STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({
                onPosting: false
            })
        })
        const data = res.data;
        if (data.status === 'success') {
            if (isEditPage) {
                showNotify('cn' === lang ? "修改成功" : 'en' === lang ? "Edit complete" : "정상적으로 수정되었습니다.", STATE_NOTIFY.TYPE_POSITIVE);
            } else {
                gid = data.id
                showNotify('cn' === lang ? "新建成功" : 'en' === lang ? 'Postage Success' : '게시완료', STATE_NOTIFY.TYPE_POSITIVE);
            }

            window.location.href = '/#/group_class/' + gid
            window.location.reload()
        } else {
            let msg = (data.msg === 'End time must after the start time.') ? '시작 날짜가 끝나는 날짜보다 앞 설 수 없습니다.' : data.msg;
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }


    }

    createBreadcrumb = () => {
        return (
            <ol className="breadcrumb">
                <li><a href="/#/"><TrasHome /></a></li>
                <li><a href="/#/group_class_list"><TrasGroupClass /></a></li>
                <li className="active"><TrasNew /></li>
            </ol>
        )
    }



    IMAGE_CallbackFuc = (_fileName) => {

        this.setState({
            ...this.state,
            showData: {
                ...this.state.showData,
                thumbnail: _fileName
            }
        }, () => { console.log('데이터 저장 완료 : ' + this.state.showData.thumbnail); })

    }

    IMAGE_CallbackCancel = (_obj) => {
        this.setState({
            ...this.state,
            showData: {
                ...this.state.showData,
                thumbnail: ''
            }
        });
    }



    createContentHtml = () => {
        // const { lang, thumbnailPicList, subjectTitle, subjectSelectedList, choosedSubject, isContinuousSelected,timeTable,location,studentNumber,learningoutComing1,
        //     learningoutComing2,learningoutComing3,learningoutComing4,teachContent,classRoomPicList,teachTextPicList,price,youtubeEmbedUrls,endOfTestDocUrls  } = this.state
        const { showData, subjectSelectedList, choosedSubject } = this.state
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <p>*When uploading the post, please bear in mind that it will mostly be parents reading this and
                        therefore use Korean as much as possible except for subject-specific terminologies in the curriculum section.</p>
                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasThumbnailPhoto /></h5>

                            {
                                this.state.isLoadingDone ?
                                    <React.Fragment>
                                        <h5 className="blue-font-color">이미지</h5>
                                        <ImageUploader
                                            autoLoadingDataUrl={this.state.showData.thumbnail}
                                            IMAGE_CallbackFuc={this.IMAGE_CallbackFuc}
                                            IMAGE_CallbackCancel={this.IMAGE_CallbackCancel}
                                            formType={'group_class'}
                                        />
                                    </React.Fragment>
                                    : ''
                            }

                            {/* 

                            <input type='file' onChange={(e)=>this.uploadPic(e,'thumbnail')} />
                            {
                                showData.thumbnail ?
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="uploaded-pic-container">
                                            <img alt="img" src={showData.thumbnail} className="img-fluid" />
                                        </div>
                                    </div>
                                </div> : ''
                            }
                             */}
                        </div>
                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasClassTitle /></h5>
                            <input className="form-control" value={showData.title} onChange={(e) => this.changeValue('title', e.target.value)} />
                        </div>
                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasCurriculumSubject /></h5>
                            <div className="row">
                                {/* <div className="select-area"> */}
                                <div className="col-md-5">
                                    <div className="base-select">
                                        <div className="subject-select-wrapper">
                                            <ul className="tutorreg-subject-ul">
                                                {
                                                    subjectSelectedList.map((item, index) =>
                                                        <li key={'subjectSelectedList' + index}
                                                            onClick={(e) => this.chooseSubject(e, index, item.Title, item.Subject, item.Major)}
                                                            className={subjectSelectedList[index].isSelected ? 'tutorreg-subject-li tutorreg-' + item.Major + '-selected' : 'tutorreg-subject-li'}>
                                                            {item.Title}
                                                        </li>
                                                    )
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 text-center">
                                    <div className="tutorreg-arrow-right center-block"></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="base-select">
                                        <div className="subject-select-wrapper">
                                            {
                                                choosedSubject.map((item, index) =>
                                                    <div className="one-selected margin-left-15 margin-right-15" key={'choosedSubject' + index}>
                                                        <div className="row align-items-center">
                                                            <div className="col-xs-4 col-md-4">
                                                                <span className={['d-inline-block', 'text-center', 'base-btn', 'tutorreg-' + item.major + '-selected'].join(' ')}>
                                                                    {item.supsubject}
                                                                </span>
                                                            </div>
                                                            <div className="col-xs-7 col-md-7">
                                                                {item.subject}
                                                            </div>
                                                            <div className="col-xs-1 col-md-1 delete-subject-img">
                                                                <img alt="img" src="../images/subbtn.png" className="img-fluid"
                                                                    onClick={(e) => this.deleteSubject(e, item.subject)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasStartingEndDate /></h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <DatePicker
                                        selected={this.state.showData.start_time}
                                        onChange={this.changeStartDate}
                                        placeholderText="dd/mm/yyyy"
                                        disabled={showData.isContinuousSelected}
                                    />
                                    {/* <input type="text" className="form-control" placeholder="dd/mm/yyyy" id="datepickerStart" disabled={isContinuousSelected} /> */}
                                </div>
                                <div className="col-md-6">
                                    <DatePicker
                                        selected={this.state.showData.end_time}
                                        onChange={this.changeEndDate}
                                        placeholderText="dd/mm/yyyy"
                                        disabled={showData.isContinuousSelected}
                                    />
                                    {/* <input type="text" className="form-control" placeholder="dd/mm/yyyy" id="datepickerEnd" disabled={isContinuousSelected} /> */}
                                </div>
                                <div className="col-md-12">
                                    <input type="checkbox" id="continuity_checkbox" name="moocradios" value={showData.isContinuousSelected} onChange={(e) => this.changeValue('isContinuousSelected', e.target.checked)} />
                                    <label className="padding-left-5" htmlFor="continuity_checkbox">수업을 상시 진행합니다.</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasTimetable /></h5>
                            <input className="form-control" value={showData.timetable} onChange={(e) => this.changeValue('timetable', e.target.value)} />
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasLocation /></h5>
                            <input className="form-control" value={showData.location} onChange={(e) => this.changeValue('location', e.target.value)} />
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasNumberofstudents /></h5>
                            <input className="form-control" type="number" value={showData.student_number} onChange={(e) => this.changeValue('student_number', e.target.value)} />
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasLearningOutcome /></h5>
                            <input className="form-control" value={showData.outcome1} onChange={(e) => this.changeValue('outcome1', e.target.value)} />
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasLearningOutcome />2</h5>
                            <input className="form-control" value={showData.outcome2} onChange={(e) => this.changeValue('outcome2', e.target.value)} />
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasLearningOutcome />3</h5>
                            <input className="form-control" value={showData.outcome3} onChange={(e) => this.changeValue('outcome3', e.target.value)} />
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasLearningOutcome />4</h5>
                            <input className="form-control" value={showData.outcome4} onChange={(e) => this.changeValue('outcome4', e.target.value)} />
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasClassCurriculum /></h5>
                            <p>Do not just copy and paste in subject curriculum/ exam syllabus. Be as specific as possible in
                            your own
                            course
                                details. This part is what parents will look at in details.</p>
                            {
                                this.state.isLoadingDone ?
                                    <CKEditor
                                        data={showData.description}
                                        config={{
                                            // baseHref:[['/ckeditor/']],
                                            language: [['ko']],
                                            filebrowserImageUploadUrl: `${getEnvironment()}/data/blog/upload`,
                                            filebrowserUploadUrl: `${getEnvironment()}/data/blog/upload`,
                                            height: 350,
                                        }}
                                        onChange={(e) => this.changeValue('description', e.editor.getData())}
                                    />
                                    : ''
                            }

                            {/* <textarea cols="80" id="editor1" name="editor1" rows="10" value={teachContent} onChange={(e) => this.changeValue('teachContent', e.target.value)}></textarea> */}
                        </div>
                        {/* 
                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasClassroom/></h5>


                            <input type='file' onChange={(e) => this.uploadPic(e,'classroom')} />
                            <div className="row">
                                {
                                    showData.classroom.map((item,index) => 
                                        <div className="col-sm-4" key={'classroom'+index}>
                                            <div className="uploaded-pic-container">
                                                <img alt="img" src={item} className="img-fluid" />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasTextbook/></h5>
                            <input type='file' onChange={(e) => this.uploadPic(e,'textbook')} />
                            <div className="row">
                                {
                                    showData.textbook.map((item,index) => 
                                        <div className="col-sm-4" key={'textbook'+index}>
                                            <img alt="img" src={item} className="img-fluid" />
                                        </div>
                                    )
                                }
                            </div>
                        </div> */}

                        <div className="form-group">
                            <h5 className="blue-font-color"><TrasTuitionFee /></h5>
                            <textarea className="form-control" value={showData.price} onChange={(e) => this.changeValue('price', e.target.value)} ></textarea>
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color">Youtube Embed links
                                <img alt="img" src="../images/youtube.png" className="inline-block margin-left-10 youtube-title-img" />
                            </h5>

                            <textarea className="form-control" placeholder="(임시) 만약 유투브 영상이 여러개라면, 영상 주소 사이를 '$' 로 구분해 주세요."
                                value={showData.youtube_embed_urls} onChange={(e) => this.changeValue('youtube_embed_urls', e.target.value)}
                            ></textarea>
                        </div>

                        <div className="form-group">
                            <h5 className="blue-font-color">End of test Doc links</h5>

                            <textarea className="form-control" placeholder="(임시) 만약 Google Doc이 여러개라면, 파일 주소 사이를 '$' 로 구분해 주세요."
                                value={showData.end_of_test_doc_urls} onChange={(e) => this.changeValue('end_of_test_doc_urls', e.target.value)}
                            ></textarea>
                        </div>

                        <div className="form-group">
                            <button className="btn submit-btn" onClick={() => this.submitData()}><TrasPost /></button>
                            <a href="/#/group_class_list" className="btn cancel-btn margin-left-20"><TrasBack /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render = () => {
        return (
            <div className="group-class-edit-container">
                {this.createBreadcrumb()}
                {this.createContentHtml()}
            </div>
        )
    }
}

export default withRouter(GroupClassEdit);
