export const localPath = 
[ {component_type: "C", component_name: "admin", component_path: "/gurume-admin", component_ck: ""},
{component_type: "C", component_name: "index", component_path: "/", component_ck: ""},
{component_type: "C", component_name: "index1", component_path: "", component_ck: ""},
{component_type: "C", component_name: "pay", component_path: "/pay", component_ck: ""},
{component_type: "C", component_name: "login", component_path: "/login", component_ck: ""},
{component_type: "C", component_name: "userreg", component_path: "/register", component_ck: ""},
{component_type: "C", component_name: "forgotpassword", component_path: "/forgot_password", component_ck: ""},
{component_type: "C", component_name: "resetpassword", component_path: "/reset_password", component_ck: ""},
{component_type: "C", component_name: "useraccountsetting", component_path: "/users/account_setting", component_ck: ""},
{component_type: "C", component_name: "accountactivation", component_path: "/account_activation", component_ck: ""},
 {component_type: "C", component_name: "teachers_native", component_path: "/teachers/native", component_ck: ""},
 {component_type: "C", component_name: "tdashboard", component_path: "/teachers/dashboard", component_ck: ""},
 {component_type: "C", component_name: "tutorreg", component_path: "/teachers/register", component_ck: ""},
 {component_type: "C", component_name: "editteacherprofile", component_path: "/teachers/{tid}/edit", component_ck: ""},
{component_type: "C", component_name: "teacherprofile", component_path: "/teachers/{tutorId}", component_ck: ""},
{component_type: "C", component_name: "editTutorProfile", component_path: "/teachers/edit", component_ck: ""},
{component_type: "C", component_name: "newTutorProfile", component_path: "/teachers/new", component_ck: ""},
{component_type: "C", component_name: "search", component_path: "/teachers?sid={id}&tid={Tid}&uid={Uid}&current_pag…r={prefGender}&teacher_levels={teacherLevelParam}", component_ck: ""},
{component_type: "C", component_name: "teacher_apply", component_path: "/teacher/apply", component_ck: ""},
{component_type: "C", component_name: "siteusage", component_path: "/site_usage", component_ck: ""},
 {component_type: "C", component_name: "editstudentprofile", component_path: "/students/{sid}/edit", component_ck: ""},
 {component_type: "C", component_name: "studentreg", component_path: "/students/register", component_ck: ""},
 {component_type: "C", component_name: "sdashboard", component_path: "/students/dashboard", component_ck: ""},
 {component_type: "C", component_name: "sitemessage", component_path: "/sitemessage", component_ck: ""},
{component_type: "C", component_name: "messagesItem", component_path: "/messages/{mid}", component_ck: ""},
{component_type: "C", component_name: "messages", component_path: "/messages", component_ck: ""},
{component_type: "C", component_name: "questionsindex", component_path: "/questions", component_ck: ""},
{component_type: "C", component_name: "questionsnew", component_path: "/questions/new", component_ck: ""},
{component_type: "C", component_name: "blogindex", component_path: "/blogs?cid={Cid}&current_page={currentPage}", component_ck: ""},
{component_type: "C", component_name: "blognew", component_path: "/blogs/new", component_ck: ""},
 {component_type: "C", component_name: "blogedit", component_path: "/blogs/{blogId}/edit", component_ck: ""},
 {component_type: "C", component_name: "bloginvitation", component_path: "/blogs/invitation", component_ck: ""},
 {component_type: "C", component_name: "blogshow", component_path: "/blogs/{id}", component_ck: ""},
 {component_type: "C", component_name: "moocindex", component_path: "/mooc", component_ck: ""},
{component_type: "C", component_name: "moocsubscribe", component_path: "/mooc/subscribe/{moocId}", component_ck: ""},
{component_type: "C", component_name: "moocnew", component_path: "/mooc/new", component_ck: ""},
{component_type: "C", component_name: "moocedit", component_path: "/mooc/{moocId}/edit", component_ck: ""},
{component_type: "C", component_name: "moocshow", component_path: "/mooc/{id}", component_ck: ""},
{component_type: "C", component_name: "mygcoin", component_path: "/mygcoin", component_ck: ""},
{component_type: "C", component_name: "tuition_request_show", component_path: "/tuition_requests/{rid}", component_ck: ""},
 {component_type: "C", component_name: "tuition_request_index", component_path: "/tuition_requests?current_page={currentPage}", component_ck: ""},
 {component_type: "C", component_name: "tuition_request_new", component_path: "/tuition_requests_new", component_ck: ""},
 {component_type: "C", component_name: "tuition_request_edit", component_path: "/tuition_requests/{rid}/edit", component_ck: ""},
 {component_type: "C", component_name: "tuition_request_opportunity", component_path: "/tuition_requests/opportunity", component_ck: ""},
{component_type: "C", component_name: "tuition_request_opportunity_abroad", component_path: "/tuition_requests/opportunity_abroad", component_ck: ""},
{component_type: "C", component_name: "group_class_logistics", component_path: "/group_class/logistics", component_ck: ""},
{component_type: "C", component_name: "about_gurume", component_path: "/about_gurume", component_ck: ""},
{component_type: "C", component_name: "how_it_works_instant_qa", component_path: "/how_it_works/instant_qa", component_ck: ""},
{component_type: "C", component_name: "how_it_works_tuition", component_path: "/how_it_works/tuition", component_ck: ""},
{component_type: "C", component_name: "how_it_works_group_class", component_path: "/how_it_works/group_class", component_ck: ""},
 {component_type: "C", component_name: "how_it_works_consulting", component_path: "/how_it_works/consulting", component_ck: ""},
 {component_type: "C", component_name: "how_it_works_resources", component_path: "/how_it_works/resources", component_ck: ""},
 {component_type: "C", component_name: "how_it_works_resources_example", component_path: "/how_it_works/resources/example", component_ck: ""},
 {component_type: "C", component_name: "how_it_works_mooc", component_path: "/how_it_works/mooc", component_ck: ""},
{component_type: "C", component_name: "how_it_works_questions", component_path: "/how_it_works/questions", component_ck: ""},
{component_type: "C", component_name: "online_tuition", component_path: "/online_tuition", component_ck: ""},
{component_type: "C", component_name: "online_tuition_touchpad", component_path: "/online_tuition_touchpad", component_ck: ""},
{component_type: "C", component_name: "contact_us", component_path: "/contact_us", component_ck: ""},
{component_type: "C", component_name: "useredit", component_path: "/users/edit", component_ck: ""},
{component_type: "C", component_name: "credit_balance", component_path: "/credit/balance", component_ck: ""},
 {component_type: "C", component_name: "credit_history", component_path: "/credit/history", component_ck: ""},
 {component_type: "C", component_name: "credit_buy", component_path: "/credit/buy", component_ck: ""},
 {component_type: "C", component_name: "credit_withdraw", component_path: "/credit/withdraw", component_ck: ""},
 {component_type: "C", component_name: "credit_approve", component_path: "/credit/approve", component_ck: ""},
 {component_type: "C", component_name: "tuition_invoice", component_path: "/tuition/invoice", component_ck: ""},
 {component_type: "C", component_name: "tuitions_bill", component_path: "/tuitions/bill", component_ck: ""},
 {component_type: "C", component_name: "tuitions_bill_admin", component_path: "/tuitions/bill/{teacherid}", component_ck: ""},
    {component_type: "C", component_name: "tuitions_bill_edit", component_path: "/tuitions/bill/edit/{ordernum}", component_ck: ""},
{component_type: "C", component_name: "tuitions_copy", component_path: "/tuitions/copy/{ordernum}", component_ck: ""},
{component_type: "C", component_name: "tuitions_confirm", component_path: "/tuitions/confirm/{Cid}", component_ck: ""},
{component_type: "C", component_name: "tuitions_progress_report", component_path: "/tuitions/progress_report", component_ck: ""},
{component_type: "C", component_name: "studyroom", component_path: "/studyroom", component_ck: ""},
 {component_type: "C", component_name: "reviews_new", component_path: "/reviews/new/{rid}", component_ck: ""},
 {component_type: "C", component_name: "group_class_new", component_path: "/group_class/new", component_ck: ""},
 {component_type: "C", component_name: "group_class_show", component_path: "/group_class/{Gid}", component_ck: ""},
 {component_type: "C", component_name: "group_class_edit", component_path: "/group_class/{gid}/edit", component_ck: ""},
{component_type: "C", component_name: "group_class_index", component_path: "/group_class", component_ck: ""},
{component_type: "C", component_name: "group_class_index_list", component_path: "/group_class_list", component_ck: ""},
{component_type: "C", component_name: "group_class_ib_songdo", component_path: "/group_class/ib-songdo", component_ck: ""},
{component_type: "C", component_name: "group_class_ib_2018_summer", component_path: "/group_class/2018/ib_summer", component_ck: ""},
{component_type: "C", component_name: "group_class_ib_2018_summer_fee", component_path: "/group_class/2018/ib_summer/fee", component_ck: ""},
{component_type: "C", component_name: "group_class_ib_2018_summer_fee2", component_path: "/group_class/2018/ib_summer/fee2", component_ck: ""},
 {component_type: "C", component_name: "group_class_ib_2018_summer_fee3", component_path: "/group_class/2018/ib_summer/fee3", component_ck: ""},
 {component_type: "C", component_name: "group_class_ib_2018_winter", component_path: "/group_class/2018/ib_winter", component_ck: ""},
 {component_type: "C", component_name: "group_class_ib_2018_winter_fee", component_path: "/group_class/2018/ib_winter/fee", component_ck: ""},
 {component_type: "C", component_name: "group_class_ib_jeju", component_path: "/group_class/ib-jeju", component_ck: ""},
{component_type: "C", component_name: "group_class_ib_ggwg", component_path: "/group_class/ib-gyeonggi-foreign-language-high-school", component_ck: ""},
{component_type: "C", component_name: "group_class_timetable", component_path: "/group_class/timetable", component_ck: ""},
{component_type: "C", component_name: "question_bank", component_path: "/question_bank", component_ck: ""},
{component_type: "C", component_name: "question_bank_demo", component_path: "/question_bank_demo", component_ck: ""},
{component_type: "C", component_name: "question_bank_show", component_path: "/question_bank/show", component_ck: ""},
{component_type: "C", component_name: "recruiting", component_path: "/about_gurume/recruiting", component_ck: ""},
 {component_type: "C", component_name: "recruiting2", component_path: "/about_gurume/career", component_ck: ""},
 {component_type: "C", component_name: "recruiting_developer", component_path: "/about_gurume/recruiting/2018_summer", component_ck: ""},
 {component_type: "C", component_name: "mooc_preview", component_path: "/mooc_preview", component_ck: ""},
 {component_type: "C", component_name: "mooc_logistics", component_path: "/mooc_logistics", component_ck: ""},
{component_type: "C", component_name: "events", component_path: "/events", component_ck: ""},
{component_type: "C", component_name: "native_tutors", component_path: "/native_tutors", component_ck: ""},
{component_type: "C", component_name: "admissions_consulting", component_path: "/admissions_consulting", component_ck: ""},
{component_type: "C", component_name: "admissions_consulting_uk", component_path: "/admissions_consulting/uk", component_ck: ""},
{component_type: "C", component_name: "admissions_consulting_us", component_path: "/admissions_consulting/us", component_ck: ""},
{component_type: "C", component_name: "admissions_consulting_korea", component_path: "/admissions_consulting/korea", component_ck: ""},
{component_type: "C", component_name: "partners", component_path: "/partners", component_ck: ""},
{component_type: "C", component_name: "academy", component_path: "/academy", component_ck: ""},
{component_type: "C", component_name: "academies", component_path: "/academies", component_ck: ""},
{component_type: "C", component_name: "academy_summer_vacation_workshop", component_path: "/academy/vacation_workshop", component_ck: ""},
{component_type: "C", component_name: "demo_class_video", component_path: "/demo_class_video", component_ck: ""},
{component_type: "C", component_name: "errorpage", component_path: "/errorpage", component_ck: ""},
{component_type: "C", component_name: "error", component_path: "*path", component_ck: ""}]