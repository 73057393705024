import React, {Component} from 'react';
import counterpart from 'counterpart';
import PropTypes from 'prop-types';

import './LocaleSwitcher.css';

const LocaleSwitcherPropTypes = {
  showLangPannel : PropTypes.func,
  hideLangPannel : PropTypes.func,
  showLang : PropTypes.bool,
  callback : PropTypes.func,
}
const LocaleSwitcherDefaultProps = {
  showLangPannel : ()=>{console.log("LocaleSwitcher showLangPannel Error")},
  hideLangPannel : ()=>{console.log("LocaleSwitcher hideLangPannel Error")},
  showLang : false,
  callback : ()=>{console.log("LocaleSwitcher callback Error")}
}

export default class LocaleSwitcher extends Component {
 
  state = {
    LOCAL_KR : 'kr',
    LOCAL_EN : 'en',
    LOCAL_CN : 'cn'
  }

  handleChange(e) {
    counterpart.setLocale(e.target.value);
  }

  clickKorean = () => {
    this.props.callback('ko');
    counterpart.setLocale(this.state.LOCAL_KR);
  }
  clickEnglish = () => {
    this.props.callback(this.state.LOCAL_EN);
    counterpart.setLocale(this.state.LOCAL_EN);
  }
  clickChinese = () => {
    this.props.callback(this.state.LOCAL_CN);
    counterpart.setLocale(this.state.LOCAL_CN);
  }

  
  render() {
    return (
      <div className='lang-wrapper'>
        {
          this.props.showLang ?
            <div className='lang-bg' onClick={this.props.hideLangPannel}>
            <div className='lang-pannel slideDownNav'>
              <ul>
                <li onClick={this.clickKorean}>한국어</li>
                <li onClick={this.clickEnglish}>English</li>
                <li onClick={this.clickChinese}>中文</li>
              </ul>
            </div>
          </div>
          :''
        }
      </div>
    );
  }
}

  LocaleSwitcher.propTypes = LocaleSwitcherPropTypes;
  LocaleSwitcher.defaultProps = LocaleSwitcherDefaultProps;

  counterpart.registerTranslations('kr', {
    "GuruMe": "그루미",
    "Home": "Home",
    "Student": "학생",
    "Parent": "학부모님",
    "Tutor": "선생님",
    "Academy": "학원",
    "Blog": "블로그 포럼",
    "Lecture": "강의",
    "Dashboard": "마이페이지",
    "GCoin": "G코인",
    "Inbox": "쪽지함",
    "Login": "로그인",
    "Sign Out": "로그아웃",
    "User": "회원",
    "Account Setting": "계정관리",
    "Language": "언어",
    "Error Page": "오류 페이지",
    "Get Started": "시작하기",
    "Try Now": "맛보기",
    "Coming Soon": "서비스 준비중",
    "About GuruMe": "그루미 소개",
    "Recruiting": "채용",
    "Tuition": "수업",
    "How it works": "이용방법",

    "Connected with kakaotalk": "카카오톡 연결됨",
    "Online Tuition": "온라인 수업",
    "Terms and Conditions": "이용약관",
    "Become A Tutor": "선생님 등록",
    "Contact Us": "연락처",
    "Verify": "인증",
    "Verified": "인증됨",
    "Email verification number": "이메일로 발송된 인증번호",
    "Kakao verification number": "카카오톡 발송 인증번호",
    "Please refer to How it works and FAQ regarding GuruMe site usage For any other enquiries please feel free to contact us through any of the following": "그루미 사이트 사용법은 이용방법 참조를 부탁드리겠습니다. 그 외 문의 사항은 아래 연락처로 연락주시면 감사하겠습니다.",
    "Support": "고객센터",
    "GuruMe Address": "중국 북경 하이디앤 지춘로 113호 E-Wing 센터 A동 706호, 10084",
    "Company Number": "사업자등록번호: 671-55-00156 | 통신판매업 신고번호: 제 2017-대전유성-0081 호",
    "Follow Us": "구독",
    "Meet your perfect suited teacher": "나에게 딱 맞는 선생님 만나기",
    "View More Tutors": "더 많은 선생님들 보기",
    "Find a Tutor": "전 세계 600명 이상의 선생님과 원하는 맞춤형 수업을 함께하세요",
    "View Profile": "프로필 열람",
    "View Tutor Profile": "선생님 프로필 열람",
    "Search Engine": "검색창",
    "Please select subject": "과목을 선택하세요",
    "Search": "검색",
    "Teaching style": "수업 스타일",
    "Actively lead the student": "내신관리",
    "Boost up test grades": "시험성적 향상",
    "Motivate and inspire as an older brother and sister like mentor": "형, 누나, 언니, 오빠로서 동기부여",
    "Help with homework": "숙제 도움",
    "Explain difficult concepts clearly": "어려운 개념 설명",
    "Make the subject more interesting": "과목에 흥미를 더하기",
    "More filter options": "더 많은 필터 옵션 보기",
    "Apologies, there is no tutor that matches your requests. Please contact GuruMe support team and request for recruitment of your ideal tutor.": "요청하신 조건에 부합하시는 선생님이 안 계십니다. 고객센터에 문의하셔서 원하시는 선생님을 요청해 주세요.",
    "Profile Image": "프로필 이미지",
    "Having a profile picture looks more committed": "프로필 사진이 있으면 더 성의있어 보입니다",
    "Resize image": "이미지 크기조절",
    "Upload": "업로드",
    "Uploading": "업로드중",
    "Uploaded": "업로드 완료",
    "Add second location (term-time address for current students)": "두번째 위치 (재학생들의 학기중 위치)",
    "Education Background": "학력",
    "View Certificate": "학생증/졸업증 보기",
    "Enrolment/graduation certificate available upon request": "그루미 고객센터에 학생증/졸업증 요청하시면 보여드립니다.",
    "Close filter option": "필터 옵션 닫기",
    "Hourly rate": "시급",
    "1st, 2nd year undergraduate": "대학생 1,2 학년",
    "3rd, 4th year undergraduate, graduate": "대학생 3,4 학년 및 졸업생",
    "graduate, master": "졸업생, 대학원생 (석사)",
    "master, phd": "대학원생 (석사, 박사)",
    "1st criteria s": "4만+",
    "2nd criteria s": "5.5만+",
    "3rd criteria s": "7만+",
    "4th criteria s": "8.5만+",
    "1st criteria t": "3만+",
    "2nd criteria t": "4.5만+",
    "3rd criteria t": "6만+",
    "4th criteria t": "7.5만+",
    "University": "대학",
    "Test": "시험",
    "Admissions": "어플리케이션",
    "English": "영어",
    "Show": "보기",
    "Hide": "가리기",
    "Profile Visibility": "프로필 공개 설정",
    "Temporarily not teaching": "당분간 수업을 받지 않습니다",
    "Name": "이름",
    "First Name": "이름",
    "Last Name": "성",
    "English Name": "영어 이름",
    "Email": "이메일",
    "Password": "비밀번호",
    "Confirm Password": "비밀번호 확인",
    "The password is not strong enough": "비밀번호 보안이 너무 약합니다.",
    "Password does not match": "비밀번호가 일치하지 않습니다",
    "Current Password": "현재 비밀번호",
    "Needed to confirm your changes": "정보수정에 필요",
    "Please enter current password": "현재 비밀번호를 입력해주세요",
    "New Password": "새로운 비밀번호",
    "Leave blank if you dont want to change it": "변경을 원하지 않으면 비워두세요",
    "Confirm New Password": "새로운 비밀번호 확인",
    "Change Password": "비밀번호 변경",
    "Tutor Registration": "선생님 가입",
    "Student Registration": "학생 가입",
    "Parent Registration": "학부모님 가입",
    "Academy Registration": "학원 가입",
    "Please contact GuruMe support for academy registration": "학원가입은 그루미 고객센터로 연락부탁드립니다.",
    "Already have an account": "계정이 이미 있으신가요",
    "Email Address": "이메일 주소",
    "Login with Facebook": "페이스북",
    "Login with Wechat": "위쳇",
    "Remember me": "로그인 상태 유지",
    "Dont have an account yet?": "아직 회원이 아니신가요?",
    "Forgot your password": "비밀번호를 잊어버리셨나요",
    "Forgot your password p": "그루미 가입시 알려주신 이메일주소를 입력해 주세요. 비밀번호를 다시 설정할 수 있는 링크를 해당 이메일 계정으로 보내드립니다.",
    "Reset Password": "비밀번호 재설정",
    "Send": "발송",
    "Account Activation": "계정 활성화",
    "Thanks for registering with GuruMe": "그루미에 가입하신 걸 환영합니다. 학생/선생님 가입 페이지 링크가 첨부된 계정 활성화 이메일이 발송되었습니다.",
    "Please follow the email instruction to complete your registration": "이메일에 게시된 대로 가입 완료를 해주세요.",
    "If you havent yet received the account activation email after 10 minutes": "10분 후에도 계정활성화 이메일을 못 받으셨다면",
    "Check that the email address you have written is correct": "입력된 이메일주소 확인",
    "Check the spam box": "스팸함 확인",
    "If the problem still continues contact GuruMe support team": "문제가 계속 된다면 그루미 고객센터에 문의 주시기 바랍니다.",
    "Please enter a name": "이름을 입력해주세요",
    "Please enter a surname": "성을 입력해주세요",
    "Please enter an English Name": "영어 이름을 입력해주세요",
    "Please enter an email address": "이메일주소를 입력해주세요",
    "Please enter a password": "비밀번호를 입력해주세요",
    "Please confirm the password": "비밀번호 확인을 해주세요",
    "Please enter an email and password": "이메일과 비밀번호를 입력해주세요",
    "Email not registered": "미가입 이메일 주소",
    "Account is not activated yet Please check your email and activate your account": "계정이 아직 활성화 되지 않았습니다. 이메일 확인하시고 계정을 활성화 해주세요.",
    "Invalid email or password": "이메일 혹은 비밀번호에 문제가 있습니다",
    "Wrong password": "비밀번호가 잘못됬습니다",
    "School": "학교",
    "Subjects of interest": "수업 관심 과목",
    "Location": "위치",
    "Term-time location": "학기중 위치",
    "District and town": "구/동",
    "Contact details": "연락처",
    "Entering phone numbers and SNS details increases the likelihood of students and parents reaching out for enquiries.": "SNS를 포함한 연락처를 지해하면 수업문의가 더 들어옵니다.",
    "Contact details are not publicly displayed but only when requested by students and parents": "연락처는 모두에게 공개되는 것이 아니라 학생/학부모님이 요청했을때만 공개됩니다.",
    "Contact details are not publicly displayed but only to teachers that you have lessons with": "연락처는 모두에게 공개되는 것이 아니라 수업 신청한 선생님에게만 공개됩니다.",
    "Phone number": "전화번호",
    "Whatsapp ID": "Whatsapp ID",
    "Skype ID": "스카입 ID",
    "Wechat ID": "위쳇 ID",
    "Kakaotalk ID": "카카오톡 ID",
    "Register": "회원가입",
    "Registering": "가입중",
    "Educational Background": "학력",
    "Add education background": "학력 더하기",
    "Degree": "전공",
    "High School": "고등",
    "Undergraduate": "학사",
    "Master": "석사",
    "PhD": "박사",
    "Professional": "전문/풀타임",
    "undergraduate": "학사",
    "graduate": "졸업생",
    "professional" : "전문/풀타임",
    "University Student": "대학생",
    "Graduate": "졸업생",
    "Date": "년도",
    "Field of Study": "전공분야",
    "Specific Major": "특정 전공",
    "If your university is not listed, temporarily select Other, ask GuruMe support to add in your university so that you can edit afterwards": "해당 대학교가 없다면 임의로 Other를 선택해 주시고 고객센터에 문의해 주시면 해당 대학교 추가드리겠습니다.",
    "Leave blank if your major name is identical to one of the major fields in the list above": "과목명이 선택한 전공과 일치하면 비워두시면 됩니다.",
    "Close": "닫기",
    "Save": "저장",
    "Upload certificate": "학생증/졸업증",
    "Teaching Subjects": "수업과목",
    "Add teaching experience": "수업 경력 더하기",
    "Curriculum/Subject": "커리큘럼/과목",
    "Description": "수업 내용",
    "IB Chemistry": "예, IB Chemistry",
    "e.g. topic taught, grade improvement and etc.": "예, 가르친 토픽, 성적 향상 등",
    "Last Updated": "마지막 업데이트",
    "About Myself": "자기소개",
    "not less than 150 words": "not less than 150 words",
    "Teaching Style": "수업 스타일",
    "Teaching Experience": "수업 경력",
    "Less than 2 years": "2년 이하",
    "More than 2 years": "2년 이상",
    "Add your teaching experience": "수업 경험 더하기",
    "Refer to Harrys as an example": "Harry 선생님 프로필 참조",
    "Hourly Rate": "시급",
    "Submit": "제출",
    "Submitting": "제출중",
    "View Tutors": "선생님 보기",
    "Tuition Request": "수업신청",
    "Tuition Request Dashboard": "수업신청 게시판",
    "Tuition request form": "수업신청서",
    "Submit a tuition request form and check applications from potential tutors in the inbox": "수업신청서를 작성하신 후 쪽지함에서 선생님 지원서를 확인하세요.",
    "Tuition Mode": "수업 모드",
    "Online": "온라인",
    "Face to face": "오프라인",
    "SNS Mentoring": "카톡 멘토링",
    "No preference": "상관없음",
    "Tuition Aim": "수업 목표",
    "Please write down as much as possible about your tuition needs and goals so that applying tutors can explain in details how they can help": "빠른 매칭을 위해서 필요한 수업 요구사항과 목표롤 상세하게 적어주세요.",
    "Tuition Frequency": "수업 횟수",
    "session(s)/week": "회/주",
    "Apply": "지원하기",
    "Application Example": "지원서 예시",
    "Sign Up": "신청하기",
    "students have already signed up": "명이 이미 신청하였습니다",
    "Enquiry": "상담문의",
    "others have already applied": "명이 이미 지원하였습니다",
    "I have chosen a tutor": "게시글 내리기",
    "Matched": "매칭 완료",
    "Appeal your class": "나만의 수업을 어필하세요",
    "Your profile link will be attached Briefly describe how you can help with reference to the students tuition aim": "프로필 링크가 같이 보내집니다. 학생의 수업 목표를 고려해서 어떻게 수업을 도와줄 수 있을지 간결하게 어필해주세요!",
    "Teaching Resource": "수업 자료",
    "Textbook": "교재",
    "Past Paper": "기출문제",
    "Tuition Bill": "수업료 인보이스",
    "Tuition Bill Link": "수업료 인보이스 링크",
    "Copy": "복사",
    "Copy and send the link below to the student/parent:": "아래 링크와 문구를 복사해서 학생/학부모님께 보내주세요:",
    "Pending": "수업 미확정",
    "Confirming Payment": "입금 확인중",
    "Book Tuition": "수업예약",
    "Below are a selection of teachers you have taken lessons with contacted or saved Schedule a new lesson now": "Below are a selection of teachers you have taken lessons with, contacted, or saved. Schedule a new lesson now.",
    "Status": "확인상태",
    "In Progress": "수업 진행중",
    "Confirm Tuition": "수업 컨펌하기",
    "Confirm": "확인",
    "Agree": "수업 진행에 동의합니다",
    "Progress Report": "Progress Report",
    "Example Progress Report": "Progress Report 예시",
    "Complete": "수업 완료",
    "Please make sure that you have agreed on the following tuition conditions with the student/parent": "학생/학부모님과 아래와 같은 수업 조건들을 동의하신 후 제출바랍니다.",
    "Starting/End Date": "시작/끝 날짜",
    "Total Hours": "총 시간",
    "Parent Pays": "학부모 지불 금액",
    "Tutor Receives": "선생님 수급 금액",
    "Commission Explained": "수수료 설명",
    "After submission you will be given a tuition bill link which you need to give your student/parent": "제출후에 수업료 요청링크가 생성되면 학생/학부모님께 전달해주세요.",
    "View all tuitions": "수업 전체보기",
    "Details": "상세내용",
    "Tuition Fee": "수업료",
    "Balance History": "Balance History",
    "Credit": "크레딧",
    "Change Currency": "화폐 전환",
    "Buy": "구매",
    "Buy Credit": "크레딧 구매",
    "Withdraw": "withdraw",
    "Withdraw Credit": "크레딧 withdraw",
    "Withdraw History": "withdraw 기록",
    "Credit awaiting withdraw": "Credit awaiting withdraw",
    "Amount": "Amount",
    "Payment Method": "결제방식",
    "Balance": "밸런스",
    "Please process the bank transfer as stated using the students name": "원활한 입금확인을 위해 학생이름으로 입금을 부탁드립니다.",
    "Bank Transfer": "계좌이체",
    "Bank Details": "계좌정보",
    "Paypal": "Paypal",
    "Alipay": "Alipay",
    "Credit/Debit Card": "Credit/Debit Card",
    "Processing Fee": "Processing Fee",
    "Total funds": "Total funds",
    "Process payment": "Process payment",
    "Tuition Review": "수업후기",
    "Recommend": "추천",
    "Do Not Recommend": "비추천",
    "Message": "쪽지",
    "Please find the tuition bill form in dashboard/tuition.": "대시보드/수업 란에서 수업료 인보이스를 작성/발행하실 수 있습니다.",
    "Please note that messages can be vigilated by GuruMe for appropriate site usage": "원활한 수업 문의 및 진행을 위해 필요시 관리자는 쪽지를 조회 할 수 있습니다.",
    "Group Class": "그룹수업",
    "Class Title": "수업 제목",
    "Timetable": "시간표",
    "Number of students": "모집인원",
    "Learning Outcome": "Learning Outcome",
    "Class Curriculum": "수업 커리큘럼",
    "Classroom": "교실",
    "Please visit the tutor profile and make an enquiry through the displayed contact details or site message": "그루미 고객센터 혹은 선생님 프로필에 게시된 연락처 혹은 쪽지를 통하여 문의해주세요",
    "Question": "문제",
    "QuestionNew": "New",
    "Ask Question": "질문하기",
    "New": "신규",
    "Answering": "답변중",
    "Answered": "답변완료",
    "Question Type": "질문분류",
    "Question Title": "질문제목",
    "Photo": "첨부사진",
    "Up to 3 images": "최대 3장",
    "Confused about...": "헷갈리는 부분",
    "Answer": "답변",
    "Video Link": "영상 링크",
    "Explanation": "해답",
    "Upload answer": "답변 올리기",
    "Write": "글쓰기",
    "Read": "읽기",
    "Blog Category": "블로그 카테고리",
    "Title": "제목",
    "Author": "글쓴이",
    "Content": "본문",
    "Introductory Paragraph": "소개글",
    "Thumbnail Photo": "썸네일 사진",
    "Post": "게시",
    "Posting": "게시중",
    "Related Articles": "Related Articles",
    "Comment": "댓글",
    "Reply": "답변",
    "Delete": "삭제",
    "Cancel": "취소",
    "Need to login to leave comment": "댓글을 남기려면 로그인 하셔야 합니다",
    "Back": "뒤로가기",
    "Online Lecture": "온라인 강좌",
    "New Trend in Study Abroad": "유학교육의 새로운 트렌드",
    "Lecture Category": "카테고리",
    "Concept": "컨셉",
    "PastQuestion": "PastQuestion",
    "Topic": "주제",
    "Image": "이미지",
    "Preview": "미리보기",
    "Sub Topic": "하위 주제",
    "Keywords": "키워드",
    "Add": "새로 추가",
    "Youtube": "유튜브",
    "Youku": "유쿠",
    "For participation enquiry please email support@gurumekr": "참여 문의는 support@gurume.kr로 이메일 주세요",
    "Event": "이벤트",
    "Sorry the page you are looking for doesn’t exist": "죄송합니다 찾고 계신 페이지는 존재하지 않습니다",
    "Please double check the website url If the problem continues please contact GuruMe support team": "웹페이지 주소를 다시 한번 확인 부탁드립니다. 계속 문제가 발생해서 원하시는 페이지를 찾으실 수 없다면 그루미 고객센터에 연락부탁드립니다.",
    "Edit Profile": "프로필 수정",
    "Edit": "수정",
    "Editing": "수정중",
    "For Teachers": "선생님",
    "Get matched with your perfect teacher": "과외 선생님과 매칭",
    "Browse through the 300+ GuruMe tutor pool/ Submit tuition request form": "400+명의 다양한 그루미 선생님들의 프로필을 열람 혹은 수업신청 공고를 게시해보세요.",
    "Discuss tuition needs and conditions with tutor(s) of interest": "관심있는 선생님(들)과 쪽지와 게시된 연락처를 통하여 수업과목/시간/페이 등을 상담하세요.",
    "Try out demo class": "상담 후 데모수업을 원하면 선생님(들)께 1:1 데모 수업을 신청하세요.",
    "Confirm tutor/tuition": "선생님/수업 확정",
    "After tutor and tuition confirmation, tutor invoices parents": "수업 확정이 되면 선생님이 학부모님께 그루미 수업료 청구서를 보내드립니다.",
    "Parents pay tuition fee to GuruMe": "청구서의 내역대로 그루미에게 수업료를 입금해주세요.",
    "Tuition In Progress": "수업 진행",
    "is provided by tutors after 1 month; upon agreement, tutors will invoice for the next months tuition fee at the same time": "를 통해 학생의 진도내역과 현황을 마이페이지에서 확인합니다.",
    "End of Tuition": "수업 완료",
    "Student/parent confirms end of tuition.": "학생/학부모님께서 수업완료 확인을 합니다.",
    "Tuition review can be submitted.": "수업후기를 적어주실 수 있습니다",
    "Tuition fee is transferred to tutor.": "수업료가 선생님에게 지급됩니다.",
    "are in place to ensure punctuality and commitment of both teachers and students": "are in place to ensure punctuality and commitment of both teachers and students.",
    "Some tutors may have pricing different to the following GuruMe recommendation": "아래 그루미 추천 수업료 측정 가이드라인에 예외인 선생님들이 계실 수 있습니다.",
    "Above is a general guideline but tuition fee (hourly rate) is dfferent for each tutor and may change based on tuition period curriculum and offline vs online": "위는 일반적인 가이드라인이지만, 수업료 (시급제)는 선생님마다 다를 수 있고 과외 기간, 커리큘럼 및 면대면 vs 온라인에 따라 변동될 가능성이 높습니다. 정확한 수업료는 선생님과 협의하에 조정할 수 있습니다.",
    "Late": "지각",
    "Less than 10 mins": "10분 이하",
    "Extra 5-10 minutes at the end or beginning of next lesson": "수업 후 혹은 다음 수업 전 추가 5-10분",
    "More than 10 mins": "10분 이상",
    "Late time counted as lesson conducted": "늦은 시간만큼 수업시간으로 간주",
    "Next lesson conducted free of charge by the late time amount": "다음 수업 중 늦은 시간만큼 무료로 진행",
    "Reschedule": "리스케줄",
    "05 hour counted as lesson conducted": "0.5시간 수업이행으로 간주",
    "Next 05 hour lesson conducted free of charge": "다음 수업의 0.5시간 무료로 진행",
    "Cancellation": "취소",
    "Less than 2 hour notice": "2시간 이하 연락",
    "1 hour counted as lesson conducted": "1시간 수업이행으로 간주",
    "Next 1 hour lesson conducted free of charge": "다음 수업의 1시간 무료로 진행",
    "Absence": "결석",
    "No notice": "무통보",
    "Counted as lesson conducted": "수업이행으로 간주",
    "Next lesson conducted free of charge": "다음 수업 무료로 진행",
    "How much cheaper is online tuition compared face to face?": "온라인 수업은 면대면 수업보다 얼마나 더 저렴한지요??",
    "Online tuition is usually 0.5만 cheaper than face to face tuition": "온라인 수업은 보통 면대면 수업보다 5천원 저렴합니다.",
    "What if a tutor suddenly says he/she cant tutor anymore?": "선생님께서 갑자기 그만두시게 되면 어떻하나요?",
    "Contact GuruMe support and you will be matched with an equally excellent tutor for similar tuition fee.": "그루미 고객센터에 연락주시면 기존 선생님처럼 잘 가르키시는 선생님을 매칭시켜드리겠습니다.",
    "Who should pay the study room cost?": "스터디룸비는 누가 내야하나요?",
    "Tuition fee is what tutors deserve for their teaching. Tutors paying for study room cost means reduction in their earnings. So its best tutors dont pay, but if they are willing, yes.": "Tuition fee is what tutors deserve for their teaching. Tutors paying for study room cost means reduction in their earnings. So it's best tutors don't pay, but if they are willing, yes.",
    "How effective is SNS Mentoring? I am worried my son/daughter plays with the phone instead of SNS mentoring with tutor.": "How effective is SNS Mentoring? I am worried my son/daughter plays with the phone instead of SNS mentoring with tutor.",
    "SNS Mentoring definitely has the danger of students playing the phone. If the student has the tendency to play the phone instead of using the phone to receiving SNS Mentroing, real-time tuition is recommended.": "SNS Mentoring definitely has the danger of students playing the phone. If the student has the tendency to play the phone instead of using the phone to receiving SNS Mentroing, real-time tuition is recommended.",
    "Click": "클릭",
    "Online Whiteboard Touchpad": "온라인 칠판 터치패드",
    "Science Based Lessons": "과학 수업",
    "Demo Class": "데모수업",
    "Demo Class Video": "데모수업 영상",
    "Science Based Lessons Intro": "Gooroomee는 라이브 영상통화와 온라인 칠판을 제공하는 온라인 교실 소프트웨어입니다. 1:1 수업 뿐만 아니라 학생 10명까지 한번에 참여할 수 있습니다. 이 프로그램은 어떠한 다운도 필요하지 않지만 현재는 컴퓨터에서 크롬 브라우저로만 사용 가능하고 한국어 버전만 지원하고 있습니다. 아래의 링크를 참고해주세요.",
    "Usage Manual": "사용 설명서",
    "Essay Based Lessons": "에세이 수업",
    "Essay Based Lessons Intro": "자기소개서나 에세이 첨삭 수업에 경우, GuruMe는 영상 통화로는 Skype, 첨삭 도구로는 Google Document를 이용하길 추천합니다. Google Document는 Microsoft word의 온라인 버전이라고 생각하시면 쉽습니다. 하지만 링크를 공유하고 있는 여러 참여자들이 온라인으로 자료를 보고, 수정할 수 있으며 그 내용이 참여자들에게 바로 보여집니다.",
    "More Info": "더보기",
    "Live Video Call": "실시간 영상 통화",
    "Wechat": "위쳇",
    "Google Hangout": "구글 행아웃",
    "FaceTime": "페이스타임",
    "Live Video Call p": "때로 국제 영상 통화는 특히 중국에서는 신호가 좋지 못합니다. 먼저 와이파이가 가장 빠른 곳에서 다시 시도해보고, 여전히 연결에 문제가 있다면 아래 옵션 중 하나를 시도해보세요.",
    "Live Video Call p2": "이 소프트웨어들을 이용하는 데 문제가 있다면, ‘Team Viewer’을 설치한 후 GuruMe에게 ‘Remote Control’섹션 아래에 있는 ‘Your ID’와 ‘Password’를 알려주세요.",
    "Online Whiteboard": "온라인 칠판",
    "Online Whiteboard p": "www.awwapp.com 은 또다른 화이트보드 소프트웨어로 수학, 화학, 물리 같이 방정식을 써야하는 과목들을 사용하기에 적합합니다. 이 프로그램은 어떠한 다운로드도 필요하지 않고 모든 인터넷 브라우저에서 사용할 수 있습니다.(IE는 몇몇 기능들이 호환이 되지 않기 때문에 가급적 사용하지 않는 게 좋습니다.) 컴퓨터, 태블릿 그리고 모바일에서 모두 사용가능하지만 터치패드를 연결하여 사용하는 것이 가장 효과적입니다.",
    "Online Whiteboard p2": "실시간 영상통화와 온라인 화이트보드 소프트웨어를 함께 사용하실 수 있습니다.",
    "FAQ": "FAQ",
    "FAQ Q1": "선생님이 주신 링크에 접속을 하려고 하는 데 회색 배경 페이지에서 더이상 접속이 진행되지 않아요.",
    "FAQ A1": "30초 후에도 아무런 변화가 없다면, 페이지를 새로고침 해주세요. 만약 여전히 연결이 되지 않으면 GuruMe 서포트 팀으로 연락주세요.",
    "FAQ Q2": "선생님 목소리를 들을 수 없어요/ 선생님 스크린이 멈춰있어요.",
    "FAQ A2": "페이지를 새로고침 해주세요.",
    "FAQ Q3": "선생님의 목소리가 자꾸 깨져서 들려요.",
    "FAQ A3": "Gooroomee의 마이크와 스피커를 끄고 wechat 음성통화를 이용하세요.",
    "Online Whiteboard Touchpad p": "온라인 칠판에서 효과적으로 글씨를 쓰고 보충설명을 할 수 있습니다.",
    "Online Whiteboard Touchpad p2": "그루미는 가성비가 좋은 Huion 420를 추천합니다.",
    "Online Whiteboard Touchpad p3": "에서 $25로 직접 구매할 수 있습니다. 중국 외의 지역에서 구매할 경우, 추가 배송료가 있기 때문에 나라별 주요 온라인 마켓에서 찾아보는 것이 좋습니다.",
    "Singapore": "싱가폴",
    "UK": "영국",
    "Korea": "한국",
    "China": "중국",
    "Alternatively, contact GuruMe and we will mail you the touchpad as a retailer.": "또한, GuruMe로 연락주시면 대리구매도 도와드립니다.",
    "Touchpad Usage Manual": "터치패드 사용 메뉴얼",
    "Touchpad Driver Installation:": "터치패드 드라이버 설치:",
    "Insert AAA battery to the pen": "AAA battery를 펜에 넣어주세요",
    "Click and activate the pen": "표시된 부분을 눌러 펜을 활성화 시켜주세요",
    "Connect the touchpad to a computer using the cable": "케이블을 이용하여 터치패드와 컴퓨터를 연결해주세요",
    "Tutors I have bookmarked": "관심 선생님",
    "Blogs I have bookmarked": "내가 찜한 글",
    "Lectures I have bookmarked": "내가 찜한 강의영상",
    "Questions I have bookmarked": "내가 찜한 문제",
    "Bookmark": "북마크",
    "Bookmarked": "찜 완료",
    "Unbookmark": "찜 취소",
    "For participation as teacher inquiry, please email support@gurume.kr": "선생님 참여 문의는 마이페이지에 있는 인강 소개서 혹은 support@gurume.kr 이메일 부탁드립니다",
    "More reviews": "후기 더 보기",
    "More": "더 보기",
    "Fold": "접기",
    "To Admin": "관리자에게",
    "Admin": "관리자",
    "Skip": "생략",
    "Leave message to admin": "관리자에게",
    "What was the tuition focused on? Did you find it useful? Were you satisfied with your tutors teaching and punctuality?": "어떤 과목 수업을 하셨나요? 선생님 수업에 만족하셨나요? 수업시간은 잘 지키셨나요?",
    "Already agreed": "이미 동의하였습니다.",
    "Progress Report tip : Leave note when finish lesson. It is recommended to download mobile google docs app": "Progress Report 사용팁: 모바일로 수업 끝날때 마다 노트를 합니다. 모바일에 Google doc 어플 다운 받아놓으면 편합니다.",
    "Please click this link and leave a review": "링크를 클릭하셔서 수업 완료 확인을 해주실 수 있을까요? 확인을 해주셔야 수업료 수급이 가능하여 메세지 드립니다^^ 이번 수업에 대한 Progress Report 또한 그루미 마이페이지에 로그인 하셔서 확인 하실 수 있습니다.",
    "Reviews left to Admin": "관리자에게",
    "Please login to your gurume account and click confirm. If you have difficulty login, Please contact gurume customer center.": "수업료 인보이스 링크이며 1) '수업 동의' 버튼을 눌러주시고 2)신한은행 계좌로 입금해주시면 감사하겠습니다. 로그인에 어려움이 있으시면 그루미 고객센터로 연락부탁드립니다.",
    "Copy to clipboard": "클립보드에 복사",
    "Is This Tuition Demo?": "데모 수업인가요?",
    "If it is a demo class it will be reduced by 05 hours from the total time": "데모 수업인 경우, 총 수업 시간에서 -0.5시간이 차감됩니다.",
    "It is demo": "데모 수업입니다.",
    "It is not demo": "데모 수업이 아닙니다.",
    "Manage": "관리",
    "My Blogs": "나의 블로그",
    "Questions I have answered": "나의 답변들",
    "Demo": "데모",
    "It would be helpful if you could provide more detailed information": "보다 자세한 정보를 남겨주시면 적합한 도움을 드릴 확률이 올라갑니다.",
    "About myself": "나에 대한 설명",
    "Do you like this teacher? then contact teacher!": "선생님이 마음에 드시면 수업 문의를 해보세요.",
    "You are blocked": "정지된 계정입니다. 정지를 풀기 위해 고객센터에 연락해 주세요.",
    "Upload your student identification or diploma to become a recognized membership.": "학생증/졸업증이 있으면 인증받은 회원이 될 수 있습니다.",
    "Tuition has been completed": "수업 완료",
    "Tuition fee awaiting transfer to tutor": "선생님께 수업료 입금중",
    "Confirm end of tuition": "수업 완료 확인",
    "Tuition fee transfer complete": "수업료 입금 완료",
    "(coming Friday)": "(오는 금요일)",
    "Tuition fee awaiting transfer": "수업료 입금중",
    "End of tuition confirmation pending": "수업 완료 확인중",
    "Having a certificate picture looks more committed": "증명서가 등록되어있으면 학부모님들께서 더 신뢰합니다.",
    "Call": "전화",
    "Show confirm page": "확인 페이지 보기",
    "Add more subtopic": "하위 주제 추가",
    "Networking event": "네트워킹 이벤트",
    "Many classroom opportunities for absentee or graduate teachers": "휴학 혹은 졸업생 선생님들을 위한 많은 수업시간 기회",
    "Full time": "풀타임",
    "Dispatch": "파견",
    "Class": "수업",
    "Manage Profile": "프로필 관리",
    "Help": "도움",
    "New tuition payment system": "새로운 수업료 지급 시스템",
    "Class completion button": "수업완료 버튼",
    "You can notify students/parents and GuruMe that you have finished your class by just clicking button.": "수업 완료 후 마이페이지에서 버튼 하나로 학생/학부모님 및 그루미에게 수업 완료 통보.",
    "You do not need to ask GuruMe to pay tuition fees.": "더이상 그루미에게 수업료 입금 부탁 메세지 할 필요 무!",
    "Tuition will be paid every Friday.": "매주 금요일 수업료 입금.",
    "After confirming of students/parents, tuition will be paid in that friday.": "학생/학부모님 수업완료 확인 후 오는 금요일 수업료 입금.",
    "Show detail": "자세히 보기",
    "MOOC Online Lecture": "인강 온라인 강좌",
    "Opportunity for teachers who want to get steady income and be star instructor": "꾸준한 수입 및 스타강사가 되고 싶으신 선생님들을 위한 Opportunity",
    "GuruMes commission will be added to your hourly rate.": "학부모 결제 시 그루미 커미션 비용이 시급에 더해집니다.",
    "N.B.": "주의",
    "Site Usage": "사이트 이용",
    "Info": "알림",
    "My MOOC": "나의 인강",
    "You currently dont have any tuition going on": "현재 진행중인 강의가 없습니다",
    "Book more tuition with your previous tutors": "선생님에게 요청해 새 강의를 수강해보세요",
    "Skype": "스카이프",
    "Kakaotalk": "카카오톡",
    "Whatsapp": "왓츠앱",
    "Phone": "전화번호",
    "Please enter your primary phone number.": "카톡알림 메세지를 받아보실 수 있도록 카톡에 연동되어있는 핸드폰 번호를 입력해 주세요.",
    "Please enter your secondary phone number.": "두번째 전화번호 (예: 해외 번호)",
    "Write in your native language first and also in English below if you want to teach foreign students too online.": "선생님의 주 언어로 자기소개를 작성해 주시고, 온라인으로 외국 학생들의 수업을 희망할 시 아래에 영어로도 자기소개를 남겨주시기 바랍니다.",
    "Monthly or for agreed period of time if less than a month": "한달 단위 혹은 1달 미만으로 합의된경우 합의된 기간을 선택해 주세요.",
    "Show more tuitions": "수업 전체 보기",
    "Fold tuitions": "접기",
    "You can get kakaotalk alarm when you fill in phone number which is linked with kakaotalk": "전화번호는 카톡에 연동되어있는 번호로 적어주시면 선생님께 쪽지를 보내실 때 카톡으로 알림을 받으실 수 있습니다.",
    "Please select whether the group class is end": "그룹수업 종료 여부",
    "Not finished": "종료되지 않았습니다.",
    "Finished": "종료되었습니다.",
    "Sending": "발송중",
    "Show all": "모두 보기",
    "Please enter phone number": "전화번호를 입력해 주세요.",
    "It is recommended to upload certificate images": "정말 증명서 이미지를 업로드하지 않으시겠습니까? 증명서를 업로드 하시면 더 좋은 인상을 줄 수 있습니다.",
    "Write comment if you have questions": "질문이 있으시면 덧글로 문의하세요.",
    "Find tutor fits with your goal. If you are hard to find your tutor, request new tuition!": "나에게 맞는 선생님을 찾아 목표를 이루세요! 선생님을 찾기 어렵다면 수업 요청을 등록하세요.",
    "Request new tuition": "새 수업요청 등록하기",
    "Thumbnail Intro": "한줄 소개",
    "Please read": "꼭 읽어주세요.",
    "Join more opportunities": "더 참여해보세요.",
    "Heera Shin": "희라쌤",
    "Harry Lee": "해리쌤",
    "Manuel Im": "누엘쌤",
    "Need help?": "도움이 필요하신가요?",
    "Punch line displayed on the thumbnail": "썸네일에 표시되는 \"펀치라인\"",
    "I can improve your bioscience or chemial score in confident way. I'm making basis in long-term relationship.": "생물, 화학은 자신있게 성적 올려드릴 수 있습니다. 단기보다는 장기로 천천히 기초를 다지는 스타일입니다.",
    "Add blog post": "포스트 추가",
    "Add MOOC": "인강 추가",
    "Not entered.": "입력되지 않았습니다.",
    "Please be sure to refer to the use of the GuruMe.": "꼭 읽어주세요.",
    "DEMO": "데모",
    "REVIEWS": "후기",
    "Please input detail location to find appropriate teacher.": "더 적합한 선생님을 찾기 위해 위치를 자세히 메모해주세요.",
    "Please enter your your disable until date description": "언제까지 수업이 불가능한지 간단하게 메모를 남겨주세요.",
    "High school name": "고등학교 이름",
    "Select test": "시험 선택",
    "Period": "기간",
    "University name": "대학교 이름",
    "Homepage": "홈페이지",
    "Community": "커뮤니티",
    "Networking": "네트워킹",
    "Admission": "입시",
    "Admission Consulting": "입시 컨설팅",
    "Fill in bank account": "e.g. 신한은행 110-338-435020 이혁진",
    "If you change your account Please update your profile and inform GuruMe customer center about your bank account changes": "만약 계좌가 바뀌면 프로필 업데이트 및 그루미 고객센터에 계좌 변경 메세지를 남겨주세요.",
    "Class Rules": "수업 규정",
    "Depends on duration": "부재 일자에 따라 다름",
    "Are you academy user?": "학원이신가요?",
    "Pending invoicing": "인보이스 발행 대기중",
    "In preparation": "인보이스 준비중",
    "It can takes about ~1 day to get response from teacher. Please contact GuruMe if you need urgent help.": "선생님에게 답변을 얻으시는데 평균 ~24시간의 시간이 걸립니다. 만약 급히 연락이 필요하면 그루미 고객센터에 연락 주시기 바랍니다.",
    "Student name": "학생 이름",
    "How much is the tuition fee?": "수업료는 어떻게 되나요?",
    "My tuition requests": "나의 수업신청서",
    "Teacher": "선생님",
    "Please input EducationBackground": "학력을 입력해 주세요.",
    "Please input subject" : "과목을 선택해 주세요.",
    "Please introduce yourself": "자기소개를 작성해 주세요.",
    "Please choose teachingStyle" : "수업 스타일을 선택해 주세요.",
    "Please input teach experience": "경험을 입력해 주세요.",
    "Please input location": "위치를 입력해 주세요.",
    "Please input charge": "수업료를 입력해 주세요.",
    "Please input your contact" : "연락처를 입력해 주세요.",
    "Please input your kakao ID": "카카오톡 아이디를 입력해 주세요.",
    "Please input your Skype ID": "스카이프 아이디를 입력해 주세요.",
    "Please type in school name" : "학교명을 입력해 주세요.",
    "Please type in phone number": "핸드폰 번호를 입력해 주세요.",
    "Reactive": "다시 사용하기",
    "Select gender" : "성별",
    "Select preference tutor gender": "성별",
    "Male" : "남자",
    "Female": "여자",
    "No pref": "미선택",
    "male" : "남자",
    "female": "여자",
    "no-preference": "미선택",
    "tutor":"선생님",
    "You can reuse your latest invoice for each student.": "최근 사용된 인보이스를 다시 발행할 수 있습니다.",
    "Copy selected tuition": "선택 수업 복사",
    "Tutor level": "학력/경력",
    "Please post a new tuition request" : "새 수업요청서를 등록해 주세요.",
    "Please select a tuition request" : "수업 요청서를 선택해 주세요.",
    "Please post a new tuition request or select a previous tuition request.": "기존의 수업신청서를 선택해 주시거나, 새로 발행해 주세요.",
    "Test kakao alarm talk": "카카오 알람톡 테스트"
  });

  counterpart.registerTranslations('en', {
      "GuruMe": "GuruMe",
      "Home": "Home",
      "Student": "Student",
      "Tutor": "Tutor",
      "Academy": "Academy",
      "Blog": "Blog Forum",
      "Lecture": "Lecture",
      "Dashboard": "Dashboard",
      "GCoin": "GCoin",
      "Inbox": "Inbox",
      "Login": "Login",
      "Sign Out": "Sign Out",
      "User": "User",
      "Account Setting": "Account Setting",
      "Language": "Language",
      "Error Page": "Error Page",
      "Get Started": "Get Started",
      "Try Now": "Try Now",
      "Coming Soon": "Coming Soon",
      "About GuruMe": "About GuruMe",
      "Tuition": "Tuition",
      "How it works": "How it works",
      "Online Tuition": "Online Tuition",
      "Terms and Conditions": "Terms and Conditions",
      "Become A Tutor": "Become A Tutor",
      "Connected with kakaotalk": "Connected with kakaotalk",
      "Verify": "Verify",
      "Verified": "Verified",
      "Contact Us": "Contact Us",
      "Kakao verification number": "Kakao verification number",
      "Email verification number": "Email verification number",
      "Please refer to How it works and FAQ regarding GuruMe site usage. For any other enquiries please feel free to contact us through any of the following": "Please refer to How it works and FAQ regarding GuruMe site usage. For any other enquiries please feel free to contact us through any of the following;",
      "Support": "Support",
      "GuruMe Address": "E-wing Center Tower A Room 706, 113 Zhichun Road, Haidian, Beijing, 10084",
      "Company Number": "91110108MA0052HM8E",
      "Follow Us": "Follow Us",
      "Meet your perfect suited teacher": "Meet your perfect suited teacher",
      "Find a tutor": "Find a tutor",
      "View More Tutors": "View More Tutors",
      "View Profile": "View Profile",
      "View Tutor Profile": "View Tutor Profile",
      "Search Engine": "Search Engine",
      "Please select subject": "Please select subject",
      "Search": "Search",
      "Teaching style": "Teaching style",
      "Actively lead the student": "Actively lead the student",
      "Boost up test grades": "Boost up test grades",
      "Motivate and inspire as an older brother and sister like mentor": "Motivate and inspire as an older brother and sister like mentor",
      "Help with homework": "Help with homework",
      "Explain difficult concepts clearly": "Explain difficult concepts clearly",
      "Make the subject more interesting": "Make the subject more interesting",
      "+ more filter options": "+ more filter options",
      "Apologies, there is no tutor that matches your requests. Please contact GuruMe support team and request for recruitment of your ideal tutor.": "Apologies, there is no tutor that matches your requests. Please contact GuruMe support team and request for recruitment of your ideal tutor.",
      "Profile Image": "Profile Image",
      "Having a profile picture looks more committed": "Having a profile picture looks more committed",
      "Resize image": "Resize image",
      "Upload": "Upload",
      "Uploading": "Uploading",
      "Uploaded": "Uploaded",
      "Add second location (term-time address for current students)": "Add second location (term-time address for current students)",
      "Education Background": "Education Background",
      "View Certificate": "View Certificate",
      "Enrolment/graduation certificate available upon request": "Enrolment/graduation certificate available upon request",
      "- close filter option": "- close filter option",
      "Hourly rate": "Hourly rate",
      "1st, 2nd year undergraduate": "1st, 2nd year undergraduate",
      "3rd, 4th year undergraduate, graduate": "3rd, 4th year undergraduate, graduate",
      "graduate, master": "graduate, master",
      "master, phd": "master, phd",
      "1st criteria s": "£27-£37",
      "2nd criteria s": "£37-£47",
      "3rd criteria s": "£47-£57",
      "4th criteria s": "£57+",
      "1st criteria t": "£20-£30",
      "2nd criteria t": "£30-£40",
      "3rd criteria t": "£40-£50",
      "4th criteria t": "£50+",
      "University": "University",
      "Test": "Test",
      "Admissions": "Application",
      "English": "English",
      "Show": "Show",
      "Hide": "Hide",
      "Profile Visibility": "Profile Visibility",
      "Temporarily not teaching": "Temporarily not teaching",
      "Name": "Name",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "English Name": "English Name",
      "Email": "Email",
      "Password": "Password",
      "Confirm Password": "Confirm Password",
      "The password is not strong enough": "The password is not strong enough",
      "Password does not match": "Password does not match",
      "Current Password": "Current Password",
      "Needed to confirm your changes": "Needed to confirm your changes",
      "Please enter current password": "Please enter current password",
      "New Password": "New Password",
      "Leave blank if you dont want to change it": "Leave blank if you don't want to change it",
      "Confirm New Password": "Confirm New Password",
      "Change Password": "Change Password",
      "Tutor Registration": "Tutor Registration",
      "Student Registration": "Student Registration",
      "Parent Registration": "Parent Registration",
      "Academy Registration": "Academy Registration",
      "Please contact GuruMe support for academy registration": "Please contact GuruMe support for academy registration",
      "Already have an account": "Already have an account",
      "Email Address": "Email Address",
      "Login with Facebook": "Facebook",
      "Login with Wechat": "Wechat",
      "Remember me": "Remember me",
      "Dont have an account yet?": "Don't have an account yet?",
      "Forgot your password": "Forgot your password",
      "Forgot your password p": "Please enter the email address you have used to register with GuruMe. We will shortly send a link to the email address with which you can reset your password.",
      "Reset Password": "Reset Password",
      "Send": "Send",
      "Account Activation": "Account Activation",
      "Thanks for registering with GuruMe": "Thanks for registering with GuruMe. An email with a link to registration page has been sent to",
      "Please follow the email instruction to complete your registration": "Please follow the email instruction to complete your registration.",
      "If you haven't yet received the account activation email after 10 minutes": "If you haven't yet received the account activation email after 10 minutes",
      "Check that the email address you have written is correct": "Check that the email address you have written is correct",
      "Check the spam box": "Check the spam box",
      "If the problem still continues contact GuruMe support team": "If the problem still continues, contact GuruMe support team.",
      "Please enter a name": "Please enter a name",
      "Please enter a surname": "Please enter a surname",
      "Please enter an English Name": "Please enter an English Name",
      "Please enter an email address": "Please enter an email address",
      "Please enter a password": "Please enter a password",
      "Please confirm the password": "Please confirm the password",
      "Please enter an email and password": "Please enter an email and password",
      "Email not registered": "Email not registered",
      "Account is not activated yet Please check your email and activate your account": "Account is not activated yet. Please check your email and activate your account.",
      "Invalid email or password": "Invalid email or password",
      "Wrong password": "Wrong password",
      "School": "School",
      "Subjects of interest": "Subjects of interest",
      "Location": "Location",
      "Term-time location": "Term-time location",
      "District and town": "District and town",
      "Contact details": "Contact Details",
      "Entering phone numbers and SNS details increases the likelihood of students and parents reaching out for enquiries.": "Entering phone numbers and SNS details increases the likelihood of students and parents reaching out for enquiries.",
      "Contact details are not publicly displayed but only when requested by students and parents": "Contact details are not publicly displayed but only when requested by students and parents.",
      "Contact details are not publicly displayed but only to teachers that you have lessons with": "Contact details are not publicly displayed but only to teachers that you have lessons with.",
      "s": "'s",
      "Phone number": "Phone number",
      "Whatsapp ID": "Whatsapp ID",
      "Skype ID": "Skype ID",
      "Wechat ID": "Wechat ID",
      "Kakaotalk ID": "Kakaotalk ID",
      "Register": "Register",
      "Registering": "Registering",
      "Educational Background": "Educational Background",
      "Add education background": "Add education background",
      "Degree": "Degree",
      "High School": "High School",
      "Undergraduate": "Undergraduate",
      "Master": "Master",
      "PhD": "PhD",
      "Professional": "Professional",
      "undergraduate": "Undergraduate",
      "graduate": "Graduate",
      "professional" : "Professional",
      "University Student": "University Student",
      "Graduate": "Graduate",
      "Date": "Date",
      "Field of Study": "Field of Study",
      "Specific Major": "Specific Major",
      "If your university is not listed, temporarily select Other, ask GuruMe support to add in your university so that you can edit afterwards": "If your university is not listed, temporarily select Other, ask GuruMe support to add in your university so that you can edit afterwards",
      "Leave blank if your major name is identical to one of the major fields in the list above": "Leave blank if your major name is identical to one of the major fields in the list above",
      "Close": "Close",
      "Save": "Save",
      "Upload certificate": "Upload certificate",
      "Teaching Subjects": "Teaching Subjects",
      "Add teaching experience": "Add teaching experience",
      "Curriculum/Subject": "Curriculum/Subject",
      "Description": "Description",
      "IB Chemistry": "e.g. IB Chemistry",
      "e.g. topic taught, grade improvement and etc.": "e.g. topic taught, grade improvement and etc.",
      "About Myself": "About Myself",
      "not less than 150 words": "not less than 150 words",
      "Teaching Style": "Teaching Style",
      "Less than 2 years": "Less than 2 years",
      "More than 2 years": "More than 2 years",
      "Add your teaching experience": "Add your teaching experience",
      "Refer to Harrys as an example": "Refer to Harry's as an example",
      "Hourly Rate": "Hourly Rate",
      "Submit": "Submit",
      "Submitting": "Submitting",
      "Past Papers": "Past Papers",
      "Last Updated": "Last Updated",
      "View Tutors": "View Tutors",
      "Tuition Request": "Tuition Request",
      "Tuition Request Dashboard": "Tuition Request Dashboard",
      "Tuition Mode": "Tuition Mode",
      "Online": "Online",
      "Face to face": "Face to face",
      "SNS Mentoring": "Whatsapp mentoring",
      "No preference": "No preference",
      "Tuition Aim": "Tuition Aim",
      "Please write down as much as possible about your tuition needs and goals so that applying tutors can explain in details how they can help": "Please write down as much as possible about your tuition needs and goals so that applying tutors can explain in details how they can help.",
      "Tuition Frequency": "Tuition Frequency",
      "session(s)/week": "session(s)/week",
      "Apply": "Apply",
      "Application Example": "Application Example",
      "Sign Up": "Sign Up",
      "students have already signed up": "students have already signed up",
      "Enquiry": "Enquiry",
      "others have already applied": "others have already applied",
      "I have chosen a tutor": "I have chosen a tutor",
      "Matched": "Matched",
      "Tuition request form": "Tuition request form",
      "Submit a tuition request form and check applications from potential tutors in the inbox.": "Submit a tuition request form and check applications from potential tutors in the inbox.",
      "Appeal your class": "Appeal your class",
      "Teaching Resource": "Teaching Resource",
      "Textbook": "Textbook",
      "Past Paper": "Past Paper",
      "Tuition Bill": "Tuition Bill",
      "Tuition Bnvoice Link": "Tuition Bill Link",
      "Copy": "Copy",
      "Copy and send the link below to the student/parent:": "Copy and send the link below to the student/parent:",
      "Pending": "Pending",
      "Confirming Payment": "Confirming Payment",
      "Book Tuition": "Book Tuition",
      "Below are a selection of teachers you have taken lessons with contacted or saved Schedule a new lesson now": "Below are a selection of teachers you have taken lessons with, contacted, or saved. Schedule a new lesson now.",
      "Status": "Status",
      "In Progress": "In Progress",
      "Confirm Tuition": "Confirm Tuition",
      "Confirm": "Confirm",
      "Progress Report": "Progress Report",
      "Example Progress Report": "Example Progress Report",
      "Complete": "Complete",
      "Please make sure that you have agreed on the following tuition conditions with the student/parent": "Please make sure that you have agreed on the following tuition conditions with the student/parent.",
      "Starting/End Date": "Starting/End Date",
      "Total Hours": "Total Hours",
      "Parent Pays": "Parent Pays",
      "Tutor Receives": "Tutor Receives",
      "Commission Explained": "Commission Explained",
      "After submission you will be given a tuition bill link which you need to give your student/parent": "After submission you will be given a tuition bill link which you need to give your student/parent.",
      "View all tuitions": "View all tuitions",
      "Details": "Details",
      "Tuition Fee": "Tuition Fee",
      "Balance History": "Balance History",
      "Credit": "Credit",
      "Change Currency": "Change Currency",
      "Buy": "Buy",
      "Buy Credit": "Buy Credit",
      "Withdraw": "Withdraw",
      "Withdraw Credit": "Withdraw Credit",
      "Withdraw History": "Withdraw History",
      "Credit awaiting withdraw": "Credit awaiting withdraw",
      "Amount": "Amount",
      "Payment Method": "Payment Method",
      "Balance": "Balance",
      "Please process the bank transfer accordingly and let GuruMe know of the payment date, user name and payers name": "Please process the bank transfer accordingly and let GuruMe know of the payment date, user name and payer's name.",
      "Bank Transfer": "Bank Transfer",
      "Bank Details": "Bank Details",
      "Paypal": "Paypal",
      "Alipay": "Alipay",
      "Credit/Debit Card": "Credit/Debit Card",
      "Processing Fee": "Processing Fee",
      "Total funds": "Total funds",
      "Process payment": "Process payment",
      "Teaching Experience": "Teaching Experience",
      "Tuition Review": "Tuition Review",
      "Recommend": "Recommend",
      "Do Not Recommend": "Do Not Recommend",
      "Message": "Message",
      "Please find the tuition bill form in dashboard/tuition.": "Please find the tuition bill form in dashboard/tuition.",
      "Please note that messages can be vigilated by GuruMe for appropriate site usage": "Please note that messages can be vigilated by GuruMe for appropriate site usage",
      "Group Class": "Group Class",
      "Class Title": "Class Title",
      "Timetable": "Timetable",
      "Number of students": "Number of students",
      "Learning Outcome": "Learning Outcome",
      "Class Curriculum": "Class Curriculum",
      "Classroom": "Classroom",
      "Please visit the tutor profile and make an enquiry through the displayed contact details or site message": "Please visit the tutor profile and make an enquiry through the displayed contact details or site message",
      "Question": "Question",
      "QuestionNew": "New",
      "Ask Question": "Ask Question",
      "New": "New",
      "Claim": "Claim",
      "Answering": "Answering",
      "Answered": "Answered",
      "Question Type": "Question Type",
      "Question Title": "Question Title",
      "Photo": "Photo",
      "Up to 3 images": "Up to 3",
      "Confused about...": "Confused about...",
      "Answer": "Answer",
      "Video Link": "Video Link",
      "Explanation": "Explanation",
      "Upload answer": "Upload answer",
      "Write": "Write",
      "Read": "Read",
      "Blog Category": "Blog Category",
      "Title": "Title",
      "Author": "Author",
      "Content": "Content",
      "Introductory Paragraph": "Introductory Paragraph",
      "Thumbnail Photo": "Thumbnail Photo",
      "Post": "Post",
      "Posting": "Posting",
      "Related Articles": "Related Articles",
      "Comment": "Comment",
      "Reply": "Reply",
      "Delete": "Delete",
      "Cancel": "Cancel",
      "Need to login to leave comment": "Need to login to leave comment",
      "Back": "Back",
      "Online Lecture": "Online Lecture",
      "New Trend in Study Abroad": "New Trend in Study Abroad",
      "Lecture Category": "Lecture Category",
      "Concept": "Concept",
      "PastQuestion": "PastQuestion",
      "Topic": "Topic",
      "Image": "Image",
      "Preview": "Preview",
      "Sub Topic": "Sub Topic",
      "Keywords": "Keywords",
      "Add": "Add",
      "Youtube": "Youtube",
      "Youku": "Youku",
      "For participation enquiry, please email support@gurume.kr": "For participation enquiry, please email support@gurume.kr",
      "Event": "Event",
      "Sorry the page you are looking for doesn’t exist": "Sorry the page you are looking for doesn’t exist",
      "Please double check the website url. If the problem continues please contact GuruMe support team": "Please double check the website url. If the problem continues please contact GuruMe support team.",
      "Edit Profile": "Edit Profile",
      "Edit": "Edit",
      "Editing": "Editing",
      "For Teachers": "For Teachers",
      "Get matched with your perfect teacher": "Get matched with your perfect teacher",
      "Browse through the 300+ GuruMe tutor pool/ Submit tuition request form": "Browse through the 400+ GuruMe tutor pool/ Submit tuition request form",
      "Discuss tuition needs and conditions with tutor(s) of interest": "Consult tuition subject/hours/fee with tutor(s) of interest through messaging and displayed contact details",
      "Try out demo class": "After consultation, you may request tutor(s) for 1:1 demo class",
      "Confirm tutor/tuition": "Confirm tutor/tuition",
      "After tutor and tuition confirmation, tutor invoices parents": "After tutor and tuition confirmation, tutor invoices parents",
      "Parents pay tuition fee to GuruMe": "Parents pay tuition fee to GuruMe according to the invoice",
      "Tuition In Progress": "Tuition In Progress",
      "is provided by tutors after 1 month; upon agreement, tutors will invoice for the next months tuition fee at the same time": "is provided by tutors after 1 month to feedback on student's progress and situation",
      "End of Tuition": "End of Tuition",
      "Student/parent confirms end of tuition.": "Student/parent confirms end of tuition during which review can be submitted.",
      "Tuition review can be submitted.": "Tuition review can be submitted.",
      "Tuition fee is transferred to tutor.": "Tuition fee is transferred to tutor.",
      "are in place to ensure punctuality and commitment of both teachers and students": "are in place to ensure punctuality and commitment of both teachers and students.",
      "Some tutors may have pricing different to the following GuruMe recommendation": "Some tutors may have pricing different to the following GuruMe recommendation",
      "Above is a general guideline but tuition fee (hourly rate) is dfferent for each tutor and may change based on tuition period curriculum and offline vs online": "Above is a general guideline but tuition fee (hourly rate) is dfferent for each tutor and may change based on tuition period, curriculum and offline vs online. Exact tuition fee is adjusted under agreement with the tutor.",
      "Late": "Late",
      "Less than 10 mins": "Less than 10 mins",
      "Extra 5-10 minutes at the end or beginning of next lesson": "Extra 5-10 minutes at the end or beginning of next lesson",
      "More than 10 mins": "More than 10 mins",
      "Late time counted as lesson conducted": "Late time counted as lesson conducted",
      "Next lesson conducted free of charge by the late time amount": "Next lesson conducted free of charge by the late time amount",
      "Reschedule": "Reschedule",
      "Cancellation": "Cancellation",
      "Less than 2 hour notice": "Less than 2 hour notice",
      "1 hour counted as lesson conducted": "1 hour counted as lesson conducted",
      "Next 1 hour lesson conducted free of charge": "Next 1 hour lesson conducted free of charge",
      "Absence": "Absence",
      "No notice": "No notice",
      "0.5 hr counted as lesson conducted": "0.5 hr counted as lesson conducted",
      "Next 05 hour lesson conducted free of charge": "Next 0.5 hour lesson conducted free of charge",
      "How much cheaper is online tuition compared face to face?": "How much cheaper is online tuition compared face to face?",
      "Online tuition is usually 0.5만 cheaper than face to face tuition": "Online tuition is usually 0.5만 cheaper than face to face tuition",
      "What if a tutor suddenly says he/she cant tutor anymore?": "What if a tutor suddenly says he/she can't tutor anymore?",
      "Contact GuruMe support and you will be matched with an equally excellent tutor for similar tuition fee.": "Contact GuruMe support and you will be matched with an equally excellent tutor for similar tuition fee.",
      "Who should pay the study room cost?": "Who should pay the study room cost?",
      "Tuition fee is what tutors deserve for their teaching. Tutors paying for study room cost means reduction in their earnings. So its best tutors dont pay, but if they are willing, yes.": "Tuition fee is what tutors deserve for their teaching. Tutors paying for study room cost means reduction in their earnings. So it's best tutors don't pay, but if they are willing, yes.",
      "How effective is SNS Mentoring? I am worried my son/daughter plays with the phone instead of SNS mentoring with tutor.": "How effective is SNS Mentoring? I am worried my son/daughter plays with the phone instead of SNS mentoring with tutor.",
      "SNS Mentoring definitely has the danger of students playing the phone. If the student has the tendency to play the phone instead of using the phone to receiving SNS Mentroing, real-time tuition is recommended.": "SNS Mentoring definitely has the danger of students playing the phone. If the student has the tendency to play the phone instead of using the phone to receiving SNS Mentroing, real-time tuition is recommended.",
      "Click": "Click",
      "Online Whiteboard Touchpad": "Online Whiteboard Touchpad",
      "Science Based Lessons": "Science Based Lessons",
      "Demo Class": "Demo Class",
      "Demo Class Video": "Demo Class Video",
      "Science Based Lessons Intro": "Gooroomee is an online classroom software that offers both live video call and online whiteboard that online tuition is made very effective. Not only does this support 1:1 online tuition but group tuition up to 10 students in a class. This program doesn’t need any downloading but is currently only available on a chrome browser from a laptop/desktop and is in Korean. Please refer to the guideline in the link below.",
      "Usage Manual": "Usage Manual",
      "Essay Based Lessons": "Essay Based Lessons",
      "Essay Based Lessons Intro": "For personal statement or any essay revision tuition, GuruMe recommends using Skype for video chat Google Document for writing board. Google Document can be thought of as Microsoft word's online version but one that can be accessed and edited by several participants where any edits are spontaneously viewed by the participants",
      "More Info": "More Info",
      "Live Video Call": "Live Video Call",
      "Wechat": "Wechat",
      "Google Hangout": "Google Hangout",
      "FaceTime": "FaceTime",
      "Live Video Call p": "Sometimes, international video calls may not work so well especially in China. Firstly try again in the strongest wifi area you can find and then if the problem still continues, try one of the other options below",
      "Live Video Call p2": "If there is any issue with using above mentioned softwares, please install ‘Team Viewer’ and let GuruMe know ‘Your ID’ and ‘Password’ under ‘Remote Control’ section.",
      "Online Whiteboard": "Online Whiteboard",
      "Online Whiteboard p": "Awwapp www.awwapp.com is another whiteboard software that is optimised for use during maths, chemistry and physics like subjects that require equation writings. This program doesn’t require any downloading and can directly be used from any browser (internet explorer is best avoided as some functions are not compatible). Can be used on desktop, tablet and mobiles but most effective on a tablet or a desktop with a touchpad.",
      "Online Whiteboard p2": "The combinations of live video call and online whiteboard softwares can be mix and matched.",
      "FAQ": "FAQ",
      "FAQ Q1": "I’m trying to enter the link given by my teacher but I’m stuck on a grey background page.",
      "FAQ A1": "If nothing changes after 30 seconds, try refreshing the page. If still cannot connect, contact GuruMe support team.",
      "FAQ Q2": "I can’t hear my teacher/ My teacher’s screen is frozen. ",
      "FAQ A2": "Refresh the page",
      "FAQ Q3": "My teacher/student’s voice keeps breaking.",
      "FAQ A3": "Turn off the microphone, speaker on Gooroomee and use wechat voice call alongside.",
      "Online Whiteboard Touchpad p": "Efficiently write and annotate on online whiteboard",
      "Online Whiteboard Touchpad p2": "GuruMe recommends Huion 420 that seems to be the most value for money touchpad on the market.",
      "Online Whiteboard Touchpad p3": "Directly purchasable from Huion for $25. For purchase outside of China, shipping fee is substantial and therefore looking at country specific online marketplaces may be a good solution, e.g. ",
      "Singapore": "Singapore",
      "UK": "UK",
      "Korea": "Korea",
      "China": "China",
      "Alternatively, contact GuruMe and we will mail you the touchpad as a retailer.": "Alternatively, contact GuruMe and we will mail you the touchpad as a retailer.",
      "Touchpad Usage Manual": "Touchpad Usage Manual",
      "Touchpad Driver Installation:": "Touchpad Driver Installation:",
      "Insert AAA battery to the pen": "Insert AAA battery to the pen",
      "Click and activate the pen": "Click and activate the pen",
      "Connect the touchpad to a computer using the cable": "Connect the touchpad to a computer using the cable",
      "Tutors I have bookmarked": "Tutors I have bookmarked",
      "Blogs I have bookmarked": "Blogs I have bookmarked",
      "Lectures I have bookmarked": "Lectures I have bookmarked",
      "Questions I have bookmarked": "Questions I have bookmarked",
      "Bookmark": "Bookmark",
      "Bookmarked": "Bookmarked",
      "Unbookmark": "Unbookmark",
      "For participation as teacher inquiry, please email support@gurume.kr": "For participation as teacher inquiry, please email support@gurume.kr",
      "More reviews": "More reviews",
      "More": "More",
      "Fold": "Fold",
      "To Admin": "To Admin",
      "Admin": "Admin",
      "Skip": "Skip review this time",
      "Leave message to admin": "Leave message to admin",
      "What was the tuition focused on? Did you find it useful? Were you satisfied with your tutors teaching and punctuality?": "What was the tuition focused on? Did you find it useful? Were you satisfied with your tutors teaching and punctuality?",
      "Already agreed": "Already agreed",
      "Progress Report tip : Leave note when finish lesson. It is recommended to download mobile google docs app": "Progress Report tip : Leave note when finish lesson. It is recommended to download mobile google docs app",
      "Please click this link and leave a review": "Please click this link and leave a review.",
      "Reviews left to Admin": "Reviews left to admin",
      "Please login to your gurume account and click confirm. If you have difficulty login, Please contact gurume customer center.": "Please login to your gurume account and click confirm. If you have difficulty login, Please contact gurume customer center.",
      "Copy to clipboard": "Copy to clipboard",
      "Is This Tuition Demo?": "Is this tuition demo?",
      "If it is a demo class it will be reduced by 05 hours from the total time": "If it is a demo class, it will be reduced by -0.5 hours from the total time.",
      "It is demo": "It is demo",
      "It is not demo": "It is not demo",
      "Manage": "Manage",
      "My Blogs": "My Blogs",
      "Questions I have answered": "Questions I have answered",
      "Demo": "Demo",
      "It would be helpful if you could provide more detailed information": "It would be helpful if you could provide more detailed information.",
      "About myself": "About myself",
      "Do you like this teacher? then contact teacher!": "Do you like this teacher? then contact teacher!",
      "You are blocked": "This account is blocked. Please contact GuruMe support.",
      "Upload your student identification or diploma to become a recognized membership.": "Upload your student identification or diploma to become a recognized membership.",
      "Tuition has been completed": "Tuition has been completed.",
      "Tuition fee awaiting transfer to tutor": "Tuition fee awaiting transfer to tutor",
      "Confirm end of tuition": "Confirm end of tuition",
      "Tuition fee transfer complete": "Tuition fee transfer complete",
      "(coming Friday)": "(coming Friday)",
      "Tuition fee awaiting transfer": "Tuition fee awaiting transfer",
      "End of tuition confirmation pending": "End of tuition confirmation pending",
      "Having a certificate picture looks more committed": "Having a certificate pictures looks more committed.",
      "Call": "Call",
      "Show confirm page": "Show confirm page",
      "Add more subtopic": "Add more Sub topic",
      "Networking event": "Networking event",
      "Many classroom opportunities for absentee or graduate teachers": "Many classroom opportunities for absentee or graduate teachers",
      "Full time": "Full time",
      "Dispatch": "Dispatch",
      "Class": "Class",
      "Manage Profile": "Manage Profile",
      "Help": "Help",
      "New tuition payment system": "New tuition payment system",
      "Class completion button": "Class completion button",
      "You can notify students/parents and GuruMe that you have finished your class by just clicking button.": "You can notify students/parents and GuruMe that you have finished your class by just clicking button.",
      "You do not need to ask GuruMe to pay tuition fees.": "You do not need to ask GuruMe to pay tuition fees.",
      "Tuition will be paid every Friday.": "Tuition will be paid every Friday.",
      "After confirming of students/parents, tuition will be paid in that friday.": "After confirming of students/parents, tuition will be paid in that friday.",
      "Show detail": "Show detail",
      "MOOC Online Lecture": "MOOC online lecture",
      "Opportunity for teachers who want to get steady income and be star instructor": "Opportunity for teachers who want to get steady income and be star instructor",
      "GuruMes commission will be added to your hourly rate.": "GuruMes commission will be added to your hourly rate.",
      "N.B.": "N.B.",
      "Site Usage": "Site Usage",
      "Info": "Info",
      "My MOOC": "My MOOC",
      "You currently dont have any tuition going on": "You currently don't have any tuition going on",
      "Book more tuition with your previous tutors": "Book more tuition with your previous tutors",
      "Skype": "Skype",
      "Kakaotalk": "Kakaotalk",
      "Whatsapp": "Whatsapp",
      "Phone": "Phone",
      "Please enter your primary phone number.": "Please enter the cell phone number that is linked to KakaoTalk so that you can receive KakaoTalk notification message.",
      "Please enter your secondary phone number.": "Second phone number (for example, overseas phone number)",
      "Write in your native language first and also in English below if you want to teach foreign students too online.": "Write in your native language first and also in English below if you want to teach foreign students too online.",
      "Monthly or for agreed period of time if less than a month": "Monthly or for agreed period of time if less than a month",
      "Show more tuitions": "Show more tuitions",
      "Fold tuitions": "Fold tuitions",
      "You can get kakaotalk alarm when you fill in phone number which is linked with kakaotalk": "You can get kakaotalk alarm when you fill in phone number which is linked with kakaotalk.",
      "Please select whether the group class is end": "Please select whether the group class is end.",
      "Not finished": "Not finished.",
      "Finished": "Finished.",
      "Sending": "Sending",
      "Show all": "Show all",
      "Please enter phone number": "Please enter phone number",
      "Add certificate image": "Add certificate image",
      "It is recommended to upload certificate images": "Are you sure you really don't upload? It is recommended to upload certificate images.",
      "Write comment if you have questions": "Leave comment if you have any questions.",
      "Find a Tutor": "Find a Tutor.",
      "Find tutor fits with your goal. If you are hard to find your tutor, request new tuition!": "Find tutor who can fit with you or request new tuition!",
      "Request new tuition": "Request new tuition",
      "Thumbnail Intro": "Thumbnail Intro",
      //  "Read this stuffs for successful using in GuruMe": "그루미 이용하시는데 꼭 참고해 주시길 바랍니다."
      "Please read": "Please read",
      "Join more opportunities": "Join more opportunities",
      "Heera Shin": "Heera",
      "Manuel Im": "Nuel",
      "Harry Lee": "Harry",
      "Need help?": "Need Help?",
      "Punch line displayed on the thumbnail": "Condensed thumbnail punchline",
      "I can improve your bioscience or chemial score in confident way. I'm making basis in long-term relationship.": "생물, 화학은 자신있게 성적 올려드릴 수 있습니다. 단기보다는 장기로 천천히 기초를 다지는 스타일입니다.",
      "Add blog post": "Add blog post",
      "Add MOOC": "Add MOOC",
      "Not entered.": "Not entered.",
      "Please be sure to refer to the use of the GuruMe.": "Please be sure to refer to the use of the GuruMe.",
      "DEMO": "DEMO",
      "REVIEWS": "REVIEWS",
      "Please input detail location to find appropriate teacher.": "Please input detail location to find appropriate teacher.",
      "Please enter your your disable until date description": "Please enter your your disable until date description",
      "High school name": "High school name",
      "Select test": "Select test",
      "Period": "Period",
      "University name": "University name",
      "Homepage": "Homepage",
      "Community": "Community",
      "Networking": "Networking",
      "Admission": "Admission",
      "Admission Consulting": "Admission Consulting",
      "Fill in bank account": "e.g. 신한은행 110-338-435020 이혁진",
      "If you change your account Please update your profile and inform GuruMe customer center about your bank account changes": "If you change your account, Please update your profile and inform GuruMe customer center about your bank account changes.",
      "Class Rules": "Class Rules",
      "Depends on duration": "Depends on duration",
      "Are you academy user?": "Are you academy user?",
      "Pending invoicing": "Pending invoicing",
      "In preparation": "In preparation",
      "Student name": "Student name",
      "How much is the tuition fee?": "How much is the tuition fee?",
      "My tuition requests": "My tuition requests",
      "Teacher": "Teacher",
      "Please input EducationBackground": "Please input EducationBackground.",
      "Please input subject": "Please input subject.",
      "Please introduce yourself": "Please introduce yourself.",
      "Please choose teachingStyle": "Please choose teachingStyle.",
      "Please input teach experience": "Please input teach experience.",
      "Please input location": "Please input location.",
      "Please input charge": "Please input charge.",
      "Please input your contact": "Please input your contact.",
      "Please input your kakao ID": "Please input your kakao ID.",
      "Please input your Skype ID": "Please input your Skype ID.",
      "Reactive": "Reactive",
      "Select gender" : "Select gender",
      "Select preference tutor gender": "Select preference tutor gender",
      "Male" : "Male",
      "Female": "Female",
      "male" : "male",
      "female" : "female",
      "no-preference": "No Preference",
      "tutor":"tutor",
      "You can reuse your latest invoice for each student.": "You can reuse your latest invoice for each student.",
      "Copy selected tuition": "Copy selected tuition",
      "Tutor level": "Tutor level",
      "Your profile link will be attached Briefly describe how you can help with reference to the students tuition aim": "Your profile link will be attached. Briefly describe how you can help with reference to the students tuition aim.",
      "Please post a new tuition request" : "Please post a new tuition request.",
      "Please select a tuition request" : "Please select a tuition request.",
      "Test kakao alarm talk": "Test kakao alarm talk"
  
  });
   
  counterpart.registerTranslations('cn', {
      "GuruMe": "顾师兄",
      "Home": "首页",
      "Student": "学生",
      "Tutor": "老师",
      "Academy": "培训机构",
      "Blog": "博文",
      "Lecture": "讲座",
      "Q&A": "问答",
      "Dashboard": "个人中心",
      "GCoin": "GCoin",
      "Inbox": "站内信",
      "Login": "登录",
      "Sign Out": "退出",
      "User": "用户",
      "Account Setting": "账号设置",
      "Language": "语言",
      "Error Page": "错误页面",
      "Get Started": "Get Started",
      "Try Now": "Try Now",
      "Coming Soon": "Coming Soon",
      "About GuruMe": "关于顾师兄",
      "Tuition": "上课",
      "How it works": "使用方式",
      "Online Tuition": "在线上课",
      "Terms and Conditions": "条款",
      "Become A Tutor": "成为老师",
      "Contact Us": "联系我们",
      "Verify": "Verify",
      "Verified": "Verified",
      "Email verification number": "Email verification number",
      "Kakao verification number": "Kakao verification number",
      "Connected with kakaotalk": "Connected with kakaotalk",
      "Please refer to How it works and FAQ regarding GuruMe site usage. For any other enquiries please feel free to contact us through any of the following": "그루미 사이트 사용법은 이용방법과 FAQ 참조 부탁드리겠습니다. 그 외 문의 사항은 아래 연락처로 연락주시면 감사하겠습니다.",
      "Support": "客服",
      "GuruMe Address": "北京海淀区知春路113号银网中心A座7层706号，10084",
      "Company Number": "京ICP备16026535号",
      "Follow Us": "关注我们",
      "Meet your perfect suited teacher": "匹配你的专属导师",
      "View More Tutors": "浏览更多导师",
      "Find a tutor": "匹配老师",
      "View Profile": "展览资料",
      "View Tutor Profile": "展览老师",
      "Search Engine": "搜索器",
      "Please select subject": "请先选择科目",
      "Search": "搜索",
      "Teaching style": "教学风格",
      "Actively lead the student": "积极引导学生",
      "Boost up test grades": "提高考试成绩",
      "Motivate and inspire as an older brother and sister like mentor": "亲切鼓舞和激励式教学",
      "Help with homework": "作业辅导",
      "Explain difficult concepts clearly": "清晰解释概念",
      "Make the subject more interesting": "让课堂更有趣",
      "+ more filter options": "+ 更多筛选项",
      "- close filter option": "- 关闭筛选",
      "Apologies, there is no tutor that matches your requests. Please contact GuruMe support team and request for recruitment of your ideal tutor.": "抱歉，目前没有您想要的老师。请联系客服让客服帮您找您所理想的老师。",
      "Profile Image": "头像",
      "Having a profile picture looks more committed": "Having a profile picture looks more committed",
      "Resize image": "调整大小",
      "Upload": "上传",
      "Uploading": "正在上传",
      "Uploaded": "上传成功",
      "Add second location (term-time address for current students)": "添加第二场位置（在读学生们的学期中的位置）",
      "Education Background": "教育背景",
      "View Certificate": "查看学生证，毕业证",
      "Enrolment/graduation certificate available upon request": "Enrolment/graduation certificate available upon request",
      "Teaching Subjects": "教学科目",
      "Hourly rate": "时薪",
      "1st criteria s": "￥230-￥320",
      "2nd criteria s": "￥320-￥410",
      "3rd criteria s": "￥410-￥500",
      "4th criteria s": "￥500+",
      "1st criteria t": "￥170-￥260",
      "2nd criteria t": "￥260-￥350",
      "3rd criteria t": "￥350-￥440",
      "4th criteria t": "￥440+",
      "University": "大学",
      "Test": "考试",
      "Admissions": "升学",
      "English": "英语",
      "Show": "显示",
      "Hide": "隐藏",
      "Profile Visibility": "档案可见",
      "Temporarily not teaching": "暂不教",
      "Name": "名字",
      "First Name": "名",
      "Last Name": "姓",
      "English Name": "英文名字",
      "Email": "邮箱",
      "Password": "密码",
      "Confirm Password": "确认密码",
      "The password is not strong enough": "The password is not strong enough",
      "Password does not match": "密码不一致",
      "Current Password": "当前密码",
      "Needed to confirm your changes": "请输入信息才能确认修改",
      "Please enter current password": "请输入当前密码",
      "New Password": "新密码",
      "Leave blank if you dont want to change it": "如果不想修改，请留空",
      "Confirm New Password": "确认新密码",
      "Change Password": "改密码",
      "Tutor Registration": "老师注册",
      "Student Registration": "学生",
      "Parent Registration": "家长注册",
      "Academy Registration": "培训机构注册",
      "Please contact GuruMe support for academy registration": "Please contact GuruMe support for academy registration",
      "Already have an account": "已有账号",
      "Email Address": "邮箱地址",
      "Login with Facebook": "Facebook登陆",
      "Login with Wechat": "微信登陆",
      "Remember me": "记住",
      "Dont have an account yet?": "还没有账号？",
      "Forgot your password": "忘记密码",
      "Forgot your password p": "在下面输入您的电子邮件地址，我们将会给您发送一封包含重置密码链接的邮件。请确保您输入的电子邮件地址与您注册的电子邮件地址一致。",
      "Reset Password": "密码重置",
      "Send": "发送",
      "Account Activation": "账号激活",
      "Thanks for registering with GuruMe": "感谢您注册GuruMe。我们已将激活链接以邮件形式发送给您。",
      "Please follow the email instruction to complete your registration": "请依照邮件内的指示进行操作，完成注册。",
      "If you havent yet received the account activation email after 10 minutes": "如果十分钟后您仍未收到激活邮件，",
      "Check that the email address you have written is correct": "请检查您的邮箱地址是否填写正确。",
      "Check the spam box": "查看垃圾邮件",
      "If the problem still continues contact GuruMe support team": "如果问题没有解决，请联系GuruMe获取技术支持。",
      "Please enter a name": "请输入名字",
      "Please enter a surname": "请输入姓氏",
      "Please enter an English Name": "请输入英文名",
      "Please enter an email address": "请输入准确的邮箱地址",
      "Please enter a password": "请输入密码",
      "Please confirm the password": "请确认密码",
      "Please enter an email and password": "账号或密码不可为空",
      "Email not registered": "没注册的邮箱地址",
      "Account is not activated yet Please check your email and activate your account": "账号还没完成激活。请查看您的邮箱而激活您的帐号。",
      "Invalid email or password": "登陆邮箱或密码错",
      "Wrong password": "密码错误",
      "School": "学校",
      "Subjects of interest": "感兴趣的科目",
      "Location": "位置",
      "Term-time location": "学期位置",
      "District and town": "区路",
      "Contact details": "联系方式",
      "Entering phone numbers and SNS details increases the likelihood of students and parents reaching out for enquiries.": "填写电话号码和其他联系方式，方便学生和家长们联系咨询。",
      "Contact details are not publicly displayed but only when requested by students and parents": "联系方式不公开显示，只根据学生和家长的要求提供。",
      "Contact details are not publicly displayed but only to teachers that you have lessons with": "联系方式不公开显示，只对你上课的老师显示。",
      "s": "의",
      "Phone number": "电话号",
      "Wechat ID": "微信号",
      "Kakaotalk ID": "Kakaotalk账号",
      "Whatsapp ID": "Whatsapp账号",
      "Skype ID": "Skype账号",
      "Register": "注册",
      "Registering": "注册中",
      "Educational Background": "教育背景",
      "Add education background": "添加教育背景",
      "Degree": "学历",
      "High School": "高中",
      "Undergraduate": "本科",
      "Master": "硕士",
      "PhD": "博士",
      "Professional": "Professional",
      "undergraduate": "Undergraduate",
      "graduate": "Graduate",
      "professional" : "Professional",
      "University Student": "大学在读生",
      "Graduate": "毕业生",
      "Date": "日期",
      "Field of Study": "专业范围",
      "Specific Major": "专业方向",
      "If your university is not listed, temporarily select Other, ask GuruMe support to add in your university so that you can edit afterwards": "如果您的大学没有列出，请暂时选择其他，请求GuruMe支持添加您的大学，以便您可以在之后进行编辑",
      "Leave blank if your major name is identical to one of the major fields in the list above": "如果您的主要名称与上面列表中的某个主要字段相同，请留空",
      "Close": "关闭",
      "Save": "保存",
      "Upload certificate": "上传证件",
      "Less than 2 years": "2年以下",
      "More than 2 years": "2年以上",
      "Add teaching experience": "添加教学经验",
      "Curriculum/Subject": "课程／科目",
      "e.g. IB Chemistry": "例如：IB 化学",
      "Description": "教学内容",
      "e.g. topic taught, grade improvement and etc.": "例如：教过的科目，教学成果等",
      "Last Updated": "最后更新",
      "About Myself": "自我介绍",
      "Teaching Style": "教学风格",
      "Teaching Experience": "教学经验",
      "Add your teaching experience": "添加教学经验",
      "Refer to Harrys as an example": "请参考Harry的样本",
      "Hourly Rate": "时薪",
      "Submit": "确认",
      "Submitting": "提交中",
      "Past Papers": "往届试卷",
      "View Tutors": "查看导师",
      "Tuition Request": "上课申请",
      "Tuition Request Dashboard": "上课申请概览",
      "Tuition request form": "上课申请表",
      "Submit a tuition request form and check applications from potential tutors in the inbox.": "Submit a tuition request form and check applications from potential tutors in the inbox.",
      "Tuition Mode": "上课模式",
      "Online": "在线",
      "Face to face": "面对面",
      "SNS Mentoring": "微信指导",
      "No preference": "任何形式",
      "Tuition Aim": "教学目标",
      "Please write down as much as possible about your tuition needs and goals so that applying tutors can explain in details how they can help": "Please write down as much as possible about your tuition needs and goals so that applying tutors can explain in details how they can help.",
      "Tuition Frequency": "上课频率",
      "session(s)/week": "节／周",
      "Apply": "报名",
      "Application Example": "报名例子",
      "Sign Up": "报名",
      "students have already signed up": "students have already signed up",
      "Enquiry": "咨询",
      "others have already applied": "位已报名了",
      "I have chosen a tutor": "已确定了老师",
      "Matched": "匹配完成",
      "Appeal your class": "课堂魅力",
      "Your profile link will be attached Briefly describe how you can help with reference to the students tuition aim": "无需介绍自己。学生可以看到老师资料。请根据教学目标和学生的需要，简要介绍您怎样帮助学生以及您的课堂特色。",
      "Teaching Resource": "Teaching Resource",
      "Textbook": "Textbook",
      "Past Paper": "Past Paper",
      "Tuition Bill": "上课账单",
      "Tuition Bill Link": "上课账单链接",
      "Copy": "拷贝",
      "Copy and send the link below to the student/parent:": "复制并发送以下链接给学生／家长：",
      "Pending": "等待确认",
      "Confirming Payment": "确认收款中",
      "Book Tuition": "预约上课",
      "Below are a selection of teachers you have taken lessons with contacted or saved Schedule a new lesson now": "下面是您上过课、联系过或收藏过的教师。立即预约一个新的课时！",
      "Contact Details": "联系方式",
      "Status": "Status",
      "In Progress": "进行中",
      "Confirm Tuition": "确认上完课",
      "Confirm": "确认",
      "Progress Report": "教学进展报告",
      "Example Progress Report": "教学进展报告例子",
      "Complete": "完成",
      "Please make sure that you have agreed on the following tuition conditions with the student/parent": "请确保以下内容您已经和学生／家长协商并达成一致。",
      "Starting/End Date": "开始／结束日期",
      "Total Hours": "总时长",
      "Parent Pays": "家长支付",
      "Tutor Receives": "老师收入",
      "Commission Explained": "中介费说明",
      "After submission you will be given a tuition bill link which you need to give your student/parent": "提交后您会收到一个上课账单链接，请发送给学生／家长。",
      "View all tuitions": "全上课纪录",
      "Details": "详细",
      "Tuition Fee": "上课费用",
      "Balance History": "钱包交易明细",
      "Credit": "信用点数",
      "Change Currency": "修改货币",
      "Buy": "购买",
      "Buy Credit": "购买信用点数",
      "Withdraw": "提现",
      "Withdraw Credit": "提现信用点数",
      "Withdraw History": "提现纪录",
      "Credit awaiting withdraw": "可提现的信用点数",
      "Amount": "金额",
      "Payment Method": "支付方式",
      "Balance": "余额",
      "Please process the bank transfer as stated and let GuruMe know of the payment date, user name and payers name": "请选择上述方式转账，并将转账时间、付款人姓名和网站用户名以短信或微信形式发送给GuruMe",
      "Bank Transfer": "银行转账",
      "Bank Details": "银行信息",
      "Paypal": "Paypal",
      "Alipay": "支付宝",
      "Credit/Debit Card": "信用卡／储蓄卡",
      "Processing Fee": "手续费",
      "Total funds": "费用总计",
      "Process payment": "处理支付",
      "Tuition Review": "学生反馈",
      "Recommend": "推荐",
      "Do Not Recommend": "不推荐",
      "Message": "发送消息",
      "Please find the tuition bill form in dashboard/tuition.": "请在个人中心／上课栏提交上课账单。",
      "Please note that messages can be vigilated by GuruMe for appropriate site usage": "Please note that messages can be vigilated by GuruMe for appropriate site usage",
      "Group Class": "大班课程",
      "Class Title": "课程标题",
      "Timetable": "课程时间",
      "Number of students": "招生数",
      "Learning Outcome": "Learning Outcome",
      "Class Curriculum": "课程内容",
      "Classroom": "教室",
      "Please visit the tutor profile and make an enquiry through the displayed contact details or site message": "Please visit the tutor profile and make an enquiry through the displayed contact details or site message",
      "Question": "问题",
      "QuestionNew": "新建问题",
      "Ask Question": "提问",
      "New": "新",
      "Claim": "认领",
      "Answering": "回答中，请稍等",
      "Answered": "回答完",
      "Question Type": "问题类型",
      "Question Title": "问题题目",
      "Photo": "照片",
      "Up to 3 images": "最多3张",
      "Confused about...": "我不懂...",
      "QuestionAnswer": "回答",
      "Answer": "答案",
      "Video Link": "视频链接",
      "Explanation": "问题解释",
      "Upload answer": "提交答案",
      "Write": "新建博文",
      "Read": "阅读",
      "Blog Category": "博文分类",
      "Title": "标题",
      "Author": "作者",
      "Content": "内容",
      "Introductory Paragraph": "介绍文章",
      "Thumbnail Photo": "展示图",
      "Post": "发布",
      "Posting": "发布中",
      "Related Articles": "相关文章",
      "Comment": "评论",
      "Need to login to leave comment": "需要登录才能评论",
      "Back": "返回",
      "Online Lecture": "在线课程",
      "New Trend in Study Abroad": "出国留学的新趋势",
      "Lecture Category": "讲座分类",
      "Concept": "概念",
      "PastQuestion": "历届问题",
      "Topic": "话题",
      "Sub Topic": "仔话题",
      "Image": "图片",
      "Preview": "预习",
      "Keywords": "关键词",
      "Add": "添加",
      "Youtube": "Youtube",
      "Youku": "优酷",
      "For participation enquiry, please email support@gurume.kr": "For participation enquiry, please email support@gurume.kr",
      "Sorry the page you are looking for doesn’t exist": "很抱歉，页面访问失败。",
      "Please double check the website url. If the problem continues please contact GuruMe support team": "请确认您输入的网址是否正确。若有任何疑问，请联系顾师兄。",
      "Edit Profile": "修改资料",
      "Edit": "修改",
      "Editing": "修改中",
      "For Teachers": "老师版",
      "Get matched with your perfect teacher": "匹配你的专属导师",
      "Browse through the 300+ GuruMe tutor pool/ Submit tuition request form": "浏览GuruMe资料库中的400+位导师资料／提交上课申请表",
      "Discuss tuition needs and conditions with tutor(s) of interest": "通过发送站内信或资料给出的联系方式，向感兴趣的导师咨询教学科目／时长／费用等细节",
      "Try out demo class": "咨询后，试听1:1导师试讲课",
      "Confirm tutor/tuition": "确定导师／辅导",
      "After tutor and tuition confirmation, tutor invoices parents": "确定导师和辅导后，导师给家长发送上课账单",
      "Parents pay tuition fee to GuruMe": "家长根据上课账单向GuruMe支付费用",
      "Tuition In Progress": "上课",
      "is provided by tutors after 1 month; upon agreement, tutors will invoice for the next months tuition fee at the same time": "一个月后，导师提供进步报告，反馈学生的进步和教学情况",
      "End of Tuition": "End of Tuition",
      "Student/parent confirms end of tuition.": "Student/parent confirms end of tuition during which review can be submitted.",
      "Tuition review can be submitted.": "Tuition review can be submitted.",
      "Tuition fee is transferred to tutor.": "Tuition fee is transferred to tutor.",
      "are in place to ensure punctuality and commitment of both teachers and students": "are in place to ensure punctuality and commitment of both teachers and students.",
      "Some tutors may have pricing different to the following GuruMe recommendation": "Some tutors may have pricing different to the following GuruMe recommendation",
      "Above is a general guideline but tuition fee (hourly rate) is dfferent for each tutor and may change based on tuition period curriculum and offline vs online": "以上只是总体参考，教学费用（课时费）会因导师／教学时长／课程科目／线上或线下的选择而发生变化。具体的教学费用根据与导师的协商调整。",
      "How much cheaper is online tuition compared face to face?": "How much cheaper is online tuition compared face to face?",
      "Online tuition is usually 0.5만 cheaper than face to face tuition": "Online tuition is usually 0.5만 cheaper than face to face tuition",
      "What if a tutor suddenly says he/she cant tutor anymore?": "What if a tutor suddenly says he/she can't tutor anymore?",
      "Contact GuruMe support and you will be matched with an equally excellent tutor for similar tuition fee.": "Contact GuruMe support and you will be matched with an equally excellent tutor for similar tuition fee.",
      "Who should pay the study room cost?": "Who should pay the study room cost?",
      "Tuition fee is what tutors deserve for their teaching. Tutors paying for study room cost means reduction in their earnings. So its best tutors dont pay, but if they are willing, yes.": "Tuition fee is what tutors deserve for their teaching. Tutors paying for study room cost means reduction in their earnings. So it's best tutors don't pay, but if they are willing, yes.",
      "How effective is SNS Mentoring? I am worried my son/daughter plays with the phone instead of SNS mentoring with tutor.": "How effective is SNS Mentoring? I am worried my son/daughter plays with the phone instead of SNS mentoring with tutor.",
      "SNS Mentoring definitely has the danger of students playing the phone. If the student has the tendency to play the phone instead of using the phone to receiving SNS Mentroing, real-time tuition is recommended.": "SNS Mentoring definitely has the danger of students playing the phone. If the student has the tendency to play the phone instead of using the phone to receiving SNS Mentroing, real-time tuition is recommended.",
      "Click": "点击",
      "Online Whiteboard Touchpad": "在线白板触控板",
      "Science Based Lessons": "讲解科学学科知识的课程",
      "Demo Class": "Demo Class",
      "Demo Class Video": "课堂示例",
      "Science Based Lessons Intro": "Gooroomee是一个在线教室软件，提供视频通话和在线白板，让在线教学更加高效。它支持一对一教学和10人以下班级授课。该程序无需下载，目前只能通过谷歌浏览器在线使用，语言为韩语。使用指导链接：",
      "Usage Manual": "使用方式",
      "Essay Based Lessons": "主要讲解文章的课程",
      "Essay Based Lessons Intro": "对于个人陈述或其他文章的修改，顾师兄推荐使用Skype进行视频，同时将谷歌文件作为演示板。谷歌文件可以看作是微软文档的在线版本，但是其中的修改可以即时共享，参与课堂的学生可以同时看到修改。更多信息请点击该链接。",
      "More Info": "详细",
      "Live Video Call": "视频",
      "Wechat": "微信",
      "Google Hangout": "谷歌群聊",
      "FaceTime": "FaceTime",
      "Live Video Call p": "在中国，有时国际视频可能不太稳定。首先到一个网络信号更强的地方再试一下，如果问题没有解决，再采用以下方式：",
      "Live Video Call p2": "이 소프트웨어들을 이용하는 데 문제가 있다면, ‘Team Viewer’을 설치한 후 GuruMe에게 ‘Remote Control’섹션 아래에 있는 ‘Your ID’와 ‘Password’를 알려주세요.",
      "Online Whiteboard": "在线白板",
      "Online Whiteboard p": "Awwapp www.awwapp.com是另一个白板软件，该软件经过优化，尤其适用于数学、化学和物理这类需要写公式的学科。该程序无需下载，可通过浏览器在线使用（最好不用IE浏览器，一些功能可能不兼容）。可以在电脑、平板和手机上使用，但是最好在平板上或电脑上配合触写板使用。",
      "Online Whiteboard p2": "视频和在线白板软件可以结合使用。",
      "FAQ": "FAQ",
      "FAQ Q1": "我想点击进入老师给我的链接，但是我的页面背景是灰色的。",
      "FAQ A1": "30秒后若扔无反应，刷新界面；若依然断线，请联系GuruMe获取帮助。",
      "FAQ Q2": "我听不见老师说话/老师的屏幕卡住。",
      "FAQ A2": "刷新页面",
      "FAQ Q3": "老师／学生的声音断断续续。",
      "FAQ A3": "关掉Gooroomee的麦克风和扩音器，使用微信电话。",
      "Online Whiteboard Touchpad p": "高效搭配在线白板进行写画标注",
      "Online Whiteboard Touchpad p2": "GuruMe推荐使用Huion 420，这是目前触控板市场上性价比最高的一款产品。",
      "Online Whiteboard Touchpad p3": "可以从Huion这里购买，价格$25。在中国以外地区，运费可能很昂贵，所以推荐在当地网上商城购买。",
      "Singapore": "新加坡",
      "UK": "英国",
      "Korea": "韩国",
      "China": "中国",
      "Alternatively, contact GuruMe and we will mail you the touchpad as a retailer.": "或者，你也可以联系GuruMe，我们以零售价寄给你一个触控板。",
      "Touchpad Usage Manual": "触控板使用手册",
      "Touchpad Driver Installation:": "触控板安装：",
      "Insert AAA battery to the pen": "在触控笔内安装一节7号电池",
      "Click and activate the pen": "按动激活",
      "Connect the touchpad to a computer using the cable": "用连接线把触控板与电脑相连",
      "Tutors I have bookmarked": "我收藏的老师",
      "Blogs I have bookmarked": "Blogs I have bookmarked",
      "Lectures I have bookmarked": "Lectures I have bookmarked",
      "Questions I have bookmarked": "Questions I have bookmarked",
      "Bookmark": "收藏",
      "Bookmarked": "已收藏",
      "Unbookmark": "取消收藏",
      "For participation as teacher inquiry, please email support@gurume.kr": "作为教师查询参与，请发送电子邮件至support@gurume.kr",
      "More reviews": "更多评论",
      "More": "更多",
      "Fold": "折",
      "To Admin": "To Admin",
      "Admin": "管理员",
      "Skip": "这次跳过评论",
      "Leave message to admin": "留言给管理员",
      "What was the tuition focused on? Did you find it useful? Were you satisfied with your tutors teaching and punctuality?": "What was the tuition focused on? Did you find it useful? Were you satisfied with your tutors teaching and punctuality?",
      "Already agreed": "已经同意了",
      "Progress Report tip : Leave note when finish lesson. It is recommended to download mobile google docs app": "进度报告提示：完成课程后留下笔记。 建议下载移动谷歌文档应用程序",
      "Please click this link and leave a review": "请点击此链接并留下评论。 ",
      "Reviews left to Admin": "评论留给管理员",
      "Please login to your gurume account and click confirm. If you have difficulty login, Please contact gurume customer center.": "请登录您的gurume帐户，然后单击确认。 如果您有困难登录，请联系gurume客户中心。",
      "Copy to clipboard": "复制到剪贴板",
      "Is This Tuition Demo?": "这是学费吗？",
      "If it is a demo class it will be reduced by 05 hours from the total time": "如果是演示课，则从总时间减少-0.5小时。",
      "It is demo": "这是演示",
      "It is not demo": "它不是演示",
      "Manage": "管理",
      "My Blogs": "我的博客",
      "Questions I have answered": "我已经回答的问题",
      "Demo": "演示",
      "It would be helpful if you could provide more detailed information": "如果您能提供更详细的信息将是有帮助的。",
      "About myself": "关于我自己",
      "Do you like this teacher? then contact teacher!": "Do you like this teacher? then contact teacher!",
      "You are blocked": "你被封锁了",
      "Upload your student identification or diploma to become a recognized membership.": "上传您的学生证明或文凭，以成为获得认可的会员资格。",
      "Tuition has been completed": "教学已经完成。",
      "Tuition fee awaiting transfer to tutor": "学费等待转账给导师",
      "Confirm end of tuition": "学费确认结束等待",
      "Tuition fee transfer complete": "学费转账完成",
      "(coming Friday)": "(coming Friday)",
      "Tuition fee awaiting transfer": "学费等待转账",
      "Having a certificate picture looks more committed": "拥有证书图片看起来更加致力。",
      "Call": "呼叫",
      "Show confirm page": "显示确认页面",
      "Add more subtopic": "添加更多的子主题",
      "Networking event": "社交活动",
      "Many classroom opportunities for absentee or graduate teachers": "许多缺课或研究生教师的课堂机会",
      "Full time": "全职",
      "Dispatch": "调度",
      "Class": "课程",
      "Manage Profile": "管理配置文件",
      "Help": "帮帮我",
      "New tuition payment system": "新的学费支付系统",
      "Class completion button": "课程完成按钮",
      "You can notify students/parents and GuruMe that you have finished your class by just clicking button.": "你可以通过点击按钮来通知学生/家长和GuruMe你已经完成了课程。",
      "You do not need to ask GuruMe to pay tuition fees.": "你不需要经过GuruMe支付学费。",
      "Tuition will be paid every Friday.": "学费将在每个星期五支付。",
      "After confirming of students/parents, tuition will be paid in that friday.": "学生/家长确认后，将在该星期五支付学费。",
      "Show detail": "查看详细",
      "MOOC Online Lecture": "MOOC在线讲座",
      "Opportunity for teachers who want to get steady income and be star instructor": "希望获得稳定收入并成为明星教师的教师的机会",
      "GuruMes commission will be added to your hourly rate.": "GuruMe佣金将被添加到您的小时费率。",
      "N.B.": "警告",
      "Info": "信息",
      "My MOOC": "我的MOOC",
      "You currently dont have any tuition going on": "你目前没有任何教学正在进行",
      "Book more tuition with your previous tutors": "与以前的导师预订更多的学费",
      "Skype": "Skype",
      "Kakaotalk": "Kakaotalk",
      "Whatsapp": "Whatsapp",
      "Phone": "Phone",
      "Please enter your primary phone number.": "请输入与微信链接的手机号码，以便收到微信通知信息。",
      "Please enter your secondary phone number.": "第二个电话号码（例如，海外电话号码）",
      "Write in your native language first and also in English below if you want to teach foreign students too online.": "如果你想在网上教外国学生，请先用母语写下你的英语，并用英语写下。",
      "Monthly or for agreed period of time if less than a month": "每月或在不到一个月的同意的时间段内",
      "Show more tuitions": "显示更多的学费",
      "Fold tuitions": "折叠学费",
      "You can get kakaotalk alarm when you fill in phone number which is linked with kakaotalk": "填写与kakaotalk连接的电话号码的话，可以得到kakaotalk报警",
      "Please select whether the group class is end": "请选择小组课程是否结束",
      "Not finished": "没做完",
      "Finished": "成品",
      "Sending": "发出",
      "Show all": "显示所有",
      "Please enter phone number": "请输入电话号码",
      "Add certificate image": "添加证书图像",
      "It is recommended to upload certificate images": "你确定你真的没有上传？ 建议上传证书图像。",
      "Write comment if you have questions": "如果您有任何问题，请发表评论。",
      "Find a Tutor": "找一位导师。",
      "Find tutor fits with your goal. If you are hard to find your tutor, request new tuition!": "找到适合你的目标的导师。 如果你很难找到你的导师，要求新的教学！",
      "Request new tuition": "要求新的教学",
      "Thumbnail Intro": "缩略图介绍",
      "Please read": "请阅读",
      "Join more opportunities": "加入更多的机会",
      "Heera Shin": "Heera",
      "Harry Lee": "Harry",
      "Manuel Im": "Nuel",
      "Need help?": "需要帮助？",
      "Punch line displayed on the thumbnail": "浓缩的缩略图妙语",
      "I can improve your bioscience or chemial score in confident way. I'm making basis in long-term relationship.": "我可以自信地改善你的生物科学或化学成绩。 我正在建立长期关系的基础。",
      "Add blog post": "添加博客文章",
      "Add MOOC": "添加MOOC",
      "Not entered.": "未输入。",
      "Please be sure to refer to the use of the GuruMe.": "请务必参考使用GuruMe。",
      "DEMO": "DEMO",
      "REVIEWS": "点评",
      "Please input detail location to find appropriate teacher.": "请输入详细的位置找到合适的老师。",
      "Please enter your your disable until date description": "请输入您的禁用日期描述",
      "High school name": "高中的名字",
      "Select test": "选择测试",
      "Period": "期",
      "University name": "大学名称",
      "Homepage": "主页",
      "Community": "社区",
      "Networking": "联网",
      "Admission": "招生",
      "Admission Consulting": "招生咨询",
      "Fill in bank account": "新韩银行110-338-435020李贤金",
      "If you change your account Please update your profile and inform GuruMe customer center about your bank account changes": "如果您更改了帐户，请更新您的个人资料，并通知GuruMe客户中心关于您的银行帐户更改。",
      "Class Rules": "班级规则",
      "Depends on duration": "取决于持续时间",
      "Are you academy user?": "你是学院用户吗？",
      "Pending invoicing": "待开发票",
      "In preparation": "在准备",
      "Student name": "学生姓名",
      "Teacher": "老师",
      "Reactive": "Reactive",
      "Select gender" : "Select gender",
      "Select preference tutor gender": "Select preference tutor gender",
      "Male" : "Male",
      "Female": "Female",
      "male" : "male",
      "female" : "female",
      "no-preference": "No Preference",
      "tutor":"tutor",
      "Copy selected tuition": "Copy selected tuition",
      "Tutor level": "Tutor level",
      "Please post a new tuition request or select a previous tuition request.": "Please post a new tuition request or select a previous tuition request.",
      "Test kakao alarm talk": "테스트 카카오 알람톡"
  }
  );