import React, { Component } from 'react';
import {
    TrasHome,
    TrasUser,
    TrasPassword,
    TrasResetPassword,
    TrasForgotyourpassword,
    TrasForgotyourpasswordp,
    TrasSend,
    TrasSending

} from '../../../Translate/EachTranslateC';

import './forgotpassword.css';
import '../../../default.css';
import './common.css';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { REGEXP } from '../../../config/configAllRoutes';
import { cusUtilStart } from '../../extra/cusUtil';
import queryString from 'query-string';
import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';



// /forgot_password
class forgotpassword extends Component {

    state = {
        lang: 'ko',
        translate: {
            ko: {
                TrasEmailAddress: '이메일 주소',
                TrasValidEmail: '이메일 주소를 바르게 입력해주세요.',

            },
            en: {
                TrasEmailAddress: 'Email Address',
                TrasValidEmail: 'Please enter a valid email address',
            },
            cn: {
                TrasEmailAddress: '邮箱地址',
                TrasValidEmail: 'Please enter a valid email address',
            },
        },
        wrapperSize: '',
        inputEmail: '',
        emailValidate: false,
        sendingEmailValidation: false,
        init_interpolate: false,
        emailPatternValidate: false,
    }

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/"><TrasUser /></a></li>
                    <li><a href="/#/"><TrasPassword /></a></li>
                    <li><a href="/#/"><TrasResetPassword /></a></li>
                </ol>
            </div>
        )
    }

    componentDidMount = () => {
        cusUtilStart(this);
    }
    calScreenSizeAndResizeWrapper = (wrapperSize) => {
        this.setState({
            wrapperSize
        })
    }



    checkEnter = (e) => {
        if (e.keyCode !== 13) return;

        this.send();
    }

    send = async () => {
        this.setState({
            ...this.state,
            sendingEmailValidation: true
        })
        if (!this.state.emailPatternValidate) return;

        const postData = {
            email: this.state.inputEmail,
            lang: this.state.lang
        }
        const allUrls = await GetAjaxURL();
        const resetPwUrl = allUrls.forgetPassword + '?' + queryString.stringify(postData);
        const res = await axios.post(resetPwUrl, {}, postConfig).catch((err) => { console.log(err) });

        if (!res) return;
        if (!res.data) return;

        if (res.data.status === 'fail') {
            let msg = '정확한 이메일 주소를 입력해주세요.';
            if (this.state.lang === 'en') msg = 'Please enter a valid email address.';
            else if (this.state.lang === 'cn') msg = '请输入正确的邮箱。';

            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);

        } else {
            // SUCCESS
            let msg = '새로운 비밀번호를 설정할수 있는 링크를 이메일로 발송드렸습니다.';
            if (this.state.lang === 'en') msg = 'Email with a link to setting a new password has been sent.';
            else if (this.state.lang === 'cn') msg = '如果您的邮箱输入正确，请注意查收。';

            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);

            window.location.href = '/#/login';
        }
        this.setState({
            ...this.state,
            sendingEmailValidation: false
        })
    }

    chkValidation = async (_email) => {

        const regex = REGEXP();

        const email = _email;

        if (!email.trim()) return false;
        if (!email.trim().length > 254) return false;
        if (!regex.emailRegExp.test(email.trim())) return false;

        return true;

    }


    setInputEmail = async (e) => {
        const value = e.target.value.trim();

        const returnVal = await this.chkValidation(value);

        this.setState({
            ...this.state,
            inputEmail: value,
            emailPatternValidate: returnVal,
            init_interpolate: true
        });

    }


    createBody = () => {
        const lang = this.state.lang;

        let placeholderEmailAddress = this.state.translate.ko.TrasEmailAddress;
        let validEmailMsg = this.state.translate.ko.TrasValidEmail;
        if (lang === 'en') {
            placeholderEmailAddress = this.state.translate.en.TrasEmailAddress;
            validEmailMsg = this.state.translate.en.TrasValidEmail;
        } else if (lang === 'cn') {
            placeholderEmailAddress = this.state.translate.cn.TrasEmailAddress;
            validEmailMsg = this.state.translate.cn.TrasValidEmail;
        }


        return (

            <div className="common-body-wrapper">
                <div className="common-body-inner-wrap common-body-padding">
                    <div className="">
                        <h3 className='common-body-h3-negative common-body-margin-bot'><TrasForgotyourpassword /></h3>
                        <hr style={{ border: '0.5px solid #ffa183' }}></hr>
                        <p className='common-body-margin-top common-body-margin-bot'><TrasForgotyourpasswordp /></p>
                    </div>
                    <div className="">
                        <div className="input-group userlogin-input-email common-body-margin-bot">
                            <span className="boot-add-on" id="userlogin-username-addon">
                                <span style={{ color: "#b5b5b6" }}> <FontAwesomeIcon icon={faEnvelope} /></span>
                            </span>
                            <input type="text" className="form-control"
                                placeholder={placeholderEmailAddress} aria-describedby="userlogin-username-addon"
                                value={this.state.inputEmail}
                                onChange={this.setInputEmail}
                                onKeyDown={this.checkEnter}
                            />
                        </div>
                        {
                            !this.state.emailPatternValidate && this.state.init_interpolate ?
                                <span style={{ textAlign: "center", color: "red" }} >
                                    {/* <TrasPleaseenteranemailaddress /> */}
                                    {validEmailMsg}
                                </span>
                                : ''
                        }
                    </div>
                    <div className='fo-pw-btns'>
                        <div className='fo-pw-align'>
                            {!this.state.sendingEmailValidation ?
                                <button className="btn center-block submit-btn" onClick={this.send}><TrasSend /></button>
                                :
                                <button className="btn center-block submit-btn" onClick={this.send}>
                                    <font className="loading"><TrasSending /></font>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>

        )
    }



    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                <div className='academy-wapper-c'>
                    {this.createBody()}
                </div>
            </div>
        )
    }
    render() {
        return (
            <div className='default-wrapper'>
                {this.createHtml()}
            </div>
        )
    }
}

export default forgotpassword;