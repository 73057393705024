import React, {Component} from 'react';
import Translate from 'react-translate-component';
export class TrasGuruMe extends Component {render(){ return <Translate { ...this.props} content="GuruMe"/>}}
export class TrasHome extends Component {render(){ return <Translate { ...this.props} content="Home"/>}}
export class TrasStudent extends Component {render(){ return <Translate { ...this.props} content="Student"/>}}
export class TrasParent extends Component {render(){ return <Translate { ...this.props} content="Parent"/>}}
export class TrasTutor extends Component {render(){ return <Translate { ...this.props} content="Tutor"/>}}
export class TrasAcademy extends Component {render(){ return <Translate { ...this.props} content="Academy"/>}}
export class TrasBlog extends Component {render(){ return <Translate { ...this.props} content="Blog"/>}}
export class TrasLecture extends Component {render(){ return <Translate { ...this.props} content="Lecture"/>}}
export class TrasDashboard extends Component {render(){ return <Translate { ...this.props} content="Dashboard"/>}}
export class TrasGCoin extends Component {render(){ return <Translate { ...this.props} content="GCoin"/>}}
export class TrasInbox extends Component {render(){ return <Translate { ...this.props} content="Inbox"/>}}
export class TrasLogin extends Component {render(){ return <Translate { ...this.props} content="Login"/>}}
export class TrasSignOut extends Component {render(){ return <Translate { ...this.props} content="Sign Out"/>}}
export class TrasUser extends Component {render(){ return <Translate { ...this.props} content="User"/>}}
export class TrasAccountSetting extends Component {render(){ return <Translate { ...this.props} content="Account Setting"/>}}
export class TrasLanguage extends Component {render(){ return <Translate { ...this.props} content="Language"/>}}
export class TrasErrorPage extends Component {render(){ return <Translate { ...this.props} content="Error Page"/>}}
export class TrasGetStarted extends Component {render(){ return <Translate { ...this.props} content="Get Started"/>}}
export class TrasTryNow extends Component {render(){ return <Translate { ...this.props} content="Try Now"/>}}
export class TrasComingSoon extends Component {render(){ return <Translate { ...this.props} content="Coming Soon"/>}}
export class TrasAboutGuruMe extends Component {render(){ return <Translate { ...this.props} content="About GuruMe"/>}}
export class TrasRecruiting extends Component {render(){ return <Translate { ...this.props} content="Recruiting"/>}}
export class TrasTuition extends Component {render(){ return <Translate { ...this.props} content="Tuition"/>}}
export class TrasHowitworks extends Component {render(){ return <Translate { ...this.props} content="How it works"/>}}
export class TrasConnectedwithkakaotalk extends Component {render(){ return <Translate { ...this.props} content="Connected with kakaotalk"/>}}
export class TrasOnlineTuition extends Component {render(){ return <Translate { ...this.props} content="Online Tuition"/>}}
export class TrasTermsandConditions extends Component {render(){ return <Translate { ...this.props} content="Terms and Conditions"/>}}
export class TrasBecomeATutor extends Component {render(){ return <Translate { ...this.props} content="Become A Tutor"/>}}
export class TrasContactUs extends Component {render(){ return <Translate { ...this.props} content="Contact Us"/>}}
export class TrasVerify extends Component {render(){ return <Translate { ...this.props} content="Verify"/>}}
export class TrasVerified extends Component {render(){ return <Translate { ...this.props} content="Verified"/>}}
export class TrasEmailverificationnumber extends Component {render(){ return <Translate { ...this.props} content="Email verification number"/>}}
export class TrasKakaoverificationnumber extends Component {render(){ return <Translate { ...this.props} content="Kakao verification number"/>}}
export class TrasPleaserefertoHowitworksandFAQregardingGuruMesiteusageForanyotherenquiriespleasefeelfreetocontactusthroughanyofthefollowing extends Component {render(){ return <Translate { ...this.props} content="Please refer to How it works and FAQ regarding GuruMe site usage For any other enquiries please feel free to contact us through any of the following"/>}}
export class TrasSupport extends Component {render(){ return <Translate { ...this.props} content="Support"/>}}
export class TrasGuruMeAddress extends Component {render(){ return <Translate { ...this.props} content="GuruMe Address"/>}}
export class TrasCompanyNumber extends Component {render(){ return <Translate { ...this.props} content="Company Number"/>}}
export class TrasFollowUs extends Component {render(){ return <Translate { ...this.props} content="Follow Us"/>}}
export class TrasMeetyourperfectsuitedteacher extends Component {render(){ return <Translate { ...this.props} content="Meet your perfect suited teacher"/>}}
export class TrasViewMoreTutors extends Component {render(){ return <Translate { ...this.props} content="View More Tutors"/>}}
export class TrasFindaTutor extends Component {render(){ return <Translate { ...this.props} content="Find a Tutor"/>}}
export class TrasViewProfile extends Component {render(){ return <Translate { ...this.props} content="View Profile"/>}}
export class TrasViewTutorProfile extends Component {render(){ return <Translate { ...this.props} content="View Tutor Profile"/>}}
export class TrasSearchEngine extends Component {render(){ return <Translate { ...this.props} content="Search Engine"/>}}
export class TrasPleaseselectsubject extends Component {render(){ return <Translate { ...this.props} content="Please select subject"/>}}
export class TrasSearch extends Component {render(){ return <Translate { ...this.props} content="Search"/>}}
export class TrasTeachingstyle extends Component {render(){ return <Translate { ...this.props} content="Teaching style"/>}}
export class TrasActivelyleadthestudent extends Component {render(){ return <Translate { ...this.props} content="Actively lead the student"/>}}
export class TrasBoostuptestgrades extends Component {render(){ return <Translate { ...this.props} content="Boost up test grades"/>}}
export class TrasMotivateandinspireasanolderbrotherandsisterlikementor extends Component {render(){ return <Translate { ...this.props} content="Motivate and inspire as an older brother and sister like mentor"/>}}
export class TrasHelpwithhomework extends Component {render(){ return <Translate { ...this.props} content="Help with homework"/>}}
export class TrasExplaindifficultconceptsclearly extends Component {render(){ return <Translate { ...this.props} content="Explain difficult concepts clearly"/>}}
export class TrasMakethesubjectmoreinteresting extends Component {render(){ return <Translate { ...this.props} content="Make the subject more interesting"/>}}
export class TrasMorefilteroptions extends Component {render(){ return <Translate { ...this.props} content="More filter options"/>}}
export class TrasApologiesthereisnotutorthatmatchesyourrequestsPleasecontactGuruMesupportteamandrequestforrecruitmentofyouridealtutor extends Component {render(){ return <Translate { ...this.props} content="Apologies, there is no tutor that matches your requests. Please contact GuruMe support team and request for recruitment of your ideal tutor."/>}}
export class TrasProfileImage extends Component {render(){ return <Translate { ...this.props} content="Profile Image"/>}}
export class TrasHavingaprofilepicturelooksmorecommitted extends Component {render(){ return <Translate { ...this.props} content="Having a profile picture looks more committed"/>}}
export class TrasResizeimage extends Component {render(){ return <Translate { ...this.props} content="Resize image"/>}}
export class TrasUpload extends Component {render(){ return <Translate { ...this.props} content="Upload"/>}}
export class TrasUploading extends Component {render(){ return <Translate { ...this.props} content="Uploading"/>}}
export class TrasUploaded extends Component {render(){ return <Translate { ...this.props} content="Uploaded"/>}}
export class TrasAddsecondlocationtermtimeaddressforcurrentstudents extends Component {render(){ return <Translate { ...this.props} content="Add second location (term-time address for current students)"/>}}
export class TrasEducationBackground extends Component {render(){ return <Translate { ...this.props} content="Education Background"/>}}
export class TrasViewCertificate extends Component {render(){ return <Translate { ...this.props} content="View Certificate"/>}}
export class TrasEnrolmentgraduationcertificateavailableuponrequest extends Component {render(){ return <Translate { ...this.props} content="Enrolment/graduation certificate available upon request"/>}}
export class TrasTeachingSubjects extends Component {render(){ return <Translate { ...this.props} content="Teaching Subjects"/>}}
export class TrasClosefilteroption extends Component {render(){ return <Translate { ...this.props} content="Close filter option"/>}}
export class TrasHourlyrate extends Component {render(){ return <Translate { ...this.props} content="Hourly rate"/>}}
export class Tras1st2ndyearundergraduate extends Component {render(){ return <Translate { ...this.props} content="1st, 2nd year undergraduate"/>}}
export class Tras3rd4thyearundergraduategraduate extends Component {render(){ return <Translate { ...this.props} content="3rd, 4th year undergraduate, graduate"/>}}
export class Trasgraduatemaster extends Component {render(){ return <Translate { ...this.props} content="graduate, master"/>}}
export class Trasmasterphd extends Component {render(){ return <Translate { ...this.props} content="master, phd"/>}}
export class Tras1stcriterias extends Component {render(){ return <Translate { ...this.props} content="1st criteria s"/>}}
export class Tras2ndcriterias extends Component {render(){ return <Translate { ...this.props} content="2nd criteria s"/>}}
export class Tras3rdcriterias extends Component {render(){ return <Translate { ...this.props} content="3rd criteria s"/>}}
export class Tras4thcriterias extends Component {render(){ return <Translate { ...this.props} content="4th criteria s"/>}}
export class Tras1stcriteriat extends Component {render(){ return <Translate { ...this.props} content="1st criteria t"/>}}
export class Tras2ndcriteriat extends Component {render(){ return <Translate { ...this.props} content="2nd criteria t"/>}}
export class Tras3rdcriteriat extends Component {render(){ return <Translate { ...this.props} content="3rd criteria t"/>}}
export class Tras4thcriteriat extends Component {render(){ return <Translate { ...this.props} content="4th criteria t"/>}}
export class TrasUniversity extends Component {render(){ return <Translate { ...this.props} content="University"/>}}
export class TrasTest extends Component {render(){ return <Translate { ...this.props} content="Test"/>}}
export class TrasAdmissions extends Component {render(){ return <Translate { ...this.props} content="Admissions"/>}}
export class TrasEnglish extends Component {render(){ return <Translate { ...this.props} content="English"/>}}
export class TrasShow extends Component {render(){ return <Translate { ...this.props} content="Show"/>}}
export class TrasHide extends Component {render(){ return <Translate { ...this.props} content="Hide"/>}}
export class TrasProfileVisibility extends Component {render(){ return <Translate { ...this.props} content="Profile Visibility"/>}}
export class TrasTemporarilynotteaching extends Component {render(){ return <Translate { ...this.props} content="Temporarily not teaching"/>}}
export class TrasName extends Component {render(){ return <Translate { ...this.props} content="Name"/>}}
export class TrasFirstName extends Component {render(){ return <Translate { ...this.props} content="First Name"/>}}
export class TrasLastName extends Component {render(){ return <Translate { ...this.props} content="Last Name"/>}}
export class TrasEnglishName extends Component {render(){ return <Translate { ...this.props} content="English Name"/>}}
export class TrasEmail extends Component {render(){ return <Translate { ...this.props} content="Email"/>}}
export class TrasPassword extends Component {render(){ return <Translate { ...this.props} content="Password"/>}}
export class TrasConfirmPassword extends Component {render(){ return <Translate { ...this.props} content="Confirm Password"/>}}
export class TrasThepasswordisnotstrongenough extends Component {render(){ return <Translate { ...this.props} content="The password is not strong enough"/>}}
export class TrasPassworddoesnotmatch extends Component {render(){ return <Translate { ...this.props} content="Password does not match"/>}}
export class TrasCurrentPassword extends Component {render(){ return <Translate { ...this.props} content="Current Password"/>}}
export class TrasNeededtoconfirmyourchanges extends Component {render(){ return <Translate { ...this.props} content="Needed to confirm your changes"/>}}
export class TrasPleaseentercurrentpassword extends Component {render(){ return <Translate { ...this.props} content="Please enter current password"/>}}
export class TrasNewPassword extends Component {render(){ return <Translate { ...this.props} content="New Password"/>}}
export class TrasLeaveblankifyoudontwanttochangeit extends Component {render(){ return <Translate { ...this.props} content="Leave blank if you dont want to change it"/>}}
export class TrasConfirmNewPassword extends Component {render(){ return <Translate { ...this.props} content="Confirm New Password"/>}}
export class TrasChangePassword extends Component {render(){ return <Translate { ...this.props} content="Change Password"/>}}
export class TrasTutorRegistration extends Component {render(){ return <Translate { ...this.props} content="Tutor Registration"/>}}
export class TrasStudentRegistration extends Component {render(){ return <Translate { ...this.props} content="Student Registration"/>}}
export class TrasParentRegistration extends Component {render(){ return <Translate { ...this.props} content="Parent Registration"/>}}
export class TrasAcademyRegistration extends Component {render(){ return <Translate { ...this.props} content="Academy Registration"/>}}
export class TrasPleasecontactGuruMesupportforacademyregistration extends Component {render(){ return <Translate { ...this.props} content="Please contact GuruMe support for academy registration"/>}}
export class TrasAlreadyhaveanaccount extends Component {render(){ return <Translate { ...this.props} content="Already have an account"/>}}
export class TrasEmailAddress extends Component {render(){ return <Translate { ...this.props} content="Email Address"/>}}
export class TrasLoginwithFacebook extends Component {render(){ return <Translate { ...this.props} content="Login with Facebook"/>}}
export class TrasLoginwithWechat extends Component {render(){ return <Translate { ...this.props} content="Login with Wechat"/>}}
export class TrasRememberme extends Component {render(){ return <Translate { ...this.props} content="Remember me"/>}}
export class TrasDonthaveanaccountyet extends Component {render(){ return <Translate { ...this.props} content="Dont have an account yet?"/>}}
export class TrasForgotyourpassword extends Component {render(){ return <Translate { ...this.props} content="Forgot your password"/>}}
export class TrasForgotyourpasswordp extends Component {render(){ return <Translate { ...this.props} content="Forgot your password p"/>}}
export class TrasResetPassword extends Component {render(){ return <Translate { ...this.props} content="Reset Password"/>}}
export class TrasSend extends Component {render(){ return <Translate { ...this.props} content="Send"/>}}
export class TrasAccountActivation extends Component {render(){ return <Translate { ...this.props} content="Account Activation"/>}}
export class TrasThanksforregisteringwithGuruMe extends Component {render(){ return <Translate { ...this.props} content="Thanks for registering with GuruMe"/>}}
export class TrasPleasefollowtheemailinstructiontocompleteyourregistration extends Component {render(){ return <Translate { ...this.props} content="Please follow the email instruction to complete your registration"/>}}
export class TrasIfyouhaventyetreceivedtheaccountactivationemailafter10minutes extends Component {render(){ return <Translate { ...this.props} content="If you havent yet received the account activation email after 10 minutes"/>}}
export class TrasCheckthattheemailaddressyouhavewritteniscorrect extends Component {render(){ return <Translate { ...this.props} content="Check that the email address you have written is correct"/>}}
export class TrasCheckthespambox extends Component {render(){ return <Translate { ...this.props} content="Check the spam box"/>}}
export class TrasIftheproblemstillcontinuescontactGuruMesupportteam extends Component {render(){ return <Translate { ...this.props} content="If the problem still continues contact GuruMe support team"/>}}
export class TrasPleaseenteraname extends Component {render(){ return <Translate { ...this.props} content="Please enter a name"/>}}
export class TrasPleaseenterasurname extends Component {render(){ return <Translate { ...this.props} content="Please enter a surname"/>}}
export class TrasPleaseenteranEnglishName extends Component {render(){ return <Translate { ...this.props} content="Please enter an English Name"/>}}
export class TrasPleaseenteranemailaddress extends Component {render(){ return <Translate { ...this.props} content="Please enter an email address"/>}}
export class TrasPleaseenterapassword extends Component {render(){ return <Translate { ...this.props} content="Please enter a password"/>}}
export class TrasPleaseconfirmthepassword extends Component {render(){ return <Translate { ...this.props} content="Please confirm the password"/>}}
export class TrasPleaseenteranemailandpassword extends Component {render(){ return <Translate { ...this.props} content="Please enter an email and password"/>}}
export class TrasEmailnotregistered extends Component {render(){ return <Translate { ...this.props} content="Email not registered"/>}}
export class TrasAccountisnotactivatedyetPleasecheckyouremailandactivateyouraccount extends Component {render(){ return <Translate { ...this.props} content="Account is not activated yet Please check your email and activate your account"/>}}
export class TrasInvalidemailorpassword extends Component {render(){ return <Translate { ...this.props} content="Invalid email or password"/>}}
export class TrasWrongpassword extends Component {render(){ return <Translate { ...this.props} content="Wrong password"/>}}
export class TrasSchool extends Component {render(){ return <Translate { ...this.props} content="School"/>}}
export class TrasSubjectsofinterest extends Component {render(){ return <Translate { ...this.props} content="Subjects of interest"/>}}
export class TrasLocation extends Component {render(){ return <Translate { ...this.props} content="Location"/>}}
export class TrasTermtimelocation extends Component {render(){ return <Translate { ...this.props} content="Term-time location"/>}}
export class TrasDistrictandtown extends Component {render(){ return <Translate { ...this.props} content="District and town"/>}}
export class TrasContactdetails extends Component {render(){ return <Translate { ...this.props} content="Contact details"/>}}
export class TrasEnteringphonenumbersandSNSdetailsincreasesthelikelihoodofstudentsandparentsreachingoutforenquiries extends Component {render(){ return <Translate { ...this.props} content="Entering phone numbers and SNS details increases the likelihood of students and parents reaching out for enquiries."/>}}
export class TrasContactdetailsarenotpubliclydisplayedbutonlywhenrequestedbystudentsandparents extends Component {render(){ return <Translate { ...this.props} content="Contact details are not publicly displayed but only when requested by students and parents"/>}}
export class TrasContactdetailsarenotpubliclydisplayedbutonlytoteachersthatyouhavelessonswith extends Component {render(){ return <Translate { ...this.props} content="Contact details are not publicly displayed but only to teachers that you have lessons with"/>}}
export class TrasPhonenumber extends Component {render(){ return <Translate { ...this.props} content="Phone number"/>}}
export class TrasWhatsappID extends Component {render(){ return <Translate { ...this.props} content="Whatsapp ID"/>}}
export class TrasSkypeID extends Component {render(){ return <Translate { ...this.props} content="Skype ID"/>}}
export class TrasWechatID extends Component {render(){ return <Translate { ...this.props} content="Wechat ID"/>}}
export class TrasKakaotalkID extends Component {render(){ return <Translate { ...this.props} content="Kakaotalk ID"/>}}
export class TrasRegister extends Component {render(){ return <Translate { ...this.props} content="Register"/>}}
export class TrasRegistering extends Component {render(){ return <Translate { ...this.props} content="Registering"/>}}
export class TrasEducationalBackground extends Component {render(){ return <Translate { ...this.props} content="Educational Background"/>}}
export class TrasAddeducationbackground extends Component {render(){ return <Translate { ...this.props} content="Add education background"/>}}
export class TrasDegree extends Component {render(){ return <Translate { ...this.props} content="Degree"/>}}
export class TrasHighSchool extends Component {render(){ return <Translate { ...this.props} content="High School"/>}}
export class TrasUndergraduate extends Component {render(){ return <Translate { ...this.props} content="Undergraduate"/>}}
export class TrasMaster extends Component {render(){ return <Translate { ...this.props} content="Master"/>}}
export class TrasPhD extends Component {render(){ return <Translate { ...this.props} content="PhD"/>}}
export class TrasProfessional extends Component {render(){ return <Translate { ...this.props} content="Professional"/>}}
export class Trasundergraduate extends Component {render(){ return <Translate { ...this.props} content="undergraduate"/>}}
export class Trasgraduate extends Component {render(){ return <Translate { ...this.props} content="graduate"/>}}
export class Trasprofessional extends Component {render(){ return <Translate { ...this.props} content="professional"/>}}
export class TrasUniversityStudent extends Component {render(){ return <Translate { ...this.props} content="University Student"/>}}
export class TrasGraduate extends Component {render(){ return <Translate { ...this.props} content="Graduate"/>}}
export class TrasDate extends Component {render(){ return <Translate { ...this.props} content="Date"/>}}
export class TrasFieldofStudy extends Component {render(){ return <Translate { ...this.props} content="Field of Study"/>}}
export class TrasSpecificMajor extends Component {render(){ return <Translate { ...this.props} content="Specific Major"/>}}
export class TrasIfyouruniversityisnotlistedtemporarilyselectOtheraskGuruMesupporttoaddinyouruniversitysothatyoucaneditafterwards extends Component {render(){ return <Translate { ...this.props} content="If your university is not listed, temporarily select Other, ask GuruMe support to add in your university so that you can edit afterwards"/>}}
export class TrasLeaveblankifyourmajornameisidenticaltooneofthemajorfieldsinthelistabove extends Component {render(){ return <Translate { ...this.props} content="Leave blank if your major name is identical to one of the major fields in the list above"/>}}
export class TrasClose extends Component {render(){ return <Translate { ...this.props} content="Close"/>}}
export class TrasSave extends Component {render(){ return <Translate { ...this.props} content="Save"/>}}
export class TrasUploadcertificate extends Component {render(){ return <Translate { ...this.props} content="Upload certificate"/>}}
export class TrasAddteachingexperience extends Component {render(){ return <Translate { ...this.props} content="Add teaching experience"/>}}
export class TrasCurriculumSubject extends Component {render(){ return <Translate { ...this.props} content="Curriculum/Subject"/>}}
export class TrasDescription extends Component {render(){ return <Translate { ...this.props} content="Description"/>}}
export class TrasIBChemistry extends Component {render(){ return <Translate { ...this.props} content="IB Chemistry"/>}}
export class Trasegtopictaughtgradeimprovementandetc extends Component {render(){ return <Translate { ...this.props} content="e.g. topic taught, grade improvement and etc."/>}}
export class TrasLastUpdated extends Component {render(){ return <Translate { ...this.props} content="Last Updated"/>}}
export class TrasAboutMyself extends Component {render(){ return <Translate { ...this.props} content="About Myself"/>}}
export class Trasnotlessthan150words extends Component {render(){ return <Translate { ...this.props} content="not less than 150 words"/>}}
export class TrasTeachingStyle extends Component {render(){ return <Translate { ...this.props} content="Teaching Style"/>}}
export class TrasTeachingExperience extends Component {render(){ return <Translate { ...this.props} content="Teaching Experience"/>}}
export class TrasLessthan2years extends Component {render(){ return <Translate { ...this.props} content="Less than 2 years"/>}}
export class TrasMorethan2years extends Component {render(){ return <Translate { ...this.props} content="More than 2 years"/>}}
export class TrasAddyourteachingexperience extends Component {render(){ return <Translate { ...this.props} content="Add your teaching experience"/>}}
export class TrasRefertoHarrysasanexample extends Component {render(){ return <Translate { ...this.props} content="Refer to Harrys as an example"/>}}
export class TrasHourlyRate extends Component {render(){ return <Translate { ...this.props} content="Hourly Rate"/>}}
export class TrasSubmit extends Component {render(){ return <Translate { ...this.props} content="Submit"/>}}
export class TrasSubmitting extends Component {render(){ return <Translate { ...this.props} content="Submitting"/>}}
export class TrasViewTutors extends Component {render(){ return <Translate { ...this.props} content="View Tutors"/>}}
export class TrasTuitionRequest extends Component {render(){ return <Translate { ...this.props} content="Tuition Request"/>}}
export class TrasTuitionRequestDashboard extends Component {render(){ return <Translate { ...this.props} content="Tuition Request Dashboard"/>}}
export class TrasTuitionrequestform extends Component {render(){ return <Translate { ...this.props} content="Tuition request form"/>}}
export class TrasSubmitatuitionrequestformandcheckapplicationsfrompotentialtutorsintheinbox extends Component {render(){ return <Translate { ...this.props} content="Submit a tuition request form and check applications from potential tutors in the inbox"/>}}
export class TrasTuitionMode extends Component {render(){ return <Translate { ...this.props} content="Tuition Mode"/>}}
export class TrasOnline extends Component {render(){ return <Translate { ...this.props} content="Online"/>}}
export class TrasFacetoface extends Component {render(){ return <Translate { ...this.props} content="Face to face"/>}}
export class TrasSNSMentoring extends Component {render(){ return <Translate { ...this.props} content="SNS Mentoring"/>}}
export class TrasNopreference extends Component {render(){ return <Translate { ...this.props} content="No preference"/>}}
export class TrasTuitionAim extends Component {render(){ return <Translate { ...this.props} content="Tuition Aim"/>}}
export class TrasPleasewritedownasmuchaspossibleaboutyourtuitionneedsandgoalssothatapplyingtutorscanexplainindetailshowtheycanhelp extends Component {render(){ return <Translate { ...this.props} content="Please write down as much as possible about your tuition needs and goals so that applying tutors can explain in details how they can help"/>}}
export class TrasTuitionFrequency extends Component {render(){ return <Translate { ...this.props} content="Tuition Frequency"/>}}
export class Trassessionsweek extends Component {render(){ return <Translate { ...this.props} content="session(s)/week"/>}}
export class TrasApply extends Component {render(){ return <Translate { ...this.props} content="Apply"/>}}
export class TrasApplicationExample extends Component {render(){ return <Translate { ...this.props} content="Application Example"/>}}
export class TrasSignUp extends Component {render(){ return <Translate { ...this.props} content="Sign Up"/>}}
export class Trasstudentshavealreadysignedup extends Component {render(){ return <Translate { ...this.props} content="students have already signed up"/>}}
export class TrasEnquiry extends Component {render(){ return <Translate { ...this.props} content="Enquiry"/>}}
export class Trasothershavealreadyapplied extends Component {render(){ return <Translate { ...this.props} content="others have already applied"/>}}
export class TrasIhavechosenatutor extends Component {render(){ return <Translate { ...this.props} content="I have chosen a tutor"/>}}
export class TrasMatched extends Component {render(){ return <Translate { ...this.props} content="Matched"/>}}
export class TrasAppealyourclass extends Component {render(){ return <Translate { ...this.props} content="Appeal your class"/>}}
export class TrasYourprofilelinkwillbeattachedBrieflydescribehowyoucanhelpwithreferencetothestudentstuitionaim extends Component {render(){ return <Translate { ...this.props} content="Your profile link will be attached Briefly describe how you can help with reference to the students tuition aim"/>}}
export class TrasTeachingResource extends Component {render(){ return <Translate { ...this.props} content="Teaching Resource"/>}}
export class TrasTextbook extends Component {render(){ return <Translate { ...this.props} content="Textbook"/>}}
export class TrasPastPaper extends Component {render(){ return <Translate { ...this.props} content="Past Paper"/>}}
export class TrasTuitionBill extends Component {render(){ return <Translate { ...this.props} content="Tuition Bill"/>}}
export class TrasTuitionBillLink extends Component {render(){ return <Translate { ...this.props} content="Tuition Bill Link"/>}}
export class TrasCopy extends Component {render(){ return <Translate { ...this.props} content="Copy"/>}}
export class TrasCopyandsendthelinkbelowtothestudentparent extends Component {render(){ return <Translate { ...this.props} content="Copy and send the link below to the student/parent:"/>}}
export class TrasPending extends Component {render(){ return <Translate { ...this.props} content="Pending"/>}}
export class TrasConfirmingPayment extends Component {render(){ return <Translate { ...this.props} content="Confirming Payment"/>}}
export class TrasBookTuition extends Component {render(){ return <Translate { ...this.props} content="Book Tuition"/>}}
export class TrasBelowareaselectionofteachersyouhavetakenlessonswithcontactedorsavedScheduleanewlessonnow extends Component {render(){ return <Translate { ...this.props} content="Below are a selection of teachers you have taken lessons with contacted or saved Schedule a new lesson now"/>}}
export class TrasStatus extends Component {render(){ return <Translate { ...this.props} content="Status"/>}}
export class TrasInProgress extends Component {render(){ return <Translate { ...this.props} content="In Progress"/>}}
export class TrasConfirmTuition extends Component {render(){ return <Translate { ...this.props} content="Confirm Tuition"/>}}
export class TrasConfirm extends Component {render(){ return <Translate { ...this.props} content="Confirm"/>}}
export class TrasAgree extends Component {render(){ return <Translate { ...this.props} content="Agree"/>}}
export class TrasProgressReport extends Component {render(){ return <Translate { ...this.props} content="Progress Report"/>}}
export class TrasExampleProgressReport extends Component {render(){ return <Translate { ...this.props} content="Example Progress Report"/>}}
export class TrasComplete extends Component {render(){ return <Translate { ...this.props} content="Complete"/>}}
export class TrasPleasemakesurethatyouhaveagreedonthefollowingtuitionconditionswiththestudentparent extends Component {render(){ return <Translate { ...this.props} content="Please make sure that you have agreed on the following tuition conditions with the student/parent"/>}}
export class TrasStartingEndDate extends Component {render(){ return <Translate { ...this.props} content="Starting/End Date"/>}}
export class TrasTotalHours extends Component {render(){ return <Translate { ...this.props} content="Total Hours"/>}}
export class TrasParentPays extends Component {render(){ return <Translate { ...this.props} content="Parent Pays"/>}}
export class TrasTutorReceives extends Component {render(){ return <Translate { ...this.props} content="Tutor Receives"/>}}
export class TrasCommissionExplained extends Component {render(){ return <Translate { ...this.props} content="Commission Explained"/>}}
export class TrasAftersubmissionyouwillbegivenatuitionbilllinkwhichyouneedtogiveyourstudentparent extends Component {render(){ return <Translate { ...this.props} content="After submission you will be given a tuition bill link which you need to give your student/parent"/>}}
export class TrasViewalltuitions extends Component {render(){ return <Translate { ...this.props} content="View all tuitions"/>}}
export class TrasDetails extends Component {render(){ return <Translate { ...this.props} content="Details"/>}}
export class TrasTuitionFee extends Component {render(){ return <Translate { ...this.props} content="Tuition Fee"/>}}
export class TrasBalance extends Component {render(){ return <Translate { ...this.props} content="Balance"/>}}
export class TrasBalanceHistory extends Component {render(){ return <Translate { ...this.props} content="Balance History"/>}}
export class TrasCredit extends Component {render(){ return <Translate { ...this.props} content="Credit"/>}}
export class TrasChangeCurrency extends Component {render(){ return <Translate { ...this.props} content="Change Currency"/>}}
export class TrasBuy extends Component {render(){ return <Translate { ...this.props} content="Buy"/>}}
export class TrasBuyCredit extends Component {render(){ return <Translate { ...this.props} content="Buy Credit"/>}}
export class TrasWithdraw extends Component {render(){ return <Translate { ...this.props} content="Withdraw"/>}}
export class TrasWithdrawCredit extends Component {render(){ return <Translate { ...this.props} content="Withdraw Credit"/>}}
export class TrasWithdrawHistory extends Component {render(){ return <Translate { ...this.props} content="Withdraw History"/>}}
export class TrasCreditawaitingwithdraw extends Component {render(){ return <Translate { ...this.props} content="Credit awaiting withdraw"/>}}
export class TrasAmount extends Component {render(){ return <Translate { ...this.props} content="Amount"/>}}
export class TrasPaymentMethod extends Component {render(){ return <Translate { ...this.props} content="Payment Method"/>}}
export class TrasPleaseprocessthebanktransferasstatedusingthestudentsname extends Component {render(){ return <Translate { ...this.props} content="Please process the bank transfer as stated using the students name"/>}}
export class TrasBankTransfer extends Component {render(){ return <Translate { ...this.props} content="Bank Transfer"/>}}
export class TrasBankDetails extends Component {render(){ return <Translate { ...this.props} content="Bank Details"/>}}
export class TrasPaypal extends Component {render(){ return <Translate { ...this.props} content="Paypal"/>}}
export class TrasAlipay extends Component {render(){ return <Translate { ...this.props} content="Alipay"/>}}
export class TrasCreditDebitCard extends Component {render(){ return <Translate { ...this.props} content="Credit/Debit Card"/>}}
export class TrasProcessingFee extends Component {render(){ return <Translate { ...this.props} content="Processing Fee"/>}}
export class TrasTotalfunds extends Component {render(){ return <Translate { ...this.props} content="Total funds"/>}}
export class TrasProcesspayment extends Component {render(){ return <Translate { ...this.props} content="Process payment"/>}}
export class TrasTuitionReview extends Component {render(){ return <Translate { ...this.props} content="Tuition Review"/>}}
export class TrasRecommend extends Component {render(){ return <Translate { ...this.props} content="Recommend"/>}}
export class TrasDoNotRecommend extends Component {render(){ return <Translate { ...this.props} content="Do Not Recommend"/>}}
export class TrasMessage extends Component {render(){ return <Translate { ...this.props} content="Message"/>}}
export class TrasPleasefindthetuitionbillformindashboardtuition extends Component {render(){ return <Translate { ...this.props} content="Please find the tuition bill form in dashboard/tuition."/>}}
export class TrasPleasenotethatmessagescanbevigilatedbyGuruMeforappropriatesiteusage extends Component {render(){ return <Translate { ...this.props} content="Please note that messages can be vigilated by GuruMe for appropriate site usage"/>}}
export class TrasGroupClass extends Component {render(){ return <Translate { ...this.props} content="Group Class"/>}}
export class TrasClassTitle extends Component {render(){ return <Translate { ...this.props} content="Class Title"/>}}
export class TrasTimetable extends Component {render(){ return <Translate { ...this.props} content="Timetable"/>}}
export class TrasNumberofstudents extends Component {render(){ return <Translate { ...this.props} content="Number of students"/>}}
export class TrasLearningOutcome extends Component {render(){ return <Translate { ...this.props} content="Learning Outcome"/>}}
export class TrasClassCurriculum extends Component {render(){ return <Translate { ...this.props} content="Class Curriculum"/>}}
export class TrasClassroom extends Component {render(){ return <Translate { ...this.props} content="Classroom"/>}}
export class TrasPleasevisitthetutorprofileandmakeanenquirythroughthedisplayedcontactdetailsorsitemessage extends Component {render(){ return <Translate { ...this.props} content="Please visit the tutor profile and make an enquiry through the displayed contact details or site message"/>}}
export class TrasQuestion extends Component {render(){ return <Translate { ...this.props} content="Question"/>}}
export class TrasQuestionNew extends Component {render(){ return <Translate { ...this.props} content="QuestionNew"/>}}
export class TrasAskQuestion extends Component {render(){ return <Translate { ...this.props} content="Ask Question"/>}}
export class TrasNew extends Component {render(){ return <Translate { ...this.props} content="New"/>}}
export class TrasAnswering extends Component {render(){ return <Translate { ...this.props} content="Answering"/>}}
export class TrasAnswered extends Component {render(){ return <Translate { ...this.props} content="Answered"/>}}
export class TrasQuestionType extends Component {render(){ return <Translate { ...this.props} content="Question Type"/>}}
export class TrasQuestionTitle extends Component {render(){ return <Translate { ...this.props} content="Question Title"/>}}
export class TrasPhoto extends Component {render(){ return <Translate { ...this.props} content="Photo"/>}}
export class TrasUpto3images extends Component {render(){ return <Translate { ...this.props} content="Up to 3 images"/>}}
export class TrasConfusedabout extends Component {render(){ return <Translate { ...this.props} content="Confused about..."/>}}
export class TrasAnswer extends Component {render(){ return <Translate { ...this.props} content="Answer"/>}}
export class TrasVideoLink extends Component {render(){ return <Translate { ...this.props} content="Video Link"/>}}
export class TrasExplanation extends Component {render(){ return <Translate { ...this.props} content="Explanation"/>}}
export class TrasUploadanswer extends Component {render(){ return <Translate { ...this.props} content="Upload answer"/>}}
export class TrasWrite extends Component {render(){ return <Translate { ...this.props} content="Write"/>}}
export class TrasRead extends Component {render(){ return <Translate { ...this.props} content="Read"/>}}
export class TrasBlogCategory extends Component {render(){ return <Translate { ...this.props} content="Blog Category"/>}}
export class TrasTitle extends Component {render(){ return <Translate { ...this.props} content="Title"/>}}
export class TrasAuthor extends Component {render(){ return <Translate { ...this.props} content="Author"/>}}
export class TrasContent extends Component {render(){ return <Translate { ...this.props} content="Content"/>}}
export class TrasIntroductoryParagraph extends Component {render(){ return <Translate { ...this.props} content="Introductory Paragraph"/>}}
export class TrasThumbnailPhoto extends Component {render(){ return <Translate { ...this.props} content="Thumbnail Photo"/>}}
export class TrasPost extends Component {render(){ return <Translate { ...this.props} content="Post"/>}}
export class TrasPosting extends Component {render(){ return <Translate { ...this.props} content="Posting"/>}}
export class TrasRelatedArticles extends Component {render(){ return <Translate { ...this.props} content="Related Articles"/>}}
export class TrasComment extends Component {render(){ return <Translate { ...this.props} content="Comment"/>}}
export class TrasReply extends Component {render(){ return <Translate { ...this.props} content="Reply"/>}}
export class TrasDelete extends Component {render(){ return <Translate { ...this.props} content="Delete"/>}}
export class TrasCancel extends Component {render(){ return <Translate { ...this.props} content="Cancel"/>}}
export class TrasNeedtologintoleavecomment extends Component {render(){ return <Translate { ...this.props} content="Need to login to leave comment"/>}}
export class TrasBack extends Component {render(){ return <Translate { ...this.props} content="Back"/>}}
export class TrasOnlineLecture extends Component {render(){ return <Translate { ...this.props} content="Online Lecture"/>}}
export class TrasNewTrendinStudyAbroad extends Component {render(){ return <Translate { ...this.props} content="New Trend in Study Abroad"/>}}
export class TrasLectureCategory extends Component {render(){ return <Translate { ...this.props} content="Lecture Category"/>}}
export class TrasConcept extends Component {render(){ return <Translate { ...this.props} content="Concept"/>}}
export class TrasPastQuestion extends Component {render(){ return <Translate { ...this.props} content="PastQuestion"/>}}
export class TrasTopic extends Component {render(){ return <Translate { ...this.props} content="Topic"/>}}
export class TrasImage extends Component {render(){ return <Translate { ...this.props} content="Image"/>}}
export class TrasPreview extends Component {render(){ return <Translate { ...this.props} content="Preview"/>}}
export class TrasSubTopic extends Component {render(){ return <Translate { ...this.props} content="Sub Topic"/>}}
export class TrasKeyword extends Component {render(){ return <Translate { ...this.props} content="Keywords"/>}}
export class TrasAdd extends Component {render(){ return <Translate { ...this.props} content="Add"/>}}
export class TrasYoutube extends Component {render(){ return <Translate { ...this.props} content="Youtube"/>}}
export class TrasYouku extends Component {render(){ return <Translate { ...this.props} content="Youku"/>}}
export class TrasForparticipationenquirypleaseemailsupportgurumekr extends Component {render(){ return <Translate { ...this.props} content="For participation enquiry please email support@gurumekr"/>}}
export class TrasEvent extends Component {render(){ return <Translate { ...this.props} content="Event"/>}}
export class TrasSorrythepageyouarelookingfordoesntexist extends Component {render(){ return <Translate { ...this.props} content="Sorry the page you are looking for doesn’t exist"/>}}
export class TrasPleasedoublecheckthewebsiteurlIftheproblemcontinuespleasecontactGuruMesupportteam extends Component {render(){ return <Translate { ...this.props} content="Please double check the website url If the problem continues please contact GuruMe support team"/>}}
export class TrasEditProfile extends Component {render(){ return <Translate { ...this.props} content="Edit Profile"/>}}
export class TrasEdit extends Component {render(){ return <Translate { ...this.props} content="Edit"/>}}
export class TrasEditing extends Component {render(){ return <Translate { ...this.props} content="Editing"/>}}
export class TrasForTeachers extends Component {render(){ return <Translate { ...this.props} content="For Teachers"/>}}
export class TrasGetmatchedwithyourperfectteacher extends Component {render(){ return <Translate { ...this.props} content="Get matched with your perfect teacher"/>}}
export class TrasBrowsethroughthe300GuruMetutorpoolSubmittuitionrequestform extends Component {render(){ return <Translate { ...this.props} content="Browse through the 300+ GuruMe tutor pool/ Submit tuition request form"/>}}
export class TrasDiscusstuitionneedsandconditionswithtutorsofinterest extends Component {render(){ return <Translate { ...this.props} content="Discuss tuition needs and conditions with tutor(s) of interest"/>}}
export class TrasTryoutdemoclass extends Component {render(){ return <Translate { ...this.props} content="Try out demo class"/>}}
export class TrasConfirmtutortuition extends Component {render(){ return <Translate { ...this.props} content="Confirm tutor/tuition"/>}}
export class TrasAftertutorandtuitionconfirmationtutorinvoicesparents extends Component {render(){ return <Translate { ...this.props} content="After tutor and tuition confirmation, tutor invoices parents"/>}}
export class TrasParentspaytuitionfeetoGuruMe extends Component {render(){ return <Translate { ...this.props} content="Parents pay tuition fee to GuruMe"/>}}
export class TrasTuitionInProgress extends Component {render(){ return <Translate { ...this.props} content="Tuition In Progress"/>}}
export class Trasisprovidedbytutorsafter1monthuponagreementtutorswillinvoiceforthenextmonthstuitionfeeatthesametime extends Component {render(){ return <Translate { ...this.props} content="is provided by tutors after 1 month; upon agreement, tutors will invoice for the next months tuition fee at the same time"/>}}
export class TrasEndofTuition extends Component {render(){ return <Translate { ...this.props} content="End of Tuition"/>}}
export class TrasStudentparentconfirmsendoftuition extends Component {render(){ return <Translate { ...this.props} content="Student/parent confirms end of tuition."/>}}
export class TrasTuitionreviewcanbesubmitted extends Component {render(){ return <Translate { ...this.props} content="Tuition review can be submitted."/>}}
export class TrasTuitionfeeistransferredtotutor extends Component {render(){ return <Translate { ...this.props} content="Tuition fee is transferred to tutor."/>}}
export class Trasareinplacetoensurepunctualityandcommitmentofbothteachersandstudents extends Component {render(){ return <Translate { ...this.props} content="are in place to ensure punctuality and commitment of both teachers and students"/>}}
export class TrasSometutorsmayhavepricingdifferenttothefollowingGuruMerecommendation extends Component {render(){ return <Translate { ...this.props} content="Some tutors may have pricing different to the following GuruMe recommendation"/>}}
export class TrasAboveisageneralguidelinebuttuitionfeehourlyrateisdfferentforeachtutorandmaychangebasedontuitionperiodcurriculumandofflinevsonline extends Component {render(){ return <Translate { ...this.props} content="Above is a general guideline but tuition fee (hourly rate) is dfferent for each tutor and may change based on tuition period curriculum and offline vs online"/>}}
export class TrasLate extends Component {render(){ return <Translate { ...this.props} content="Late"/>}}
export class TrasLessthan10mins extends Component {render(){ return <Translate { ...this.props} content="Less than 10 mins"/>}}
export class TrasExtra510minutesattheendorbeginningofnextlesson extends Component {render(){ return <Translate { ...this.props} content="Extra 5-10 minutes at the end or beginning of next lesson"/>}}
export class TrasMorethan10mins extends Component {render(){ return <Translate { ...this.props} content="More than 10 mins"/>}}
export class TrasLatetimecountedaslessonconducted extends Component {render(){ return <Translate { ...this.props} content="Late time counted as lesson conducted"/>}}
export class TrasNextlessonconductedfreeofchargebythelatetimeamount extends Component {render(){ return <Translate { ...this.props} content="Next lesson conducted free of charge by the late time amount"/>}}
export class TrasReschedule extends Component {render(){ return <Translate { ...this.props} content="Reschedule"/>}}
export class Tras05hourcountedaslessonconducted extends Component {render(){ return <Translate { ...this.props} content="05 hour counted as lesson conducted"/>}}
export class TrasNext05hourlessonconductedfreeofcharge extends Component {render(){ return <Translate { ...this.props} content="Next 05 hour lesson conducted free of charge"/>}}
export class TrasCancellation extends Component {render(){ return <Translate { ...this.props} content="Cancellation"/>}}
export class TrasLessthan2hournotice extends Component {render(){ return <Translate { ...this.props} content="Less than 2 hour notice"/>}}
export class Tras1hourcountedaslessonconducted extends Component {render(){ return <Translate { ...this.props} content="1 hour counted as lesson conducted"/>}}
export class TrasNext1hourlessonconductedfreeofcharge extends Component {render(){ return <Translate { ...this.props} content="Next 1 hour lesson conducted free of charge"/>}}
export class TrasAbsence extends Component {render(){ return <Translate { ...this.props} content="Absence"/>}}
export class TrasNonotice extends Component {render(){ return <Translate { ...this.props} content="No notice"/>}}
export class TrasCountedaslessonconducted extends Component {render(){ return <Translate { ...this.props} content="Counted as lesson conducted"/>}}
export class TrasNextlessonconductedfreeofcharge extends Component {render(){ return <Translate { ...this.props} content="Next lesson conducted free of charge"/>}}
export class TrasHowmuchcheaperisonlinetuitioncomparedfacetoface extends Component {render(){ return <Translate { ...this.props} content="How much cheaper is online tuition compared face to face?"/>}}
export class TrasOnlinetuitionisusually05만cheaperthanfacetofacetuition extends Component {render(){ return <Translate { ...this.props} content="Online tuition is usually 0.5만 cheaper than face to face tuition"/>}}
export class TrasWhatifatutorsuddenlysaysheshecanttutoranymore extends Component {render(){ return <Translate { ...this.props} content="What if a tutor suddenly says he/she cant tutor anymore?"/>}}
export class TrasContactGuruMesupportandyouwillbematchedwithanequallyexcellenttutorforsimilartuitionfee extends Component {render(){ return <Translate { ...this.props} content="Contact GuruMe support and you will be matched with an equally excellent tutor for similar tuition fee."/>}}
export class TrasWhoshouldpaythestudyroomcost extends Component {render(){ return <Translate { ...this.props} content="Who should pay the study room cost?"/>}}
export class TrasTuitionfeeiswhattutorsdeservefortheirteachingTutorspayingforstudyroomcostmeansreductionintheirearningsSoitsbesttutorsdontpaybutiftheyarewillingyes extends Component {render(){ return <Translate { ...this.props} content="Tuition fee is what tutors deserve for their teaching. Tutors paying for study room cost means reduction in their earnings. So its best tutors dont pay, but if they are willing, yes."/>}}
export class TrasHoweffectiveisSNSMentoringIamworriedmysondaughterplayswiththephoneinsteadofSNSmentoringwithtutor extends Component {render(){ return <Translate { ...this.props} content="How effective is SNS Mentoring? I am worried my son/daughter plays with the phone instead of SNS mentoring with tutor."/>}}
export class TrasSNSMentoringdefinitelyhasthedangerofstudentsplayingthephoneIfthestudenthasthetendencytoplaythephoneinsteadofusingthephonetoreceivingSNSMentroingrealtimetuitionisrecommended extends Component {render(){ return <Translate { ...this.props} content="SNS Mentoring definitely has the danger of students playing the phone. If the student has the tendency to play the phone instead of using the phone to receiving SNS Mentroing, real-time tuition is recommended."/>}}
export class TrasClick extends Component {render(){ return <Translate { ...this.props} content="Click"/>}}
export class TrasOnlineWhiteboardTouchpad extends Component {render(){ return <Translate { ...this.props} content="Online Whiteboard Touchpad"/>}}
export class TrasScienceBasedLessons extends Component {render(){ return <Translate { ...this.props} content="Science Based Lessons"/>}}
export class TrasDemoClass extends Component {render(){ return <Translate { ...this.props} content="Demo Class"/>}}
export class TrasDemoClassVideo extends Component {render(){ return <Translate { ...this.props} content="Demo Class Video"/>}}
export class TrasScienceBasedLessonsIntro extends Component {render(){ return <Translate { ...this.props} content="Science Based Lessons Intro"/>}}
export class TrasUsageManual extends Component {render(){ return <Translate { ...this.props} content="Usage Manual"/>}}
export class TrasEssayBasedLessons extends Component {render(){ return <Translate { ...this.props} content="Essay Based Lessons"/>}}
export class TrasEssayBasedLessonsIntro extends Component {render(){ return <Translate { ...this.props} content="Essay Based Lessons Intro"/>}}
export class TrasMoreInfo extends Component {render(){ return <Translate { ...this.props} content="More Info"/>}}
export class TrasLiveVideoCall extends Component {render(){ return <Translate { ...this.props} content="Live Video Call"/>}}
export class TrasWechat extends Component {render(){ return <Translate { ...this.props} content="Wechat"/>}}
export class TrasGoogleHangout extends Component {render(){ return <Translate { ...this.props} content="Google Hangout"/>}}
export class TrasFaceTime extends Component {render(){ return <Translate { ...this.props} content="FaceTime"/>}}
export class TrasLiveVideoCallp extends Component {render(){ return <Translate { ...this.props} content="Live Video Call p"/>}}
export class TrasLiveVideoCallp2 extends Component {render(){ return <Translate { ...this.props} content="Live Video Call p2"/>}}
export class TrasOnlineWhiteboard extends Component {render(){ return <Translate { ...this.props} content="Online Whiteboard"/>}}
export class TrasOnlineWhiteboardp extends Component {render(){ return <Translate { ...this.props} content="Online Whiteboard p"/>}}
export class TrasOnlineWhiteboardp2 extends Component {render(){ return <Translate { ...this.props} content="Online Whiteboard p2"/>}}
export class TrasFAQ extends Component {render(){ return <Translate { ...this.props} content="FAQ"/>}}
export class TrasFAQQ1 extends Component {render(){ return <Translate { ...this.props} content="FAQ Q1"/>}}
export class TrasFAQA1 extends Component {render(){ return <Translate { ...this.props} content="FAQ A1"/>}}
export class TrasFAQQ2 extends Component {render(){ return <Translate { ...this.props} content="FAQ Q2"/>}}
export class TrasFAQA2 extends Component {render(){ return <Translate { ...this.props} content="FAQ A2"/>}}
export class TrasFAQQ3 extends Component {render(){ return <Translate { ...this.props} content="FAQ Q3"/>}}
export class TrasFAQA3 extends Component {render(){ return <Translate { ...this.props} content="FAQ A3"/>}}
export class TrasOnlineWhiteboardTouchpadp extends Component {render(){ return <Translate { ...this.props} content="Online Whiteboard Touchpad p"/>}}
export class TrasOnlineWhiteboardTouchpadp2 extends Component {render(){ return <Translate { ...this.props} content="Online Whiteboard Touchpad p2"/>}}
export class TrasOnlineWhiteboardTouchpadp3 extends Component {render(){ return <Translate { ...this.props} content="Online Whiteboard Touchpad p3"/>}}
export class TrasSingapore extends Component {render(){ return <Translate { ...this.props} content="Singapore"/>}}
export class TrasUK extends Component {render(){ return <Translate { ...this.props} content="UK"/>}}
export class TrasKorea extends Component {render(){ return <Translate { ...this.props} content="Korea"/>}}
export class TrasChina extends Component {render(){ return <Translate { ...this.props} content="China"/>}}
export class TrasAlternativelycontactGuruMeandwewillmailyouthetouchpadasaretailer extends Component {render(){ return <Translate { ...this.props} content="Alternatively, contact GuruMe and we will mail you the touchpad as a retailer."/>}}
export class TrasTouchpadUsageManual extends Component {render(){ return <Translate { ...this.props} content="Touchpad Usage Manual"/>}}
export class TrasTouchpadDriverInstallation extends Component {render(){ return <Translate { ...this.props} content="Touchpad Driver Installation:"/>}}
export class TrasInsertAAAbatterytothepen extends Component {render(){ return <Translate { ...this.props} content="Insert AAA battery to the pen"/>}}
export class TrasClickandactivatethepen extends Component {render(){ return <Translate { ...this.props} content="Click and activate the pen"/>}}
export class TrasConnectthetouchpadtoacomputerusingthecable extends Component {render(){ return <Translate { ...this.props} content="Connect the touchpad to a computer using the cable"/>}}
export class TrasTutorsIhavebookmarked extends Component {render(){ return <Translate { ...this.props} content="Tutors I have bookmarked"/>}}
export class TrasBlogsIhavebookmarked extends Component {render(){ return <Translate { ...this.props} content="Blogs I have bookmarked"/>}}
export class TrasLecturesIhavebookmarked extends Component {render(){ return <Translate { ...this.props} content="Lectures I have bookmarked"/>}}
export class TrasQuestionsIhavebookmarked extends Component {render(){ return <Translate { ...this.props} content="Questions I have bookmarked"/>}}
export class TrasBookmark extends Component {render(){ return <Translate { ...this.props} content="Bookmark"/>}}
export class TrasBookmarked extends Component {render(){ return <Translate { ...this.props} content="Bookmarked"/>}}
export class TrasUnbookmark extends Component {render(){ return <Translate { ...this.props} content="Unbookmark"/>}}
export class TrasForparticipationasteacherinquirypleaseemailsupportgurumekr extends Component {render(){ return <Translate { ...this.props} content="For participation as teacher inquiry, please email support@gurume.kr"/>}}
export class TrasMorereviews extends Component {render(){ return <Translate { ...this.props} content="More reviews"/>}}
export class TrasMore extends Component {render(){ return <Translate { ...this.props} content="More"/>}}
export class TrasFold extends Component {render(){ return <Translate { ...this.props} content="Fold"/>}}
export class TrasToAdmin extends Component {render(){ return <Translate { ...this.props} content="To Admin"/>}}
export class TrasAdmin extends Component {render(){ return <Translate { ...this.props} content="Admin"/>}}
export class TrasSkip extends Component {render(){ return <Translate { ...this.props} content="Skip"/>}}
export class TrasLeavemessagetoadmin extends Component {render(){ return <Translate { ...this.props} content="Leave message to admin"/>}}
export class TrasWhatwasthetuitionfocusedonDidyoufinditusefulWereyousatisfiedwithyourtutorsteachingandpunctuality extends Component {render(){ return <Translate { ...this.props} content="What was the tuition focused on? Did you find it useful? Were you satisfied with your tutors teaching and punctuality?"/>}}
export class TrasAlreadyagreed extends Component {render(){ return <Translate { ...this.props} content="Already agreed"/>}}
export class TrasProgressReporttipLeavenotewhenfinishlessonItisrecommendedtodownloadmobilegoogledocsapp extends Component {render(){ return <Translate { ...this.props} content="Progress Report tip : Leave note when finish lesson. It is recommended to download mobile google docs app"/>}}
export class TrasPleaseclickthislinkandleaveareview extends Component {render(){ return <Translate { ...this.props} content="Please click this link and leave a review"/>}}
export class TrasReviewslefttoAdmin extends Component {render(){ return <Translate { ...this.props} content="Reviews left to Admin"/>}}
export class TrasPleaselogintoyourgurumeaccountandclickconfirmIfyouhavedifficultyloginPleasecontactgurumecustomercenter extends Component {render(){ return <Translate { ...this.props} content="Please login to your gurume account and click confirm. If you have difficulty login, Please contact gurume customer center."/>}}
export class TrasCopytoclipboard extends Component {render(){ return <Translate { ...this.props} content="Copy to clipboard"/>}}
export class TrasIsThisTuitionDemo extends Component {render(){ return <Translate { ...this.props} content="Is This Tuition Demo?"/>}}
export class TrasIfitisademoclassitwillbereducedby05hoursfromthetotaltime extends Component {render(){ return <Translate { ...this.props} content="If it is a demo class it will be reduced by 05 hours from the total time"/>}}
export class TrasItisdemo extends Component {render(){ return <Translate { ...this.props} content="It is demo"/>}}
export class TrasItisnotdemo extends Component {render(){ return <Translate { ...this.props} content="It is not demo"/>}}
export class TrasManage extends Component {render(){ return <Translate { ...this.props} content="Manage"/>}}
export class TrasMyBlogs extends Component {render(){ return <Translate { ...this.props} content="My Blogs"/>}}
export class TrasQuestionsIhaveanswered extends Component {render(){ return <Translate { ...this.props} content="Questions I have answered"/>}}
export class TrasDemo extends Component {render(){ return <Translate { ...this.props} content="Demo"/>}}
export class TrasItwouldbehelpfulifyoucouldprovidemoredetailedinformation extends Component {render(){ return <Translate { ...this.props} content="It would be helpful if you could provide more detailed information"/>}}
export class TrasAboutmyself extends Component {render(){ return <Translate { ...this.props} content="About myself"/>}}
export class TrasDoyoulikethisteacherthencontactteacher extends Component {render(){ return <Translate { ...this.props} content="Do you like this teacher? then contact teacher!"/>}}
export class TrasYouareblocked extends Component {render(){ return <Translate { ...this.props} content="You are blocked"/>}}
export class TrasUploadyourstudentidentificationordiplomatobecomearecognizedmembership extends Component {render(){ return <Translate { ...this.props} content="Upload your student identification or diploma to become a recognized membership."/>}}
export class TrasTuitionhasbeencompleted extends Component {render(){ return <Translate { ...this.props} content="Tuition has been completed"/>}}
export class TrasTuitionfeeawaitingtransfertotutor extends Component {render(){ return <Translate { ...this.props} content="Tuition fee awaiting transfer to tutor"/>}}
export class TrasConfirmendoftuition extends Component {render(){ return <Translate { ...this.props} content="Confirm end of tuition"/>}}
export class TrasTuitionfeetransfercomplete extends Component {render(){ return <Translate { ...this.props} content="Tuition fee transfer complete"/>}}
export class TrascomingFriday extends Component {render(){ return <Translate { ...this.props} content="(coming Friday)"/>}}
export class TrasTuitionfeeawaitingtransfer extends Component {render(){ return <Translate { ...this.props} content="Tuition fee awaiting transfer"/>}}
export class TrasEndoftuitionconfirmationpending extends Component {render(){ return <Translate { ...this.props} content="End of tuition confirmation pending"/>}}
export class TrasHavingacertificatepicturelooksmorecommitted extends Component {render(){ return <Translate { ...this.props} content="Having a certificate picture looks more committed"/>}}
export class TrasCall extends Component {render(){ return <Translate { ...this.props} content="Call"/>}}
export class TrasShowconfirmpage extends Component {render(){ return <Translate { ...this.props} content="Show confirm page"/>}}
export class TrasAddMoreSubTopic extends Component {render(){ return <Translate { ...this.props} content="Add more subtopic"/>}}
export class TrasNetworkingevent extends Component {render(){ return <Translate { ...this.props} content="Networking event"/>}}
export class TrasManyclassroomopportunitiesforabsenteeorgraduateteachers extends Component {render(){ return <Translate { ...this.props} content="Many classroom opportunities for absentee or graduate teachers"/>}}
export class TrasFulltime extends Component {render(){ return <Translate { ...this.props} content="Full time"/>}}
export class TrasDispatch extends Component {render(){ return <Translate { ...this.props} content="Dispatch"/>}}
export class TrasClass extends Component {render(){ return <Translate { ...this.props} content="Class"/>}}
export class TrasManageProfile extends Component {render(){ return <Translate { ...this.props} content="Manage Profile"/>}}
export class TrasHelp extends Component {render(){ return <Translate { ...this.props} content="Help"/>}}
export class TrasNewtuitionpaymentsystem extends Component {render(){ return <Translate { ...this.props} content="New tuition payment system"/>}}
export class TrasClasscompletionbutton extends Component {render(){ return <Translate { ...this.props} content="Class completion button"/>}}
export class TrasYoucannotifystudentsparentsandGuruMethatyouhavefinishedyourclassbyjustclickingbutton extends Component {render(){ return <Translate { ...this.props} content="You can notify students/parents and GuruMe that you have finished your class by just clicking button."/>}}
export class TrasYoudonotneedtoaskGuruMetopaytuitionfees extends Component {render(){ return <Translate { ...this.props} content="You do not need to ask GuruMe to pay tuition fees."/>}}
export class TrasTuitionwillbepaideveryFriday extends Component {render(){ return <Translate { ...this.props} content="Tuition will be paid every Friday."/>}}
export class TrasAfterconfirmingofstudentsparentstuitionwillbepaidinthatfriday extends Component {render(){ return <Translate { ...this.props} content="After confirming of students/parents, tuition will be paid in that friday."/>}}
export class TrasShowdetail extends Component {render(){ return <Translate { ...this.props} content="Show detail"/>}}
export class TrasMOOCOnlineLecture extends Component {render(){ return <Translate { ...this.props} content="MOOC Online Lecture"/>}}
export class TrasOpportunityforteacherswhowanttogetsteadyincomeandbestarinstructor extends Component {render(){ return <Translate { ...this.props} content="Opportunity for teachers who want to get steady income and be star instructor"/>}}
export class TrasGuruMescommissionwillbeaddedtoyourhourlyrate extends Component {render(){ return <Translate { ...this.props} content="GuruMes commission will be added to your hourly rate."/>}}
export class TrasNB extends Component {render(){ return <Translate { ...this.props} content="N.B."/>}}
export class TrasSiteUsage extends Component {render(){ return <Translate { ...this.props} content="Site Usage"/>}}
export class TrasInfo extends Component {render(){ return <Translate { ...this.props} content="Info"/>}}
export class TrasMyMOOC extends Component {render(){ return <Translate { ...this.props} content="My MOOC"/>}}
export class TrasYoucurrentlydonthaveanytuitiongoingon extends Component {render(){ return <Translate { ...this.props} content="You currently dont have any tuition going on"/>}}
export class TrasBookmoretuitionwithyourprevioustutors extends Component {render(){ return <Translate { ...this.props} content="Book more tuition with your previous tutors"/>}}
export class TrasSkype extends Component {render(){ return <Translate { ...this.props} content="Skype"/>}}
export class TrasKakaotalk extends Component {render(){ return <Translate { ...this.props} content="Kakaotalk"/>}}
export class TrasWhatsapp extends Component {render(){ return <Translate { ...this.props} content="Whatsapp"/>}}
export class TrasPhone extends Component {render(){ return <Translate { ...this.props} content="Phone"/>}}
export class TrasPleaseenteryourprimaryphonenumber extends Component {render(){ return <Translate { ...this.props} content="Please enter your primary phone number."/>}}
export class TrasPleaseenteryoursecondaryphonenumber extends Component {render(){ return <Translate { ...this.props} content="Please enter your secondary phone number."/>}}
export class TrasWriteinyournativelanguagefirstandalsoinEnglishbelowifyouwanttoteachforeignstudentstooonline extends Component {render(){ return <Translate { ...this.props} content="Write in your native language first and also in English below if you want to teach foreign students too online."/>}}
export class TrasMonthlyorforagreedperiodoftimeiflessthanamonth extends Component {render(){ return <Translate { ...this.props} content="Monthly or for agreed period of time if less than a month"/>}}
export class TrasShowmoretuitions extends Component {render(){ return <Translate { ...this.props} content="Show more tuitions"/>}}
export class TrasFoldtuitions extends Component {render(){ return <Translate { ...this.props} content="Fold tuitions"/>}}
export class TrasYoucangetkakaotalkalarmwhenyoufillinphonenumberwhichislinkedwithkakaotalk extends Component {render(){ return <Translate { ...this.props} content="You can get kakaotalk alarm when you fill in phone number which is linked with kakaotalk"/>}}
export class TrasPleaseselectwhetherthegroupclassisend extends Component {render(){ return <Translate { ...this.props} content="Please select whether the group class is end"/>}}
export class TrasNotfinished extends Component {render(){ return <Translate { ...this.props} content="Not finished"/>}}
export class TrasFinished extends Component {render(){ return <Translate { ...this.props} content="Finished"/>}}
export class TrasSending extends Component {render(){ return <Translate { ...this.props} content="Sending"/>}}
export class TrasShowall extends Component {render(){ return <Translate { ...this.props} content="Show all"/>}}
export class TrasPleaseenterphonenumber extends Component {render(){ return <Translate { ...this.props} content="Please enter phone number"/>}}
export class TrasItisrecommendedtouploadcertificateimages extends Component {render(){ return <Translate { ...this.props} content="It is recommended to upload certificate images"/>}}
export class TrasWritecommentifyouhavequestions extends Component {render(){ return <Translate { ...this.props} content="Write comment if you have questions"/>}}
export class TrasFindtutorfitswithyourgoalIfyouarehardtofindyourtutorrequestnewtuition extends Component {render(){ return <Translate { ...this.props} content="Find tutor fits with your goal. If you are hard to find your tutor, request new tuition!"/>}}
export class TrasRequestnewtuition extends Component {render(){ return <Translate { ...this.props} content="Request new tuition"/>}}
export class TrasThumbnailIntro extends Component {render(){ return <Translate { ...this.props} content="Thumbnail Intro"/>}}
export class TrasPleaseread extends Component {render(){ return <Translate { ...this.props} content="Please read"/>}}
export class TrasJoinmoreopportunities extends Component {render(){ return <Translate { ...this.props} content="Join more opportunities"/>}}
export class TrasHeeraShin extends Component {render(){ return <Translate { ...this.props} content="Heera Shin"/>}}
export class TrasHarryLee extends Component {render(){ return <Translate { ...this.props} content="Harry Lee"/>}}
export class TrasManuelIm extends Component {render(){ return <Translate { ...this.props} content="Manuel Im"/>}}
export class TrasNeedhelp extends Component {render(){ return <Translate { ...this.props} content="Need help?"/>}}
export class TrasPunchlinedisplayedonthethumbnail extends Component {render(){ return <Translate { ...this.props} content="Punch line displayed on the thumbnail"/>}}
export class TrasIcanimproveyourbioscienceorchemialscoreinconfidentwayImmakingbasisinlongtermrelationship extends Component {render(){ return <Translate { ...this.props} content="I can improve your bioscience or chemial score in confident way. I'm making basis in long-term relationship."/>}}
export class TrasAddblogpost extends Component {render(){ return <Translate { ...this.props} content="Add blog post"/>}}
export class TrasAddMOOC extends Component {render(){ return <Translate { ...this.props} content="Add MOOC"/>}}
export class TrasNotentered extends Component {render(){ return <Translate { ...this.props} content="Not entered."/>}}
export class TrasPleasebesuretorefertotheuseoftheGuruMe extends Component {render(){ return <Translate { ...this.props} content="Please be sure to refer to the use of the GuruMe."/>}}
export class TrasDEMO extends Component {render(){ return <Translate { ...this.props} content="DEMO"/>}}
export class TrasREVIEWS extends Component {render(){ return <Translate { ...this.props} content="REVIEWS"/>}}
export class TrasPleaseinputdetaillocationtofindappropriateteacher extends Component {render(){ return <Translate { ...this.props} content="Please input detail location to find appropriate teacher."/>}}
export class TrasPleaseenteryouryourdisableuntildatedescription extends Component {render(){ return <Translate { ...this.props} content="Please enter your your disable until date description"/>}}
export class TrasHighschoolname extends Component {render(){ return <Translate { ...this.props} content="High school name"/>}}
export class TrasSelecttest extends Component {render(){ return <Translate { ...this.props} content="Select test"/>}}
export class TrasPeriod extends Component {render(){ return <Translate { ...this.props} content="Period"/>}}
export class TrasUniversityname extends Component {render(){ return <Translate { ...this.props} content="University name"/>}}
export class TrasHomepage extends Component {render(){ return <Translate { ...this.props} content="Homepage"/>}}
export class TrasCommunity extends Component {render(){ return <Translate { ...this.props} content="Community"/>}}
export class TrasNetworking extends Component {render(){ return <Translate { ...this.props} content="Networking"/>}}
export class TrasAdmission extends Component {render(){ return <Translate { ...this.props} content="Admission"/>}}
export class TrasAdmissionConsulting extends Component {render(){ return <Translate { ...this.props} content="Admission Consulting"/>}}
export class TrasFillinbankaccount extends Component {render(){ return <Translate { ...this.props} content="Fill in bank account"/>}}
export class TrasIfyouchangeyouraccountPleaseupdateyourprofileandinformGuruMecustomercenteraboutyourbankaccountchanges extends Component {render(){ return <Translate { ...this.props} content="If you change your account Please update your profile and inform GuruMe customer center about your bank account changes"/>}}
export class TrasClassRules extends Component {render(){ return <Translate { ...this.props} content="Class Rules"/>}}
export class TrasDependsonduration extends Component {render(){ return <Translate { ...this.props} content="Depends on duration"/>}}
export class TrasAreyouacademyuser extends Component {render(){ return <Translate { ...this.props} content="Are you academy user?"/>}}
export class TrasPendinginvoicing extends Component {render(){ return <Translate { ...this.props} content="Pending invoicing"/>}}
export class TrasInpreparation extends Component {render(){ return <Translate { ...this.props} content="In preparation"/>}}
export class TrasItcantakesabout1daytogetresponsefromteacherPleasecontactGuruMeifyouneedurgenthelp extends Component {render(){ return <Translate { ...this.props} content="It can takes about ~1 day to get response from teacher. Please contact GuruMe if you need urgent help."/>}}
export class TrasStudentname extends Component {render(){ return <Translate { ...this.props} content="Student name"/>}}
export class TrasHowmuchisthetuitionfee extends Component {render(){ return <Translate { ...this.props} content="How much is the tuition fee?"/>}}
export class TrasMytuitionrequests extends Component {render(){ return <Translate { ...this.props} content="My tuition requests"/>}}
export class TrasTeacher extends Component {render(){ return <Translate { ...this.props} content="Teacher"/>}}
export class TrasPleaseinputEducationBackground extends Component {render(){ return <Translate { ...this.props} content="Please input EducationBackground"/>}}
export class TrasPleaseinputsubject extends Component {render(){ return <Translate { ...this.props} content="Please input subject"/>}}
export class TrasPleaseintroduceyourself extends Component {render(){ return <Translate { ...this.props} content="Please introduce yourself"/>}}
export class TrasPleasechooseteachingStyle extends Component {render(){ return <Translate { ...this.props} content="Please choose teachingStyle"/>}}
export class TrasPleaseinputteachexperience extends Component {render(){ return <Translate { ...this.props} content="Please input teach experience"/>}}
export class TrasPleaseinputlocation extends Component {render(){ return <Translate { ...this.props} content="Please input location"/>}}
export class TrasPleaseinputcharge extends Component {render(){ return <Translate { ...this.props} content="Please input charge"/>}}
export class TrasPleaseinputyourcontact extends Component {render(){ return <Translate { ...this.props} content="Please input your contact"/>}}
export class TrasPleaseinputyourkakaoID extends Component {render(){ return <Translate { ...this.props} content="Please input your kakao ID"/>}}
export class TrasPleaseinputyourSkypeID extends Component {render(){ return <Translate { ...this.props} content="Please input your Skype ID"/>}}
export class TrasPleasetypeinschoolname extends Component {render(){ return <Translate { ...this.props} content="Please type in school name"/>}}
export class TrasPleasetypeinphonenumber extends Component {render(){ return <Translate { ...this.props} content="Please type in phone number"/>}}
export class TrasReactive extends Component {render(){ return <Translate { ...this.props} content="Reactive"/>}}
export class TrasSelectgender extends Component {render(){ return <Translate { ...this.props} content="Select gender"/>}}
export class TrasSelectpreferencetutorgender extends Component {render(){ return <Translate { ...this.props} content="Select preference tutor gender"/>}}
export class TrasMale extends Component {render(){ return <Translate { ...this.props} content="Male"/>}}
export class TrasFemale extends Component {render(){ return <Translate { ...this.props} content="Female"/>}}
export class TrasNopref extends Component {render(){ return <Translate { ...this.props} content="No pref"/>}}
export class Trasmale extends Component {render(){ return <Translate { ...this.props} content="male"/>}}
export class Trasfemale extends Component {render(){ return <Translate { ...this.props} content="female"/>}}
export class Trasnopreference extends Component {render(){ return <Translate { ...this.props} content="no-preference"/>}}
export class Trastutor extends Component {render(){ return <Translate { ...this.props} content="tutor"/>}}
export class TrasYoucanreuseyourlatestinvoiceforeachstudent extends Component {render(){ return <Translate { ...this.props} content="You can reuse your latest invoice for each student."/>}}
export class TrasCopyselectedtuition extends Component {render(){ return <Translate { ...this.props} content="Copy selected tuition"/>}}
export class TrasTutorlevel extends Component {render(){ return <Translate { ...this.props} content="Tutor level"/>}}
export class TrasPleasepostanewtuitionrequest extends Component {render(){ return <Translate { ...this.props} content="Please post a new tuition request"/>}}
export class TrasPleaseselectatuitionrequest extends Component {render(){ return <Translate { ...this.props} content="Please select a tuition request"/>}}
export class TrasPleasepostanewtuitionrequestorselectaprevioustuitionrequest extends Component {render(){ return <Translate { ...this.props} content="Please post a new tuition request or select a previous tuition request."/>}}
export class TrasTestkakaoalarmtalk extends Component {render(){ return <Translate { ...this.props} content="Test kakao alarm talk"/>}}