const staticGroupClassList = [{
        isOld : true,
        title: "2018 겨울 IB 그룹수업 공고",
        period: "2018/12/17 - 2019/01/06 (3주)",
        location: "그루미 스터디룸 (역삼역)",
        teachers: [],
        imageUrl: "/images/group-class/2018/18_vacation_group_class_thumbnail.jpg",
        redirectUrl: "/group_class/2018/ib_winter"
    },
    {
        isOld : true,
        title: "2018 여름 IB 그룹수업 공고",
        period: "2018/06/25 - 2018/08/03",
        location: "그루미 스터디룸 (역삼역)",
        teachers: [],
        imageUrl: "/images/group-class/2018/group_class_2018_summer_thumbnail.jpg",
        redirectUrl: "/group_class/2018/ib_summer"
    },
    {
        isOld : true,
        title: "제주 IB 수업",
        period: "-",
        location: "이노에듀/삼정",
        teachers: [
            "[수학] 민윤기 - 옥스퍼드 수학과",
            "[영어, 리딩, 롸이팅] 김성민 - 옥스퍼드 문학과",
            "[화학 + (iGCSE 생물)] 이혁진 - 옥스퍼드 생화학과",
            "[역사, 철학, ToK] 조아영 - 케임브리지 철학과"
        ],
        imageUrl: "/images/group-class/2018/0223-ib-jeju/2018_ib_jeju_group_class_thumbnail.jpg",
        redirectUrl: "/group_class/ib-jeju"
    },
    {
        isOld : true,
        title: "경기외고 IB 생물 수업",
        period: "2018. 02. 25 - 2018. 06. 24",
        location: "토즈스터디센터 군포당동센터",
        teachers: [
            "[Bio] 이상진 - 케임브리지 생물학",
        ],
        imageUrl: "/images/group-class/gurume-ib-gyeonggi-hs-group-class-thumbnail.jpg",
        redirectUrl: "/group_class/ib-gyeonggi-foreign-language-high-school",
    },
    {
        isOld : true,
        title: "송도 IB 수업",
        period: "2018. 01. 29 - 2018. 06. 01",
        location: "커피랑도서관",
        teachers: [
            "[Maths HL] 방원준 - 워릭 수학과",
            "[English Lang & Lit SL] 최윤지 - 서울대 영문학",
            "[Bio HL] 이상진 - 케임브리지 생물학",
            "[Chem HL] 이혁진 - 옥스퍼드 생화학"
        ],
        imageUrl: "/images/group-class/gurume-ib-songdo-group-class-thumbnail.jpg",
        redirectUrl: "/group_class/ib-songdo",
    },
    {
        isOld : true,
        title: "2017 겨울특강",
        period: "상세보기를 눌러 확인하세요.",
        location: "교대 토즈, 온라인",
        teachers: [
            "[경제] 이가영 - NYU",
            "[심리학] 윤효원 - 서울대",
            "[한국어] 윤지영 - UC 샌디에고",
            "[영어] 사혜원 - 옥스퍼드",
            "[물리] 조승현 - 옥스퍼드",
            "[경제] 엄의용 - 케임브리지",
            "[토론] 김형석 - 하버드",
            "[코딩] 임동규 - 펜실베니아"
        ],
        imageUrl: "/images/group-class/171115-winter/17_winter_group_class_thumbnail.jpg",
        end: true,
        childGroupClasses: [{
                title: "겨울방학 이용해 IB Psychology SL/HL 기초 잡기",
                period: "2017. 12. 18 - 2018. 01. 06",
                location: "교대 토즈",
                teachers: [
                    "[심리학] 윤효원 - 서울대",
                ],
                end: true,
                imageUrl: "/images/group-class/171115-winter/17_11_15_winter_group_ib_psychology.jpg",
                redirectList: [{
                    title: "상세보기",
                    url: "/group_class/30"
                }, ]
            },
            {
                title: "단기간 집중 수업을 통해 완벽한 IB Korean 대비",
                period: "2017. 12. 19 - 2018. 01. 05",
                location: "교대 토즈",
                teachers: [
                    "[한국어] 윤지영 - UC 샌디에고",
                ],
                end: true,
                imageUrl: "/images/group-class/171115-winter/17_11_15_winter_group_ib_korean.jpg",
                redirectList: [{
                        title: "DP2 상세보기",
                        url: "/group_class/33"
                    },
                    {
                        title: "DP1 상세보기",
                        url: "/group_class/32"
                    },

                ]
            },
            {
                title: "IB 영어 Lang & Lit 겨울특강",
                period: "2017. 12. 18 - 2018. 01. 05",
                location: "교대 토즈",
                teachers: [
                    "[영어] 사혜원 - 옥스퍼드",
                ],
                end: true,
                imageUrl: "/images/group-class/171115-winter/17_11_15_winter_group_ib_english.jpg",
                redirectList: [{
                    title: "상세보기",
                    url: "/group_class/31"
                }, ]
            },
            {
                title: "IB 물리 겨울특강",
                period: "2017. 12. 18 - 2018. 01. 06",
                location: "교대 토즈",
                teachers: [
                    "[물리] 조승현 - 옥스퍼드",
                ],
                end: true,
                imageUrl: "/images/group-class/171115-winter/17_11_15_winter_group_ib_physics.jpg",
                redirectList: [{
                        title: "DP2 상세보기",
                        url: "/group_class/37"
                    },
                    {
                        title: "DP1 상세보기",
                        url: "/group_class/36"
                    },
                ]
            },
            {
                title: "겨울방학 에이레벨 경제 특강",
                period: "2017. 12. 18 - 2018. 01. 06",
                location: "교대 토즈",
                teachers: [
                    "[경제] 엄의용 - 케임브리지"
                ],
                end: true,
                imageUrl: "http://oofuatpq5.bkt.clouddn.com/thumbnail/bI3O4VTJG2VND0kIJFwu",
                redirectList: [{
                    title: "상세보기",
                    url: "/group_class/41"
                }]
            },
            {
                title: "겨울방학 Debating(토론) 겨울특강",
                period: "2017. 12. 18 - 2018. 01. 06",
                location: "교대 토즈",
                teachers: [
                    "[토론] 김형석 - 하버드"
                ],
                end: true,
                imageUrl: "http://oofuatpq5.bkt.clouddn.com/thumbnail/uB4yELylWpXqBRXrTcmz",
                redirectList: [{
                    title: "상세보기",
                    url: "/group_class/42"
                }]
            },
            {
                title: "코딩 겨울방학 특강",
                period: "2017. 12. 18 - 2018. 01. 06",
                location: "교대 토즈",
                teachers: [
                    "[코딩] 임동규 - 펜실베니아"
                ],
                end: true,
                imageUrl: "/images/group-class/171115-winter/17_12_19_winter_group_coding.jpg",
                redirectList: [{
                        title: "커리큘럼 2 상세보기",
                        url: "/group_class/45"
                    },
                    {
                        title: "커리큘럼 1 상세보기",
                        url: "/group_class/44"
                    },
                ]
            },

            {
                title: "화학 겨울 특강",
                period: "2017. 12. 18 - 2018. 01. 06",
                location: "교대 토즈",
                teachers: [
                    "[화학] 이혁진 - 옥스퍼드"
                ],
                end: true,
                imageUrl: "/images/group-class/171115-winter/17_11_15_winter_group_ib_chemistry.jpg",
                redirectList: [{
                        title: "DP2 상세보기",
                        url: "/group_class/35"
                    },
                    {
                        title: "DP1 상세보기",
                        url: "/group_class/34"
                    },
                ]
            },
            {
                title: "IB 수학 겨울특강",
                period: "2017. 12. 18 - 2018. 01. 06",
                location: "교대 토즈",
                teachers: [
                    "[수학] 방원준 - 워릭",
                ],
                end: true,
                imageUrl: "http://oofuatpq5.bkt.clouddn.com/thumbnail/8VsMVHpJ5vJ50LxBKKV1",
                redirectList: [{
                    title: "상세보기",
                    url: "/group_class/40",
                }, ]
            }


        ],
    }
];

export {
    staticGroupClassList 
}