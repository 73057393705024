import React, { Component } from 'react';
import './demo_class_video.css';
import DemoClassVide_1 from '../../images/demo_class_video_student_only_II.jpg';
import DemoClassVide_2 from '../../images/demo_class_video_student_only_I.jpg';


export default class Demo_class_video extends Component {
    componentDidMount() {
        this.props.RootBindingData.doLoading(false);
    }
    render() {
        return (
            <div className="demo-class">
                <div className="demo-class-banner">
                    <div className="demo-class-banner-box">
                        <div className="demo-class-banner-main">
                            <h1>데모수업 영상</h1>
                            <h4>Parents want to see a tutor’s teaching style and get a feel of what to expect in class</h4>
                        </div>
                        <div className="color-overlay"></div>
                    </div>
                </div>
                <div className="demo-class-example">
                    <div className="demo-class-common-box">
                        <h3>Example</h3>
                        <div className="demo-class-youtube">
                            <div className="demo-class-youtube-box">
                                <div className="demo-class-youtube-item">
                                    <div class="videowrapper">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/JTvt3evTntg" frameborder="0" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="demo-class-youtube-item">
                                    <div class="videowrapper">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/9Ec5veRFyLA" frameborder="0" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="demo-class-youtube-item">
                                    <div class="videowrapper">
                                        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/hAvXtjaAOrA" frameborder="0" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                            <p>Duration: ~5 minutes</p>
                            <p>Content: Past paper question go through (recommended) or Concept explanation</p>
                        </div>

                        <div className="demo-class-benefit">
                            <h3>Benefit</h3>
                            <p>1. Receive more tuition enquiries (by ranking higher in the tutor search results)</p>
                            <p>2. Receive more successful tuition enquiries (already happy with teaching style)</p>
                            <p>3. No need for demo class (in most cases)</p>
                        </div>
                        <div className="demo-class-publicity">
                            <div className="demo-class-publicity-box">
                                <h3>Publicity</h3>
                                <p>GuruMe understands tutors may feel awkward/worried about their videos being open to the public. Therefore, only students and parents can view the demo class film after logging into their GuruMe account.</p>
                                <div className="demo-class-publicity-image">
                                    <div className="demo-class-publicity-image-item">
                                        <img src={DemoClassVide_1} />
                                    </div>
                                    <div className="demo-class-publicity-image-item">
                                        <img src={DemoClassVide_2} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="demo-class-preparation">
                            <h3>Preparation</h3>
                            <p>1. Outline demo class content with question/text</p>
                            <p>2. Practice delivery with particular attention to:</p>
                            <p><i className="fa fa-check"></i>Hand gesture</p>
                            <p><i className="fa fa-check"></i>Confident/eager/enthusiastic voice and look (looking at the camera)</p>
                            <p><i className="fa fa-check"></i>Polytonous voice (change in tone from high, middle to low)</p>
                            <p><i className="fa fa-check"></i>Visually Colorful notation (ideally 3 different colours)</p>
                            <p>*Do not overprepare. 5 minutes end up very shortly. </p>
                            <p>*It is recommended to use both English and Korean when doing this demo video as many of the audience will be parents who may not necessarily speak English.</p>
                        </div>
                        <div className="demo-class-filming">
                            <h3>Filming</h3>
                            <p>1. In front of board @GuruMe office - recommended for visual and audio professionalism</p>
                            <p>2. Online whiteboard using touchpad (if sciences) - <a href="https://goo.gl/4KFh8j" target="blank">recording protocol</a></p>
                            <p>*For arts + science - if touchpad available</p>
                            <p>While Skyping with GuruMe, tutor screenshares his online whiteboard and GuruMe screenrecords. GuruMe can ask a question for the demo to sound like a real class.</p>
                        </div>

                        <div className="demo-class-dresscode">
                            <h3>Dresscode</h3>
                            <p>Smart casual</p>
                            <p>* No cap/tank-top</p>
                        </div>

                        <div className="demo-class-faq">
                            <div className="demo-class-fqa-box">
                            </div>
                        </div>

                    </div>
                </div>

                <div className="email-us">
                    <div className="email-us-box">
                        <div className="email-us-content">
                            <h3 style={{ color: "white" }}>참여 문의는 support@gurume.kr로 이메일 주세요</h3>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}