import React, { Component } from 'react';

export default class Header extends Component {
    render(){
        return(
            <div id="top-bar" class='top_bar_nav'>
            <div>
                {/* <div class="top-bar-how">
                    <div class="top-links" id="how-it-works-wrapper">
                        <button id="how-it-works">
                            <a href='/#/how_it_works/tuition'>이용방법</a>
                        </button>
                    </div>
                </div> */}
                <div class="top-bar-menu">
                    <div class="top-links">
                        <ul id='loginPannel' class="nav_ul">
                            {/*<li class="cursor">*/}
                                {/*<a id='lang_id' class="border_text">En|中文|한글</a>*/}
                            {/*</li>*/}
                            <li id="after_login" class='after-login'>
                                <a id='login_id' class="cursor" data-toggle="dropdown"> <span class="caret"></span></a>
                                <ul class="dropdown-menu after-login-dropdown fix-nav-menu">
                                    <li id='goDashBoard'><a>
                                            마이페이지
                                        </a></li>
                                    <li id='goMessageInbox'><a>
                                            쪽지함
                                            {/* <label id='unreadMessageCount' class="badge">
                                                0
                                            </label> */}
                                        </a></li>
                                    <li id='accountSet'><a>
                                            계정관리
                                        </a></li>
                                    <li id='doLogout'><a>
                                            로그아웃
                                        </a></li>
                                </ul>
                            </li>
    
    
                            <li class="cursor"><a href="/#/login" class="border_text">로그인</a></li>
                            <li class="cursor"><a href='/#/register' class="border_text">회원가입</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}