import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GetAjaxURL, postConfig } from '../../../../config/AjaxConfig';
import axios from '../../../../config/axios';

import './TdClassComponent.css';
import {
    TrasPendinginvoicing, TrasPending, TrasConfirmingPayment, TrasInProgress, TrasEndoftuitionconfirmationpending,
    TrasConfirmendoftuition,
} from '../../../../Translate/EachTranslateC';
import { showNotify, STATE_NOTIFY } from '../../../extra/cusUtil';
import ReactTooltip from 'react-tooltip';
import DashboardQuestions from '../../../utils/DashboardQuestions';

import { InfinityController, INFI_STATE } from '../../../extra/InfinityScroll';
const $ = window.jQuery;


const TdClassComponentPropTypes = {
    showingInvoice: PropTypes.func,
    showingDetailInfo: PropTypes.func,
    showingNewTdData: PropTypes.func,
    tdMoney: PropTypes.string,
    showingNewChildren: PropTypes.func,
    clickConfirmMsg: PropTypes.func,
    tTutionData: PropTypes.any,
    isAjaxDone: PropTypes.bool,
}
const TdClassComponentDefaultProps = {
    showingInvoice: () => { console.log("showingInvoice NOT SETTING") },
    showingDetailInfo: () => { console.log("showingDetailInfo NOT SETTING") },
    showingNewTdData: () => { console.log("showingNewTdData NOT SETTING") },
    tdMoney: '0',
    showingNewChildren: () => { console.log("showingNewChildren NOT SETTING") },
    clickConfirmMsg: () => { console.log('clickConfirmMsg') },
    tTutionData: [],
    isAjaxDone: false,
    isTeacherCompleteOT: 0,
}

class TdClassComponent extends Component {

    state = {
        firstSenta: '"Progress Report 사용팁: 모바일로 수업 끝날때 마다 노트를 합니다. 모바일에 Google doc 어플 다운 받아놓으면 편합니다."',
        secondSenta: '그루미에게 1~6월에 대한 3.3% 금액을 환급해주시기를 요청드립니다...',
        thirdSenta: '2019년 1월~6월 총 수업료 소득:',
        forthSenta: '신고/환급 받을 3.3% 금액:',
        fifthSenta: '신한은행 110-338-435020 이혁진 (그루미) *그루미희라 3.3% 세금*으로 입금',
        lastSenta: '입금 후 확인 메세지는 그루미 신희라 선생님께 (ilovegurume) 메세지 부탁드립니다. "입금하시면 위 글은 자동으로 사라집니다."',

        TdClassComponentData: [],              // 전체 데이터
        TdCurrentClassComponetData: [],        // 현재 데이터
        InfinityController: null,              // InfinityController
    }

    constructor(props) {
        super(props);
        this._isMounted = false;
    }


    componentDidMount = async () => {
        this._isMounted = true;
        if (this.state.TdClassComponentData.length === 0)
            this.getTuitionsClick();
        const allUrls = await GetAjaxURL();
        const apiThisUser = allUrls.apiThisUser
        const res = await axios.get(apiThisUser).catch((err) => { return console.log(err) })
        const userData = res.data

        this.setState({
            isTeacherCompleteOT: userData.profile.ot
        })
    }



    createMoneys = () => {
        let moneyTypeB = Number(this.props.tdMoney) * 1;
        if (!moneyTypeB) moneyTypeB = '0';

        let moneyTypeS = Number(this.props.tdMoney) * 0.033;
        if (!moneyTypeS) moneyTypeS = '0';

        return (
            <div className='tdClass-TdClassComponent'>
                <ul>
                    <li>{this.state.firstSenta}</li>
                    <li>{this.state.secondSenta}</li>
                    <li><h3>{this.state.thirdSenta} <span style={{ color: '#5b9aef' }}>{moneyTypeB}</span>원</h3></li>
                    <li><h3>{this.state.forthSenta} <span style={{ color: '#5b9aef' }}>{moneyTypeS}</span>원</h3></li>
                    <li>{this.state.fifthSenta}</li>
                    <li>{this.state.lastSenta}</li>
                </ul>
            </div>
        )
    }

    createClassList = () => {
        return (
            <div className='tdClass-so-TdClassComponent'>
                <ul>
                    <li>학생</li>
                    <li>커리큘럼/과목</li>
                    <li>확인상태</li>
                    <li>수업료</li>
                    <li>상세내용</li>
                </ul>
            </div>
        )
    }


    createInvoiced = () => {
        return (
            <div className='tdClass-ext'>
                <button onClick={this.showingnewData}>
                    <i className="fa fa-plus-circle"></i>
                    &nbsp; 수업료 인보이스
                </button>
            </div>
        )
    }




    getTuitionsClick = async () => {

        const tTutionData = this.props.tTutionData;


        const TUITION_STATUS_CODE = {
            CREATED: 1,
            STUDENT_CONFIRMED_START: 3,
            ADMIN_CONFIRMED_WITH_RECEIPT: 4,
            TUTOR_CONFIRMED_END: 5,
            STUDENT_CONFIRMED_END: 6,
            FINISHED: 7
        };


        const tdClassArr = [];
        for (let i = 0; i < tTutionData.length; i++) {
            if (tTutionData[i].status >= TUITION_STATUS_CODE.STUDENT_CONFIRMED_START && String(tTutionData[i].credit_purchase) === '0') {
            }
            tdClassArr.push(tTutionData[i]);
        }

        // change into query
        const tempArr = [];
        for (let i = 0; i < tdClassArr.length; i++) {
            let tcStuName = tdClassArr[i].student_name;
            let tcContact = '';
            if (tdClassArr[i]['student_info']) {
                if (tdClassArr[i]['student_info']['full_name'])
                    tcStuName = tdClassArr[i]['student_info']['full_name'];

                if (tdClassArr[i]['student_info']['contact_details'])
                    tcContact = tdClassArr[i]['student_info']['contact_details'];
            }

            const tcDemo = tdClassArr[i].demo;
            const tcCondition = String(tdClassArr[i].status);
            const tcWellet = tdClassArr[i].total_amount;
            const tcKey = tdClassArr[i].ordernum;
            const tcClassId = tdClassArr[i].id;
            const tcComeOutTime = tdClassArr[i].hours;
            const tcPriceToEat = tdClassArr[i].hourly_rate;

            const tcDateTime = tdClassArr[i].start_time + ' ~ ' + tdClassArr[i].end_time;
            const tcStudentId = tdClassArr[i].student_id;
            const tcTotalMoney = tdClassArr[i].total_amount;
            const tcTuitionRequestId = tdClassArr[i].tuition_request_correct;

            const tcStuTotalMoney = tdClassArr[i].student_total_amount;

            const isCredit = String(tdClassArr[i].credit_purchase) === '0' ? false : true;
            const isCopy = tdClassArr[i].is_copy;

            let tdLinkReport = '';
            let reportType;
            if (tdClassArr[i]['report']) {
                tdLinkReport = tdClassArr[i].report.url;
                reportType = String(tdClassArr[i].report.demo) === '0' ? "Progress Report" : "Demo Class Report";
            }



            let tdCul;
            let tdSubj;
            let tdSCKey;
            if (tdClassArr[i].curriculum_details) {
                // THIS WILL BE AN ARRAY WITH only ONE DATA
                for (let k = 0; k < tdClassArr[i].curriculum_details.length; k++) {
                    if (tdClassArr[i].curriculum_details[k]) {
                        tdCul = tdClassArr[i].curriculum_details[k].curriculum;
                        tdSubj = tdClassArr[i].curriculum_details[k].subject;
                        tdSCKey = tdClassArr[i].curriculum_details[k].id;
                    }
                }
            }

            tempArr.push({
                tcStuName, tcDemo, tcCondition, tcWellet, tdCul, tdSubj, tdSCKey, tcKey, tcClassId, tcStudentId,
                tcContact, tcComeOutTime, tcPriceToEat, tcDateTime, tcTotalMoney, tcStuTotalMoney, tdLinkReport, reportType
                , isCredit, isCopy, tcTuitionRequestId
            });

        }

        const InfinityControllers = new InfinityController();
        const currentIndex = INFI_STATE.START_COUNT;
        InfinityControllers.setInit(tempArr);
        const TdCurrentClassComponetData = InfinityControllers.getDataWithCount(currentIndex);

        this.setState({
            ...this.state,
            TdClassComponentData: tempArr,
            TdCurrentClassComponetData: TdCurrentClassComponetData,
            InfinityController: InfinityControllers,
        }, () => {
        })

    }




    showingInvoiceClick = (e) => {
        const target = e.target;
        const data = target.dataset['tempModifyKey'];
        this.props.showingInvoice(data);
    }

    getDataKeys = (e) => {
        const target = e.target;
        const tempkey = target.dataset['tempModifyKey'];
        window.location.href = '/#/tuitions/bill/edit/' + tempkey;
    }

    getTheDataFromIt = async (e) => {

        const target = e.target;
        const tcClassId = target.dataset['tcClassId'];

        const allUrls = await GetAjaxURL();
        const sendUrl = allUrls.tuitionDelete + tcClassId;

        let msg = '';
        if (window.confirm('해당 데이터를 삭제하시겠습니까?')) {
            const res = await axios.post(sendUrl, {}, postConfig).catch((err) => { console.log(err) });
            if (res.data.status === 'success') {
                msg = '해당 수업을 성공적으로 삭제했습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
                window.location.reload();
            } else {
                msg = res.data.msg;
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            }
        } else {
            msg = '작업이 취소되었습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
        }

    }


    clickToTd = (e) => {
        $('.td-detail-modal').toggle('slow')

        const target = e.target;

        const classId = target.dataset['classId'];
        const culOutTime = target.dataset['culOutTime'];
        const culs = target.dataset['culs'];
        const subs = target.dataset['subs'];

        const startAndEndTime = target.dataset['startAndEndTime'];
        const pricePerAHour = target.dataset['pricePerAHour'];
        const classFee = target.dataset['classFee'];
        const payments = target.dataset['payments'];
        const orderNumber = target.dataset['orderNumber'];



        const data = {
            classId, culOutTime, culs, subs, startAndEndTime,
            pricePerAHour, classFee, payments, orderNumber
        }


        this.props.showingDetailInfo(data);
    }
    showingnewData = () => {
        if (this.state.isTeacherCompleteOT) {
            this.props.showingNewTdData();
        }
        else {
            if (window.confirm("OT를 수료하셔야 인보이스 기능을 이용할 수 있습니다. OT 페이지로 이동하시겠습니까?")) {
                window.location.href = "/#/ot"
            }
        }
    }
    clickedToSms = (e) => {
        const target = e.target;

        const email = target.dataset['tempEmail'];
        const kakaotalk = target.dataset['tempKakaotalk'];
        const phone = target.dataset['tempPhone'];
        const skype = target.dataset['tempSkype'];
        const wechat = target.dataset['tempWechat'];
        const whatsapp = target.dataset['tempWhatsapp'];
        const stuName = target.dataset['tempStuName'];

        const data = {
            email, kakaotalk, phone, skype, wechat, whatsapp, stuName
        }

        this.props.showingNewChildren(data);
    }




    doTutor_confirm = async (e) => {
        let target = e.target;
        if (!target.dataset['orderNumber']) target = target.parentNode;
        const orderNum = target.dataset['orderNumber'];
        const tdCondition = String(target.dataset['tdCondition']);
        const tcStuName = String(target.dataset['tcStuName']);
        const tcDemo = String(target.dataset['tcDemo']);
        const lang = 'ko';

        let checkVal = false;


        if ('4' === tdCondition || '2' === tdCondition) {
            let confirmMsg;
            let studentName = tcStuName;
            if (tcDemo === '1') {
                confirmMsg = ('en' === lang ? 'Are you sure demo tuition has been finished? This will notify <' + studentName + '> that you have requested tuition fee payment.' : ('ko' === lang ? '데모 수업이 정말 끝났나요? 수업 마무리시 <' + studentName + '> 학생에게 선생님께서 수업을 종료하고 수업료를 요청하셨다는 알람이 전송됩니다.' : '你确定演示学费已经完成了吗？ 这将通知 <' + studentName + '>你已经要求学费支付。'));
            } else {
                confirmMsg = (lang === 'en' ? 'Are you sure tuition has been finished? This will notify <' + studentName +
                    '> that you have requested tuition fee payment. Have you finished progress report? Student/parent will be given the progress report link to look at' : lang === 'cn' ?
                    '你确定学费已经完成了吗？ 这将通知 <' + tcStuName + '> 你已经要求学费支付。 你有完成进度报告吗？ 学生/家长将被给予进度报告链接看' :
                    '수업이 정말 끝났나요? 수업 마무리 시 "' + tcStuName + '" 학생에게 선생님께서 수업을 종료하고 수업료를 요청하셨다는 알람이 전송됩니다. 학생과 학부모가 조회할 수 있는 ' + 'Progress report도 작성 되었는지 확인 후 확인을 눌러주세요.');
            }
            if (window.confirm(confirmMsg)) {
                // $scope.onCompletingTuition = true;
                checkVal = '';

                const allUrls = await GetAjaxURL();
                const tutor_confirmUrl = allUrls.tutor_confirm;
                const tutorCUrl = tutor_confirmUrl + orderNum;
                const resData = await axios.post(tutorCUrl, {}, postConfig).catch((err) => { return console.log(err) });
                const checkData = resData.data;

                if (checkData.status === 'success') {
                    if (!tcDemo) {
                        // $('#finish-tuition').modal('show');
                    }
                    showNotify('성공적으로 보냈습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                    checkVal = '5';
                } else {
                    showNotify(checkData.msg, "danger");
                }
            }
        }


        if ('5' === checkVal || '5' === tdCondition) {
            this.props.clickConfirmMsg(orderNum, tcStuName);
        }

    }


    scrollDectectFunc = () => {
        // 스크롤 마지막 위치 감지
        const isScrollDone = this.state.InfinityController.scrollDetect('infiWapper', 'infiList');
        if (isScrollDone) {
            const TdCurrentClassComponetData = this.state.InfinityController.getDataWithCount(INFI_STATE.REPEAT_COUNT);
            this.setState({
                ...this.state,
                TdCurrentClassComponetData: TdCurrentClassComponetData,
            });
        }
    }

    goToStudentShow = (sId) => {
        if (sId > 0) {
            window.location.href = "/#/students/" + sId
        }
        else {
            return;
        }
    }
    createLastParts = () => {

        // const TdClassComponentData = this.state.TdClassComponentData;
        const TdClassComponentData = this.state.TdCurrentClassComponetData;
        const li = [];




        // tcStuName, tcDemo, tcCondition, tcWellet, tdCul, tdSubj, tdSCKey
        for (let i = 0; i < TdClassComponentData.length; i++) {

            const tcKey = TdClassComponentData[i].tcKey;
            const tcClassId = TdClassComponentData[i].tcClassId;
            const tcComeOutTime = TdClassComponentData[i].tcComeOutTime;
            const tcPriceToEat = TdClassComponentData[i].tcPriceToEat;
            const tcDateTime = TdClassComponentData[i].tcDateTime;
            const tcStudentId = TdClassComponentData[i].tcStudentId;
            const tcSession = TdClassComponentData[i].session_id;
            const tcTotalMoney = TdClassComponentData[i].tcTotalMoney;
            const tcStuTotalMoney = TdClassComponentData[i].tcStuTotalMoney;
            const tcStuName = TdClassComponentData[i].tcStuName;
            const tdLinkReport = TdClassComponentData[i].tdLinkReport;
            const reportType = TdClassComponentData[i].reportType;
            const requestId = TdClassComponentData[i].tcTuitionRequestId;
            const tcIsCredit = TdClassComponentData[i].isCredit;
            const tcIsCopy = TdClassComponentData[i].isCopy;
            const tcDemo = TdClassComponentData[i].tcDemo;

            let demoSpan = '';
            let statusMsg = '';

            if (TdClassComponentData[i].tcDemo === '1') {
                demoSpan = <span className='td-component-block comm-blue-td'>demo</span>;
            }
            if (TdClassComponentData[i].tcCondition === '0') {
                statusMsg = <TrasPendinginvoicing key='1' />;
            } else if (TdClassComponentData[i].tcCondition === '1') {
                statusMsg = <TrasPending key='2' />;
            } else if (TdClassComponentData[i].tcCondition === '2' || TdClassComponentData[i].tcCondition === '4') {
                statusMsg = <TrasInProgress key='4' />;
            } else if (TdClassComponentData[i].tcCondition === '3') {
                statusMsg = <TrasConfirmingPayment key='3' />;
            } else if (TdClassComponentData[i].tcCondition === '5') {
                statusMsg = <TrasEndoftuitionconfirmationpending key='5' />;
            } else if (TdClassComponentData[i].tcCondition === '6') {
                statusMsg = <div className='tdclass-tool-tip-size'>수업료 입금 중 <br />
                    <span
                        className="tp-profile-pointer"
                        data-tip={`금액이 작은 데모수업료는 이체수수료를 면하기 위해 (매건 500원) 첫 달 수업료와 함께 지급됩니다. 
                                        2주내에 첫 달 인보이스 발행이 안 될 경우 수업진행이 안된 걸로 간주하여 데모수업료만 따로 입금됩니다. 
                                        *정규수업에 대해 주별로 발행된 인보이스 또한 한달량의 인보이스가 축적되면 같이 발행됩니다.`}
                        data-for='idPretty'>
                        <i className='gurume-red'> 아직 입금이 안 되었나요?</i>
                        <ReactTooltip
                            effect="solid"
                            place="bottom"
                            id='idPretty'
                            type='light'>
                        </ReactTooltip>
                    </span>
                </div>;
            }
            else if (TdClassComponentData[i].tcCondition === '7') {
                statusMsg = '수업료 입금 완료!';
            }
            const onCompletingTuition = false;
            let additional = '';
            if ((!onCompletingTuition) && ('4' === TdClassComponentData[i].tcCondition
                || '5' === TdClassComponentData[i].tcCondition
                || '2' === TdClassComponentData[i].tcCondition)) {

                additional =
                    <button className='tdClass_btn_additional'
                        data-order-number={tcKey}
                        data-td-condition={TdClassComponentData[i].tcCondition}
                        data-tc-stu-name={TdClassComponentData[i].tcStuName}
                        data-tc-demo={String(TdClassComponentData[i].tcDemo)}
                        onClick={this.doTutor_confirm} >
                        <TrasConfirmendoftuition />
                    </button>;
            }

            let checkCondition = false;
            if (TdClassComponentData[i].tcCondition === '3') {
                checkCondition = true;
            }

            let list_li =
                <div key='tempa'>
                    <ul>
                        <li onClick={() => this.goToStudentShow(TdClassComponentData[i].tcStudentId)}>
                            <img src='../images/backuptutorpic.png' alt='...' />
                        </li>
                        <li>{TdClassComponentData[i].tcStuName}</li>
                        <li><button onClick={this.clickedToSms}
                            data-temp-email={TdClassComponentData[i].tcContact.email}
                            data-temp-kakaotalk={TdClassComponentData[i].tcContact.kakaotalk}
                            data-temp-phone={TdClassComponentData[i].tcContact.phone}
                            data-temp-skype={TdClassComponentData[i].tcContact.skype}
                            data-temp-wechat={TdClassComponentData[i].tcContact.wechat}
                            data-temp-whatsapp={TdClassComponentData[i].tcContact.whatsapp}
                            data-temp-stu-name={tcStuName}
                        >연락처</button></li>
                    </ul>
                </div>;
            if (checkCondition && tcIsCopy || TdClassComponentData[i].tcCondition === '1') {
                list_li =
                    <div key='stemp'>
                        <span className='td-span-type-b'>{TdClassComponentData[i].tcStuName}</span>
                        <span className='td-span-type-a'><button onClick={this.showingInvoiceClick} data-temp-modify-key={tcKey}>수업료 인보이스 링크</button></span>
                        <span className='td-op-type-a'>
                            <span><button onClick={this.getDataKeys} data-temp-modify-key={tcKey}>수정</button></span>
                            <span><button onClick={this.getTheDataFromIt} data-tc-class-id={tcClassId}>삭제</button></span>
                        </span>
                    </div>;
            }

            let senBtn = '';
            if (TdClassComponentData[i].tcCondition === '5'
                || TdClassComponentData[i].tcCondition === '4'
                || TdClassComponentData[i].tcCondition === '2'
                || TdClassComponentData[i].tcCondition === '3'
                || TdClassComponentData[i].tcCondition === '6'
                || TdClassComponentData[i].tcCondition === '7'
            ) {
                senBtn =
                    <button
                        data-link-data={tdLinkReport}
                        onClick={this.dreamWithMe}
                    >{reportType}</button>;

            }

            let requestBtn = '';
            if (requestId != 0) {
                let requestUrl = '/#/tuition_request/' + requestId;

                requestBtn =
                    <button
                        data-link-data={requestUrl}
                        onClick={this.dreamWithMe}
                    >Tuition Request</button>;
            }


            const newMoney = [];
            const tdMoney = String(TdClassComponentData[i].tcWellet);
            const remainMoneyLength = Math.ceil(tdMoney.length / 3);

            for (let k = 0; k < remainMoneyLength; k++) {
                let currentPos = (tdMoney.length - 3 * (k + 1));
                let currentLastPos = tdMoney.length - (3 * k);
                if (currentPos < 0) {
                    currentPos = 0;
                }
                const tempStr = tdMoney.slice(currentPos, currentLastPos);
                newMoney.push(tempStr);
            }
            let moneyWithFormat = '';
            while (newMoney.length > 0) {
                const getStr = newMoney.pop();
                moneyWithFormat += getStr + ',';
            }
            moneyWithFormat = moneyWithFormat.slice(0, moneyWithFormat.length - 1);



            let displayBankOrCredit = '계좌 이체';
            if (tcIsCredit) {
                displayBankOrCredit = '크레딧';
            }

            li.push(
                <li key={`ks${i}`} className='td-op-type-b-li'>
                    {list_li}
                    <div>
                        <div className='td-compon-type-a'>
                            {demoSpan}
                            <span className='td-component-block td-com-text'>
                                <span><b>{TdClassComponentData[i].tdCul}</b></span><span>{TdClassComponentData[i].tdSubj}</span>
                            </span>
                            {senBtn}
                            {requestBtn}
                        </div>
                    </div>
                    <div className='td-optional-type-a'>
                        <span>
                            {statusMsg}
                            {additional}
                            {
                                (TdClassComponentData[i].tcCondition === '5' || TdClassComponentData[i].tcCondition === '4')
                                    && TdClassComponentData[i].tcDemo !== '1' ?
                                    <DashboardQuestions />
                                    : ''
                            }
                        </span>
                    </div>
                    <div>
                        <div>
                            <span className='td-component-block'>{moneyWithFormat}</span>
                            <span className='td-component-block comm-blue-td'>{displayBankOrCredit}</span>
                        </div>
                    </div>
                    <div className='td-optional-type-a'>

                        <button className='temp-mine' onClick={this.clickToTd}
                            data-order-number={tcKey}
                            data-class-id={tcClassId}
                            data-cul-out-time={tcComeOutTime}
                            data-culs={TdClassComponentData[i].tdCul}
                            data-subs={TdClassComponentData[i].tdSubj}
                            data-start-and-end-time={tcDateTime}
                            data-session={tcSession}
                            data-price-per-a-hour={tcPriceToEat}
                            data-class-fee={tcTotalMoney}
                            data-payments={tcStuTotalMoney}
                        >상세 내용</button>
                    </div>
                </li>

            );
        }


        return (
            <div className='tdClass-sq-TdClassComponent infi-wapper' id='infiWapper' onScroll={this.scrollDectectFunc}>
                <ul id='infiList'>
                    {li}
                </ul>
            </div>
        )
    }

    dreamWithMe = (e) => {
        const target = e.target;
        const data = target.dataset['linkData'];

        window.open(data);
    }



    createHtml = () => {
        return (
            <div className='td-common-wrapper'>
                {this.createInvoiced()}
                <div className="td-invoice-wrapper">
                    {this.createClassList()}
                    {this.createLastParts()}
                </div>
            </div>
        )
    }

    render() {
        return this.createHtml();
    }

}

TdClassComponent.propTypes = TdClassComponentPropTypes;
TdClassComponent.defaultProps = TdClassComponentDefaultProps;

export default TdClassComponent;