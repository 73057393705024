import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import './DashboardQuestions.css';

class DashboardQuestions extends Component{
    render(){
        return(
            <div className="tdashboard-explanation">
                <span
                    className="pink-font-color dq-tool-tip-box"
                    data-tip= {`그루미 수업 매니저에게 '학생이름/콤플레인 사유 (punctuality/수업 준비, 태도/연락 어려움)' 카톡 연락합니다.`}
                    data-for='idComp'>
                    <i className="fa fa-question-circle"></i> {'컴플레인'}
                    <ReactTooltip
                        effect="solid"
                        place="bottom"
                        id='idComp'
                        type='light'>
                    </ReactTooltip>
                </span>
                <span
                    className="brown-font-color dq-tool-tip-box"
                    data-tip= {`그루미 수업 매니저에게 '학생이름/조기종료 사유/마지막 수업가능 날짜/대체 선생님 유무' 카톡 연락합니다. 만약 어떤 이유에서라도 예정된 수업기간 보다 일찍 끝내야 한다면 최소 2주 전에는 말씀해주시고 그루미와 같이 replace 선생님을 배정해드립니다.`}
                    data-for='idFin'>
                    <i className="fa fa-question-circle"></i> {'조기 종료'}
                    <ReactTooltip
                        effect="solid"
                        place="bottom"
                        id='idFin'
                        type='light'>
                    </ReactTooltip>
                </span>
                <span
                    className="green-font-color dq-tool-tip-box"
                    data-tip= {`학생이 수업 완료 확인 요청 날로부터 10일내로 미확인시 수업 완료로 간주 및 선생님께 수업료 지급`}
                    data-for='idFinConf'>
                    <i className="fa fa-question-circle"></i> {'수업 완료 미확인'}
                    <ReactTooltip
                        effect="solid"
                        place="bottom"
                        id='idFinConf'
                        type='light'>
                    </ReactTooltip>
                </span>
            </div>
        )
    }

}

export default DashboardQuestions;