import React, { Component } from 'react';
import axios from '../../../config/axios';
import { GetAjaxURL } from '../../../config/AjaxConfig';
import { showNotify, STATE_NOTIFY } from '../../../components/extra/cusUtil';
import { withRouter } from 'react-router';
import YouTube from 'react-youtube';
import {
    TrasHome,
    TrasTutor,
    TrasRegister,
} from '../../../Translate/EachTranslateC';
import './TeachersOT.css';

const $ = window.jQuery;

class OTModal extends Component {
    render() {
        return (
            <div></div>
        )
    }
}
class OT extends Component {
    state = {
        teacherId: 1,
        isTeacherCompleteOT: 0,
        videoTimer: 519,
        time: 519,
    }
    componentDidMount = async () => {
        this.props.RootBindingData.doLoading(false);

        const allUrls = await GetAjaxURL();
        const apiThisUser = allUrls.apiThisUser
        const apiIsLogin = allUrls.isLogin
        const loginRes = await axios.get(apiIsLogin).catch((err) => { return console.log(err) })
        const userRes = await axios.get(apiThisUser).catch((err) => { return console.log(err) })


        const loginData = loginRes.data
        const userData = userRes.data
        this.setState({
            teacherId: userData.profile.id,
            isTeacherCompleteOT: userData.profile.ot
        })
        if (loginData.isLogin == "false" || loginData.type !== 'tutor') {
            window.location.href = "/#/"
        } else {
            return
        }
    }
    // onStartOTVideo = (e) => {
    //     if (e.data === YouTube.PlayerState.PLAYING) {
    //         this.state.videoTimer = setInterval(() => {
    //             this.setState({time:this.state.time-1})
    //         }, 1000)
    //     }
    //     if (e.data === YouTube.PlayerState.PAUSED) {
    //         this.setState({time : this.state.time})
    //         clearInterval(this.state.videoTimer)
    //     }
    //     if (e.data === YouTube.PlayerState.ENDED){
    //         clearInterval(this.state.videoTimer)
    //     }
    // }
    // onEndOTVideo = (e) => {
    //     if(this.state.videoTimer === 0){
    //         $("#openConfirmOTModal").click()
    //     }else{

    //     }
    // }
    onClickGoToQuiz = async (e) => {
        const allUrls = await GetAjaxURL();
        const apiOT = allUrls.otToOted;
        const formData = new FormData();
        formData.append('id', this.state.teacherId)

        const res = await axios.post(apiOT, formData).catch((err) => { return err; });
        const otData = res.data

        if (otData.status === "success") {
            /* 그루미 선생님 OT 퀴즈폼 */
            window.open("https://forms.gle/979EwaTATCd1pivS7")
        }
        else {
            showNotify("이미 OT를 수료하였습니다", STATE_NOTIFY.TYPE_NEGATIVIE)
        }
    }
    watchVideoLater = () => {
        if (window.confirm("그루미는 원활한 개인 수업 매칭과 시스템 이용을 위해 OT를 진행합니다. OT를 진행하지 않으면 그루미 이용에 제한이 있을 수 있습니다.")) {
            window.location.href = "/#/dashboard"
        }
    }
    endOTVideo = (e) => {
        if (e.data == YouTube.PlayerState.ENDED) {
            $("#openConfirmOTModal").css("display", "block")
            $("#openConfirmOTModal").click()
        }
    }
    render() {
        const options = {
            playerVars: {
                autoplay: 0,
            }
        }
        return (
            <div className="default-wrapper">
                {/* Create Navbar */}
                <div className='each-nav'>
                    <ol className="breadcrumb">
                        <li><a ui-sref="index"><TrasHome /></a></li>
                        <li><a ui-sref="index"><TrasTutor /></a></li>
                        <li><a ui-sref="index"><TrasRegister /></a></li>
                        <li className="active">OT</li>
                    </ol>
                </div>
                {/* Create Contents */}
                {/* Quiz Popup */}
                <div className="ot-video-modal">
                    <div class="modal" id="confirmOTModal" tabindex="-1" role="dialog" aria-labelledby="confirmOTModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div className="ot-video-modal-content">
                                        <p className="text-center">OT영상을 다 시청하셨습니다!!!<span>&#128079;</span> <br />이제, 간단한 퀴즈를 풀면 최종적으로 OT가 수료됩니다.</p>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" onClick={this.onClickGoToQuiz} className="btn btn-primary">퀴즈 풀러 GO</button>
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div className="ot-wrapper">
                    <div className="ot-video-wrapper">
                        <div className="videowrapper">
                            <YouTube id="ot-video" opts={options} className="embed-responsive-item" videoId="aKkeUEs7x3w" onStateChange={this.endOTVideo} />
                        </div>
                    </div>
                    <div className="ot-video-button-wrapper">
                        <button onClick={this.watchVideoLater}>다음에 보기&nbsp;&#128187;</button>
                        <button id="openConfirmOTModal" data-toggle="modal" data-target="#confirmOTModal">
                            퀴즈 풀기&nbsp;<span>&#128221;</span>
                        </button>
                    </div>
                </div>
            </div >
        )
    }
}
export default withRouter(OT)