import React, {Component} from 'react';
import {
    TrasHome,
    TrasAdmin
} from '../../Translate/EachTranslateC';

import ReactTooltip from 'react-tooltip'
/* font awesome */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList, faPencilAlt, faBook, faSearch} from '@fortawesome/free-solid-svg-icons';

import './admissions_consulting_uk.css';
import './how_it_works/how_it_works_tuition.css';

class admissions_consulting_uk extends Component{

    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/admissions_consulting">입시 컨설팅</a></li>
                    <li className="active">영국</li>
                </ol>
            </div>
        )
    }
    
   
    createFirstPart = () => {
        return (
            <div
                className="admissionUs-banner"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "url('/images/background/bg-oxbridge.jpg')",
                    backgroundPosition: "center"
                }}>
                <div className="admissionUs-banner-box">
                    <div className="admissionUs-banner-content text-center">
                        <h2 className="boldest-font white-font-color">
                        영국 대입 컨설팅/멘토링
                        </h2>
                        <h4 className="normal-font white-font-color">
                        옥스브리지/의대/법대/G5
                        </h4>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }
    createSecondPart = () => {
        return (
            <div className="admissionUs-map">
                <div className="admissionUs-map-box text-center margin-bottom-30">
                    <div className="admissionUs-map-title margin-top-30">
                        <h2 className="color-gurume-blue bold-font">권장 대입 로드맵</h2>
                    </div>
                    <div className="admissionUs-map-content">
                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </span>
                                </h1>
                                <h3>
                                    분석/상담
                                </h3>
                                <p>
                                    레벨 테스트<br/>
                                    상담<br/>
                                    대학/전공 선정
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faBook}/>
                                    </span>
                                </h1>
                                <h3>
                                    입시 전략
                                </h3>
                                <p>
                                자기소개서/입시시험/인터뷰<br/>
                                에세이 대회/올림피아드<br/>
                                전공과목 독서<br/>
                                영국 인턴쉽
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faPencilAlt}/>
                                    </span>
                                </h1>
                                <h3>
                                    원서 준비/제출
                                </h3>
                                <p>
                                UCAS/원서제출<br/>
                                입시 시험 신청<br/>
                                대학 인터뷰 여행 동반
                                </p>
                            </div>
                        </div>

                        <div className="admissionUs-map-item">
                            <div className="promotion-box no-border">
                                <h1>
                                    <span className="color-gurume-blue" aria-hidden="true">
                                        <FontAwesomeIcon icon={faList}/>
                                    </span>
                                </h1>
                                <h3>
                                    결과 발표
                                </h3>
                                <p>
                                학교 측과 커뮤니케이션<br/>
                                T4 학생 비자<br/>
                                재입시 가능성 분석
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createThirdPart = () => {
        return (
            <div className="admissionUs-field text-center">
                <div className="admissionUs-field-box">
                    <div className="admissionUs-field-title margin-top-30">
                        <h2 className="color-gurume-blue bold-font">대입 컨설팅/멘토링 분야</h2>
                        <p>아래는 대입에 필요할 수 있는 모든 주요 컨설팅/멘토링 분야입니다. 지원하는 대학과 전공 과목에 따라, 필요한 분야가 다를 수 있습니다.</p>
                    </div>
                    <div className="admissionUs-field-content">
                        <div className="admissionUs-item-wrapper">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">레벨 테스트</h2>
                                <a href='/#/blogs/14' >블로그</a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>전공 과목에 대한 이해/적합성 평가</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>학생의 준비여부 및 희망 대학 가능여부 평가</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>준비해야 할 입시 분야 분석</h5>
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                                <a
                                    className="blue-font-color cursor collapse-btn pull-right margin-bottom-10"
                                    data-toggle="collapse"
                                    data-target=".consulting-detail">더 보기 &raquo;</a>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">상담</h2>
                                <a href='/#/blogs/14' >블로그</a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>영국/옥스브리지/의대/법대 입시 과정 설명</h5>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td>
                                                <h5>대학/전공 및 어플리케이션 로드맵 추천
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td>
                                                <h5>희망 대학/전공 출신의 재학생, 졸업생과 Q&A</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    className="blue-font-color cursor collapse-btn pull-right margin-bottom-10"
                                    data-toggle="collapse"
                                    data-target=".self-introduction-detail">더 보기 &raquo;</a>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">전공과목 독서</h2>
                                <a href='/#/blogs/161' >블로그 | </a>
                                <a href='/#/group_class/89' >커리큘럼</a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>학생 관심분야 식별 및 관련 서적/논문 엄선</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>서적/논문 주제에 대한 에세이 라이팅 및 토론 </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>자기소개서에 기재 될 수 있는 내용 확정/발전
                                                    <span className="">
                                                        <i className="fa fa-question-circle color-gurume-red schools" data-tip={'학생 자기소개서에 기재되거나 고등학교와 대학 1, 2학년 수준 사이의 주제/개념에 대한 토론을 통한 구강 oral assessment를 대비한 구강 전달 능력 향상'}  data-for='tooltipSchool' data-event='mouseover'></i>
                                                        <ReactTooltip effect="solid" place="bottom" id='tooltipSchool' type='light'
                                                            globalEventOff='mouseout' >
                                                        </ReactTooltip>
                                                    </span>
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">자기소개서</h2>
                                <a href='/#/blogs/16' >블로그 | </a>
                                <a href='/#/group_class/88' >커리큘럼</a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>브레인스토밍 (스토리 플로우), 첨삭, 교정</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>학문적 관심사를 바탕으로 탄탄한 지원 동기 강조</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>온라인 실시간 첨삭/피드백/수정 본 드라이브 공유</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">입시시험</h2>
                                <a href='/#/blogs/157' >블로그 | </a>
                                <a href='/#/group_class/74' >커리큘럼</a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>
                                                시험 테크닉 전수
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                기출 문제 및 유사 문제 (+그루미 제작) 풀이
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                시험 실러버스 내용 학습
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr className="interview-detail collapse">
                                            <td>
                                                <h5>
                                                    원서 어필을 위한 입시사정관 연락
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    className="blue-font-color cursor collapse-btn pull-right margin-bottom-10"
                                    data-toggle="collapse"
                                    data-target=".interview-detail">더 보기 &raquo;</a>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">인터뷰</h2>
                                <a href='/#/blogs/157' >블로그 | </a>
                                <a href='/#/group_class/74' >커리큘럼</a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>
                                                모의 인터뷰 
                                                <span className="">
                                                        <i className="fa fa-question-circle color-gurume-red schools" data-tip={'1. 전 30분 - 옥스브리지 인터뷰 실제 기출문제를 사용한 모의 인터뷰 &#013; 2. 후 30분 - 피드백/인터뷰 테크닉 전수/모의 인터뷰에서 제시된 주제, 개념 관련 심층 토론 및 예상 문제 제시'}  data-for='tooltipSchool' data-event='mouseover'></i>
                                                        <ReactTooltip effect="solid" place="bottom" id='tooltipSchool' type='light'
                                                            globalEventOff='mouseout' >
                                                        </ReactTooltip>
                                                    </span>
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                인터뷰 역대 기출/예상 문제 풀이
                                                <span className="">
                                                        <i className="fa fa-question-circle color-gurume-red schools" data-tip={'인터뷰 기출문제 풀이를 통한 고등학교에서는 배우지 않지만 인터뷰에서 나올 수 있는 심화 주제/개념 학습'}  data-for='tooltipSchool' data-event='mouseover'></i>
                                                        <ReactTooltip effect="solid" place="bottom" id='tooltipSchool' type='light'
                                                            globalEventOff='mouseout' >
                                                        </ReactTooltip>
                                                    </span>
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                예상 질문 모범답변 준비 및 테크닉 연습
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">에세이 경시대회</h2>
                                <a href='/#/blogs/20' >블로그 | </a>
                                <a href='/#/group_class/92' >커리큘럼</a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>에세이 주제 선정 
                                                <span className="">
                                                        <i className="fa fa-question-circle color-gurume-red schools" data-tip={'포함된 핵심 내용 파악 및 분석 설명'}  data-for='tooltipSchool' data-event='mouseover'></i>
                                                        <ReactTooltip effect="solid" place="bottom" id='tooltipSchool' type='light'
                                                            globalEventOff='mouseout' >
                                                        </ReactTooltip>
                                                    </span>
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>주제에 대한 심층 조사/토론</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>브레인스토밍, 첨삭, 교정</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">에세이 II</h2>
                                <a href='/#/blogs/17' >블로그 | </a>
                                <a href='/#/group_class/76' >커리큘럼</a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>시험 테크닉 전수</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>기출 문제 및 유사 문제 (+그루미 제작) 풀이</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>시험 실러버스 내용 학습</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">영국 인턴쉽</h2>
                                <a href='/#/blogs/18' >블로그 | </a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>인턴쉽 리스트 산출 (연구소/병원 한정)</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>레쥬메 및 자기소개서 브레인스토밍/편집/교정</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>모의 인터뷰</h5>
                                            </td>
                                        </tr>
                                        <tr className="application-form-submission-detail collapse">
                                            <td>
                                                <h5>학교 이메일 회신 도움</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a
                                    className="blue-font-color cursor collapse-btn pull-right margin-bottom-10"
                                    data-toggle="collapse"
                                    data-target=".application-form-submission-detail">더 보기 &raquo;</a>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">UCAS 서류작성</h2>
                                <a href='/#/blogs/14' >블로그 | </a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>5개 대학/전공 선정</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>서류 작성 질의응답 및 제출 전 검토
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>COPA, SAQ 추가 에세이 첨삭 (케임브리지*)
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">오픈데이</h2>
                                <a href='/#/blogs/151' >블로그 | </a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>전공과목 교수님과 그룹 면담</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>대학 기숙사 숙박/학식 체험</h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>칼리지 및 과빌딩 탐방</h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="admissionUs-item-wrapper text-center">
                            <div className="admissionUs-field-item border-gray-1 border-radius-4">
                                <h2 className="bold-font padding-top-30">제출과제/성적 관리</h2>
                                <a href='/#/blogs/19' >블로그 | </a>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h5>
                                                최상 점수를 받을 수 있도록 수업
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                IB/A-Level 학습플랜 및 부족한 과목 수업
                                                </h5>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h5>
                                                오프라인/온라인
                                                </h5>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="clearfix margin-bottom-40"></div>
                    </div>
                </div>
            </div>
        )
    }


    createHtmlSentance = () => {
        return(
            <div className='adcUk-sen'>
                <ul>
                    <li>*자기소개서, 에세이 경시대회, 인턴쉽 지원 부문 에세이 라이팅 용어정리</li>
                    <li> <strong>브레인스토밍</strong> - 플래닝</li>
                    <li><strong>편집</strong> - 단어 선택/ 단락 구조/ 키포인트 추가, 삭제 및 강조</li>
                    <li><strong>교정</strong> - 자연스럽고 능숙한 영작</li>
                </ul>
            </div>
        )
    }

    createHtmlSentance2 = () => {
        return (
            <div className="admissionUs-map">
                <div className="admissionUs-map-box text-center margin-bottom-30">
                    <div className="admissionUs-map-title margin-top-30">
                        <h2 className="color-gurume-blue bold-font">도서 출간</h2>
                    </div>
                    <div className='ine-type-a'>
                        <ul>
                            <li> <img src='/images/temporary/All About UK Admissions Front Cover (Ko) 3D.47fd9930.jpg' alt='....' ></img> </li>
                            <li>
                                <div><span className='ine-type-title'>저자</span></div>
                                <div>
                                    <i> <p>
                                        "영국 대입을 생각하고 있는 학생들에게 도움이 되고자 지난 수년간의 경험, 노하우를 바탕으로 이 책을 집필하게 되었습니다."
                                    </p> </i>
                                </div>
                                <div>
                                    <strong>이혁진, </strong>
                                    B.Sc. M.Sc. Oxford University, 생화학
                                </div>
                                <div>
                                그루미 대표, 영국 유학 컨설턴트
                                </div>
                                <div>
                                    <strong>키안 양 (Kian Yang), </strong>
                                    B.A. Oxford University, 법학
                                </div>
                                <div>
                                법대 입시 컨설턴트, Practising US Attorney
                                </div>
                            </li>
                        </ul>
                        <div className='ine-type-ss'>
                            <img src='/images/temporary/contents.6a167fd9.png' alt='....' ></img>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    createHtmlSentance3 = () => {
        return(
            <div className="admissionUs-map">
                <div className="admissionUs-map-box text-center margin-bottom-30">
                    <div className="admissionUs-map-title margin-top-30">
                        <h2 className="color-gurume-blue bold-font">입시 후기/결과</h2>
                    </div>
                    <div className='ine-type-afs'>
                        <ul>
                            <li>
                                <span>옥스퍼드 화학과 합격</span>
                                <span>Yang</span>
                                <span><a href='https://www.youtube.com/watch?v=lfTuuBpQ5pA&feature=youtu.be&list=PLCn-JhjRTGbMz6mz4NaH4QpTh7O5k7Kxr' >영상 후기</a></span>
                            </li>
                            <li>
                                <span>옥스퍼드 생화학과 합격</span>
                                <span>Carmen</span>
                                <span><a href='https://www.youtube.com/watch?v=DFoytkWXXhM&t=15s' >영상 후기</a></span>
                            </li>
                            <li>
                                <span>옥스퍼드 수학과 합격</span>
                                <span>Yang</span>
                                <span><a href='https://www.youtube.com/watch?v=x-cHJqjo1CE&feature=youtu.be&list=PLCn-JhjRTGbMz6mz4NaH4QpTh7O5k7Kxr' >영상 후기</a></span>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <span>케임브리지 건축학과 합격</span>
                                <span>Wong</span>
                                <span><a href='https://www.youtube.com/watch?v=9RQ8CWDgE2Q&feature=youtu.be&list=PLCn-JhjRTGbMz6mz4NaH4QpTh7O5k7Kxr' >영상 후기</a></span>
                            </li>
                            <li>
                                <span>옥스퍼드 법대 합격</span>
                                <span>Kim</span>
                                <span><a href='https://www.youtube.com/watch?v=AHKM09fnN3I&t=61s' >영상 후기</a></span>
                            </li>
                            <li>
                                <span>옥스퍼드 심리&철학과 합격</span>
                                <span>Xing</span>
                                <span><a href='https://www.youtube.com/watch?v=GAM6DiKvayE&feature=youtu.be&list=PLCn-JhjRTGbMz6mz4NaH4QpTh7O5k7Kxr' >영상 후기</a></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    showingSS = () => {
        return(
                <table className="table table-striped">
                    <thead style={{fontWeight:"bold"}}>
                    <tr>
                        <td className="col-xs-3">이름</td>
                        <td className="col-xs-3">도시</td>
                        <td className="col-xs-3">대학</td>
                        <td className="col-xs-3">전공과목</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>김oo</td>
                        <td>한국</td>
                        <td>옥스퍼드</td>
                        <td>법학</td>
                    </tr>
                    <tr>
                        <td>익명</td>
                        <td>한국</td>
                        <td>옥스퍼드</td>
                        <td>지리학</td>
                    </tr>
                    <tr>
                        <td>익명</td>
                        <td>한국</td>
                        <td>옥스퍼드</td>
                        <td>법학</td>
                    </tr>
                    <tr>
                        <td>Wang</td>
                        <td>북경</td>
                        <td>옥스퍼드</td>
                        <td>수학 &amp; 컴퓨터 공학</td>
                    </tr>
                    <tr>
                        <td>Yang</td>
                        <td>북경</td>
                        <td>옥스퍼드</td>
                        <td>수학 &amp; 통계학</td>
                    </tr>
                    <tr>
                        <td>Yu</td>
                        <td>홍콩</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>Tsui</td>
                        <td>홍콩</td>
                        <td>옥스퍼드</td>
                        <td>정치, 철학, 경제(PPE)</td>
                    </tr>
                    <tr>
                        <td>Lam</td>
                        <td>홍콩</td>
                        <td>옥스퍼드</td>
                        <td>미술</td>
                    </tr>
                    <tr>
                        <td>Lee</td>
                        <td>싱가폴</td>
                        <td>케임브리지</td>
                        <td>경제학</td>
                    </tr>
                    <tr>
                    </tr>
                    <tr>
                        <td>Wu</td>
                        <td>싱가폴</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>Diwarkar</td>
                        <td>영국</td>
                        <td>옥스퍼드</td>
                        <td>생화학</td>
                    </tr>
                    <tr>
                        <td>Lawson</td>
                        <td>영국</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>익명</td>
                        <td>영국</td>
                        <td>옥스퍼드</td>
                        <td>자연과학</td>
                    </tr>
                    <tr>
                        <td>Bartoszcze</td>
                        <td>폴란드</td>
                        <td>옥스퍼드</td>
                        <td>경제학 &amp; 경영학</td>
                    </tr>
                    </tbody>
                </table>
        )
    }

    showingD = () => {
        return(
                <table className="table table-striped">
                    <thead style={{fontWeight:"bold"}}>
                    <tr>
                        <td className="col-xs-3">이름</td>
                        <td className="col-xs-3">도시</td>
                        <td className="col-xs-3">대학</td>
                        <td className="col-xs-3">전공과목</td>
                    </tr>
                    </thead>
                    <tbody>

                    <tr>
                        <td>권oo</td>
                        <td>한국</td>
                        <td>옥스퍼드</td>
                        <td>생화학</td>
                    </tr>
                    <tr>
                        <td>이oo</td>
                        <td>한국</td>
                        <td>케임브리지</td>
                        <td>의학</td>
                    </tr>
                    <tr>
                        <td>Fitzsimons</td>
                        <td>영국</td>
                        <td>케임브리지</td>
                        <td>공학</td>
                    </tr>
                    <tr>
                        <td>Yang</td>
                        <td>북경</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>Ning</td>
                        <td>천진</td>
                        <td>옥스퍼드</td>
                        <td>미술</td>
                    </tr>
                    <tr>
                        <td>Song</td>
                        <td>상해</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>익명</td>
                        <td>상해</td>
                        <td>옥스퍼드</td>
                        <td>수학 &amp; 철학</td>
                    </tr>
                    <tr>
                        <td>Xing</td>
                        <td>청도</td>
                        <td>옥스퍼드</td>
                        <td>심리학 &amp; 철학</td>
                    </tr>

                    <tr>
                        <td>Lee</td>
                        <td>심천</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>Huang</td>
                        <td>심천</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>Wilson</td>
                        <td>싱가폴</td>
                        <td>옥스퍼드</td>
                        <td>생화학</td>
                    </tr>
                    <tr>
                        <td>Wong</td>
                        <td>말레이시아</td>
                        <td>케임브리지</td>
                        <td>건축학</td>
                    </tr>
                    </tbody>
                </table>
        )
    }

    showingC = () => {
        return(
            <table className="table table-striped">
                <thead style={{fontWeight:"bold"}}>
                <tr>
                    <td>이름</td>
                    <td>도시</td>
                    <td>대학</td>
                    <td>전공과목</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Cao</td>
                    <td>중국</td>
                    <td>옥스퍼드</td>
                    <td>화학</td>
                </tr>
                <tr>
                    <td>Wang</td>
                    <td>중국</td>
                    <td>옥스퍼드</td>
                    <td>화학</td>
                </tr>
                <tr>
                    <td>익명</td>
                    <td>한국</td>
                    <td>옥스퍼드</td>
                    <td>생화학</td>
                </tr>
                <tr>
                    <td>익명</td>
                    <td>한국</td>
                    <td>옥스퍼드</td>
                    <td>정치, 철학, 경제(PPE)</td>
                </tr>

                <tr>
                    <td>이oo</td>
                    <td>한국</td>
                    <td>케임브리지</td>
                    <td>컴퓨터 과학</td>
                </tr>
                <tr>
                    <td>홍oo</td>
                    <td>한국</td>
                    <td>케임브리지</td>
                    <td>자연과학</td>
                </tr>
                </tbody>
            </table>
        )
    }

    showingB = () => {
        return(
            <table className="table table-striped">
                <thead style={{fontWeight:"bold"}}>
                <tr>
                    <td>이름</td>
                    <td>도시</td>
                    <td>대학</td>
                    <td>전공과목</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>이oo</td>
                    <td>한국</td>
                    <td>옥스퍼드</td>
                    <td>수학</td>
                </tr>
                <tr>
                    <td>홍oo</td>
                    <td>한국</td>
                    <td>옥스퍼드</td>
                    <td>공학</td>
                </tr>
                <tr>
                    <td>부oo</td>
                    <td>심천</td>
                    <td>옥스퍼드</td>
                    <td>공학</td>
                </tr>
                <tr>
                    <td>왕oo</td>
                    <td>심천</td>
                    <td>옥스퍼드</td>
                    <td>공학</td>
                </tr>
                <tr>
                    <td>양oo</td>
                    <td>심천</td>
                    <td>옥스퍼드</td>
                    <td>공학</td>
                </tr>
                </tbody>
            </table>
        )
    }


    showingA = () => {
        return(
                <table className="table table-striped">
                    <thead style={{fontWeight:"bold"}}>
                    <tr>
                        <td>이름</td>
                        <td>도시</td>
                        <td>대학</td>
                        <td>전공과목</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>이oo</td>
                        <td>한국</td>
                        <td>옥스퍼드</td>
                        <td>수학</td>
                    </tr>
                    <tr>
                        <td>김oo</td>
                        <td>한국</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>이oo</td>
                        <td>한국</td>
                        <td>옥스퍼드</td>
                        <td>PPE(정치,철학,경제)</td>
                    </tr>
                    <tr>
                        <td>양oo</td>
                        <td>한국</td>
                        <td>케임브리지</td>
                        <td>건축학</td>
                    </tr>
                    <tr>
                        <td>최oo</td>
                        <td>한국</td>
                        <td>케임브리지</td>
                        <td>자연과학 (생물)</td>
                    </tr>
                    <tr>
                        <td>최oo</td>
                        <td>한국</td>
                        <td>케임브리지</td>
                        <td>경제학</td>
                    </tr>
                    <tr>
                        <td>이oo</td>
                        <td>한국</td>
                        <td>케임브리지</td>
                        <td>수학</td>
                    </tr>
                    <tr>
                        <td>후oo</td>
                        <td>심천</td>
                        <td>옥스퍼드</td>
                        <td>공학</td>
                    </tr>
                    <tr>
                        <td>고oo</td>
                        <td>심천</td>
                        <td>옥스퍼드</td>
                        <td>물리학</td>
                    </tr>
                    <tr>
                        <td>부oo</td>
                        <td>심천</td>
                        <td>옥스퍼드</td>
                        <td>화학</td>
                    </tr>
                    <tr>
                        <td>정oo</td>
                        <td>심천</td>
                        <td>케임브리지</td>
                        <td>랜드이코노미</td>
                    </tr>

                    <tr>
                        <td>송oo</td>
                        <td>심천</td>
                        <td>케임브리지</td>
                        <td>자연과학 (물리)</td>
                    </tr>
                    <tr>
                        <td>천oo</td>
                        <td>산서</td>
                        <td>케임브리지</td>
                        <td>수학</td>
                    </tr>
                    <tr>
                        <td>수oo</td>
                        <td>대련</td>
                        <td>옥스퍼드</td>
                        <td>수학</td>
                    </tr>
                    <tr>
                        <td>Haymandhra</td>
                        <td>영국</td>
                        <td>케임브리지</td>
                        <td>공학</td>
                    </tr>
                    <tr>
                        <td>Pelden</td>
                        <td>영국</td>
                        <td>케임브리지</td>
                        <td>컴퓨터과학</td>
                    </tr>

                    </tbody>
                </table>
                
        )
    }


    createHtmlSentance4 = () => {
        return(
            <div className='inner-adc'>
                <div className='summ-how-it-header'>
                    <h4 className='summ-title how-it-green-light' data-parent="#accordion" data-target="#collapse-2018-graduation" data-toggle="collapse">
                        <span style={{cursor: "pointer"}} className="blue-font-color"><i className="fa fa-graduation-cap"></i>2018년 합격자 명단</span>
                    </h4>
                </div>
                <div id="collapse-2018-graduation" className="panel-collapse collapse summ-how-its-body">
                    <div className="panel-body">
                        {this.showingA()}
                    </div>
                </div>

                <div className='summ-how-it-header'>
                    <h4 className='summ-title how-it-green-light' data-parent="#accordion" data-target="#collapse-2017-graduation" data-toggle="collapse">
                        <span style={{cursor: "pointer"}} className="blue-font-color"><i className="fa fa-graduation-cap"></i>2017년 합격자 명단</span>
                    </h4>
                </div>
                <div id="collapse-2017-graduation" className="panel-collapse collapse summ-how-its-body">
                    <div className="panel-body">
                        {this.showingB()}
                    </div>
                </div>

                <div className='summ-how-it-header'>
                    <h4 className='summ-title how-it-green-light' data-parent="#accordion" data-target="#collapse-2016-graduation" data-toggle="collapse">
                        <span style={{cursor: "pointer"}} className="blue-font-color"><i className="fa fa-graduation-cap"></i>2016년 합격자 명단</span>
                    </h4>
                </div>
                <div id="collapse-2016-graduation" className="panel-collapse collapse summ-how-its-body">
                    <div className="panel-body">
                        {this.showingC()}
                    </div>
                </div>

                <div className='summ-how-it-header'>
                    <h4 className='summ-title how-it-green-light' data-parent="#accordion" data-target="#collapse-2015-graduation" data-toggle="collapse">
                        <span style={{cursor: "pointer"}} className="blue-font-color"><i className="fa fa-graduation-cap"></i>2015년 합격자 명단</span>
                    </h4>
                </div>
                <div id="collapse-2015-graduation" className="panel-collapse collapse summ-how-its-body">
                    <div className="panel-body">
                        {this.showingD()}
                    </div>
                </div>

                <div className='summ-how-it-header'>
                    <h4 className='summ-title how-it-green-light' data-parent="#accordion" data-target="#collapse-2014-graduation" data-toggle="collapse">
                        <span style={{cursor: "pointer"}} className="blue-font-color"><i className="fa fa-graduation-cap"></i>2014년 합격자 명단</span>
                    </h4>
                </div>
                <div id="collapse-2014-graduation" className="panel-collapse collapse summ-how-its-body">
                    <div className="panel-body">
                        {this.showingSS()}
                    </div>
                </div>



                

            </div>
  

        )
    }


    creatJxPas = () => {
        return(
            <div className="panel-group" id="accordion">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <p className="accordion-title">
                            <span
                                data-toggle="collapse"
                                data-parent="#accordion"
                                data-target="#collapse6"></span>
                        </p>
                    </div>
                    <div id="collapse6" className="panel-collapse collapse">
                        <div className="summ-how-its-body panel-body">
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    createLastPart = () => {
        return (
            <div
                className="parallax position-relative"
                style={{
                    backgroundSize: "cover",
                    backgroundImage: "url('/images/background/bg-ivy-league2.jpg')",
                    backgroundPosition: "center center"
                }}>
                <div className="admissionUs-ment">
                    <div className="admissionUs-ment-box text-center">
                        <div className="admissionUs-ment-content">
                            <span
                                className="admission-apply"
                                data-tip="data-tip"
                                data-for='tooltipEx'
                                data-event='click focus'>
                                <button
                                    className="admissionUs-apply coming-soon-btn border-5 padding-top-30 padding-bottom-30 padding-left-60 padding-right-60 boldest-font white-font-color no-margin tooltip-h2">
                                    상담신청
                                </button>
                                <ReactTooltip
                                    effect="solid"
                                    place="right"
                                    id='tooltipEx'
                                    type='light'
                                    globalEventOff='click'>
                                    <span className='vd-tooltip-ment'>
                                        <span>그루미&nbsp;
                                        </span>
                                        <span>
                                            <a href='/#/contact_us' className='blue-font-color'>
                                                고객 센터</a>
                                        </span>
                                        <span>에 연락해 주세요.</span>
                                    </span>
                                </ReactTooltip>
                            </span>
                        </div>
                        <div className="color-overlay"></div>
                    </div>
                </div>
            </div>
        )
    }


    createHtml = () => {
        return(
            <div>
                {this.createNav()}
                {this.createFirstPart()}
                <div className='academy-wapper-c'>
                    {this.createSecondPart()}
                    <div className='adconUk' >
                        <img src='/images/consulting/gurume-recommend-oxbridge-roadmap.5332db8d.png' alt='...' />
                    </div>
                    {this.createThirdPart()}
                    {this.createHtmlSentance()}
                    {this.createHtmlSentance2()}
                    {this.createHtmlSentance3()}


                    {this.creatJxPas()}
                    {this.createHtmlSentance4()}
                </div>

                {this.createLastPart()}
                
            </div>
        )
    }

    render(){
        return(this.createHtml())
    }

}

export default admissions_consulting_uk;