import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasLecture,
    TrasBuyCredit,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, NoImgContens, postConfig } from '../../../config/AjaxConfig';
import { STATE_NOTIFY, showNotify, moneyFormat } from '../../extra/cusUtil';
import { isAdmin } from '../../extra/utils';
import queryString from 'query-string';
import Pagination from 'react-js-pagination';
import Paginate from '../../utils/Paginate';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faClock, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import Player from '@vimeo/player';

import './moocshow.css';
import './moocindex.css';

class MoocshowInterestedCard extends Component {
    calculateDiscount = (fee, discountRate) => {
        return Math.round(fee * (100 - discountRate) / 100);
    }

    render() {
        return (
            <div className="moocshow-card-item" key={this.props.id}>
                <div className="moocshow-card">
                    <div className="moocshow-card-top">
                        <h3>{this.props.topic1}<br />
                            {this.props.topic2}
                        </h3>
                        <div className="moocshow-tutor">
                            <div className="moocshow-tutor-name">
                                <p>{this.props.tutorName} 강사</p>
                            </div>
                            <div className="moocshow-tutor-pic">
                                <div className="moocshow-tutor-pic-box">
                                    <img src={this.props.tutorPic} onError={function (e) { e.target.src = '/images/backuptutorpic.png'; }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="moocshow-card-bottom">
                        <div className="moocshow-price-box">
                            {
                                this.props.nowPrice == 0 ?
                                    <span className="moocshow-original-price">{this.props.originalPrice.toLocaleString('en')}</span>
                                    : <span className="moocshow-original-price is-discount">{this.props.originalPrice.toLocaleString('en')}</span>
                            }
                            <span className="moocshow-now-price">{this.props.nowPrice == 0 ? "" : this.calculateDiscount(this.props.originalPrice, this.props.nowPrice).toLocaleString('en')}</span>
                        </div>
                        {
                            this.props.isSubscribed ?
                                <button className="moocshow-apply-btn" onClick={this.props.onClick}>수강중</button> :
                                <button className="moocshow-apply-btn" onClick={this.props.onClick}>상세보기</button>
                        }
                        <p className="moocshow-time">총 <span>{this.props.totalMooc}강</span>/<span>{this.props.totalMoocTime}시간/</span><span>{this.props.totalMoocDate}일</span></p>
                    </div>
                </div>
            </div>
        )
    }
}
class MoocshowCard extends Component {
    render() {
        let sliceTitle
        if (this.props.title.length > 80) {
            sliceTitle = this.props.title.substring(0, 80)
        }
        return (
            <div className="moocshow-curriculum-list" key={this.props.id}>
                <div className="moocshow-curriculum-title">
                    <FontAwesomeIcon icon={faPlayCircle} />
                    &nbsp;
                    <p data-youtube={this.props.dataYoutube} onClick={this.props.onClick}>
                        <span>{this.props.chapter}</span>
                        &nbsp;
                        <span>
                            {
                                this.props.title.length > 80 ?
                                    sliceTitle + "..." : this.props.title
                            }
                        </span>
                    </p>
                </div>
                <div className="moocshow-curriculum-time">
                    <div className="moocshow-curriculum-time-clock">
                        <FontAwesomeIcon icon={faClock} />
                    </div>
                    <div className="moocshow-curriculum-time-text">
                        <p>{this.props.time}</p>
                    </div>
                </div>
            </div>
        )
    }
}
class Moocnew_show extends Component {

    state = {
        tutorId: "",
        r_isAdmin: false,
        isSubscribed: false,
        moocId: '',
        perMoocId: 1,
        recommendList: [],
        currentMoocList: '1',
        currentULink: '',
        sampleVideo1: '', //sample video1
        sampleVideo2: '', //sample video2
        showCredit: false,
        curriculumCurrentPage: 1,
        pageSize: 15,
        currentPage: 1,
        endPage: 5,
        totalDuration: 0,
        studentInfo: {
            name: "",
            id: 0,
        },
        userInfo: {
            full_name: '',
            picture: '',
            undergraduate_school: '',
            u__degree_1: '',
            moocTopic: '',
            moocPicUrl: '',
            canBuy: false,
            moocFee: '',
            moocFeeDiscount: 0,
            note_doc_url: '',
            question_doc_url: '',
        },
        thisUserId: "",
        moocsData: [],

        isDoCredit: false,


        defaultValues: {
            undergraduate_school: '학위가 선택되어 있지 않습니다.',
            u__degree_1: '전공과목이 선택되어 있지 않습니다.',
        },
        currentNav: '',
        NavObj: [
            {
                id: '1',
                topic: 'Mathematics',
            },
            {
                id: '2',
                topic: 'Biology',
            },
            {
                id: '3',
                topic: 'Chemistry',
            },
            {
                id: '4',
                topic: 'Physics',
            },
            {
                id: '5',
                topic: 'Economics',
            },
        ]
    }


    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.location.reload();
        }
        return true;
    }

    componentDidMount = () => {
        const urlHash = window.location.hash
        const urlStr = urlHash.substring(urlHash.indexOf('?') + 1)
        const parser = queryString.parse(urlStr)

        const moocid = this.props.match.params.moocid;

        if (moocid !== 'index' && moocid !== 'new') {
            this.doInit(moocid);
        }

        this.setState({
            curriculumCurrentPage: parser.current_page || 1
        })
    }



    doInit = async (_moocid) => {
        const moocid = _moocid;
        if (!moocid) {
            let msg = '링크가 잘못 되었습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const allUrls = await GetAjaxURL();


        const moocDataUrl = allUrls.mooc.index;
        const moocDataRes = await axios.get(moocDataUrl + '/' + moocid).catch((err) => { return err; })
        const moocRes = moocDataRes.data;
        const recommendList = moocRes.recommended_moocs;
        const moocFee = moocRes.fee;
        const moocFeeDiscount = moocRes.fee_discount;

        const mooc_subject_id = moocRes.mooc_subject_id;
        const NavObj = this.state.NavObj;
        const moocsData = moocRes.moocs;
        const tutorId = moocRes.tutor_id;

        let currentNav = '';
        for (let i = 0; i < NavObj.length; i++) {
            if (NavObj[i].id === String(mooc_subject_id)) {
                currentNav = NavObj[i].topic;
            }
        }

        if (moocRes.status) {
            // status가 있으면 에러 페이지
            let msg = '해당 페이지가 없습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.history.back();
        }

        const moocTopic = moocRes.topic + ' ' + moocRes.topic2;
        const moocPicUrl = moocRes.poster;
        const remainingDays = moocRes.remaining_days;
        const subscriptionDays = moocRes.subscription_days

        const note_doc_url = moocRes.note_doc_url;
        const question_doc_url = moocRes.question_doc_url;
        let isSubscribed = moocRes.is_subscribed;
        const full_name = moocRes.tutor_full_name;
        const picture = moocRes.tutor_pic;

        let undergraduate_school = this.state.defaultValues.undergraduate_school;
        let u__degree_1 = this.state.defaultValues.u__degree_1;
        if (moocRes.hasOwnProperty('school') && moocRes.hasOwnProperty('degree')) {
            undergraduate_school = moocRes.school;
            u__degree_1 = moocRes.degree;
        }

        const thisUserUrl = allUrls.apiThisUser;
        const userRes = await axios.get(thisUserUrl).catch((err) => { return console.log(err); });

        let student_name = "";
        let student_id = 0;
        let amount = 0;
        let r_isAdmin = false;
        let thisUserId = ""

        if (userRes.data.status === 'fail') {
            // let msg = '로그인 후에 이용해주세요.';
            // showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            // return window.location.href = '/#/login';
        } else {
            student_name = userRes.data.user.last_name + userRes.data.user.first_name;
            student_id = userRes.data.user.id;
            thisUserId = userRes.data.user.id;
            r_isAdmin = isAdmin(userRes.data.user);
            if (r_isAdmin) isSubscribed = true;

            const coinUrl = allUrls.gcoin.getTotalAmount;
            const coinRes = await axios.get(coinUrl).catch((err) => { return err; })
            const coinData = coinRes.data;
            const TempAmount = coinData.amount;
            const splitAmount = String(TempAmount).split('.');
            amount = splitAmount[0];
        }


        this.setState({
            ...this.state,
            tutorId: tutorId,
            r_isAdmin: r_isAdmin,
            isSubscribed: isSubscribed,
            moocId: moocid,
            recommendList: recommendList,
            totalDuration: moocRes.total_duration,
            studentInfo: {
                name: student_name,
                id: student_id,
            },
            sampleVideo1: moocRes.sampleVideo1,
            sampleVideo2: moocRes.sampleVideo2,
            userInfo: {
                ...this.state.userInfo,
                full_name: full_name,
                picture: picture,
                undergraduate_school: undergraduate_school,
                u__degree_1: u__degree_1,
                moocTopic: moocTopic,
                moocPicUrl: moocPicUrl,
                amount: amount,
                moocFee: moocFee,
                moocFeeDiscount: moocFeeDiscount,
                note_doc_url: note_doc_url,
                question_doc_url: question_doc_url,
                remainingDays: remainingDays + 1,
                subscriptionDays: subscriptionDays
            },
            thisUserId: thisUserId,
            moocsData: moocsData,
            currentNav: currentNav
        })
    }

    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/mooc"><TrasLecture /></a></li>
                    <li><a href="/#/mooc">{this.state.currentNav}</a></li>
                    <li className="active">{this.state.userInfo.moocTopic}</li>
                </ol>
            </div>
        )
    }
    calculateDiscount = (fee, discountRate) => {
        return Math.round(fee * (100 - discountRate) / 100);
    }

    closeAppendM = () => {
        const darkBg = document.getElementById('darkBg');
        darkBg.classList.remove('dark-active');
        darkBg.classList.remove('dark-bg-active');

        const appendM = document.getElementById('appendM');
        appendM.classList.remove('dark-active');
        const $ = window.jQuery

        let vidsrc_frame = $("#darkFrame");
        let player = new Player(vidsrc_frame)

        player.setVolume(0)

        this.setState({
            ...this.state,
            currentULink: ''
        })
    }
    blinkWatermark = () => {
        const $ = window.jQuery
        const watermarkText = $("#dark-watermark")
        let watermarkFunc = setInterval(() => {
            watermarkText.fadeIn('slow')
            $("#close-mooc").on("click", function () {
                clearInterval(watermarkFunc)
            })
            setTimeout(() => {
                watermarkText.fadeOut('slow')
                $("#close-mooc").on("click", function () {
                    clearTimeout(watermarkFunc)
                })
            }, 10000)
        }, 60000)
    }

    openMooc = (e) => {
        const thisUserId = String(this.state.thisUserId)
        if (this.state.tutorId === thisUserId || this.state.isSubscribed) {
            const target = e.target;
            let linkU = target.dataset['youtube'];
            if (!linkU) linkU = target.parentNode.dataset['youtube'];
            this.setState({
                ...this.state,
                currentULink: linkU
            }, () => {
                const darkBg = document.getElementById('darkBg');
                darkBg.classList.add('dark-active');
                darkBg.classList.add('dark-bg-active');

                const appendM = document.getElementById('appendM');
                appendM.classList.add('dark-active');


                const darkFrame = document.getElementById('darkFrame');
                darkFrame.src = this.state.currentULink;
            });
            this.blinkWatermark()
        }
    }
    goToSubscribe = () => {
        window.location.href = "/#/mooc/" + this.state.moocId + "/pay"
    }
    goToTutorDetailPage = (id) => {
        window.location.href = "/#/mooc/" + id
    }
    openTextbook = () => {
        if (this.state.isSubscribed) {
            if (this.state.userInfo.note_doc_url.length > 0) {
                window.open(this.state.userInfo.note_doc_url)
            } else {
                window.alert("제공되는 교재가 없습니다")
            }
        }
    }
    handlePageChange = (page) => {
        this.setState({
            currentPage: page
        })
    }
    goToPrevPage = () => {
        if (this.state.currentPage <= 1) {
            return
        } else {
            this.setState({
                currentPage: this.state.currentPage - 1
            })
        }
    }
    goToNextPage = () => {
        if (this.state.currentPage >= this.state.moocsData.length / 15) {
            return
        } else {
            this.setState({
                currentPage: this.state.currentPage + 1
            })
        }
    }
    createBody = () => {
        let recommendList = []
        for (let i = 0; i < this.state.recommendList.length; i++) {
            recommendList.push(this.state.recommendList[i]);
        }
        const totalData = this.state.moocsData.length;
        const pagedList = Paginate(this.state.moocsData, this.state.currentPage, this.state.pageSize)
        return (
            <div className="moocshow-wrapper">
                <div className="moocshow-box">
                    <div className="moocshow-box-left">
                        {
                            this.state.userInfo.moocPicUrl.length > 0 ?
                                <div className="moocshow-poster">
                                    <div className="moocshow-poster-img">
                                        <img src={this.state.userInfo.moocPicUrl} />
                                    </div>
                                </div> : ''
                        }
                        <div className="moocshow-curriculum">
                            <div className="moocshow-curriculum-header">
                                <h3>
                                    커리큘럼
                                    <br />
                                    <span className="font-size-16">(총 {this.state.moocsData.length}강 / {this.state.totalDuration}시간)</span>
                                </h3>
                            </div>
                            {
                                this.state.userInfo.note_doc_url.length > 0 ?
                                    <div className="moocshow-curriculum-textbook" onClick={this.openTextbook}>
                                        <FontAwesomeIcon icon={faBook} /><span> 교재</span>
                                    </div> : ''
                            }
                            {
                                this.state.moocsData.length > 0 ?
                                    pagedList.map(mooc =>
                                        <MoocshowCard
                                            id={mooc.id}
                                            chapter={mooc.chapter}
                                            title={mooc.sub_topic}
                                            onClick={this.openMooc}
                                            time={mooc.duration}
                                            dataYoutube={mooc.youtube}
                                        />
                                    ) : ''
                            }
                            <Pagination
                                hideFirstLastPages
                                totalItemsCount={totalData}
                                activePage={this.state.currentPage}
                                itemsCountPerPage={15}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange}
                                innerClass="pagination justify-content-center"
                                itemClass="page-item"
                                activeClass="active"
                                linkClass="page-link"
                            />
                        </div>
                    </div>
                    <div className="moocshow-box-right">
                        <div className="moocshow-price">
                            <p>
                                *인강은 크레딧으로만 구매가 가능합니다<br />
                                *<a data-tip="data-tip" data-for="refundRule" data-event="click focus" className='color-gurume-blue'>환불 규정</a>을 확인해주세요
                            </p>
                            <ReactTooltip
                                effect="solid"
                                place="right"
                                id='refundRule'
                                type='light'
                                border={true}
                                globalEventOff='click'
                            >
                                <li className="font-size-14">
                                    1. 수강 기간의 1/3이전  판매 가격에 2/3에 해당하는 금액을 환불<br />
                                        2. 수간 기간의 1/2 이전, 판매가격에 1/2에 해당하는 금액을 환불<br />
                                        3. 수강 기간의 1/2 경과, 환불 불가<br />
                                        *기간제 상품, 이벤트성 할인을 받은 경우 별도의 수강취소, 변경 및 환불규정이 적용될수 있습니다.
                                    </li>
                            </ReactTooltip>
                            <div>
                                <p>
                                    {
                                        this.state.userInfo.moocFeeDiscount == 0 ?
                                            <span className="moocshow-original-price">₩{this.state.userInfo.moocFee.toLocaleString('en')}</span>
                                            :
                                            <span className="moocshow-original-price is-discount">₩{this.state.userInfo.moocFee.toLocaleString('en')}</span>
                                    }
                                    <br />
                                    {/* now price(discounted price) */}
                                    {
                                        <span className="now-price">{this.state.userInfo.moocFeeDiscount == 0 ? "" : "₩" + this.calculateDiscount(this.state.userInfo.moocFee, this.state.userInfo.moocFeeDiscount).toLocaleString('en')}</span>
                                    }
                                </p>
                                {
                                    !this.state.isSubscribed ?
                                        <button className="blink-price-button" onClick={this.goToSubscribe}>수강신청하기</button> :
                                        <button>수강중</button>
                                }
                                <div className="moocshow-price-time-box">
                                    <p>
                                        <FontAwesomeIcon icon={faClock} />&nbsp;
                                        {
                                            this.state.isSubscribed ?
                                                this.state.userInfo.remainingDays + "일"
                                                : this.state.userInfo.subscriptionDays + "일"
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.sampleVideo1.length > 0 ?
                                <div className="moocshow-sample">
                                    <h2>샘플 영상</h2>
                                    <div className="moocshow-sample-box">
                                        {
                                            this.state.sampleVideo1.length > 0 ?
                                                <div className="videowrapper">
                                                    <iframe className="embed-responsive-item" src={this.state.sampleVideo1} frameBorder="0" allowFullScreen={true} webkitallowfullscreen={true} mozallowfullscreen={true}></iframe>
                                                </div>
                                                : ''
                                        }
                                        {
                                            this.state.sampleVideo2.length > 0 ?
                                                <div className="videowrapper">
                                                    <iframe className="embed-responsive-item" src={this.state.sampleVideo2} frameBorder="0" allowFullScreen={true} webkitallowfullscreen={true} mozallowfullscreen={true}></iframe>
                                                </div> : ''
                                        }
                                    </div>
                                </div> : ''
                        }
                        <div className="moocshow-interested">
                            <h2>You may also be interested</h2>
                            <div className="moocshow-interested-box">
                                {
                                    recommendList.map(mooc =>
                                        <MoocshowInterestedCard
                                            id={mooc.id}
                                            topic1={mooc.topic}
                                            topic2={mooc.topic2}
                                            tutorName={mooc.tutor_full_name}
                                            tutorPic={mooc.tutor_pic}
                                            originalPrice={mooc.fee}
                                            nowPrice={mooc.fee_discount}
                                            totalMooc={mooc.total_moocs}
                                            totalMoocTime={mooc.total_duration}
                                            totalMoocDate={mooc.subscription_days}
                                            onClick={() => this.goToTutorDetailPage(mooc.id)}
                                            isSubscribed={mooc.is_subscribed}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createShowYoutube = () => {
        return (
            <div id="darkBg" className="dark-bg">
                <button id="close-mooc" className="dark-close" onClick={this.closeAppendM}> x </button>
                {/* <button id="close-mooc" className='dark-close' onClick={() => this.closeAppendM(this.state.perMoocId)}> x </button> */}
                <div id="appendM" className="dark-inner">
                    <div className="watermark-wrapper">
                        <div id="dark-watermark">
                            <p id="watermark-text">
                                {this.state.studentInfo.name}({this.state.studentInfo.id})
                                </p>
                        </div>
                        <div className="videowrapper mooc-iframe-wrapper">
                            <iframe src="" className="embed-responsive-item" id="darkFrame" allowFullScreen={true} webkitallowfullscreen={true} mozallowfullscreen={true}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    render = () => {
        return (
            <div>
                {this.createNav()}
                {this.createBody()}
                {this.createShowYoutube()}
            </div>
        )
    }
}

export default withRouter(Moocnew_show);
