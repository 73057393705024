import React, {Component} from 'react';

import {metaClass, applyMetaHeader} from '../../extra/metaController';
import './bloginvitation.css';

class bloginvitation extends Component{


    componentDidMount(){
        this.setMetas();
    }

    setMetas = () => {
        const _type = '';
        const _title = 'GuruMe Blog Writer';
        const _url = window.location.href;
        let _description = '그루미 블로그 작가를 모집합니다.';
        const _imageUrl = '../../images/header-bg/teacher-list-header-bg.jpg';
        let _keywords = '';
        const newMetaClass = new metaClass(_type, _title, _url, _description, _imageUrl, _keywords);
        applyMetaHeader(newMetaClass);
    }


    createHeader = () => {
        return(
            <div className='bi-fafa'>
                <div className='bi-header'>
                    <h1>GuruMe Blog Writer</h1>
                    <h3>그루미 블로그 작가를 모집합니다.</h3>
                </div>
                <div className='bi-bg'>
                    <img src='../../images/header-bg/teacher-list-header-bg.jpg' alt='...' />
                </div>
            </div>
        )
    }
    
    createBodyA = () => {
        return(
            <div className='bi-ba-part'>
                <div className='bi-ba-part-a'>
                    <h3>그루미 X 블로그 컨텐츠</h3>
                    <div>그루미는 수업 매칭 뿐만 아니라 <span>유학 정보 컨텐츠</span>를 공유하기 위해 끊임없이 노력합니다.</div>
                    <div>그루미 <strong>선생님들</strong>은 직접 블로그 <strong>작가</strong>가 되어 <strong>본인</strong>의 <span>경험담</span>을 공유할 수 있습니다.</div>
                </div>
                <div className='bi-ba-part-b'>
                    <img src='/images/icon/blog-invitation/comment-icon.png' alt='hellena cut?' />
                </div>
            </div>
        )
    }

    createBodyB = () => {
        return(
            <div className='bi-ba-part-right'>
                <div className='bi-ba-part-a'>
                    <img src='/images/icon/blog-invitation/pen-icon.png' alt='but so yunng' />
                </div>
                <div className='bi-ba-part-b'>
                    <h3>블로그 컨텐츠 주제</h3>
                    <div>
                        <span className='blog-span-header'><i class="fa fa-check b-color"></i>고등학교</span>
                        입시 / 학업 / 학교 생활, 커리큘럼 준비 과정(IB/AP/A-Level/SAT/ACT/Coding) 대회 / 캠프 경험담, 타지에서 공부하며 느낀 점
                    </div>
                    <div>
                        <span className='blog-span-header'><i class="fa fa-check b-color"></i>대학(원)</span>
                        입시 / 학업 / 학교 생활, 인턴쉽, 교환 학생, 타지에서 공부하며 느낀 점
                    </div>
                    <div>
                        <span className='blog-span-header'><i class="fa fa-check b-color"></i>대학(원)</span>
                        그루미의 학생, 학부모님들이 궁금해하시는 위의 내용을 공유해 주세요!
                    </div>
                </div>
              
            </div>
        )
    }

    createBodyC = () => {
        return(
            <div>
                <div className='bi-ba-part'>
                    <div className='bi-ba-part-a'>
                        <h3>블로그 작가 혜택</h3>
                        <div>선생님 프로필 검색 사 <strong>이전 대비 상위 페이지</strong>로 게시</div>
                        <div><strong>카톡 / 이메일 뉴스레터</strong>로 선생님 프로필과 블로그 홍보</div>
                        <div>홈페이지 내의 <strong>추천 선생님</strong> 리스트로 게시</div>
                        <div className='bi-ba-blog-part'>
                            <ul>
                                <li>프로필 <span>조회 수</span><i className='fa fa-toggle-up gspot' /></li>
                                <li>프로필 <span>노출</span><i className='fa fa-toggle-up gspot' /></li>
                                <li>수업 <span>문의</span><i className='fa fa-toggle-up gspot' /></li>
                            </ul>
                        </div>
                    </div>
                    <div className='bi-ba-part-b'>
                        <img src='/images/icon/blog-invitation/alarm-icon.jpg' alt='hellena cut?' />
                    </div>
                </div>
                <div className='bi-ba-part-c'>
                    <div>커리큘럼을 포함한 해외 유학 관련 정보들이 제한적이기 때문에 학부모님들께서는 확실하고 정확한 정보를 제공하는 그루미 블로그에 관심이 많습니다.</div>
                    <div>블로그에 선생님 프로필이 함께 연동되어 있기 때문에 블로그 내용 관련 수업 매칭에 효과적입니다.</div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return(
            <div>
                {this.createHeader()}
                {this.createBodyA()}
                {this.createBodyB()}
                {this.createBodyC()}
            </div>
        )
    }

    render(){
        return(
            <div>
                {this.createHtml()}
            </div>
        )
    }

}

export default bloginvitation;