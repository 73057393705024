import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './MainModal.css';
import './NewsletterModal.css'
const $ = window.jQuery;

class NewsletterModalItem extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.isOpen ?
                        <React.Fragment>
                            <div className="modal-overlay" />
                            <Modal.Dialog id={this.props.id} className="newsletter-modal">
                                <Modal.Header>
                                    <h5 class="modal-title">그루미 선생님 뉴스레터</h5>
                                    <button className="modal-close-btn" onClick={this.props.close}>X</button>
                                </Modal.Header>
                                <Modal.Body>
                                    {/* <div className="modal-img">
                                        <img src={this.props.img} />
                                    </div> */}
                                    <div className="modal-event-content">
                                        {this.props.content}
                                    </div>
                                </Modal.Body>
                            </Modal.Dialog>
                        </React.Fragment> : null
                }
            </React.Fragment>
        )
    }
}
export default class NewsletterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen_1: true,
            isModalOpen_2: true,
            checked: false,
        }
        this.closeModal.bind(this)
    }
    closeModal(id) {
        $('.newsletter-wrapper').hide();
        if (id === 1) {
            this.setState({
                isModalOpen_1: false,
            })
        } else if (id === 2) {
            this.setState({
                isModalOpen_2: false,
            })
        }
    }
    checkModal(id) {
        if (id === 1) {
            this.closeModal(1)
        } else if (id === 2) {
            this.closeModal(2)
        }
    }
    render() {
        const modals = [
            {
                num: 1,
                isModalNum: this.state.isModalOpen_1,
                id: "newsletter-modal-1",
                content:
                    <div>
                        <p>
                            안녕하세요 그루미 입니다.<br />
        항상 그루미를 믿고 이용해 주시는 선생님께 깊은 감사를 드립니다.
    </p>
                        <p>
                            <b>선생님 이용약관</b><br />
고객에게보다 합리적인 과외서비스를 제공하고자 자유로운 매칭 활성화를 위해 그루미가 새로워졌습니다. 매니저 매칭이 아닌 자율 매칭만을 진행합니다. 이에 따라 <a href="https://docs.google.com/document/d/1NcHuzyU_WLZ4nhrHHZoqJvDoWg5PhAQgsyAF1b1Lwa4/edit?usp=sharing" target="_blank">이용약관</a>이 대폭 변경되었으니 전문 확인 부탁드립니다. (그루미 마이페이지에서 또한 확인할 수 있습니다) <a href="https://docs.google.com/document/d/1vz_BQW8OTZB-OWfDQA8jilpIBKKsfMop9k2-qvnfc_Y/edit?usp=sharing" target="_blank">이용약관</a>에서 동의하지 않는 부분이 있다면 연락주시면 감사하겠습니다.
    </p>
                        <p>
                            <b>사이트 이용</b><br />
        (쪽지 확인)<br />
매니저 매칭이 이루어지지 않으므로 수업 매칭을 위해 쪽지를 자주 확인해 주세요.

    </p>
                        <p>
                            (수업료 상담)<br />
매니저 매칭이 사라졌기 때문에 이미 진행하고 있는 수업의 경우 수업과목, 내용에 따라 기존에 협의된 시급에서  +/- 1만원으로 조정할 수 있습니다. 학부모님과 상담을 통해 수업료를 협의해 주세요.
    </p>
                        <p>
                            (인보이스 사용법)<br />
기존의 이용료 정책(시간당 1만원, 시간당 3만원, 문의당 10만원)에서 시간당 이용료 1만원으로 이용료 정책을 변경했습니다. 그루미에서는 어떤 수업이든 시간당 이용료 1만원이 책정되며 이에 따라 숏노티스/단기/과제/매니징 버튼과 패키지/일회성 과제 버튼이 사라졌습니다. 인보이스 발행시 학부모님과 협의한 시급과 총 시간을 알맞게 기입해주세요.
    </p>
                        <p>
                            <b>채용/ 리크룻/ 리퍼럴</b><br />
*그루미 커리어 <a href="https://gurume.kr/#/about_gurume/career" target="_blank">www.gurume.kr/about_gurume/career</a><br />
*성공적인 리퍼럴을 해주시는 선생님께는 30만원의 소개비를 드립니다 (인턴/방학특강 TA 제외)
    </p>
                        <p>
                            그루미와 아래 분야에서 같이 일하실 인재를 찾습니다:<br />
    - IB/ A-Level/ AP/ SAT 풀타임 선생님 <a href="https://docs.google.com/document/d/1k1ihoQpo6cuLHTJHOiWZNRGGHEBLGqyI1kcT3ZBzo6c/edit?usp=sharing" target="_blank">https://goo.gl/Srdrbp</a><br />
- 개발자 <a href="https://www.rocketpunch.com/jobs/43035" target="_blank">www.rocketpunch.com/jobs/43035</a>
                        </p>
                    </div>
            },
        ]
        return (
            <div className="newsletter-wrapper" >
                <div className="newsletter-box">
                    {
                        modals.map(
                            modal =>
                                <NewsletterModalItem
                                    id={modal.id}
                                    content={modal.content}
                                    isOpen={modal.isModalNum}
                                    close={() => this.checkModal(modal.num)}
                                />
                        )
                    }
                </div>
            </div>
        )
    }
}