import React, { Component } from 'react';
import { withRouter } from 'react-router';

import {
    TrasHome,
    TrasDashboard,
} from '../../../Translate/EachTranslateC';

import './tdashboard-left.css';
import './tdashboard-right.css';
import '../../../default.css';

import './thComponents/TdBlogComponent.css';

import '../template/TApplyMsgs.css';
import { GetAjaxURL, postConfig, copyToClipboard, clipMsgState, NoImgContens } from '../../../config/AjaxConfig';
import axios from '../../../config/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope, faPhone,
} from '@fortawesome/free-solid-svg-icons';

import TdListComponent from './thComponents/TdListComponent';
import TdClassComponent from './thComponents/TdClassComponent';
import TdBlogComponent from './thComponents/TdBlogComponent';
import TdMoocComponent from './thComponents/TdMoocComponent';
import TdGoodClassComponent from './thComponents/TdGoodClassComponent';
import TdApplyComponent from './thComponents/TdApplyComponent';


import { showNotify, STATE_NOTIFY, moneyFormat } from '../../extra/cusUtil';

import { InfinityController, INFI_STATE } from '../../extra/InfinityScroll';
import '../../utils/InfinityScroll.css';
import MainModal from '../../../components/utils/MainModal'
import NewsletterModal from '../../../components/utils/NewsletterModal';

const $ = window.jQuery;

// class NewsLetterModal extends Component {
//     render() {
//         return (
//             <div id="newsletterModal" class="modal" tabindex="-1" role="dialog">
//                 <div class="modal-dialog" role="document">
//                     <div class="modal-content">
//                         <div class="modal-header">
//                             <h5 class="modal-title">그루미 선생님 뉴스레터</h5>
//                             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
//                                 <span aria-hidden="true">&times;</span>
//                             </button>
//                         </div>
//                         <div class="modal-body">
//                             <div>
//                                 <p>
//                                     안녕하세요 그루미 입니다.<br />
//                         항상 그루미를 믿고 이용해 주시는 선생님께 깊은 감사를 드립니다.
//                     </p>
//                                 <p>
//                                     <b>선생님 이용약관</b><br />
// 고객에게보다 합리적인 과외서비스를 제공하고자 자유로운 매칭 활성화를 위해 그루미가 새로워졌습니다. 매니저 매칭이 아닌 자율 매칭만을 진행합니다. 이에 따라 <a href="https://docs.google.com/document/d/1NcHuzyU_WLZ4nhrHHZoqJvDoWg5PhAQgsyAF1b1Lwa4/edit?usp=sharing" target="_blank">이용약관</a>이 대폭 변경되었으니 전문 확인 부탁드립니다. (그루미 마이페이지에서 또한 확인할 수 있습니다) <a href="https://docs.google.com/document/d/1vz_BQW8OTZB-OWfDQA8jilpIBKKsfMop9k2-qvnfc_Y/edit?usp=sharing" target="_blank">이용약관</a>에서 동의하지 않는 부분이 있다면 연락주시면 감사하겠습니다.
//                     </p>
//                                 <p>
//                                     <b>사이트 이용</b><br />
//                         (쪽지 확인)<br />
// 매니저 매칭이 이루어지지 않으므로 수업 매칭을 위해 쪽지를 자주 확인해 주세요.

//                     </p>
//                                 <p>
//                                     (수업료 상담)<br />
// 매니저 매칭이 사라졌기 때문에 이미 진행하고 있는 수업의 경우 수업과목, 내용에 따라 기존에 협의된 시급에서  +/- 1만원으로 조정할 수 있습니다. 학부모님과 상담을 통해 수업료를 협의해 주세요.
//                     </p>
//                                 <p>
//                                     (인보이스 사용법)<br />
// 기존의 이용료 정책(시간당 1만원, 시간당 3만원, 문의당 10만원)에서 시간당 이용료 1만원으로 이용료 정책을 변경했습니다. 그루미에서는 어떤 수업이든 시간당 이용료 1만원이 책정되며 이에 따라 숏노티스/단기/과제/매니징 버튼과 패키지/일회성 과제 버튼이 사라졌습니다. 인보이스 발행시 학부모님과 협의한 시급과 총 시간을 알맞게 기입해주세요.
//                     </p>
//                                 <p>
//                                     <b>채용/ 리크룻/ 리퍼럴</b><br />
// *그루미 커리어 <a href="https://gurume.kr/#/about_gurume/career" target="_blank">www.gurume.kr/about_gurume/career</a><br />
// *성공적인 리퍼럴을 해주시는 선생님께는 30만원의 소개비를 드립니다 (인턴/방학특강 TA 제외)
//                     </p>
//                                 <p>
//                                     그루미와 아래 분야에서 같이 일하실 인재를 찾습니다:<br />
//                     - IB/ A-Level/ AP/ SAT 풀타임 선생님 <a href="https://docs.google.com/document/d/1k1ihoQpo6cuLHTJHOiWZNRGGHEBLGqyI1kcT3ZBzo6c/edit?usp=sharing" target="_blank">https://goo.gl/Srdrbp</a><br />
// - 개발자 <a href="https://www.rocketpunch.com/jobs/43035" target="_blank">www.rocketpunch.com/jobs/43035</a>
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }
class Tdashboard extends Component {


    state = {
        lang: 'ko',        // this will bring from localstorage
        currentCondition: 'TdClassComponent',      // DEFAULT VALUE
        CURRENT_STATE: {
            TdClassComponent: 'TdClassComponent',
            TdBlogComponent: 'TdBlogComponent',
            TdMoocComponent: 'TdMoocComponent',
            TdGoodClassComponent: 'TdGoodClassComponent',
            TdApplyComponent: 'TdApplyComponent',
        },

        stateParams: {
            tdId: '',
            tdNameData: '',
            tdSchoolData: '',
            tdPicture: '../images/backuptutorpic.png',             //DEFAULT VALUE
            tdEmail: '',
            tdPhone: '',
            tdSkype: '',
            tdWhatChat: '',
            tdKakao: '',
            tdWechat: '',
            tdMoney: '',
            tdDataOt: '',
            tdBlogsData: [],
            tdGoodClassData: [],
            tdType: [],
            tdModData: [],
            gCoin: '',
            tax: [],
            tdMoocsData: [],
        },
        originData: {
            tTutionData: [],
        },

        // INFINITY SCROLL CONTROL
        // totalTdModData : [],                 // 전체 데이터 = stateParams.tdModData
        currentTdModData: [],                  // 현재 데이터
        InfinityController: null,              // InfinityController

        needInvoiceCopy: false,

        ConfirmMsgTextArea: '',
        invoiceTextArea: '',
        invoiceToken: '',

        needDetailInfo: false,
        invoiceData: {},
        needInvoice: false,
        needContact: false,
        needConfirmMsg: false,
        tdContactData: {},

        currentOrderNum: '',
        isAjaxDone: false,
    }



    componentDidMount = async () => {


        this.startInit();
        $("#newsletterModal").show('slow')
        $("#td-total-fee-modal").hide('slow')
    }





    startInit = async () => {

        const allUrls = await GetAjaxURL();
        const apiTdash = allUrls.apiTeacherDashboard;
        const TdashRtn = await axios.get(apiTdash).catch((err) => { return err });
        const thisUserApi = TdashRtn.data.thisUser;
        const tuitionDatas = TdashRtn.data.tuitions;
        const gcoinRes = TdashRtn.data.gcoin;
        const tax = TdashRtn.data.tax;

        // Login Check
        if (thisUserApi.status === 'fail') {

            let msg = '로그인 후 이용해주세요';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            // WILL NOT LOGIN
            window.location.href = '/#/login';
            return;
        }


        // const tuitionDatas = await axios.get(tuitionUrl).catch((err)=>{return err});
        // const gcoinRes = await axios.get(gcoinUrl).catch((err)=>{return console.log(err)});

        const userProfile = thisUserApi.profile;
        const userData = thisUserApi.user;


        const tdNameData = userData['last_name'] + ' ' + userData['first_name'];
        let tdSchoolData = userProfile.school;
        if (tdSchoolData === undefined || tdSchoolData === 'undefined') {
            tdSchoolData = userProfile.high_school;
        }


        const tdId = userData.id;
        const tdPicture = userProfile.picture;

        const tdEmail = userData.email;
        const tdPhone = userData.phone;
        const tdSkype = userData.skype;
        const tdWhatChat = userData.whatsapp;
        const tdKakao = userData.kakaotalk;
        const tdWechat = userData.wechat;
        const tdMoney = userProfile.tax_amount_first_half_19;


        const tdType = userData.type;
        const tuitionData = tuitionDatas.tuitions;

        let tdDataOt = '';
        if (userProfile['ot']) {
            tdDataOt = userProfile.ot;
        }


        const tdBlogsData = userProfile.blogs;
        const tdGoodClassData = userProfile.group_classes;
        const tdMoocsData = userProfile.moocs;

        // 수업료 인보이스 발행 리스트
        let tdModData = [];
        let tempkey = [];
        for (let i = 0; i < tuitionData.length; i++) {
            if (!tempkey.includes(tuitionData[i].student_id)) {
                tdModData.push(tuitionData[i]);
                tempkey.push(tuitionData[i].student_id);
            }
        }

        // Credit
        const gCoin = gcoinRes.amount;

        const InfinityControllers = new InfinityController();
        InfinityControllers.setInit(tdModData);
        const currentTdModData = InfinityControllers.getDataWithCount(INFI_STATE.START_COUNT);

        this.setState({
            ...this.state,
            stateParams: {
                ...this.state.stateParams,
                tdId,
                tdPicture,
                tdNameData,
                tdSchoolData,
                tdEmail,
                tdPhone,
                tdSkype,
                tdWhatChat,
                tdKakao,
                tdWechat,
                tdMoney,
                tdDataOt,
                tdBlogsData,
                tdMoocsData,
                tdGoodClassData,
                tdType,
                tdModData,
                gCoin,
                tax
            },
            currentTdModData: currentTdModData,
            InfinityController: InfinityControllers,
            originData: {
                tTutionData: tuitionData
            },
            isAjaxDone: true,
        }, () => {
            // this.props.RootBindingData.doLoading(false);
        })


    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><TrasDashboard /></li>
                </ol>
            </div>
        )
    }


    clickToProf = () => {
        if (this.state.stateParams.tdType === 'student') {
            const msg = '프로필은 선생님만 볼 수 있습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        } else {
            window.location.href = '/#/teachers/' + this.state.stateParams.tdId;
        }
    }

    clickToModf = () => {
        if (this.state.stateParams.tdType === 'student') {
            const msg = '프로필은 선생님만 볼 수 있습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        } else {
            window.location.href = '/#/teachers/' + this.state.stateParams.tdId + '/edit';
        }
    }

    goToTutorhive = () => {
        if(window.confirm("튜터하이브로 이동하셔서 프로필을 수정해주시길 바랍니다. 이동하시겠습니까?")){
            window.location.href="https://tutorhive.kr"
        }
    }
    SummLeft_1 = () => {
        let tdPicture = this.state.stateParams.tdPicture;

        return (
            <div className='td-summ-left'>
                <div className='td-summ-first'>
                    <div className="td-profile-img"><img src={tdPicture} alt='... ...' onError={function (e) { e.target.src = '../images/backuptutorpic.png'; }} /></div>
                    <span>
                        {this.state.stateParams.tdNameData}
                        <span>
                            {`(${this.state.stateParams.tdSchoolData})`}
                        </span>
                    </span>
                    <span>마지막 업데이트</span>
                </div>
                <div>
                    <div className='td-show-profile'>
                        <button className='tdash-left-normal-btn' onClick={this.clickToProf}>프로필 보기</button>
                        <button className='tdash-left-normal-btn' onClick={this.goToTutorhive}>프로필 수정</button>
                    </div>
                    <div className='td-show-profile-sec'>
                        <ul>
                            <li><span><FontAwesomeIcon icon={faEnvelope} /></span><span>{this.state.stateParams.tdEmail ? this.state.stateParams.tdEmail : '입력되지 않았습니다.'}</span></li>
                            <li><span><FontAwesomeIcon icon={faPhone} /></span><span>{this.state.stateParams.tdPhone ? this.state.stateParams.tdPhone : '입력되지 않았습니다.'}</span></li>
                            <li><span><i className='fa fa-skype' /></span><span>{this.state.stateParams.tdSkype ? this.state.stateParams.tdSkype : '입력되지 않았습니다.'}</span></li>
                            <li><span><img src='/images/kakao_icon.de3bdea1.png' alt='-kakao' /></span><span>{this.state.stateParams.tdKakao ? this.state.stateParams.tdKakao : '입력되지 않았습니다.'}</span></li>
                            <li><span><i className='fa fa-wechat' /></span><span>{this.state.stateParams.tdWechat ? this.state.stateParams.tdWechat : '입력되지 않았습니다.'}</span></li>
                            <li><span><i className='fa fa-whatsapp' /></span><span>{this.state.stateParams.tdWhatChat ? this.state.stateParams.tdWhatChat : '입력되지 않았습니다.'}</span></li>
                            <li className="td-total-fee"><button onClick={this.showTotalFeeModal}>총 수업료</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    showTotalFeeModal = () => {
        $("#td-total-fee-modal").show('slow')
    }
    LinkTypeC_1 = () => {
        window.location.href = '/#/credit/balance';
    }
    LinkTypeC_2 = () => {
        window.location.href = '/#/credit/withdraw';
    }

    SummLeft_2 = () => {

        let credit = this.state.stateParams.gCoin;
        if (!credit) credit = 0;

        credit = String(credit).split('.')[0];


        return (
            <div className='tdash-credit-wrapper'>
                <span>보유 크레딧 : {moneyFormat(credit)} 원 </span>
                <div className='td-show-profile td-credit-cards'>
                    <button className='tdash-left-normal-btn' onClick={this.LinkTypeC_1} >사용내역</button>
                    <button className='tdash-left-normal-btn' onClick={this.LinkTypeC_2}>반환</button>
                </div>
            </div>
        )
    }


    LinkType_1 = () => {
        window.open('https://docs.google.com/document/d/1NcHuzyU_WLZ4nhrHHZoqJvDoWg5PhAQgsyAF1b1Lwa4/edit');
    }
    LinkType_2 = () => {
        window.open('https://docs.google.com/document/d/1pY380AurI-B3u316QYdovJsmabWGU8rv07uPl4XbAss/edit');
    }
    LinkType_3 = () => {
        window.open('https://docs.google.com/document/d/14dOsw2Uz1V89w18s7rpGhfb_LPa0WxVg7EbURbOovr8/edit');
    }
    LinkType_5 = () => {
        window.open('https://docs.google.com/document/d/1jvWC8z8I-3vQ05ZQVaP2LQ8o5rolOAtR7-pRvJzNxkc/edit');
    }
    LinkType_4 = () => {
        window.open('https://drive.google.com/drive/folders/0B_AwTAYY3Iy6M05OQWU4V1RGdU0');
    }
    LinkType_6 = () => {
        window.open('https://docs.google.com/document/d/10ro9LMQrot2y52hw7fr3qA_ihC6kUzETgEGyeAe7HpM/edit');
    }
    LinkType_7 = () => {
        window.open('https://youtu.be/XMr6jVvbLuo');
    }
    LinkType_8 = () => {
        window.open('https://docs.google.com/document/d/1VuFzmuZLv4HhFH3JnxZba_MqR9L1P7fG8TbytJ1kz4E/edit');
    }

    SummLeft_3 = () => {
        return (
            <div className='td-left-menu'>
                <span>꼭 읽어주세요!</span>
                <ul className='td-left-must-read'>
                    <li onClick={this.LinkType_1}>선생님 이용약관</li>
                    <li onClick={this.LinkType_3}>수업 비법/요령</li>
                    <li onClick={this.LinkType_7}>온라인 수업 TIP</li>
                    <li onClick={this.LinkType_4}>수업 자료</li>
                    <li onClick={this.LinkType_5}>강사료 세금 및 해외송금</li>
                    <li onClick={this.LinkType_6}>Progress Report 예시</li>
                    <li onClick={this.LinkType_8}>FAQ</li>
                </ul>
            </div>
        )
    }

    LinkTypeB_1 = () => {
        window.open('https://docs.google.com/document/d/1S6nNNH34y0heMfYjYrRWmp1JVk7B5ExUrT91cmbTqXw/edit');
    }
    LinkTypeB_2 = () => {
        window.open('https://docs.google.com/document/d/1k1ihoQpo6cuLHTJHOiWZNRGGHEBLGqyI1kcT3ZBzo6c/edit');
    }
    LinkTypeB_3 = () => {
        window.open('http://localhost:3000/#/mooc_logistics');
    }
    LinkTypeB_4 = () => {
        window.open('https://docs.google.com/document/d/1J8YW9qq7yOFJmQlYcKpPDQvvrVFO7-FiKG_ZAXPAOh4/edit');
    }


    SummLeft_4 = () => {
        return (
            <div className='td-left-menu'>
                <span>더 참여해주세요!</span>
                <ul className='td-left-must-join'>
                    {/* <li onClick={this.LinkTypeB_1}>하프풀타임/재학생 선생님</li> */}
                    <li onClick={this.LinkTypeB_2}>풀타임/전문 선생님</li>
                    {/* <li onClick={this.LinkTypeB_3}>인강 온라인 강좌</li> */}
                    <li onClick={this.LinkTypeB_4}>그루미 인강 선생님</li>
                </ul>
            </div>
        )
    }



    SummLeftBody = () => {
        return (
            <div className="tdashboard-left-pannel-wapper">
                <div className='tdashboard-left-pannel'>
                    {this.SummLeft_1()}
                </div>
                <div className='tdashboard-left-pannel'>
                    {this.SummLeft_2()}
                </div>
                <div className='tdashboard-left-pannel'>
                    {this.SummLeft_3()}
                </div>
                <div className='tdashboard-left-pannel'>
                    {this.SummLeft_4()}
                </div>
            </div>
        )
    }





    SummRightA = () => {

        const currentCondition = this.state.currentCondition;
        const doAction = [];

        if (currentCondition === this.state.CURRENT_STATE.TdClassComponent) {
            doAction.push(
                <TdClassComponent key='1'
                    showingInvoice={this.showingInvoice}
                    showingDetailInfo={this.showingDetailInfo}
                    showingNewTdData={this.showingNewTdData}
                    tdMoney={String(this.state.stateParams.tdMoney)}
                    showingNewChildren={this.showingNewChildren}
                    clickConfirmMsg={this.clickConfirmMsg}
                    tTutionData={this.state.originData.tTutionData}
                    isAjaxDone={this.state.isAjaxDone}
                />
            );
        } else if (currentCondition === this.state.CURRENT_STATE.TdBlogComponent) {
            doAction.push(
                <TdBlogComponent key='2'
                    tdBlogsData={this.state.stateParams.tdBlogsData}
                    tdNameData={this.state.stateParams.tdNameData}
                />
            );
        } else if (currentCondition === this.state.CURRENT_STATE.TdMoocComponent) {
            doAction.push(<TdMoocComponent key='5' tdMoocsData={this.state.stateParams.tdMoocsData} tutorPic={this.state.stateParams.tdPicture} />);
        } else if (currentCondition === this.state.CURRENT_STATE.TdGoodClassComponent) {
            doAction.push(
                <TdGoodClassComponent key='3'
                    tdGoodClassData={this.state.stateParams.tdGoodClassData}
                    tdNameData={this.state.stateParams.tdNameData}
                />
            );
        } else if (currentCondition === this.state.CURRENT_STATE.TdApplyComponent) {
            doAction.push(<TdApplyComponent key='4' />);
        }


        return (
            <div>
                {doAction}
            </div>
        )
    }


    ChangeCondition = (_condition) => {

        const Condition = _condition;

        this.setState({
            ...this.state,
            currentCondition: Condition
        })

    }


    openNewWindow = () => {
        window.location.href = "/#/ot"
    }

    createRequestOtLocked = () => {
        return (
            <div className='tdashboard-ot-lock-wapper'>
                <h3>OT 미수료 선생님입니다</h3>
                <button onClick={this.openNewWindow}>&#11088; OT 수료하러 가기 &#11088;</button>
            </div>
        )
    }


    SummRightBody = () => {
        return (
            <div className="t-dash-right-body">
                <div className='tdashboard-left-pannel'>
                    {this.state.stateParams.tdDataOt !== 1 ?
                        this.createRequestOtLocked()
                        : ''
                    }

                    <div className='td-list-body'>
                        <TdListComponent
                            ChangeCondition={this.ChangeCondition}
                            CURRENT_STATE={this.state.CURRENT_STATE}
                        />
                    </div>
                    {
                        this.state.isAjaxDone ?
                            this.SummRightA()
                            : <div className="teacher-dashboard-loading">
                                <div className="loader"></div>
                            </div>
                    }
                </div>
            </div>
        )
    }

    createTotalFeeModal = () => {
        let tax_display = [];
        for (let i = 0; i < this.state.stateParams.tax.length; i++) {
            const year = this.state.stateParams.tax[i]['year'];

            let monthly_data = [];
            const tax_tmp = this.state.stateParams.tax[i]['tax'];

            for (var j in tax_tmp) {
                const tax_data = tax_tmp[j];

                monthly_data.push(
                    <tr>
                        <td>{tax_data['month']}</td>
                        <td>{tax_data['total_amount']}</td>
                        <td>{tax_data['tax']}</td>
                    </tr>
                );
            }

            tax_display.push(
                <div className="td-table">
                    <table border="1">
                        <thead>
                            <tr>
                                <th colSpan="3">{year}</th>
                            </tr>
                            <tr>
                                <td>Month</td>
                                <td>Total Class Fee</td>
                                <td>3.3%</td>
                            </tr>
                        </thead>
                        <tbody>
                            {monthly_data}
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <div id='td-total-fee-modal'>
                <div className='tdash-normal-state'>
                    <div className="modal-content tdash-common-state">
                        <div className="modal-header">
                            <h3>총 수업료</h3>
                            <button type="button" onClick={this.hideFeeModal}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {/* you can input data here */}
                            {tax_display}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    createBody = () => {
        return (
            <div className="container tdashboard-wrap">
                {this.SummLeftBody()}
                {this.SummRightBody()}
            </div>
        )
    }



    clickToPoint = (e) => {
        const target = e.target;
        let isRightDom = false;
        if (target.id === 'td-tmp-data') {
            isRightDom = true;
        }

        if (isRightDom) {
            this.setState({
                ...this.state,
                needInvoiceCopy: false
            })
        }
    }
    clickToPoint2 = (e) => {
        const target = e.target;
        let isRightDom = false;
        if (target.id === 'td-tmp-data2') {
            isRightDom = true;
        }
        if (isRightDom) {
            this.setState({
                ...this.state,
                needDetailInfo: false
            })
        }
    }
    clickToPoint3 = (e) => {
        const target = e.target;

        let isRightDom = false;
        if (target.id === 'td-tmp-data3' || target.parentNode.id === 'td-tmp-data3') {
            isRightDom = true;
        }
        if (isRightDom) {
            this.setState({
                ...this.state,
                needInvoice: false
            }, () => {
            })
        }
    }
    clickToPoint5 = (e) => {
        const target = e.target;
        let isRightDom = false;
        if (target.id === 'td-tmp-data5') {
            isRightDom = true;
        }
        if (isRightDom) {
            this.setState({
                ...this.state,
                needContact: false
            })
        }
    }


    showingInvoice = (_data) => {

        const gurumeUrlTutionConfirmUrl = clipMsgState.gurumeUrlTutionConfirmUrl;
        const invoiceLinkCopy = clipMsgState.invoiceLinkCopy;
        const data = gurumeUrlTutionConfirmUrl + _data + invoiceLinkCopy;

        this.setState({
            ...this.state,
            needInvoiceCopy: true,
            invoiceTextArea: data,
            invoiceToken: _data,
        })
    }

    showingDetailInfo = (_data) => {

        this.setState({
            ...this.state,
            needDetailInfo: true,
            invoiceData: _data
        })
    }

    showingNewTdData = () => {

        if (this.state.stateParams.tdType === 'student') {
            showNotify("선생님만 가능합니다.", STATE_NOTIFY.TYPE_POSITIVE);
            // return;
        }

        this.setState({
            ...this.state,
            needInvoice: true
        });
    }

    showingNewChildren = (_data) => {
        this.setState({
            ...this.state,
            needContact: true,
            tdContactData: _data
        });
    }

    changeInvoceTextArea = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            invoiceTextArea: value
        })
    }

    moveToInvoice = () => {
        const url = '/tuitions/confirm/' + this.state.invoiceToken;
        window.open(url);
    }
    copyToClipBoardClick = async () => {
        const gurumeUrlTutionConfirmUrl = clipMsgState.gurumeUrlTutionConfirmUrl;
        const invoiceLinkCopy = clipMsgState.invoiceLinkCopy;
        copyToClipboard(gurumeUrlTutionConfirmUrl + this.state.invoiceToken + invoiceLinkCopy);
        const msg = '클립보드에 복사되었습니다.';
        showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
    }

    createInvoiceAndCopy = () => {
        return (
            <div className='tdashboard-td-invoice' onClick={this.clickToPoint}>
                <div id='td-tmp-data' className='tdash-normal-state'>
                    <div className='tdash-common-state'>
                        <textarea
                            value={this.state.invoiceTextArea}
                            onChange={this.changeInvoceTextArea}
                        >
                        </textarea>
                        <div>
                            <button onClick={this.moveToInvoice}>수업료 인보이스</button>
                            <button onClick={this.copyToClipBoardClick}>클립보드에 복사</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    clickConfirmMsg = (orderNum, tcStuName) => {
        const ConfirmMsgTextArea = 'www.gurume.kr/reviews/new/' + orderNum + ' 링크를 클릭하셔서 수업 완료 확인을 해주실 수 있을까요? 확인을 해주셔야 수업료 수급이 가능하여 메세지 드립니다^^ 이번 수업에 대한 Progress Report 또한 그루미 마이페이지에 로그인 하셔서 확인 하실 수 있습니다.'
            + '만약 링크를 여시는데 문제가 있으시면 그루미 고객센터 (카톡 gurume)에 직접 ' + tcStuName + ' 학생과 ' + this.state.stateParams.tdNameData + ' 선생님 수업 완료 확인”이라고 메세지 보내주셔도 됩니다^^';

        this.setState({
            ...this.state,
            needConfirmMsg: true,
            ConfirmMsgTextArea: ConfirmMsgTextArea,
        })
    }
    closeConfirmMsg = (e) => {
        const target = e.target;
        if (target.id !== 'tdashboardConfirmMsg') {
            return;
        }
        this.setState({
            ...this.state,
            needConfirmMsg: false
        })
    }


    copyToClipFromConfirmMsg = () => {
        const gurumeUrlTutionConfirmUrl = clipMsgState.gurumeUrlTutionConfirmUrl;
        const invoiceLinkCopy = clipMsgState.invoiceLinkCopy;
        copyToClipboard(this.state.ConfirmMsgTextArea);
        const msg = '클립보드에 복사되었습니다.';
        showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
    }
    onChangeConfirmMsg = (e) => {
        const target = e.target;
        const value = target.value;
        this.setState({
            ...this.state,
            ConfirmMsgTextArea: value
        });
    }
    createConfirmMsg = () => {

        return (
            <div className='tdashboard-td-invoice' onClick={this.closeConfirmMsg}>
                <div id='tdashboardConfirmMsg' className='tdash-normal-state'>
                    <div className='tdash-common-state'>
                        <textarea
                            onChange={this.onChangeConfirmMsg}
                            value={this.state.ConfirmMsgTextArea}
                        />

                        <div>
                            <button onClick={this.copyToClipFromConfirmMsg}>클립보드에 복사</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    hideFeeModal = () => {
        $("#td-total-fee-modal").hide('slow')
    }
    hideDetailModal = () => {
        $('.td-detail-modal').hide('slow')
    }

    createDetailInfo = () => {

        const invoiceData = this.state.invoiceData;

        const classId = invoiceData['classId'];
        const culOutTime = invoiceData['culOutTime'];
        const culs = invoiceData['culs'];
        const subs = invoiceData['subs'];

        const startAndEndTime = invoiceData['startAndEndTime'];

        const pricePerAHour = invoiceData['pricePerAHour'];
        const classFee = invoiceData['classFee'];
        const payments = invoiceData['payments'];
        const orderNumber = invoiceData['orderNumber'];

        return (
            <div className='td-detail-modal' onClick={this.clickToPoint2}>
                <div id='td-tmp-data2' className='tdash-normal-state'>
                    <div className="modal-content tdash-common-state">
                        <div className="modal-header">
                            <h3>상세 내용</h3>
                            <button type="button" onClick={this.hideDetailModal}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul className='tdash-details'>
                                <li>
                                    <span>수업 ID</span><br />
                                    <span>{classId}</span>
                                </li>
                                <li>
                                    <span>커리큘럼 / 과목</span><br />
                                    <span>{culs} {subs}</span>
                                </li>
                                <li>
                                    <span>시작 / 끝 날짜</span><br />
                                    <span>{startAndEndTime}</span>
                                </li>
                                <li>
                                    <span>총 시간</span><br />
                                    <span>{culOutTime}</span>
                                </li>
                                <li>
                                    <span>수업료</span><br />
                                    <span>{moneyFormat(classFee)} (KRW)</span>
                                </li>
                                <li>
                                    <span>지불 금액</span><br />
                                    <span>{moneyFormat(payments)} (KRW)</span>
                                </li>
                                <li>
                                    <span>시간당 금액</span><br />
                                    <span>{moneyFormat(pricePerAHour)} (KRW)</span>
                                </li>
                            </ul>
                            <button className="detail-invoice-btn" onClick={this.doLinkInvoice} data-order-number={orderNumber}>수업료 인보이스 링크</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    doLinkInvoice = (e) => {
        const orderNumber = e.target.dataset['orderNumber'];
        window.location.href = '/#/tuitions/confirm/' + orderNumber;
    }



    clickToPointSelect = (e) => {

        const target = e.target;
        let ordernum = target.dataset['ordernum'];

        let currentNode = target;
        while (!ordernum) {
            currentNode = currentNode.parentNode;
            ordernum = currentNode.dataset['ordernum'];
        }

        for (let i = 0; i < currentNode.parentNode.childElementCount; i++) {
            currentNode.parentNode.children[i].classList.remove('active');
        }
        currentNode.classList.add('active');


        this.setState({
            ...this.state,
            currentOrderNum: ordernum
        })

    }


    clickToOrderNum = () => {
        const currentOrderNum = this.state.currentOrderNum;
        if (!currentOrderNum) return;

        window.location.href = '/#/tuitions/copy/' + currentOrderNum;
    }

    clickToBill = () => {
        window.location.href = '/#/tuitions/bill';
    }

    scrollDectectFunc = () => {
        const isScrollDone = this.state.InfinityController.scrollDetect('infiWapper2', 'infiList2');
        if (isScrollDone) {
            const currentTdModData = this.state.InfinityController.getDataWithCount(INFI_STATE.REPEAT_COUNT);
            this.setState({
                ...this.state,
                currentTdModData: currentTdModData,
            });
        }
    }

    createAddInvoice = () => {

        // const tuitionDatas = this.state.stateParams.tdModData;
        const tuitionDatas = this.state.currentTdModData;


        const modData = [];
        for (let i = 0; i < tuitionDatas.length; i++) {
            if (String(tuitionDatas[i].status) === '1') continue;
            const student_name = tuitionDatas[i].student_name;
            const ordernum = tuitionDatas[i].ordernum;
            let curriculum = '';
            let subject = '';
            let curId = ''; //id
            let end_time = tuitionDatas[i].end_time;
            let start_time = tuitionDatas[i].start_time;
            for (let k = 0; k < tuitionDatas[i].curriculum_details.length; k++) {
                if (tuitionDatas[i].curriculum_details[k] !== null) {
                    curriculum = tuitionDatas[i].curriculum_details[k].curriculum;
                    subject = tuitionDatas[i].curriculum_details[k].subject;
                    curId = tuitionDatas[i].curriculum_details[k].id;
                }
            }

            if (ordernum) {
                modData.push({
                    student_name, ordernum, curriculum, subject, curId, end_time, start_time
                });
            }
        }

        const ul = [];
        const li = [];

        for (let i = 0; i < modData.length; i++) {
            li.push(
                <li key={`key_${i}`} className='teacher-dashboard-invoice-modal' data-ordernum={modData[i].ordernum} onClick={this.clickToPointSelect}>
                    <div>
                        <div>
                            <span>학생 : </span>
                            <span>{modData[i]['student_name']}</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>커리큘럼 / 과목 : </span>
                            <span>{modData[i]['curriculum']} {modData[i]['subject']}</span>
                        </div>
                    </div>
                    <div className='td-extra-info'>
                        <div>
                            <span>시작 / 끝 날짜 : </span>
                            <span><i>{modData[i]['start_time']}~{modData[i]['end_time']}</i></span>
                        </div>
                    </div>
                </li>
            )
        }

        return (
            <div className='tdashboard-td-invoice' id='td-tmp-data3' onClick={this.clickToPoint3}>
                <div className='tdash-normal-state'>
                    <div className='tdash-common-state'>
                        <div className='tdash-copy-btn'>
                            <button onClick={this.clickToOrderNum}>선택 수업 복사</button>
                        </div>
                        <h3>수업료 인보이스</h3>
                        <p>최근 사용된 인보이스를 다시 발행할 수 있습니다.</p>
                        <button className='tdash-new-btn' onClick={this.clickToBill}><i class="fa fa-plus-circle"></i>&nbsp;새로 추가</button>
                        <div className='tapply-msg-ul tdash-tapply-wapper' id='infiWapper2' onScroll={this.scrollDectectFunc}>
                            <ul id='infiList2'>
                                {li}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    createContactAddr = () => {

        const tdContactData = this.state.tdContactData;
        let email = tdContactData.email;
        let kakaotalk = tdContactData.kakaotalk;
        let phone = tdContactData.phone;
        let skype = tdContactData.skype;
        let wechat = tdContactData.wechat;
        let whatsapp = tdContactData.whatsapp;
        const stuName = tdContactData.stuName;

        if (!email) email = "입력되지 않았습니다.";
        if (!kakaotalk) kakaotalk = "입력되지 않았습니다.";
        if (!phone) phone = "입력되지 않았습니다.";
        if (!skype) skype = "입력되지 않았습니다.";
        if (!wechat) wechat = "입력되지 않았습니다.";
        if (!whatsapp) whatsapp = "입력되지 않았습니다.";

        return (
            <div className='tdashboard-td-invoice' onClick={this.clickToPoint5}>
                <div id='td-tmp-data5' className='tdash-normal-state'>
                    <div className='tdash-left-contact-s'>
                        <h3>{stuName} - 연락처</h3>
                        <div className='tdash-left-common-advice'>연락처를 이용해 연락해보세요!</div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_email.png' alt='...' /></span><span>{email}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_phone.png' alt='...' /></span><span>{phone}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_kakaotalk.png' alt='...' /></span><span>{kakaotalk}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_wechat.png' alt='...' /></span><span>{wechat}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span><img src='../images/contact_details_skype.png' alt='...' /></span><span>{skype}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div className="tdashboard-container default-wrapper">
                {this.state.needContact ?
                    this.createContactAddr()
                    : ''
                }
                {this.state.needInvoiceCopy ?
                    this.createInvoiceAndCopy()
                    : ''
                }
                {this.state.needDetailInfo ?
                    this.createDetailInfo()
                    : ''
                }

                {this.state.needInvoice ?
                    this.createAddInvoice()
                    : ''
                }
                {this.state.needConfirmMsg ?
                    this.createConfirmMsg()
                    : ''
                }
                {this.createTotalFeeModal()}
                {this.createNav()}
                <div className='td-small-wrapper'>
                    <div className='td-small-img-wrapper'>
                        <img src='../../images/header-bg/student-header-bg.jpg' alt='...' />
                    </div>
                    {this.createBody()}
                </div>
            </div>
        )
    }
    goToLink1 = () => {
        window.open('https://docs.google.com/document/d/1J8YW9qq7yOFJmQlYcKpPDQvvrVFO7-FiKG_ZAXPAOh4/edit')
    }

    render() {
        return (
            <div>
                {/* <NewsletterModal /> */}
                <MainModal
                    id="main-modal-1"
                    num={1}
                    link={this.goToLink1}
                    linkButton="자세히 보기"
                    content={
                        <p>
                            <b>‘나를 전문가 (Guru) 로 만들어주세요’</b>라는 뜻을 가진 그루미(GuruMe)는 유학생들을 위한 과외 플랫폼으로서 이미 축적된 5500+ 유저 및 4500+ 수업신청서 데이터를 통해 유학 과외 업계에서는 선두를 달리고 있습니다.
                            <br />
                            <br />
                            IT 인프라를 더 구축하여 더 많은 EdTech 서비스 제공하고 인강 사업 확장에 함께 해주실 수 있는 분을 만나고 싶습니다.
                            <br />
                            <br />
                            자세한 내용은 아래의 “ 그루미 인강 선생님’ 을 확인해주세요!
                        </p>
                    }
                    isModalNum={1}
                />
                {this.createHtml()}
            </div>
        )
    }

}



export default withRouter(Tdashboard);