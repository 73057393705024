import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasInbox,
    TrasPleasenotethatmessagescanbevigilatedbyGuruMeforappropriatesiteusage
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';

import './messages.css'

const $ = window.jQuery;

class Messages extends Component {
    state = {
        lang: window.localStorage.lang || 'ko',
        userType: '',
        chatList: [],
        is_ot: true,
    }

    componentDidMount = async () => {

        await this.getIsLogin();
        await this.getData();
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    }


    getIsLogin = async () => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.isLogin;
        const res = await axios.get(url).catch((err) => { return err; })
        let data = res.data;
        if (data.isLogin === 'false') {
            let lang = this.state.lang
            let msg = (lang === 'cn' ? '请登录' : lang === 'en' ? 'Please login.' : '로그인 해주세요.', "danger");
            let type = STATE_NOTIFY.TYPE_NEGATIVIE;
            showNotify(msg, type);
            window.location.href = '/#/login'
        } else {
            this.setState({
                userType: data.type
            })
        }
    }

    getData = async (ordernum) => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.message.RRR_ReactMsgList;
        const formData = new FormData();
        formData.append('frameType', 'React');
        const res = await axios.post(url, formData, postConfig).catch((err) => { return err; });
        let data = [];
        let is_ot = true;
        if (res.data === "Not OT") {
            is_ot = false;
        } else {
            data = res.data;
        }
        this.setState({
            chatList: data,
            is_ot: is_ot,
        })
    }

    showChat = (mid) => {
        window.location.href = '/#/messages/' + mid
    }

    createBreadcrumb = () => {
        return (
            <div className="container">
                <ol className="breadcrumb mooc-index-breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasInbox /></li>
                </ol>
            </div>
        )
    }

    FixFortmatDate = (_newDateFormat) => {
        const newDateFormat = _newDateFormat.substr(0, _newDateFormat.indexOf('.'));
        return newDateFormat;
    }

    replaceTmp = (content) => {

        if (content.indexOf('<a href') !== -1) {
            return content;

        } else {
            return content.replace(/\n/g, '<br/>');
        }

    }

    goToOTPage = () => {
        window.location.href = "/#/ot"
    }

    getUnOTMsg = () => {
        return this.state.is_ot ?
            "" :
            <button className="btn english-btn base-btn" onClick={this.goToOTPage}>
                OT가 필요합니다. OT 신청하기
            </button>
    }

    createContentHtml = () => {
        const chatList = this.state.chatList;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-2 col-xs-12"></div>
                    <div className="col-md-8 col-xs-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="panel-title"><i className="fa fa-comment margin-right-10" aria-hidden="true"></i><TrasInbox /></h3>
                            </div>
                            <div className="card-body">
                                <ul className="list-group">
                                    <p><i><TrasPleasenotethatmessagescanbevigilatedbyGuruMeforappropriatesiteusage /></i></p>
                                    {this.getUnOTMsg()}
                                    {
                                        chatList.map((item, index) => {
                                            let recentMsg = item.recentMsg.substr(0, 120) + '...';
                                            let pics = item.pic;
                                            if (pics.split('http').length > 2) {
                                                const tempArr = pics.split('http');
                                                pics = 'http' + tempArr[1];
                                            }

                                            let spanNew = '';
                                            if (item.n_read === 0) {
                                                // is_read_receiver
                                                spanNew = <span className='msg-newer'>N</span>;
                                            }
                                            return (
                                                <li className="list-group-item cursor" data-toggle="modal" key={'Message' + index} onClick={() => this.showChat(item.id, item.current_id)}>
                                                    <div className="row">
                                                        <div className=""
                                                            style={{ width: '50px', height: '50px' }}
                                                        >
                                                            <img src={pics || '../images/backuptutorpic.png'} className="rounded-circle img-fluid"
                                                                style={{ width: '50px', height: '50px' }}
                                                                alt="..." />
                                                        </div>
                                                        <div className="col-xs-10 col-md-11">
                                                            <div className="comment-info clearfix">
                                                                <b data-toggle="tooltip" data-placement="left" title={item.flagstring}>{item.last_name}{item.first_name}</b>{spanNew}
                                                                {
                                                                    item.hasNemMsg === 'true' ? <span>({item.n_msg})</span> : ''
                                                                }
                                                                <span className="float-right">{this.FixFortmatDate(item.createDate.date)}</span>
                                                            </div>
                                                            <div className="comment-text">
                                                                <div dangerouslySetInnerHTML={{ __html: this.replaceTmp(recentMsg) }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    render = () => {
        return (
            <div className="messages-container"
            >
                {this.createBreadcrumb()}
                {this.createContentHtml()}
            </div>
        )
    }
}

export default withRouter(Messages);
