import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { showNotify, STATE_NOTIFY } from '../extra/cusUtil';
import axios from '../../config/axios';
import { GetAjaxURLFileUpload, multipartForm, NoImgContens, postConfig } from '../../config/AjaxConfig';
import defaultImgSrc from '../../images/backuptutorpic.png';
import './ImageUploader.css';


const ImageUploaderPropTypes = {
    IMAGE_CallbackFuc: PropTypes.func,
    IMAGE_CallbackCancel: PropTypes.func,
    autoLoadingDataUrl: PropTypes.string,
    formType: PropTypes.string,
}
const ImageUploaderDefaultProps = {
    IMAGE_CallbackFuc: () => { console.log("") },
    IMAGE_CallbackCancel: () => { console.log("") },
    autoLoadingDataUrl: '',
    formType: '',                                                                  // 필수 값
}

class ImageUploader extends Component {

    state = {
        isLocal: false,                                                            // 테스트 할 때 true값으로 변환하면 서버에 파일 올라가지 않고 테스트 가능.
        currentStateMsg: '',
        dataUrl: '',
        isShowDiplayer: false,
        serverFileName: '',

        // ******************** //
        //    프로그램 설정 부분     //
        // ******************** //
        UPLOADER_STEP_MSG: {
            STEP_A: '선택된 파일 없음',                                          // 시작
            STEP_B: '이미지가 선택되었습니다. 서버에 올리겠습니까? (서버에 올려야지 반영됩니다.)', // 진행
            STEP_B_1: '이미지를 전송하고 있습니다.',
            STEP_C: '이미지 전송이 완료되었습니다.',                                    // 완료
        },
        DISPLAY_MSG: {                                                            // 사용자 표시 메시지
            STEP_A: '파일을 올려주세요.',                                             // 인풋 영역에 파일이 선택되어 있지 않을 때.
            STEP_B: '파일을 업로드하는 중입니다. 잠시만 기다려주세요.',                      // 서버로 파일 전송
            STEP_C: ' -이름으로 파일이 전송되었습니다.'                                  // 완료 메시지
        },
        VALID_FORMAT: ["image/gif", "image/jpeg", "image/png"],                  // 사용 가능한 확장자
        NOT_VALID_FORMAT_MSG: '이미지 확장자는 "gif/jpeg/png"만 가능합니다.',           // 사용 가능하지 않았을 때 메시지 출력

    }



    componentDidMount = () => {
        if (this.props.autoLoadingDataUrl === '') {
            // For Testing
            if (this.props.formType === '') {
                this.setState({
                    ...this.state,
                    currentStateMsg: this.state.UPLOADER_STEP_MSG.STEP_A,
                    isLocal: true,
                })
            } else {
                this.setState({
                    ...this.state,
                    currentStateMsg: this.state.UPLOADER_STEP_MSG.STEP_A
                });
            }
        } else {
            this.setState({
                ...this.state,
                currentStateMsg: this.state.UPLOADER_STEP_MSG.STEP_C,
                isShowDiplayer: true,
            }, () => {
                this.Do_AutoLoading();
            });
        }


    }

    Do_AutoLoading = () => {
        const output = document.getElementById('imageUploaderDisplay');
        output.src = this.props.autoLoadingDataUrl;
    }


    // users STEP_A
    clickSelectFiles = () => {

        // 메시지 출력
        let msg = this.state.UPLOADER_STEP_MSG.STEP_A;
        showNotify(msg, STATE_NOTIFY.TYPE_INFO);

        // 실제 파일 업로드 인풋 클릭
        const hideFileDom = document.getElementById('hideFile');
        hideFileDom.click();

    }



    // users STEP_B
    changeToFileUpLoad = (file) => {

        // 파일 가져옴.
        const input = file.target;
        if (input.files.length <= 0) return;

        const chk_file = input.files[0];

        // 파일 포맷 설정 영역.
        const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

        let chkBool = false;
        for (let i = 0; i < validImageTypes.length; i++) {
            if (validImageTypes[i] === chk_file.type) {
                chkBool = true;
            }
        }

        // 확장자가 다른 경우, 에러 메시지
        if (!chkBool) {
            let msg = this.state.NOT_VALID_FORMAT_MSG;
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            const hideFileDom = document.getElementById('hideFile');
            hideFileDom.value = '';
            return;
        }

        const validFile = chk_file;
        const imgName = validFile.name;
        const imgLastModified = validFile.lastModified;
        const imgLastModifiedDate = validFile.lastModifiedDate;

        // 이미지 변환
        const reader = new FileReader();
        reader.onload = e => {
            const image = new Image();
            image.src = e.target.result;
            image.name = imgName;
            image.lastModified = imgLastModified;
            image.lastModifiedDate = imgLastModifiedDate;
            image.onload = imageEvent => {
                this.resize_image(image);
            };

        }
        reader.readAsDataURL(validFile);
    }

    resize_image = image => {
        let canvas = document.createElement("canvas"),
            max_size = 2000,
            width = image.width,
            height = image.height;

        if (width > height) {
            if (width > max_size) {
                height *= max_size / width;
                width = max_size;
            }
        } else {
            if (height > max_size) {
                width *= max_size / height;
                height = max_size;
            }
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(image, 0, 0, width, height);
        const dataUrl = canvas.toDataURL("image/png");


        this.setState({
            ...this.state,
            dataUrl: dataUrl,
            isShowDiplayer: true,
            currentStateMsg: this.state.UPLOADER_STEP_MSG.STEP_B,
        }, () => {

            const output = document.getElementById('imageUploaderDisplay');
            output.src = dataUrl;
            this.uploadFileToServer();
        });
    };


    // 닫기 버튼
    ClickToCloseImg = () => {
        this.setState({
            ...this.state,
            currentStateMsg: this.state.UPLOADER_STEP_MSG.STEP_A,
            dataUrl: '',
            isShowDiplayer: false,
        }, () => {

            const hideFileDom = document.getElementById('hideFile');
            hideFileDom.value = '';
            setTimeout(() => {
                const output = document.getElementById('imageUploaderDisplay');
                output.src = defaultImgSrc;
            }, 300);
            this.props.IMAGE_CallbackCancel();
        })
    }

    /// do SEND DATA with AJAX
    uploadFileToServer = async () => {
        this.setState({
            ...this.state,
            currentStateMsg: this.state.UPLOADER_STEP_MSG.STEP_B_1
        });

        let formType = 'tutor';
        if (this.props.formType !== '') {
            formType = this.props.formType;
        }

        const formData = new FormData();
        formData.append('image-data', this.state.dataUrl);
        formData.append('type', formType);

        let fileUrlConfig = await GetAjaxURLFileUpload();
        let fileurl = fileUrlConfig.uploadPic;              // BASE URL

        let res = {};
        if (this.state.isLocal) {
            res = { data: { status: 'success', name: 'TEST.png' } };
        } else {

            res = await axios.post(fileurl, formData, multipartForm, postConfig).catch((err) => { console.log(err) });

            if (!res) return;
            if (!res['data']) return;
            if (!res['data']['status']) return;
        }


        if (res.data.status === 'success') {
            this.setState({
                ...this.state,
                currentStateMsg: this.state.UPLOADER_STEP_MSG.STEP_C,
                serverFileName: res.data.name
            }, () => {
                this.props.IMAGE_CallbackFuc(this.state.serverFileName);
            })
        } else {
            this.setState({
                ...this.state,
                currentStateMsg: '서버에서 에러가 발생했습니다. (' + res.data.error + ')'
            });
        }

    }


    render() {
        const ldefaultImgSrc = defaultImgSrc;

        // 이미지 Showing 애니메이션
        let isShowDiplayer = 'r-display-card-hide';        // DEFAULT false
        if (this.state.isShowDiplayer) {
            isShowDiplayer = 'r-display-card-show';
        }




        return (
            <div className='r-img-loader-wapper'>
                {/* poster */}
                <div className='r-img-loader-btn-area'>
                    {
                        this.props.formType !== '' ?
                            /* 싱글 방식 */
                            <input onClick={this.clickSelectFiles} className="comm-btn-style" type='button' defaultValue='파일 선택' />

                            : ''
                    }

                    {/* 로컬 테스트용 버튼 */}
                    {
                        this.state.isLocal ?
                            <input onClick={this.clickSelectFiles} className="comm-btn-style" type='button' defaultValue='파일 선택' />
                            : ''
                    }

                    {/* {this.props.formType}


                    {/* 단계별 스테이트 조작. */}
                    <div className='r-upload-file-name'>{this.state.currentStateMsg}</div>
                    {/* {
                        this.state.dataUrl !== '' ?
                            this.state.currentStateMsg === this.state.UPLOADER_STEP_MSG.STEP_B ?
                                <input className="comm-btn-style" type='button' defaultValue='서버에 올리기' onClick={this.uploadFileToServer} />
                                :
                                this.state.currentStateMsg === this.state.UPLOADER_STEP_MSG.STEP_B_1 ?
                                    <button className="comm-btn-style loading">서버에 올리는 중</button>
                                    : ''
                        : 
                            <input onClick={this.clickSelectFiles} className="comm-btn-style" type='button' defaultValue='파일 선택' />
                    } */}


                    <input type="file" id='hideFile' style={{ display: 'none' }} onChange={this.changeToFileUpLoad} />

                </div>
                <div className='r-img-display-area'>

                    <div className={`r-img-display-card ${isShowDiplayer}`}>
                        <button onClick={this.ClickToCloseImg}>x</button>
                        <img id='imageUploaderDisplay' src={ldefaultImgSrc} alt='...' onError={function (e) { e.target.src = NoImgContens; }} />
                    </div>

                </div>
            </div>
        )
    }
}

ImageUploader.propTypes = ImageUploaderPropTypes;
ImageUploader.defaultProps = ImageUploaderDefaultProps;

export default ImageUploader;