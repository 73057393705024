import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasTuitionRequest,
    TrasDetails,
    TrasAppealyourclass,
    TrasOnline,
    TrasFacetoface,
    TrasSNSMentoring,
    Trassessionsweek,

    TrasUndergraduate,
    TrasGraduate,
    TrasProfessional,

    TrasTeachingSubjects,
    TrasTuitionAim,

    TrasBack,
    TrasApply,
    TrasDelete,
    TrasEdit,
    TrasMatched,
    TrasIhavechosenatutor,

    TrasYourprofilelinkwillbeattachedBrieflydescribehowyoucanhelpwithreferencetothestudentstuitionaim,

} from '../../../Translate/EachTranslateC';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMapMarkerAlt, faCalendarWeek, faMars, faVenus, faPen, faComments, faUser, faCommentAlt
} from '@fortawesome/free-solid-svg-icons';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { moneyFormat, showNotify, STATE_NOTIFY } from '../../extra/cusUtil';
import { isAdminEmail } from '../../extra/utils';
import TextareaAutosize from 'react-textarea-autosize';
import testPic from '../../../images/backuptutorpic.png';

import './tuition_request_show.css';

class Tuition_request_show extends Component {


    state = {
        lang: 'ko',
        pageId: '',
        isLogin: true,
        isAdmin: false,
        isTutorLogin: false,
        isStudent: false,
        isGuest: false,
        is_ot: false,
        thisUserID: '',
        studentID: '',
        tutorFullName: '',
        isSendingMessage: false,

        originData: {
            details: '',
        },

        paramData: {
            stdPicUrl: '../images/backuptutorpic.png',
            last_name: '',
            last_name_en: '',
            created_at: '',
            curriculums: [],
            hourly_rate: '',
            details: '',
            city_kr: '',
            region_kr: '',
            region_en: '',
            city_en: '',
            region_zh: '',
            city_zh: '',
            location_memo: '',
            sex: '',
            mode: '',
            tutor_level: '',

            hours: '',
            start_time: '',
            end_time: '',

            internal_status: '',
            uploaded_by_admin: '',
            recommended_tutors: [],
            memo: '',
            student_id: 1,
        },

        isDetailEdit: false,

        isSetAgreeA: false,
        isSetAgreeB: false,
        isSetAgreeC: false,
        isSetAgreeD: false,
        checkMsg: '지원하기 위해서는 체크되어야 하는 항목입니다.',
        sendMsg: '',
        isShowApplyPannel: false,
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.location.reload();
        }
        return true;
    }

    componentDidMount = async () => {

        const allUrls = await GetAjaxURL();
        const isLoginUrl = allUrls.isLogin;
        const isLoginRes = await axios.get(isLoginUrl).catch((err) => { return console.log(err); });
        const isStudent = isLoginRes.data.type === 'student' ? true : false;

        if (isStudent) {
            this.setState({
                isStudent: isStudent
            })
        }

        if (isLoginRes.data.isLogin === 'false') // return window.location.href = '/#/login';
        {
            this.setState({
                isGuest: true
            })
        }


        const rid = this.props.match.params.rid;
        if (rid) {
            const rUrls = allUrls.tuitionReqWithRid + rid;
            const reqDataRes = await axios.get(rUrls).catch((err) => { return err; });

            let msg = '';
            if (!reqDataRes) {
                msg = '서버에서 에러가 발생했습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return;
            }
            if (!reqDataRes['data']) {
                msg = '서버에서 에러가 발생했습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return;
            }

            const resData = reqDataRes['data'];
            if (resData['status'] === "fail") {
                showNotify("존재하지 않는 수업 요청서입니다.", STATE_NOTIFY.TYPE_NEGATIVIE);
                window.location.href = '/tuition_requests';
                return;
            }

            this.SetInitData(resData, allUrls);

        }

    }


    SetInitData = async (_resData, _allUrls) => {

        const allUrls = _allUrls;
        const thisUserUrl = allUrls.apiThisUser;
        const thisUserRes = await axios.get(thisUserUrl).catch((err) => { return console.log(err) });

        const isLoginUrl = allUrls.isLogin;
        const isLoginRes = await axios.get(isLoginUrl).catch((err) => { return console.log(err); });
        // Remove login restriction
        if (isLoginRes.data.isLogin === 'false') // return window.location.href = '/#/login';
        {
            this.setState({
                isGuest: true
            })
        }

        let thisUserEmail;
        let thisUserType;
        let tutorFullName;
        let thisUserID;
        if (isLoginRes.data.isLogin === 'false') {
            thisUserEmail = "";
            thisUserType = "guest";
            tutorFullName = "Guest";
            thisUserID = 0;
        } else {
            thisUserEmail = thisUserRes.data.user.email;
            thisUserType = thisUserRes.data.user.type;
            tutorFullName = thisUserRes.data.user.last_name + thisUserRes.data.user.first_name;
            thisUserID = thisUserRes.data.user.id;
        }


        const isAdmin = isAdminEmail(thisUserEmail);
        const isTutorLogin = (thisUserType === 'student' || thisUserType === 'guest') ? false : true;
        const studentID = _resData.student_id;
        const is_ot = (thisUserType === 'tutor') && (thisUserRes.data.profile.ot == 1);
        // thisUserID : '',
        // studentID : '',



        const resData = _resData;
        const pageId = resData.id;
        const studentData = resData.student;
        const location = resData.location;



        // const stdPicUrl = studentData.image;
        const last_name = studentData.last_name;
        const first_name = studentData.first_name;
        const last_name_en = studentData.last_name_en;
        const first_name_en = studentData.first_name_en;
        const created_at = resData.created_at;
        const curriculums = resData.curriculums_new;

        const hourly_rate = resData.hourly_rate;
        const details = resData.details;

        const region_kr = location.region_kr;
        const city_kr = location.city_kr;
        const region_en = location.region_en;
        const city_en = location.city_en;
        const region_zh = location.region_zh;
        const city_zh = location.city_zh;


        const location_memo = resData.location_memo;
        const sex = resData.gender;
        const mode = resData.mode;
        const tutor_level = resData.tutor_level;
        const hours = resData.hours;
        const start_time = resData.start_time;
        const end_time = resData.end_time;

        const internal_status = resData.internal_status;
        const uploaded_by_admin = resData.uploaded_by_admin;
        const recommended_tutors = resData.recommended;


        this.setState({
            ...this.state,
            pageId: pageId,
            isAdmin: isAdmin,
            isTutorLogin: isTutorLogin,
            is_ot: is_ot,
            thisUserID: thisUserID,
            studentID: studentID,
            tutorFullName: tutorFullName,

            originData: {
                ...this.state.originData,
                details: details,
            },

            paramData: {
                ...this.state.paramData,
                first_name,
                first_name_en,
                last_name,
                last_name_en,
                created_at,
                curriculums,
                hourly_rate,
                details,
                region_kr,
                city_kr,
                region_en,
                city_en,
                region_zh,
                city_zh,
                location_memo,
                sex,
                mode,
                tutor_level,
                hours,
                start_time,
                end_time,

                internal_status,
                uploaded_by_admin,
                recommended_tutors,
            }
        }, () => {
            this.props.RootBindingData.doLoading(false);
        })


    }



    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/tuition_request_index">수업신청</a></li>
                    <li className="active">상세내용</li>
                </ol>
            </div>
        )
    }




    editDetailsCancel = () => {

        const details = this.state.originData.details;

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                details: details,
            },
            isDetailEdit: !this.state.isDetailEdit
        })

    }

    // 액션 변경 받는 함수.
    // 완료되었을 때 묻고 난 다음 요청.
    // 백단 코드는 해당 url 확인.
    editDetails = async () => {
        if (!this.state.isDetailEdit) {
            this.setState({
                ...this.state,
                isDetailEdit: !this.state.isDetailEdit
            })
        } else {
            if (!window.confirm('변경하시겠습니까?')) return;

            const allUrls = await GetAjaxURL();
            const url = allUrls.RRR_ReactEditor;

            const formData = new FormData();
            formData.append('code', 'tuition_request_show');                        // 기본 코드값. 백단 크드 확인
            formData.append('id', this.state.pageId);
            formData.append('contents', this.state.paramData.details);

            const req = await axios.post(url, formData, postConfig).catch((err) => { return console.log(err) });

            if (req) {
                if (req.data) {
                    let msg = req.data.msg;
                    if (req.data.status === 'success') {
                        showNotify('업데이트 되었습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                    } else {
                        showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                    }
                }
            }

            this.setState({
                ...this.state,
                originData: {
                    ...this.state.originData,
                    details: this.state.paramData.details,
                },
                isDetailEdit: !this.state.isDetailEdit
            })
        }

    }

    // 체인지 받는 함수.
    changeDetails = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                details: value
            }
        })
    }

    // 수업 목표
    createPartD = () => {

        const details = this.state.paramData.details;

        // this.state.isAdmin;

        let displayButten = 'Edit';
        if (this.state.isDetailEdit) {
            displayButten = 'Done';
        }

        const editTextarea = {
            width: '100%',
            borderRadius: '8px',
            border: '2px solid #5b9aef',
            resize: 'none',
            overflow: 'hidden',
            padding: '6px 12px',
            color: '#6c767f'
        }
        return (
            <div className='trs-card-partB'>
                <div>
                    <div className='trs-comm-reverse'>
                        <h4 className="color-gurume-blue font-size-24">수업 목표
                            {
                                this.state.isDetailEdit ?
                                    <input className='comm-btn-style' type='button' onClick={this.editDetailsCancel} value={'Cancel'} />
                                    : ''
                            }
                            {
                                this.state.isAdmin ?
                                    <input className='comm-btn-style' type='button' onClick={this.editDetails} value={displayButten} />
                                    : ''
                            }
                        </h4>
                    </div>
                    <div>
                        <div className='trs-comm-textarea'>

                            {this.state.isDetailEdit ?
                                <TextareaAutosize value={details} onChange={this.changeDetails} style={editTextarea} />
                                :
                                <TextareaAutosize value={details} disabled />
                            }

                        </div>
                    </div>
                    {
                        this.state.isTutorLogin ?
                            <div className='trs-agree-div'>
                                <span>
                                    <label htmlFor="isAgreeC">
                                        <input type="checkbox" id="isAgreeC" value="isAgreeC" onChange={this.setAgreeC} />
                                        위의 수업목표를 지켜주실 수 있으십니까?
                                    </label>
                                    <p className='trs-err trs-err-hide' id="isAgreeC_msg"> {this.state.checkMsg}</p>
                                </span>
                            </div>
                            : ''
                    }
                    {
                        this.state.isTutorLogin ?
                            <div className='trs-agree-div'>
                                <span>
                                    <label htmlFor="isAgreeD">
                                        <input type="checkbox" id="isAgreeD" value="isAgreeD" onChange={this.setAgreeD} />
                                        <a href="https://goo.gl/PPWx72" target="blank">
                                            <i>선생님 이용약관</i>
                                        </a> 과
                                    <a href="https://goo.gl/tsHNM7" target="blank">
                                            <i> 수업 가이드라인</i>
                                        </a>에 동의합니다.
                                </label>
                                    <p className='trs-err trs-err-hide' id="isAgreeD_msg"> {this.state.checkMsg}</p>
                                </span>
                            </div>
                            : ''
                    }
                </div>
            </div>
        )
    }

    createPartC = () => {

        const hourly_rate = this.state.paramData.hourly_rate;

        return (
            <div className='trs-card-partB'>
                <div>
                    <div className='trs-comm-reverse'>
                        <h4 className="color-gurume-blue font-size-24">최대 시급 (네고 불가능)</h4>
                    </div>
                    <div>
                        <span className='trs-comm-size20'>₩ {moneyFormat(hourly_rate)}</span>
                    </div>
                </div>
            </div>
        )
    }


    createPartB = () => {

        const curriculums = this.state.paramData.curriculums;

        const CurSpans = [];
        let curriculumnData = [];
        let curriculumnSubjData = [];
        for (let i = 0; i < curriculums.length; i++) {
            let CurriculumnClass = 'trs-comm-light-pink';
            let CurriculumnBorderClass = 'trs-comm-light-pink-border';

            if (String(curriculums[i]['test_type']) === '2') {
                CurriculumnClass = 'trs-comm-light-org';
                CurriculumnBorderClass = 'trs-comm-light-org-border';
            } else if (String(curriculums[i]['test_type']) === '3') {
                CurriculumnClass = 'trs-comm-light-green';
                CurriculumnBorderClass = 'trs-comm-light-green-border';
            }

            curriculumnData.push(<span key={`cur-key${i}`} className={CurriculumnClass}>{curriculums[i].curriculum_name}</span>);

            const subjects = curriculums[i]['subject_name'];
            for (let k = 0; k < subjects.length; k++) {
                curriculumnSubjData.push(<span key={`subj-keys${k}`} className={CurriculumnBorderClass}>{subjects[k]['name']}</span>);
            }

            CurSpans.push(
                <li key={`_key_span_${i}`}>
                    <span>
                        {curriculumnData}
                        {curriculumnSubjData}
                    </span>
                </li>
            );

            curriculumnData = [];
            curriculumnSubjData = [];
        }

        return (
            <div className='trs-card-partA trs-card-teaching-subject'>
                <h4 className="color-gurume-blue font-size-24">수업과목</h4>
                <div className="trs-card-partA-box">
                    {CurSpans}
                </div>

                {
                    this.state.isTutorLogin ?
                        <div className='trs-agree-div'>
                            <span>
                                <label htmlFor="isAgreeB">
                                    <input type="checkbox" id="isAgreeB" value="isAgreeB" onChange={this.setAgreeB} />
                                    위의 수업과목을 가르쳐주실 수 있습니까?
                                </label>
                                <p className='trs-err trs-err-hide' id="isAgreeB_msg"> {this.state.checkMsg}</p>
                            </span>
                        </div>
                        : ''
                }
            </div>
        )
    }

    moveToStudentsShow = (e) => {
        if (this.state.isAdmin) {
            const target = e.target;
            const dataId = target.dataset['id'];
            window.location.href = '/#/students/' + dataId
        }
    }
    createPartA = () => {

        const lang = this.state.lang;
        let stdName = ((lang === 'ko') ? this.state.paramData.last_name : this.state.paramData.last_name_en) + '** ';

        if (this.state.isAdmin) {
            stdName = this.state.paramData.last_name + this.state.paramData.first_name + ' ';
        }

        const createdDate = this.state.paramData.created_at.split(' ')[0];

        let locationRegion = lang === 'ko' ? this.state.paramData.region_kr
            : lang === 'en' ? this.state.paramData.region_en
                : this.state.paramData.region_zh;
        let locationCity = lang === 'ko' ? this.state.paramData.city_kr
            : lang === 'en' ? this.state.paramData.city_en
                : this.state.paramData.city_zh;

        const location_memo = this.state.paramData.location_memo.length > 0
            ? ' - ' + this.state.paramData.location_memo
            : '';


        const tutor_level = this.state.paramData.tutor_level.split('_');
        let tutorArr = [];
        let comma = ', ';
        for (let i = 0; i < tutor_level.length; i++) {
            if (i === tutor_level.length - 1) comma = ''; else comma = ', ';
            if (tutor_level[i] === 'undergraduate') {
                tutorArr.push(<span key={`_key_${i}`}>재학생{comma}</span>);
            } else if (tutor_level[i] === 'graduate') {
                tutorArr.push(<span key={`_key_${i}`}>전문강사{comma}</span>);
            } else if (tutor_level[i] === 'professional') {
                tutorArr.push(<span key={`_key_${i}`}>그루미 강사진{comma}</span>);
            }
        }

        const mode = this.state.paramData.mode;
        const modeArr = [];
        for (let i = 0; i < mode.length; i++) {
            if (i === mode.length - 1) comma = ''; else comma = ', ';
            if (mode[i] === '1') {
                modeArr.push(<span key={'mode_key_' + i}>온라인{comma}</span>);
            } else if (mode[i] === '2') {
                modeArr.push(<span key={'mode_key_' + i}>오프라인{comma}</span>);
            } else {
                modeArr.push(<span key={'mode_key_' + i}>카톡 멘토링{comma}</span>);
            }
        }


        const start_time = this.state.paramData.start_time;
        const end_time = this.state.paramData.end_time;
        const hours = this.state.paramData.hours;

        const sex = this.state.paramData.sex;
        const student_id = this.state.studentID

        return (
            <div className='trs-card-partA'>
                <div className="trs-card-partA-box">
                    <li>
                        <div>

                            <input type="image" data-id={student_id} onClick={this.moveToStudentsShow} className='trs-card-picture' src={this.state.paramData.stdPicUrl} alt='...'></input>
                        </div>
                        <div>
                            <button data-id={student_id} onClick={this.moveToStudentsShow}>{stdName} {createdDate}</button>
                        </div>
                    </li>
                    <li>
                        <div>
                            <span><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
                        </div>
                        <div>
                            <span> {locationRegion} {locationCity} </span>
                            <span> {location_memo} </span>
                            <span> ( {modeArr} ) </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <span><FontAwesomeIcon icon={faCalendarWeek} /></span>
                        </div>
                        <div>
                            {end_time !== '2100-01-01' && end_time !== '2100-1-1' ?
                                <span> {start_time + ' ~ ' + end_time} </span>
                                :
                                <span> {start_time + ' ~ 일정 조율 예정'} </span>
                            }
                            <span> ({hours}회/주)</span>

                        </div>
                    </li>
                    <li>
                        <div>
                            <span><FontAwesomeIcon icon={faUser} /></span>&nbsp;
                            {
                                sex === '1' ?
                                    <span><FontAwesomeIcon icon={faMars} /></span>
                                    :
                                    <span><FontAwesomeIcon icon={faVenus} /></span>
                            }
                        </div>
                        <div>
                            {tutorArr}
                            {/* {tutorLvStr} */}
                        </div>
                    </li>
                </div>

                {
                    this.state.isTutorLogin ?
                        <div className='trs-agree-div'>
                            <span>
                                <label htmlFor="isAgreeA">
                                    <input type="checkbox" id="isAgreeA" value="isAgreeA" onChange={this.setAgreeA} />
                                    위의 희망 선생님 성별, 학력/경력을 가지고 희망 위치에서 희망 시간대에 맞추어 수업 가능하십니까?
                                    </label>
                                <p className='trs-err trs-err-hide' id="isAgreeA_msg"> {this.state.checkMsg}</p>
                            </span>
                        </div>
                        : ''
                }
            </div>
        )
    }


    sendApplyMsg = async () => {
        this.setState({ isSendingMessage: true })
        const lang = this.state.lang;
        const sendMsg = this.state.sendMsg;

        if (!this.state.isTutorLogin) {
            const msg = (lang === 'ko') ? '선생님만 이용가능합니다.' : lang === 'en' ? 'You are not a tutor.' : '您不是老师';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }
        if (sendMsg === '') {
            const msg = (lang === 'ko') ? '수업 어필란을 적어주세요.' : lang === 'en' ? 'Please type in class appeal message.' : '请输入课堂魅力';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const rid = this.props.match.params.rid;
        const allUrls = await GetAjaxURL();
        const trsApplyUrls = allUrls.tuitionReqApply + '?msg=' + sendMsg + '&id=' + rid;

        const trsApplyRes = await axios.post(trsApplyUrls, {}, postConfig).catch((err) => { return console.log(err); });
        if (!trsApplyRes) {
            const msg = '서버에 에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (!trsApplyRes['data']) {
            const msg = '서버에 에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }

        if (trsApplyRes['data']['status'] === 'success') {
            const msg = (lang === 'ko') ? '지원서가 발송되었습니다.' : lang === 'en' ? 'Application Sent' : '报名成功';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);

            this.setState({
                ...this.state,
                isShowApplyPannel: !this.state.isShowApplyPannel,
                isSendingMessage: false
            });

        } else {
            const msg = '서버에 에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
    }

    sendMsgFunc = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            sendMsg: value
        });
    }

    sendApplyToCloseBtn = () => {
        this.setState({
            ...this.state,
            isShowApplyPannel: !this.state.isShowApplyPannel
        });
    }

    goToOTPage = () => {
        window.location.href = "/#/ot"
    }

    createHideHeader = () => {

        const teacherID = this.state.thisUserID;
        const tutorFullName = this.state.tutorFullName;

        return (
            <div className='trs-main-header'>
                <div className='trs-main-apply-box trs-comm-blue'>
                    <h3><TrasAppealyourclass /></h3>
                    <p>
                        <a href={`/#/teachers/${teacherID}`} target="_blank" rel="noreferrer noopener">www.gurume.kr/teachers/{teacherID} </a>
                        <TrasYourprofilelinkwillbeattachedBrieflydescribehowyoucanhelpwithreferencetothestudentstuitionaim />
                    </p>

                    <div className='trs-main-apply-textarea'>
                        <div className='trs-apply-top'>
                            <span> 안녕하세요! {tutorFullName} 선생님입니다. </span>
                            <a target="_blank" rel="noreferrer noopener" className="trs-comm-back trs-extra-fix" href={`/#/teachers/${teacherID}`}>
                                프로필 보기
                            </a>
                            <div>
                                {this.state.is_ot ?
                                    <textarea
                                        onChange={this.sendMsgFunc}
                                        placeholder='안녕하세요 이번 9월부터 IB 화학 SL 선행 수업이 필요하시다고 하셔서 지원하는 ooo선생님 입니다. 간결하게 제가 도움 드릴 수 있는 부분을 말씀드리자면... 저 같은 경우 아직도 고등학교 과정때 배운 노트들이 있어서 일부분의 노트들과 문제들 그리고 제 노트 문제 포함에서 가르칠 예정이고 매 수업마다 첫 10~20분 동안 그 전 수업때 했던 내용을 복습하거나 시험을 보게 하는 스타일입니다. 처음 배운것은 잊어버리기가 쉽거든요...'
                                        ng-model="appealMsg">
                                    </textarea> : <div><button className="btn english-btn base-btn" onClick={this.goToOTPage}>OT가 필요합니다. OT 신청하기 </button> </div>}
                            </div>
                        </div>
                    </div>

                    <div className='trs-main-apply-btns'>
                        {this.state.is_ot && !this.state.isSendingMessage ?
                            <span target="_blank" className="trs-comm-back trs-extra-fix" onClick={this.sendApplyMsg} >발송</span>
                            : <span target="_blank" className="trs-comm-back trs-extra-fix">발송중...</span>
                        }
                        <span target="_blank" className="trs-comm-back trs-extra-fix" onClick={this.sendApplyToCloseBtn} >닫기</span>
                    </div>

                </div>

            </div>
        )
    }



    onClickMatched = (e) => {
        const msg = '이미 매칭이 되었습니다.';
        showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
    }
    onClickPostDone = async (e) => {

        const rid = this.props.match.params.rid;
        const allUrls = await GetAjaxURL();
        const urls = allUrls.tuitionReqConfirm + '?request_id=' + rid;
        const trcRes = await axios.post(urls, {}, postConfig).catch((err) => { return console.log(err) });
        const lang = this.state.lang;
        const trcData = trcRes['data']['status'];
        let msg = '';
        if (trcData === 'success') {
            const internal_status = '3';
            this.setState({
                ...this.state,
                paramData: {
                    ...this.state.paramData,
                    internal_status: internal_status
                }
            }, () => {
                msg = (lang === 'ko') ? '확인되었습니다.' : lang === 'en' ? 'Successfully confirmed.' : '确认成功';
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            });
        } else {
            msg = trcRes['data']['msg'];
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
    }
    onClickEdit = (e) => {

        const rid = this.props.match.params.rid;
        if (!rid) {
            const msg = '작동에 실패했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        window.location.href = '/#/tuition_request/' + rid + '/edit';

    }
    onClickDelete = async (e) => {

        const lang = this.state.lang;
        const conMsg = (lang === 'ko') ? '정말 의뢰서를 삭제하시겠습니까?' : lang === 'en' ? 'Really delete this tuition request?' : '真的删除这个学费吗？';
        const checkVal = window.confirm(conMsg);
        if (!checkVal) {
            return e.preventDefault();
        }

        const pageId = this.state.pageId;
        const allUrls = await GetAjaxURL();
        const deleteUrls = allUrls.tuitionReqDelete + pageId;
        const deleteRes = await axios.post(deleteUrls, {}, postConfig).catch((err) => { return console.log(err); });

        if (!deleteRes) {
            const msg = '서버에 에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (!deleteRes.status) {
            const msg = '서버에 에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }

        const resStatus = deleteRes['data']['status'];

        if (resStatus === 'success') {
            const resMsg = (lang === 'ko') ? '성공적으로 삭제하였습니다.' : lang === 'en' ? 'Successfully deleted.' : '已成功删除。';
            showNotify(resMsg, STATE_NOTIFY.TYPE_POSITIVE);
            window.location.href = '/#/tuition_requests/';
        } else {
            const resMsg = deleteRes['data']['msg'];
            showNotify(resMsg, STATE_NOTIFY.TYPE_POSITIVE);
        }

    }

    ShowValidations = (_arr) => {

        const arrDom = _arr;
        for (let i = 0; i < arrDom.length; i++) {
            const eachDom = document.getElementById(arrDom[i]);
            const eachClassList = eachDom.classList;
            for (let k = 0; k < eachClassList.length; k++) {
                if (eachClassList[k] === 'trs-err-hide') {
                    eachDom.classList.remove('eachDom.classList');
                    eachDom.classList.add('trs-err-show');
                }
            }
        }

    }

    onClickApply = (e) => {

        const isSetAgreeA = this.state.isSetAgreeA;
        const isSetAgreeB = this.state.isSetAgreeB;
        const isSetAgreeC = this.state.isSetAgreeC;
        const isSetAgreeD = this.state.isSetAgreeD;

        if (!isSetAgreeA || !isSetAgreeB || !isSetAgreeC || !isSetAgreeD) {
            const msg = '동의 항목에 체크해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            const checkDom = [];

            if (!isSetAgreeA) {
                checkDom.push('isAgreeA_msg');
            }
            if (!isSetAgreeB) {
                checkDom.push('isAgreeB_msg');
            }
            if (!isSetAgreeC) {
                checkDom.push('isAgreeC_msg');
            }
            if (!isSetAgreeD) {
                checkDom.push('isAgreeD_msg');
            }

            this.ShowValidations(checkDom);

            return;
        }

        this.setState({
            ...this.state,
            isShowApplyPannel: !this.state.isShowApplyPannel
        });

    }
    onClickBack = (e) => {
        window.location.href = '/#/tuition_requests';
    }

    onClickChangeStatus2 = async (e) => {
        const rid = this.props.match.params.rid;
        const allUrls = await GetAjaxURL();
        const urls = allUrls.tuitionReqChangeStatus + '?request_id=' + rid;
        const trcRes = await axios.post(urls, {}, postConfig).catch((err) => { return console.log(err) });

        const lang = this.state.lang;
        const trcData = trcRes['data']['status'];
        let msg = '';
        if (trcData === 'success') {
            const internal_status = '2';
            this.setState({
                ...this.state,
                paramData: {
                    ...this.state.paramData,
                    internal_status: internal_status
                }
            }, () => {
                msg = (lang === 'ko') ? 'status가 1에서 2로 변경 완료되었습니다.' : lang === 'en' ? 'Successfully change status 1 to 2.' : '确认成功';
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            });
        } else {
            msg = trcRes['data']['msg'];
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
    }

    createPartBTN = () => {

        // internal_status == 3 && thisUserId==studentId

        const internal_status = String(this.state.paramData.internal_status);
        const thisUserId = String(this.state.thisUserID);
        const adminId = String(this.state.i)
        const studentId = String(this.state.studentID);
        const isTutorLogin = this.state.isTutorLogin;



        return (
            <div className='trs-card-partB'>
                <div className="trs-buttons-wrapper">
                    {
                        internal_status === '1' && this.state.isAdmin ?
                            <button className="trs-comm-back" onClick={this.onClickChangeStatus2}>
                                상의중
                        </button>
                            : ''
                    }
                    {
                        internal_status === '3' && thisUserId === studentId ?
                            <button className='trs-comm-back' onClick={this.onClickMatched}>
                                <span><TrasMatched /></span>
                            </button>
                            : ''
                    }

                    {
                        internal_status !== '3' && !isTutorLogin && thisUserId === studentId ?
                            <button className='trs-comm-org' onClick={this.onClickPostDone}>
                                <span><TrasIhavechosenatutor /></span>
                            </button>
                            : ''
                    }

                    {
                        thisUserId === studentId ?
                            <button className='trs-comm-green' onClick={this.onClickEdit}>
                                <span><TrasEdit /></span>
                            </button>
                            : ''
                    }

                    {
                        thisUserId === studentId ?
                            <button className='trs-comm-str-org' onClick={this.onClickDelete}>
                                <span><TrasDelete /></span>
                            </button>
                            : ''
                    }

                    {
                        internal_status === '1' && isTutorLogin ?
                            <button className="gurume-blue-back-btn" onClick={this.onClickApply}>
                                <span className='trs-nego'><TrasApply />__<FontAwesomeIcon icon={faPen} /></span>
                            </button>
                            : ''
                    }

                    {
                        isTutorLogin && internal_status === '2' ?
                            <button className='gurume-blue-back-btn'>
                                <span><FontAwesomeIcon icon={faCommentAlt} />&nbsp;상의중</span>
                            </button>
                            : ''
                    }
                    <button className='trs-comm-back' onClick={this.onClickBack}>
                        <span><TrasBack /></span>
                    </button>
                </div>
                {
                    internal_status === '1' && isTutorLogin ?
                        <p className="trs-all-agree">*위의 조건을 모두 동의하셔야 지원 가능합니다.</p>
                        : ''
                }
            </div>
        )

    }

    createBody = () => {
        return (
            <div className='trs-main-body'>
                <div className='trs-card-wrapper'>
                    {this.createPartA()}

                    {this.createPartB()}
                </div>

                <div className='trs-card-wrapperB'>
                    {this.createPartC()}
                </div>

                <div className='trs-card-wrapperB'>
                    {this.createPartD()}
                </div>


                <div className='trs-card-wrapperB'>
                    {this.createPartBTN()}
                </div>

            </div>
        )
    }

    moveToTdPage = (id, e) => {
        window.location.href = '/#/teachers/' + id;
    }

    recommendTeachersCard = () => {
        let x = [];
        let tutors = this.state.paramData.recommended_tutors;

        if (tutors.length == 0) {
            return;
        }

        x.push(<h4 className="color-gurume-blue font-size-24">추천 선생님</h4>)

        for (let i = 0; i < tutors.length; i++) {
            let tutor = tutors[i];
            let text_curriculums = '';

            for (let j = 0; j < tutor.curriculums.length; j++) {
                text_curriculums += tutor.curriculums[j].curriculum_name;
                text_curriculums += ' ';
            }

            x.push(
                <div className="recommend-teachers-card " onClick={(e) => this.moveToTdPage(tutor.user_profile.id, e)}>
                    <div className="recommend-teachers-card-main">
                        <div className="recommend-teachers-card-header">
                            <div className="recommend-teachers-card-header-box">
                                <div className="recommend-teachers-card-header-img">
                                    <div className="teacher-card-header-img-box">
                                        <img src={tutor.tutor_profile.picture} className="img-responsive"
                                            onError={function (e) { e.target.src = '../images/backuptutorpic.png'; }} />
                                    </div>
                                </div>
                                <div className="recommend-teachers-card-header-info">
                                    <div className="recommend-teachers-card-header-info-box">
                                        <span className="recommend-teachers-name"> {tutor.user_profile.last_name}{tutor.user_profile.first_name} </span><br />
                                        <span className="recommend-teachers-university">{tutor.tutor_profile.undergraduate_school ? tutor.tutor_profile.undergraduate_school.university_name : ''}</span><br />
                                        <span className="recommend-teachers-major color-gurume-gray">{tutor.tutor_profile.major}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="recommend-teachers-card-subject bg-gurume-blue">
                            <marquee className="search-marquee" scrollamount="0">
                                {text_curriculums}
                            </marquee>
                        </div>
                        <div className="recommend-teachers-card-content color-gurume-gray">
                            {tutor.tutor_profile.thumbnail_intro}
                        </div>
                        <div className="recommend-teachers-card-footer">
                            <span><i className="fa fa-youtube-play padding-right-3" style={{ color: 'red' }}></i>데모</span>
                            <span><i className="fa fa-thumbs-o-up padding-right-3"></i>{tutor.tutor_profile.all_review_count} 추천</span>
                            <span><i className="fa fa-comments-o padding-right-3"></i>{tutor.tutor_profile.viewable_review_count} 후기</span>
                        </div>
                    </div>
                </div>)
        }
        return (
            x
        )
    }

    setAgreeA = (e) => {
        const isChecked = e.target.checked;
        this.setState({
            ...this.state,
            isSetAgreeA: isChecked
        })
    }
    setAgreeB = (e) => {
        const isChecked = e.target.checked;
        this.setState({
            ...this.state,
            isSetAgreeB: isChecked
        })
    }
    setAgreeC = (e) => {
        const isChecked = e.target.checked;
        this.setState({
            ...this.state,
            isSetAgreeC: isChecked
        })
    }
    setAgreeD = (e) => {
        const isChecked = e.target.checked;
        this.setState({
            ...this.state,
            isSetAgreeD: isChecked
        })
    }



    createHtml = () => {
        return (
            <div className='trs-main-html'>
                {this.createNav()}

                {
                    this.state.isShowApplyPannel ?
                        this.createHideHeader()
                        : ''
                }
                <div className='tuition-request-show-wrapper'>
                    {this.createBody()}
                    {
                        this.state.isAdmin || this.state.studentID == this.state.thisUserID ?
                            <div className="recommend-teachers">
                                {this.recommendTeachersCard()}
                            </div> : ''
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='default-wrapper trs-main-wrapper'>
                {this.createHtml()}
            </div>
        )
    }

}

export default withRouter(Tuition_request_show);