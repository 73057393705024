import React, {Component} from 'react';
import {withRouter} from 'react-router';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';

import * as AuthService from '../../modules/AuthService';

class PrePrepare extends Component{

    state = {
    }


    componentDidMount = async () => {
        await this.props.AuthService.isLoginInReact(183);
    }


    resisterReactEvent = () => {
    }
    callingFunc = () => {
    }
    dontstand = () => {
    }

    render(){
        return(
            <div>
            </div>

        )
    }
}
let mapStateToProps = (state) => {
    return {
        AuthKey : state.AuthService.isAuthkey
    };
  }
  
  let mapDispatchToProps = (dispatch) => {
    return {
      AuthService : bindActionCreators(AuthService, dispatch)
    }
  }
  
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrePrepare));
  