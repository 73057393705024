import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {
    TrasHome,
    TrasUser,
    TrasPassword,
    TrasNew,
    TrasResetPassword,
    TrasNewPassword,
    TrasPleaseenterapassword,
    TrasConfirmNewPassword,
    TrasPleaseconfirmthepassword,
    TrasPassworddoesnotmatch,
    TrasSubmit
} from '../../../Translate/EachTranslateC';

import './resetpassword.css';
import '../../../default.css';
import './common.css';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { REGEXP } from '../../../config/configAllRoutes';
import { cusUtilStart } from '../../extra/cusUtil';
import queryString from 'query-string';
import {STATE_NOTIFY, showNotify} from '../../extra/cusUtil';



// /resetpassword

// I will do again :()
class resetpassword extends Component {

    state = {
        lang : 'ko',
        wrapperSize : '',

        resetPass : '',
        resetPassChk : '',

        passwordValidate : false,
        comparePassword : false,
        confirmPassword : false,

    }


    componentDidMount = async () => {
        cusUtilStart(this);

        // DO LOGIN CHECK FIRST
        // ( CAN BE LATER )



        // TOKEN CHECK START
        const qs = queryString.parse(this.props.location.search);
        const allUrls = await GetAjaxURL();
        const rememberTokenUrl = allUrls.rememberToken + '?token=' + qs.token;
        const res = await axios.post(rememberTokenUrl, {}, postConfig).catch((err)=>{console.log(err)});

        if(!res) return showNotify('통신에 실패했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
        if(!res.data) return showNotify('데이터를 받아오는데 실패했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);

        if(res.data.status === 'fail'){
            // showNotify(res.data.msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            window.location.href = '/#/';
        }else{
            // SUCCESS

        }
        // TOKEN CHECK END

    }

    calScreenSizeAndResizeWrapper = (wrapperSize) => {
        this.setState({
            wrapperSize
        })
    }

    createNv = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/"><TrasUser /></a></li>
                    <li><a href="/#/"><TrasPassword /></a></li>
                    <li><a href="/#/"><TrasNew /></a></li>
                </ol>
            </div>
        )
    }
    


    checkEnter = (e) => {
        if(e.keyCode !== 13) return;

        this.resetSubmit();
    }


    resetSubmit = async () => {

        const allUrls = await GetAjaxURL();
        const changePwUrl = allUrls.changePassword;

        const postData = {
            password: this.state.resetPass,
            password_confirmation: this.state.resetPassChk
        };

        let qsPwUrl = changePwUrl + '?' + queryString.stringify(postData);

        const res = await axios.post(qsPwUrl, {}, postConfig).catch((err)=>{console.log(err)});

        if(!res) return showNotify('통신에 실패했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
        if(!res.data) return showNotify('데이터를 받아오는데 실패했습니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
        
        if(res.data.status === 'fail'){
            let msg = '';
            let lang = this.state.lang;
            if(res.data.code === '1'){
                msg = 
                    ('ko' === lang) ? 
                        '현재 비밀번호를 잘못 입력하셨습니다.' : 
                            ('en' === lang) ?
                                'Current password is incorrect.' : '原来的密码错误。';

            }else{
                // (res.data.code === '2')
                msg =
                    ('ko' === lang) ?
                        '비밀번호를 조금 더 길고 어렵게 작성해주세요.' :
                            ('en' === lang) ?
                                'Your password strength is too low.' : '您的密码强度太低。';

            }

            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);

        }else{
            // SUCCESS
            let msg = '회원님의 비밀번호가 성공적으로 변경되었습니다.';
            if(this.state.lang === 'en'){
                msg = '密码修改成功。';
            }else if(this.state.lang === 'cn'){
                msg = 'Password Change Complete.';
            }

            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            window.location.href = '/#/';
        }

    }


    validationOfResetPass = (_val) => {
        const val = _val;

        if(val.length < 6) return false;

        return true;
    }

    setResetPass = (e) =>{
        const value = e.target.value.trim();

        const validation = this.validationOfResetPass(value);

        this.setState({
            ...this.state,
            resetPass : value,
            passwordValidate : validation,
            comparePassword : false,
            confirmPassword : false,
        })
    }

    setResetPassChk = async (e) => {

        const value = await this.validationOfResetPassChk(e.target.value);

        this.setState({
            ...this.state,
            resetPassChk : value.value,
            comparePassword : value.comparePassword,
            confirmPassword : value.confirmPassword
        });
    }

    validationOfResetPassChk = (_val) => {

        const val = _val.trim();
        const returnVal = {
            value : val,
            comparePassword : false,
            confirmPassword : false,
        };

        if(this.state.resetPass === '' || !this.state.passwordValidate){
            returnVal.confirmPassword = true;
            returnVal.value = '';

            const resetPw = document.getElementById('resetPw');
            resetPw.focus();
            return returnVal;
        }

        if(this.state.resetPass !== val){
            returnVal.comparePassword = true;
        }

        return returnVal;
    }




    createBody = () => {
        return(

            <div className="common-body-wrapper">
                <div className="common-body-inner-wrap common-body-padding">
                    <div className="common-body-margin-bot">
                        <h3 className='common-body-h3-positive common-body-margin-bot'><TrasResetPassword /></h3>
                        <hr style={{border:'0.5px solid #5b9aef'}}></hr>
                    </div>

                    <div className="common-body-margin-bot">
                        <h4 className="common-body-blue-color"><TrasNewPassword /></h4>
                        <div className="common-body-margin-bot">
                            <div className="">
                                <input id='resetPw'
                                className="form-control" 
                                placeholder="" 
                                onChange={this.setResetPass} 
                                value={this.state.resetPass}
                                type="password" />
                            </div>
                            {
                                !this.state.passwordValidate?
                                    <span style={{textAlign: "center", color: "red"}} ng-show="passwordValidate==true"><TrasPleaseenterapassword /></span>
                                : ''
                            }
                        </div>
                        <h4 className="common-body-blue-color"><TrasConfirmNewPassword /></h4>
                        <div className="">
                            <div className="">
                                <input className="form-control" placeholder=""
                                    onChange={this.setResetPassChk} 
                                    value={this.state.resetPassChk}
                                    onKeyDown={this.checkEnter}
                                type="password" />
                            </div>
                            
                            {
                                this.state.comparePassword?
                                    <span style={{textAlign: "center", color: "red"}}><TrasPassworddoesnotmatch /></span>
                                : ''                            
                            }
                            {
                                this.state.confirmPassword?
                                    <span style={{textAlign: "center", color: "red"}}><TrasPleaseconfirmthepassword /></span>
                                : ''
                            }
                        </div>
                        <div className='re-pw-btns'>
                            <div className='re-pw-align'>
                                <button className="btn center-block submit-btn" onClick={this.resetSubmit}><TrasSubmit /></button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    createHtml = () => {
        return(
            <div>
                {this.createNv()}
                <div className='academy-wapper-c'>
                    {this.createBody()}
                </div>
            </div>
        )
    }

    render(){
        return(
            <div className='default-wrapper'>
                {this.createHtml()}
            </div>
        )
    }
}

export default withRouter(resetpassword);