import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasTuitionRequest,
    TrasNew,
    TrasStudentname,
    TrasSelectpreferencetutorgender,
    TrasMale,
    TrasFemale,
    TrasNopref,
    TrasTutorlevel,
    TrasUndergraduate,
    TrasGraduate,
    TrasProfessional,
    TrasTeachingStyle,

    TrasActivelyleadthestudent,
    TrasBoostuptestgrades,
    TrasMotivateandinspireasanolderbrotherandsisterlikementor,
    TrasHelpwithhomework,
    TrasExplaindifficultconceptsclearly,
    TrasMakethesubjectmoreinteresting,

    TrasLocation,
    TrasCurriculumSubject,

    TrasTest,
    TrasAdmission,
    TrasEnglish,
    TrasTuitionMode,

    TrasOnline,
    TrasFacetoface,
    TrasSNSMentoring,

    TrasHourlyRate,
    TrasTuitionAim,
    TrasPleasewritedownasmuchaspossibleaboutyourtuitionneedsandgoalssothatapplyingtutorscanexplainindetailshowtheycanhelp,

    TrasStartingEndDate,
    TrasTuitionFrequency,
    Trassessionsweek,

    TrasSubmit,
    TrasBack,


} from '../../../Translate/EachTranslateC';

import './tuition_request_new.css';

import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { Handle, Track, Tick } from '../../extra/Slider';
import { Button } from 'semantic-ui-react';
import Calendar from 'react-calendar'
import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';
import { isAdminEmail } from '../../extra/utils';

import axios from '../../../config/axios';
import queryString from 'query-string';

import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';

class Tuition_request_new extends Component {

    state = {
        userType: '',
        teacherId: '',
        tuitionReqId: '',
        lang: 'ko',
        isAdmin: false,
        isEdit: false,
        isInit: false,
        translate: {
            ko: {
                TrasDistrictandtown: '구/동',
            },
            en: {
                TrasDistrictandtown: 'District and town',
            },
            cn: {
                TrasDistrictandtown: '区路',
            }
        },

        paramData: {
            curriculums: [],
            mode: [],
            location_id: '37',                                          //  DEFAULT
            location_memo: '',
            teaching_styles: [],
            details: '학생소개\r\n- \r\n- \r\n- \r\n\r\n구하는 선생님\r\n- \r\n- \r\n- \r\n\r\n시간대/위치\r\n- \r\n- \r\n- \r\n\r',                                                // $scope.customTeachStyle || ''
            start_time: '',
            end_time: '',
            hours: '',
            lang: '',
            is_hidden: '',
            student_name: '',
            sex: '0',
            gender: '',
            tutor_level: '',
            hourly_rate: '70000',
            uploaded_by_admin: '',

            tuitionRequestMessage: '',

            isReadStudentTermsAndCondition: false,
            isLevelUndergraduateChecked: false,
            isLevelGraduate: false,
            isLevelProfessional: false,
        },
        curriculumns: {
            Admission: {},
            Test: {},
            English: {},
        },
        currentKrLocation: '한국',
        currentLocation: '37',                             // default
        locations: [],
        allLocationData: [],

        CurrentSelectedTitle: '',
        CurrentSelectedCul: '',

        CUL_TITLE: {
            TEST: 'Test',
            ADMISSION: 'Admission',
            ENGLISH: 'English'
        },

        calendarState: {
            formattingDate: '',
            msTime: 0,
            formattingDateSec: '',
            msTimeSec: 0,
        },
        eventState: {
            needCalendar: false,
            needCalendarSec: false,
        },
        isCheckedForNotSettingDate: false,
        isSendding: false,
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.location.reload();
        }
        return true;
    }

    componentDidMount = async () => {

        const teacherId = this.props.location.search;
        let getTeacherId = '';
        if (teacherId) {
            if (teacherId.indexOf('teacherId') !== -1) {
                getTeacherId = teacherId.substr(teacherId.indexOf('teacherId=') + 'teacherId='.length, teacherId.length);
            }
        }


        let isEdit = false;
        let tuitionReqId = '';
        if (window.location.href.indexOf('edit') !== -1) {
            tuitionReqId = this.props.match.params.rid;
            isEdit = true;
        }

        await this.initFunc(getTeacherId, isEdit, tuitionReqId);
    }


    initFunc = async (_teacherId, _isEdit, _tuitionReqId) => {

        const teacherId = _teacherId ? _teacherId : '';
        const tuitionReqId = _tuitionReqId ? _tuitionReqId : '';
        const isEdit = _isEdit;


        const lang = this.state.lang;

        const allUrls = await GetAjaxURL();
        const isLoginUrl = allUrls.isLogin;
        const isLoginRes = await axios.get(isLoginUrl).catch((err) => { return console.log("err"); });
        const isLogin = isLoginRes.data.isLogin === 'true' ? true : false;
        const isStudent = isLoginRes.data.type === 'student' ? true : false;

        const userType = isLoginRes.data.type;

        let userData = {};
        let isAdmin = false;

        if (isLogin) {
            if (!isStudent) {
                const msg = (lang === 'ko' ? '학생 회원만 가능합니다.' : lang === 'en' ? 'Only student can access.' : '您不是学生');
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                window.location.href = '/#/tuition_request_index';
                return;
            }

            const thisUserUrl = allUrls.apiThisUser;
            const thisUserRes = await axios.get(thisUserUrl).catch((err) => { return console.log("err") });
            userData = thisUserRes.data.user;
            const thisEmail = userData.email;

            if (isAdminEmail(thisEmail)) {
                isAdmin = true;
            }
        } else {
            // do Login
            const msg = (lang === 'ko' ? '로그인 후 이용가능합니다.' : lang === 'en' ? 'Please login.' : '请登录');
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            window.location.href = '/#/login?redirect=request_new';
            return;
        }

        const curriculUrl = allUrls.curriculums;
        const curriculRes = await axios.get(curriculUrl).catch((err) => { return console.log("err") });
        const curriculumns = curriculRes.data;
        let defaultCulTitle = Object.keys(curriculumns)[1];
        let defaultSelectedCul = Object.keys(curriculumns[defaultCulTitle])[0];


        const locationUrl = allUrls.locations + '/?lan=' + this.state.lang;
        const locationRes = await axios.get(locationUrl).catch((err) => { return console.log("err") });
        const locations = locationRes.data;


        const allLocData = [];
        for (let i = 0; i < locations.length; i++) {
            const region = locations[i]['region'];
            const detail = locations[i]['detail'];
            for (let k = 0; k < detail.length; k++) {
                const id = detail[k]['id'];
                const city = detail[k]['city'];
                allLocData.push({
                    id, region, city
                });
            }
        }



        let xsData = {};
        let currentLocation = this.state.currentLocation;
        let currentKrLocation = this.state.currentKrLocation;
        let isReadStudentTermsAndCondition = this.state.paramData.isReadStudentTermsAndCondition;
        let isLevelUndergraduateChecked = this.state.paramData.isLevelUndergraduateChecked;
        let isLevelGraduate = this.state.paramData.isLevelGraduate;
        let isLevelProfessional = this.state.paramData.isLevelProfessional;
        let formattingDate = this.state.calendarState.formattingDate;
        let formattingDateSec = this.state.calendarState.formattingDateSec;
        let isCheckedForNotSettingDate = this.state.isCheckedForNotSettingDate;

        if (isEdit) {

            const currentUrl = window.location.href.split('/');
            let rid = '';
            for (let i = 0; i < currentUrl.length; i++) {
                if (Number(currentUrl[i])) {
                    rid = currentUrl[i];
                }
            }
            if (!rid) {
                let msg = '해당 주소를 찾지 못했습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return window.location.href = '/#/tuition_requests';
            }

            const tuitionReqWithRidUrl = allUrls.tuitionReqWithRid + rid;
            const tuitionReqWithRidRes = await axios.get(tuitionReqWithRidUrl).catch((err) => { return console.log(err); });


            if (!tuitionReqWithRidRes) {
                let msg = '해당 정보를 받아오는데 실패했습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return window.location.href = '/#/tuition_requests';
            }
            if (tuitionReqWithRidRes['status'] !== 200) {
                let msg = '해당 정보를 받아오는데 실패했습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return window.location.href = '/#/tuition_requests';
            }

            // Edit Info
            const paramData = tuitionReqWithRidRes['data'];


            const paramCurri = paramData.curriculums;
            const reFreshCul = [];
            for (let i = 0; i < paramCurri.length; i++) {
                reFreshCul.push(String(paramCurri[i]['id']));
            }

            const tempSelectedObjOfCur = await this.getCurriculumnSelectionType(curriculumns, paramCurri);
            if (tempSelectedObjOfCur !== null) {
                defaultCulTitle = tempSelectedObjOfCur.name;
                defaultSelectedCul = tempSelectedObjOfCur.subName;

            }


            const mode = paramData.mode;
            const location_id = paramData.location_id;
            const location_memo = paramData.location_memo;
            const teaching_styles = paramData.teaching_styles;
            const details = paramData.details;
            const start_time = paramData.start_time;
            const end_time = paramData.end_time;
            const hours = paramData.hours;
            const hourly_rate = paramData.hourly_rate;
            const student_name = paramData.student_name;
            const sex = paramData.gender;
            const gender = sex;
            const tutor_level = paramData.tutor_level.split('_');
            const uploaded_by_admin = String(paramData.uploaded_by_admin) === '1' ? true : false;
            isAdmin = uploaded_by_admin ? true : false;
            const is_hidden = ''



            for (let i = 0; i < mode.length; i++) {
                if (mode[i] === '1') {
                    const triOnline = document.getElementById('triOnline');
                    if (triOnline) {
                        triOnline.checked = true;
                    }
                } else if (mode[i] === '2') {
                    const triGraduate = document.getElementById('triGraduate');
                    if (triGraduate) {
                        triGraduate.checked = true;
                    }
                } else if (mode[i] === '3') {
                    const triProfessional = document.getElementById('triProfessional');
                    if (triProfessional) {
                        triProfessional.checked = true;
                    }
                }
            }


            isReadStudentTermsAndCondition = true;
            for (let i = 0; i < tutor_level.length; i++) {
                if (tutor_level[i] === 'undergraduate') {
                    isLevelUndergraduateChecked = true;
                } else if (tutor_level[i] === 'graduate') {
                    isLevelGraduate = true;
                } else if (tutor_level[i] === 'professional') {
                    isLevelProfessional = true;
                }
            }

            for (let i = 0; i < teaching_styles.length; i++) {
                const domId = 's' + teaching_styles[i]['id'];
                const selcDom = document.getElementById(domId);
                if (selcDom)
                    selcDom.click();
            }

            xsData = {
                curriculums: reFreshCul,
                location_id, location_memo,
                details,
                start_time, end_time,
                uploaded_by_admin,
                hours, lang,
                is_hidden, student_name,
                sex, gender,
                tutor_level, hourly_rate,
                uploaded_by_admin,
                mode,

                isReadStudentTermsAndCondition,
                isLevelUndergraduateChecked,
                isLevelGraduate,
                isLevelProfessional
            };

            currentLocation = String(location_id);
            for (let i = 0; i < allLocData.length; i++) {
                if (String(allLocData[i]['id']) === String(location_id)) {
                    currentKrLocation = allLocData[i]['region'];
                    break;
                }
            }

            formattingDate = start_time;
            formattingDateSec = end_time;
            if (formattingDateSec === '2100-01-01') {
                isCheckedForNotSettingDate = true;
            }
        }


        this.setState({
            ...this.state,
            userType: userType,
            isEdit: isEdit,
            tuitionReqId: tuitionReqId,
            teacherId,
            isAdmin,
            curriculumns,

            paramData: {
                ...this.state.paramData,
                ...xsData
            },

            locations: locations,
            allLocationData: allLocData,
            currentLocation: currentLocation,
            currentKrLocation: currentKrLocation,

            CurrentSelectedTitle: defaultCulTitle,
            CurrentSelectedCul: defaultSelectedCul,
            calendarState: {
                ...this.state.calendarState,
                formattingDate: formattingDate,
                formattingDateSec: formattingDateSec,
            },
            isCheckedForNotSettingDate: isCheckedForNotSettingDate,
        }, () => {
            const locRegionSelect = document.getElementById('locRegionSelect');
            let currVal = '';
            for (let i = 0; i < this.state.allLocationData.length; i++) {
                if (String(this.state.allLocationData[i]['id']) === String(this.state.currentLocation)) {
                    currVal = this.state.allLocationData[i]['region'];
                    break;
                }
            }
            locRegionSelect.value = currVal;


            if (this.state.paramData.sex === '1') {
                const rMan = document.getElementById('rMan');
                rMan.click();
            } else if (this.state.paramData.sex === '2') {
                const rWoman = document.getElementById('rWoman');
                rWoman.click();
            } else {
                const rNoSelected = document.getElementById('rNoSelected');
                rNoSelected.click();
            }

            if (this.state.calendarState.formattingDateSec === '2100-01-01') {
                const isContinuousSelected = document.getElementById('isContinuousSelected');
                isContinuousSelected.click();
            }

            const isFrequency = document.getElementById('isFrequency');
            isFrequency.value = this.state.paramData.hours;

            if (this.state.CurrentSelectedTitle === 'Test') {
                const xTEST = document.getElementById('xTEST');
                xTEST.click();
            } else if (this.state.CurrentSelectedTitle === 'Admission') {
                const xADMISSION = document.getElementById('xADMISSION');
                xADMISSION.click();
            } else if (this.state.CurrentSelectedTitle === 'English') {
                const xENGLISH = document.getElementById('xENGLISH');
                xENGLISH.click();
            } else {
                // Default value Admission
                // const xADMISSION = document.getElementById('xADMISSION');
                // xADMISSION.click();
                // 시험으로 디폴트값 변경
                const xTEST = document.getElementById('xTEST');
                xTEST.click();
            }


            // Showing Alert
            if (this.state.userType !== 'student') {
                const tutiAlert = document.getElementById('tutiAlert');
                tutiAlert.classList.remove('hide-for-alert');
                tutiAlert.classList.add('show-for-alert');
            }

            this.props.RootBindingData.doLoading(false);

        });

    }

    getCurriculumnSelectionType = async (_curriculumns, _paramCurri) => {
        const curriculumns = _curriculumns;
        const paramCurri = _paramCurri;
        let curriculumnSelectionType = '';

        if (curriculumns.length === 0) return null;
        if (paramCurri.length === 0) return null;

        // 백분율로 선택되는 항목 선정함.
        // 무슨 말이냐면 각 항목당 선택되는 항목이 발생되니, 각 항목 중에서 가장 많은 항목을 가지고 있는 걸 우선 순위로 둬서
        // 초기 선택되는 항목을 선정하겠다는 뜻임.

        // 선정되는 변수들 퍼센트 : default 0
        let Test = 0;
        let Admission = 0;
        let English = 0;

        // 모든 커리큘럼 데이터 재정의.
        const newCurriculumns = [];
        const curObjKeys = Object.keys(curriculumns);
        for (let i = 0; i < curObjKeys.length; i++) {
            const sTitle = curriculumns[curObjKeys[i]];
            const subObjKeys = Object.keys(sTitle);
            for (let k = 0; k < subObjKeys.length; k++) {
                const eachSubTitle = subObjKeys[k];
                const eachData = curriculumns[curObjKeys[i]][eachSubTitle];
                for (let j = 0; j < eachData.length; j++) {
                    const eachId = eachData[j].id;
                    const tempObj = {
                        Title: curObjKeys[i],
                        SubTitle: eachSubTitle,
                        id: eachId
                    };
                    newCurriculumns.push(tempObj);
                }
            }
        }


        const tempValArr = [];
        for (let i = 0; i < newCurriculumns.length; i++) {
            const eachId = String(newCurriculumns[i]['id']);
            for (let k = 0; k < paramCurri.length; k++) {
                const eachParamId = String(paramCurri[k]['id']);
                if (eachId === eachParamId) {
                    tempValArr.push(newCurriculumns[i]);
                }
            }
        }

        // 백분율 구하기 위한 카운트
        for (let i = 0; i < tempValArr.length; i++) {
            const TitleName = tempValArr[i]['Title'];
            if (TitleName === 'Test') {
                Test++;
            } else if (TitleName === 'Admission') {
                Admission++;
            } else if (TitleName === 'English') {
                English++;
            }
        }

        // 백분율 구하기
        const totalCount = Test + Admission + English;
        Test = Test / totalCount * 100;
        Admission = Admission / totalCount * 100;
        English = English / totalCount * 100;


        // 가장 큰 항목 찾기 DESC
        const sortArr = [];
        sortArr.push({ name: 'Test', value: Test });
        sortArr.push({ name: 'Admission', value: Admission });
        sortArr.push({ name: 'English', value: English });
        sortArr.sort((a, b) => { return b.value - a.value; });
        const firstReward = sortArr[0];



        // 선정한 항목 하나의 subTitle 가져오기
        const forSubArr = [];
        for (let i = 0; i < tempValArr.length; i++) {
            const TitleName = tempValArr[i]['Title'];
            if (firstReward.name === TitleName) {
                forSubArr.push(tempValArr[i]);
            }
        }



        return { name: forSubArr[0]['Title'], subName: forSubArr[0]['SubTitle'] }

    }



    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/tuition_requests"><TrasTuitionRequest /></a></li>
                    <li><TrasNew /></li>
                </ol>
            </div>
        )
    }



    setName = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                student_name: value
            }
        }, () => {
        })
    }

    setSexAndGender = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                sex: value,
                gender: value,
            }
        }, () => {
        })
    }

    setUndergraduate = (e) => {
        const isChecked = e.target.checked;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                isLevelUndergraduateChecked: isChecked
            }
        })
    }
    setGraduate = (e) => {
        const isChecked = e.target.checked;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                isLevelGraduate: isChecked
            }
        })
    }
    setProfessional = (e) => {
        const isChecked = e.target.checked;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                isLevelProfessional: isChecked
            }
        })
    }

    setTeachingStyle = (e) => {
        let target = e.target;
        let data = target.dataset['sbKey'];

        if (!data) {
            while (!data) {
                target = target.parentNode;
                data = target.dataset['sbKey'];
            }
        }

        const originTeachingData = this.state.paramData.teaching_styles;

        if (!originTeachingData.includes(data)) {
            if (originTeachingData.length > 3) {
                originTeachingData.shift();
            }
            originTeachingData.push(data);
        }

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                teaching_styles: originTeachingData,
            }
        }, () => {
            const tutionReqTeachingStyle = document.getElementById('tutionReqTeachingStyle');
            for (let i = 0; i < tutionReqTeachingStyle.childElementCount; i++) {
                const eachChild = tutionReqTeachingStyle.children[i];
                const eachData = eachChild.dataset['sbKey'];
                const teachingStyle = this.state.paramData.teaching_styles;
                if (teachingStyle.includes(eachData)) {
                    eachChild.classList.add('tri-span-active');
                } else {
                    eachChild.classList.remove('tri-span-active');
                }
            }
        })

    }

    setRegionLocation = (e) => {
        const value = e.target.value;

        const allLoc = this.state.allLocationData;

        let reVal = '';
        for (let i = 0; i < allLoc.length; i++) {

            if (allLoc[i]['region'] === value) {
                reVal = String(allLoc[i]['id']);
                break;
            }
        }

        this.setState({
            ...this.state,
            currentKrLocation: value,
            currentLocation: reVal,
            paramData: {
                ...this.state.paramData,
                location_id: reVal
            }
        }, () => {
        })
    }

    setDetailLocation = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                location_id: value
            }
        })
    }

    setLocationMemo = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                location_memo: value
            }
        })
    }


    createPartA = () => {

        const lang = this.state.lang;
        let TrasDistrictandtown = this.state.translate.ko.TrasDistrictandtown;
        if (lang === 'en') {
            TrasDistrictandtown = this.state.translate.en.TrasDistrictandtown;
        } else if (lang === 'cn') {
            TrasDistrictandtown = this.state.translate.cn.TrasDistrictandtown;
        }

        const locationsData = this.state.locations;
        const customLocationDetail = this.state.allLocationData;
        const regions = [];
        const regionDetail = [];
        for (let i = 0; i < locationsData.length; i++) {
            regions.push(
                <option key={`region_${i}`} value={locationsData[i].region}>{locationsData[i].region}</option>
            )
        }
        for (let i = 0; i < customLocationDetail.length; i++) {
            if (String(customLocationDetail[i]['region']) === String(this.state.currentKrLocation)) {
                regionDetail.push(
                    <option key={`detail_${i}`} value={customLocationDetail[i].id}>{customLocationDetail[i].city}</option>
                );
            }
        };

        return (
            <div className='tri-new-partA'>
                {
                    this.state.isAdmin ?
                        <div className='tri-new-partA-item'>
                            <div className="tri-new-header"><TrasStudentname /></div>
                            <div className='tri-new-single'>
                                <input id="student_name_input" onChange={this.setName} value={this.state.paramData.student_name} type="text" />
                            </div>
                        </div>
                        : ''
                }
                {
                    this.createPartB()
                }
                <div className='tri-new-partA-item'>
                    <div className="tri-new-header"><TrasLocation /></div>
                    <div className='tri-new-fix-location'>
                        <div className='tri-new-location'>
                            <div className="">
                                <select onChange={this.setRegionLocation} id='locRegionSelect'>
                                    {regions}
                                </select>
                            </div>
                            <div className="">
                                <select className="" onChange={this.setDetailLocation} key={this.state.currentLocation} >
                                    {regionDetail}
                                </select>
                            </div>
                        </div>
                        <div className="tri-new-single">
                            <input className="" onChange={this.setLocationMemo} placeholder={TrasDistrictandtown} value={this.state.paramData.location_memo} type="text" />
                        </div>
                    </div>
                </div>
                <div className='tri-new-partA-item'>
                    <div className="tri-new-header"><TrasTeachingStyle /></div>
                    <div id='tutionReqTeachingStyle'>
                        <span data-sb-key='1' id='s1' onClick={this.setTeachingStyle}><span>꼼꼼 내신 대비</span></span>
                        <span data-sb-key='2' id='s2' onClick={this.setTeachingStyle}><span>전략 위주 시험 대비</span></span>
                        <span data-sb-key='3' id='s3' onClick={this.setTeachingStyle}><span>동기부여</span></span>
                        <span data-sb-key='4' id='s4' onClick={this.setTeachingStyle}><span>디테일에 강한</span></span>
                        <span data-sb-key='5' id='s5' onClick={this.setTeachingStyle}><span>탄탄한 경력</span></span>
                        <span data-sb-key='6' id='s6' onClick={this.setTeachingStyle}><span>숙제량 많은</span></span>
                        <span data-sb-key='7' id='s7' onClick={this.setTeachingStyle}><span>스파르타 식</span></span>
                        <span data-sb-key='8' id='s8' onClick={this.setTeachingStyle}><span>성과 보장</span></span>
                    </div>
                </div>
                {this.createPartC()}
            </div>
        )
    }

    setHourlyRate = (val) => {
        const hourlyRateMax = val[0];
        this.setState({

            ...this.state,
            paramData: {
                ...this.state.paramData,
                hourly_rate: hourlyRateMax,
            }
        }, () => {
        });
    }

    setTriOnline = (e) => {
        const isChecked = e.target.checked;
        const value = e.target.value;
        const mode = this.state.paramData.mode;
        let newMode = [];

        if (isChecked) {
            if (!mode.includes(value)) {
                mode.push(value);
                while (mode.length > 0) {
                    const each = mode.shift();
                    newMode.push(each);
                }
            }
        } else {
            if (mode.includes(value)) {
                let i = mode.length - 1;
                while (i >= 0) {
                    if (mode[i] !== value) {
                        newMode.push(mode[i]);
                    }
                    i--;
                }
            }
        }
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                mode: newMode
            }
        }, () => { })

    }

    setDetails = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                details: value
            }
        })
    }


    createPartC = () => {

        return (
            <div>
                <div className='tri-new-partA-item'>
                    <div className="tri-new-header"><TrasTuitionAim /></div>
                    <div>
                        <p><TrasPleasewritedownasmuchaspossibleaboutyourtuitionneedsandgoalssothatapplyingtutorscanexplainindetailshowtheycanhelp /></p>
                        <textarea rows="12" onChange={this.setDetails} value={this.state.paramData.details}></textarea>
                    </div>
                </div>
                <div className='tri-new-partB-item adjust-margin'>
                    <div className="tri-new-header border-zero"><TrasStartingEndDate /></div>
                    <div className='tri-new-calenda'>
                        <label htmlFor="isContinuousSelected" className='dating'> <input type="checkbox" value="3" id='isContinuousSelected' onChange={this.setNotSettingDate} /> 수업 종료 날짜 미정</label>&nbsp;
                        {this.callCalenders()}
                    </div>
                </div>

                <div className='tri-new-partB-item adjust-margin'>
                    <div className="tri-new-header border-zero"><TrasTuitionFrequency /></div>
                    <div className='tri-new-single'>
                        <label htmlFor="isFrequency"> <input type="number" id="isFrequency" onChange={this.setThatFreqeuncy} /> <Trassessionsweek /></label>&nbsp;
                    </div>
                </div>
            </div>
        )
    }


    calendarFormat = (_date) => {
        // format : new Date();
        const date = _date;
        const yyyy = date.getFullYear().toString();
        const mm = (date.getMonth() + 1).toString();
        const dd = date.getDate().toString();

        return yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0]);

    }

    showCalendarPannel = () => {
        this.setState({
            ...this.state,
            eventState: {
                ...this.state.eventState,
                needCalendar: !this.state.eventState.needCalendar
            }
        }, () => {

        });
    }
    calendarOnChange = (date) => {
        const dateFormatting = this.calendarFormat(date);

        const tempDate = new Date();
        const formatDate = this.calendarFormat(tempDate);
        const compareDate = new Date(formatDate).getTime();
        const selectedDate = new Date(dateFormatting).getTime();

        if (compareDate >= selectedDate) {
            let msg = '지난 날짜와 오늘 날짜는 선택되지 않습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                start_time: dateFormatting,
            },
            calendarState: {
                ...this.state.calendarState,
                formattingDate: dateFormatting,
                msTime: date.getTime()
            }
        }, () => {
            this.showCalendarPannel();
        });
    }

    showCalendarPannelSec = () => {

        if (this.state.isCheckedForNotSettingDate) {
            const msg = '수업 종료 날짜가 미정일 시 선택이 안 됩니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_INFO);

        } else {
            this.setState({
                ...this.state,
                eventState: {
                    ...this.state.eventState,
                    needCalendarSec: !this.state.eventState.needCalendarSec
                }
            }, () => {
            });
        }
    }


    calendarOnChangeSec = (date) => {
        const dateFormatting = this.calendarFormat(date);

        const formatDate = this.state.calendarState.formattingDate;
        const compareDate = new Date(formatDate).getTime();
        const selectedDate = new Date(dateFormatting).getTime();

        if (compareDate >= selectedDate) {
            let msg = '시작 날짜가 끝나는 날짜보다 늦을 수 없습니다. 시작 날짜를 다시 설정해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }


        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                end_time: dateFormatting,
            },
            calendarState: {
                ...this.state.calendarState,
                formattingDateSec: dateFormatting,
            }
        }, () => {
            this.showCalendarPannelSec();
            let msg = '날짜가 설정되었습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
        });
    }



    // callCalenders
    callCalenders = () => {

        return (
            <div className="tuitions_bill-calender-wapper">
                <div className="tuitions_bill-calender-innerwapper">
                    <div className='tuitions_bill-part-b-calendar'>

                        <Button basic onClick={this.showCalendarPannel} > <i className="fa fa-calendar"></i> </Button>
                        <span className='tuitions_bill-part-b-input-span'>
                            <input type="text" className="form-control" placeholder="yyyy/mm/dd" id="formattingDate"
                                value={`${this.state.calendarState.formattingDate}`}
                                onChange={() => { console.log('temp') }}
                            />
                        </span>

                    </div>
                    <div className='tuitions_bill-part-b-line-fixer'>
                        {
                            this.state.eventState.needCalendar ?
                                <Calendar
                                    onChange={this.calendarOnChange}
                                    value={new Date()}
                                />
                                : ''
                        }
                    </div>

                </div>
                <div className="tuitions_bill-calender-innerwapper">
                    <div className='tuitions_bill-part-b-calendar'>

                        <Button basic onClick={this.showCalendarPannelSec} > <i className="fa fa-calendar"></i> </Button>
                        <span className='tuitions_bill-part-b-input-span'>
                            <input type="text" className="form-control" placeholder="yyyy/mm/dd" id="formattingDateSec"
                                value={`${this.state.calendarState.formattingDateSec}`}
                                onChange={() => { console.log('temp') }}
                            />
                        </span>
                    </div>
                    <div className='tuitions_bill-part-b-line-fixer'>
                        {
                            this.state.eventState.needCalendarSec ?
                                <Calendar
                                    onChange={this.calendarOnChangeSec}
                                    value={new Date()}
                                />
                                : ''
                        }
                    </div>
                </div>
                <p className='gurume-red'>시작 날짜와 끝나는 날짜가 같을 경우, 자동으로 끝나는 날짜가 하루 추가됩니다. </p>
            </div>
        )
    }


    clickToSelectedTitle = (e) => {

        let target = e.target;
        let curData = target.dataset['culKey'];
        if (!curData) {
            target = target.parentNode;
            curData = target.dataset['culKey'];
        }

        // 클레스 초기화
        const xTEST = document.getElementById('xTEST');
        const xADMISSION = document.getElementById('xADMISSION');
        const xENGLISH = document.getElementById('xENGLISH');

        // 선택 클레스 지우기
        const SELECTED_CLASS = 'tuti-border-select';
        xTEST.classList.remove(SELECTED_CLASS);
        xADMISSION.classList.remove(SELECTED_CLASS);
        xENGLISH.classList.remove(SELECTED_CLASS);

        // 선택 클레스
        target.classList.add(SELECTED_CLASS);



        const currentCulTitle = curData;
        const currentCul = this.state.CurrentSelectedCul;
        const curriculumns = this.state.curriculumns;
        const curTitle = Object.keys(curriculumns);

        let selectedCuls = [];
        let selectedSubj = [];
        let selectedCulKey = '';
        for (let i = 0; i < curTitle.length; i++) {
            if (currentCulTitle === curTitle[i]) {
                selectedCuls = curriculumns[curTitle[i]];
            }
        }
        const selectedCulKeys = Object.keys(selectedCuls);
        selectedCulKey = selectedCulKeys[0];
        selectedSubj = selectedCuls[selectedCulKeys[0]];

        // 초기값만 설정. 한 번 이후는 무조건 true;
        const isInit = true;
        if (!this.state.isInit) {
            const xOption = document.getElementById('xOption');
            xOption.value = this.state.CurrentSelectedCul;
            selectedCulKey = this.state.CurrentSelectedCul;
        }


        this.setState({
            ...this.state,
            isInit: isInit,
            CurrentSelectedTitle: curData,
            CurrentSelectedCul: selectedCulKey
        }, () => {
            this.showDipalyCurr();
        })

    }
    clickToSelectedCul = (e) => {
        const target = e.target;
        this.setState({
            ...this.state,
            CurrentSelectedCul: target.value
        }, () => {
            this.showDipalyCurr();
        });
    }
    clickToSelectedSubj = (e) => {

        const target = e.target;
        const data = target.dataset['culId'];

        const selectedCul = this.state.paramData.curriculums;
        let newCul = [];

        if (!selectedCul.includes(data)) {
            selectedCul.push(data);
            newCul = selectedCul;
        } else {
            let i = selectedCul.length - 1;
            while (i >= 0) {
                if (selectedCul[i] !== data) {
                    newCul.push(selectedCul[i]);
                }
                i--;
            }
        }

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                curriculums: newCul
            }
        }, () => {
            this.showDipalyCurr();
        })
    }

    showDipalyCurr = () => {
        const triSecList = document.getElementById('triSecList');
        const culs = this.state.paramData.curriculums;
        for (let i = 0; i < triSecList.childElementCount; i++) {
            const eachChild = triSecList.children[i];
            eachChild.classList.remove('tri-active');
            for (let k = 0; k < culs.length; k++) {
                if (eachChild.dataset['culId'] === culs[k]) {
                    eachChild.classList.add('tri-active');
                } else {
                }
            }
        }

    }




    setThatFreqeuncy = (e) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                hours: value
            }
        })
    }



    setNotSettingDate = (e) => {

        const isChecked = e.target.checked;

        let value = '2100-01-01';
        if (!isChecked) {
            value = '';
        }
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                end_time: value
            },
            calendarState: {
                ...this.state.calendarState,
                formattingDateSec: value,
            },
            isCheckedForNotSettingDate: isChecked
        });
    }

    createPartB = () => {

        const sliderStyle = {  // Give the slider some width
            position: 'relative',
            width: '100%',
            height: 80,
        };

        const railStyle = {
            position: 'absolute',
            width: '100%',
            height: 10,
            marginTop: 35,
            borderRadius: 5,
            backgroundColor: '#8B9CB6',
        };

        const curriculumns = this.state.curriculumns;
        const curTitle = Object.keys(curriculumns);

        const currentCulTitle = this.state.CurrentSelectedTitle;
        const currentCul = this.state.CurrentSelectedCul;
        let selectedCuls = [];
        let selectedSubj = [];
        let selectedCulKey = '';


        for (let i = 0; i < curTitle.length; i++) {
            if (currentCulTitle === curTitle[i]) {
                selectedCuls = curriculumns[curTitle[i]];
            }
        }

        const selectedCulKeys = Object.keys(selectedCuls);
        for (let i = 0; i < selectedCulKeys.length; i++) {
            if (currentCul === selectedCulKeys[i]) {
                selectedCulKey = selectedCulKeys[i];
                selectedSubj = selectedCuls[selectedCulKeys[i]];
            }
        }

        const cOptions = [];
        const lis = [];
        for (let i = 0; i < selectedCulKeys.length; i++) {
            cOptions.push(
                <option key={`_key_${i}`}
                    data-cul-key={selectedCulKeys[i]}
                    value={selectedCulKeys[i]}>{selectedCulKeys[i]}</option>
            );
        }
        for (let i = 0; i < selectedSubj.length; i++) {
            lis.push(
                <li key={`key_${i}`}
                    data-cul-id={selectedSubj[i]['id']}
                    data-cul-key={selectedCulKey}
                    onClick={this.clickToSelectedSubj}
                >{selectedSubj[i]['content']}</li>
            );
        }

        return (
            <div className='tri-new-partB'>
                <div className='tri-new-partB-item'>
                    <div className="tri-new-header border-zero"><TrasCurriculumSubject /></div>
                    <div className='tri-new-curriculumns'>
                        <div className='tri-new-cur-header'>
                            <button id='xTEST' onClick={this.clickToSelectedTitle} data-cul-key={this.state.CUL_TITLE.TEST} ><TrasTest /></button>
                            <button id='xADMISSION' onClick={this.clickToSelectedTitle} data-cul-key={this.state.CUL_TITLE.ADMISSION} ><TrasAdmission /></button>
                            <button id='xENGLISH' onClick={this.clickToSelectedTitle} data-cul-key={this.state.CUL_TITLE.ENGLISH} ><TrasEnglish /></button>
                        </div>
                        <div className='tri-first-selector'>
                            <select id='xOption' onChange={this.clickToSelectedCul} value={currentCul}>
                                {cOptions}
                            </select>
                            <div className="tri-arrow">
                                <i className="fa fa-play color-gurume-gray"></i>
                            </div>
                            <div className='tri-second-selector'>
                                <ul id='triSecList'>
                                    {lis}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tri-new-partA-item'>
                    <div className="tri-new-header"><TrasTuitionMode /></div>
                    <div>
                        <label htmlFor="triOnline"> <input type="checkbox" value="1" id="triOnline" onChange={this.setTriOnline} /> <TrasOnline /></label>&nbsp;
                        <label htmlFor="triGraduate"> <input type="checkbox" value="2" id="triGraduate" onChange={this.setTriOnline} /> <TrasFacetoface /> </label>&nbsp;
                        <label htmlFor="triProfessional"> <input type="checkbox" value="3" id="triProfessional" onChange={this.setTriOnline} /> <TrasSNSMentoring /></label>&nbsp;
                    </div>
                </div>
                <div className='tri-new-partB-item'>
                    <div className="tri-new-header"><TrasSelectpreferencetutorgender /></div>
                    <div className="tri-gender-radio" onChange={this.setSexAndGender}>
                        <label className="radio-inline"><input id='rMan' type="radio" name='SexAndGender' value="1" /><TrasMale /></label>
                        <label className="radio-inline"><input id='rWoman' type="radio" name='SexAndGender' value="2" /><TrasFemale /></label>
                        <label className="radio-inline"><input id='rNoSelected' type="radio" name='SexAndGender' value="0" /><TrasNopref /></label>
                    </div>
                </div>
                <div className='tri-new-partB-item tri-new-partB-item2'>
                    <div className="tri-new-header border-zero"><TrasHourlyRate /></div>

                    <div className='tri-new-partB-slide'>
                        <Slider
                            rootStyle={sliderStyle}
                            domain={[30000, 100000]}
                            step={10000}
                            mode={1}
                            values={[Number(this.state.paramData.hourly_rate)]}
                            onChange={this.setHourlyRate}
                        >
                            <Rail>
                                {({ getRailProps }) => (
                                    <div style={railStyle} {...getRailProps()} />
                                )}
                            </Rail>
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <div className="slider-handles">
                                        {handles.map(handle => {
                                            return (<Handle
                                                key={handle.id}
                                                handle={handle}
                                                getHandleProps={getHandleProps}
                                            />);

                                        })}
                                    </div>
                                )}
                            </Handles>
                            <Tracks right={false}>
                                {({ tracks, getTrackProps }) => (
                                    <div className="slider-tracks">
                                        {tracks.map(({ id, source, target }) => (
                                            <Track
                                                key={id}
                                                source={source}
                                                target={target}
                                                getTrackProps={getTrackProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Tracks>
                            <Ticks values={[30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000]}>
                                {({ ticks }) => (
                                    <div className="slider-ticks">
                                        {ticks.map(tick => ( // get back ids, values and percents (to place them)
                                            <Tick key={tick.id} tick={tick} count={ticks.length} />
                                        ))}
                                    </div>
                                )}
                            </Ticks>
                        </Slider>
                    </div>
                </div>
                <div className='tri-new-partB-item'>
                    <div className="tri-new-header"><TrasTutorlevel /></div>
                    <div>
                        <label htmlFor="level-undergraduate"> <input type="checkbox" value="1" id="level-undergraduate" onChange={this.setUndergraduate} checked={this.state.paramData.isLevelUndergraduateChecked} />재학생</label>&nbsp;
                        <label htmlFor="level-graduate"> <input type="checkbox" value="1" id="level-graduate" onChange={this.setGraduate} checked={this.state.paramData.isLevelGraduate} />전문강사</label>&nbsp;
                        <label htmlFor="level-professional"> <input type="checkbox" value="1" id="level-professional" onChange={this.setProfessional} checked={this.state.paramData.isLevelProfessional} />그루미 강사진</label>&nbsp;
                    </div>
                </div>
            </div>
        )
    }


    setIsHiddenValue = (e) => {
        const value = e.target.value;
        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                is_hidden: value
            },
        })
    }


    setMsgForTeacher = (e) => {

        const value = e.target.value;

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                tuitionRequestMessage: value,
            }
        }, () => {
        })


    }

    createBody = () => {
        return (
            <div className='tri-new-wapper'>

                <div className='tri-new-info'>"작성자와 선생님만 보실 수 있는 글입니다. 원활하고 효율적인 수업 매칭을 위해 최대한 상세하게 적어주세요." </div>

                <div className='tri-new-partItems'>
                    {/* Student Name, Sex, TutorLevel */}
                    {this.createPartA()}
                </div>

                {
                    this.state.teacherId !== '' ?
                        <div className='tri-new-partC'>
                            <div className="tri-new-header border-zero">수업 신청 노출</div>
                            <div className='tri-new-partC-labels' onChange={this.setIsHiddenValue}>
                                <label className="radio-inline"><input type="radio" name='isHidden' value="1" />해당 선생님에게만 노출하기</label>
                                <label className="radio-inline"><input type="radio" name='isHidden' value="0" defaultChecked={true} />전체 회원 조회 가능</label>
                            </div>
                            <div className='tri-new-nuds'>
                                <div className="tri-new-header border-zero">수업신청 메시지</div>
                                <textarea onChange={this.setMsgForTeacher} placeholder="안녕하세요 선생님! 위와 같은 수업신청서를 확인 후 답변해 주시면 감사하겠습니다." ></textarea>
                            </div>
                        </div>
                        : ''
                }

                {this.createSubmBtns()}
            </div>
        )
    }

    setIsReadStudentTermsAndCondition = (e) => {
        const isChecked = e.target.checked;

        this.setState({
            ...this.state,
            paramData: {
                ...this.state.paramData,
                isReadStudentTermsAndCondition: isChecked
            }
        })
    }

    doValidation = () => {
        let valid = true;
        let msg = '';
        const paramData = this.state.paramData;


        if (this.state.userType !== 'student') {
            showNotify('학생 회원만 가능합니다.', STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }


        if (this.state.isAdmin) {
            const student_name = paramData.student_name;
            if (!student_name) {
                msg = '학생 이름을 적어주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return false;
            }
        }

        const gender = paramData.sex;
        if (!gender) {
            msg = '성별을 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }


        const isReadStudentTermsAndCondition = paramData.isReadStudentTermsAndCondition;
        const isLevelUndergraduateChecked = paramData.isLevelUndergraduateChecked;
        const isLevelGraduate = paramData.isLevelGraduate;
        if (!isReadStudentTermsAndCondition && !isLevelUndergraduateChecked && !isLevelGraduate) {
            msg = '학력/경력을 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }

        const teaching_styles = paramData.teaching_styles.join('$');
        if (!teaching_styles) {
            msg = '수업 스타일을 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }


        const location_id = paramData.location_id;
        const location_memo = paramData.location_memo;
        if (!location_id) {
            msg = '위치를 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }
        if (!location_memo) {
            msg = '자세한 위치를 적어주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }

        const mode = paramData.mode.join('$');
        if (!mode) {
            msg = '수업모드를 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }

        const details = paramData.details;
        if (!details) {
            msg = '수업목표를 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }

        const curriculums = paramData.curriculums.join('$');
        if (!curriculums) {
            msg = '커리큘럼을 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }

        const hours = paramData.hours;
        if (!hours) {
            msg = '수업 회수를 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }

        const start_time = paramData.start_time;
        if (!start_time) {
            msg = '시작 날짜를 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }

        const end_time = paramData.end_time;
        if (!end_time) {
            msg = '끝나는 날짜를 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return false;
        }

        // 날짜 유효값 추가
        const d_from = new Date(start_time).getTime();
        const d_to = new Date(end_time).getTime();
        if (d_from > d_to) {
            msg = '시작 날짜가 끝나는 날짜보다 늦을 수 없습니다. ( -유효하지 않은 날짜 선택- )';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }


        const teacherId = this.state.teacherId;
        const tuitionRequestMessage = this.state.paramData.tuitionRequestMessage;
        if (teacherId !== '') {
            if (!tuitionRequestMessage || tuitionRequestMessage.trim() === '') {
                msg = '선생님께 보내는 메시지를 입력해주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return false;
            }
            const isHidden = this.state.paramData.is_hidden;
            if (!isHidden) {
                msg = '수업 신청 노출하기를 선택해주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return false;
            }
        }


        return valid;
    }

    getAllCurriCulumns = () => {
        const curriculumnData = this.state.curriculumns;
        const allData = [];

        const curriculKeys = Object.keys(curriculumnData);
        for (let i = 0; i < curriculKeys.length; i++) {
            const eachKeys = Object.keys(curriculumnData[curriculKeys[i]]);
            for (let k = 0; k < eachKeys.length; k++) {
                const eachData = curriculumnData[curriculKeys[i]][eachKeys[k]];
                for (let j = 0; j < eachData.length; j++) {
                    const Major = curriculKeys[i];
                    const Subject = eachKeys[k];
                    const Title = eachData[j]['content'];
                    const keyInOrgin = eachData[j]['id'];

                    const SubjectObj = {
                        Major, Subject, Title, keyInOrgin
                    }
                    allData.push(SubjectObj);
                }

            }
        }
        return allData;
    }

    onSubmitHere = async () => {

        if (!this.state.isSendding) {
            this.setState({
                ...this.state,
                isSendding: true
            });
        }

        const valid = await this.doValidation();

        if (!valid) {
            this.setState({
                ...this.state,
                isSendding: false
            });
            return;
        }

        const paramData = this.state.paramData;

        const tutorLevels = [];
        if (paramData.isLevelUndergraduateChecked) {
            tutorLevels.push('undergraduate');
        }
        if (paramData.isLevelGraduate) {
            tutorLevels.push('graduate');
        }
        if (paramData.isLevelProfessional) {
            tutorLevels.push('professional');
        }


        const curriculums = paramData.curriculums.join('$');
        const mode = paramData.mode.join('$');
        const location_id = paramData.location_id;
        const location_memo = paramData.location_memo;
        const teaching_styles = paramData.teaching_styles.join('$');
        const details = paramData.details;
        const start_time = paramData.start_time;
        const end_time = paramData.end_time;
        const hours = paramData.hours;
        const hourly_rate = paramData.hourly_rate;
        const lang = this.state.lang;
        const is_hidden = this.state.paramData.is_hidden;
        const student_name = paramData.student_name;
        const gender = paramData.sex;
        const tutor_level = tutorLevels.join('_');
        const uploaded_by_admin = this.state.isAdmin ? '1' : '0';


        const postData = {
            location_id, location_memo, uploaded_by_admin,
            details, start_time, end_time, hours, lang, is_hidden, student_name,
            gender, hourly_rate
        };


        const allUrls = await GetAjaxURL();
        let tuitionRequestNew = allUrls.tuitionRequestNew;

        if (this.state.isEdit) {
            tuitionRequestNew = allUrls.tuitionReqEditWithRid + this.state.tuitionReqId;
        }


        const queryStr = queryString.stringify(postData);
        const urlWithThem = tuitionRequestNew + '?' + queryStr + '&curriculums=' + curriculums
            + '&mode=' + mode + '&teaching_styles=' + teaching_styles + '&tutor_level=' + tutor_level;

        const tuitionReqNewRes = await axios.post(urlWithThem, {}, postConfig).catch((err) => { return console.log(err); });

        if (!tuitionReqNewRes) return;
        if (!tuitionReqNewRes.data) return;
        if (!tuitionReqNewRes.data.status) return;

        const resData = tuitionReqNewRes.data;


        if (resData.status === 'success') {

            if (this.state.teacherId !== '') {
                const teacherId = this.state.teacherId;
                const tuitionRequestId = resData.id;
                const tuitionRequestMessage = this.state.paramData.tuitionRequestMessage;
                const allCulData = await this.getAllCurriCulumns();

                const selecCurriculumns = this.state.paramData.curriculums;
                const FindOrgFormatCuls = [];
                for (let i = 0; i < allCulData.length; i++) {
                    for (let k = 0; k < selecCurriculumns.length; k++) {
                        if (String(selecCurriculumns[k]) === String(allCulData[i]['keyInOrgin'])) {
                            FindOrgFormatCuls.push(allCulData[i]);
                        }
                    }
                }

                let strFormatSubj = '';
                for (let i = 0; i < FindOrgFormatCuls.length; i++) {
                    strFormatSubj += FindOrgFormatCuls[i]['Title'] + '/';
                }
                strFormatSubj = strFormatSubj.substr(0, strFormatSubj.length - 1);

                const rawContent = "$$TuitionRequest$$$" + tuitionRequestId + "$" + strFormatSubj + "$" + tuitionRequestMessage;

                const paObj = {
                    msgInfo: rawContent,
                    receiver: teacherId
                };

                const newMessage = queryString.stringify(paObj);


                const msgUrl = allUrls.message.send;
                const msgUrlWithPar = msgUrl + '?' + newMessage;
                const msgRes = await axios.post(msgUrlWithPar, {}, postConfig).catch((err) => { return console.log(err); });

                if (!msgRes) return;
                if (!msgRes.data) return;

                if (msgRes.data.status === 'success') {
                    let msg = '성공적으로 수업신청서를 생성하였으며 나의 메세지를 전송하였습니다. 선생님께서 답변이 없으시다면 그루미에 문의해 주시기 바랍니다.';
                    showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
                    // window.location.href = '/#/teachers/' + this.state.teacherId;
                    window.location.href = '/#/tuition_requests/' + tuitionReqNewRes.data.id;
                } else {
                    const subLang = this.state.lang;
                    let msg = '';
                    if (msgRes.data.status === 'The receiver is blocked.') {
                        msg = subLang === 'ko' ? '해당 유저는 차단되어 메세지 발송이 불가능합니다.' : subLang === 'en' ? 'The user is blocked.' : '用户被阻止。';
                    } else {
                        msg = subLang === 'ko' ? '메세지 발송에 실패하였습니다.' : subLang === 'en' ? 'Failed to send, please try again' : '发送失败，请重试';
                    }
                    showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                    window.location.href = '/#/tuition_requests';
                }
            } else {
                const toLang = this.state.lang;
                let sMsg = toLang === 'ko' ? '성공적으로 게시하였습니다.' : toLang === 'en' ? 'Successfully Posted.' : '发布成功';
                showNotify(sMsg, STATE_NOTIFY.TYPE_POSITIVE);
                window.location.href = '/#/tuition_requests/' + tuitionReqNewRes.data.id;
                //     $state.go('tuition_request_show', {'rid': response.data.id});
            }

        } else {
            let msg = '해당 페이지 수정 권한이 없습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            window.location.href = '/#/tuition_request/';
        }

        this.setState({
            ...this.state,
            isSendding: false
        });


    }

    onClickDoCondition = () => {
        const msg = '그루미 학생/학부모 이용약관에 동의해주세요.';
        showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
    }

    goBackHere = () => {
        window.location.href = '/#/tuition_requests';
    }

    createSubmBtns = () => {
        return (
            <div className='tri-new-subm-btns'>
                <div>
                    <input type="checkbox" onChange={this.setIsReadStudentTermsAndCondition} id="check_agreed" checked={this.state.paramData.isReadStudentTermsAndCondition} />
                    <a href="https://goo.gl/4hic7e" target="_blank">그루미 학생/학부모 이용약관</a>을 읽었으며 동의합니다.
                </div>
                <div className='tri-new-btns'>
                    {this.state.paramData.isReadStudentTermsAndCondition ?
                        this.state.isSendding ?
                            <span>
                                보내는 중 ...
                            </span>
                            :
                            <span onClick={this.onSubmitHere}>
                                <TrasSubmit />
                            </span>
                        :
                        <span onClick={this.onClickDoCondition}>
                            동의를 선택해주세요
                        </span>
                    }

                    <span onClick={this.goBackHere}>
                        <TrasBack />
                    </span>
                </div>
            </div>
        )
    }

    showAlert = () => {
        let showAndHideClass = 'alert-tuti-req hide-for-alert';
        return (
            <div id='tutiAlert' className={showAndHideClass}>
                <h2 className='tuti-req-red'> 학생 회원만 작성할 수 있습니다! </h2>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div>
                {this.createNav()}

                {this.showAlert()}
                {/* {
                this.state.userType !== 'student'?
                    this.showAlert()
                    : ''
                }
                 */}
                <div className='academy-wapper-c'>
                    {this.createBody()}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="default-wrapper tuition_request_new-pannel-size">
                {this.createHtml()}
            </div>
        )
    }

}

export default withRouter(Tuition_request_new);