// Controller For Meta
export const metaRegExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"\n0-9]/gi;

export function metaClass(_type, _title, _url, _description, _imageUrl, _keywords){
    this.type = _type;
    this.title = _title;
    this.url = _url;
    this.description = _description;
    this.imageUrl = _imageUrl;
    this.keywords = _keywords;
}

export const applyMetaHeader = (_metaClass) => {
    const metaClass = _metaClass;

    const metaKeys = Object.keys(metaClass);
    let validChecker = [];
    for(let i = 0; i < metaKeys.length; i++){
        if(metaClass[metaKeys[i]] !== '' && metaClass[metaKeys[i]] !== undefined){
            validChecker.push(true);
        }
    }

    if(validChecker.length < 4) return;

    if(metaClass.imageUrl === 'base' || metaClass.imageUrl === '' || metaClass.imageUrl === undefined){
        metaClass.imageUrl = '/apple-touch-icon.png';
    }
    if(metaClass.keywords === '' || metaClass.keywords === undefined || metaClass.keywords === 'base'){
        metaClass.keywords = 'IB, 에이레벨 A-Level, iGCSE, AP,수학(Maths),생물(Biology),화학(Chemistry),영어(English),만다린/중국어(Mandarin,Chinese),경영(Business),수업(Class),과외(Tuition),특강(Vacation workshop),영국 대입,대학 입시,옥스브리지,옥스퍼드,케임브리지,입시지도,컨설팅,멘토링,자기소개서,입시시험,인터뷰';
    }

    metaClass.title = metaClass.title + ' | 그루미 GuruMe';

    document.type = 'website';
    document.title = metaClass.title;

    const metas = document.getElementsByTagName('head')[0].getElementsByTagName('meta');
    for(let i = 0; i < metas.length; i++){
        if(metas[i]['name'] === 'url'){
            let url = metaClass.url;
            if(url.indexOf('/#') !== -1){
                url = url.replace('/#', '');
            }
            metas[i].content = url;
        }
        if(metas[i]['name'] === 'description'){
            let descript = metaClass.description;
            if(descript.length > 255){
                descript = descript.substr(0, 252) + ' ...';
            }
            metas[i].content = descript;
        }
        if(metas[i]['name'] === 'image'){
            let imageUrl = metaClass.imageUrl;
            metas[i].content = imageUrl;
        }
        if(metas[i]['name'] === 'keywords'){
            let keywords = metaClass.keywords;
            metas[i].content = keywords;
        }
        if(metas[i].getAttribute('property') === 'og:title'){
            let title = metaClass.title;
            metas[i].content = title;
        }
        if(metas[i].getAttribute('property') === 'og:url'){
            let url = metaClass.url;
            if(url.indexOf('/#') !== -1){
                url = url.replace('/#', '');
            }
            metas[i].content = url;
        }
        if(metas[i].getAttribute('property') === 'og:description'){
            let descript = metaClass.description;
            if(descript.length > 255){
                descript = descript.substr(0, 252) + ' ...';
            }
            metas[i].content = descript;
        }
        if(metas[i].getAttribute('property') === 'og:keywords'){
            let keywords = metaClass.keywords;
            metas[i].content = keywords;
        }
    }

}