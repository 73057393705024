import React, { Component } from 'react';
import {withRouter} from 'react-router';
import {
    TrasHome,
    TrasCredit,
    TrasBalanceHistory,
    TrasBalance,
    TrasQuestion,
    TrasTutor, TrasCurriculumSubject, TrasStatus, TrasTuitionFee, TrasDetails
    , TrasHourlyrate, TrasTuitionBill, TrasTuition,
    TrasContactdetails,
    TrasInpreparation,
    TrasTuitionhasbeencompleted,

    TrasDemo,
    TrasProgressReport,
    TrasInProgress,
    TrasConfirmingPayment,
    TrasConfirmendoftuition,
    TrasTuitionfeeawaitingtransfertotutor,
    TrasTuitionfeetransfercomplete,
    TrasStartingEndDate,
    Trassessionsweek,
    Trasothershavealreadyapplied,

} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import {clipMsgState, GetAjaxURL, getEnvironment, multipartForm, postConfig} from '../../../config/AjaxConfig';
import { isAdmin } from '../../extra/utils';

import './tuition_invoice.css';
import {INFI_STATE, InfinityController} from "../../extra/InfinityScroll";


class tuition_invoice extends Component {


    state = {

        currentTdModData: [],
        InfinityController: null,
        tTutionData: [],

        userIsAdmin: false,

        teacherList : [],
        currentTutor : 0,

        isAjaxDone : false,
        isAjax2Done : false,
        isLoading : false,

        needInvoice: false,
        needContact: false,
        needConfirmMsg: false,

        defaultValue: "none",
    }


    componentDidMount = () => {
        this.doInit();
    }

    doInit = async () => {
        const apiurls = await GetAjaxURL();
        const url = apiurls.apiThisUser;
        const res = await axios.get(url).catch((err) => { return err; });
        const data = res.data;


        if (data.status  == 'fail' || data.user.id != 14) {
            window.location.href = "/#";
        }


        const allUrls = await GetAjaxURL();
        const approveUrl = allUrls.apiTutors;
        const approveRes = await axios.get(approveUrl).catch((err) => { return console.log(err); });

        const approveData = approveRes.data;
        const teacherList = approveData;


        const query = new URLSearchParams(this.props.location.search);
        const rd = query.get('redirect');
        let defaultValue = "none";
        if (rd) {
            defaultValue = rd;
        }


        this.setState({
            ...this.state,
            teacherList : teacherList,
            isAjaxDone : true,
            isAjax2Done : false,
            userIsAdmin: true,
            defaultValue: defaultValue,
        });

        if (rd) {
            this.changerUsers({"target": {"value": rd} });
        }
    }


    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className='active'>Invoice</li>
                </ol>
            </div>
        )
    }

    scrollDectectFunc = () => {
        const isScrollDone = this.state.InfinityController.scrollDetect('infiWapper2', 'infiList2');
        if (isScrollDone) {
            const currentTdModData = this.state.InfinityController.getDataWithCount(INFI_STATE.REPEAT_COUNT);
            this.setState({
                ...this.state,
                currentTdModData: currentTdModData,
            });
        }
    }

    clickToBill = () => {
        const teacher = this.state.currentTutor;
        window.location.href = '/#/tuitions/adminbill/' + teacher.toString();
    }

    clickToOrderNum = () => {
        const currentOrderNum = this.state.currentOrderNum;
        if (!currentOrderNum) return;

        window.location.href = '/#/tuitions/copy/' + currentOrderNum;
    }

    clickToPointSelect = (e) => {

        const target = e.target;
        let ordernum = target.dataset['ordernum'];

        let currentNode = target;
        while (!ordernum) {
            currentNode = currentNode.parentNode;
            ordernum = currentNode.dataset['ordernum'];
        }

        for (let i = 0; i < currentNode.parentNode.childElementCount; i++) {
            currentNode.parentNode.children[i].classList.remove('active');
        }
        currentNode.classList.add('active');


        this.setState({
            ...this.state,
            currentOrderNum: ordernum
        })

    }

    createAddInvoice = () => {
        const tuitionDatas = this.state.tTutionData;

        const modData = [];
        for (let i = 0; i < tuitionDatas.length; i++) {
            if (String(tuitionDatas[i].status) === '1') continue;
            const student_name = tuitionDatas[i].student_name;
            const ordernum = tuitionDatas[i].ordernum;
            let curriculum = '';
            let subject = '';
            let curId = ''; //id
            let end_time = tuitionDatas[i].end_time;
            let start_time = tuitionDatas[i].start_time;
            for (let k = 0; k < tuitionDatas[i].curriculum_details.length; k++) {
                if (tuitionDatas[i].curriculum_details[k] !== null) {
                    curriculum = tuitionDatas[i].curriculum_details[k].curriculum;
                    subject = tuitionDatas[i].curriculum_details[k].subject;
                    curId = tuitionDatas[i].curriculum_details[k].id;
                }
            }

            if (ordernum) {
                modData.push({
                    student_name, ordernum, curriculum, subject, curId, end_time, start_time
                });
            }
        }

        const ul = [];
        const li = [];

        for (let i = 0; i < modData.length; i++) {
            li.push(
                <li key={`key_${i}`} className='teacher-dashboard-invoice-modal' data-ordernum={modData[i].ordernum} onClick={this.clickToPointSelect}>
                    <div>
                        <div>
                            <span>학생 : </span>
                            <span>{modData[i]['student_name']}</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>커리큘럼 / 과목 : </span>
                            <span>{modData[i]['curriculum']} {modData[i]['subject']}</span>
                        </div>
                    </div>
                    <div className='td-extra-info'>
                        <div>
                            <span>시작 / 끝 날짜 : </span>
                            <span><i>{modData[i]['start_time']}~{modData[i]['end_time']}</i></span>
                        </div>
                    </div>
                </li>
            )
        }

        return (
            <div className='tdashboard-td-invoice' id='td-tmp-data3' onClick={this.clickToPoint3}>
                <div className='tdash-normal-state'>
                    <div className='tdash-common-state'>
                        <div className='tdash-copy-btn'>
                            <button onClick={this.clickToOrderNum}>선택 수업 복사</button>
                        </div>
                        <h3>수업료 인보이스</h3>
                        <p>최근 사용된 인보이스를 다시 발행할 수 있습니다.</p>
                        <button className='tdash-new-btn' onClick={this.clickToBill}><i class="fa fa-plus-circle"></i>&nbsp;새로 추가</button>
                        <div className='tapply-msg-ul tdash-tapply-wapper' id='infiWapper2' onScroll={this.scrollDectectFunc}>
                            <ul id='infiList2'>
                                {li}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    changerUsers = async (e) => {
        const target = e.target;

        const value = target.value;

        const allUrls = await GetAjaxURL();
        const apiTuitions = allUrls.tuitionApi;
        const formData2 = new FormData();
        formData2.append('teacherId', value);
        const thisUserApi = await axios.post(apiTuitions, formData2).catch((err) => { return err });


        const tuitionData = thisUserApi.data.tuitions;

        let tdModData = [];
        let tempkey = [];
        for (let i = 0; i < tuitionData.length; i++) {
            if (!tempkey.includes(tuitionData[i].student_id)) {
                tdModData.push(tuitionData[i]);
                tempkey.push(tuitionData[i].student_id);
            }
        }

        const InfinityControllers = new InfinityController();
        InfinityControllers.setInit(tdModData);
        const currentTdModData = InfinityControllers.getDataWithCount(10);

        this.setState({
            ...this.state,
            currentTdModData: currentTdModData,
            InfinityController: InfinityControllers,
            tTutionData: tuitionData,
            currentTutor: value,
            isAjax2Done: true,
        })



    }

    createHeader = () => {

        const teacherList = this.state.teacherList;

        const options = [];
        for(let i = 0; i < teacherList.length; i++){
            options.push(
                <option key={'_key_' + i} value={teacherList[i].id}>{teacherList[i].id + ") " + teacherList[i].name}</option>
            );
        }


        return(
            <div className='credit-history-head'>
                <div className='credit-history-head-won'>

                    <div>
                        <div>
                            <span>
                                <div>회원 : </div>
                                <div>
                                    <select id="user_list" onChange={this.changerUsers} defaultValue={this.state.defaultValue}>
                                        <option value="none" disabled hidden>
                                            Select Teacher
                                        </option>
                                        {options}
                                    </select>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    showingInvoice = (_data) => {

        const gurumeUrlTutionConfirmUrl = clipMsgState.gurumeUrlTutionConfirmUrl;
        const invoiceLinkCopy = clipMsgState.invoiceLinkCopy;
        const data = gurumeUrlTutionConfirmUrl + _data + invoiceLinkCopy;

        this.setState({
            ...this.state,
            needInvoiceCopy: true,
            invoiceTextArea: data,
            invoiceToken: _data,
        })
    }

    showingDetailInfo = (_data) => {
        this.setState({
            ...this.state,
            needDetailInfo: true,
            invoiceData: _data
        })
    }


    showingnewData = () => {
        this.setState({
            ...this.state,
            needInvoice: true
        });    }


    clickToPoint3 = (e) => {
        const target = e.target;

        let isRightDom = false;
        if (target.id === 'td-tmp-data3' || target.parentNode.id === 'td-tmp-data3') {
            isRightDom = true;
        }
        if (isRightDom) {
            this.setState({
                ...this.state,
                needInvoice: false
            }, () => {
            })
        }
    }

    showingNewChildren = (_data) => {
        this.setState({
            ...this.state,
            needContact: true,
            tdContactData: _data
        });
    }

    clickConfirmMsg = (orderNum, tcStuName) => {
        const ConfirmMsgTextArea = 'www.gurume.kr/reviews/new/' + orderNum + ' 링크를 클릭하셔서 수업 완료 확인을 해주실 수 있을까요? 확인을 해주셔야 수업료 수급이 가능하여 메세지 드립니다^^ 이번 수업에 대한 Progress Report 또한 그루미 마이페이지에 로그인 하셔서 확인 하실 수 있습니다.'
            + '만약 링크를 여시는데 문제가 있으시면 그루미 고객센터 (카톡 gurume)에 직접 ' + tcStuName + ' 학생과 ' + this.state.stateParams.tdNameData + ' 선생님 수업 완료 확인”이라고 메세지 보내주셔도 됩니다^^';

        this.setState({
            ...this.state,
            needConfirmMsg: true,
            ConfirmMsgTextArea: ConfirmMsgTextArea,
        })
    }


    createTuitions = () => {
        return (
            <div className='td-common-wrapper'>
                <div className='tdClass-ext'>
                    <button onClick={this.showingnewData}>
                        <i className="fa fa-plus-circle"></i>
                        &nbsp; 수업료 인보이스
                    </button>
                </div>
            </div>
        )
    }

    createBody = () => {
        return (
            <div>
                {
                    this.state.needInvoice ?
                    this.createAddInvoice()
                    : ''
                }
                <div className='td-small-wrapper'>
                    {this.createTuitions()}
                </div>
            </div>
        )
    }

    createHtml = () => {
        return(
            <div className='academy-wapper-c credit-default-resize'>
                {
                    this.state.isAjaxDone ?
                        this.createHeader()
                        : ''
                }
                {
                    this.state.isAjax2Done ?
                        this.createBody()
                        : ''
                }
            </div>
        )
    }

    render(){
        return(
            <div className='credit-history-wapper'>
                {this.createNav()}
                {this.createHtml()}
            </div>
        )
    }

}

export default withRouter(tuition_invoice);