import React, { Component } from 'react';
import {withRouter} from 'react-router';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as RootBindingData from '../../../modules/RootBindingData';

import Pagination from "react-js-pagination";
import { GetAjaxURL } from '../../../config/AjaxConfig';
import {
    TrasHome,
    TrasBlog,
    TrasSearch,
    TrasAuthor,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';

import {metaClass, applyMetaHeader} from '../../extra/metaController';
import { getHashQuery } from '../../extra/utils';

//css
import './blogs.css'
class BlogIndex extends Component{
    state = {
        lang : 'ko',                                        // default
        cid: '',
        currentPage: 1,
        totalNumber:0,
        catalogueList: [],
        onLoading: false,
        articleList: [],
        chooseCatalogueArr: [],
        selectSubCatalogueId: [],
        isAjaxDone : false,
    }
    componentDidMount = async () => {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        const cid = getHashQuery().cid;
        const currentPage = getHashQuery().current_page || 1;
        await this.getData(currentPage, cid);
        await this.getCataloguesData(cid);

        this.setMetas();
    }

    setMetas = () => {

        const articleList = this.state.articleList;
        const catalogueList = this.state.catalogueList;
        const _type = '';
        const _title = '블로그 포럼';
        const _url = window.location.href;
        let _description = '';
        for(let i = 0; i < catalogueList.length; i++){
            _description += catalogueList[i]['catName'] + ' ';
        }

        const _imageUrl = 'base';
        let _keywords = '';
        for(let i = 0; i < articleList.length; i++){
            _keywords += articleList[i]['title_kr'] + ', ';
        }
        _keywords = _keywords.substr(0, _keywords.length-2);

        const newMetaClass = new metaClass(_type, _title, _url, _description, _imageUrl, _keywords);
        applyMetaHeader(newMetaClass);
    }


    toggleThisCatalogue = catalogue => {
        catalogue.isOpen = catalogue.isOpen !== true;
    }

    chooseCatalogue = id => {
        let {chooseCatalogueArr, selectSubCatalogueId} = this.state

        if (chooseCatalogueArr[id] === undefined || chooseCatalogueArr[id].isSelected === false) {
            chooseCatalogueArr[id] = {};
            chooseCatalogueArr[id].isSelected = true;
            selectSubCatalogueId.push(id)
        } else {
            var indexInArr = selectSubCatalogueId.indexOf(id);
            selectSubCatalogueId.splice(indexInArr, 1);
            chooseCatalogueArr[id].isSelected = false;
        }
        this.setState({chooseCatalogueArr, selectSubCatalogueId})
    }

    goToBlogShow = id => {
        window.location.href = `/#/blogs/${id}`
    }

    searchBlog = (num) => {
        let {selectSubCatalogueId} = this.state;
        window.location.href = `/#/blogs?cid=${selectSubCatalogueId.join('$')}&current_page=${num}`;
        window.location.reload()
    }


    getData = async (page, cid) => {
        this.setState({
            onLoading: true,
            currentPage: page
        })

        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.index + '?page=' + (page || 1) + (cid ? ('&catalogue=' + cid) : '');

        const res = await axios.get(url).catch((err) => {
            const {lang} = this.props.lang;
            alert(lang === 'cn' ? '未知错误－请刷新' : lang === 'en' ? 'Unknown Error - Please Refresh' : '알수없는 에러 - 새로고침 해주세요.', "danger");
            this.setState({
                onLoading: false
            })
        })

        const data = res.data;

        this.setState({
            onLoading: false,
            total: data.total,
            currentPage: data.current_page,
            totalNumber: data.total,
            articleList:data.data
        })
    }

    getCataloguesData = async (cid) => {
        const lang = this.props.lang;
        const apiurls = await GetAjaxURL();
        const url = apiurls.blogs.catalogues +'?lan=' + lang;
        const res = await axios.get(url).catch((err) => err)
        this.setState({
            catalogueList: res.data,
            isAjaxDone : true
        })
        if(cid){
            let that = this
            cid.split('$').forEach(element => {
                that.chooseCatalogue(element)
            });
        }
    }

    createBreadcrumb = () => {
        return(
            <div className="container">
                <ol className="breadcrumb mooc-index-breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><TrasBlog/></li>
                </ol>
            </div>
        )
    }
    createCatalogue = () => {
        const {catalogueList,chooseCatalogueArr} = this.state;

        return(
            <div className="card">
                {
                    catalogueList.map((catalogue, index) => 
                    <div className="row margin-bottom-5" key={'catalogue' + index}>
                        <div className="col-md-2">
                            <span onClick={() => this.toggleThisCatalogue(catalogue)}
                                    className="blog-index-catalogue-name clearfix">{catalogue.catName}
                            </span>
                        </div>
                        <div className="col-md-9">
                            {
                                
                                catalogue.detail.filter(i=>i).map((detail, index2) => {
                                    const isSelected = chooseCatalogueArr[detail.id]&&chooseCatalogueArr[detail.id].isSelected;
                                    return (
                                        <button key = {'detail' + index2}
                                        className={isSelected?'blog-catalogue-btn blog-catalogue-btn-selected':'blog-catalogue-btn'}
                                        onClick={() => this.chooseCatalogue(detail.id)}>
                                        {detail.detailName}
                                    </button>
                                    )
                                })
                            }
                            <hr className="catalogue-line"/>
                        </div>
                    </div>)
                }
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn blog-filter-btn" onClick={()=>this.searchBlog(1)} >
                            <i className="fa fa-search margin-right-5"></i>
                            <TrasSearch/>
                        </button>
                    </div>
                </div>
            </div>           
        )
    }
    createArticleList = () => {
        const {onLoading, articleList} = this.state
        const lang = this.props.lang;

        return(
            <div className="row items-container">
            {
                onLoading?
                <div className="mx-auto align-center margin-top-30">
                    <div className="loader"></div>
                </div> : 
                articleList.map((article,index) => {

                    let imgOrIframe = <img alt="img" src={article.image} onError={function(e){
                        e.target.src = 'https://via.placeholder.com/250x150?text=No+Image';
                        }.bind(this)} className="img-fluid" />;

                        if(article.youtubeEmbedUrls.length > 0){
                            imgOrIframe = <iframe className='blog-iframe' src={article.youtubeEmbedUrls} width={400} height={250}></iframe>
                        }
                   
                    return(
                        <div className="col-sm-12 col-md-4 col-lg-3 item margin-bottom-10" key = {'article'+index}>
                            <div className="card_thumbnail article-card" onClick={() => this.goToBlogShow(article.id)}>
                                <div className="position-relative">
                                    <div className="article-item-image">

                                        {/* <img alt="img" src={article.image} onError={function(e){
                                            e.target.src = 'https://via.placeholder.com/250x150?text=No+Image';
                                            }.bind(this)} className="img-fluid" /> */}
                                            {imgOrIframe}
                                            <div className='blog-over-cover'></div>
                                    </div>
                                    {/* {
                                        article.youtubeEmbedUrls.length ?
                                            <button className="ytp-large-play-button ytp-button" aria-label="Play">
                                                <svg>
                                                    <path className="ytp-large-play-button-bg"
                                                        d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                                        fill="#212121" fillOpacity="0.8"></path>
                                                    <path d="M 45,24 27,14 27,34" fill="#fff"
                                                    ></path>
                                                </svg>
                                            </button>
                                            :null
                                    } */}
                                </div>
                                <p className="blog-p-inline italic margin-top-10 margin-bottom-10 gray3-font-color">{article.created_at.split(' ')[0]}</p>
                                <div className="art art-area">
                                    <h3 className="tutor-major artItem art-area-item">
                                        {{
                                            en: article.title_en,
                                            cn: article.title_cn,
                                            ko: article.title_kr
                                        }[lang] || article.title_en || article.title_cn || article.title_kr}
                                    </h3>
                                    <p className="noMeaning art-area-info">
                                        {({
                                            en: article.intro_en,
                                            cn: article.intro_cn,
                                            ko: article.intro_kr
                                        }[lang] || article.intro_en || article.intro_cn || article.intro_kr)}...
                                    </p>
                                </div>
                                {
                                    article.user?
                                    <p className="margin-top-10">
                                        <TrasAuthor/> <b>{lang === 'en' ? article.user.first_name_en+article.user.last_name_en : article.user.last_name+article.user.first_name}</b>
                                    </p>:''
                                }
                                
                            </div>
                        </div>
                    )})
            }
        </div>

        )
    }

    createContentHtml = () => {
        let {currentPage,totalNumber} = this.state
        return(
            <div className="greybackground">
                <div className="container blogindex-container">
                    {
                        this.state.isAjaxDone ?
                            this.createCatalogue()
                        : ''
                    }
                    {this.createArticleList()}
                   <div className="clearfix margin-top-10"></div>
                    <div className="col-md-12 center">
                        <Pagination
                            activePage={currentPage}
                            itemsCountPerPage={12}
                            totalItemsCount={totalNumber}
                            pageRangeDisplayed={10}
                            onChange={(num)=>this.searchBlog(num)}
                            innerClass="pagination justify-content-center"
                            itemClass="page-item"
                            activeClass="active"
                            linkClass="page-link"
                            hideNavigation="true"
                        />
                    </div>
                </div>
            </div>
        )
    }

    render(){
        return(
            <div className="blogs-container">
                {this.createBreadcrumb()}
                {this.createContentHtml()}
            </div>
        )
    }
}


let mapStateToProps = (state) => {
    return {
        lang : state.RootBindingData.lang
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        RootBindingData : bindActionCreators(RootBindingData, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlogIndex));

