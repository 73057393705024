
export const getEnvironment = () => {
    const development = false;

    let http = '';

    if (development) {
        http = "http://localhost:8000";
    } else {
        http = "https://www.gurume.kr";
    }

    //http = 'http://13.124.149.13:8000';


    return http;
}


export const postConfig = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
    }
};
export const multipartForm = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const clipMsgState = {
    gurumeUrlTutionConfirmUrl: 'www.gurume.kr/tuitions/confirm/',
    invoiceLinkCopy: ' 수업료 인보이스 링크이며 1) \'수업 동의\' 버튼을 눌러주시고 2)신한은행 계좌로 입금해주시면 감사하겠습니다. 로그인에 어려움이 있으시면 그루미 고객센터로 연락부탁드립니다.'
};

export const copyToClipboard = (val) => {
    var textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    textarea.value = val;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
};



export const NoImgContens = 'https://via.placeholder.com/250x150?text=No+Image';



export const GetAjaxURL = () => {
    // development = true : development
    // development = false : production


    return new Promise((resolve) => {

        const http = getEnvironment();
        let dev_prefix = '';

        // if(http === 'http://localhost:8000')
        // dev_prefix = '/reactapi';

        resolve({

            unreadMessageCount: http + '/data/api/unreadMessageCount',

            blogsInfo: '/blogs/invitation',

            tuitionRequestsURL: http + '/data/tuition/request/index',
            tutorProfilesURL: http + dev_prefix + '/data/tutor_profile',
            profile: http + '/data/profile', // 'profile/teacherId'
            apiThisUser: http + '/data/api/thisUser',                                   // to check login a user
            apiThatUser: http + '/data/api/thatUser',
            apiGetTeacher: http + '/data/api/teacher_show',
            apiTeacherSearch: http + '/data/api/teacher_search',
            getLogin: http + '/data/login',
            LoginOut: http + '/data/logout',
            isLogin: http + '/data/api/isLogin',
            sendLang: http + '/data/api/lang',
            tuitionApi: http + '/data/api/get_tuitions',
            register: http + '/data/register',
            getFrontPath: http + '/react/getFrontPath',
            confirmToken: http + '/data/api/confirmToken',
            universities: http + '/data/api/universities',
            majors: http + '/data/api/majors',
            locations: http + '/data/api/locations', // 'api/locations?lan=' + lang
            countries: http + '/data/api/countries',
            curriculums: http + '/data/api/curriculums',
            apiTeacherDashboard: http + '/data/api/tdashboard',
            otToOted: http + '/data/api/ot',

            editUser: http + '/data/user/editUser',
            forgetPassword: http + '/data/user/forgetPassword',
            rememberToken: http + '/data/api/rememberToken',
            changePassword: http + '/data/user/changePassword',
            apiTutors: http + '/data/api/tutors',

            tuitionsNew: http + '/data/tuition/new',
            tuitionsCopy: http + '/data/tuition/copy',
            tuitionsEdit: http + '/data/tuition/edit',
            tutor_confirm: http + '/data/tuition/tutor_confirm/',

            tuitionConfirmLink: http + '/data/tuitions/confirm',
            tuitionConfrim: http + '/data/tuition/is_confirm_valid',


            tuition: http + '/data/tuition',
            tuitionDelete: http + '/data/tuition/delete/',

            tuitionMyRequest: http + '/data/tuition/request/my_request',
            tuitionRequestNew: http + '/data/tuition/request/new',
            tuitionReqWithRid: http + '/data/tuition/request/',
            tuitionReqEditWithRid: http + '/data/tuition/request/edit/',
            tuitionReqConfirm: http + '/data/tuition/request/confirm',
            tuitionReqChangeStatus: http + '/data/tuition/request/status2',
            tuitionReqDelete: http + '/data/tuition/request/delete/',
            tuitionReqApply: http + '/data/tuition/request/apply',


            bookTutor: http + '/data/tutor_profile/favorite',
            unbookTutor: http + '/data/tutor_profile/unfavorite',
            unbookmarkBlog: http + '/data/blog/unfavorite',
            tuitionIsReviewVaild: http + '/data/tuition/is_review_valid',
            tuitionReview: http + '/data/tuition/review',

            RRR_ReactEditor: http + '/react/RRR_ReactEditor',
            RRR_isManagerChecker: http + '/react/RRR_isManagerChecker',

            mooc: {
                index: http + '/data/mooc',
                delete: http + '/data/delete',
                new: http + '/data/mooc/new',
                edit: http + '/data/mooc/edit',
                subscript: http + '/data/mooc/subscribe',
                this_student: http + '/data/mooc/get_student_moocs',
                subjects: http + '/data/mooc/subjects',
            },
            gcoin: {
                index: http + '/data/gcoin',
                getTotalAmount: http + '/data/gcoin/getTotalAmount',
                withdraw: http + '/data/gcoin/withdraw',
                withdrawHistoryList: http + '/data/gcoin/withdrawHistory',
                setPreference: http + '/data/gcoin/setPreference',
                buy: http + '/data/gcoin/buy',
                approve: http + '/data/gcoin/approve',
                confirmBuy: http + '/data/gcoin/confirmBuy',
                confirmWithdraw: http + '/data/gcoin/confirmWithdraw',
                check_admin: http + '/data/gcoin/check_admin',
                withdraw_admin: http + '/data/gcoin/withdraw_admin',
                buy_admin: http + '/data/gcoin/buy_admin',
                history_admin: http + '/data/gcoin/history_admin',
            },
            message: {
                list: http + '/data/msg',
                send: http + '/data/msg/store',
                RRR_ReactMsgList: http + '/react/RRR_ReactMsgList',
                RRR_ReactMsgShow: http + '/react/RRR_ReactMsgShow'
            },
            blogs: {
                index: http + '/data/blog',
                catalogues: http + '/data/api/blogCatalogues',
                newcomment: http + '/data/blog/comment/new',
                editcomment: http + '/data/blog/comment/edit',
                deletecomment: http + '/data/blog/comment/delete',
                upload: http + '/data/blog/upload',
                show: http + '/data/blog/show',
                new: http + '/data/blog/new',
                edit: http + '/data/blog/edit',
            },
            groupclass: {
                list: http + '/data/group_class',
                new: http + '/data/group_class/new',
                edit: http + '/data/group_class/edit',
            }
        })
    })
}

export const GetAjaxURLFileUpload = () => {
    return new Promise((resolve) => {
        const http = getEnvironment();

        resolve({
            uploadPic: http + '/data/helper/s3/upload',
            uploadThumbnail: http + '/data/group_class/upload_thumbnail',
            uploadTextbook: http + '/data/group_class/upload_textbook',
            uploadClassroom: http + '/data/group_class/upload_classroom',
        })
    })
}



export const GetAuthURL = () => {

    return new Promise((resolve) => {

        // const http = getEnvironment();

        resolve({
            // isLogin : http + '/data/api/isLogin'
        })
    })

}