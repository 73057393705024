import React, { Component } from 'react';
import {
    TrasHome,
    TrasTuitionRequest,
    TrasTuitionRequestDashboard,
    TrasSubmitatuitionrequestformandcheckapplicationsfrompotentialtutorsintheinbox,
    TrasApplicationExample,
    TrasTuitionrequestform,
    TrasCurriculumSubject,
    TrasStartingEndDate,
    Trassessionsweek,
    Trasothershavealreadyapplied,
    TrasDetails,
    TrasMatched,
} from '../../../Translate/EachTranslateC';

import { withRouter } from 'react-router';

import ReactTooltip from 'react-tooltip'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMapMarkerAlt, faBook, faClock, faCommentAlt, faInfoCircle, faThumbtack
} from '@fortawesome/free-solid-svg-icons';
import kakaoIcon from '../../../images/kakao_icon_original.png'


import './tuition_request_index.css';
import axios from '../../../config/axios';
import { GetAjaxURL } from '../../../config/AjaxConfig';
import { showNotify, STATE_NOTIFY } from '../../extra/cusUtil';
import { isAdminEmail } from '../../extra/utils';
import jQuery from "jquery";

class tuition_request_index extends Component {

    state = {
        isAdmin: false,
        isLogin: false,
        isTutor: false,
        lang: 'kr',
        pageLocationUrl: '/tuition_requests',
        // tuitionRequestsURL : 'http://localhost:8000/data/tuition/request/index',
        DataTuitionRequest: [],
        currentPage: 1,    // DEFAULT 1
        totalCount: 0,
        PAGE_NATION_STEP: {
            STEP_A: 1,
            STEP_B: 2,
            STEP_C: 3
        },

        userInfo: {
            userId: '',
            userType: ''
        },
        isLoadingDone: false,
        isShowReqPannel: false,
    }



    componentDidMount = async () => {

        const Xstate = this.props.location.state;
        const paramStr = this.props.location.search;
        let curPage = 1;
        if (paramStr) {
            if (paramStr.indexOf('?current_page=') !== -1) {
                curPage = paramStr.substr('?current_page='.length, paramStr.length);
            }
        }

        this.setState({
            ...this.state,
            currentPage: Number(curPage)
        }, () => {
            this.getTuitionRequests();
        });

    }

    getTuitionRequests = async (e) => {

        this.setState({
            ...this.state,
            isLoadingDone: false
        });

        const allUrls = await GetAjaxURL();

        // login check
        const isLogined = allUrls.isLogin;
        const isLoginRes = await axios.get(isLogined).catch((err) => { return console.log(err) });
        const isLogin = isLoginRes.data.isLogin === 'true' ? true : false;

        let userId = '';
        let userType = '';
        let userEmail = '';
        let userInfo = {};

        if (!isLogin) {
            // return window.location.href = '/login';
        } else {
            // user Info
            const thisUser = allUrls.apiThisUser;
            const thisURes = await axios.get(thisUser).catch((err) => { return console.log(err) });
            const user = thisURes.data.user;
            const isTutor = thisURes.data.user.type === 'student' ? false : true;

            this.setState({
                isTutor: isTutor
            })
            userId = user.id;
            userType = user.type;
            userEmail = user.email;

            userInfo = {
                userId: userId,
                userType: userType,
            }
        }




        let target;
        let pageNum = this.state.currentPage;
        if (e) {
            target = e.target;
            pageNum = target.dataset.pageNum;   // data-page-num
        }

        const currentPage = pageNum || 1;
        const getData = {
            params: {
                page: currentPage
            }
        };

        const url = allUrls.tuitionRequestsURL;
        const response = await axios.get(url, getData).catch((err) => { return console.log(err) });

        if (!response) return (console.log("GET ERROR :: VTuitionRequestIndex"));

        const data = response.data.data;
        const totalCount = response.data.total;

        const isAdmin = isAdminEmail(userEmail);

        this.setState({
            ...this.state,
            isAdmin: isAdmin,
            isLogin: isLogin,
            DataTuitionRequest: data,
            totalCount,
            userInfo: userInfo,
            isLoadingDone: true,
        }, () => {
            // this.goToPageClick();
            const pagination = document.getElementById('pagination');
            for (let i = 0; i < pagination.childElementCount; i++) {
                const child = pagination.children[i];
                const childData = child.dataset['pageNumber'];
                if (String(childData) === String(this.state.currentPage)) {
                    child.classList.add('active');
                }
            }
        })

    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li className="active"><a href="/#/">수업신청</a></li>
                </ol>
            </div>
        )
    }




    calculatePagNation = (_step) => {

        // THIS IS THREE STEPS
        // 1 : this.calculatePagNationFirst()   : this.state.PAGE_NATION_STEP.STEP_A
        // 2 : this.calculatePagNation()        : this.state.PAGE_NATION_STEP.STEP_B
        // 3 : this.calculatePagNationLast()    : this.state.PAGE_NATION_STEP.STEP_C

        const STEP = _step;

        const totalCount = this.state.totalCount;
        const pageDataCount = 15;
        const totalBtnCnt = Math.ceil(totalCount / 15);

        let displayBtnCnt = 11;

        const returnLI = [];

        const standardNum = 6;
        const currentPageNum = this.state.currentPage;


        // CURRENT PAGE OVER THAN 6
        for (let i = 0; i < displayBtnCnt; i++) {
            let displayNumber = i + 1;
            let additionalNum = currentPageNum - standardNum;
            if (currentPageNum < 6) {
                additionalNum = 0;
            }
            let temp_li;

            if (STEP === this.state.PAGE_NATION_STEP.STEP_A) {
                if (i >= 8) {
                    if (i === 8) {
                        temp_li = <li className="no_drop" data-page-number={displayNumber} key={displayNumber}>{`...`}</li>;
                    }
                    else {
                        temp_li = <li data-page-number={totalBtnCnt - (displayBtnCnt - (i + 1))} onClick={this.goToPageClick} key={displayNumber}>{`${totalBtnCnt - (displayBtnCnt - (i + 1))}`}</li>;
                    }
                } else {
                    temp_li = <li data-page-number={displayNumber + additionalNum} onClick={this.goToPageClick} key={displayNumber}>{displayNumber + additionalNum}</li>;
                }
            } else if (STEP === this.state.PAGE_NATION_STEP.STEP_B) {
                if (i <= 2) {
                    // currentPage = over than 6
                    // will be ...
                    if (i === 2) {
                        temp_li = <li data-page-number={displayNumber} className="no_drop" key={displayNumber}>{`...`}</li>;
                    } else {
                        temp_li = <li data-page-number={displayNumber} onClick={this.goToPageClick} key={displayNumber}>{displayNumber}</li>;
                    }
                } else if (i >= 8) {
                    if (i === 8) {
                        temp_li = <li data-page-number={displayNumber} className="no_drop" key={displayNumber}>{`...`}</li>;
                    }
                    else {
                        temp_li = <li data-page-number={totalBtnCnt - (displayBtnCnt - (i + 1))} onClick={this.goToPageClick} key={displayNumber}>{`${totalBtnCnt - (displayBtnCnt - (i + 1))}`}</li>;
                    }
                } else {
                    temp_li = <li data-page-number={displayNumber + additionalNum} onClick={this.goToPageClick} key={displayNumber}>{displayNumber + additionalNum}</li>;
                }
            } else {
                if (i <= 2) {
                    if (i === 2) {
                        temp_li = <li data-page-number={displayNumber} className="no_drop" key={displayNumber}>{`...`}</li>;
                    } else {
                        temp_li = <li data-page-number={displayNumber} onClick={this.goToPageClick} key={displayNumber}>{displayNumber}</li>;
                    }
                } else {
                    temp_li = <li data-page-number={totalBtnCnt - (displayBtnCnt - (i + 1))} onClick={this.goToPageClick} key={displayNumber}>{`${totalBtnCnt - (displayBtnCnt - (i + 1))}`}</li>;
                }
            }

            returnLI.push(temp_li);
        }

        return returnLI;
    }

    createHtmlPagination = () => {

        const totalData = this.state.totalDataCount;
        const currentPage = this.state.currentPage;
        const totalBtnCnt = Math.ceil(this.state.totalCount / 15);

        let LI;
        if (this.state.currentPage <= 5) {
            LI = this.calculatePagNation(this.state.PAGE_NATION_STEP.STEP_A);
        } else if (this.state.currentPage >= (totalBtnCnt - 4)) {
            LI = this.calculatePagNation(this.state.PAGE_NATION_STEP.STEP_C);
        } else {
            LI = this.calculatePagNation(this.state.PAGE_NATION_STEP.STEP_B);
        }

        return (
            <div className="gurume-pagination">
                <ul id='pagination'>
                    {LI}
                </ul>
            </div>
        )
    }


    goToPageClick = (e) => {

        const target = e.target;
        const targetParent = target.parentNode;
        const pageNumber = target.dataset.pageNumber;
        for (let i = 0; i < targetParent.childElementCount; i++) {
            targetParent.children[i].classList.remove('active');
        }


        var currentHref = window.location.href;
        var newHref;
        if (currentHref.search("current_page") != -1) {
            newHref = currentHref.replace(/(current_page=).*?($)/,"current_page="+pageNumber);
        } else {
            if (currentHref.search("\\?") != -1) {
                newHref = currentHref + "current_page="+pageNumber;
            } else {
                newHref = currentHref + "?current_page="+pageNumber;
            }
        }

        if (window.history.replaceState){
            window.history.replaceState(this.state,"",newHref)
        }

        this.setState({
            ...this.state,
            currentPage: pageNumber
        }, () => {
            this.getTuitionRequests();

        })


    }

    createLists = () => {
        return (
            <div className='tui_req_i-card-wapper2'>
                <div className="tui_req_i-card-wapper2-box">
                    {this.createHtmlTuiRequestDetail()}
                    {this.createHtmlPagination()}
                </div>
            </div>
        )
    }



    goToApplyReq = () => {
        window.location.href = '/#/tuition_request/new';
    }

    showRecruitCard = () => {
        const $ = window.jQuery = jQuery;
        const recruitButton = $('#recruit-btn');
        const recruitCard = $('.tuition-req-recruit-card');

        recruitButton.off('click').on("click", function () {
            recruitCard.toggle('slow')
        })
    }

    goToRecruitLink = (e) => {
        if (this.state.isTutor || this.state.isAdmin) {
            const getId = e.target.id
            switch (getId) {
                case 'recruit-link-1':
                    window.open('https://goo.gl/Srdrbp')
                    break;
                case 'recruit-link-2':
                    window.open('https://www.rocketpunch.com/jobs/53662')
                    break;
                case 'recruit-link-3':
                    window.open('https://www.rocketpunch.com/jobs/50226')
                    break;
                case 'recruit-link-4':
                    window.open('https://www.rocketpunch.com/jobs/54937')
                    break;
                case 'recruit-link-5':
                    window.open('https://www.rocketpunch.com/jobs/63591')
                    break;
                case 'recruit-link-6':
                    window.open('https://www.rocketpunch.com/jobs/67889')
                    break;
                case 'recruit-link-7':
                    window.open('https://www.rocketpunch.com/jobs/55175')
                    break;
                case 'recruit-link-8':
                    window.open('https://www.rocketpunch.com/jobs/71867')
                    break;
            }
        } else {
            return false;
        }
    }

    createRecruitCard = () => {
        return (
            <div className="tuition-req-recruit-wrapper">
                <button className="tuition-req-common-btn visible-xs" onClick={this.showRecruitCard} id="recruit-btn">+ 그루미 채용</button>
                <div className="tuition-req-recruit-box">
                    <div className="tuition-req-recruit-card hidden-xs">
                        <div className="tuition-req-recruit-main">
                            <h2>그루미 채용</h2>
                            <p>그루미와 아래 분야에서 같이 일하실 인재를 찾습니다:</p>
                            <p>
                                *<a target="_blank" href="https://www.gurume.kr/about_gurume/career" className="gurume-grey-link">그루미 커리어</a><br />
                                *성공적인 리퍼럴을 해주시는 선생님께는 5만원의 소개비를 드립니다 (인턴/방학특강 TA 제외)
                            </p>
                            <p>
                                - <span onClick={this.goToRecruitLink} className="gurume-grey-link" id="recruit-link-1">IB/ A-Level/ AP/ SAT 풀타임 선생님</span><br />
                                - <span onClick={this.goToRecruitLink} className="gurume-grey-link" id="recruit-link-3">수업 상담, 영업, 관리</span><br />
                                - <span onClick={this.goToRecruitLink} className="gurume-grey-link" id="recruit-link-4">컨텐츠 기획/제작/관리(인턴)</span><br />
                                - <span onClick={this.goToRecruitLink} className="gurume-grey-link" id="recruit-link-5">마케팅</span><br />
                                - <span onClick={this.goToRecruitLink} className="gurume-grey-link" id="recruit-link-6">선생님 리크룻/트레이닝</span><br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    goToKakaoChannel = () => {
        window.open("https://pf.kakao.com/_ddvDV")
    }
    createTopPannel = () => {
        return (
            <div className='tui_req_i-card-wapper'>
                <ul className='tui_req_i-top'>
                    <h2>수업신청 게시판</h2>
                    <div className="tui-req-i-top-box">
                        <p>
                            <span>수업신청서를 작성하신 후 쪽지함에서 선생님 지원서를 확인하세요.</span>
                            {/* <span data-tip data-for='tuitReqEx' >
                                <span className='tui_req_i-btn-block'>
                                    지원서 예시
                            </span>

                                <ReactTooltip effect="solid" place='bottom' id='tuitReqEx' type='none'>
                                    <div className='tui_req_i-toolt-tuitReqEx'>
                                        <span>안녕하세요. 이번 9월부터 IB화학 SL선행 수업이 필요하시다고 하셔서, 지원하는 ooo선생님 입니다.</span>
                                        <span>간략하게 제가 도움을 드릴 수 있는 부분을 말씀드리자면... </span>
                                        <span>제 경우, 고교 과정에서 유용하게 쓰던 자습서와 문제지, 노트가 있어</span>
                                        <span>교육에 맞게 체계적인 단위를 정해 가르칠 예정이며 매 수업마다 첫 10~20분 동안</span>
                                        <span>이전 수업에 했던 내용을 시험을 보게 하고 복습하게 하는 스타일입니다.</span>
                                        <span>한 번 배울 때 제대로 배우고 그걸 자기 것으로 만들어 버리면 잊어버리는 게 더 어려워지거든요.</span>
                                        <span>쉽게 배운 것은 쉽게 잃어버리지만 공들여서 어렵게 배우면 잃어버리는 것도 어려운 것처럼 말입니다.</span>
                                    </div>
                                </ReactTooltip>
                            </span> */}
                        </p>
                    </div>
                    <button className="tuition-req-common-btn" onClick={this.goToApplyReq}><i className="fa fa-envelope"></i>&nbsp;수업신청서</button>
                    {/* <button className="tuition-req-common-btn tuition-req-kakao-btn" onClick={this.goToKakaoChannel}><span><img src={kakaoIcon} /></span>&nbsp;카카오톡</button> */}
                </ul>
            </div>
        )
    }

    createGreyBackGround = () => {
        return (

            <div className='tuition_req_index-backg'>
                <div className='tuition-req-index-wrapper'>
                    <div className="tuition-req-index-box">
                        {/* {this.createRecruitCard()} */}
                        <div className="tuition-req-list-wrapper">
                            <div className="tuition-req-list-box">
                                {this.createTopPannel()}
                                {
                                    this.state.isLoadingDone ?
                                        this.createLists()
                                        :
                                        this.createHtmlLoading()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createHtmlLoading = () => {
        return (
            <div className="tuition-request-loading">
                <div className="loader"></div>
            </div>
        )
    }


    getDisplayName = (_studentName) => {
        let returnName = '';
        const studentName = _studentName;

        let firstName = studentName.substr(0, studentName.length - (studentName.length - 1));
        let hideRemainName = '';
        let displayName = '';

        if (this.state.isAdmin) {
            // Here YESSSSSSSSSS ADMIN
            displayName = _studentName;

        } else {
            // Here NOOOOOOOOOOO ADMIN
            if (studentName.length > 0) {
                for (let k = 0; k < studentName.length - 1; k++) {
                    hideRemainName += '*';
                }
                displayName = firstName + hideRemainName;
            }
        }

        returnName = displayName;

        return returnName;
    }


    showDetailBTN = (e) => {
        let targetData = e.target.dataset['id'];
        if (!targetData) {
            targetData = e.target.parentNode.dataset['id'];
        }
        const isLogin = this.state.isLogin;
        const isAdmin = this.state.isAdmin;
        let studentID = 0;
        for (let i = 0; i < this.state.DataTuitionRequest.length; i++) {
            if (this.state.DataTuitionRequest[i].id == targetData){
                studentID = this.state.DataTuitionRequest[i].student_id;
                break;
            }
        }
        if (isAdmin) {
            window.location.href = '/#/tuition_requests/' + targetData;
            return;
        }

        // if (isLogin === true) {
        // Remove login verification
        if (1) {

            const userType = this.state.userInfo.userType;
            if (userType === 'student' && studentID == this.state.userInfo.userId) {
                window.location.href = '/#/tuition_requests/' + targetData;
                return;
            }
            if (userType === 'student' || ! isLogin) {
                this.setState({
                    ...this.state,
                    isShowReqPannel: true
                });
            } else {
                window.location.href = '/#/tuition_requests/' + targetData;
            }
        } else {
            let msg = '로그인 후 이용이 가능합니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
        }
    }
    showDetailBTN_NotValid = () => {
        let msg = '로그인 후 이용해주세요.';
        showNotify(msg, STATE_NOTIFY.TYPE_INFO);
    }

    clickDiscussionBTN = (e) => {
        // NO ACTION
    }


    createHtmlTuiRequestDetail = () => {

        // Tuition Request DATA REPEATATION

        const data = this.state.DataTuitionRequest;
        const div = [];

        if (!data) return;
        // THIS DATA MUST BE DIFFERENT FROM ADMIN AND NORMAL
        data.map((RequestDetailItem, i) => {

            const isAdmin = this.state.isAdmin;  //&& (isAdmin || isTutor)

            // displayShowName START
            // THIS TAKES AN ORDER
            let studentName = RequestDetailItem.student_name;

            let showNameToNotAdmin = this.state.lang === 'en' ?
                RequestDetailItem.student.last_name_en
                : RequestDetailItem.student.last_name + "**";
            let showNameToAdmin = this.state.lang === 'en' ?
                RequestDetailItem.student.last_name_en + RequestDetailItem.student.first_name_en
                : RequestDetailItem.student.last_name + RequestDetailItem.student.first_name;
            let displayShowName = '';

            if (studentName) {
                showNameToNotAdmin = this.getDisplayName(studentName);
                displayShowName = showNameToNotAdmin;
            }
            // displayShowName END

            // PREARE COMPONENTS
            let hiddenBTN = '';
            let uploadedByAdminBTN = '';
            let showMangerName = '';
            let locationInfoRegion = '';
            let locationInfoCity = '';
            let locationMemo = '';
            let curriculumsItems = [];
            let dateTime = '';
            let requestItemStatus = '';
            let detailBTN = '';
            let discussionBTN = '';
            let matchedDisplay = '';
            let pinIcon = '';

            // hiddenBTN && uploadedByAdminBTN START
            if (isAdmin) {
                if (RequestDetailItem.hidden === 1) {
                    // HIDE : 0, SHOW : 1
                    hiddenBTN =
                        <span className="label label-danger v_lavel font-size-10">숨김</span>;
                }
                if (RequestDetailItem.uploaded_by_admin !== 1) {
                    // HIDE : 0, SHOW : 1
                    uploadedByAdminBTN =
                        <span className="label label-info s_v_lavel font-size-10">학생 작성</span>;
                }
                if (RequestDetailItem.managers_name) {
                    showMangerName =
                        <span className='label tv_lavel font-size-10'>{RequestDetailItem.managers_name}</span>;
                }
            }
            // hiddenBTN && uploadedByAdminBTN END

            // locationInfoRegion && locationInfoCity && locationMemo START
            if (this.state.lang === 'kr') {
                locationInfoRegion = RequestDetailItem.location.region_kr;
                locationInfoCity = RequestDetailItem.location.city_kr;
            }
            if (this.state.lang === 'en') {
                locationInfoRegion = RequestDetailItem.location.region_en;
                locationInfoCity = RequestDetailItem.location.city_en;
            }
            if (this.state.lang === 'cn') {
                locationInfoRegion = RequestDetailItem.location.region_zh;
                locationInfoCity = RequestDetailItem.location.city_zh;
            }
            if (RequestDetailItem.location_memo.length > 0) {
                locationMemo = ' - ' + RequestDetailItem.location_memo;
            }

            // locationInfoRegion && locationInfoCity && locationMemo END

            // curriculumsItem START
            if (RequestDetailItem.curriculums.length !== 0) {
                for (let k = 0; k < RequestDetailItem.curriculums.length; k++) {
                    curriculumsItems.push(
                        <span key={`curriculums_key_${k}`}>
                            {`${RequestDetailItem.curriculums[k].curriculum} ${RequestDetailItem.curriculums[k].subject}`}&nbsp;
                        </span>
                    );
                }
            }
            // curriculumsItem END

            // dateTime START
            if (RequestDetailItem.end_time !== '2100-01-01' && RequestDetailItem.end_time !== '2100-1-1') {
                dateTime = <span>
                    {`${RequestDetailItem.start_time} ~ ${RequestDetailItem.end_time} `}
                    ({RequestDetailItem.hours}{"회/주"})
                        </span>;
            }

            if (RequestDetailItem.end_time === '2100-01-01' || RequestDetailItem.end_time === '2100-1-1') {
                dateTime = <span>
                    {RequestDetailItem.start_time} ~ 일정 조율 예정
                            ({RequestDetailItem.hours}{"회/주"})
                        </span>
            }
            // dateTime END

            // ANGULAR SOURCES ARE SUCKS!
            // requestItemStatus START
            if (RequestDetailItem.status >= 0) {
                requestItemStatus =
                    <i>(*{RequestDetailItem.status} {"명이 이미 지원하였습니다"})</i>;
            } else if (RequestDetailItem.status < 0) {
                requestItemStatus =
                    <i>(*{Math.abs(RequestDetailItem.status) - 1} {"명이 이미 지원하였습니다"})</i>;
            } else {
                //STATUS WILL BE NUM. IF NOT, WRONG.
            }
            // requestItemStatus END

            /* <button className="btn cancel-btn" ng-click="goDetail(RequestDetailItem.id)"
            ng-show="RequestDetailItem.status >=0 && RequestDetailItem.internal_status !== 3 && (isAdmin || isTutor)">
            {{'Details'|translate}}
            </button> */

            // let detailBTN = '';

            /* <button className="btn cancel-btn"
                    ng-click="confirmIsTutorAndRedirectToLogin(RequestDetailItem.id)"
                    ng-show="isNotLogined && 3 !== RequestDetailItem.internal_status"
                    data-toggle="tooltip"
                    data-placement="left" title="수업요청은 선생님으로 로그인 하셔야 확인하실 수 있습니다.">
                {{'Details' | translate}}
            </button> */


            const isLogined = this.state.isLogin;
            if (1) {
                // if(isAdmin){
                // 일반 선생님일 때도 접근할 수 있도록 수정
                if (RequestDetailItem.status >= 0
                    && RequestDetailItem.internal_status !== 3) {
                    // TrasDetails
                    detailBTN = <span className='tri-btns-type-a' data-id={RequestDetailItem.id} onClick={this.showDetailBTN}>
                        상세내용
                    </span>
                }
                // }
            } else {
                if (3 !== RequestDetailItem.internal_status) {
                    // DO ANOTHER CLICK FUNCTION TO SHOW A VALIDE PAANEL
                    detailBTN = <span className='tri-btns-type-a' data-id={RequestDetailItem.id} onClick={this.showDetailBTN_NotValid}>
                        상세내용
                    </span>
                }
            }

            // faComment, faCommentAlt
            // discussionBTN START
            if (RequestDetailItem.internal_status === 2) {
                discussionBTN = <span className='tri-btns-type-b' onClick={this.clickDiscussionBTN}>
                    <FontAwesomeIcon icon={faCommentAlt} />&nbsp;상의중
                </span>
            }

            // discussionBTN END


            if (RequestDetailItem.internal_status === 3) {
                matchedDisplay = <p className="red-border border-radius-4 padding-5 text-center">
                    매칭 완료
                </p>
            }


            if (RequestDetailItem.pin === 1) {
                pinIcon = <FontAwesomeIcon icon={faThumbtack} className='color-gurume-blue tuition-request-pin' />
            }

            div.push(
                <div className='tri-item-div' key={'wrapper_' + i}>
                    <ul>
                        <div className="tri-item-header">
                            <li>
                                <div className='tri-first-item'>
                                    <img className="img-circle dash-img-size" src='../images/backuptutorpic.7684e35a.png' alt='backuptutorpic.png' />
                                    <span className='tri-first-style'>
                                        <span>
                                            <span>{displayShowName}</span>
                                        &nbsp; {RequestDetailItem.created_at.split(" ")[0]}
                                        </span>
                                        {hiddenBTN}
                                        {uploadedByAdminBTN}
                                        {showMangerName}
                                    </span>
                                </div>
                            </li>
                            {pinIcon}
                        </div>
                        <li>
                            <div className='tri-second-item'>
                                <span>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />&nbsp;{locationInfoRegion}&nbsp;
                                </span>
                                <span>
                                    {locationInfoCity}
                                </span>
                                <span>{locationMemo}</span>
                            </div>
                            <div className='tri-second-item'>
                                <span>
                                    커리큘럼/과목&nbsp;:&nbsp;
                                </span>
                                <span>
                                    {curriculumsItems.map((item) => {
                                        return item;
                                    })}
                                </span>
                            </div>
                            <div className='tri-second-item'>
                                <span>
                                    시작/끝 날짜&nbsp;:&nbsp;
                                </span>
                                <span> {dateTime} </span>
                            </div>

                        </li>
                        <li>
                            <div className='tri-second-item'>
                                <span className='try-sec-item-fix'>
                                    {requestItemStatus}
                                </span>
                            </div>
                            <div className="tri-bot-btns">
                                {detailBTN}
                                {discussionBTN}
                                {matchedDisplay}
                            </div>
                        </li>
                    </ul>
                </div>
            )
        })
        return div;
    }


    hideReqPannel = (e) => {
        const target = e.target;

        if (target.id !== 'triReqPannel') return;

        this.setState({
            ...this.state,
            isShowReqPannel: false
        }, () => {
        })

    }

    hideReqPannel2 = (e) => {
        this.setState({
            ...this.state,
            isShowReqPannel: false
        })
    }

    goToLoginPage = () => {
        window.location.href = '/#/login';
    }

    createHtmlModal = () => {
        return (
            <div id='triReqPannel' className='tri-call-request-checker' onClick={this.hideReqPannel}>
                <div className='tri-call-req-paper'>
                    <div>
                        <span><FontAwesomeIcon icon={faInfoCircle} /></span>
                        <span>수업요청서는 선생님만 조회할 수 있습니다. 선생님으로 로그인 하시겠습니까?</span>
                    </div>
                    <div>
                        <div>
                            <span onClick={this.goToLoginPage}>선생님으로 로그인</span>
                            <span onClick={this.hideReqPannel2}>취소</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createBody = () => {
        return (
            <div>
                {this.createGreyBackGround()}

                {this.state.isShowReqPannel ?
                    this.createHtmlModal()
                    : ''}

            </div>
        )
    }

    createHtml = () => {
        return (
            <div>
                {this.createNav()}
                {this.createBody()}
            </div>
        )
    }

    render() {
        return (
            <div className="default-wrapper">
                {this.createHtml()}
            </div>
        )
    }

}


export default withRouter(tuition_request_index);