import React, { Component } from 'react';
import {withRouter} from 'react-router';
import {
    TrasHome,
    TrasCredit,
    TrasBuy,
    TrasBuyCredit,
    TrasPaymentMethod,
    TrasBankDetails,
    TrasProcesspayment,
    TrasPleaseprocessthebanktransferasstatedusingthestudentsname,
    TrasConfirm,


} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { showNotify, STATE_NOTIFY, moneyFormat } from '../../extra/cusUtil';

import './credit_buy.css';
import './credit_common.css';

class credit_buy extends Component{


    state = {

        userType : '',

        onConfirmingCreditBuy : false,

        paramState : {
            preferred_currency : '',
            pay_method : '1',
            gcoin : '',
        },

        extraInfo : {
            myCoins : '',
        },

        isSubmit : false,
        isShowPannel : false,
    }


    componentDidMount = () => {
        this.doInit();
    }

    doInit = async () => {

        const allUrls = await GetAjaxURL();

        // Login Check
        const isLogin = allUrls.isLogin;
        const isLoginRes = await axios.get(isLogin).catch((err) => { return console.log(err); });
        const isLoginData = isLoginRes.data;

        if(isLoginData.isLogin === 'false'){
            let msg = '로그인 후 이용해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            return;
        }

        const gcoinUrl = allUrls.gcoin.index;
        const gCoinRes = await axios.get(gcoinUrl).catch((err) => { return console.log(err); });


        if(!gCoinRes){
            let msg = '서버에서 에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const userType = isLoginData.type;

        const gCoinData = gCoinRes.data;
        const preferred_currency = gCoinData.preferred_currency;
        const extraInfo = gCoinData.total_gcoin;

        this.setState({
            ...this.state,
            userType : userType,
            paramState : {
                ...this.state.paramState,
                preferred_currency : preferred_currency,
            },
            extraInfo : {
                ...this.state.extraInfo,
                myCoins : Number(extraInfo)
            }
        })


    }

    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/credit/balance"><TrasCredit /></a></li>
                    <li className='active'><TrasBuyCredit /></li>
                </ol>
            </div>
        )
    }


    setGCoins = (e) => {
        const target = e.target;
        const value = Number(target.value);
        let gcoin = value;
        if(isNaN(value)) gcoin = 0;

        this.setState({
            ...this.state,
            paramState : {
                ...this.state.paramState,
                gcoin : gcoin
            }
        }, () => { });

    }



    showProcessPayment = () => {

        this.setState({
            ...this.state,
            isShowPannel : true,
        })
    }
    hideProcessPayment = () => {

        this.setState({
            ...this.state,
            isShowPannel : false,
        })
    }

    

    doConfirm = async () => {

        this.setState({
            ...this.state,
            isSubmit : true,
        });
        
        const allUrls = await GetAjaxURL();


        // Login Check
        const isLogin = allUrls.isLogin;
        const isLoginRes = await axios.get(isLogin).catch((err) => { return console.log(err); });
        const isLoginData = isLoginRes.data;

        if(isLoginData.type !== 'student'){
            let msg = '학생 회원이 아닙니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            setTimeout(() => {
                let msg = '학생 회원만 구매가 가능합니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_INFO);
            }, 2000);

            this.setState({
                ...this.state,
                isSubmit : false,
            });
            return;
        }

        const coinBuyUrl = allUrls.gcoin.buy;

        const postData = {
            currency : this.state.paramState.preferred_currency,
            money : this.state.paramState.gcoin,
            pay_method : this.state.paramState.pay_method,
            gcoin : this.state.paramState.gcoin,
        };

        if(!postData.gcoin || postData.gcoin === 0){
            this.setState({
                ...this.state,
                isSubmit : false,
                isShowPannel : false,
            }, () => {
                let msg = '금액을 입력해주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            })
            return;
        }

        const formData = new FormData();
        formData.append('currency', postData.currency);
        formData.append('money', postData.money);
        formData.append('pay_method', postData.pay_method);
        formData.append('gcoin', postData.gcoin);

        const coinBuyRes = await axios.post(coinBuyUrl, formData, postConfig).catch((err) => { return console.log(err); });


        if(coinBuyRes.data.status === 'success'){
            let msg = '성공적으로 결제 요청을 보냈습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);

            setTimeout(() => {
                let msg = '입금 관련된 문의 사항은 그루미 고객센터를 통해 문의해주세요.';
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            }, 2000);
            window.location.href = '/#/credit/balance';

        }else{
            let msg = '결제 요청에 실패했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            setTimeout(() => {
                let msg = '학생 회원만 구매가 가능합니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_INFO);
            }, 2000);
        }
        this.setState({
            ...this.state,
            isSubmit : false,
        });

    }

    createBody = () => {
        return(
            <div>
                <div className='credit-buy-content-wapper'>
                    <h4 className='color-gurume-blue'> <TrasBuy /> 금액 ( { moneyFormat(this.state.paramState.gcoin) } 원) </h4>
                    <div>
                        <input className='credit-buy-input' onChange={this.setGCoins} type='text' name='' value={this.state.paramState.gcoin} /> KRW
                    </div>
                </div>

                <div className='credit-buy-content-wapper'>
                    <h4 className='color-gurume-blue'> <TrasPaymentMethod /> </h4>
                    <div className='credit-buy-content-radio'>
                        <label>
                            <input type='radio' name='bank' value='1' defaultChecked readOnly /> &nbsp; 계좌이체 - Processing Fee 0%
                        </label>
                    </div>
                </div>

                <div className='credit-buy-content-wapper'>
                    <h4 className='color-gurume-blue'> <TrasBankDetails /> </h4>
                    <div className='credit-buy-content-ul'>
                        <ul>
                            <li><span>Korea</span><span>신한은행 110-338-435020 이혁진</span></li>
                            <li><span>UK</span><span>Mr Jian E Wong, 30-96-35, 62130560</span></li>
                            <li><span>China</span><span>工商银行北京海淀支行 北京谷路米教育咨询有限公司 0200049609201119513</span></li>
                        </ul>
                    </div>
                </div>

                <div className='credit-buy-content-wapper'>
                    <div className='credit-buy-divider'>
                        <div>
                            <span>결제방식 : Direct Bank Transfer</span>
                            <span>Amount : {moneyFormat(this.state.paramState.gcoin)} KRW</span>
                            <span>Processing Fee : 0 KRW</span>
                        </div>
                        <div>
                            <span>Total funds : {moneyFormat(this.state.paramState.gcoin)} KRW</span>
                            <button onClick={this.showProcessPayment}>크레딧 구매</button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    createHtml = () => {
        return(
            <div className='credit-buy-wapper academy-wapper-c'>
                <h3 className='color-gurume-blue'> <TrasBuyCredit /> </h3>
                {this.createBody()}
            </div>
        )
    }


    createWapper = () => {

        let gcoin = this.state.paramState.gcoin;
        let myCoins = this.state.extraInfo.myCoins;
        let totalCoinsAfterPayment = '';
        let won = ' 원';
        let coins = ' coins';
        if(!gcoin || gcoin === 0){
            gcoin = '결제 금액이 입력되지 않았습니다.';
            won = '';
            coins = '';
            totalCoinsAfterPayment = myCoins;
        }

        if(!isNaN(gcoin) && gcoin !== 0){
            totalCoinsAfterPayment = Number(myCoins) + Number(gcoin);
            gcoin = moneyFormat(gcoin);
        }
        if(!isNaN(totalCoinsAfterPayment)){
            totalCoinsAfterPayment = moneyFormat(totalCoinsAfterPayment);
        }

        return(
            <div className='credit-buy-pannel'>
                <div className='credit-buy-pannel-card'>
                    <h5> <TrasProcesspayment /></h5> 
                    <div className='credit-buy-pannel-content'>
                        <div className='credit-buy-info'>
                            <ul>
                                <li><span>통화</span><span>원화(KRW)</span></li>
                                <li><span>결제 금액</span><span>{gcoin} {won}</span></li>
                                <li><span>결제 방법</span><span>계좌이체</span></li>
                                <li><span>코인</span><span>{gcoin} {coins}</span></li>
                                <li><span>결제 후 나의 코인</span><span className='color-gurume-red'>{totalCoinsAfterPayment} {' coins'}</span></li>
                            </ul>
                        </div>

                        <div><TrasPleaseprocessthebanktransferasstatedusingthestudentsname /></div>
                        <div>
                            <button className='credit-default-btn credit-default-blue-btn' onClick={this.hideProcessPayment}>닫기</button> 

                            {
                                this.state.userType !== 'tutor' ?
                                    this.state.isSubmit ? 
                                    <button className='credit-default-btn credit-default-blue-btn'>확인 중...</button>
                                    :
                                    <button className='credit-default-btn credit-default-blue-btn' onClick={this.doConfirm}><TrasConfirm /></button>
                                : 
                                    <button className='credit-default-btn credit-default-red-btn' onClick={this.doConfirm}>학생 회원이 아닙니다.</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render(){
        return(
            <div>
                {this.createNav()}
                {this.createHtml()}

                {
                    this.state.isShowPannel ?
                        this.createWapper()
                    : ''
                }

            </div>
        )
    }

}

export default withRouter(credit_buy);