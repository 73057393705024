import React, {Component} from 'react';
import {withRouter} from 'react-router';
import queryString from 'query-string';

import {
    TrasHome,
    TrasUser,
    TrasAccountActivation,
    TrasThanksforregisteringwithGuruMe,
    TrasPleasefollowtheemailinstructiontocompleteyourregistration,
    TrasIfyouhaventyetreceivedtheaccountactivationemailafter10minutes,
    TrasCheckthattheemailaddressyouhavewritteniscorrect,
    TrasCheckthespambox,
    TrasIftheproblemstillcontinuescontactGuruMesupportteam
} from '../../../Translate/EachTranslateC';

import './accountactivation.css';
import './common.css';
import '../../../default.css';

import { cusUtilStart } from '../../extra/cusUtil';

// Please follow the email instruction to complete your registration.
// /account_setting
class accountactivation extends Component {


    state = {
        lang : 'ko',
        email : 'xxxxx@xx.com',
        wrapperSize : ''
    }


    componentDidMount = () => {
        this.setParams();
        cusUtilStart(this);
    }
    calScreenSizeAndResizeWrapper = (wrapperSize) => {
        this.setState({
            wrapperSize
        })
    }

    createNav = () => {
        return(
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/"><TrasUser /></a></li>
                    <li><a href="/#/"><TrasAccountActivation /></a></li>
                </ol>
            </div>
        )
    }

    createBody = () => {
        return(
            <div className="common-body-wrapper" style={{height : this.state.wrapperSize+'px'}}>
                <div className="common-body-inner-wrap common-body-padding">
                    <h3 className='common-body-margin-bot' style={{color:'#5b9aef'}}><TrasAccountActivation /></h3>
                    <hr style={{border:'0.5px solid #5b9aef'}}></hr>
                    <p className='common-body-margin-top common-body-margin-bot'><TrasThanksforregisteringwithGuruMe />
                    </p>
                    <p className='common-body-margin-bot'><b style={{fontSize:'20px', color:'#5b9aef'}}>[  {this.state.email}  ]</b> <TrasPleasefollowtheemailinstructiontocompleteyourregistration /></p>

                    <p><TrasIfyouhaventyetreceivedtheaccountactivationemailafter10minutes /></p> 
                    
                    <ul>
                        <li className='acc-act-underline common-body-red-color'><TrasCheckthattheemailaddressyouhavewritteniscorrect /></li> 
                        <li className='acc-act-underline common-body-red-color'><TrasCheckthespambox /></li> 
                    </ul>

                    <p><TrasIftheproblemstillcontinuescontactGuruMesupportteam /></p>
                    <div className='active_anim'>
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    setParams = () => {
        const param = queryString.parse(this.props.location.search);
  
        if(param){
            this.setState({
                ...this.state,
                ...param
            })
        }
    }


    createHtml = () => {
        return(
            <div>
                {this.createNav()}
                <div className='academy-wapper-c'>
                    {this.createBody()}
                </div>
            </div>
        )
    }

    render(){
        return(
            <div className='acc-act-wrapper default-wrapper'>
                {this.createHtml()}
            </div>
        )
    }
}

export default withRouter(accountactivation);