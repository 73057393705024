import React, { Component } from 'react';

import './Header.css';
import GurumeLogo from '../../images/logo_ko.jpg';

export default class MobileHeader extends Component {
    render() {
        return (
            <nav class="navbar navbar-default visible-xs">
                <div class="container navbar-toggle-container">
                    <div class="navbar-header">
                        <button type="button" class="navbar-toggle nav-toggle-btn collapsed" data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span><i class="fa fa-bars"></i></span>
                        </button>
                        <a class='navbar-brand' href="/#"> <img src={GurumeLogo} style={{width : '150px'}} /></a>
                    </div>

                    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav navbar-right">
                            {/* <li class="nav-li">
                                <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">그루미 소개</a>
                                <ul class="dropdown-menu">
                                    <li><a href='/#/about_gurume'>회사 소개</a></li>
                                    <li><a href='/#/studyroom'>스터디룸</a></li>
                                    <li><a href='/#/contact_us'>연락처</a></li>
                                </ul>
                            </li> */}
                            <li class="nav-li">
                                <a href="/#/mooc">인강</a>
                            </li>
                            <li class="nav-li">
                                <a href="/#/blogs">블로그</a>
                            </li>
                            {/* <li class="nav-li">
                                <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">입시</a>
                                <ul class="dropdown-menu">
                                    <li><a href="/#/admissions_consulting">입시 컨설팅</a></li>
                                    <li><a href="http://www.peopleloving.co.kr/gcse/recommendationa.html" target="blank">영국
                                        보딩</a></li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}