import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasClass,
    TrasTuitionBill,
    TrasDemoClass,
    TrasTeacher,
    TrasCurriculumSubject,
    TrasStartingEndDate,
    TrasTotalHours,
    TrasHourlyRate,
    TrasTuitionFee,
    TrasPaymentMethod,
    TrasBankTransfer,
    TrasCredit,

    TrasAgree,
    TrasClick,
    TrasSubmitting,
    TrasAlreadyagreed,
    TrasBack,

} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import queryString from 'query-string';
import { GetAjaxURL, postConfig, getEnvironment } from '../../../config/AjaxConfig';
import { moneyFormat, showNotify, STATE_NOTIFY } from '../../extra/cusUtil';

import '../../../default.css';
import './tuitions_confirm.css';

class Tuitions_confirm extends Component {

    state = {
        lang: 'ko',
        tuitionData: {
            teacherFullName: '우리 ',
            studentFullName: ' 학생',
            selectedCuls: [
            ],
            tuitionDate: '2020-1-10 ～ 2020-1-11',
            startTime: 'start_time',
            daysToStart: '1',
            hourlyRate: '1000',
            totalAmount: '1000',
            totalHours: '2',
            KoreaClassFee: '10000',
            MyCredit: '10000',
            postCurrency: '2',                                  // KRW
            isDemo: false,
            isAcademy: false,
        },

        isCreditCheck: false,
        isAbleToConfirm: false,

        currentPaymentCountry: 'KRW',
        paymentOptions: {
            KOREA: {
                value: 'KRW',
                text: '신한은행 110-338-435020 이혁진(그루미)'
            },
            UK: {
                value: 'GBP',
                text: 'Mr Jian E Wong, 30-96-35, 62130560, Lloyds'
            },
            CHINA: {
                value: 'RMB',
                text: 'Alipay 13146216866 (Verification name = HYOKJIN)'
            },
            ACADEMY: {
                value: 'KRW',
                text: '국민 754201 04 130955 이혁진 (그루미 교육학원)'
            }
        },

        isSubmit: false,
    }


    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            window.location.reload();
        }
        return true;
    }

    componentDidMount = () => {
        //initFunc
                // const orderNum = this.props.match.params.ordernum;
        // if (!orderNum) return console.log("Wrong Url");

        // const allUrls = await GetAjaxURL();
        // const isLoginUrl = allUrls.isLogin;
        // const isLoginRes = await axios.get(isLoginUrl).catch((err) => { return console.log(err) });

        // if (!isLoginRes) return;
        // if (!isLoginRes.data) return;

        // const loginData = isLoginRes.data;
        // const isLogin = loginData.isLogin === 'true' ? true : false;
        // const loginType = loginData.type === 'tutor' ? 'tutor' : 'student';

        // if (isLogin) {
        //     const isValid = await this.doTuitionConfirmProcess(orderNum, allUrls);
        //     const setData = await this.getTuitionInfomation(orderNum, allUrls);
        //     this.setState({
        //         ...this.state,
        //         tuitionData: setData,
        //         isAbleToConfirm: isValid
        //     }, () => {
        //         const bank = document.getElementById('bank');
        //         bank.click();
        //         this.props.RootBindingData.doLoading(false);
        //     });
        // } else {
        //     const isValid = await this.doTuitionConfirmProcess(orderNum, allUrls);
        //     const setData = await this.getTuitionInfomationChecker(orderNum, allUrls);
        //     this.setState({
        //         ...this.state,
        //         tuitionData: setData,
        //         isAbleToConfirm: isValid
        //     }, () => {
        //         const bank = document.getElementById('bank');
        //         bank.click();
        //         this.props.RootBindingData.doLoading(false);
        //     });
        //     // return window.location.href = '/login';
        // }
    }

    initFunc = async () => {
        const orderNum = this.props.match.params.ordernum;
        if (!orderNum) return console.log("Wrong Url");

        const allUrls = await GetAjaxURL();
        const tuitionUrl = allUrls.tuition + '/' + orderNum;
        const tuitionRes = await axios.get(tuitionUrl).catch((err) => { return console.log(err) });
        const tuitionData = tuitionRes.data;

        if (tuitionData.status === 'fail') {
            return null;
        }
        const tuitionId = tuitionData.id
        window.location.href="https://tutorhive.kr/invoice/" + tuitionId
    }

    getTuitionInfomationChecker = async (_orderNum, allUrls) => {
        const orderNum = _orderNum;
        const tuitionUrl = allUrls.tuition + '/' + orderNum;
        const tuitionRes = await axios.get(tuitionUrl).catch((err) => { return console.log(err) });

        const tuitionData = tuitionRes.data;

        if (tuitionData.status === 'fail') {
            return null;
        }

        const teacherFullName = tuitionData.tutor_info.teacher_full_name;
        const studentFullName = tuitionData.student_name;
        const selectedCuls = tuitionData.curriculums;
        const tuitionDate = tuitionData.start_time + ' ~ ' + tuitionData.end_time;
        const startTime = tuitionData.start_time;
        const daysToStart = Math.ceil((new Date(tuitionData.start_time) - new Date(tuitionData.updated_at)) / (1000 * 3600 * 24));
        const hourlyRate = Math.round( Number(tuitionData.hourly_rate) + (tuitionData.is_academy ? tuitionData.usage_fee : (tuitionData.is_package_tuition ? 100000 : (tuitionData.is_short_term ? 10000 * 3 : 10000)) ));
        const totalAmount = tuitionData.amount.ko;
        const totalHours = parseFloat(tuitionData.hours) + parseFloat(tuitionData.demo ? 0.5 : 0);
        const KoreaClassFee = tuitionData.amount.ko;
        const MyCredit = 0;
        const postCurrency = tuitionData.currency;

        const isDemo = tuitionData.demo === 1 ? true : false;
        const isAcademy = tuitionData.is_academy === 1 ? true : false;

        const setObject = {
            teacherFullName: teacherFullName,
            studentFullName: studentFullName,
            selectedCuls: selectedCuls,
            tuitionDate: tuitionDate,
            startTime: startTime,
            daysToStart: daysToStart,
            hourlyRate: hourlyRate,
            totalAmount: totalAmount,
            totalHours: totalHours,
            KoreaClassFee: KoreaClassFee,
            MyCredit: MyCredit,
            postCurrency: postCurrency,
            isDemo: isDemo,
            isAcademy: isAcademy,
        };

        return setObject;
    }

    getTuitionInfomation = async (_orderNum, allUrls) => {
        // const orderNum = _orderNum;
        // const tuitionUrl = allUrls.tuition + '/' + orderNum;
        // const tuitionRes = await axios.get(tuitionUrl).catch((err) => { return console.log(err) });
        // const gcoinUrl = allUrls.gcoin.getTotalAmount;
        // const gcoinRes = await axios.get(gcoinUrl).catch((err) => { return console.log(err) });

        // const tuitionData = tuitionRes.data;

        // if (tuitionData.status === 'fail') {
        //     return null;
        // }
        // const teacherFullName = tuitionData.tutor_info.teacher_full_name;
        // const studentFullName = tuitionData.student_name;
        // const selectedCuls = tuitionData.curriculums;
        // const tuitionDate = tuitionData.start_time + ' ~ ' + tuitionData.end_time;
        // const startTime = tuitionData.start_time;
        // const daysToStart = Math.ceil((new Date(tuitionData.start_time) - new Date(tuitionData.updated_at)) / (1000 * 3600 * 24));
        // const hourlyRate = Math.round( Number(tuitionData.hourly_rate) + (tuitionData.is_academy ? tuitionData.usage_fee : (tuitionData.is_package_tuition ? 100000 : (tuitionData.is_short_term ? 10000 * 3 : 10000)) ));
        // const totalAmount = tuitionData.amount.ko;
        // const totalHours = parseFloat(tuitionData.hours) + parseFloat(tuitionData.demo ? 0.5 : 0);
        // const KoreaClassFee = tuitionData.amount.ko;
        // const MyCredit = gcoinRes.data.amount;
        // const postCurrency = tuitionData.currency;

        // const isDemo = tuitionData.demo === 1 ? true : false;
        // const isAcademy = tuitionData.is_academy === 1 ? true : false;

        // const setObject = {
        //     teacherFullName: teacherFullName,
        //     studentFullName: studentFullName,
        //     selectedCuls: selectedCuls,
        //     tuitionDate: tuitionDate,
        //     startTime: startTime,
        //     daysToStart: daysToStart,
        //     hourlyRate: hourlyRate,
        //     totalAmount: totalAmount,
        //     totalHours: totalHours,
        //     KoreaClassFee: KoreaClassFee,
        //     MyCredit: MyCredit,
        //     postCurrency: postCurrency,
        //     isDemo: isDemo,
        //     isAcademy: isAcademy,
        // };

        // return setObject;

    }

    doTuitionConfirmProcess = async (_orderNum, allUrls) => {
        const orderNum = _orderNum;
        const tuitionConfirmUrl = allUrls.tuitionConfrim;

        const tConfT = tuitionConfirmUrl + '/' + orderNum;
        const tuitionConfirmRes = await axios.get(tConfT).catch((err) => { return console.log(err); });

        if (!tuitionConfirmRes) return;
        if (!tuitionConfirmRes.data) return;

        const tuitionConData = tuitionConfirmRes.data;

        let checkerValid = false;
        if (tuitionConData.status === 'success') {
            checkerValid = true;
        } else {
            const tcode = tuitionConData.code;
            const tmsg = tuitionConData.msg;
            const lang = this.props.lang;
            if (tcode === 1) {
                showNotify(tmsg, STATE_NOTIFY.TYPE_NEGATIVIE);
                window.location.href = '/#/errors';
            } else if (tcode === 2) {
            } else {
                let msg = lang == 'cn' ? '该上课账单已被确认' : lang == 'en' ? 'This tuition invoice has already been confirmed.' : '이 수업료 청구서는 이미 확인되었습니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            }
        }

        return checkerValid;

    }



    ShowAnim = () => {
        const tuitionConfirmAnim = document.getElementById('tuition-confirm-anim');
        const tuitionConfirmAnim2 = document.getElementById('tuition-confirm-anim2');
        const ShowClass = 'tuition_confirm-close-anim-start';
        const ShowClass2 = 'tuition_confirm-show-anim-start';
        tuitionConfirmAnim.classList.add(ShowClass2);
        tuitionConfirmAnim2.classList.add(ShowClass);

        tuitionConfirmAnim.addEventListener('animationend', () => {
            const tuitionConfirmAnim = document.getElementById('tuition-confirm-anim');
            const EndClass = 'tuition_confirm-close-anim-end';
            const ShowClass2 = 'tuition_confirm-show-anim-start';
            tuitionConfirmAnim.classList.remove(EndClass);
            // tuitionConfirmAnim.classList.remove(ShowClass2);
            tuitionConfirmAnim.removeEventListener("animationend", tuitionConfirmAnim, true);
        });

        tuitionConfirmAnim2.addEventListener('animationend', () => {
            const tuitionConfirmAnim2 = document.getElementById('tuition-confirm-anim2');
            const ShowClass = 'tuition_confirm-close-anim-start';
            const EndClass = 'tuition_confirm-close-anim-end';
            tuitionConfirmAnim2.classList.remove(ShowClass);
            tuitionConfirmAnim2.classList.add(EndClass);
            tuitionConfirmAnim2.removeEventListener("animationend", tuitionConfirmAnim2, true);
        });

    }
    HideAnim = () => {
        const tuitionConfirmAnim = document.getElementById('tuition-confirm-anim');
        const tuitionConfirmAnim2 = document.getElementById('tuition-confirm-anim2');
        const ShowClass = 'tuition_confirm-close-anim-start';
        const ShowClass2 = 'tuition_confirm-show-anim-start';

        tuitionConfirmAnim.addEventListener('animationend', () => {
            const tuitionConfirmAnim = document.getElementById('tuition-confirm-anim');
            const EndClass = 'tuition_confirm-close-anim-end';
            tuitionConfirmAnim.classList.remove(EndClass);
            tuitionConfirmAnim.classList.add(EndClass);
            tuitionConfirmAnim.removeEventListener("animationend", tuitionConfirmAnim, true);
        });
        tuitionConfirmAnim.classList.remove(ShowClass2);
        tuitionConfirmAnim.classList.add(ShowClass);

        tuitionConfirmAnim2.addEventListener('animationend', () => {
            const tuitionConfirmAnim2 = document.getElementById('tuition-confirm-anim2');
            const EndClass = 'tuition_confirm-close-anim-end';
            const ShowClass2 = 'tuition_confirm-show-anim-start';
            tuitionConfirmAnim2.classList.remove(EndClass);
            tuitionConfirmAnim2.classList.remove(ShowClass2);
            tuitionConfirmAnim2.removeEventListener("animationend", tuitionConfirmAnim2, true);
        });
        tuitionConfirmAnim2.classList.add(ShowClass2);

    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li>
                        <a href="/#/"><TrasHome /></a>
                    </li>
                    <li>
                        <a href="/#/teachers/dashboard"><TrasClass /></a>
                    </li>
                    <li className="current"><TrasTuitionBill /></li>
                </ol>
            </div>
        )
    }




    onChangePaymentWay = (e) => {
        const target = e.target;
        const value = target.value;

        const setType = value;

        let postCurrency = '2';

        if (setType === 'RMB') {
            postCurrency = '3';
        } else if (setType === 'GBP') {
            postCurrency = '1';
        }

        this.setState({
            ...this.state,
            currentPaymentCountry: setType,
            tuitionData: {
                ...this.state.tuitionData,
                postCurrency: postCurrency
            }
        });

    }


    onChangeCredit = (e) => {

        const target = e.target;
        const checked = target.checked;

        let valid = false;

        if (target.id === 'bank') {
            this.ShowAnim();
            valid = true;
        } else {
            this.HideAnim();
        }

        this.setState({
            ...this.state,
            isCreditCheck: valid
        }, () => {
        })

    }

    // Payment Way
    createBodyC = () => {

        const currentPaymentType = this.state.currentPaymentCountry;
        const TrasKorean = 'KOREA';
        const TrasUK = 'UK';
        const TrasChina = 'CHINA';

        let showText = this.state.tuitionData.isAcademy? this.state.paymentOptions.ACADEMY.text:this.state.paymentOptions.KOREA.text;
        if (currentPaymentType === 'GBP') {
            showText = this.state.paymentOptions.UK.text;
        } else if (currentPaymentType === 'RMB') {
            showText = this.state.paymentOptions.CHINA.text;
        }


        let extraMemo = `*받는통장메모에 "${this.state.tuitionData.studentFullName}"이라고 적어주세요.`;
        let extraMemo2 = ``;
        if (this.state.lang === 'en') {
            extraMemo = `Please process the bank transfer as stated using the student name "${this.state.tuitionData.studentFullName}"`
        } else if (this.state.lang === 'cn') {
            extraMemo = `请使用学生姓名“${this.state.tuitionData.studentFullName}”处理银行转帐`;
        }

        if (Number(this.state.tuitionData.daysToStart) > 0) {
            extraMemo2 = <div className='tuitions_confirm-memo'>{`"${this.state.tuitionData.startTime}"까지 입금 부탁 드리겠습니다.`}</div>
        } else {
            extraMemo2 = <div className='tuitions_confirm-memo'>{`인보이스가 수업 시작 날짜보다 늦게 발행되었습니다. 양해 부탁 드리겠습니다. 빠른 시일 내에 입금 부탁 드리겠습니다.`}</div>
        }

        return (
            <div className='tuition_confirm-part-single-wapper'>
                <h3 className="blue-font-color">
                    <TrasPaymentMethod />
                </h3>
                <div className='tuition_confirm-payment-way'>

                    {/* Payment Way */}
                    <div className="tuition_confirm-p-way"><input type="radio" name="wayPayment" id="bank" onChange={this.onChangeCredit} /><TrasBankTransfer /></div>
                    <div id='tuition-confirm-anim' className='tuition_confirm-close-anim-end'>
                        <div className='tuition_confirm-payment-way2'>
                            <div>
                                <span>
                                    <select onChange={this.onChangePaymentWay}>
                                        <option value="KRW">{TrasKorean}</option>
                                        <option value="GBP">{TrasUK}</option>
                                        <option value="RMB">{TrasChina}</option>
                                    </select>
                                </span>
                                <span>
                                    <div>{showText}</div>
                                </span>
                            </div>
                        </div>
                        <div className='tuitions_confirm-memo'>{extraMemo}</div>
                        {extraMemo2}
                    </div>
                    {/* Credit */}
                    <div className="tuition_confirm-p-way"><input type="radio" name="wayPayment" id="credit" onChange={this.onChangeCredit} /><TrasCredit /></div>

                    {this.createBodyC_WithCredit()}
                </div>

            </div>
        )
    }


    onSubmitBtn = async () => {

        if (this.state.isSubmit) return;

        this.setState({
            ...this.state,
            isSubmit: true,
        })

        const credit = document.getElementById('credit');
        const bank = document.getElementById('bank');
        let valid = false;

        if (bank.checked || credit.checked) valid = true;
        if (!valid) {
            const msg = '결제 방식을 선택해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);

            this.setState({
                ...this.state,
                isSubmit: false,
            })
            return;
        }


        // Payment Way
        const orderNum = this.props.match.params.ordernum;
        let paymentUrl = '';
        if (bank.checked === true) {
            paymentUrl = 'tuition/transfer/' + orderNum + '/';
        } else {
            paymentUrl = 'tuition/purchase/' + orderNum + '/';
        }


        const postData = {
            currency: this.state.tuitionData.postCurrency,
            lang: this.state.lang,
            teacherName: this.state.tuitionData.teacherFullName
        };

        const http = getEnvironment();

        let sendUrl = http + '/data/' + paymentUrl;
        sendUrl += ('?' + queryString.stringify(postData));

        const res = await axios.post(sendUrl, postData, postConfig).catch((err) => { return console.log(err); });

        if (!res) return;
        if (!res.data) return;

        const result = res.data.status;
        const lang = this.state.lang;
        if (result === 'success') {
            this.setState({
                ...this.state,
                isSubmit: false,
            }, () => {
                showNotify(lang === 'cn' ? '确认成功' : lang === 'en' ? 'Confirm Complete.' : '성공적으로 확인되었습니다.', STATE_NOTIFY.TYPE_POSITIVE);
                window.location.href = '/#/students/dashboard';
            })
        } else {
            const msg = res.data.msg;
            let innerMsg = '';
            if (res.data.code === 1) {
                innerMsg = lang === 'cn' ? '没有有效的学费ID。' : lang === 'en' ? 'Not a valid tuition id.' : '올바른 강의가 아닙니다.';
            } else if (res.data.code === 2) {
                innerMsg = lang === 'cn' ? '你不是学生。' : lang === 'en' ? 'You are not a student.' : '학생 회원만 접근 가능합니다.';
            } else if (res.data.code === 3) {
                innerMsg = lang === 'cn' ? '信用不足。' : lang === 'en' ? 'Not enough credit.' : '크레딧이 충분하지 않습니다.';
            } else {
                innerMsg = 'Server Error (' + msg + ')';
            }

            this.setState({
                ...this.state,
                isSubmit: false,
            }, () => {
                showNotify(innerMsg, STATE_NOTIFY.TYPE_NEGATIVIE);
            });
        }

    }

    createBodyC_WithCredit = () => {

        let creditLabelMessage = "";

        const totalHours = Number(this.state.tuitionData.totalHours);
        const hourlyRate = Number(this.state.tuitionData.hourlyRate);
        const totalAmount = Number(this.state.tuitionData.totalAmount);
        const KoreaClassFee = Number(this.state.tuitionData.KoreaClassFee);
        const MyCredit = Number(this.state.tuitionData.MyCredit);

        const isEnough = (MyCredit >= KoreaClassFee);
        const isCreditCheck = this.state.isCreditCheck;

        const currentPaymentType = this.state.currentPaymentCountry;
        const TrasKorean = 'KOREA';
        const TrasUK = 'UK';
        const TrasChina = 'CHINA';

        let showText = this.state.tuitionData.isAcademy? this.state.paymentOptions.ACADEMY.text:this.state.paymentOptions.KOREA.text;
        if (currentPaymentType === 'GBP') {
            showText = this.state.paymentOptions.UK.text;
        } else if (currentPaymentType === 'RMB') {
            showText = this.state.paymentOptions.CHINA.text;
        }

        let extraMemo = `*받는통장메모에 "${this.state.tuitionData.studentFullName}"이라고 적어주세요.`;
        let extraMemo2 = ``;
        if (this.state.lang === 'en') {
            extraMemo = `Please process the bank transfer as stated using the student name "${this.state.tuitionData.studentFullName}"`
        } else if (this.state.lang === 'cn') {
            extraMemo = `请使用学生姓名“${this.state.tuitionData.studentFullName}”处理银行转帐`;
        }

        if (Number(this.state.tuitionData.daysToStart) > 0) {
            extraMemo2 = <div className='tuitions_confirm-memo'>{`"${this.state.tuitionData.startTime}"까지 입금 부탁 드리겠습니다.`}</div>
        } else {
            extraMemo2 = <div className='tuitions_confirm-memo'>{`인보이스가 수업 시작 날짜보다 늦게 발행되었습니다. 양해 부탁 드리겠습니다. 빠른 시일 내에 입금 부탁 드리겠습니다.`}</div>
        }

        if (isEnough) {
            creditLabelMessage = `(보유 크레딧 ${moneyFormat(MyCredit)} 원 - 강의료 ${moneyFormat(KoreaClassFee)} 원 = ${moneyFormat(MyCredit - KoreaClassFee)} 원)`;
        } else {
            creditLabelMessage = `크레딧이 부족해 강좌 구매가 불가능합니다. (현재 보유중인 크레딧 : ${moneyFormat(MyCredit)})`;
        }

        let tuitionSmallMsg = '- 결제 시 크레딧이 차감됩니다.';
        if (isCreditCheck) {
            tuitionSmallMsg = '';
        }



        return (
            <div className='tuition_confirm-with-credit'>
                <div id='tuition-confirm-anim2' className='tuition_confirm-close-anim-end'>
                    {isEnough ?
                        <div>
                            <div>{`크레딧으로 강좌 구매가 가능합니다.`}</div>
                            <div className='tuition_pay_msg'>{creditLabelMessage}</div>
                            {/* <span id="creditBtn" className='btn btn-gurume-blue creditBtn'>크레딧으로 결제하기 (현재 보유 크레딧 : {moneyFormat(KoreaClassFee)})</span> */}
                        </div>
                        :
                        <div>
                            <div>{creditLabelMessage}</div>
                            <a href='/#/credit/buy' className='btn btn-gurume-blue creditBtn'>(크레딧 구매하러 가기)</a>
                        </div>
                    }
                    {/* 또한, {moneyFormat(MyCredit)} 크레딧을 사용할 수 있고, */} 남은 {moneyFormat(KoreaClassFee - MyCredit)}을 계좌이체를 통해 지불할 수 있습니다. 
                    <div >
                        <div className='tuition_confirm-payment-way2'>
                            <div>
                                <span>
                                    <select onChange={this.onChangePaymentWay}>
                                        <option value="KRW">{TrasKorean}</option>
                                        <option value="GBP">{TrasUK}</option>
                                        <option value="RMB">{TrasChina}</option>
                                    </select>
                                </span>
                                <span>
                                    <div>{showText}</div>
                                </span>
                            </div>
                        </div>
                        <div className='tuitions_confirm-memo'>{extraMemo}</div>
                        {extraMemo2}
                    </div>
                </div>

                <div>
                    {
                        this.state.isAbleToConfirm ?
                            this.state.isSubmit ?
                                <span className='btn btn-gurume-blue credit-btn-agree'>
                                    보내는 중 <b className='tuition-small'>{'...'}</b>
                                </span>
                                :
                                <span className='btn btn-gurume-blue credit-btn-agree' onClick={this.onSubmitBtn}>
                                    <TrasAgree />(클릭)<b className='tuition-small'>{tuitionSmallMsg}</b>
                                </span>
                            :
                            <div>
                                <span className='btn credit-btn-submit'>
                                    이미 처리되었습니다.
                            </span>
                                <span className="btn blog-back-btn">
                                    <a href="/#/dashboard" style={{textDecoration:'none'}}><TrasBack /></a>
                                </span>
                            </div>
                    }
                    {/* <p className='color-gurume-red'>결제 방식 - 계좌이체 또는 크레딧으로 했는지는 현재 이 페이지에서 확인되지 않습니다.</p>
                    <p className='color-gurume-red'>크레딧으로 결제했으면 크레딧 히스토리 페이지를 확인해주세요.</p> */}
                </div>
            </div>
        )
    }



    // START DATE AND END DATE
    createBodyB = () => {
        const date = this.state.tuitionData.tuitionDate;

        return (
            <div className='tuition_confirm-part-single-wapper'>
                <h3 className="blue-font-color"><TrasStartingEndDate /></h3>
                <div className='tuition_confirm-part-date'>{date}</div>
            </div>
        )
    }

    createBodyD = () => {
        const totalHours = this.state.tuitionData.totalHours;
        const hourlyRate = this.state.tuitionData.hourlyRate;
        const totalAmount = this.state.tuitionData.totalAmount;

        return (
            <div className='tuition_confirm-part-single-wapper'>
                <div className='tuition_confirm-part-display'>
                    <table className="table">
                        <thead>
                            <tr>
                                <td className='tuition-c-revese'><TrasTotalHours /></td>
                                <td className='tuition-c-revese'><TrasHourlyRate /></td>
                                <td className='tuition-c-revese'><TrasTuitionFee /></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='tuition-c-td'>
                                <td>{totalHours}</td>
                                <td>{hourlyRate}</td>
                                <td>{totalAmount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    /* Curriculms */
    createBodyA = () => {

        const data = this.state.tuitionData.selectedCuls;
        const li = [];

        // id: 82,
        // curriculum: "IB",
        // subject: "Business and Management HL",
        // test_type: 2

        for (let i = 0; i < data.length; i++) {
            li.push(
                <li key={'_' + i}>
                    {/* <span>{i + 1}</span> */}
                    <span>({data[i].curriculum}) {data[i].subject}</span>
                </li>
            )
        }

        return (
            <div className='tuition_confirm-part-single-wapper'>
                <h3 className="blue-font-color"><TrasCurriculumSubject /></h3>
                <ol className='tuition_confirm-part-body-A'>
                    {li}
                </ol>
            </div>
        )
    }


    createHeader = () => {
        return (
            <div className='tuition_confirm-part-single-wapper'>
                <h2 className="blue-font-color"><TrasTuitionBill />
                    {this.state.tuitionData.isDemo ?
                        <small>&nbsp;&nbsp;*<TrasDemoClass /></small>
                        : ''
                    }
                    {this.state.tuitionData.isAcademy ?
                        <small>&nbsp;&nbsp;*아카데미</small>
                        : ''
                    }
                </h2>

                <div className='tuition_confirm_th'>
                    <h4>
                        <span>
                            <img style={{ width: "30px", height: "30px" }} src='../images/backuptutorpic.png' className="img-circle" alt='...' />
                            <span>
                                {this.state.tuitionData.teacherFullName} <TrasTeacher />
                            </span>
                        </span>
                    </h4>
                </div>
            </div>
        )
    }


    createHtml = () => {
        return (
            <div className='academy-wapper-c'>
                <div className='tuitions_confirm-html-wapper'>
                    {/* Invoice Title */}
                    {this.createHeader()}

                    {/* Curriculms */}
                    {this.createBodyA()}

                    {/* START DATE AND END DATE */}
                    {this.createBodyB()}
                    {this.createBodyD()}

                    {/* Payment Way */}
                    {this.createBodyC()}
                </div>
            </div>
        )
    }


    render (){
        return this.initFunc();
    }

}

export default withRouter(Tuitions_confirm);