import React, { Component } from 'react';
import {
    TrasHome,
} from '../../../Translate/EachTranslateC';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarAlt,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

import './winter_vacation_workshop.css';

class Winter_vacation_workshop extends Component {
    componentDidMount() {
        this.props.RootBindingData.doLoading(false);
    }

    createNav = () => {
        return (
            <div className="each-nav">
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a target="_blank" href="http://ibthetop.com">그루미 아카데미</a></li>
                    <li>그루미 IB 방학특강</li>
                </ol>
            </div>
        )
    }


    createBanner = () => {
        return (
            <div className="class-banner">
                <div className="class-banner-box">
                    <div className="class-banner-main" />
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }

    createPartA = () => {
        return (
            <div className="class-content">
                <div className="class-content-box">
                    <div className="class-content-main">
                        <h1 className="class-content-title">
                            <span className="thin-font">그루미 2020</span><br />
                            <p className="color-gurume-blue bold-font font-size-45">IB 겨울 방학 온 · 오프라인 특강</p>
                        </h1>
                    </div>
                </div>
                <hr className="class-line" />
            </div>
        )
    }

    createPartB = () => {
        return (
            <div className="class-content">
                <div className="class-content-box">
                    <div className="class-content-main">
                        <div className="class-content-main-text">
                            <h3>
                                <span className="bold-font">A. 일정 : </span>
                                <span>12.21~1.8 (3주)</span>&nbsp;
                                <span className="font-size-18 thin-font">**25일, 1일 휴강(미정)</span>
                            </h3>
                            <h3><span className="bold-font">B. 2020 겨울 특강 개요 : </span></h3>
                            <p className="text-indent-2">01. 귀국이 어려운 학생들을 위해 온·오프라인 수업이 동시에 진행되며 모든 수업의 녹화본이 제공됩니다.</p>
                            <p className="text-indent-2">02. DP1 은 4~6교시에 수업이 진행되며, DP2는 1~3교시에 진행됩니다.</p>
                            <p className="text-indent-2">03. 해당 학년의 시간표에서 수강 희망 과목 선택, 매 교시 택 1가능합니다.</p>
                            <p className="text-indent-2">04. 각 과목당 1시간 25분 진행, 총 12회 진행됩니다.</p>
                            <p className="text-indent-2">05. 모든 수업은 3명 이상 등록 시 개강하며 학원 상황에 따라 시간표에 변동이 있을 수 있습니다. (인원 미달시 개인/그룹수업으로 진행)</p>
                            <p className="text-indent-4">*코로나 사태에 대비하여 현장 강의 참여시 원내 상시 마스크착용, 손소독제 미 향균비누 비치, 개인컵 및 종이컵이용, 전원 체온 검사, 주 2회 자체 방역을 실시</p>
                            <p className="text-indent-4">하고 있습니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createPartC = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">오픈반 vs 학교반</h2>
                    <div className="vacation-content-main display-row">
                        <div className="vacation-class-type">
                            <h4><b>오픈반</b></h4>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>IB 국제학교에서 공통적으로 진행되는 커리큘럼에 맞춤화 된 '시험용' 선행/복습 과정
                            </p>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>다른 학교 학생들과 함께
                            </p>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>정해진 수업시간 스케줄
                            </p>
                        </div>
                        <div className="vacation-class-type">
                            <h4><b>학교반</b></h4>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>학생 학교 진도에 맞춤화 된 '내신용' 선행/복습 과정
                            </p>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>같은 학교 학생들과 함께
                            </p>
                            <p>
                                <i className="fa fa-check margin-right-5"></i>원하는 수업시간 스케줄
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartD = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">특강 시간표</h2>
                    <div className="vacation-schedule table-responsive">
                        <p className="visible-xs">(* 시간표를 좌우로 움직여 자세히 확인이 가능합니다.)</p>
                        <table className="table table-bordered border-default-1 schedule-table">
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>
                                        <b>시간</b>
                                    </td>
                                    <td>
                                        <b>월</b>
                                    </td>
                                    <td>
                                        <b>화</b>
                                    </td>
                                    <td>
                                        <b>수</b>
                                    </td>
                                    <td>
                                        <b>목</b>
                                    </td>
                                    <td>
                                        <b>금</b>
                                    </td>
                                    <td>
                                        <b>토</b>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan="3">
                                        학교반/개인 수업
                                </td>
                                </tr>
                                <tr>
                                    <td>09:00-10:25</td>
                                    <td colSpan="6">1교시</td>
                                </tr>
                                <tr>
                                    <td>10:30-11:55</td>
                                    <td colSpan="6">2교시</td>
                                </tr>
                                <tr>
                                    <td>Break</td>
                                    <td>12:00-12:30</td>
                                    <td colSpan="6">Lunch</td>
                                </tr>
                                <tr>
                                    <td rowSpan="3">DP2 오픈반</td>
                                    <td>12:30-13:55</td>
                                    <td colSpan="5">1교시</td>
                                    <td rowSpan="3">문제<br />풀이</td>
                                </tr>
                                <tr>
                                    <td>14:00-15:25</td>
                                    <td colSpan="5">2교시</td>
                                </tr>
                                <tr>
                                    <td>15:30-16:55</td>
                                    <td colSpan="5">3교시</td>
                                </tr>
                                <tr>
                                    <td>Break</td>
                                    <td>17:00-17:30</td>
                                    <td colSpan="6">Dinner</td>
                                </tr>
                                <tr>
                                    <td rowSpan="3">DP1 오픈반</td>
                                    <td>17:30-18:55</td>
                                    <td colSpan="5">1교시</td>
                                    <td rowSpan="3">문제풀이<br />/<br />
                                        ToK 17:30-19:00
                                </td>
                                </tr>
                                <tr>
                                    <td>19:00-20:25</td>
                                    <td colSpan="5">2교시</td>
                                </tr>
                                <tr>
                                    <td>20:30-21:55</td>
                                    <td colSpan="5">3교시</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>
                            *1월 1일 휴원으로 3주간 HL은 14회, SL은 8회 수업을 진행합니다.<br />
                            *11월 시험의 학생들은 희망 단원 선택 혹은 담당 선생님의 권장 위주로 DP1 그리고 DP2 과정을 부분적으로 수강합니다.<br />
                            <br />
                            *DP1 ToK 마스터클래스 (오픈반) : 3회, 매주 토요일 17:30-19:00<br />
                            *DP2 ToK 에세이 (개인수업) : 3회<br />
                            <br />
                            *학교반<br />
                            (과목 담당 선생님과 상의 후 조율 가능)<br />
                            - 오픈반 스케줄이 맞지 않거나 특정 학교 커리큘럼에 따라 수업 진행을 원하실 경우<br />
                            - 학업 사정상 학교반 수업이 오픈반 기간보다 좀 더 일찍 혹은 늦게 시작되어야 할 경우<br />
                            <br />
                            *개인수업(1:1)<br />
                            (약 특강 시작 전 1달 전 부터 신청 받음)<br />
                            - 기존 학생에게 우선 배정하고 신규학생은 이후 예약 가능<br />
                            <br />
                            *문제풀이반<br />
                            - 오픈반 학생들뿐만 아니라 개인/학교반 학생들도 참여 가능<br />
                            (단원별로 진행되기 때문에 DP2 학생이 DP1 문제풀이반 참여도 가능)<br />
                            <br />
                            *다양한 영어 실력의 학생들의 니즈를 맞추기 위해 수업은 평균적으로 영어반/한국어반으로 진행됩니다.<br />
                            하지만 등록한 학생 그룹에 따라 주로 영어 혹은 한국어가 사용 될 수 있습니다.<br />
                            <a href="https://youtu.be/91XJ50SWYTw">https://youtu.be/91XJ50SWYTw</a><br />
                            (2018 겨울방학 DP2 수학 HL 오픈반 중)<br />
                        </p>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartE = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">오픈반 시간표</h2>
                    <div className="vacation-schedule">
                        <h6 className="">*DP1과 DP2 과정의 1, 2, 3교시 과목 시간표는 동일합니다.</h6>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td width="30%">1교시</td>
                                    <td>
                                        Maths HL (1반), Chem SL, Bio HL, Physics HL, Korean, Business, English B
                                    </td>
                                </tr>
                                <tr>
                                    <td>2교시</td>
                                    <td>
                                        Maths HL (2반), Chem HL (1반), Bio SL, Physics SL, English A
                                    </td>
                                </tr>
                                <tr>
                                    <td>3교시</td>
                                    <td>
                                        Maths SL, Chem HL (2반), English A, Econ, Psychology
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            *HL 주 5회 (월~금)<br />
                            *SL 주 3회 (기본적으로 월, 수, 금/ 요청 시 화, 목, 토 반 추가 개설 가능)<br />
                            *3주 동안 최대 3과목 수강 (추가 과목은 개인수업 가능)<br />
                            *경제, 경영, 심리, 영어, 코리안 HL/SL 통합 수업 (SL 주 3회)<br />
                            *시간 상 약간의 변동이 있을 수 있습니다
                        </p>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartF = () => {
        return (
            <div>
                <div className="vacation-content">
                    <div className="vacation-content-box">
                        <h2 className="vacation-content-title">오픈반 커리큘럼</h2>
                        <p>
                            DP1 오픈반은 남은 1학년 과정의 75~100% 내용 선행 및 가을 학기 과정 복습<br />
                            DP2 오픈반은 남은 2학년의 전 과정 선행 및 2학년 가을 학기 과정 복습
                    </p>
                        <div className="vacaton-content-main">
                            <div className="margin-bottom-30 margin-top-30">
                                <button className="vacation-subject-btn">
                                    <a target="_blank" href="http://tiny.cc/99wucz" className="hover-sub">수학</a>
                                </button>
                                <button className="vacation-subject-btn">
                                    <a target="_blank" href="https://goo.gl/V4NFYi" className="hover-sub">물리</a>
                                </button>
                                <button className="vacation-subject-btn">
                                    <a target="_blank" href="https://goo.gl/TxYwWh" className="hover-sub">화학</a>
                                </button>
                                <button className="vacation-subject-btn">
                                    <a target="_blank" href="https://goo.gl/dTGX3e" className="hover-sub">생물</a>
                                </button>
                                <button className="vacation-subject-btn">
                                    <a target="_blank"
                                        href="https://docs.google.com/document/d/1O2GIMVo-8nhPaBTXOkjRVpRdW6IEt6t4xa_jFCaEb6M/edit?usp=sharing">경제</a>
                                </button>
                                <button className="vacation-subject-btn">
                                    <a target="_blank"
                                        href="https://docs.google.com/document/d/1dNLtgC1YtWOBflbUR63l6MV4s4Phk4VeM-kHCehtFgc/edit?usp=sharing"
                                        className="hover-sub">영어</a>
                                </button>
                                <button className="vacation-subject-btn">
                                    <a className="hover-sub">코리안</a>
                                </button>
                                <button className="vacation-subject-btn">
                                    <a className="hover-sub">ToK</a>
                                </button>
                            </div>

                            <p>
                                *해당 과목을 클릭하시면 커리큘럼 상세내용 확인 가능<br />
                                *기타 과목 커리큘럼은 담당 과목 선생님과 상담
                            </p>
                        </div>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartG = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">학교반 시간표</h2>
                    <p>
                        *수학, 화학, 생물, 경제, 영어 과목 한정<br />
                        *오픈반과 달리 HL과 SL과정 모두 주 5회(총 14회) 또는 3회(총 8회) 진행 가능<br />
                        *특정 과목은 오전 시간 외에도 화, 목, 토 오후와 저녁에 가능 (별도 문의 필요)<br />
                        *학교마다 방학 시작/끝 날짜와 기간이 다르기 때문에 요청에 따라 수업 기간 배정<br />
                        *3~5명 학생들의 공통 가능시간으로 요청시 선착순 배정<br /><br />
                    </p>
                    <div className='vacation-schedule'>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td rowSpan="2">선생님</td>
                                    <td colSpan="2">월~금 / 월수금 / 화목토</td>
                                </tr>
                                <tr>
                                    <td>08:30-10:00</td>
                                    <td>10:00-11:30</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>수학 - 방원준</td>
                                    <td>월~금 마감</td>
                                    <td>월~토 마감</td>
                                </tr>
                                <tr>
                                    <td>물리 - 김정환</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>

                                <tr>
                                    <td>화학 - 이재훈</td>
                                    <td>마감</td>
                                    <td>마감</td>
                                </tr>
                                <tr>
                                    <td>생물 - 이혁진</td>
                                    <td>마감</td>
                                    <td>마감</td>
                                </tr>
                                <tr>
                                    <td>경제 - 박소미</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <td>영어 - 안재현</td>
                                    <td>O</td>
                                    <td>마감</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h5 className="bold-font font-size-14">O 예약가능</h5>
                    <p>*자리가 웹사이트 업데이트 전에 마감 되었을 수 있으니 전화.카톡 문의바랍니다</p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartH = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">학습관리</h2>
                    <div className="vacation-table">
                        <table className="table table-bordered" width="90%">
                            <thead>
                                <tr>
                                    <td width="30%;">위클리 테스트</td>
                                    <td width="30%;">자습/보충 수업</td>
                                    <td width="30%;">IA/EE/ToK 과제</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        - 그루미 특강학습 내용에 맞추어 매주 배운 내용에 대한 학습 성취도 체크용 단원별 시험<br />
                                        - 각 과목별 선생님께서 디자인 해주셨기 때문에 과목별로 테스트 스타일과 포멧에 차이 有<br />
                                        - 과학의 경우 DP2는 점수 유실이 가장 많은 Paper 2 주간식 위주로 DP1은 개념확인을 하기 위한 Paper 1 객관식 위주로 문제 배정<br />
                                        <br />
                                        *IB 특강 특성상 1~3 과목 학습하는 학생들이 모두 동시에 같은 공간에서 시험을 치르지 않습니다. 그루미의 학생 별 맞춤형 학습 철학에 따라
                                        개별적으로 수~목요일에 자습실에서 TA의 감독 아래 개별적으로 진행 옵션을 드립니다. 만약 수~목에 진행이 안될 경우 토요일 오후 12시~3시
                                        사이에 시험 시간만큼 필수 등원
                                    </td>
                                    <td>
                                        - 감독관이 관리하는 수업 전.후 필수 자습시간에 과목별 TA 선생님과 자유로운 질의응답<br />
                                        - 학부모님 요청시 학생 등.하원 보고<br />
                                        <br />
                                        (보충)<br />
                                        특강 수업 진도가 너무 빠르게 느껴지는 학생들은 탄탄한 습득을 위해 당직 TA 혹은 대학생 1학년 선생님과 보충 수업이 배정됩니다. 별도의
                                        수업료가 있기 때문에 그루미에서는 권유를 드리는 것 뿐이면 결정은 학부모님께서는 하십니다:<br />
                                        - 1주 차가 끝날 무렵 학생과의 개별미팅을 바탕으로 그리고 과목 선생님께서 말씀해주시는 보충이 필요한 학생들 대상으로 보충 수업<br />
                                        - TA 쌤들께서 위클리 테스트를 마킹할 때 채점 및 특정 약한 단원에 대한 피드백을 코멘트로 남겨주시며 보충 수업 필요여부를 안내하면 학생은
                                        토요일에 등원하여 보충 수업
                                    </td>
                                    <td>
                                        DP2 학생들은 이번 여름방학 동안 과목별로 주제선정/초안 혹은 파이널 작성을 마쳐야 하는 만큼 그루미에서 IA/EE 진도상황을 학생과 직접
                                        확인 후 필요한 개별 멘토링을 아래 커리큘럼에 따라 준비해드립니다:<br />
                                        (DP2 과제 멘토링은 학원 선생님이 아닌 과외 선생님과 진행)<br />
                                        IA<br />
                                        <a target="_blank" href="https://gurume.kr/#/group_class/29">https://gurume.kr/group_class/29</a><br />
                                        EE<br />
                                        <a target="_blank" href="https://gurume.kr/#/group_class/77">https://gurume.kr/group_class/77</a><br />
                                        (DP1 학생들은 주제를 가장 중요시 하기 때문에 특강 수업 중 1회는 IA 수업으로 샘플 IA와 주제 종류에 대해 소개)
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
    createPartI = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <div className="vacation-content-main width-60">
                        <h2>
                            <small>학부모 간담회</small>
                        </h2>
                        <p>
                            *특강기간의 중간시점에서 선생님께서 학부모님과 학생에게 학업 성취도와 학습진도에 대해 공유하여 남은 기간동안 부족한 부분에 더욱 신경써야하는 부분을 파악 및 개선<br />
                            *학교에서 진행되는 Parents Evening과 같이 학생도 동행하여 학생 또한 직접 선생님으로부터 피드백을 들을 필요有 (면대면 참여를 못하시는 학부모님은 보이스톡)<br />
                            *구글클래스 매니지먼트 시스템 사용
                        </p>
                        <p className="boldest-font">2019년 겨울방학 학부모 간담회<br />
                            일시: 12월 28일 토요일 9:00am-12:30pm
                        </p>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartJ = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">수업 교재 및 학습 자료<br />
                        <small>IB 기출문제집</small>
                    </h2>
                    <p>
                        시험 예상 문제 선출<br />
                        단원/부단원 별 분류<br />
                        연도별 시험지 및 출판사별 교재 출원<br />
                        *수업별 교재비 별도
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartK = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">성취 (Learning Outcome)</h2>
                    <p>
                        과목별 핵심 개념정리 및 중요<br />
                        고득점 유형별 문제답변 테크닉 터득<br />
                        IA/ToK/EE 과제물 이해 및 초본 완성<br />
                        내신 & 파이널 점수 향상
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartL = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">Amenities</h2>
                    <p>
                        쾌적한 자습실<br />
                        매 교실 대형 TV 스크린<br />
                        무료 각종 음료/ 스낵
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }


    createPartM = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">추후 서비스</h2>
                    <div className="vacation-schedule">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td>셀프스터디 패키지</td>
                                    <td>1:1 면대면/온라인수업</td>
                                    <td>대입 컨설팅</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>인강, 교재, 문제집를 사용하여 학기중에 학생 스스로 학습할 수 있는 프로그램을 제공합니다.</td>
                                    <td>특강이 끝난 후에도 지속적인 개별 수업을 통하여 학생 학업관리 및 성적을 책임집니다.</td>
                                    <td>학생의 포텐셜을 최대한 끌어올려 최상위권 대학에 원서를 넣을 수 있도록 멘토링을 제공합니다.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }
    createPartN = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">상담/수강신청</h2>
                    <p>
                        <b>
                            2019 겨울방학 DP1 오픈반 신청서&nbsp;
                                    <a href="https://forms.gle/wbbDNKwkinDMFTi26" target="_blank">https://forms.gle/wbbDNKwkinDMFTi26</a><br />
                            2019 겨울방학 DP2 오픈반 신청서&nbsp;
                                    <a href="https://forms.gle/EngvGzC2B43R4vGf6" target="_blank">https://forms.gle/EngvGzC2B43R4vGf6</a><br />
                            2019 겨울방학 학교반 신청서&nbsp;
                                    <a href="https://forms.gle/boWvB2qGqjkwGFYK8" target="_blank">https://forms.gle/boWvB2qGqjkwGFYK8</a><br />
                        </b>
                        상담 및 수강신청은 그루미 상담 과목 선생님과도 직접 가능합니다.<br />
                        수강신청은 특강 시작 하루 전까지 가능하며, 피치못할 사정으로 특강 시작 후 부분수강을 하셔야 할 경우 연락주세요.<br />
                        정원 모집시 조기마감 될 수 있으니 방학 스케줄이 확정되면 빠른 시일내에 등록 완료 하시길 바랍니다.
                    </p>
                </div>
                <hr className="vacation-line" />
            </div>
        )
    }

    createPartO = () => {
        return (
            <div className="vacation-content">
                <div className="vacation-content-box">
                    <h2 className="vacation-content-title">자주 묻는 질문 (FAQ)</h2>
                    <p className="margin-bottom-30">
                        반 구성 / 특강 선생님 / 학습 & 보충 / 부분 수강 & 중도하차 / 수업료<br />
                        <a href="https://goo.gl/VHTiFc" target="_blank">https://goo.gl/VHTiFc</a>
                    </p>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div className="vacation-wrapper">
                {this.createNav()}
                {this.createBanner()}
                {this.createPartA()}
                {this.createPartB()}
                {this.createPartC()}
                {this.createPartD()}
                {this.createPartE()}
                {this.createPartF()}
                {this.createPartG()}
                {this.createPartH()}
                {this.createPartI()}
                {this.createPartJ()}
                {this.createPartK()}
                {this.createPartL()}
                {this.createPartM()}
                {this.createPartN()}
                {this.createPartO()}
            </div>
        )
    }

    render() {
        return (
            <div className="default-wrapper" >
                {this.createHtml()}
            </div>
        )
    }

}

export default Winter_vacation_workshop;