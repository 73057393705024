import { createAction, handleActions } from 'redux-actions';
import { GetAjaxURL, getEnvironment, postConfig } from '../config/AjaxConfig';

import axios from '../config/axios';


// const CHECK_LOGIN = 'AuthService/CHECK_LOGIN';

const CHECK_REACT_LOGIN = 'AuthService/CHECK_REACT_LOGIN';
const SET_RESET_ISLOGIN = 'AuthService/SET_RESET_ISLOGIN';
// const SET_AUTH_MSG = 'AuthService/SET_AUTH_MSG';
const SET_USER_MSG = 'AuthService/SET_USER_MSG';
const SET_REDIRECT = 'AuthService/SET_REDIRECT';
const SET_IS_LOGIN = 'AuthService/SET_IS_LOGIN';




// const checkLogin = createAction(CHECK_LOGIN);

const reactLogin = createAction(CHECK_REACT_LOGIN);
const setResetIsLogin = createAction(SET_RESET_ISLOGIN);
// const setAuthMsg = createAction(SET_AUTH_MSG);
const setUserMsg = createAction(SET_USER_MSG);
const setRedirect = createAction(SET_REDIRECT);
const setIsLogin = createAction(SET_IS_LOGIN);

export const setAuthMsgService = (_userData) => dispatch => {

    // var userMessage = {
    //     isLogin: 'true',
    //     name: res_data.name,
    //     type: res_data.type,
    //     uid: res_data.id,
    //     needContactInfo: res_data.need_contact_info,
    //     needTutorMoneyInfo: res_data.need_tutor_money_info,
    //     needHighSchoolInfo: res_data.need_high_school_info,
    // };


}
export const setUserMsgService = (_userData, lang) => async dispatch => {

    const allUrls = await GetAjaxURL();
    const lang_url = allUrls.sendLang;

    const postLang = {
        'lang': lang
    };

    axios.post(lang_url, postLang, postConfig).then((res, err) => {
        if (err) return console.log(err);
        /// this call for db save lang data 
        // make showNotify;
    })


    const userMessage = {
        isLogin: 'true',
        name: _userData.name,
        type: _userData.type,
        uid: _userData.id,
        needContactInfo: _userData.need_contact_info,
        needTutorMoneyInfo: _userData.need_tutor_money_info,
        needHighSchoolInfo: _userData.need_high_school_info,
    };

    // window.localStorage.removeItem('person');
    // window.localStorage.setItem('person', JSON.stringify(userMessage));
    dispatch(setUserMsg(userMessage));
    dispatch(setResetIsLogin());
    dispatch(setRedirect(true));
}


// export const reSetIsLoginData = () => async dispatch => {
//     dispatch(setResetIsLogin());
//     dispatch(setRedirect(true));
// }

export const isLoginFromAuthService = () => async dispatch => {

    const allUrls = await GetAjaxURL();
    const authUrlIsLogin = allUrls.isLogin;
    const loginCheckData = await axios.get(authUrlIsLogin).catch((err) => { return err; });

    dispatch(setIsLogin(loginCheckData.data));
}


export const isLoginInReact = (_keynum) => dispatch => {

    const env = getEnvironment();

    if (env) {
        // const url = `http://localhost:8000/react/getReactAuth?id=${_keynum}`;
        // axios.get(url).then((res) => {
        //     // dispatch(reactLogin(res.data));
        // }).catch((err)=>{console.log(err)});
        dispatch(reactLogin('temp'));

    }

}


export const isLoginFromPHP = () => dispatch => {
    return;
    // dispatch(checkLogin());
}



const initialState = {
    isAuthkey: '',
    authMsg: {},
    userMessage: {},
    needRedirect: false
}


export default handleActions({

    [CHECK_REACT_LOGIN]: (state, action) => {
        return {
            ...state,
            isAuthkey: action.payload
        };
    },
    [SET_RESET_ISLOGIN]: (state, action) => {
        return {
            ...state,
            authMsg: {}
        }
    },
    // [SET_AUTH_MSG] : (state, action) => {
    //     return {
    //         ...state,
    //     }
    // },

    [SET_USER_MSG]: (state, action) => {
        return {
            ...state,
            userMessage: action.payload
        }
    },
    [SET_REDIRECT]: (state, action) => {
        return {
            ...state,
            needRedirect: action.payload
        }
    },
    [SET_IS_LOGIN]: (state, action) => {
        return {
            ...state,
            authMsg: action.payload
        }
    }




}, initialState);