import React, { Component } from 'react';
import './recruiting.css';

class RecruitingContent extends Component {
    render() {
        return (
        
            <div class="container">
                <div class="row">
                    <div class="col-sm-8">
                        <div class="card">
                            <h3 style={{ color: '#5b9aef' }}><span class="glyphicon glyphicon-record" aria-hidden="true"></span> 업무분야</h3>

                            <div class="panel panel-default">
                                <div class="panel-heading cursor" data-toggle="collapse" data-parent="#accordion"
                                    data-target="#collapse-1">
                                    <h4 class="panel-title">
                                        <span style={{ cursor: 'pointer' }} class="blue-font-color">#1 유학 컨텐츠 제작/관리</span>
                                    </h4>
                                </div>
                                <div id="collapse-1" class="panel-collapse collapse in">
                                    <div class="panel-body">
                                        <p>- 대입/학업 경험담 블로그, 영상 (글 교정/편집), 예 <a href="http://www.gurume.kr/blogs/160" target="blank">홍콩과기대 화공 학업 경험담</a></p>
                                        <p>- 미국 대입 책자, 예 <a href="http://www.gurume.kr/admissions_consulting/uk#book-publication" target="blank">"영국 대입에 대한 모든 것”</a></p>
                                        <p>- <a href="http://www.gurume.kr/how_it_works/resources" target="blank">대입 원서 자료실 <i class="fa fa-question-circle hover-shadow cursor blue-font-color"></i></a> (에세이/레쥬메)</p>
                                    </div>
                                </div>
                            </div>

                            <div class="panel panel-default">
                                <div class="panel-heading cursor" data-toggle="collapse" data-parent="#accordion"
                                    data-target="#collapse-2">
                                    <h4 class="panel-title">
                                        <span style={{ cursor: 'pointer' }} class="blue-font-color">#2 MOOC/Question Bank/Study Note 어플</span>
                                    </h4>
                                </div>
                                <div id="collapse-2" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p>- IB/A-Level/AP - Maths, Physics, Chemistry, Biology, Economics</p>
                                        <p>- 2017년까지 과목별 Past paper 문제를 토픽별로 (심지어 keyword/ key concept 별로) 수집</p>
                                        <p>- <a href="http://www.gurume.kr/question_bank_demo" target="blank">모바일 어플 <i class="fa fa-question-circle hover-shadow cursor blue-font-color"></i></a> 출시 예정</p>
                                    </div>
                                </div>
                            </div>

                            <div class="panel panel-default">
                                <div class="panel-heading cursor" data-toggle="collapse" data-parent="#accordion"
                                    data-target="#collapse-3">
                                    <h4 class="panel-title">
                                        <span style={{ cursor: 'pointer' }} class="blue-font-color">#3 마케팅/디자인</span>
                                    </h4>
                                </div>
                                <div id="collapse-3" class="panel-collapse collapse">
                                    <div class="panel-body">
                                        <p><b>*컨텐츠 마케팅 (블로그/영상) - 학생, 학부모님 유저수 증가</b></p>
                                        <p>- 영상 자막 작업</p>
                                        <p>- 중.고등학생, 학부모님들께서 가장 효율적으로 받아볼 수 있는 마케팅 전략 기획</p>
                                        <p>- 카톡/페북 학생 그룹 생성/관리</p>
                                        <p>- 중국어 가능시 <a href="http://mp.weixin.qq.com/s/zL4Zp8DUnItQU915UVOzZA" target="blank">微信公众号 <i class="fa fa-question-circle hover-shadow cursor blue-font-color"></i></a> 업무 가능</p>
                                        <p><b>*페북 마케팅 - 유학출신 선생님 네트워크 강화</b></p>
                                        <p>- 현재 영국/홍콩/국내 출신 선생님 다수; 미국/유럽/호주 출신 선생님 리크룻 필요</p>
                                        <p>-  <a href="http://www.gurume.kr/how_it_works/instant_qa" target="blank">Instant Q&A <i class="fa fa-question-circle hover-shadow cursor blue-font-color"></i></a> 활성화를 1, 2학년 선생님 리크룻</p>
                                    </div>
                                </div>
                            </div>

                            <h3 style={{ color: '#5b9aef' }}><span class="glyphicon glyphicon-record" aria-hidden="true"></span> 이런 분께 적합합니다</h3>
                            <p>- 학생 혹은 선생님으로서 유학원/개인과외 경험과 자기만의 교육 철학이 있있으신 분</p>
                            <p>- 기존의 유학 과외/특강/컨설팅을 넘어 Innovation을 통해 유학업계를 발전 시키고 싶은 분</p>
                            <p>- 후배들에게 학업/대입 이야기와 조언 해주는 걸 뿌듯해하시는 분</p>
                            <p>- 아래 분야에 관심있고 발전시키고 싶은 분</p>
                            <p>Coding (HTML/CSS/Bootstrap/Javascript)</p>
                            <p>Design (PS/AI)</p>
                            <p>Film Editing (Final Cut/Premier Pro)</p>
                            <p>Marketing (Youtube/Facebook/Plus ID/Wechat)</p>

                            <h3 style={{ color: '#5b9aef' }}><span class="glyphicon glyphicon-record" aria-hidden="true"></span> 지원절차</h3>
                            <p>- CV와 함께 아래 인턴쉽 가능기간/관심 업무분야/유학교육 업계에서 실현해보고 싶은 부분에 대해 support@gurume.kr로 이메일 주세요</p>
                            <p>- 스카입 면접을 통해 서로 알아가는 시간을 가집니다</p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <a href="/#/about_gurume">
                            <div class="card">
                                <center>
                                    <h4><span class="glyphicon glyphicon-user" aria-hidden="true"></span> 그루미 팀</h4>
                                </center>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}
class RecruitingBanner extends Component {
    render() {
        return (
            <div className="recruiting-banner">
                <div className="recruiting-banner-box">
                    <div className="recruiting-banner-main">
                        <h1 className="boldest-font">Ed-tech 여름 인턴쉽</h1>
                        <p>
                            <i>"유학교육 커뮤니티 (IT 스타트업) 에서 자신만의 교육 혁신 아이디어 기획/실현"</i>
                        </p>
                        <div className="recruiting-banner-table">
                            <div className="recruiting-banner-td-left">
                                <p>기간</p>
                                <p>근무시간</p>
                                <p>월급</p>
                                <p>사무실</p>
                                <p>모집인원</p>
                            </div>
                            <div className="recruiting-banner-td-right">
                                <p>6월-9월 중 1~2달 선택</p>
                                <p>월-금 10AM-7PM (조율가능)</p>
                                <p>면접 후 결정</p>
                                <p>그루미 새로운 사무실 @교대 혹은 선릉역</p>
                                <p>3명</p>
                            </div>
                        </div>
                        <p>*기간 및 근무시간은 유동적으로 조율 가능합니다.</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default class Recruiting extends Component {
    render() {
        return (
            <div className="recruiting-wrapper">
                <RecruitingBanner />
                <RecruitingContent />
            </div>
        )
    }
}