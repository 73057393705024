import React, { Component } from 'react';
import { withRouter } from 'react-router';

import {
    TrasHome,
    TrasDashboard,
    TrasTuitionBill,
    TrasPleasemakesurethatyouhaveagreedonthefollowingtuitionconditionswiththestudentparent,
    TrasAftersubmissionyouwillbegivenatuitionbilllinkwhichyouneedtogiveyourstudentparent,
    TrasStudentname,
    TrasCurriculumSubject,
    TrasTuitionMode,
    // TrasDemoClass
    TrasOnline,
    TrasFacetoface,
    TrasSNSMentoring,
    TrasStartingEndDate,
    TrasMonthlyorforagreedperiodoftimeiflessthanamonth,
    TrasHourlyRate,
    TrasTotalHours,
    TrasIfitisademoclassitwillbereducedby05hoursfromthetotaltime,
    TrasTuitionFee,
    TrasParentPays,
    TrasTutorReceives,
    TrasCommissionExplained,
    TrasSubmit,
    TrasPosting,
    TrasNonotice,
    TrasCredit,
    TrasPaymentMethod,
    TrasBankTransfer
} from '../../../Translate/EachTranslateC';


import { Button } from 'semantic-ui-react';
import Calendar from 'react-calendar'
import ReactTooltip from 'react-tooltip';

import axios from '../../../config/axios';
import queryString from 'query-string';

import { GetAjaxURL, GetAjaxURLFileUpload, multipartForm, postConfig, copyToClipboard, clipMsgState } from '../../../config/AjaxConfig';
import { STATE_NOTIFY, showNotify } from '../../extra/cusUtil';


import '../../../default.css';
import './tuitions_bill.css';

class Tuitions_bill extends Component {


    state = {
        isEdit: false,
        isCopy: false,
        isAdmin: false,
        tempData: { modeArr: [], orderNum: '' },
        userType: '',
        teacherid: -1,
        lang: 'ko',
        params: {
            teacherId: -1,                                 // number
            curriculums: '',                               // check format 46$38  get From 'subjectSelectList'
            // start_time : '',                             // MOVE TO onSubmit
            // end_time : '',                               // MOVE TO onSubmit
            // currency : '',                               // MOVE TO onSubmit
            hourly_rate: '',                               // Math.round($scope.newhourlyRate);              
            // $scope.DEFAULT_NEW_HOURLY_RATE = 50000;
            // $scope.newhourlyRate = $scope.DEFAULT_NEW_HOURLY_RATE;
            demo: false,                                   // $scope.demo == true ? 1 : 0;
            hours: '',                                     // $scope.postHour - ($scope.demo ? 0.5 : 0);
            studentName: '',                               // string
            total_amount: '',                              // Math.round(postChoose.hours * postChoose.hourly_rate);
            // mode : '',                                   // MOVE TO onSubmit
            // lang : this.state.lang
            is_short_term: false,                             // $scope.isShortTerm ? '1' : '0';
            is_package_tuition: false,                        // $scope.isPackageTuition ? '1' : '0';
            is_academy: false,                               // 0: is not
            report: '',
            is_credit: false,
        },

        modeIsOnline: false,
        modeIsOffline: false,
        modeIsKakaoMeto: false,

        newhourlyRate: 0,
        gurumeRate: 10000,         // DEFAULT
        newCurrency: 2,            // DEFAULT
        toCredit: 1190,            // DEFAULT

        validations: {
            needStudentName: false,                        // True
        },

        subjectTeachingListFromProfile: {},
        subjectSelectList: [],
        isSubjectSelected: false,
        isDoneTuitionProfile: false,

        SUBJECT_SELECT_TYPE: {
            TUITIONS_BILL: 'TUITIONS_BILL',
        },

        // For className ( CSS ) START
        SUBJECT_TYPES: {
            ADMISSION: 'Admission',
            ENGLISH: 'English',
            TEST: 'Test'
        },
        // For className ( CSS ) END

        calendarState: {
            // date : new Date(),
            formattingDate: '',
            msTime: 0,
            formattingDateSec: '',
            msTimeSec: 0,
        },
        eventState: {
            needCalendar: false,
            needCalendarSec: false,
        },
        alertMsgs: {
            init: `&#8251; 이용료 1만원, 3만원, 10만원을 확인해주세요.`
        },

        isSubmitting: false,
    }


    componentDidMount = async () => {

        const allUrls = await GetAjaxURL();
        const isLoginUrl = allUrls.isLogin;
        const isLoginRes = await axios.get(isLoginUrl).catch((err) => { return err; });

        if (isLoginRes.data.isLogin === 'false') {
            let msg = '로그인 후 이용이 가능합니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/';
        } else {

            if (isLoginRes.data.type !== 'tutor') {
                let msg = '선생님 회원만 이용이 가능합니다.';
                showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
                return window.location.href = '/#/';
            }
            let msg = this.state.alertMsgs.init;
            showNotify(msg, STATE_NOTIFY.TYPE_INFO);
        }

        const ordernum = this.props.match.params.ordernum;

        if (ordernum) {
            this.initEditAjaxData(ordernum);
        } else {
            this.initAjaxData();
        }
    }

    initEditAjaxData = async (_ordernum) => {
        const ordernum = _ordernum;

        const allUrls = await GetAjaxURL();
        const tuitionUrl = allUrls.tuition;
        const fullTuitionUrl = tuitionUrl + '/' + ordernum;

        // For Curriculums START
        const thisUser = allUrls.apiThisUser;
        const curriculumsUrl = allUrls.curriculums;




        let userData = await axios.get(thisUser).catch((err) => { console.log(err) });
        let isAdmin = false;
        const data = userData.data;


        const curriculumsData = await axios.get(curriculumsUrl).catch((err) => { return console.log(err) });
        // For Curriculums END


        const resp = await axios.get(fullTuitionUrl).catch(err => { return console.error(err) });
        const reData = resp.data;



        const resId = reData.id;
        const created_at = reData.created_at;
        const updated_at = reData.updated_at;
        const student_id = reData.student_id;
        const student_name = reData.student_name;
        const teacherId = reData.tutor_id;
        const curriculums = reData.curriculums;
        const start_time = reData.start_time;
        let end_time = reData.end_time;
        const hourly_rate = reData.hourly_rate;
        const hours = reData.hours;
        const total_amount = reData.total_amount;
        const status = reData.status;
        const likeCount = reData.like;
        const review = reData.review;
        const modeArr = reData.mode;
        const currencyType = reData.currency;
        const student_currency = reData.student_currency;
        const student_total_amount = reData.student_total_amount;
        const tutor_total_amount = reData.tutor_total_amount;
        const demo = reData.demo;
        const to_admin = reData.to_admin;
        const is_short_term = reData.is_short_term;
        const is_package_tuition = reData.is_package_tuition;
        const is_academy = reData.is_academy;
        const pay_with_credit = reData.pay_by_credit > 0 ? true : false;
        const student_credit = reData.student_remaining_credit;

        const gurume_rate = reData.usage_fee;

        const transfer_tutor_datetime = reData.transfer_tutor_datetime;
        const refund = reData.refund;
        const penalty = reData.penalty;
        const tuition_change_note = reData.tuition_change_note;


        if (data.user.id == 14) {
            isAdmin = true;
        }

        if (isAdmin) {
            const thatUser = allUrls.apiThatUser + '/' + teacherId;
            userData = await axios.get(thatUser).catch((err) => { console.log(err) });
        }


        let reCuls = '';
        for (let i = 0; i < curriculums.length; i++) {
            reCuls += curriculums[i].id + '$';
        }
        reCuls.slice(0, reCuls.lastIndexOf());

        const reDemo = demo === 1 ? true : false;
        // const reHours = reDemo === true ? 0.5 : 0;
        let reHours = Number(hours);

        if (reDemo) {
            reHours += 0.5;
        }


        const reTotalAMount = Math.round(reHours * hourly_rate);
        const reIsShortTerm = is_short_term === 1 ? true : false;
        const reIsPackageTuition = is_package_tuition === 1 ? true : false;
        const reIsAcademy = is_academy === 1 ? true : false;


        // TEACHER TEACHING MAJORS

        if (!userData || !userData.data) {
            let msg = '서버에 문제가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const uData = userData.data.profile;
        const curData = curriculumsData.data;

        const newhourlyRate = uData['salary'];

        // Selected Curriculms
        const teachingMajor = uData.teaching_major;
        const subjectTeachingListFromProfile = this.getTeachingListFromIt(teachingMajor, curData);
        const allKeysOfSubj = Object.keys(subjectTeachingListFromProfile);
        const newSelectedData = [];
        for (let i = 0; i < allKeysOfSubj.length; i++) {
            for (let k = 0; k < curriculums.length; k++) {
                if (String(subjectTeachingListFromProfile[allKeysOfSubj[i]]['keyInOrgin']) === String(curriculums[k]['id'])) {
                    newSelectedData.push(subjectTeachingListFromProfile[allKeysOfSubj[i]]);
                }
            }
        }

        if (reDemo) {
            end_time = '';
        }


        // 에딧이 트루면 카피는 펄스. 카피가 트루면 에딧이 펄스. 둘 중 하나는 트루.
        let isEdit = true;
        let isCopy = false;
        if (window.location.href.indexOf('copy') !== -1) {
            isCopy = true;
            isEdit = false;
        }



        this.setState({
            ...this.state,
            isEdit: isEdit,
            isCopy: isCopy,
            isAdmin: isAdmin,
            gurumeRate: gurume_rate,
            teacherid: teacherId,
            tempData: {
                modeArr,
                orderNum: ordernum
            },
            calendarState: {
                formattingDate: start_time,
                formattingDateSec: end_time
            },
            params: {
                teacherId: teacherId,
                curriculums: reCuls,
                start_time: start_time,
                end_time: end_time,
                // currency : '',                               // MOVE TO onSubmit
                hourly_rate: hourly_rate,
                demo: reDemo,
                hours: reHours,
                studentName: student_name,
                total_amount: reTotalAMount,
                // mode : '',                                   // MOVE TO onSubmit
                // lang : this.state.lang
                is_short_term: reIsShortTerm,
                is_package_tuition: reIsPackageTuition,
                is_academy: reIsAcademy,
                pay_with_credit: pay_with_credit,
                student_credit: student_credit,
            },
            subjectTeachingListFromProfile,
            subjectSelectList: [
                ...newSelectedData
            ],
            newhourlyRate: hourly_rate
        }, () => {
            if (this.state.params.demo) {
                const tu_demo = document.getElementById('tu_demo');
                tu_demo.checked = this.state.params.demo;
                const formattingDateSec = document.getElementById('formattingDateSec');
                formattingDateSec.disabled = this.state.params.demo;
            }
            this.displaySelectedItems();
            this.setInitTuition();
            this.doCheckForMode();

            this.props.RootBindingData.doLoading(false);
        })

    }


    initAjaxData = async () => {
        const allUrls = await GetAjaxURL();

        const curriculumsUrl = allUrls.curriculums;


        let userData = ''
        let isAdmin = false;

        if (window.location.href.indexOf('admin') !== -1) {
            isAdmin = true;
            const apiurls = await GetAjaxURL();
            const url = apiurls.apiThisUser;
            userData = await axios.get(url).catch((err) => { return err; });
            const data = userData.data;

            if (data.status == 'fail' || data.user.id != 14) {
                window.location.href = "/#";
            }
        }
        if (isAdmin) {
            const thatUser = allUrls.apiThatUser + '/' + this.props.match.params.teacherid;
            userData = await axios.get(thatUser).catch((err) => { console.log(err) });
        } else {
            const apiurls = await GetAjaxURL();
            const url = apiurls.apiThisUser;
            userData = await axios.get(url).catch((err) => { return err; });
        }


        const curriculumsData = await axios.get(curriculumsUrl).catch((err) => { return console.log(err) });

        if (!userData || !userData.data) {
            let msg = '서버에 문제가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const uData = userData.data.profile;
        const curData = curriculumsData.data;

        const newhourlyRate = uData['salary'];

        const teachingMajor = uData.teaching_major;
        const subjectTeachingListFromProfile = this.getTeachingListFromIt(teachingMajor, curData);




        this.setState({
            ...this.state,
            isAdmin: isAdmin,
            teacherid: this.props.match.params.teacherid,
            subjectTeachingListFromProfile,
            isSubjectSelected: false,
            isDoneTuitionProfile: true,
            newhourlyRate: newhourlyRate,
        }, () => {
            this.setInitTuition();
            this.props.RootBindingData.doLoading(false);
        });

    }

    getTeachingListFromIt = (teachingMajor, curData) => {

        const subjectTeachingListFromProfile = {};

        for (const eachMajor of teachingMajor) {
            let selectMajorTmp, selectSubjectTmp, subjectSelectsTmp;
            switch (eachMajor.test_type) {
                case '1':
                    selectMajorTmp = 'Admission';
                    break;
                case '2':
                    selectMajorTmp = 'Test';
                    break;
                case '3':
                    selectMajorTmp = 'English';
                    break;
            }

            selectSubjectTmp = eachMajor.curriculum_name;
            subjectSelectsTmp = curData[selectMajorTmp][selectSubjectTmp];
            const subjectNamesTmp = eachMajor.subject_name;

            for (let i = 0; i < subjectNamesTmp.length; i++) {
                const key = subjectNamesTmp[i].id;

                subjectTeachingListFromProfile[key] = {
                    Major: selectMajorTmp,
                    Subject: selectSubjectTmp,
                    Title: subjectNamesTmp[i].name,
                    keyInOrgin: key
                };
            }
        }
        return subjectTeachingListFromProfile;
    }





    setInitTuition = () => {
        const t_shortTerm = document.getElementById('t_shortTerm');
        const t_package = document.getElementById('t_package');
        const t_academy = document.getElementById('t_academy');

        if (t_shortTerm) {
            t_shortTerm.checked = this.state.params.is_short_term;
        }
        if (t_package) {
            t_package.checked = this.state.params.is_package_tuition;
        }
        if (t_academy) {
            t_academy.checked = this.state.params.is_academy;
        }

        const hours = document.getElementById('hours');
        if (this.state.params.is_package_tuition) {
            hours.disabled = true;
        } else {
            hours.disabled = false;
        }
    }



    doCheckForMode = () => {

        const mode = this.state.tempData.modeArr;

        const online = {
            keys: 'modeIsOnline',
            target: {
                value: '1',
                checked: true
            }
        };
        const offline = {
            keys: 'modeIsOffline',
            target: {
                value: '2',
                checked: true
            }
        };
        const snsMento = {
            keys: 'modeIsKakaoMeto',
            target: {
                value: '3',
                checked: true
            }
        };


        const sendValues = [];

        for (let i = 0; i < mode.length; i++) {
            if (mode[i] === '1') {
                // online
                const tu_online = document.getElementById('tu_online');
                tu_online.checked = true;
                sendValues.push(online);
            } else if (mode[i] === '2') {
                // offline
                const tu_offline = document.getElementById('tu_offline');
                tu_offline.checked = true;
                sendValues.push(offline);
            } else if (mode[i] === '3') {
                // snsMento
                const tu_snsMento = document.getElementById('tu_snsMento');
                tu_snsMento.checked = true;
                sendValues.push(snsMento);
            }
        }
        this.setInitModes(sendValues);
    }

    setInitModes = (_modeArr) => {

        const modeArr = _modeArr;

        const setVal = {};
        for (let i = 0; i < modeArr.length; i++) {
            const eachKey = Object.keys(modeArr[i]);
            if (eachKey[0] === 'keys') {
                const val = modeArr[i][eachKey[0]];
                setVal[val] = true;
            }
        }

        this.setState({
            ...this.state,
            ...setVal
        })

    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li>
                        <a href="/#/"><TrasHome /></a>
                    </li>
                    <li>
                        <a href="/#/teachers/dashboard"><TrasDashboard /></a>
                    </li>
                    <li className="current"><TrasTuitionBill /></li>
                </ol>
            </div>
        )
    }


    createPartA = () => {
        return (
            <div>
                <p>
                    <button
                        id='watchup'
                        className="btn btn-primary"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="true"
                        aria-controls="collapseExample">
                        ※ 발행 전 꼭 보세요!
                    </button>
                </p>
                <div className="collapse in" id="collapseExample">
                    <div className="card-body">
                        ※ 그루미 이용료를 포함하지 않은 수업료를 안내할 경우, 다시 그루미와 수업료를 정정합니다.<br />
                        다만 수업료 인상으로 인해 학생측에서 수업 진행을 거부하여 수업료 변동이 불가피할 경우 선생님 수업료에서 이용료를 공제합니다.
                        <br /><br />
                        <table className="table table-bordered border-default-1" width="60%">
                            <thead className="text-center" >
                                <tr>
                                    <td width="30%" className='gurume-blue-tuitions'><strong style={{ fontSize: '15px' }}>3 만원 책정</strong></td>
                                    <td width="30%" className='gurume-blue-tuitions'><strong style={{ fontSize: '15px' }}>1 만원 책정</strong></td>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                <tr>
                                    <td className='text-aline-left'>
                                        <label>숏 노티스</label><p> 수업 시작일로부터 3일 전에 수업문의가 들어온 경우 (3일전, 2일전, 1일전 모두 포함)</p>
                                        <label>단기</label><p> 10회 미만의 수업</p>
                                        <label>과제</label><p> IA, EE, 실험 보고서, 문학 분석 에세이와 같은 과제를 완성할 수 있게 도움</p>
                                        <label>매니징</label><p> 그루미 스태프가 직접 선생님과 학생 혹은 학부모님을 단톡방으로 연결</p>
                                    </td>
                                    <td>
                                        그루미 스태프의 개입 없이 사이트 쪽지를 통해 성사되었고 10회 이상 진행
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className='gurume-blue-tuitions'>
                                        <strong style={{ fontSize: '15px' }}>10 만원 책정</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2" className='text-aline-left'>
                                        <label>일회성 수업/상담</label><p>1회만 진행</p>
                                        <label>패키지</label><p> 수업 없이 진행되는 EE 첨삭과 같이 시간을 책정하기 어려워 문의에 자체에 비용을 책정하는 경우(수업 없이 EE 초고 첨삭 문의 등), 컨설팅과 같이 학생이 해야하는게 패키지로 정해져 있는 경우</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }




    // IT'S BETTER TO ORGANIZE LATER.
    // AFTER TUITIONS PART, I WILL Editteacherprofile.js FINISH UNTIL THIS WEEKEND.
    getSubmitValues = () => {

        ////////////////////////
        // validation checker //
        ////////////////////////

        if (!this.state.params.teacherId) {
            let msg = 'SERVER A ERROR';
            window.location.href = '/#/';
            return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (!this.state.params.studentName) {
            return showNotify("학생 이름을 입력해 주세요.", STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (this.state.subjectSelectList.length === 0) {
            let msg = '커리큘럼을 선택해주세요.';
            return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (!this.state.modeIsKakaoMeto && !this.state.modeIsOffline && !this.state.modeIsOnline) {
            let msg = '수업 모드를 선택해주세요.';
            return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }
        if (this.state.params.demo) {
            if (!this.state.calendarState.formattingDate) {
                let msg = '날짜를 선택해주세요.';
                return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            }
        } else {
            if (!this.state.calendarState.formattingDate || !this.state.calendarState.formattingDateSec) {
                let msg = '날짜를 선택해주세요.';
                return showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            }
        }
        // if (startTime > endTime) {
        //     return showNotify("시작일이 마지막날 보다 늦을 수 없습니다.", STATE_NOTIFY.TYPE_NEGATIVIE);
        // }

        if (this.state.params.hours === undefined && this.state.params.hours === 0 || this.state.params.hours === '') {
            return showNotify("수업시간을 입력해주세요.", STATE_NOTIFY.TYPE_NEGATIVIE);
        }



        ////////////////////////
        // validation checker //
        ////////////////////////



        // PUT DATA STUDENT NAME START
        const student_name = this.state.params.studentName;
        // PUT DATA STUDENT NAME END

        // PUT DATA CURRICULUMS START
        const selectedList = this.state.subjectSelectList;
        const curriculums = [];
        for (let i = 0; i < selectedList.length; i++) {
            curriculums.push(selectedList[i]['keyInOrgin']);
        }
        // PUT DATA CURRICULUMS END

        // PUT DATA mode START
        const tempModeArr = [];


        // ORDER CHAGNE
        // ONLINE 1, OFFLINE 2, KAKAO METOLING 3 ARE CORRECT
        // const CONST_DEMO = '1';
        const CONST_ONLINE = '1';
        const CONST_OFFLINE = '2';
        const CONST_KAKAO_METOLING = '3';

        // ONLINE - TrasOnline
        if (this.state.modeIsOnline) {
            tempModeArr.push(CONST_ONLINE);
        }
        if (this.state.modeIsOffline) {
            tempModeArr.push(CONST_OFFLINE);
        }
        if (this.state.modeIsKakaoMeto) {
            tempModeArr.push(CONST_KAKAO_METOLING);
        }

        const mode = tempModeArr.join('$');
        // PUT DATA mode END



        // PUT DATA start_time START
        // ** gurume-red ** //
        // IF start_time = end_time, end_time WILL PLUS A DAY cuz backend CHECK VALIDATION.
        // TEMPORARY, LET USERS KNOW THIS INFO WITH <p className='gurume-red'> ~~~~ 

        const start_time = this.state.calendarState.formattingDate;
        let end_time;
        let mod_end_time;
        const OneDaysGap = 86400000;
        const spDate = start_time.split('-');
        let tempDate = new Date(Date.UTC(spDate[0], spDate[1], spDate[2], 0, 0, 0));
        let afterOneDay = tempDate.getTime() + OneDaysGap;
        let newEndDate = new Date(afterOneDay);
        mod_end_time = newEndDate.getFullYear() + '-' + newEndDate.getMonth() + '-' + newEndDate.getDate();


        if (this.state.params.demo) {
            end_time = mod_end_time;
        } else {
            end_time = this.state.calendarState.formattingDateSec;
            if (start_time === end_time) {
                end_time = mod_end_time;
            }
        }
        // PUT DATA start_time END


        // PUT DATE currency START
        const currency = this.state.newCurrency;
        // PUT DATE currency END

        const hourly_rate = this.state.newhourlyRate;

        const hours = this.state.params.hours - (this.state.params.demo ? 0.5 : 0);

        const postChoose = {
            tutor_id: this.state.params.teacherId,

            studentName: this.state.params.studentName,

            curriculums: curriculums.join('$'),
            start_time: start_time,
            end_time: end_time,
            currency: currency,
            hourly_rate: hourly_rate,
            demo: this.state.params.demo ? 1 : 0,
            hours: hours,
            //                total_amount : this.state.params.total_amount,
            total_amount: hours * hourly_rate,
            mode: mode,
            lang: this.state.lang,
            is_short_term: this.state.params.is_short_term ? '1' : '0',
            is_package_tuition: this.state.params.is_package_tuition ? '1' : '0',
            is_academy: this.state.params.is_academy ? '1' : '0',
            teacherid: this.state.teacherid,
            gurumeRate: this.state.gurumeRate,
            report: this.state.params.report,
            pay_with_credit: (this.state.params.pay_with_credit && this.state.params.is_academy) ? 1 : 0,
        }

        return postChoose;


    }




    doSubmit = async () => {

        const postChoose = this.getSubmitValues();

        if (!postChoose) return;

        this.setState({
            ...this.state,
            isSubmitting: true,
        });

        const allUrls = await GetAjaxURL();

        const tuitionsEdit = allUrls.tuitionsEdit;
        const tuitionCopy = allUrls.tuitionsCopy;
        const tuitionsNewUrl = allUrls.tuitionsNew;
        const postData = queryString.stringify(postChoose);

        // Define Here For Action Type
        let rightUrl = tuitionsNewUrl;
        if (this.state.isEdit) {
            rightUrl = tuitionsEdit + '/' + this.state.tempData.orderNum;
        }
        if (this.state.isCopy) {
            rightUrl = tuitionCopy + '/' + this.state.tempData.orderNum;
        }

        rightUrl += '?val=1';
        const urlWithData = rightUrl + '&' + postData;
        const res = await axios.post(urlWithData, {}, postConfig).catch((err) => { return console.log(err) });


        if (res.data.status == 'success') {
            const lang = this.state.lang;
            showNotify('cn' == lang ? "发布成功,请将以下地址发送给学生：www.gurume.kr/tuitions/confirm/" + res.data.ordernum :
                'en' == lang ? "Link creation complete! Please click on the Tuition Fee Invoice link on the next page." :
                    "인보이스가 생성되었으며, 내 클립보드에 내용이 복사되었습니다.", STATE_NOTIFY.TYPE_POSITIVE);

            // function for execCommand
            const gurumeUrlTutionConfirmUrl = clipMsgState.gurumeUrlTutionConfirmUrl;
            const invoiceLinkCopy = clipMsgState.invoiceLinkCopy;
            copyToClipboard(gurumeUrlTutionConfirmUrl + res.data.ordernum + invoiceLinkCopy);

            if (!this.state.isAdmin) {
                window.location.href = '/#/dashboard';
            } else {
                window.location.href = '/#/tuition/invoice?redirect=' + this.state.teacherid;
            }
        } else {
            showNotify(res.data.msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }


        this.setState({
            ...this.state,
            isSubmitting: false,
        });
    }


    createSubmittingBtns = () => {
        return (
            <div>
                {/* <a href='/#/site_usage' target="blank">
                    <p>*<TrasCommissionExplained /> </p>
                </a> */}

                {
                    this.state.params.studentName.length === 0 ?
                        // <div className="alert alert-danger">
                        //     <span>
                        //         학생 이름을 입력해 주세요.
                        //     </span>
                        // </div>
                        ''
                        : ''
                }

                <div className='tuitions_bill_btns'>
                    <div>
                        {!this.state.isSubmitting ?
                            <button
                                type="button"
                                className="btn center-block submit-btn"
                                onClick={this.doSubmit}
                                // onClick={this.againSubmit}
                                id="newSubmit">
                                <TrasSubmit />
                            </button>
                            :
                            <button
                                className="btn center-block btn-processing btn-processing-outline"
                                disabled="disabled"
                            >
                                <font className="loading">
                                    <TrasPosting />
                                </font>
                            </button>
                        }

                    </div>
                </div>
            </div>
        )
    }





    // START / END DATE - START

    calendarFormat = (_date) => {
        // format : new Date();
        const date = _date;
        const yyyy = date.getFullYear().toString();
        const mm = (date.getMonth() + 1).toString();
        const dd = date.getDate().toString();

        return yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0]);

    }

    showCalendarPannel = () => {
        this.setState({
            ...this.state,
            eventState: {
                ...this.state.eventState,
                needCalendar: !this.state.eventState.needCalendar
            }
        }, () => {

        });
    }

    calendarOnChange = (date) => {
        const dateFormatting = this.calendarFormat(date);
        this.setState({
            ...this.state,
            calendarState: {
                ...this.state.calendarState,
                formattingDate: dateFormatting,
                msTime: date.getTime()
            }
        }, () => {
            this.showCalendarPannel();
        });
    }

    showCalendarPannelSec = () => {

        if (this.state.params.demo) return showNotify("데모수업에는 끝나는 날짜를 선택할 수 없습니다.", STATE_NOTIFY.TYPE_POSITIVE);

        this.setState({
            ...this.state,
            eventState: {
                ...this.state.eventState,
                needCalendarSec: !this.state.eventState.needCalendarSec
            }
        }, () => {
        });
    }

    calendarOnChangeSec = (date) => {
        const dateFormatting = this.calendarFormat(date);
        const msTimeSec = date.getTime();

        if (this.state.calendarState.msTime > msTimeSec || this.state.calendarState.msTime === 0) {
            let msg = '시작 날짜가 끝나는 날짜보다 늦을 수 없습니다. 시작 날짜를 다시 설정해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        this.setState({
            ...this.state,
            calendarState: {
                ...this.state.calendarState,
                formattingDateSec: dateFormatting,
                msTimeSec: msTimeSec
            }
        }, () => {
            this.showCalendarPannelSec();
            let msg = '날짜가 설정되었습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
        });
    }

    // START / END DATE - END


    // callCalenders
    callCalenders = () => {

        return (
            <div className="tuitions_bill-calender-wapper">
                <div className="tuitions_bill-calender-innerwapper">
                    <div className='tuitions_bill-part-b-calendar'>
                        <Button basic onClick={this.showCalendarPannel} > <i className="fa fa-calendar"></i> </Button>
                        <span className='tuitions_bill-part-b-input-span'>
                            <input type="text" className="form-control" placeholder="yyyy/mm/dd" id="formattingDate"
                                value={`${this.state.calendarState.formattingDate}`}
                                onChange={() => { console.log('temp') }}
                            // onChange={this.funcMemoWithCal}
                            />
                        </span>

                    </div>
                    <div className='tuitions_bill-part-b-line-fixer'>
                        {
                            this.state.eventState.needCalendar ?
                                <Calendar
                                    onChange={this.calendarOnChange}
                                    value={new Date()}
                                />
                                : ''
                        }
                    </div>

                </div>
                <div className="tuitions_bill-calender-innerwapper">
                    <div className='tuitions_bill-part-b-calendar'>
                        <Button basic onClick={this.showCalendarPannelSec} > <i className="fa fa-calendar"></i> </Button>
                        <span className='tuitions_bill-part-b-input-span'>
                            <input type="text" className="form-control" placeholder="yyyy/mm/dd" id="formattingDateSec"
                                value={`${this.state.calendarState.formattingDateSec}`}
                                onChange={() => { console.log('temp') }}
                            />
                        </span>
                    </div>
                    <div className='tuitions_bill-part-b-line-fixer'>
                        {
                            this.state.eventState.needCalendarSec ?
                                <Calendar
                                    onChange={this.calendarOnChangeSec}
                                    value={new Date()}
                                />
                                : ''
                        }
                    </div>
                </div>
                <p className='gurume-red'>시작하는 날짜와 끝나는 날짜가 같을 경우, 자동으로 끝나는 날짜가 하루 추가됩니다.</p>
            </div>
        )
    }



    // SETTING TO onSubmit()
    setCheckBoxes = (e) => {
        const target = e.target;
        const value = target.value;

        let demo = '';
        const teachModal = [];


        // DEMO CLASS
        if (value === '1') {

            let formattingDateSec = '';
            let msSec = 0;

            if (!target.checked) {
                formattingDateSec = this.state.calendarState.formattingDateSec;
                msSec = this.state.calendarState.msTimeSec;
            }


            this.setState({
                ...this.state,
                params: {
                    ...this.state.params,
                    demo: target.checked,
                },
                calendarState: {
                    ...this.state.calendarState,
                    formattingDateSec: formattingDateSec,
                    msTimeSec: msSec
                }
            }, () => {
                const formattingDateSec = document.getElementById('formattingDateSec');
                formattingDateSec.disabled = this.state.params.demo;
            });
            return;
        }

        // ONLINE - TrasOnline
        if (value === '2') {
            // modeIsOnline : false,
            this.setState({
                ...this.state,
                modeIsOnline: target.checked
            }, () => {
            });
            return;
        }
        // OFFLINE - TrasFacetoface
        if (value === '3') {
            this.setState({
                ...this.state,
                modeIsOffline: target.checked
            }, () => {
            });
            return;
        }
        // KAKAO METOLING - TrasSNSMentoring
        if (value === '4') {
            this.setState({
                ...this.state,
                modeIsKakaoMeto: target.checked
            }, () => {
            });
            return;
        }

    }


    createCheckboxes = () => {
        return (
            <div className='tuitions_bill-part-b-grid marginLeft3'>
                <ul>
                    <li>
                        <label className="container-check tutorreg-font-sz-14">
                            <input id='tu_demo' type="checkbox" value='1' onClick={this.setCheckBoxes} />
                            {/* Demo className */}
                            &nbsp; 데모수업
                            <span className="default-checkmark"></span>
                        </label>
                    </li>
                    <li>
                        <label className="container-check tutorreg-font-sz-14">
                            <input id='tu_online' type="checkbox" value='2' onClick={this.setCheckBoxes} />
                            &nbsp; <TrasOnline />
                            <span className="default-checkmark"></span>
                        </label>
                    </li>
                    <li>
                        <label className="container-check tutorreg-font-sz-14">
                            <input id='tu_offline' type="checkbox" value='3' onClick={this.setCheckBoxes} />
                            &nbsp; <TrasFacetoface />
                            <span className="default-checkmark"></span>
                        </label>
                    </li>
                    {/* <li>
                        <label className="container-check tutorreg-font-sz-14">
                            <input id='tu_snsMento' type="checkbox" value='4' onClick={this.setCheckBoxes} />
                            &nbsp; <TrasSNSMentoring />
                            <span className="default-checkmark"></span>
                        </label>
                    </li> */}
                </ul>
            </div>
        )
    }




    setCurrency = (e) => {
        const value = e.target.value;

        let gurumeRate = 10000;
        let newCurrency = 2;
        let toCredit = 1190;

        if (value == 'KRW') {
            gurumeRate = 10000;
            newCurrency = 2;
            toCredit = 1190;
        }
        if (value == 'RMB') {
            gurumeRate = 58;
            newCurrency = 3;
            toCredit = 6.9;
        }
        if (value == 'GBP') {
            gurumeRate = 6.72;
            newCurrency = 1;
            toCredit = 0.8;
        }

        this.setState({
            ...this.state,
            gurumeRate, newCurrency, toCredit
        }, () => {
        })
    }
    setNewhourlyRate = (e) => {
        let value = e.target.value;


        if (value === '') value = 0;


        this.setState({
            ...this.state,
            newhourlyRate: Math.round(value)
        }, () => {
        });

    }


    checkShorTerms = () => {

        const t_package = document.getElementById('t_package');
        if (!t_package.checked) return;

        showNotify('패키지/일회성 과제가 선택되었을 시에는 숏노티스/단기/과제/매니징이 선택되지 않습니다.', STATE_NOTIFY.TYPE_POSITIVE);
    }

    setShortTerm = (e) => {
        const target = e.target;

        let is_package_tuition = this.state.params.is_package_tuition;
        let is_academy = this.state.params.is_academy;
        if (target.checked) {
            is_package_tuition = false;
            is_academy = false;
        }

        this.setState({
            ...this.state,
            gurumeRate: 10000,
            params: {
                ...this.state.params,
                is_short_term: target.checked,
                is_package_tuition: is_package_tuition,
                is_academy: is_academy,
            }
        }, () => {
            this.setInitTuition();
        })
    }

    setPackageTuition = (e) => {
        const target = e.target;

        let is_short_term = this.state.params.is_short_term;
        let is_academy = this.state.params.is_academy;
        if (target.checked) {
            is_short_term = false;
            is_academy = false;
        }

        this.setState({
            ...this.state,
            gurumeRate: 10000,
            params: {
                ...this.state.params,
                is_short_term: is_short_term,
                is_academy: is_academy,
                is_package_tuition: target.checked,
                hours: 1,
            }
        }, () => {
            this.setInitTuition();
        })
    }


    setAcademyTuition = (e) => {
        const target = e.target;


        let is_package_tuition = this.state.params.is_package_tuition;
        let is_short_term = this.state.params.is_short_term;
        let g_rate = this.state.gurumeRate;

        if (target.checked) {
            is_package_tuition = false;
            is_short_term = false;
        } else {
            g_rate = 10000;
        }

        this.setState({
            ...this.state,
            gurumeRate: g_rate,

            params: {
                ...this.state.params,
                is_academy: target.checked,
                is_package_tuition: is_package_tuition,
                is_short_term: is_short_term,
                hours: 1,
            }
        }, () => {
            this.setInitTuition();
        })
    }


    createTuitionBillHour = () => {
        const academy_module = [];
        if (this.state.isAdmin) {
            academy_module.push(
                <div>
                    <label className="container-check tutorreg-font-sz-14 marginLeft3">
                        <input type="checkbox" id="t_academy" onChange={this.setAcademyTuition} />
                        &nbsp; 아카데미
                        <span className="default-checkmark"></span>
                    </label>
                </div>
            );
        }
        return (
            <div>
                <div className='tuition_bill-part-b-hour-wapper'>
                    <input
                        type="number"
                        className="form-control"
                        onChange={this.setNewhourlyRate}
                        value={this.state.newhourlyRate}
                    />
                    {/* {
                        this.state.params.is_academy ?
                            this.createUsageFee()
                            : ''
                    } */}
                </div>

                {
                    this.state.newhourlyRate < 10000 ?
                        <small className="help-block color-gurume-red tuition_bill-fix-font-size" >
                            의도한 시급이 정말 {this.state.newhourlyRate} 원이 맞나요? 총 금액을 입력해 주세요.
                    </small>
                        : ''
                }

                {/* <div>
                    <label className="container-check tutorreg-font-sz-14 marginLeft3" onClick={this.checkShorTerms}>
                        <input type="checkbox" id="t_shortTerm" onChange={this.setShortTerm} />
                        &nbsp; 숏노티스/단기/과제/매니징
                        <span className="default-checkmark"></span>

                        <span className="">
                            <i className="fa fa-question-circle color-gurume-red tuitions_bill_short" data-tip
                                data-for='tuitions_bill_short' data-event='mouseover'></i>
                            <ReactTooltip effect="solid" place="bottom" id='tuitions_bill_short' type='light'
                                globalEventOff='mouseout' >
                                <ul>
                                    <li>숏노티스 문의일 경우 그루미에서 저녁 늦게까지 혹은</li>
                                    <li>주말에도 업무를 해서 수업 매칭을 드려야 하기 때문에</li>
                                    <li>이에 대해 3만/시간의 이용료를 받고 있습니다. 또한 </li>
                                    <li>그루미 회사 운영을 위해 수업 매칭 건 별 Expected</li>
                                    <li>수입이 있습니다.</li>
                                    <li>때문에 단기 수업으로 진행될 경우 3만/시간의 이용료를</li>
                                    <li>받고 있습니다. IB 과정 IA/EE/ToK 포함합니다.</li>
                                </ul>
                            </ReactTooltip>
                        </span>

                    </label>
                </div> */}

                {/* <div>
                    <label className="container-check tutorreg-font-sz-14 marginLeft3">
                        <input type="checkbox" id="t_package" onChange={this.setPackageTuition} />
                        &nbsp; 패키지/일회성 과제
                        <span className="default-checkmark"></span>
                        <span className="">
                            <i className="fa fa-question-circle color-gurume-red tuitions_bill_package" data-tip
                                data-for='tuitions_bill_package' data-event='mouseover'></i>
                            <ReactTooltip effect="solid" place="bottom" id='tuitions_bill_package' type='light'
                                globalEventOff='mouseout' >
                                <ul>
                                    <li>시간을 책정하기 어려운 수업의 경우 해당 수업에 대한</li>
                                    <li>총 수업료+그루미 이용료 10만원으로 수업료가 책정됩</li>
                                    <li>니다.(운영 사정을 고려하여 측정된 가격입니다).</li>
                                    <li>대입 상담 포함합니다.</li>
                                </ul>
                            </ReactTooltip>
                        </span>
                    </label>
                </div> */}

                {/* {academy_module} */}

                {/* <div>
                    <label className="tutorreg-font-sz-15">
                        <b>※이용료 책정이 잘못된 경우</b>
                        <span className="">
                            <i className="fa fa-arrow-right color-gurume-red" data-tip
                                data-for='tuitions_bill_wrong_fee' data-event='mouseover'></i>
                            <ReactTooltip effect="solid" place="bottom" id='tuitions_bill_wrong_fee' type='light'
                                globalEventOff='mouseout' >
                                <ul>
                                    <li>그루미 이용료를 포함하지 않은 수업료를 안내할 경우, 다시 그루미와 수업료를 정정합니다.</li>
                                    <li>다만 수업료 인상으로 인해 학생측에서 수업 진행을 거부하여 수업료 변동이 불가피할 경우 </li>
                                    <li>선생님 수업료에서 이용료를 공제합니다.</li>
                                </ul>
                            </ReactTooltip>
                        </span>
                    </label>
                </div> */}
            </div>
        )
    }


    setTHours = (e) => {
        let value = e.target.value;

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                hours: String(value)
            }
        });

    }

    createTHours = () => {
        return (
            <div className='tuition_bill-hours-wrapper'>
                <div className="tuition_bill-hours-div">
                    <input className="form-control" id="hours"
                        type="number"
                        step="0.5"
                        onChange={this.setTHours}
                        value={this.state.params.hours}
                    />
                </div>
                {
                    this.state.params.demo ?
                        <div className="tuition_bill-label-wapper">
                            <p>
                                <span className="tuition_bill-label">
                                    Notice
                                    </span>
                                <TrasIfitisademoclassitwillbereducedby05hoursfromthetotaltime />
                            </p>
                        </div>
                        : ''
                }

            </div>
        )
    }

    setReport = (e) => {
        let value = e.target.value;

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                report: String(value)
            }
        });

    }


    createReport = () => {
        return (
            <div>
                {
                    this.state.isAdmin && this.state.params.is_academy ?
                        <div className='marginBottom30'>
                            <h3 className="blue-font-color marginBottom15">Progress Report</h3>
                            <div className='tuition_bill-hours-wrapper'>

                                <div className="tuition_bill-hours-div">
                                    <input className="form-control" id="report"
                                        onChange={this.setReport}
                                        value={this.state.params.report}
                                    />
                                </div>
                            </div>
                        </div>
                        : ''
                }
            </div>
        );
    }

    setUsageFee = (e) => {
        let value = e.target.value;
        this.setState({
            ...this.state,
            gurumeRate: parseInt(value)
        });
    }

    createUsageFee = () => {
        return (
            <div>
                <div className='tuition_bill-hours-wrapper'>
                    <div className="tuition_bill-hours-div">
                        <input className="form-control" id="gurume_rate"
                            type="number"
                            step="1000"
                            onChange={this.setUsageFee}
                            value={this.state.gurumeRate}
                        />
                    </div>
                </div>

            </div>

        )
    }



    createPaymentCalculation = () => {
        return (
            <div className='tuitions_bill-payment-wapper'>
                <table className="table">
                    <thead>
                        <tr>
                            <td> <TrasParentPays /> </td>
                            <td> <TrasTutorReceives /> </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {
                                this.state.params.demo ?
                                    <td>
                                        {
                                            Math.round(
                                                (this.state.params.demo ?
                                                    (this.state.params.hours != 0) ? (1 * this.state.newhourlyRate * 0.5) + ((this.state.params.hours - 1) * this.state.newhourlyRate)
                                                        + (1 * this.state.gurumeRate * 0.5) + ((this.state.params.hours - 1) * this.state.gurumeRate)
                                                        + ((this.state.params.is_short_term) ? (1 * 0.5 * 20000) + ((this.state.params.hours - 1) * 20000) : 0)
                                                        + ((this.state.params.is_package_tuition) ? 100000 : 0)
                                                        : 0
                                                    : 0
                                                ))
                                        }
                                    </td>
                                    :
                                    <td>
                                        {
                                            Math.round(
                                                (this.state.params.hours * this.state.newhourlyRate +
                                                    (this.state.params.is_package_tuition ? 0 : this.state.params.hours * this.state.gurumeRate))
                                                + (this.state.params.is_package_tuition ? 100000 :
                                                    (-(this.state.params.demo ? this.state.params.hours === 0 ? 0 : (0.5 * this.state.newhourlyRate + 0.5 * this.state.gurumeRate) : 0)
                                                        + (this.state.params.is_short_term ? this.state.params.hours * 20000 : 0)
                                                    )
                                                )
                                            )
                                        }

                                    </td>
                            }


                            <td>
                                {
                                    Math.round(
                                        (this.state.params.hours != 0) ?
                                            (this.state.params.hours * this.state.newhourlyRate)
                                            - (this.state.params.is_package_tuition ?
                                                0
                                                :
                                                (this.state.params.demo ? 0.5 * this.state.newhourlyRate : 0))
                                            : 0
                                    )
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }



    setSelectedList = (e) => {
        const target = e.target;

        const DATA_SET = 'keyInOrgin';

        let findData = target.dataset[DATA_SET];
        let currentTarget = target;

        // GO UP HIGHER NODES IN H STRUCTURE.
        while (!findData) {

            currentTarget = currentTarget['parentNode'];
            findData = currentTarget.dataset[DATA_SET];

            if (findData) break;

        }


        const teachingData = this.state.subjectTeachingListFromProfile;

        if (!teachingData) return;

        const allObjKeys = Object.keys(teachingData);
        const newDataArr = [];
        const compareArr = this.state.subjectSelectList;
        for (let i = 0; i < allObjKeys.length; i++) {
            const originKeyFromData = teachingData[allObjKeys[i]]['keyInOrgin'];

            if (String(originKeyFromData) === String(findData)) {
                if (compareArr.length !== 0) {

                    let chkBool = false;
                    for (let k = 0; k < compareArr.length; k++) {
                        if (String(compareArr[k]['keyInOrgin']) === String(originKeyFromData)) {
                            chkBool = true;
                        }
                    }

                    // PUSH HERE
                    if (!chkBool) {
                        newDataArr.push(teachingData[allObjKeys[i]]);
                    };

                } else {
                    newDataArr.push(teachingData[allObjKeys[i]]);
                }
            }
        }


        this.setState({
            ...this.state,
            // DISPLAY DATA
            subjectSelectList: [
                ...this.state.subjectSelectList,
                ...newDataArr
            ],
        }, () => {
            this.displaySelectedItems();
        })
    }

    displaySelectedItems = () => {

        const tuition_display_subj = document.getElementById('tuition_display_subj');
        const subjectSelectList = this.state.subjectSelectList;

        // Init
        for (let i = 0; i < tuition_display_subj.childElementCount; i++) {
            tuition_display_subj.children[i].classList.remove(`tutorreg-${this.state.SUBJECT_TYPES.TEST}-selected`);
            tuition_display_subj.children[i].classList.remove(`tutorreg-${this.state.SUBJECT_TYPES.ENGLISH}-selected`);
            tuition_display_subj.children[i].classList.remove(`tutorreg-${this.state.SUBJECT_TYPES.ADMISSION}-selected`);
        }


        // Do Action
        for (let i = 0; i < tuition_display_subj.childElementCount; i++) {
            const keyData = tuition_display_subj.children[i].dataset['keyInOrgin'];
            for (let k = 0; k < subjectSelectList.length; k++) {

                if (String(subjectSelectList[k]['keyInOrgin']) === String(keyData)) {
                    for (let j = 0; j < tuition_display_subj.children[i].classList.length; j++) {
                        let CLASS_NAME;
                        if (tuition_display_subj.children[i].classList[j] === this.state.SUBJECT_TYPES.TEST.toLowerCase()) {
                            // TEST
                            CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.TEST}-selected`;
                        } else if (tuition_display_subj.children[i].classList[j] === this.state.SUBJECT_TYPES.ENGLISH.toLowerCase()) {
                            // ENGLISH
                            CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.ENGLISH}-selected`;
                        } else {
                            // ADMISSION
                            CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.ADMISSION}-selected`;
                        }
                        tuition_display_subj.children[i].classList.add(CLASS_NAME);
                    }
                }
            }
        }

    }



    unSelectedList = (e) => {

        const target = e.target;
        const DATA_SET = 'keyInOrgin';

        let findData = target.dataset[DATA_SET];
        let currentTarget = target;

        // GO UP HIGHER NODES IN H STRUCTURE.
        while (!findData) {

            currentTarget = currentTarget['parentNode'];
            findData = currentTarget.dataset[DATA_SET];
            if (findData) break;
        }


        const subjectSelectList = this.state.subjectSelectList;
        const newArr = [];

        for (let i = 0; i < subjectSelectList.length; i++) {
            if (String(subjectSelectList[i][DATA_SET]) !== String(findData)) {

                // PUTTING DATA
                newArr.push(subjectSelectList[i]);
            }
        }


        this.setState({
            ...this.state,
            subjectSelectList: [
                ...newArr
            ]
        }, () => {
            this.displaySelectedItems();
        })


    }


    // SETTING TO onSubmit()
    createTeachingProfile = () => {


        const subjectTeachingListFromProfile = this.state.subjectTeachingListFromProfile;       // For Teaching List From Profile of this user
        const subjectSelectList = this.state.subjectSelectList;                                 // For Display Selected List

        const subj_li = [];
        const display_selected_list = [];

        const allSubjKeys = Object.keys(subjectTeachingListFromProfile);


        for (let i = 0; i < allSubjKeys.length; i++) {

            const subj_major = subjectTeachingListFromProfile[allSubjKeys[i]]['Major'];
            const subj_msg = subjectTeachingListFromProfile[allSubjKeys[i]]['Subject'] + ' ' + subjectTeachingListFromProfile[allSubjKeys[i]]['Title'];
            const subj_key_oring = subjectTeachingListFromProfile[allSubjKeys[i]]['keyInOrgin'];


            let LI_CLASS_NAME;
            let CLASS_NAME;
            if (subj_major === this.state.SUBJECT_TYPES.TEST) {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.TEST}-selected`;
                LI_CLASS_NAME = this.state.SUBJECT_TYPES.TEST.toLowerCase();
            }
            else if (subj_major === this.state.SUBJECT_TYPES.ENGLISH) {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.ENGLISH}-selected`;
                LI_CLASS_NAME = this.state.SUBJECT_TYPES.ENGLISH.toLowerCase();
            }
            else {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.ADMISSION}-selected`;
                LI_CLASS_NAME = this.state.SUBJECT_TYPES.ADMISSION.toLowerCase();
            }

            subj_li.push(
                <li key={i}
                    className={LI_CLASS_NAME}
                    data-key-in-orgin={subj_key_oring}
                    onClick={this.setSelectedList}>
                    {subj_msg}
                </li>
            );
        }


        subjectSelectList.map((item, i) => {

            const subj_major = item['Major'];
            const subj_title = item['Subject'];
            const subj_title_2 = item['Title'];
            const subj_key_oring = item['keyInOrgin'];

            let LI_CLASS_NAME;
            let CLASS_NAME;
            if (subj_major === this.state.SUBJECT_TYPES.TEST) {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.TEST}-selected`;
                LI_CLASS_NAME = this.state.SUBJECT_TYPES.TEST.toLowerCase();
            }
            else if (subj_major === this.state.SUBJECT_TYPES.ENGLISH) {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.ENGLISH}-selected`;
                LI_CLASS_NAME = this.state.SUBJECT_TYPES.ENGLISH.toLowerCase();
            }
            else {
                CLASS_NAME = `tutorreg-${this.state.SUBJECT_TYPES.ADMISSION}-selected`;
                LI_CLASS_NAME = this.state.SUBJECT_TYPES.ADMISSION.toLowerCase();
            }

            display_selected_list.push(
                <li key={i}
                    className={LI_CLASS_NAME}
                    data-key-in-orgin={subj_key_oring}
                    onClick={this.unSelectedList}>
                    <span className={`${CLASS_NAME}` + ' tuitions_bill-major-span'}>{subj_title}</span> {subj_title_2}
                    <img className="cursor" style={{ width: "25px", height: '25px', float: 'right' }}
                        src="../images/subbtn.png" alt='subbtn'
                    />
                </li>
            );
        })





        return (
            <div className='tuitions_bill-teaching-list-wapper'>
                <ul id='tuition_display_subj'>
                    {/* Subject List From Profile of This User */}
                    {subj_li}
                </ul>
                <ul>
                    <li>
                        <div className="cust-arrow-right"></div>
                    </li>
                </ul>
                <ul>
                    {/* Subject List Selected */}
                    {display_selected_list}
                </ul>
            </div>
        )
    }


    setStudentName = (e) => {
        const value = e.target.value;

        let validation_student_name = false;

        if (value === '') validation_student_name = true;

        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                studentName: value
            },
            validations: {
                ...this.state.validations,
                needStudentName: validation_student_name
            }
        }, () => {
        })

    }

    createStudentName = () => {
        const newArr = [];

        return (
            <div>
                {newArr}
                <p>
                    <TrasAftersubmissionyouwillbegivenatuitionbilllinkwhichyouneedtogiveyourstudentparent />
                </p>

                <h3 className="blue-font-color">
                    <TrasStudentname />
                </h3>
                <div className="row">
                    <div className="col-sm-12" style={{ marginBottom: '7px' }}>
                        <input
                            type="text"
                            style={{ fontSize: '1.1em' }}
                            className="form-control col-md-6"
                            onChange={this.setStudentName}
                            value={this.state.params.studentName}
                        />

                        {this.state.validations.needStudentName ?
                            <span style={{ textAlign: 'center', color: 'red' }}>
                                학생 이름을 입력해 주세요.
                            </span>
                            : ''}

                    </div>
                </div>
            </div>
        )
    }






    createPartB = () => {
        return (
            <div className='tuition_bill-part-b-wapper'>


                {/* STUDENTS NAME - START */}

                <div>
                    <h4 className="tuition_bill-part-b-modal-title">
                        <TrasTuitionBill />
                        {
                            this.state.isAdmin ?
                                <span>&nbsp;(선생님 ID&nbsp;{this.state.teacherid})</span>
                                : ''
                        }
                    </h4>
                    {this.createStudentName()}
                </div>

                {/* STUDENTS NAME - END */}


                {/* SELECTED ITEMS - START */}

                <div className='marginBottom15'>
                    <h3 className="blue-font-color"> <TrasCurriculumSubject /> </h3>
                    {this.createTeachingProfile()}
                </div>

                {/* SELECTED ITEMS - END */}


                {/* CHECK_BOX - START */}
                {/* 수업 모드 */}

                <div className=''>
                    <h3 className="blue-font-color marginBottom15"> <TrasTuitionMode />  </h3>

                    {this.createCheckboxes()}

                </div>

                {/* CHECK_BOX - END */}


                {/* START / END DATE - START */}

                <div className='marginBottom30'>
                    <h3 className="blue-font-color marginBottom15"> <TrasStartingEndDate /> </h3>
                    <p> <TrasMonthlyorforagreedperiodoftimeiflessthanamonth /> </p>

                    {this.callCalenders()}
                </div>

                {/* START / END DATE - END */}


                {/* HOURL - START */}

                <div className='marginBottom30'>
                    <h3 className="blue-font-color marginBottom15"> <TrasHourlyRate /> </h3>
                    <p>스터디룸 비용 청구도 유효하다면 시급에 추가해주세요, 예: 시급이 50000 (원/시간) 이고 스터디룸 비용이 1000원/시간이라면
                        51000 (원/시간)</p>

                    {this.createTuitionBillHour()}

                </div>

                {/* HOURL - END */}




                {/* TOTAL HOUR - START */}

                <div className='marginBottom30'>

                    <h3 className="blue-font-color marginBottom15"><TrasTotalHours /></h3>

                    {this.createTHours()}


                </div>


                {/* TOTAL HOUR - END */}



                {this.createReport()}



                {/* PAYMENT CALCULATION - START */}

                <div>
                    <h3 className="blue-font-color marginBottom15"> <TrasTuitionFee /> </h3>
                    {this.createPaymentCalculation()}
                </div>


                {/* PAYMENT CALCULATION - END */}

                <div className='marginBottom30'>
                    {this.createPartC()}
                </div>


                {/* SUBMITTING BUTTON - START */}

                {this.createSubmittingBtns()}

                {/* SUBMITTING BUTTON - END */}

            </div>
        )
    }


    onChangeCredit = (e) => {

        const target = e.target;

        let isCredit = false;
        if (target.id === 'credit') {
            isCredit = true;
        }
        this.setState({
            ...this.state,
            params: {
                ...this.state.params,
                pay_with_credit: isCredit,
            }
        });

    }


    createPartC = () => {

        const currentPaymentType = this.state.currentPaymentCountry;
        let current_payment = this.state.params.demo ?
            Math.round(
                (this.state.params.demo ?
                    (this.state.params.hours != 0) ? (1 * this.state.newhourlyRate * 0.5) + ((this.state.params.hours - 1) * this.state.newhourlyRate)
                        + (1 * this.state.gurumeRate * 0.5) + ((this.state.params.hours - 1) * this.state.gurumeRate)
                        + ((this.state.params.is_short_term) ? (1 * 0.5 * 20000) + ((this.state.params.hours - 1) * 20000) : 0)
                        + ((this.state.params.is_package_tuition) ? 100000 : 0)
                        : 0
                    : 0
                ))
            :
            Math.round(
                (this.state.params.hours * this.state.newhourlyRate +
                    (this.state.params.is_package_tuition ? 0 : this.state.params.hours * this.state.gurumeRate))
                + (this.state.params.is_package_tuition ? 100000 :
                    (-(this.state.params.demo ? this.state.params.hours === 0 ? 0 : (0.5 * this.state.newhourlyRate + 0.5 * this.state.gurumeRate) : 0)
                        + (this.state.params.is_short_term ? this.state.params.hours * 20000 : 0)
                    )
                )
            );

        const parentDom = document.getElementById('credit');
        if (parentDom) {
            if (this.state.params.student_credit < current_payment) {
                parentDom.setAttribute("disabled", "disabled");
                const parentDom2 = document.getElementById('bank');
                parentDom2.click();
            } else {
                parentDom.removeAttribute("disabled");
            }
        }


        if (this.state.isCopy && this.state.params.is_academy) {
            return (
                <div className='tuition_confirm-part-single-wapper'>
                    <h3 className="blue-font-color">
                        <TrasPaymentMethod />
                    </h3>
                    <div className='tuition_confirm-payment-way'>
                        {/* Payment Way */}
                        <div className="tuition_confirm-p-way"><input type="radio" name="wayPayment" id="bank" onChange={this.onChangeCredit} checked={!this.state.params.pay_with_credit} /><TrasBankTransfer /></div>
                        {/* Credit */}
                        <div className="tuition_confirm-p-way"><input type="radio" name="wayPayment" id="credit" onChange={this.onChangeCredit} checked={this.state.params.pay_with_credit} /><TrasCredit /> {this.state.params.student_credit < current_payment ? "(Not enough credit)" + this.state.params.student_credit : ""} </div>
                    </div>
                </div>
            )
        } else {
            return "";
        }
    }


    createHtml = () => {
        return (
            <div className='academy-wapper-c'>
                <div className='tuitions_bill-html-wapper'>
                    {/* {this.createPartA()} */}
                    {this.createPartB()}
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='default-wrapper'>
                {this.createNav()}
                {this.createHtml()}
            </div>
        )
    }
}

export default withRouter(Tuitions_bill);

