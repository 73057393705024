import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './MainModal.css';
const $ = window.jQuery;

const setCookie = (name, value, expiredays) => {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + expiredays)
    document.cookie = name + "=" + escape(value) + '; path=/; expires=' + todayDate.toGMTString() + ";"
}

const getCookie = () => {
    let cookieData = document.cookie;

    if (cookieData.indexOf("todayCookie1=done") < 0) {
        $("#main-modal-1").show()
    } else {
        $("#main-modal-1").hide()
    }
    if (cookieData.indexOf("todayCookie2=done") < 0) {
        $("#main-modal-2").show()
    } else {
        $("#main-modal-2").hide()
    }
}
class ModalItem extends Component {
    componentDidMount() {
        getCookie();
    }
    clickDoNotOpenModalToday = (id) => {
        let doNotOpenBtn = $('.do-not-modal');

        if (doNotOpenBtn.is(':checked')) {
            if (id === 1) {
                setCookie("todayCookie1", "done", 1);
            }
            else if (id === 2) {
                setCookie("todayCookie2", "done", 1);
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="modal-wrapper" id={this.props.id}>
                    <div className="modal-dialog-wrapper">
                        {
                            this.props.isOpen ?
                                <React.Fragment>
                                    <div className="modal-overlay" />
                                    <Modal.Dialog className="main-modal">
                                        <Modal.Header>
                                            <button className="modal-close-btn" onClick={this.props.close}>X</button>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <div className="modal-img">
                                                <img src={this.props.img} onClick={this.props.goToLink}/>
                                            </div>
                                            <div className="modal-event-content">
                                                {this.props.content}
                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <button onClick={this.props.link}>{this.props.linkButton}</button>
                                            {/* <button onClick={this.props.link2}>{this.props.linkButton2}</button> */}
                                        </Modal.Footer>
                                        <p class="do-not-open-modal">
                                            <input type="checkbox" className="do-not-modal" onClick={() => this.clickDoNotOpenModalToday(this.props.num)} />&nbsp;
                                    <label for="do-not-modal" title="do not modal today">오늘 하루동안 열지 않기</label>
                                        </p>
                                    </Modal.Dialog>
                                </React.Fragment> : null
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default class MainModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen_1: true,
            isModalOpen_2: true,
            checked: false,
        }
    }
    checkModal(id) {
        if (id === 1) {
            $("#main-modal-1").hide()
        } else if (id === 2) {
            $("#main-modal-2").hide()
        }
    }
    render() {
        return (
            <ModalItem
                id={this.props.id}
                close={() => this.checkModal(this.props.num)}
                img={this.props.img}
                content={this.props.content}
                isOpen={this.props.isModalNum}
                link={this.props.link}
                link2={this.props.link2}
                linkButton={this.props.linkButton}
                linkButton2={this.props.linkButton2}
                num={this.props.num}
                goToLink={this.props.goToLink}
            />
        )
    }
}