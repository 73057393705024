import React, { Component } from 'react';

import {
    TrasHome,
    TrasAboutGuruMe,

} from '../../Translate/EachTranslateC';

import './about_gurume.css';
import '../../default.css';
import Banner from '../../images/about-us/banner.jpg';
import GurumeTuition from '../../images/about-us/gurume_tuition.png';

class about_gurume extends Component {

    createGurumeNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><TrasAboutGuruMe /></li>
                </ol>
            </div>
        )
    }

    createAbout = () => {
        const bg = {
            height: "396px",
            backgroundImage: `url(${Banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
        return (
            <div className="about-banner" style={bg}>
                <div className="about-banner-box">
                    <div className="about-banner-main-wrapper text-center">
                        <h1 style={{ color: "white" }}>Teach for you, Learn from us, and Share with the others</h1>
                        <br />
                        <h4 style={{ color: "white" }}>해외에서 공부하며 도움이 필요한 학생들,<br /> 정보가 부족해 입시 준비가 힘든 학생들을 위한<br /> 전세계 600명 이상의 다양한 선생님들과 함께 하는<br /> All in one 유학 커뮤니티</h4>
                    </div>
                </div>
            </div>
        )
    }

    createGreyBackground = () => {
        return (
            <div className="greybackground">
                <div className="about-service">
                    <div className="about-service-box text-center" style={{ margin: "30px 0px 30px 0px" }}>
                        <div className="about-service-main-wrapper">
                            <h1>그루미 서비스</h1>
                            <div className="about-service-main-content" style={{ paddingTop: "30px" }}>
                                <div className="about-service-item">
                                    <div className="card text-center">
                                        <img className="card-img-top" src={GurumeTuition} alt="Card cap" />
                                        <div className="bg-gurume-blue" style={{ backgroundColor: "blue", margin: "0px -10px 0px -10px", padding: "5px 0px 5px 0px" }}>
                                            <h4 style={{ color: "white" }}>과외</h4>
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text">개인<br />온라인(스카입)<br />오프라인</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-service-item">
                                    <div className="card text-center">
                                        <img className="card-img-top" src="/images/about-us/gurume_class.png" alt="Card cap" />
                                        <div className="bg-gurume-blue" style={{ backgroundColor: "blue", margin: "0px -10px 0px -10px", padding: "5px 0px 5px 0px" }}>
                                            <h4 style={{ color: "white" }}>수업</h4>
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text">개인<br />그룹<br />온라인(스카입)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-service-item">
                                    <div className="card text-center">
                                        <img className="card-img-top" src="/images/about-us/gurume_contents.png" alt="Card cap" />
                                        <div className="bg-gurume-blue" style={{ backgroundColor: "blue", margin: "0px -10px 0px -10px", padding: "5px 0px 5px 0px" }}>
                                            <h4 style={{ color: "white" }}>컨텐츠</h4>
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text">유학 경험담<br />블로그<br />인터뷰 영상</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="about-service-item">
                                    <div className="card text-center">
                                        <img className="card-img-top" src="/images/about-us/gurume_consulting.png" alt="Card cap" />
                                        <div className="bg-gurume-blue" style={{ backgroundColor: "blue", margin: "0px -10px 0px -10px", padding: "5px 0px 5px 0px" }}>
                                            <h4 style={{ color: "white" }}>컨설팅</h4>
                                        </div>
                                        <div className="card-body">
                                            <p className="card-text">1:1 컨설팅<br />멘토링<br />네트워킹 이벤트(설명회)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createGurumeStory = () => {
        return (
            <div className="about-story">
                <div className="about-story-box" style={{ margin: "30px 0px 30px 0px" }}>
                    <div className="about-story-main-wrapper">
                        <h1 className="color-gurume-blue text-center">그루미 스토리</h1>
                        <br />
                        <h4>"제가 옥스퍼드 생화학 대입 준비를 하던 고등학교 3학년 당시, 옥스퍼드 생화학과 출신 학생을 만나 입시에 대한 조언을 구하고 싶었습니다. 하지만 안타깝게도 연락이 닿을 길이 없었고 입학 전까지도 만날 수 있는 기회가 없었습니다. 만약 이 때 성공적인 대입 경험이 있는 본교 재학생, 졸업생과 입학을 희망하는 입시생이 연결될 수 있는 통로가 있다면 좋겠다 생각에서 그루미가 시작되었습니다.</h4>
                        <h4><b>GuruMe</b>는 국내외 대학생 네트워크를 만들어 학생들의 멘토가 될 수 있는 선생님들과 함께합니다.  유학을 준비하는 어린 후배들에게 교과 과정 수업 뿐만 아니라 동기 부여가 될 수 있는 학업적인 멘토링 수업도 함께 제공해 높은 유학 사교육 비용의 부담을 줄입니다. 선생님으로서, 학생으로서 정직한 수업료와 함께 가르침을 나눌 수 있도록 비영리로 출발했습니다.</h4>
                        <h4><b>GuruMe</b>는 더 많은 학생들에게 유학 경험, 학업, 해외 관련 정보를 나누고자 블로그 포럼을 시작해 현재까지도 실직적인 정보의 컨텐츠를 제공하고있습니다. 꾸준하게 글과 영상 컨텐츠를 제작하며 다양한 SNS 플랫폼과 네트워킹 이벤트를 통해 학생, 학부모님, 그리고 선생님과의 소통을 지향합니다.</h4>
                        <h4><b>현재 Team GuruMe</b>는 그루미의 Philosophy 합리성, 네트워킹, 자율성, 효율성을 바탕으로 각 분야에서 역량을 발휘하며 유학 관련 서비스를 제공하고있습니다. 그루미 선생님들 또한 끊임없이 연구하고 발전하기 위해 노력합니다."</h4>
                        <p className="about-pull-right">-그루미 대표 이혁진</p>
                    </div>
                </div>
            </div>
        )
    }

    createGurumePeople = () => {
        return (
            <div className="greybackground">
                <div className="about-team">
                    <div className="about-team-box">
                        <div className="about-team-main-wrapper">
                            <h4 style={{ borderBottom: "3px solid #c6d2d5", display: "inline-block", paddingBottom: "8px" }}><b>그루미 팀원 소개</b>
                            </h4>
                            <p>그루미는 영국 옥스퍼드와 미국 유펜 출신 학교 재학 당시 비영리로 시작하였습니다.</p>
                            <div className="about-team-main-content">
                                <div className="about-team-item">
                                    <div className="card">
                                        <center>
                                            <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-1"></div>
                                            </div>
                                            <p><b>Harry Lee | 대표</b></p>
                                        </center>
                                        <li>B.Sc. M.Sc. Oxford University (생화학)</li>
                                        <li>Princeton University (생화학 방문 학생)</li>
                                        <li>6년의 GCSE, IB 과외 및 영국 대입 컨설팅 경험</li>
                                    </div>
                                </div>

                                <div className="about-team-item">
                                    <div className="card">
                                        <center>
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-2"></div>
                                            </div>
                                            <p><b>Patrick Chung | 부대표</b></p>
                                        </center>
                                        <li>B.A. University of Pennsylvania (수학, 일어)</li>
                                        <li>B.Sc. The Wharton School (금융, 마케팅)</li>
                                        <li>SAT II, IB 생물, 화학 및 칼리지 에세이 멘토링</li>
                                    </div>
                                </div>

                                <div className="about-team-item">
                                    <div className="card">
                                        <center>
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-3"></div>
                                            </div>
                                            <p><b>Mark | 개발자</b></p>
                                        </center>
                                        <li>B.Sc. Jiatong University (컴퓨터 공학)</li>
                                        <li>PhD UCLA (컴퓨터 공학)</li>
                                        <li>봉사 교육 사업</li>
                                    </div>
                                </div>
                                <div className="about-team-item">
                                    <div className="card">
                                        <center>
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-4"></div>
                                            </div>
                                            <p><b>Heera Shin | 인사/행정</b></p>
                                        </center>
                                        <li>SungKongHoe University (사회학, 문화기획)</li>
                                        <li>조직문화/행정 관리</li>
                                        <li>인재 채용/교육</li>
                                    </div>
                                </div>

                                <div className="about-team-item">
                                    <div className="card">
                                        <center>
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-5"></div>
                                            </div>
                                            <p><b>Helena Park | 개발자</b></p>
                                        </center>

                                        <li>Front-end Developer</li>
                                        <li>Daegu Software High School(SW개발과)</li>
                                        <li>사이트 기능 개발 및 유지보수</li>
                                    </div>
                                </div>

                                <div className="about-team-item">
                                    <div className="card">
                                        <center>
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-6"></div>
                                            </div>
                                            <p><b>Soo Lee | 컨텐츠 기획/제작자</b></p>
                                        </center>
                                        <li>Product Director / Journalist</li>
                                        <li>컨텐츠 기획 및 제작</li>
                                        <li>그루미 영상 / 마케팅</li>
                                    </div>
                                </div>
                                <div className="about-team-item">
                                    <div className="card">
                                        <center>
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-7"></div>
                                            </div>
                                            <p><b>Jessica Oh | IB학원 부원장</b></p>
                                        </center>
                                        <li>Canada VCC (TESOL)</li>
                                        <li>Sungkyunkwan University 대학원(영어교육)</li>
                                        <li>학원 행정 / 교육 관리</li>
                                    </div>
                                </div>
                                <div className="about-team-item">
                                    <div className="card">
                                        <center>
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-8"></div>
                                            </div>
                                            <p><b>Diana Lee | 중국어 학원장</b></p>
                                        </center>
                                        <li>Duksung University(중어중문), Gachon university(중국어 교육)</li>
                                        <li>중등교원자격증</li>
                                        <li>중국어 교육 서비스 기획/운영</li>
                                    </div>
                                </div>
                                <div className="about-team-item">
                                    <div className="card">
                                        <center className="colo">
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-10"></div>
                                            </div>
                                            <p><b>Lisa | 수업 매니저</b></p>
                                        </center>
                                        <li>China, Dalian university(영어)</li>
                                        <li>Kookmin university(교육)</li>
                                        <li>수업 매칭 관리</li>
                                    </div>
                                </div>
                                <div className="about-team-item">
                                    <div className="card">
                                        <center className="colo">
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-11"></div>
                                            </div>
                                            <p><b>Melissa | 컨설턴트</b></p>
                                        </center>
                                        <li>Hankuk University of Foreign Studies(영어교육과)</li>
                                        <li>컨설팅/멘토링 수업 관리</li>
                                        <li>컨설팅/멘토링 선생님 관리</li>
                                    </div>
                                </div>
                                <div className="about-team-item">
                                    <div className="card">
                                        <center className="colo">
                                        <div className="about-team-item-img-box">
                                                <div className="about-team-item-img" id="member-13"></div>
                                            </div>
                                            <p><b>Henry Park | 학습자료 편집</b></p>
                                        </center>
                                        <li>The Hollyfield School</li>
                                        <li>교재 편집</li>
                                        <li>운영/관리</li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    createHtml = () => {
        return (
            <div className="about-wrapper">
                {this.createGurumeNav()}
                {this.createAbout()}
                {this.createGreyBackground()}
                {this.createGurumeStory()}
                {this.createGurumePeople()}
            </div>
        )
    }

    render() {
        return (
            <div className='default-wrapper'>
                {this.createHtml()}
            </div>
        )
    }
}

export default about_gurume;