import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    TrasBlog,
    TrasGetStarted
} from '../../../Translate/EachTranslateC';
import './MainBlogs.css';


class MainBlogs extends Component{


    clickToBlog = () => {
        window.location.href = '/#/blogs';
    }


    SummonsMainBlogs = () => {
        return(
            <div className='main-blog-wapper'>
                <h1 className="white-font-color bold-font main_h1" style={{color:'white'}}>
                    <TrasBlog />
                </h1>
                <h4 style={{color:'white'}} className='main_h4'>
                    <span style={{color: '#5b9aef'}}>경험자들이 들려주는</span>
                    <span>대입 경험담, 학교/학과 소개 및 커리큘럼/과목 노하우</span>
                </h4>
                <button onClick={this.clickToBlog}>
                    <TrasGetStarted />
                </button>
            </div>
        )
    }

    render(){
        return this.SummonsMainBlogs();
    }
}

export default MainBlogs;