import React, { Component } from 'react';
import './online_tuition_touchpad.css';

import Online_tuition_touchpad_1 from '../../images/online-tuition-touchpad-box.jpg';
import Online_tuition_touchpad_2 from '../../images/online-tuition-touchpad-with-laptop.jpg';
import Online_tuition_touchpad_3 from '../../images/online-tuition/online_tuition_huion_tablet2.jpg';
import Online_tuition_touchpad_4 from '../../images/how_it_works/huion-pref.png';

export default class Online_tuition_touchpad extends Component {
    componentDidMount() {
        this.props.RootBindingData.doLoading(false);
    }
    render() {
        return (
            <div className="online-tuition-touchpad-wrapper">
                <h2>온라인 칠판 터치패드</h2>
                <p>온라인 칠판에서 효과적으로 글씨를 쓰고 보충설명을 할 수 있습니다.</p>
                <div className="online-tuition-touchpad-pic">
                    <div className="online-touchpad-pic-box">
                        <img className="touchpad-pic" src={Online_tuition_touchpad_1} />
                    </div>
                    <div className="online-touchpad-pic-box">
                        <img className="touchpad-pic" src={Online_tuition_touchpad_2} />
                    </div>
                </div>
                <br />
                <p>그루미는 가성비가 좋은 Huion 420를 추천합니다.</p>
                <p><a href="http://www.huiontablet.com/all-products/graphic-tablets/420.html" target="_blank">Huion</a>
                에서 $25로 직접 구매할 수 있습니다. 중국 외의 지역에서 구매할 경우, 추가 배송료가 있기 때문에 나라별 주요 온라인 마켓에서 찾아보는 것이 좋습니다.</p>
                <li><a
                    href="http://www.lazada.sg/huion-420-4-x-223-inches-professional-graphic-signature-padcompatible-with-microsoft-office-9186927.html?boost=1"
                    target="blank">싱가폴</a></li>
                <li><a href="http://www.amazon.co.uk/Huion-420-Portable-Writing-Signature/dp/B00DTPYWBG"
                    target="blank">영국</a></li>

                <h3 className="second-sec-title">Huion 420 터치패드 사용 메뉴얼</h3>
                <p>1. Huion 터치패드 드라이버 설치:</p>
                <ul>
                    <li><a href="https://drive.google.com/file/d/0B_AwTAYY3Iy6WVNSMGVOWks5RFE/view?usp=sharing" target="blank">Windows</a></li>
                    <li><a href="https://drive.google.com/file/d/0B_AwTAYY3Iy6Q2xEQUVvSld5NUE/view?usp=sharing" target="blank">Mac</a></li>
                </ul>
                <p>2. AAA battery를 펜에 넣어주세요</p>
                <div className="online-tuition-touchpad-pic">
                    <div className="online-touchpad-pic-box big-md">
                        <img className="touchpad-pic" src={Online_tuition_touchpad_3} />
                    </div>
                </div>
                <br />
                <p>3. 표시된 부분을 눌러 펜을 활성화 시켜주세요</p>
                <p>4. 케이블을 이용하여 터치패드와 컴퓨터를 연결해주세요</p>
                <div className="online-tuition-touchpad-pic">
                    <div className="online-touchpad-pic-box big-md">
                        <img className="touchpad-pic" src={Online_tuition_touchpad_4} />
                    </div>
                </div>
                <br />
                <p>5. 터치패드 sensitivity는 사용하시는 분에 따라 다르게 체감 될 수 있는데 본인의 취향에 맞게 Tablet Driver Panel 에서 설정해주시면 됩니다.</p>
            </div>
        )
    }
}
