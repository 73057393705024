
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    TrasNetworkingevent
} from '../../../Translate/EachTranslateC';
import './MainNetworking.css';

import MoocBrian from '../../../images/index_mooc_brian.jpg';

class MainNetworking extends Component {


    openMooc = () => {
        window.open('https://youtu.be/JTvt3evTntg');
    }
    goToMooc = () => {
        window.location.href = "/#/mooc_preview";
    }

    Summons = () => {
        return (
            <div className='main-networking-wr'>
                <div className="main-mooc-box">
                    <div className="main-mooc-ment">
                        <h1>IB 화학 인강</h1>
                        <h4>유학교육의 새로운 트렌드</h4>
                        <button onClick={this.goToMooc} >더보기</button>
                    </div>
                    <div className="main-mooc-img">
                        <img
                            onClick={this.openMooc}
                            src={MoocBrian} style={{ maxWidth: '100%' }} alt="youtube_link" />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return this.Summons();
    }
}

export default MainNetworking;