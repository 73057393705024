import React, { Component } from 'react';
import {
    TrasHome,
    TrasHowitworks,
    TrasTuition
} from '../../../Translate/EachTranslateC';
import {metaRegExp, metaClass, applyMetaHeader} from '../../extra/metaController';

import './how_it_works_mooc.css';

class MoocBanner extends Component {
    render() {
        return (
            <div className="mooc-explain-banner">
                <div className="mooc-explain-banner-box">
                    <div className="mooc-explain-banner-main">
                        <h2 className="font-size-44 boldest-font">IB 교육의 새로운 트렌드</h2>
                        <h4>최상의 IB 전문 강사진이 만든 "인강 + 기출문제집"으로 스스로 공부하기</h4>
                    </div>
                    <div className="color-overlay"></div>
                </div>
            </div>
        )
    }
}
class MoocContent extends Component {
    render() {
        return (
            <div className="mooc-explain-content-main">
                <h2>{this.props.title}</h2>
                <h4>{this.props.content}</h4>
            </div>
        )
    }
}
export default class How_it_works_mooc extends Component {


    componentDidMount(){
        this.setMetas();
    }

    setMetas = () => {
        const _type = '';
        const _title = 'MOOC | HOW IT WORKS';
        const _url = window.location.href;
        const _description = '1. 인강 열람. “5분 핵심 개념 설명 + 유형별 문제풀이” (키워드/개념별 검색으로 필요한 부분만 열람 가능). 2. 기출문제 풀기. 연도별 기출시험지 그리고 출판사별 교재';
        const _imageUrl = 'base';
        let _keywords = '';

        const newMetaClass = new metaClass(_type, _title, _url, _description, _imageUrl, _keywords);
        applyMetaHeader(newMetaClass);
    }

    goContactPage = () => {
        this.props.history.push("/contact_us")
    }
    render() {
        const contents = [
            {
                title: '1. 인강 열람',
                content: '"5분 핵심 개념 설명 + 유형별 문제풀이" (키워드/개념별 검색으로 필요한 부분만 열람 가능)'
            },
            {
                title: '2. 기출 문제 풀기',
                content: '연도별 기출시험지 그리고 출판사별 교재에서 부단원별로 선출된 문제 리스트'
            },
            {
                title: '3. 질의 응답',
                content: '강의 선생님께 댓글로 질문하고 답변 받기'
            },
            {
                title: '4. 학습 스케줄 상담 (옵션/추가비용)',
                content: '격주/월별 30분 스카입 미팅을 통해 학습 진도 및 계획 체크'
            },
            {
                title: '비용',
                content: '월별 구독 (2만원 부터)'
            }
        ]
        return (
            <div className="mooc-explain-wrapper">
                <MoocBanner />
                <div className="mooc-explain-content">
                    <div className="mooc-explain-content-box">
                        <div className="mooc-explain-contents">
                            {
                                contents.map(content => <MoocContent title={content.title} content={content.content} />)
                            }
                        </div>
                        <div className="mooc-explain-content-button">
                            <button className="gurume-blue-back-btn" onClick={this.goContactPage}>Start 1 Day Trial</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}