import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTypingEffect from 'react-typing-effect';

import {
    TrasTuitionReview,
} from '../../Translate/EachTranslateC';

import '../../css/common/common.css';
import './Main.css';

import DefaultMain from './bodyComponents/DefaultMain';
import MainReviews from './bodyComponents/MainReviews';
import MainRecommend from './bodyComponents/MainRecommend';
import MainBlogs from './bodyComponents/MainBlogs';
import MainNetworking from './bodyComponents/MainNetworking';
import MainMooc from './bodyComponents/MainMooc';

import MainModal from '../../components/utils/MainModal';

import * as RootBindingData from '../../modules/RootBindingData';
import * as AuthService from '../../modules/AuthService';
import Marquee from 'react-marquee';
import ReactTooltip from 'react-tooltip';

import MainBanner from '../../images/ib_fulltime_teachers_banner.jpg';
import ibdpIcon from '../../images/icon/ibdp_icon.png';
import gurumeLogoWhite from '../../images/gurume_logo_white.png';
import defaultTeacherImg from '../../images/backuptutorpic.png';

class TeacherPremiumBox extends Component {
    moveToTdPage = () => {
        window.location.href = '/#/teachers/' + this.props.pId;
    }
    render() {
        const pReviewCount = this.props.pReviewCount;
        const pRecommendCount = this.props.pRecommendCount;
        return (
            /* new IBDP card desigan */
            <div className="main-premium-card-main">
                <div onClick={this.moveToTdPage}>
                    <div className="teacher-profile-card-header">
                        <div className="teacher-card-header-box">
                            <div className="teacher-card-header-img">
                                <div className="teacher-card-header-img-box border-blink">
                                    <img src={this.props.pPicture} className="img-responsive"
                                        onError={function (e) { e.target.src = '../images/backuptutorpic.png'; }.bind(this)} />
                                </div>
                            </div>
                            <div className="teacher-card-header-info">
                                <div className="teacher-card-header-info-box">
                                    <div className="teacher-card-header-ibdp-1">
                                        <span className="teacher-name">{this.props.pName}</span><br />
                                        {/* IBDP Icon */}
                                        {
                                            this.props.pIBDP ?
                                                <div className="teacher-card-header-ibdp">
                                                    <div className="teacher-card-header-ibdp-box">
                                                        <div className="teacher-card-header-ibdp-img">
                                                            <img src={ibdpIcon} />
                                                        </div>
                                                    </div>
                                                </div> : ''
                                        }
                                    </div>
                                    <span className="teacher-university">{this.props.pUniversity}</span><br />
                                    <span className="teacher-major color-gurume-gray">{this.props.pMajor}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="teacher-profile-card-subject bg-premium-purple">
                        {/* new teacher profile design */}
                        {
                            this.props.pFlagstring !== '' ?
                                <img className="search-marquee-img" src={gurumeLogoWhite} />
                                : ''
                        }
                        <Marquee
                            className="search-marquee"
                            text={this.props.pCurriculum}
                            trailing="0.3"
                            leading="0.3"
                        />
                    </div>
                    <div className="teacher-profile-card-content color-gurume-gray">
                        {this.props.pIntro}
                        {/* <div className="teacher-profile-card-time">
                            {
                                (this.props.tdRespondT == 0) ? <p><i className="fa fa-envelope"></i>&nbsp;보통 몇 시간 이내 응답</p> : ((this.props.tdRespondT > 0 && this.props.tdRespondT < 3) ? <p><i className="fa fa-envelope"></i>&nbsp;보통 몇 일 이내 응답</p> : ((this.props.tdRespondT >= 3 && this.props.tdRespondT <= 7)) ? <p><i className="fa fa-envelope"></i>&nbsp;보통 일주일 이내 응답</p> : "")

                            }
                            </div> */}
                        {/* {
                            this.props.visibility === 0 ?
                                <div className="teacher-profile-card-hidden">
                                    당분간 수업을 받지 않습니다.
                                </div>
                                : ''
                        } */}
                    </div>
                    {/* average reply time */}
                </div >
                <div className="teacher-profile-card-footer">
                    <div className="teacher-profile-footer-info">
                        <p>
                            {
                                this.props.pHasDemo ?
                                    <span><i className="fa fa-youtube-play padding-right-3" style={{ color: 'red' }}></i>데모</span>
                                    : ''
                            }
                            {
                                this.props.pRecommendCount > 0 ?
                                    <span><i className="fa fa-thumbs-o-up padding-right-3"></i>{pRecommendCount} 추천</span>
                                    : ''
                            }
                            {
                                this.props.pReviewCount ?
                                    <span><i className="fa fa-comments-o padding-right-3"></i>{pReviewCount} 후기</span>
                                    : ''
                            }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

class Main extends Component {

    state = {
        pageLocationUrl: '/',
        lang: 'kr',
        Data_BestTutorReviews: [
            {
                id: 282,
                imageUrl: '/images/index_review_michaelsong.jpg',
                review: "수업 전 TOK라는 광범위한 철학 과목에 대해 생소하였지만, 막연했던 여러가지 부분들을 Michael 선생님께서 상세히 설명해주셔서 좋았습니다. 학교에서는 어렵게 들렸던 개념들을 Michael쌤이 이해하기 쉽게 설명해줘서 큰 도움이 되었습니다. 또한, 특히 학교에서 딱히 다루지 않았던 TOK를 쓰는 팁, 쓰면서 조심해야 사항들, 고득점을 받는 지름길 등 신속하고...",
                name: "송서호",
                study: "TOK",
                school: "옥스퍼드",
                representativeDegree: "신경과학 박사"
            },
            {
                id: 1187,
                imageUrl: '/images/index_review_wonjoon.jpg',
                review: "방원준 선생님은 매 수업마다 열정적이시고 비단 예전 IB시험지 문제들을 풀리기보단 학생들의 수학 실력을 높이려고 노력하십니다. 모르는 문제가 있으면 원리를 가르치고 더 심화문제를 풀려 실제 시험에서도 높은 점수를 받게 도와주십니다. ",
                name: "방원준",
                study: "IB 수학",
                school: "워윅",
                representativeDegree: "수학 석사"
            },
            {
                id: 1682,
                imageUrl: '/images/index_review_jiyoung.jpg',
                review: "아직 수업을 시작한 지 얼마 되지 않았지만 선생님은 첫 번째 수업으로 부터 제가 어떤 부분에 약한지 알아 채시고 그 부분을 수업 도중 매번 애기해 주시며 고쳐 주시려고 했습니다. 수업 도중 서로 의논 하며 한국어를 더 만족스럽게 배울 수 있었습니다. 선생님은 설명을 확실하고 쉽게 해주셨습니다. 그래서 제가 더욱 더 쉽고 효과적으로 수업 내용을 이해 할 수...",
                name: "윤지영",
                study: "IB 한국어",
                school: "UC 샌디에고",
                representativeDegree: "생물학"
            }
        ],
        tutorProfiles: [],
        isLoadedtutorProfilesData: false,

    }


    componentDidMount = () => {
        const authKey = this.props.isAuthkey;
        if (!this.props.isLoadedtutorProfilesData) {
            this.props.RootBindingData.setTutorPrifileData(authKey);
        }

    }


    Main_3_Recor_info = () => {
        const data = this.props.tutorProfile;
        return (
            <MainRecommend
                normalData={data}
            />
        )
    }
    Main_2_Reviews_info = () => {
        const data = this.state.Data_BestTutorReviews;
        return (
            <MainReviews
                normalData={data}
            />
        )
    }

    Main_1_info = () => {
        return (
            <DefaultMain />
        )
    }

    Main_4_Blog_info = () => {
        return (
            <MainBlogs />
        )
    }

    Main_5_Networking_info = () => {
        return (
            <MainNetworking />
        )
    }

    Main_6_Mooc_info = () => {
        return (
            <MainMooc />
        )
    }



    // ACTIVE DOM ANIMATION 
    // WHEN MOUSE ON AND LEAVE. ( A WRAPPER AND A CHILD )
    ANIM_START_MARQUEE = (e) => {
        const target = e.target;

        const TARGET_CLASS_NAME = 'main_marquee';
        const TARGET_CLASS_ACTIVE = 'main_marquee_active';

        let validCheck = false;

        target.classList.forEach(className => {
            if (className !== TARGET_CLASS_NAME) return;
            if (className === TARGET_CLASS_ACTIVE) return;

            validCheck = true;
        });

        if (!validCheck) return;

        target.classList.add(TARGET_CLASS_ACTIVE);

    }

    ANIM_END_MARQUEE = (e) => {

        const target = e.target;
        // const TARGET_CLASS_NAME = 'main_marquee';
        const TARGET_CLASS_WRAP_NAME = 'main_marquee_wrap';
        const TARGET_CLASS_ACTIVE = 'main_marquee_active';

        let isParent = false;
        let newTarget = null;

        for (let i = 0; i < target.classList.length; i++) {
            if (target.classList[i] === TARGET_CLASS_WRAP_NAME)
                isParent = true;
            else
                isParent = false;
        }

        if (isParent) {
            newTarget = target.children[0];
        }
        else {
            newTarget = target;
        }

        newTarget.classList.remove(TARGET_CLASS_ACTIVE);
    }

    goToAcademy = () => {
        window.location.href = '/#/academy';
    }

    render() {
        return (
            <React.Fragment>
                <div className='main-wr-rrr'>
                    <div className="homepage">
                        {this.Main_1_info()}
                    </div>
                    <div className='main-event'>
                        {this.Main_5_Networking_info()}
                    </div>
                    {/* <div className="main-review">
                        <div className="main-banner" onClick={this.goToAcademy}>
                            <img src={MainBanner} />
                        </div>
                    </div> */}

                    <div className="main-recommend">
                        <div className='s_main_start main-recommend-box'>
                            <div className="main-premium">
                                <div className="main-premium-title">
                                    <ReactTypingEffect className="typing-effect" text="이 달의 선생님이란?" spped={100} />
                                    <p className="margin-top-5 font-size-18">수업에 적극적으로 임하고자 홍보에 지원한 선생님들로 그루미 시스템에 관한 OT를 완료하였습니다.</p>
                                </div>
                                <div className="main-premium-profile">
                                    <TeacherPremiumBox
                                        pId="4195"
                                        pPicture={defaultTeacherImg}
                                        pName="박민우"
                                        pIBDP={false}
                                        pUniversity="UC Berkeley"
                                        pMajor="응용수학"
                                        pFlagstring=""
                                        pCurriculum="미국계 고학년, AP, SAT Subject, SAT"
                                        pIntro="안녕하세요. AP 수학, SAT 수학을 전문으로 하고 있는 박민우 선생님 입니다...."
                                        pHasDemo={false}
                                        pRecommendCount={6}
                                        pReviewCount="1"
                                    />
                                    <TeacherPremiumBox
                                        pId="3828"
                                        pPicture="https://s3.ap-northeast-2.amazonaws.com/gurume-tutor/anmiPRw9DZO4ZcbA2C52.png"
                                        pName="김예지"
                                        pIBDP={false}
                                        pUniversity="UCL"
                                        pMajor="Chemistry with..."
                                        pFlagstring=""
                                        pCurriculum="(i)GCSE, A Level, IB, 영국대학입학시험, Olympiad/Competition"
                                        pIntro="안녕하세요, 현재 UCL에서 Chemistry with Mathematics를 이..."
                                        pHasDemo={false}
                                        pRecommendCount=""
                                        pReviewCount=""
                                    />
                                    <TeacherPremiumBox
                                        pId="3272"
                                        pPicture="https://s3.ap-northeast-2.amazonaws.com/gurume-tutor/h3xFJ93Rn6cj81mkHwdt.png"
                                        pName="윤재영"
                                        pIBDP={false}
                                        pUniversity="Purdue University"
                                        pMajor="Mechanical Engi..."
                                        pFlagstring=""
                                        pCurriculum="(i)GCSE, AP, Pre-U, ACT, SAT Subject, SAT, Undergraduate"
                                        pIntro="안녕하세요, Purdue University에서 Mechanical Enginee..."
                                        pHasDemo={true}
                                        pRecommendCount={17}
                                        pReviewCount="8"
                                    />
                                    <TeacherPremiumBox
                                        pId="5816"
                                        pPicture="https://s3.ap-northeast-2.amazonaws.com/gurume-tutor/ct9Rdut0EPpxQPWftqC5.png"
                                        pName="허지나"
                                        pIBDP={false}
                                        pUniversity="The Chinese Uni..."
                                        pMajor="Global Economic..."
                                        pFlagstring=""
                                        pCurriculum="미국계 고학년, Master, Undergraduate, 석.박사, 커리어, Essay/Debate, Grammar, Reading, Coding/Programming"
                                        pIntro="홍콩 중문대에서 4년 장학금으로 Global Economics & Finance..."
                                        pHasDemo={false}
                                        pRecommendCount=""
                                        pReviewCount=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='main-blog_wsa'>
                        <div className='main-blog-box'>
                            {this.Main_4_Blog_info()}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}



let mapStateToProps = (state) => {
    return {
        tutorProfile: state.RootBindingData.tutorProfile,
        isLoadedtutorProfilesData: state.RootBindingData.isLoadedtutorProfilesData,
        isAuthkey: state.AuthService.isAuthkey
    };
}

let mapDispatchToProps = (dispatch) => {
    return {
        RootBindingData: bindActionCreators(RootBindingData, dispatch),
        AuthService: bindActionCreators(AuthService, dispatch)
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

