import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
    TrasHome,
    TrasCredit,
    TrasSubmit,
} from '../../../Translate/EachTranslateC';

import axios from '../../../config/axios';
import { GetAjaxURL, postConfig } from '../../../config/AjaxConfig';
import { showNotify, STATE_NOTIFY, moneyFormat } from '../../extra/cusUtil';

import './credit_approve.css';
import './credit_common.css';

class credit_approve extends Component {

    state = {
        buyList: [],
        withdrawList: [],
        allUsers: [],
        allStudents: [],



        withdrawPannel: {
            userId: '',
            payMethod: '1',
            bankInfo: '',
            creditAccount: '',
            proccessed: '0',
            message: '',
        },

        buyPannel: {
            userId: '',
            payMethod: '1',
            creditAccount: '',
            proccessed: '0',
        },

        isWithdrawSubmit: false,
        isBuySubmit: false,
        isShowDrawingPannel: false,
        isShowBuyPannel: false,
        isAjaxDone: false,

    }

    componentDidMount() {
        this.doInit();
    }

    doInit = async () => {

        const allUrls = await GetAjaxURL();
        const approveUrl = allUrls.gcoin.approve;
        const approveRes = await axios.get(approveUrl).catch((err) => { return console.log(err); });

        if (approveRes.data.status === 'fail') {
            let msg = '어드민만 접근이 가능한 페이지입니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return window.location.href = '/#/';
        }

        const buyList = approveRes.data.buy;
        const withdrawList = approveRes.data.withdraw;
        const allUsers = approveRes.data.users;
        const allStudents = approveRes.data.students;


        this.setState({
            ...this.state,
            buyList,
            withdrawList,
            allUsers,
            allStudents,
            isAjaxDone: true,
        })


    }


    createNav = () => {
        return (
            <div className='each-nav'>
                <ol className="breadcrumb">
                    <li><a href="/#/"><TrasHome /></a></li>
                    <li><a href="/#/credit/balance"><TrasCredit /></a></li>
                    <li className='active'>Approve</li>
                </ol>
            </div>
        )
    }


    moveToCreditHistory = () => {
        window.location.href = '/#/credit/history';
    }

    createHeader = () => {
        return (
            <div className='credit-default-body'>
                <h4 className='color-gurume-blue'>Create Buy/Withdraw</h4>
                <div className='credit-approve-header-btns'>
                    <button onClick={this.showDrawingPannel}>Add withdraw</button>
                    <button onClick={this.showBuyPannel}>Add buy</button>
                    <button onClick={this.moveToCreditHistory}>To Credit History</button>
                </div>
            </div>
        )
    }



    clickToApprove = async (e) => {

        const target = e.target;
        const dataId = target.dataset['id'];

        if (!dataId) {
            let msg = '에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const formData = new FormData();
        formData.append('id', dataId);

        const allUrls = await GetAjaxURL();
        const confirmBuyUrl = allUrls.gcoin.confirmBuy;
        const confirmBuyRes = await axios.post(confirmBuyUrl, formData, postConfig).catch((err) => { return console.log(err); });

        if (confirmBuyRes.data.status === 'success') {
            let msg = '성공적으로 반영했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            this.setBuyApproveData(dataId);
        } else {
            let msg = 'Unknown error';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }

    }

    setBuyApproveData = (_dataId) => {
        const dataId = _dataId;
        let buyList = this.state.buyList;

        const newBuyList = [];
        while (buyList.length > 0) {
            let each = buyList.shift();

            if (String(each.id) === String(dataId)) {
                each['isProcessed'] = 1;
            }
            newBuyList.push(each);
        }

        this.setState({
            ...this.state,
            buyList: newBuyList
        });
    }


    createBuyCreditList = () => {

        const buyList = this.state.buyList;

        const li = [];
        for (let i = 0; i < buyList.length; i++) {
            const id = buyList[i].id;
            const created_at = buyList[i].created_at;
            const full_name = buyList[i].user.last_name + ' ' + buyList[i].user.first_name;
            const pay_method = String(buyList[i].pay_method);
            const currency = String(buyList[i].currency);
            const money = buyList[i].money;
            const isProcessed = String(buyList[i].isProcessed);


            let displayPayMethod = 'Bank Transfer';

            if (pay_method === '2') {
                displayPayMethod = 'Paypal';
            } else if (pay_method === '3') {
                displayPayMethod = 'Alipay';
            } else {
                displayPayMethod = 'Bank Transfer';
            }

            let displayCurrency = 'KRW';
            if (currency === '1') {
                displayCurrency = 'RMB';
            } else if (currency === '3') {
                displayCurrency = 'GBP';
            } else {
                displayCurrency = 'KRW';
            }



            let displayProcess = <button>NO DATA</button>;
            if (isProcessed === '0') {
                displayProcess = <button data-id={id} onClick={this.clickToApprove}>Approve</button>;
            } else if (isProcessed === '1') {
                displayProcess = <button>Processed</button>;
            }



            li.push(
                <li key={'_key_' + i}>
                    <span>{created_at}</span>
                    <span>{full_name}</span>
                    <span>{displayPayMethod}</span>
                    <span>{displayCurrency}</span>
                    <span>{moneyFormat(money)}</span>
                    <span>{displayProcess}</span>
                </li>
            );
        }

        return (
            <div className='credit-default-body'>
                <h4 className='color-gurume-blue'>Buy Credit list</h4>
                <div className='credit-default-table'>
                    <div className='credit-bt-header2'>
                        <span>년도</span>
                        <span>Student Name</span>
                        <span>결제방식</span>
                        <span>Credit Currency</span>
                        <span>Amount</span>
                        <span>Approve</span>
                    </div>
                    <div className='credit-bt-bodies2'>
                        <ul>
                            {li}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    clickToWithdraw = async (e) => {

        const target = e.target;
        const dataId = target.dataset['id'];

        if (!dataId) {
            let msg = '에러가 발생했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return;
        }

        const formData = new FormData();
        formData.append('id', dataId);

        const allUrls = await GetAjaxURL();
        const confirmWithdrawUrl = allUrls.gcoin.confirmWithdraw;
        const confirmWithdrawRes = await axios.post(confirmWithdrawUrl, formData, postConfig).catch((err) => { return console.log(err); });

        if (confirmWithdrawRes.data.status === 'success') {
            let msg = '성공적으로 반영했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            this.setWithdrawData(dataId);
        } else {
            let msg = 'Unknown error';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
        }

    }


    setWithdrawData = (_dataId) => {
        const dataId = _dataId;
        let withdrawList = this.state.withdrawList;

        const newWithdrawList = [];
        while (withdrawList.length > 0) {
            let each = withdrawList.shift();

            if (String(each.id) === String(dataId)) {
                each['isProcessed'] = 1;
            }
            newWithdrawList.push(each);
        }

        this.setState({
            ...this.state,
            withdrawList: newWithdrawList
        });
    }

    createWithdrawCreditList = () => {


        const withdrawList = this.state.withdrawList;
        const li = [];
        for (let i = 0; i < withdrawList.length; i++) {

            const id = withdrawList[i].id;
            const created_at = withdrawList[i].created_at;
            const full_name = withdrawList[i].user.last_name + ' ' + withdrawList[i].user.first_name;
            const pay_method = String(withdrawList[i].pay_method);
            const currency = String(withdrawList[i].currency);
            const money = withdrawList[i].money;
            const isProcessed = String(withdrawList[i].isProcessed);
            const bank_info = String(withdrawList[i].bank_info);


            let displayPayMethod = 'Bank Transfer';

            if (pay_method === '2') {
                displayPayMethod = 'Paypal';
            } else if (pay_method === '3') {
                displayPayMethod = 'Alipay';
            } else {
                displayPayMethod = 'Bank Transfer';
            }

            let displayCurrency = 'KRW';
            if (currency === '1') {
                displayCurrency = 'RMB';
            } else if (currency === '3') {
                displayCurrency = 'GBP';
            } else {
                displayCurrency = 'KRW';
            }

            let displayProcess = <button>NO DATA</button>;
            if (isProcessed === '0') {
                displayProcess = <button data-id={id} onClick={this.clickToWithdraw}>Confirm Paid</button>;
            } else if (isProcessed === '1') {
                displayProcess = <button>Processed</button>;
            }


            li.push(
                <li key={'_key_withdraw_' + i}>
                    <span>{created_at}</span>
                    <span>{full_name}</span>
                    <span>{displayPayMethod}</span>
                    <span>{bank_info}</span>
                    <span>{displayCurrency}</span>
                    <span>{moneyFormat(money)}</span>
                    <span>{displayProcess}</span>
                </li>
            );

        }




        return (
            <div className='credit-default-body'>
                <h4 className='color-gurume-blue'>Withdraw Credit list</h4>
                <div className='credit-default-table'>
                    <div className='credit-bt-header2'>
                        <span>년도</span>
                        <span>Tutor Name</span>
                        <span>결제방식</span>
                        <span>Payment Account</span>
                        <span>Currency</span>
                        <span>Amount</span>
                        <span>Payment Confirm</span>
                    </div>
                    <div className='credit-bt-bodies2'>
                        <ul>
                            {li}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    createBodies = () => {
        return (
            <div>
                {this.createHeader()}
                {this.createBuyCreditList()}
                {this.createWithdrawCreditList()}
            </div>
        )
    }

    createHtml = () => {
        return (
            <div className='credit-approve-wapper academy-wapper-c'>
                {
                    this.state.isAjaxDone ?
                        this.createBodies()
                        : ''
                }
            </div>
        )
    }



    changerInUsers = async (e) => {
        const target = e.target;
        const value = target.value;

        const allUrls = await GetAjaxURL();
        const check_adminUrl = allUrls.gcoin.check_admin;

        const formData = new FormData();
        formData.append('id', value);

        const check_adminRes = await axios.post(check_adminUrl, formData, postConfig).catch((err) => { return console.log(err); });

        const message = check_adminRes.data.message;
        const bankInfo = check_adminRes.data.bank_info;

        this.setState({
            ...this.state,
            withdrawPannel: {
                ...this.state.withdrawPannel,
                userId: value,
                bankInfo: bankInfo,
                message: message,
            }
        })

    }


    changerCreditAmount = (e) => {
        const target = e.target;
        const value = target.value;


        this.setState({
            ...this.state,
            withdrawPannel: {
                ...this.state.withdrawPannel,
                creditAccount: value,
            }
        })
    }

    changerProccessed = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            withdrawPannel: {
                ...this.state.withdrawPannel,
                proccessed: value,
            }
        })
    }


    withdrawValidation = (withdrawPannel) => {
        const valid = false;
        if (!withdrawPannel.userId) {
            let msg = '유저 정보가 없습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return valid;
        }
        if (!withdrawPannel.creditAccount || String(withdrawPannel.creditAccount) === '0') {
            let msg = '크레딧 어카운트를 입력해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return valid;
        }
        if (!withdrawPannel.creditAccount) {
            let msg = '크레딧 어카운트 정보가 잘못 입력되었습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return valid;
        }

        if (!withdrawPannel.bankInfo === '') {
            let msg = '은행 정보가 없습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return valid;
        }

        return true;
    }

    doWithdrawSubmit = async () => {


        if (this.state.isWithdrawSubmit) return;
        this.setState({
            ...this.state,
            isWithdrawSubmit: true,
        })


        const withdrawPannel = this.state.withdrawPannel;
        const valid = await this.withdrawValidation(withdrawPannel);

        if (!valid) {
            this.setState({
                ...this.state,
                isWithdrawSubmit: false,
            })
            return;
        }

        const formData = new FormData();
        formData.append('money', withdrawPannel.creditAccount);
        formData.append('gcoin', withdrawPannel.creditAccount);
        formData.append('isProcessed', withdrawPannel.proccessed);
        formData.append('user_id', withdrawPannel.userId);
        formData.append('pay_method', withdrawPannel.payMethod);
        formData.append('bank_info', withdrawPannel.bankInfo);
        formData.append('currency', '2');

        const allUrls = await GetAjaxURL();
        const withdraw_adminUrl = allUrls.gcoin.withdraw_admin;

        const withdraw_adminRes = await axios.post(withdraw_adminUrl, formData, postConfig).catch((err) => { return console.log(err) });


        const resData = withdraw_adminRes.data;

        if (resData.status === 'success') {
            let msg = '성공적으로 인출되었습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);
            this.setState({
                ...this.state,
                withdrawPannel: {
                    userId: '',
                    payMethod: '1',
                    bankInfo: '',
                    creditAccount: '',
                    proccessed: '0',
                    message: '',
                },
                isWithdrawSubmit: false,
            }, () => {
                this.hideDrawingPannel();
            })

        } else {
            let msg = resData.msg;
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({
                ...this.state,
                isWithdrawSubmit: false,
            })
        }

    }

    showDrawingPannel = () => {
        this.setState({
            ...this.state,
            isShowDrawingPannel: true,
        })
    }
    hideDrawingPannel = () => {
        this.setState({
            ...this.state,
            isShowDrawingPannel: false,
        })
    }

    createDrawingPannel = () => {

        const allUsers = this.state.allUsers;


        const options = [];
        for (let i = 0; i < allUsers.length; i++) {
            options.push(
                <option key={'key__' + i} value={allUsers[i].id}>{allUsers[i].id + ')' + allUsers[i].name}</option>
            );
        }

        let bankInfo = this.state.withdrawPannel.bankInfo;
        if (bankInfo === '') {
            bankInfo = '입력된 정보가 없습니다.';
        }


        return (
            <div className='credit-drawing-pannel'>
                <div className='credit-drawing-pannel-card credit-adjust-height_1'>
                    <h5> Create Credit Withdraw </h5>
                    <div className='credit-drawing-card'>
                        <h4 className='color-gurume-blue'>회원</h4>
                        <select onChange={this.changerInUsers} value={this.state.withdrawPannel.userId}>
                            <option>회원을 선택해주세요.</option>
                            {options}
                        </select>
                    </div>
                    <div className='credit-drawing-card'>
                        <h4 className='color-gurume-blue'>Credit Amount</h4>
                        <input type='text' onChange={this.changerCreditAmount} value={this.state.withdrawPannel.creditAccount} />
                    </div>
                    <div className='credit-drawing-card'>
                        <h4 className='color-gurume-blue'>Processed?</h4>
                        <div>If choose "no", you have to approve this transaction in this admin page later.</div>
                        <div>
                            <label>
                                <input type='radio' name='proccessed' value='1' onChange={this.changerProccessed} /> Yes
                            </label>
                            <label>
                                <input type='radio' name='proccessed' value='0' onChange={this.changerProccessed} defaultChecked /> No
                            </label>
                        </div>
                    </div>
                    <div className='credit-drawing-card'>
                        <h4 className='color-gurume-blue'>Bank Info</h4>
                        <div className='disablemode'>
                            {bankInfo}
                        </div>
                        <div style={{ color: 'red' }}>
                            {this.state.withdrawPannel.message}
                        </div>
                    </div>
                    <div className='credit-drawing-table2-btns'>
                        <button className='credit-default-btn credit-default-blue-btn' onClick={this.hideDrawingPannel}>close</button>
                        <button className='credit-default-btn credit-default-blue-btn' onClick={this.doWithdrawSubmit}>
                            {
                                this.state.isWithdrawSubmit ?
                                    '제출 중'
                                    :
                                    <TrasSubmit />
                            }

                        </button>
                    </div>
                </div>
            </div>
        )
    }


    buyValidation = (buyPannel) => {

        const valid = false;

        if (!buyPannel.userId) {
            let msg = '유저 정보가 없습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return valid;
        }
        if (!buyPannel.creditAccount || String(buyPannel.creditAccount) === '0') {
            let msg = '크레딧 어카운트를 입력해주세요.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return valid;
        }
        if (isNaN(buyPannel.creditAccount)) {
            let msg = '크레딧 어카운트 정보가 잘못 입력되었습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            return valid;
        }
        return true;
    }

    doBuySubmit = async () => {

        if (this.state.isBuySubmit) return;

        this.setState({
            ...this.state,
            isBuySubmit: true
        })

        const buyPannel = this.state.buyPannel;

        const valid = await this.buyValidation(buyPannel);

        if (!valid) {
            this.setState({
                ...this.state,
                isBuySubmit: false
            })
            return;
        }


        const formData = new FormData();
        formData.append('money', buyPannel.creditAccount);
        formData.append('gcoin', buyPannel.creditAccount);
        formData.append('isProcessed', buyPannel.proccessed);
        formData.append('user_id', buyPannel.userId);
        formData.append('pay_method', buyPannel.payMethod);
        formData.append('currency', '2');

        const allUrls = await GetAjaxURL();
        const buy_adminUrl = allUrls.gcoin.buy_admin;

        const buy_adminRes = await axios.post(buy_adminUrl, formData, postConfig).catch((err) => { return console.log(err) });
        const buyData = buy_adminRes.data;

        if (buyData.status === 'success') {
            let msg = '성공적으로 반영했습니다.';
            showNotify(msg, STATE_NOTIFY.TYPE_POSITIVE);

            this.setState({
                ...this.state,
                buyPannel: {
                    userId: '',
                    payMethod: '1',
                    creditAccount: '',
                    proccessed: '0',
                },
                isBuySubmit: false,
                isShowBuyPannel: false,
            })

        } else {
            let msg = buyData.msg;
            showNotify(msg, STATE_NOTIFY.TYPE_NEGATIVIE);
            this.setState({
                ...this.state,
                isBuySubmit: false,
            })
        }

    }

    showBuyPannel = () => {
        this.setState({
            ...this.state,
            isShowBuyPannel: true,
        })
    }
    hideBuyPannel = () => {
        this.setState({
            ...this.state,
            isShowBuyPannel: false,
        })
    }


    changerStuName = (e) => {

        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            buyPannel: {
                ...this.state.buyPannel,
                userId: value,
            }
        })

    }

    changerStuCreditAccount = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            buyPannel: {
                ...this.state.buyPannel,
                creditAccount: value,
            }
        })

    }

    changerStuProccessed = (e) => {
        const target = e.target;
        const value = target.value;

        this.setState({
            ...this.state,
            buyPannel: {
                ...this.state.buyPannel,
                proccessed: value,
            }
        })
    }

    createBuyPannel = () => {

        const allStudents = this.state.allStudents;

        const options = [];
        for (let i = 0; i < allStudents.length; i++) {

            options.push(
                <option key={'key_i_' + i} value={allStudents[i].id}>
                    {allStudents[i].id + ') ' + allStudents[i].name}
                </option>
            )
        }

        return (
            <div className='credit-drawing-pannel'>
                <div className='credit-drawing-pannel-card credit-adjust-height_2'>
                    <h5> Create Credit Buy </h5>
                    <div className='credit-drawing-card'>
                        <h4 className='color-gurume-blue'>학생 이름</h4>
                        <select onChange={this.changerStuName} value={this.state.buyPannel.userId}>
                            <option>학생 이름을 선택해주세요.</option>
                            {options}
                        </select>
                    </div>
                    <div className='credit-drawing-card'>
                        <h4 className='color-gurume-blue'>Credit Amount</h4>
                        <input type='text' onChange={this.changerStuCreditAccount} value={this.state.buyPannel.creditAccount} />
                    </div>
                    <div className='credit-drawing-card'>
                        <h4 className='color-gurume-blue'>Processed?</h4>
                        <div>If not, you can confirm the student has finished payment in this admin page later.</div>
                        <div>
                            <label>
                                <input type='radio' name='stuproccessed' value='1' onChange={this.changerStuProccessed} /> Yes
                            </label>
                            <label>
                                <input type='radio' name='stuproccessed' value='0' onChange={this.changerStuProccessed} defaultChecked /> No
                            </label>
                        </div>
                    </div>
                    <div className='credit-drawing-table2-btns'>
                        <button className='credit-default-btn credit-default-blue-btn' onClick={this.hideBuyPannel}>close</button>
                        <button className='credit-default-btn credit-default-blue-btn' onClick={this.doBuySubmit}>
                            {
                                this.state.isBuySubmit ?
                                    '제출 중'
                                    :
                                    <TrasSubmit />
                            }
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.createNav()}
                {this.createHtml()}

                {
                    this.state.isShowDrawingPannel ?
                        this.createDrawingPannel()
                        : ''
                }

                {
                    this.state.isShowBuyPannel ?
                        this.createBuyPannel()
                        : ''
                }

            </div>
        )
    }
}

export default withRouter(credit_approve);